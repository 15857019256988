import {
  getAllBasicProfiles,
  getBasicProfilesThatNeedAttention,
  getPremiumProfiles,
  getTrainingProfiles,
  trainingProfile,
} from "../../Api/TrainingProfile";
import { useContext, useEffect, useState } from "react";
import { UserInfoContext } from "../../Context/UserContext";
import { isSuperAdmin } from "../../Api/User";
import { connect } from "getstream";
import Default from "../../Default/Default";
import { Notification } from "../../Api/Stream";
import { formatDistanceForAthletePage } from "../../Utilities/FormatUtilties";
import { useLocation } from "react-router-dom";
import Tooltip from "../../Utilities/Components/Tooltip";
import {
  defaultOnboardingProgressStatusesNavBar,
  onboardingProgressStatuses,
} from "../../Api/TrainingAttribute";
import useAthleteRatingIcon from "./useAthleteRatingIcon";

export type unreadMap = {
  trainingProfile: trainingProfile;
  unreadCount: number;
};

type statsMap = {
  trainingProfile: trainingProfile;
  stats: {
    sevenDayDistance: string;
    sevenDayDistanceTrend: string;
    twentyEightDayDistance: string;
    twentyEightDayDistanceTrend: string;
  };
};

interface useAthletesInput {
  showAllAthletes: boolean;
}

function useAthletes({ showAllAthletes }: useAthletesInput) {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const [trainingProfiles, setTrainingProfiles] = useState<trainingProfile[]>();
  const [basicNeedsAttentionProfiles, setBasicNeedsAttentionProfiles] =
    useState<trainingProfile[]>();
  const [premiumProfiles, setPremiumProfiles] = useState<trainingProfile[]>();
  const [allBasicProfiles, setAllBasicProfiles] = useState<trainingProfile[]>();
  const [unreadMap, setUnreadMap] = useState<unreadMap[]>();
  const [statsMap, setStatsMap] = useState<statsMap[]>();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { getRatingIcon } = useAthleteRatingIcon();

  function unreadCount(tab: "premium" | "basic" | "reviewed") {
    const selectedProfiles =
      tab === "basic"
        ? basicNeedsAttentionProfiles
        : tab === "premium"
        ? premiumProfiles
        : allBasicProfiles;

    if (!unreadMap || !selectedProfiles) return 0;

    if (premiumProfiles && tab === "premium") {
      return premiumProfiles.filter(
        (profile) => profile.coachingStatus === "NEEDS_ATTENTION"
      ).length;
    }

    if (basicNeedsAttentionProfiles && tab === "basic") {
      return basicNeedsAttentionProfiles.length;
    }

    return 0;
  }

  function athletesToRender() {
    if (location.pathname.replaceAll("/", "").endsWith("basic")) {
      return basicNeedsAttentionProfiles;
    } else if (location.pathname.replaceAll("/", "").endsWith("reviewed")) {
      return allBasicProfiles?.filter(
        (profile) =>
          !basicNeedsAttentionProfiles
            ?.map((profile) => profile.miniProfile.id)
            .some((id) => id === profile.miniProfile.id)
      );
    } else {
      return premiumProfiles;
    }
  }

  function getDaysSinceLastInteractionIcon(trainingProfile: trainingProfile) {
    const ratings = [
      {
        id: "GOOD",
        component: (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            Good
          </span>
        ),
      },
      {
        id: "OKAY",
        component: (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            Okay
          </span>
        ),
      },
      {
        id: "URGENT",
        component: (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            Urgent
          </span>
        ),
      },
    ];

    let statusElement = ratings.find(
      (rating) => rating.id === "GOOD"
    )?.component;

    if (
      trainingProfile.daysSinceLastCoachInteraction === -1 ||
      trainingProfile.daysSinceLastCoachInteraction >= 4
    ) {
      statusElement = ratings.find(
        (rating) => rating.id === "URGENT"
      )?.component;
    } else if (
      trainingProfile.daysSinceLastCoachInteraction >= 2 &&
      trainingProfile.daysSinceLastCoachInteraction <= 3
    ) {
      statusElement = ratings.find((rating) => rating.id === "OKAY")?.component;
    } else {
      statusElement = ratings.find((rating) => rating.id === "GOOD")?.component;
    }

    const tooltipMessage =
      trainingProfile.daysSinceLastCoachInteraction === -1
        ? "You haven't interacted with this athlete yet"
        : trainingProfile.daysSinceLastCoachInteraction === 0
        ? "You interacted with this athlete today"
        : `You interacted with this athlete ${
            trainingProfile.daysSinceLastCoachInteraction
          } ${
            trainingProfile.daysSinceLastCoachInteraction === 1 ? "day" : "days"
          } ago`;

    if (statusElement)
      return (
        <Tooltip display={statusElement} content={<p>{tooltipMessage}</p>} />
      );
  }

  useEffect(() => {
    function createStatsMap(trainingProfiles: trainingProfile[]) {
      let statsMap: statsMap[] = [];

      for (let i = 0; i < trainingProfiles.length; i++) {
        const sevenDayDistance = formatDistanceForAthletePage(
          trainingProfiles[i].stats.distanceLast7Days,
          userInfo?.profileMetricSetting,
          true,
          false,
          true,
          1
        );

        const twentyEightDayDistance = formatDistanceForAthletePage(
          trainingProfiles[i].stats.distanceLast28Days,
          userInfo?.profileMetricSetting,
          true,
          false,
          true,
          1
        );

        const sevenDayTrend =
          Math.round(trainingProfiles[i].stats.distanceTrendLast7Days * 100) +
          "%";

        const twentyEightDayTrend =
          Math.round(trainingProfiles[i].stats.distanceTrendLast28Days * 100) +
          "%";

        const newMapping: statsMap = {
          trainingProfile: trainingProfiles[i],
          stats: {
            sevenDayDistance: sevenDayDistance,
            sevenDayDistanceTrend: sevenDayTrend,
            twentyEightDayDistance: twentyEightDayDistance,
            twentyEightDayDistanceTrend: twentyEightDayTrend,
          },
        };

        statsMap = statsMap.concat([newMapping]);
      }

      return statsMap;
    }

    function initStatsMap(trainingProfiles: trainingProfile[]) {
      if (!statsMap || location.pathname === "/coach")
        setStatsMap(createStatsMap(trainingProfiles));
    }

    if (trainingProfiles) {
      setIsLoading(false);
      initStatsMap(trainingProfiles);
    }
  }, [location.pathname, setUserInfo, trainingProfiles, userInfo]);

  useEffect(() => {
    async function initUnreadMap(trainingProfiles: trainingProfile[]) {
      if (!userInfo) return;

      const client = connect(
        Default.streamsApiKey,
        userInfo?.streamAPIAuth,
        Default.streamsAppId
      );

      const feed = client.feed(
        "athlete_coach_notifications",
        client.currentUser?.id
      );

      const feedContent = await feed.get({ limit: 100 });

      let unreadMap: unreadMap[] = [];

      const feedItems = feedContent.results as Notification[];

      for (let i = 0; i < trainingProfiles.length; i++) {
        const items = feedItems.filter(
          (item) =>
            item.activities[0].actor ===
              trainingProfiles[i].miniProfile.numericId.toString() &&
            !item.is_read
        );
        const newMap: unreadMap = {
          trainingProfile: trainingProfiles[i],
          unreadCount: items.length,
        };

        unreadMap = unreadMap.concat([newMap]);
      }

      setUnreadMap(unreadMap);
    }

    if (trainingProfiles && userInfo) {
      initUnreadMap(trainingProfiles);
    }
  }, [userInfo, trainingProfiles, location.pathname]);

  function reInitAthleteStates() {
    async function initBasicAthletesThatNeedAttention() {
      const athletes = await getBasicProfilesThatNeedAttention();
      if (athletes) setBasicNeedsAttentionProfiles(athletes);
    }
    async function initPremiumAthletes() {
      const athletes = await getPremiumProfiles();
      if (athletes) setPremiumProfiles(athletes);
    }

    async function initAllBasicAthletes() {
      const athletes = await getAllBasicProfiles();
      if (athletes) setAllBasicProfiles(athletes);
    }

    initBasicAthletesThatNeedAttention();
    initPremiumAthletes();
    initAllBasicAthletes();
  }

  useEffect(() => {
    async function initBasicAthletesThatNeedAttention() {
      const athletes = await getBasicProfilesThatNeedAttention(showAllAthletes);
      if (athletes) setBasicNeedsAttentionProfiles(athletes);
    }
    async function initPremiumAthletes() {
      const athletes = await getPremiumProfiles(showAllAthletes);
      if (athletes) setPremiumProfiles(athletes);
    }

    async function initAllBasicAthletes() {
      const athletes = await getAllBasicProfiles(showAllAthletes);
      if (athletes) setAllBasicProfiles(athletes);
    }

    async function initTrainingProfiles() {
      let trainingProfileStatuses = defaultOnboardingProgressStatusesNavBar;

      if (userInfo && isSuperAdmin(userInfo))
        trainingProfileStatuses = [onboardingProgressStatuses[4]];

      const trainingProfiles = await getTrainingProfiles(
        showAllAthletes,
        trainingProfileStatuses
      );

      if (trainingProfiles) {
        setTrainingProfiles(trainingProfiles);
      }
    }

    initTrainingProfiles();
    initBasicAthletesThatNeedAttention();
    initPremiumAthletes();
    initAllBasicAthletes();
  }, [showAllAthletes, userInfo]);

  return {
    getRatingIcon,
    getDaysSinceLastInteractionIcon,
    unreadMap,
    statsMap,
    trainingProfiles,
    isLoading,
    basicNeedsAttentionProfiles,
    premiumProfiles,
    allBasicProfiles,
    athletesToRender,
    unreadCount,
    reInitAthleteStates,
  } as useAthletesOutput;
}

export interface useAthletesOutput {
  getRatingIcon: (trainingProfile: trainingProfile) => JSX.Element | undefined;
  getDaysSinceLastInteractionIcon: (
    trainingProfile: trainingProfile
  ) => JSX.Element | undefined;
  unreadMap: unreadMap[] | undefined;
  statsMap: statsMap[] | undefined;
  trainingProfiles: trainingProfile[] | undefined;
  isLoading: boolean;
  basicNeedsAttentionProfiles: trainingProfile[] | undefined;
  premiumProfiles: trainingProfile[] | undefined;
  allBasicProfiles: trainingProfile[] | undefined;
  athletesToRender: () => trainingProfile[] | undefined;
  unreadCount(tab: "premium" | "basic" | "reviewed"): number;
  reInitAthleteStates: () => void;
}

export default useAthletes;
