import { IonContent, IonPage } from "@ionic/react";

function AccountAuthenticationSuccess() {
  return (
    <IonPage>
      <IonContent>
        <h1 className="mt-12 text-2xl text-center">
          Your account was successfully linked
        </h1>
      </IonContent>
    </IonPage>
  );
}

export default AccountAuthenticationSuccess;
