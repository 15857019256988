import { GlobeIcon, PlusCircleIcon, UserIcon } from "@heroicons/react/outline";
import {
  createBlankTemplate,
  TrainingEventTemplate,
} from "../../Api/TrainingEventTemplate";
import { useTemplateContext } from "../../Context/TemplateContext";
import { ViewerContentType } from "./util";

interface Props {
  handleOpenTemplate: (template: TrainingEventTemplate) => void;
  setListViewerContents: React.Dispatch<
    React.SetStateAction<ViewerContentType | undefined>
  >;
}

export default function Container({
  handleOpenTemplate,
  setListViewerContents,
}: Props) {
  const { initTemplates } = useTemplateContext();

  if (
    // eslint-disable-next-line no-restricted-globals
    !location.pathname.replace("/", "").includes("coach") ||
    // eslint-disable-next-line no-restricted-globals
    !location.pathname.replace("/", "").includes("calendar")
  ) {
    return null;
  }

  function Title() {
    return (
      <h1 className="font-semibold text-[#9CA3AF] text-[12px] tracking-[1px]">
        TEMPLATES
      </h1>
    );
  }

  function PersonalTemplates() {
    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          setListViewerContents(ViewerContentType.PERSONAL);
        }}
        className="flex items-center mt-1 py-2 px-[15px]"
      >
        <UserIcon className="w-5 h-5 mr-[11px] text-neutral-500" />
        <p className="font-medium text-neutral-700 text-[14px]">Personal</p>
      </button>
    );
  }

  function SharedTemplates() {
    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          setListViewerContents(ViewerContentType.SHARED);
        }}
        className="flex items-center mt-1 py-2 px-[15px]"
      >
        <GlobeIcon className="w-5 h-5 mr-[11px] text-neutral-500" />
        <p className="font-medium text-neutral-700 text-[14px]">Shared</p>
      </button>
    );
  }

  function CreateButton() {
    async function handleAdd() {
      const templateName = prompt("Enter template name");
      if (templateName) {
        const template = await createBlankTemplate(templateName);

        if (template) {
          await initTemplates();
          handleOpenTemplate(template);
        }
      }
    }

    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleAdd();
        }}
        className="flex w-full items-center mt-1 py-2 px-[15px] border border-neutral-200 rounded-[8px]"
      >
        <PlusCircleIcon className="w-5 h-5 mr-[11px] text-neutral-500" />
        <p className="font-medium text-neutral-700 text-[14px]">Create New</p>
      </button>
    );
  }

  return (
    <>
      <div
        onClick={() => {
          setListViewerContents(undefined);
        }}
        className="relative w-64 px-5 pt-6 bg-white"
      >
        <div className="sticky-top top-24">
          <Title />

          <>
            <PersonalTemplates />
            <SharedTemplates />
          </>
          <CreateButton />
        </div>
      </div>
    </>
  );
}
