import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { trainingProfile } from "../Api/TrainingProfile";
import TailwindDashboardModal from "../Coach/Components/TailwindDashboardModal";
// import ActivityFeedback from "../Coachee/Activity/Feedback/ActivityFeedback";
import CoachPost from "../Coachee/Components/CoachPost";
import DashboardModal from "../Coachee/Components/DashboardModal";
import DeletePostModal from "../Coachee/Components/DeletePostModal";
import PostFeedback from "../Coachee/Components/PostFeedback";
import useActivityFeedback from "../Coachee/Hooks/useActivityFeedback";
import { UserInfoContext } from "../Context/UserContext";
import ProfilePicture from "../Utilities/Components/ProfilePicture";
import { formatNotificationDate } from "../Utilities/DateUtilities";
import { ModalContent } from "./CoacheeDashboard";
import useCoachPosts from "./Hooks/useCoachPosts";

interface CoachPostsInput {
  selectedTrainingProfile: trainingProfile;
}

function CoachPosts({ selectedTrainingProfile }: CoachPostsInput) {
  const { userInfo } = useContext(UserInfoContext);
  const {
    filteredPosts,
    streamClient,
    triggerRefresh,
    deactivateDeleteModal,
    handleDeletePost,
    postToBeDeleted,
    activateDeleteModal,
  } = useCoachPosts({
    selectedTrainingProfile,
  });
  const history = useHistory();
  const [modalContent, setModalContent] = useState<ModalContent>();
  const params = useParams<{ idforeignid: string | undefined }>();
  const id =
    params.idforeignid && params.idforeignid.split("coach_feedback")[0];
  const foreign_id =
    params.idforeignid &&
    "coach_feedback" + params.idforeignid.split("coach_feedback")[1];

  const selectedPost = filteredPosts?.find((post) => post.id === id);

  const activityFeedback = useActivityFeedback({
    id: id,
    foreign_id: foreign_id,
  });

  return (
    <>
      <div className="font-tableHeader">
        <header className="relative z-20 flex items-center justify-between px-6 lg:flex-none">
          <div className="flex items-center justify-between gap-2">
            {params.idforeignid && (
              <ArrowLeftIcon
                onClick={() => {
                  if (history.length === 0) {
                    history.push(
                      `/coach/${selectedTrainingProfile.miniProfile.id}/posts`
                    );
                  } else {
                    history.goBack();
                  }
                }}
                className="w-5 h-5 text-lg font-semibold text-gray-900 cursor-pointer"
              />
            )}
            <h1 className="text-lg font-semibold text-gray-900">
              {params.idforeignid ? "Post" : "Posts"}
            </h1>
          </div>
          {!params.idforeignid && (
            <button
              onClick={() => setModalContent("new_post")}
              className="flex items-center gap-1"
            >
              <PlusIcon className="w-4 h-4 text-rose-500" />
              <p className="font-medium text-rose-500">Add Post</p>
            </button>
          )}
        </header>
        <div className="mx-auto max-w-7xl">
          <div className="pb-10 mx-auto max-w-7xl">
            <div className="py-5 space-y-6 overflow-hidden sm:p-6">
              {id && foreign_id && selectedPost && streamClient ? (
                <div className="px-6 py-8 bg-white rounded-lg">
                  <div className="relative flex items-center gap-2 pb-10">
                    <span
                      className="absolute w-px h-full -ml-px bg-gray-200 top-8 left-5"
                      aria-hidden="true"
                    />
                    <div className="relative flex items-start space-x-3">
                      <ProfilePicture
                        url={selectedPost.actorMiniProfile.profilePicture}
                        classNames="flex items-center justify-center bg-gray-400 w-9 h-9 rounded-xl ring-[12px] ring-white"
                      />
                      <div>
                        <div className="flex items-center gap-2">
                          <p className="font-medium">
                            {userInfo &&
                            selectedPost.actor === streamClient.userId ? (
                              <p>Coach {userInfo.firstName}</p>
                            ) : (
                              <p>{selectedPost.actorMiniProfile.name}</p>
                            )}
                          </p>
                          <p className="text-xs text-gray-400">
                            {formatNotificationDate(
                              new Date(selectedPost.published),
                              true
                            )}
                          </p>
                        </div>
                        <p className="text-sm whitespace-pre-line">
                          {selectedPost.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  <PostFeedback activityFeedback={activityFeedback} />
                </div>
              ) : (
                <>
                  {filteredPosts &&
                    streamClient &&
                    userInfo &&
                    filteredPosts.map((post, index) => (
                      <div key={index}>
                        <CoachPost
                          post={post}
                          selectedTrainingProfile={selectedTrainingProfile}
                          streamClient={streamClient}
                          activateDeleteModal={activateDeleteModal}
                        />
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <>
        <TailwindDashboardModal
          modalContent={modalContent}
          setOpen={setModalContent}
        >
          <DashboardModal
            selectedTrainingProfile={selectedTrainingProfile}
            handleRefresh={triggerRefresh}
            closeModal={() => setModalContent(undefined)}
            modalContent={modalContent}
          />
        </TailwindDashboardModal>
        <DeletePostModal
          deactivatePostModal={deactivateDeleteModal}
          handleDeletePost={handleDeletePost}
          showDeleteModal={postToBeDeleted !== undefined}
        />
      </>
    </>
  );
}

export default CoachPosts;
