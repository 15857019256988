import Checklist from "../../../Assets/Checklist.svg";
import {formatMinutesAndSecondsToMetricVelocity, formatPaceToMinutesAndSeconds, getPaceUnit} from "../../../Utilities/FormatUtilties";
import {classNames} from "../../../Utilities/classnames";
import {useState} from "react";
import {useUserInfo} from "../../../Context/UserContext";
import _ from "lodash";
import {usePaceZonesOutput} from "./usePaceZones";
import Tooltip from "../../../Utilities/Components/Tooltip";

function PaceZones({
                     paceZonesHook,
                     adminView
                   }: {
  paceZonesHook: usePaceZonesOutput;
  adminView: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const {userInfo} = useUserInfo();
  const originalZones = paceZonesHook.paceZoneValues.map((pace) => formatPaceToMinutesAndSeconds(pace, 1, userInfo?.profileMetricSetting));
  const [paceZoneValues, setPaceZoneValues] = useState(paceZonesHook.paceZoneValues.map((pace) => formatPaceToMinutesAndSeconds(pace, 1, userInfo?.profileMetricSetting)));

  function updateSeconds(pace: { paceSeconds: number; paceMinutes: number }, e: React.ChangeEvent<HTMLInputElement>, index: number) {
    pace.paceSeconds = parseInt(e.target.value, 10);
    if (pace.paceSeconds < 0 || pace.paceSeconds > 59) {
      return false;
    }
    const items = [...paceZoneValues];
    const item = {...paceZoneValues[index]};
    item.paceSeconds = parseInt(e.target.value, 10);
    items[index] = item;
    setPaceZoneValues(items);
    return true;
  }

  function updateMinutes(pace: { paceSeconds: number; paceMinutes: number }, e: React.ChangeEvent<HTMLInputElement>, index: number) {
    pace.paceMinutes = parseInt(e.target.value, 10);
    if (pace.paceMinutes < 0 || pace.paceMinutes > 30) {
      return false;
    }
    const items = [...paceZoneValues];
    const item = {...paceZoneValues[index]};
    item.paceMinutes = parseInt(e.target.value, 10);
    items[index] = item;
    setPaceZoneValues(items);
    return true;
  }

  function handleSave() {
    setLoading(true);
    paceZonesHook.updatePaceZoneToBackend(paceZoneValues.map(pace => formatMinutesAndSecondsToMetricVelocity(pace.paceMinutes, pace.paceSeconds, userInfo?.profileMetricSetting)));
    setLoading(false);
  }

  function paceZoneTable() {
    return (<div>
      <div className=" shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
          <tr className="divide-x divide-gray-200">
            <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-2">
              Zone
            </th>
            <th scope="col" className="px-4 overflow-visible py-3.5 text-left text-sm font-semibold text-gray-900 flex justify-between">
              <div>Pace</div>
              {
                adminView && paceZonesHook.isOverride && (

                      <Tooltip
                          display={
                            <div className="cursor-default inline-flex items-center z-50 px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                              Manually changed
                            </div>
                          }
                          content={`One or more of the athlete's pace zone(s) were manually changed. This will prevent future automatic calculations.`}
                      />
                  )
              }
            </th>
          </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
          {paceZoneValues.map((pace, index) => {

            return (
                <tr key={index + 1} className="divide-x divide-gray-200">
                  <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                    {index + 1}
                  </td>
                  <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                    <input
                        className="border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm w-[60px] text-right"
                        type="number" value={pace.paceMinutes}
                        min="0" max="30"
                        onChange={(e) => {
                          return updateMinutes(pace, e, index);
                        }}/>
                    &nbsp;:&nbsp;
                    <input
                        min="0" max="59"
                        onChange={(e) => {
                          return updateSeconds(pace, e, index);
                        }}
                        className="border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm w-[60px] text-right"
                        type="number" value={pace.paceSeconds}/>
                    &nbsp;{getPaceUnit(userInfo?.profileMetricSetting)}
                  </td>
                </tr>);
          })}
          </tbody>
        </table>
      </div>
      <button
          disabled={loading || _.isEqualWith(originalZones, paceZoneValues)}
          type="button"
          onClick={async () => {
            handleSave();
          }}
          className={classNames(
              "mt-8 disabled:cursor-default disabled:bg-gray-50 disabled:border-gray-300 disabled:text-gray-400 flex border-[#F57385] items-center justify-center w-full gap-2.5 py-3 border rounded-lg"
          )}
      >
        Save
      </button>
    </div>);
  }

  if (adminView) {
    return paceZoneTable();
  }

  return (
      <div className="p-4 mt-6 bg-white border border-gray-10 rounded-xl">
        <img src={Checklist} alt="checklist"/>
        <h2 className="font-semibold text-[20px] mt-2">Pace Zones</h2>
        <p className="text-[#717171] text-[15px] pr-8 mt-2">
          See and adjust your pace zones.
        </p>
        {paceZoneTable()}
      </div>
  );
}

export default PaceZones;

