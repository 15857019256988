import { useEffect } from "react";
import { getUserInfo, signUpWithTypeForm } from "../Api/User";

import { Widget } from "@typeform/embed-react";
import ReactGA from "react-ga";
import "@typeform/embed/build/css/widget.css";
import { track, TrackingEventType } from "../Api/Tracking";

import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import {
  getCurrentSource,
  updateCurrentExperimentIfNeeded,
} from "../Utilities/Experiments";
import { getTrainingAttributes } from "../Api/TrainingAttribute";
import DefaultWebFooter from "../Coach/Components/Footer/DefaultWebFooter";
import { isMobile } from "../Utilities/isMobile";

function Onboard() {
  const routerHook = useIonRouter();

  useEffect(() => {
    async function redirectIfLoggedIn() {
      const response = await getUserInfo();

      if (response.userInfo) {
        const trainingAttributes = await getTrainingAttributes(
          response.userInfo.miniProfile.id
        );

        if (trainingAttributes && trainingAttributes.onboardingProgress > 0) {
          // routerHook.push("/onboard", "none", "replace");
          switch (trainingAttributes.onboardingProgress) {
            case 1:
              routerHook.push("/pending");
              break;
            case 2:
              routerHook.push("/pending");
              break;
            case 3:
              routerHook.push("/pending");
              break;
            case 4:
            case 5:
              routerHook.push("/dashboard/training-log");
              break;
            default:
              break;
          }
        }
      } else {
        ReactGA.event({
          category: "CheckoutFlow",
          action: "SurveyStarted",
        });
      }
    }

    updateCurrentExperimentIfNeeded();
    redirectIfLoggedIn();
  }, []);

  async function createProfileFromTypeform(payload: { responseId: string }) {
    const responseId = payload.responseId;
    ReactGA.event({
      category: "CheckoutFlow",
      action: "ProfileCreated",
    });
    window.fbq("track", "CompleteRegistration");
    window.gtag_report_signup_conversion();
    // Add your logic to submit to your backend server here.
    await track(
      TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
      "Finished stand-alone survey"
    ).then(() => {
      return signUpWithTypeForm(responseId).then(async (signUpResponse) => {
        if (!signUpResponse) {
          track(
            TrackingEventType.TRACKING_APP_WEB_SIGN_UP,
            "User completed intake survey, but didn't provide an e-mail.\n*Source:* " +
            getCurrentSource(),
            "profiles"
          );
          return;
        }
        if (signUpResponse && signUpResponse.errorMessage === "") {
          const response = await getUserInfo();
          if (response.userInfo) {
            track(
              TrackingEventType.TRACKING_APP_WEB_SIGN_UP,
              "User completed intake survey\n*E-mail:* " +
              response.userInfo.email +
              "\n*Source:* " +
              getCurrentSource(),
              "profiles"
            );
          }
        }
      });
    });
  }

  return (
    <IonPage>
      <IonContent>
        <div className="w-full h-full">
          <Widget
            inlineOnMobile
            id="bd5SYFpF"
            className="w-full h-full"
            onReady={() => {
              track(
                TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
                "Loaded stand-alone survey"
              );
            }}
            onSubmit={createProfileFromTypeform}
          />
        </div>
      </IonContent>
      {/* {!isMobile && <DefaultWebFooter />} */}
    </IonPage>
  );
}

export default Onboard;
