import { Popover } from "@headlessui/react";
import { classNames } from "../../../Utilities/classnames";

function CalendarContextMenuContainer({
  displayOnTop,
  displayContainerOnLeft,
  containerRef,
  children,
}: {
  displayOnTop: boolean;
  displayContainerOnLeft: boolean;
  containerRef: React.MutableRefObject<null>;
  children: JSX.Element;
}) {
  return (
    <Popover
      id="context-menu-container"
      as="div"
      className="relative inline-block text-left"
    >
      <Popover.Panel
        ref={containerRef}
        static
        className={classNames(
          displayOnTop ? "bottom-full" : "top-full",
          displayContainerOnLeft ? "right-full" : "left-full",
          "absolute w-52 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        )}
      >
        <div className="relative py-1 overflow-visible">{children}</div>
      </Popover.Panel>
    </Popover>
  );
}

export default CalendarContextMenuContainer;
