import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ContentOptions } from "../Coachee/Components/ActivityContent";
import CoacheeActivity from "../Coachee/Components/CoacheeActivity";
import { getTrainingEventById, trainingEvent } from "../Api/TrainingEvent";
import { UserInfoContext } from "../Context/UserContext";
import { IonPage } from "@ionic/react";

function CoacheeActivityContainer() {
  const { userInfo } = useContext(UserInfoContext);
  const location = useLocation();
  const [trainingEvent, setTrainingEvent] = useState<trainingEvent>();

  async function initEvent() {
    const currentLocation = location.pathname;
    const currentLocationArray = currentLocation.split("/");

    const eventId = currentLocationArray[currentLocationArray.length - 2];

    if (userInfo) {
      const event = await getTrainingEventById(
        userInfo.id,
        eventId,
        undefined,
        userInfo.profileMetricSetting
      );

      if (event) {
        setTrainingEvent(event);
      }
    }
  }

  useEffect(() => {
    initEvent();
  }, [userInfo, location.pathname]);

  function renderActivityTab() {
    if (location.pathname.endsWith("plan")) {
      return (
        <CoacheeActivity
          selectedTab={ContentOptions.PLAN}
          trainingEvent={trainingEvent}
        />
      );
    } else if (location.pathname.endsWith("results")) {
      return (
        <CoacheeActivity
          selectedTab={ContentOptions.RESULTS}
          trainingEvent={trainingEvent}
        />
      );
    } else if (location.pathname.endsWith("feedback")) {
      return (
        <CoacheeActivity
          selectedTab={ContentOptions.FEEDBACK}
          trainingEvent={trainingEvent}
        />
      );
    }
  }

  return <IonPage>{renderActivityTab()}</IonPage>;
}

export default CoacheeActivityContainer;
