import { useEffect, useState } from 'react'

import { getTrainingProfiles, searchTrainingProfiles, trainingProfile, } from '../../Api/TrainingProfile'

import {
    editTrainingAttributes,
    getOnboardingProgressDescription,
    onboardingProgressStatus,
    trainingAttributes, updateAthlete,
} from '../../Api/TrainingAttribute'
import { getTrainingAdmins, TrainingAdmin } from '../../Api/TrainingAdmin'
import { toast } from 'react-toastify'
import { PaginationMeta } from '../../Api'
import { Athlete, getAthletes } from '../../Api/Athletes'
import { AthleteOrderBy, OrderDirection } from './useAdminFilter'

export type Assignment = {
    athlete: Athlete;
    coach?: TrainingAdmin;
    dailyUpdatesEnabled: boolean;
    onboardingProgress: number;
    initCoach?: TrainingAdmin;
    initDailyUpdatesEnabled: boolean;
    initOnboardingStatus: number;
};

export type AthleteUpdateAttribute = {
    id: number;
    coachId: string;
    onboardingProgress: number;
    dailyUpdatesEnabled: boolean;
}

function findCoachById(
    adminId: string,
    trainingAdmins: TrainingAdmin[]
): TrainingAdmin | void {
    const foundAdmin = trainingAdmins.find(
        (admin) => admin.miniProfile.id === adminId
    )

    if (foundAdmin) return foundAdmin
}

function getOnboardProgressBackgroundColor(
    trainingProfile: trainingProfile
): string {
    switch (trainingProfile.trainingAttribute.onboardingProgress) {
        case -1:
            return 'bg-sky-100'
        case 1:
            return 'bg-indigo-100'
        case 2:
            return 'bg-amber-100'
        case 3:
            return 'bg-rose-100'
        case 4:
            return 'bg-green-100'

        default:
            return 'bg-gray-100'
    }
}

function getOnboardProgressTextColor(trainingProfile: trainingProfile): string {
    switch (trainingProfile.trainingAttribute.onboardingProgress) {
        case 0:
            return 'text-sky-800'
        case 1:
            return 'text-indigo-800'
        case 2:
            return 'text-amber-800'
        case 3:
            return 'text-rose-800'
        case 4:
            return 'text-green-800'

        default:
            return 'text-gray-800'
    }
}

function getOnboardingStatus(assignment: Assignment): number {
    return assignment.athlete.onboardingProgress
}

function getTrainingProfilesCoach(
    coachId: number,
    coaches: TrainingAdmin[]
): TrainingAdmin | undefined {
    return coaches.find((coach) => coach.miniProfile.numericId === coachId)
}

interface useAdminInput {
    enableTestUsers: boolean;
    filteredOnboardingProgressStatuses: onboardingProgressStatus[];
    searchQuery: string;
    orderBy: AthleteOrderBy;
    orderDir: OrderDirection
}

function useAdmin({
                      enableTestUsers = false,
                      filteredOnboardingProgressStatuses,
                      searchQuery,
                      orderBy,
                      orderDir,
                  }: useAdminInput) {
    type State = {
        athletes: Athlete[],
        meta: PaginationMeta,
        trainingProfiles: trainingProfile[];
        trainingAdmins: TrainingAdmin[];
        assignments: Assignment[];
        isDisabled: boolean;
    };

    const initState: State = {
        athletes: [],
        meta: {} as PaginationMeta,
        trainingProfiles: [],
        trainingAdmins: [],
        assignments: [],
        isDisabled: true,
    }

    const [state, setState] = useState<State>(initState)


    async function refreshAthletes() {
        setState({...state, isDisabled: true, athletes: []})

        getAthletes({
            page: 1,
            perPage: 100,
            search: searchQuery,
            orderBy,
            orderDir,
            toggleTest: Number(enableTestUsers),
            onboardingProgress: filteredOnboardingProgressStatuses.map((e) => e.id).join(',')
        }).then(({data, meta}) => {
            setState({
                ...state,
                isDisabled: false,
                athletes: data,
                meta,
            })
        })
    }

    async function initTrainingAdmins() {
        const trainingAdmins = await getTrainingAdmins()

        if (trainingAdmins) {
            setState((prevState) => (
                {...prevState, trainingAdmins: trainingAdmins}
            ))
        }
    }

    async function initAssignments() {
        let newAssignments: Assignment[] = []

        for (let i = 0; i < state.athletes.length; i++) {

            const assignment: Assignment = {
                athlete: state.athletes[i],
                coach: getTrainingProfilesCoach(
                    state.athletes[i].coachId,
                    state.trainingAdmins
                ),
                dailyUpdatesEnabled: state.athletes[i].dailyUpdatesEnabled,
                onboardingProgress: state.athletes[i].onboardingProgress,
                initOnboardingStatus: state.athletes[i].onboardingProgress,
                initDailyUpdatesEnabled: state.athletes[i].dailyUpdatesEnabled,
                initCoach: getTrainingProfilesCoach(
                    state.athletes[i].coachId,
                    state.trainingAdmins
                ),
            }

            newAssignments = newAssignments.concat([assignment])
        }

        setState({
            ...state,
            assignments: newAssignments,
        })
    }


    async function saveProfileAttribute(assignment: Assignment) {
        let attributes = {id: assignment.athlete.id} as AthleteUpdateAttribute

        if (assignment.dailyUpdatesEnabled !== assignment.initDailyUpdatesEnabled) {
            attributes = {...attributes, dailyUpdatesEnabled: assignment.dailyUpdatesEnabled}
        }
        if (assignment.coach && assignment.coach !== assignment.initCoach) {
            attributes = {
                ...attributes,
                coachId: assignment.coach.id,
            }
        }
        if (assignment.onboardingProgress !== assignment.initOnboardingStatus) {
            attributes = {
                ...attributes,
                onboardingProgress: assignment.onboardingProgress,
            }
        }

        const response = await updateAthlete(attributes)

        if (response) {
            const updatedAssignment: Assignment = {
                ...assignment,
                initCoach: assignment.coach,
                initDailyUpdatesEnabled: assignment.dailyUpdatesEnabled,
                initOnboardingStatus: assignment.onboardingProgress,
            }

            const updatedAssignments = state.assignments

            for (let i = 0; i < updatedAssignments.length; i++) {
                if (updatedAssignments[i].athlete === updatedAssignment.athlete) {
                    updatedAssignments[i] = updatedAssignment
                }
            }

            setState({
                ...state,
                assignments: updatedAssignments,
            })

            toast('Profile attributes have been saved.')
        }
    }

    function updateAthletesCoach(athleteId: string, coachId: string) {
        const foundCoach = findCoachById(coachId, state.trainingAdmins)

        let newAssignments: Assignment[] = []

        for (let i = 0; i < state.assignments.length; i++) {
            const currentAssignment: Assignment = {
                ...state.assignments[i],
            }

            if (
                state.assignments[i].athlete.id.toString() === athleteId &&
                foundCoach
            ) {
                currentAssignment.coach = foundCoach
            }

            newAssignments = newAssignments.concat([currentAssignment])
        }

        setState({...state, assignments: newAssignments})
    }

    function toggleOnboardingProgress(assignment: Assignment, status: number) {
        const updatedAssignment: Assignment = {
            ...assignment,
            onboardingProgress: status,
        }

        const updatedAssignments = state.assignments

        for (let i = 0; i < updatedAssignments.length; i++) {
            if (updatedAssignment.athlete === updatedAssignments[i].athlete) {
                updatedAssignments[i] = updatedAssignment
            }
        }

        setState({...state, assignments: updatedAssignments})
    }

    function toggleAthleteEmail(athleteId: number) {
        let updatedAssignments: Assignment[] = []

        for (let i = 0; i < state.assignments.length; i++) {
            const currentAssignment: Assignment = {
                ...state.assignments[i],
            }

            if (state.assignments[i].athlete.id === athleteId) {
                currentAssignment.dailyUpdatesEnabled = !currentAssignment.dailyUpdatesEnabled
            }

            updatedAssignments = updatedAssignments.concat([currentAssignment])
        }

        setState({
            ...state,
            assignments: updatedAssignments,
        })
    }

    function hasChanged(assignment: Assignment) {
        return assignment.coach !== assignment.initCoach ||
            assignment.dailyUpdatesEnabled !== assignment.initDailyUpdatesEnabled ||
            assignment.onboardingProgress !== assignment.initOnboardingStatus
    }

    useEffect(() => {
        initAssignments()
    }, [state.trainingAdmins])

    useEffect(() => {
        if (state.trainingAdmins.length > 0) {
            initAssignments()
        } else {
            if (state.athletes.length > 0) {
                initTrainingAdmins()
            }
        }
    }, [state.athletes])

    useEffect(() => {
        refreshAthletes()
    }, [searchQuery, filteredOnboardingProgressStatuses, enableTestUsers, orderBy, orderDir])

    return {
        state,
        updateAthletesCoach,
        getOnboardingProgressDescription,
        toggleAthleteEmail,
        hasChanged,
        saveProfileAttribute,
        getOnboardProgressBackgroundColor,
        getOnboardProgressTextColor,
        toggleOnboardingProgress,
    }
}

export default useAdmin
