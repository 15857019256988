import {useContext, useEffect} from "react";
import { Disclosure, Menu } from "@headlessui/react";
import { MenuIcon, XIcon, ChevronLeftIcon } from "@heroicons/react/outline";
import RunzyLogo from "./../../Assets/RunzyLogo.svg";
import useCoacheeHeader from "../Hooks/useCoacheeHeader";
import { classNames } from "../../Utilities/classnames";
import { UserInfoContext } from "../../Context/UserContext";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonRouterLink,
  IonToolbar,
  isPlatform,
  useIonRouter,
} from "@ionic/react";
import { trainingAttributes } from "../../Api/TrainingAttribute";
import { useLocation } from "react-router-dom";
import NoExternalAccountBanner from "./NoExternalAccountBanner";
import Bell from "../../Assets/Bell.svg";
import { isMobile } from "../../Utilities/isMobile";

// import PerformLogo from "../../Assets/logo@2x.png";
import { PlusIcon } from "@heroicons/react/solid";
import { isMobileEmbeddedView } from "../../Api/Auth";
import {useLocalStorage} from 'usehooks-ts';

interface CoacheeHeaderInput {
  trainingAttributes?: trainingAttributes;
  hasScrolled?: boolean;
}

function CoacheeHeader({
  trainingAttributes,
  hasScrolled = false,
}: CoacheeHeaderInput) {
  const { userInfo } = useContext(UserInfoContext);
  const location = useLocation();
  const routerHook = useIonRouter();

  const [isAppMobileFooter, setIsAppMobileFooter] =
      useLocalStorage("isMobileFooter", false);

  useEffect(() => {
    if (routerHook.routeInfo.search.includes("app=mobile") ||
        routerHook.routeInfo.search.includes(encodeURIComponent("app=mobile"))) {
      setIsAppMobileFooter(true)
    }
  }, [])

  const {
    navigation,
    hasNotifications,
    showNotificationIcon,
    canShow,
    navigateHome,
    navigateToNotifications,
  } = useCoacheeHeader(trainingAttributes);

  const navClasses = isPlatform("ios")
    ? "bg-white"
    : "border-b border-[#F0F0F0] sticky bg-white top-0 z-50";

  function hasFullyOnboarded() {
    return trainingAttributes && trainingAttributes.onboardingProgress > 2;
  }

  return (!routerHook.routeInfo.search.includes("app=mobile") &&
      !routerHook.routeInfo.search.includes(encodeURIComponent("app=mobile"))) && !isAppMobileFooter ? (
    !isMobileEmbeddedView() ? (
      <IonHeader className="relative">
        <IonToolbar
          className={classNames(
            hasScrolled ? "border-b border-gray-300" : "",
            "pb-2 mt-2 rounded-t-xl"
          )}
        >
          {isMobile ? (
            <>
              <IonButtons slot="start">
                <IonButton>
                  <img
                    // className="w-auto h-6"
                    className={classNames(
                      isPlatform("ios") ? "mx-auto" : "",
                      "w-auto h-8 ml-3"
                    )}
                    src={RunzyLogo}
                    alt="Runzy"
                  />
                </IonButton>
              </IonButtons>

              <IonButtons slot="end">
                <IonButton>
                  <IonRouterLink
                    routerLink="/notifications"
                    routerDirection="forward"
                  >
                    <button
                      type="button"
                      className="inline-flex items-center justify-center py-2 pl-2 text-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand"
                    >
                      <div className="relative">
                        {showNotificationIcon && (
                          <>
                            <img src={Bell} alt="notifications" />
                            {hasNotifications && (
                              <span className="absolute top-0.5 right-1 block h-1.5 w-1.5 rounded-full ring-2 ring-white bg-rose-400" />
                            )}
                          </>
                        )}
                      </div>
                    </button>
                  </IonRouterLink>
                </IonButton>
                <IonButton>
                  <IonRouterLink
                    routerLink="/activities/create"
                    routerDirection="forward"
                  >
                    <button
                      type="button"
                      className="inline-flex items-center justify-center py-2 pr-2 text-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand"
                    >
                      <PlusIcon className="w-5 h-5 text-gray-600" />
                    </button>
                  </IonRouterLink>
                </IonButton>
              </IonButtons>
            </>
          ) : (
            <>
              <Disclosure as="div" className={navClasses}>
                {({ open }) => (
                  <>
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                      <div className="flex justify-between h-16">
                        <div className="flex">
                          <div className="flex items-center flex-shrink-0">
                            <>
                              {location.pathname.includes("activity") ? (
                                <>
                                  <button onClick={() => routerHook.goBack()}>
                                    <ChevronLeftIcon className="block w-auto h-5 pl-2 text-black cursor-pointer md:hidden" />
                                  </button>
                                </>
                              ) : (
                                <IonRouterLink
                                  routerLink="/dashboard/training-log"
                                  routerDirection="root"
                                >
                                  {/*<a href="https://runzy.com">*/}
                                  <img
                                    className="block w-auto pl-2 cursor-pointer h-9 md:hidden"
                                    src={RunzyLogo}
                                    alt="Runzy"
                                  />
                                </IonRouterLink>
                              )}
                            </>

                            <img
                              onClick={() => {
                                navigateHome();
                              }}
                              className="hidden w-auto cursor-pointer h-7 md:block"
                              src={RunzyLogo}
                              alt="Runzy"
                            />
                          </div>
                          <div className="hidden md:ml-6 md:mt-1.5 md:flex md:items-center md:space-x-4">
                            {navigation.map((item, i) => (
                              <div key={i}>
                                {canShow(item) && (
                                  <a
                                    href={item.href}
                                    key={item.name}
                                    onClick={(e) => {
                                      if (item.action) {
                                        e.preventDefault();
                                        item.action();
                                      }
                                    }}
                                    className={classNames(
                                      item.current
                                        ? "bg-[#FDFDFD] text-black"
                                        : "text-gray-300 hover:bg-gray-300 hover:text-black cursor-pointer",
                                      "px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                                    )}
                                    aria-current={
                                      item.current ? "page" : undefined
                                    }
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="flex items-center justify-end">
                          <div className="flex-shrink-0">
                            <div className="flex items-center -ml-2 md:hidden">
                              {/* Mobile menu button */}
                              <IonRouterLink
                                routerLink="/notifications"
                                routerDirection="forward"
                              >
                                <button
                                  type="button"
                                  className="inline-flex items-center justify-center p-2 text-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand"
                                >
                                  <div className="relative">
                                    {showNotificationIcon && (
                                      <>
                                        <img src={Bell} alt="notifications" />
                                        {hasNotifications && (
                                          <span className="absolute top-0.5 right-1 block h-1.5 w-1.5 rounded-full ring-2 ring-white bg-rose-400" />
                                        )}
                                      </>
                                    )}
                                  </div>
                                </button>
                              </IonRouterLink>

                              {!location.pathname.includes("activity") &&
                                hasFullyOnboarded() && (
                                  <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-600 rounded-md hover:text-white hover:bg-brand focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand">
                                    <span className="sr-only">
                                      Open main menu
                                    </span>
                                    {open ? (
                                      <XIcon
                                        className="block w-6 h-6"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <MenuIcon
                                        className="block w-6 h-6"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </Disclosure.Button>
                                )}
                            </div>
                          </div>

                          <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                            <button
                              type="button"
                              className="inline-flex items-center justify-center p-2 text-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand"
                              onClick={() => navigateToNotifications()}
                            >
                              <div className="relative">
                                {showNotificationIcon && (
                                  <>
                                    <img src={Bell} alt="notifications" />

                                    {hasNotifications && (
                                      <>
                                        <span className="absolute top-0.5 animate-ping opacity-75 right-1 block h-1.5 w-1.5 rounded-full ring-2 ring-rose-300 bg-rose-300" />
                                        <span className="absolute top-0.5 right-1 block h-1.5 w-1.5 rounded-full ring-2 ring-rose-400 bg-rose-400" />
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </button>
                            <Menu as="div" className="relative ml-3">
                              <div>
                                {hasFullyOnboarded() && (
                                  <Menu.Button
                                    onClick={() => {
                                      if (
                                        trainingAttributes &&
                                        trainingAttributes.onboardingProgress >=
                                          3
                                      ) {
                                        routerHook.push("/profile");
                                      }
                                    }}
                                    className={classNames(
                                      trainingAttributes &&
                                        trainingAttributes.onboardingProgress >=
                                          3
                                        ? "cursor-pointer"
                                        : "cursor-default",
                                      "flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brand focus:ring-white"
                                    )}
                                  >
                                    <span className="sr-only">
                                      Open user menu
                                    </span>
                                    {userInfo ? (
                                      <img
                                        className="w-8 h-8 rounded-full"
                                        src={userInfo.profileImage}
                                        alt=""
                                      />
                                    ) : (
                                      <span className="inline-block w-8 h-8 overflow-hidden bg-gray-100 rounded-full">
                                        <svg
                                          className="w-full h-full text-gray-300"
                                          fill="currentColor"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                        </svg>
                                      </span>
                                    )}
                                  </Menu.Button>
                                )}
                              </div>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    </div>

                    {!isMobile && (
                      <Disclosure.Panel className="md:hidden">
                        <div className="px-2 pt-2 space-y-1 sm:px-3">
                          {navigation.map((item, i) => (
                            <div key={i}>
                              {canShow(item) && (
                                <>
                                  {item.href ? (
                                    <>
                                      <Disclosure.Button
                                        key={item.name}
                                        href={item.href}
                                        as="a"
                                        className={classNames(
                                          item.current
                                            ? "bg-gray-100 text-black"
                                            : "text-gray-700 hover:bg-gray-300 hover:text-black",
                                          "block px-3 py-2 rounded-md text-base font-medium w-full text-left"
                                        )}
                                        aria-current={
                                          item.current ? "page" : undefined
                                        }
                                      >
                                        {item.name}
                                      </Disclosure.Button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        key={item.name}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (item.action) {
                                            item.action();
                                          }
                                        }}
                                        className={classNames(
                                          item.current
                                            ? "bg-[#FDFDFD] text-black"
                                            : "text-gray-300 hover:bg-gray-300 hover:text-black",
                                          "block px-3 py-2 rounded-md text-base font-medium w-full text-left"
                                        )}
                                        aria-current={
                                          item.current ? "page" : undefined
                                        }
                                      >
                                        {item.name}
                                      </button>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="">
                          <div className="px-2 pb-3 space-y-1 sm:px-3"></div>
                        </div>
                      </Disclosure.Panel>
                    )}
                  </>
                )}
              </Disclosure>
              {trainingAttributes &&
                trainingAttributes.onboardingProgress >= 3 &&
                !trainingAttributes.stravaProfileUrl &&
                !routerHook.routeInfo.pathname.includes("connect-strava") && (
                  <div className="absolute inset-0 z-10 top-1/2">
                    <NoExternalAccountBanner />
                  </div>
                )}
            </>
          )}
        </IonToolbar>
      </IonHeader>
    ) : (
      <></>
    )
  ) : null;
}

export default CoacheeHeader;
