import { trainingEvent } from "../../Api/TrainingEvent";
import { useUserInfo } from "../../Context/UserContext";
import { getTodaysEvents } from "../Components/DashboardContent";
import TodaysEvent from "../Components/TodaysEvent";
import Pencil from "../../Assets/Pencil.svg";
import { getNextWeeksEvents, getThisWeeksEvents } from "./util";
import ThisWeeksEvent from "./ThisWeeksEvent";
import NextWeeksEvent from "./NextWeeksEvent";
import Spinner from "../Components/Spinner";
import { isMobile } from "../../Utilities/isMobile";

function UpcomingContent({
  events,
  setShowModal,
  isLoading,
}: {
  events?: trainingEvent[];
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}) {
  const { userInfo } = useUserInfo();

  return (
    <>
      {!isMobile && (
        <h1 className="mx-4 mt-5 text-left text-[28px] mb-2 flex items-center justify-between font-semibold">
          Schedule
        </h1>
      )}

      {userInfo && events && getTodaysEvents(events) && (
        <div className="px-4 pt-4">
          <TodaysEvent event={getTodaysEvents(events)} userInfo={userInfo} />
        </div>
      )}
      {events && (
        <>
          <div className="flex items-center justify-between px-4 pt-4">
            <div className="flex items-center gap-2">
              <h2 className="font-semibold text-[16px] text-black">
                This Week
              </h2>
              {isLoading && <Spinner isCentered={false} />}
            </div>

            {isMobile && (
              <button onClick={() => setShowModal(true)}>
                <img src={Pencil} alt="Edit upcoming workouts" />
              </button>
            )}
          </div>
          <div className="px-4 pt-4 space-y-4">
            {getThisWeeksEvents(events)
              .filter(
                (event) => event.scheduledAt.getTime() > new Date().getTime()
              )
              .map((event) => (
                <ThisWeeksEvent event={event} />
              ))}
          </div>
        </>
      )}
      {events && getNextWeeksEvents(events).length > 0 && (
        <div className="mt-4 border-t border-neutral-300">
          <div className="px-4 pt-6">
            <h2 className="font-semibold text-[16px] text-black">Upcoming</h2>
          </div>
          {events && (
            <div className="px-4 pt-4 space-y-4">
              {getNextWeeksEvents(events)
                .filter(
                  (event) => event.scheduledAt.getTime() > new Date().getTime()
                )
                .map((event) => (
                  <NextWeeksEvent event={event} />
                ))}
            </div>
          )}
        </div>
      )}
      {!events && <Spinner />}
    </>
  );
}

export default UpcomingContent;
