import { StatusBar, Style } from "@capacitor/status-bar";
import { isPlatform } from "@ionic/core";

function useCapacitorStatusBar() {
  const setStatusBarStyleDark = async () => {
    if(isPlatform('capacitor')){
      await StatusBar.setStyle({ style: Style.Dark });
    }
  };

  const setStatusBarStyleLight = async () => {
    if(isPlatform('capacitor')) {
      await StatusBar.setStyle({ style: Style.Light });
    }
  };

  return { setStatusBarStyleDark, setStatusBarStyleLight };
}

export default useCapacitorStatusBar;
