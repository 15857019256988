import {useContext} from "react";
import {signOut} from "../../Api/User";
import {UserInfoContext} from "../../Context/UserContext";
import {isMobileEmbeddedView, resetAuth} from "../../Api/Auth";

const useAuth = () => {
  const { setUserInfo } = useContext(UserInfoContext);

  const handleSignOut = async () => {
    const response = await signOut();
    if (response) {
      if (isMobileEmbeddedView()) {
        window.location.href = '/sign-out-redirect';
      } else {
        setUserInfo(null);
        resetAuth();
        window.location.href = "/sign-in";
      }
    }
  };

  return {
    handleSignOut,
  };
};

export default useAuth;
