import TrainingEvents from "../Components/TrainingEvents";
import { trainingProfile } from "../../Api/TrainingProfile";

interface ActivityInput {
  selectedTrainingProfile: trainingProfile;
}

function Activity({ selectedTrainingProfile }: ActivityInput) {
  return (
    <>
      {selectedTrainingProfile && (
        <>
          <TrainingEvents selectedTrainingProfile={selectedTrainingProfile} />
        </>
      )}
    </>
  );
}

export default Activity;
