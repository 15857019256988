import { useLocation } from "react-router-dom";
import { trainingAttributes } from "../../Api/TrainingAttribute";
import { trainingEvent } from "../../Api/TrainingEvent";
import { useActivityFeedbackOutput } from "../Hooks/useActivityFeedback";
import ActivityContentSlider from "./ActivityContentSlider";
import ActivityFeedback from "../Activity/Feedback/ActivityFeedback";
import ActivityPlan from "./ActivityPlan";
import ActivityResults from "../Activity/Results/ActivityResults";

interface ActivityContentInput {
  trainingEvent: trainingEvent;
  trainingAttributes: trainingAttributes;
  selectedTab: ContentOptions;
  activityFeedback: useActivityFeedbackOutput;
}

export enum ContentOptions {
  PLAN,
  RESULTS,
  FEEDBACK,
}

function ActivityContent({
  trainingEvent,
  trainingAttributes,
  selectedTab,
  activityFeedback,
}: ActivityContentInput) {
  const location = useLocation();

  const currentOption = () => {
    if (location.pathname.includes("plan")) {
      return ContentOptions.PLAN;
    } else if (location.pathname.includes("results")) {
      return ContentOptions.RESULTS;
    } else {
      return ContentOptions.FEEDBACK;
    }
  };

  return (
    <div className="relative flex flex-col w-full font-poppins grow">
      <div className="flex flex-col h-full px-6 text-left md:h-3/4">
        <>
          <ActivityContentSlider option={selectedTab} />

          {selectedTab === ContentOptions.PLAN && (
            <ActivityPlan
              trainingAttributes={trainingAttributes}
              trainingEvent={trainingEvent}
              contentOption={currentOption()}
            />
          )}

          {selectedTab === ContentOptions.RESULTS && (
            <ActivityResults trainingEvent={trainingEvent} />
          )}
        </>
      </div>

      {selectedTab === ContentOptions.FEEDBACK && (
        <ActivityFeedback activityFeedback={activityFeedback} />
      )}
    </div>
  );
}

export default ActivityContent;
