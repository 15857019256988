import { StreamClient } from "getstream";
import { useHistory } from "react-router-dom";
import { Post } from "../../Api/Stream";
import { trainingProfile } from "../../Api/TrainingProfile";
import ActivityDropdown from "../../Coach/Components/ActivityDropdown";
import { useUserInfo } from "../../Context/UserContext";
import { classNames } from "../../Utilities/classnames";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";
import { formatNotificationDate } from "../../Utilities/DateUtilities";
import useCoachPost from "../Hooks/useCoachPost";
import StreamActivityReaction from "./StreamActivityReaction";
import StreamEditBox from "./StreamEditBox";

interface CoachPostInput {
  selectedTrainingProfile: trainingProfile;
  post: Post;
  streamClient: StreamClient;
  activateDeleteModal: (postId: string) => void;
}

function CoachPost({
  selectedTrainingProfile,
  post,
  streamClient,
  activateDeleteModal,
}: CoachPostInput) {
  const coachPostHook = useCoachPost({
    post: post,
    streamClient: streamClient,
  });

  const { userInfo } = useUserInfo();
  const history = useHistory();

  if (!userInfo) return <></>;

  return (
    <div
      onClick={(e: any) => {
        e.preventDefault();
        history.push(
          `/coach/${selectedTrainingProfile.miniProfile.id}/posts/${
            post.id + post.foreign_id
          }`
        );
      }}
      className="flex flex-col w-full gap-2 bg-white rounded-lg cursor-pointer"
    >
      <div
        className={classNames(
          coachPostHook.editMode ? "" : "border-b border-gray-200",
          "px-4 py-6"
        )}
      >
        <div className="flex items-start justify-between gap-2">
          <div className="flex items-center gap-2">
            <ProfilePicture
              url={post.actorMiniProfile.profilePicture}
              alt=""
              classNames="self-start mt-1 rounded-lg w-9 h-9"
            />

            {!coachPostHook.editMode && (
              <div>
                <div className="flex items-center gap-2">
                  <div className="font-medium">
                    {post.actor === streamClient.userId ? (
                      <p>Coach {userInfo.firstName}</p>
                    ) : (
                      <p>{post.actorMiniProfile.name}</p>
                    )}
                  </div>
                  <p className="text-xs text-gray-400">
                    {formatNotificationDate(new Date(post.published), true)}
                  </p>
                </div>

                <p className="text-sm whitespace-pre-line line-clamp-3 ">
                  {post.message}
                </p>
              </div>
            )}
          </div>
          {coachPostHook.editMode ? (
            <div className="relative grow" onClick={(e) => e.stopPropagation()}>
              <StreamEditBox
                text={coachPostHook.postContent}
                setText={coachPostHook.setPostContent}
                onCancel={coachPostHook.cancelEdit}
                onSave={coachPostHook.savePost}
              />
            </div>
          ) : (
            <>
              {userInfo &&
                userInfo.miniProfile.id ===
                  post.actorMiniProfile.id.toString() && (
                  <ActivityDropdown
                    actions={[
                      { key: "Edit", action: coachPostHook.enableEditMode },
                      {
                        key: "Delete",
                        action: () => activateDeleteModal?.(post.id),
                      },
                    ]}
                  />
                )}
            </>
          )}
        </div>
      </div>
      {!coachPostHook.editMode && (
        <StreamActivityReaction streamClient={streamClient} activity={post} />
      )}
    </div>
  );
}

export default CoachPost;
