import { Fragment, useState, useEffect } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ReactComponent as SearchIcon } from "./../../Assets/search.svg";
import { Filter } from "../Hooks/useAdminFilter";
import { onboardingProgressStatus } from "../../Api/TrainingAttribute";
import { classNames } from "../../Utilities/classnames";
import { useLocation, useHistory } from "react-router-dom";
import SmallToggle from "./SmallToggle";

interface AdminFilterInput {
  enableTestUsers: boolean;
  toggleTestUsers: () => void;
  filters: Filter[];
  filteredOnboardingProgressStatuses: onboardingProgressStatus[];
  toggleFilteredOnboardingProgressStatuses: (status: number) => void;
  toggleSearch: (searchQuery: string) => void;
  results: number;
}

function AdminFilter({
  enableTestUsers,
  toggleTestUsers,
  filters,
  filteredOnboardingProgressStatuses,
  toggleFilteredOnboardingProgressStatuses,
  toggleSearch,
  results,
}: AdminFilterInput) {
  const location = useLocation();

  const initialQuery = new URLSearchParams(location.search).get("query");

  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(initialQuery || "");

  function filterIsEnabled(option: onboardingProgressStatus): boolean {
    for (let i = 0; i < filteredOnboardingProgressStatuses.length; i++) {
      if (filteredOnboardingProgressStatuses[i].id === option.id) return true;
    }

    return false;
  }

  function handleSearch() {
    toggleSearch(searchQuery);
  }

  useEffect(() => {
    setSearchQuery(initialQuery || "");
  }, [location]);

  const history = useHistory();


  return (
    <div className="bg-white">
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex sm:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="relative flex flex-col w-full h-full max-w-xs py-4 pb-12 ml-auto overflow-y-auto bg-white shadow-xl">
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  className="flex items-center justify-center w-10 h-10 p-2 -mr-2 text-gray-400 bg-white rounded-md"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4">
                {filters.map((section) => (
                  <Disclosure
                    as="div"
                    key={section.name}
                    className="px-4 py-6 border-t border-gray-200"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="flow-root -mx-2 -my-3">
                          <Disclosure.Button className="flex items-center justify-between w-full px-2 py-3 text-sm text-gray-400 bg-white">
                            <span className="font-medium text-gray-900">
                              {section.name}
                            </span>
                            <span className="flex items-center ml-6">
                              <ChevronDownIcon
                                className={classNames(
                                  open ? "-rotate-180" : "rotate-0",
                                  "h-5 w-5 transform"
                                )}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-6">
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.id}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-mobile-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.id}
                                  type="checkbox"
                                  checked={filterIsEnabled(option)}
                                  onChange={() => {
                                    toggleFilteredOnboardingProgressStatuses(
                                      option.id
                                    );
                                  }}
                                  className="w-4 h-4 border-gray-300 rounded text-brand focus:ring-brand"
                                />
                                <label
                                  htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-500"
                                >
                                  {option.description}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* Filters */}
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>

        <div className="relative z-10 pb-4 bg-white border-b border-gray-200">
          <div className="flex items-center justify-between px-4 pt-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <button
              type="button"
              className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
              onClick={() => setOpen(true)}
            >
              Filters
            </button>
            <div className="flex text-sm items-left">
              <b>{results}</b>&nbsp;results
            </div>
            <div className="flex text-sm items-left">
              <input
                type="search"
                className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-sm text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-l transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brand focus:outline-none"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                value={searchQuery}
                placeholder="Search"
                aria-label="Search"
                aria-describedby="button-addon2"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <button
                className="btn inline-block px-4 py-2.5 bg-brand text-white font-medium text-xs leading-tight uppercase rounded-r shadow-md hover:bg-brand-dark hover:shadow-lg focus:bg-brand-dark focus:shadow-lg focus:outline-none focus:ring-0 active:bg-brand active:shadow-lg transition duration-150 ease-in-out flex items-center"
                type="button"
                id="button-addon2"
                onClick={handleSearch}
              >
                <SearchIcon
                  style={{ fill: "#fff", width: "17px", height: "17px" }}
                  aria-hidden="true"
                />
              </button>
            </div>
            <div className="flex items-center gap-2">
              Show test users
              <SmallToggle
                isEnabled={enableTestUsers}
                setIsEnabled={() => toggleTestUsers()}
              />
            </div>
            <div className="hidden sm:block">
              <div className="flow-root">
                <Popover.Group className="flex items-center -mx-4 divide-x divide-gray-200">
                  {filters.map((section, sectionIdx) => (
                    <Popover
                      key={section.name}
                      className="relative inline-block px-4 text-left"
                    >
                      <Popover.Button className="inline-flex justify-center text-sm font-medium text-gray-700 group hover:text-gray-900">
                        <span>{section.name}</span>
                        {sectionIdx === 0 ? (
                          <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                            {filteredOnboardingProgressStatuses.length}
                          </span>
                        ) : null}
                        <ChevronDownIcon
                          className="flex-shrink-0 w-5 h-5 ml-1 -mr-1 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="absolute right-0 p-4 mt-2 origin-top-right bg-white rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <form className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.id}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.id}
                                  type="checkbox"
                                  checked={filterIsEnabled(option)}
                                  onChange={() => {
                                    toggleFilteredOnboardingProgressStatuses(
                                      option.id
                                    );
                                  }}
                                  className="w-4 h-4 border-gray-300 rounded text-brand focus:ring-brand"
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="pr-6 ml-3 text-sm font-medium text-gray-900 whitespace-nowrap"
                                >
                                  {option.description}
                                </label>
                              </div>
                            ))}
                          </form>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ))}
                </Popover.Group>
              </div>
            </div>
            <div>
              <a
                href="/admin/create-user"
                // onClick={() => history.push("/admin/create-user")}
                className="inline-block text-sm font-medium bg-brand text-white px-4 py-2 rounded"
              >
                Add new user
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdminFilter;
