import { ACCOUNT_PROVIDER, ExternalAccount } from "../../../../Types";

export interface Preference {
  text: string;
  value: boolean | undefined;
  updateField: string | null;
  editable: boolean;
}

export const preferencesForAccount = (
  account: ExternalAccount
): Preference[] => {
  if (account.provider === ACCOUNT_PROVIDER.STRAVA) {
    const { stravaPreferences } = account;
    return [
      {
        text: "Enable Runzy to update your Strava activities with stats.",
        value: stravaPreferences?.updatesEnabled,
        updateField: "update",
        editable: true,
      },
      {
        text: "Allow Runzy to rename your Strava activities.",
        value: stravaPreferences?.titleUpdatesEnabled,
        updateField: "title",
        editable: true,
      },
      {
        text: "Allow Runzy to add workout streaks to your activity descriptions.",
        value: stravaPreferences?.streakUpdatesEnabled,
        updateField: "streak",
        editable: true,
      },
      {
        text: "Allow Runzy to add workout scores to your activity descriptions.",
        value: stravaPreferences?.scoreUpdatesEnabled,
        updateField: "score",
        editable: true,
      },
      {
        text: "Allow Runzy to add goal countdowns to your activity descriptions.",
        value: stravaPreferences?.goalUpdatesEnabled,
        updateField: "goal",
        editable: true,
      },
    ];
  } else {
    return [
      {
        text: `Runzy is able to view your ${account.provider} activities with stats.`,
        value: true,
        editable: false,
        updateField: null,
      },
    ];
  }
};
