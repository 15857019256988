import { useState, useContext, useEffect } from "react";
import { trainingEvent, TrainingEventStatus } from "../../Api/TrainingEvent";
import { UserInfoContext } from "../../Context/UserContext";
import { getDayDifference } from "../../Utilities/DateUtilities";
import { connect, StreamClient, ReactionAPIResponse } from "getstream";
import Default from "../../Default/Default";
import * as DOMPurify from "dompurify";

interface useCoachsNoteInput {
  trainingEvent: trainingEvent;
}

function useCoachsNote({ trainingEvent }: useCoachsNoteInput) {
  const [coachNoteIsExpanded, setCoachNoteIsExpanded] =
    useState<boolean>(false);
  const { userInfo } = useContext(UserInfoContext);
  const [streamClient, setStreamClient] = useState<StreamClient>();
  const [coachsFeedback, setCoachsFeedback] = useState<ReactionAPIResponse>();

  function handleExpandCoachNote() {
    if (!coachNoteIsExpanded) {
      setCoachNoteIsExpanded(true);
    }
  }

  function createNoteMarkup(shortened?: boolean) {
    const firstSegment = trainingEvent.message.split("</p>")[0];
    const message = shortened
      ? firstSegment.replaceAll("<p><br></p>", "")
      : trainingEvent?.message.replaceAll(/\n/g, "<br />");
    return message;
  }

  function createFeedbackMarkup() {
    const sanitizedMessage =
      DOMPurify.sanitize(coachsFeedback?.data.text as string) ?? "";

    return { __html: sanitizedMessage };
  }

  function hasCoachesNote(): boolean {
    if (
      trainingEvent.message &&
      trainingEvent.message.length > 0 &&
      trainingEvent.message !== "<p><br></p>" &&
      getDayDifference(trainingEvent.scheduledAt) === -1
    ) {
      return true;
    }
    return false;
  }

  function hasCoachesFeedback(): boolean {
    if (
      coachsFeedback &&
      (coachsFeedback.data.text as string).length > 0 &&
      (coachsFeedback.data.text as string) !== "<p><br></p>" &&
      (trainingEvent.status === TrainingEventStatus.COMPLETED ||
        trainingEvent.status === TrainingEventStatus.MISSED)
    ) {
      return true;
    }
    return false;
  }

  function initStreamClient() {
    if (userInfo) {
      const client = connect(
        Default.streamsApiKey,
        userInfo.streamAPIAuth,
        Default.streamsAppId
      );

      setStreamClient(client);
    }
  }

  async function initCoachFeedback() {
    if (streamClient) {
      if (trainingEvent.feedActivityId) {
        const comments = await streamClient.reactions.filter({
          activity_id: trainingEvent.feedActivityId,
          kind: "comment",
        });

        if (comments.results.length > 0) {
          setCoachsFeedback(comments.results.reverse()[0]);
        }
      }
    }
  }

  useEffect(() => {
    initCoachFeedback();
  }, [streamClient]);

  useEffect(() => {
    initStreamClient();
  }, [userInfo]);

  return {
    hasCoachesNote,
    handleExpandCoachNote,
    coachNoteIsExpanded,
    createNoteMarkup,
    userInfo,
    createFeedbackMarkup,
    hasCoachesFeedback,
    coachsFeedback,
  };
}

export default useCoachsNote;
