import { StepType } from "../../../../Api/Step";
import { UnitPreference } from "../../../../Api/User";
import { useUserInfo } from "../../../../Context/UserContext";

interface Props {
  value: string;
  onChange: (value: string) => void;
  editorType: StepType;
}

export default function ValueField({ value, onChange, editorType }: Props) {
  const { userInfo } = useUserInfo();

  const modifiedValue = value.split(":");
  const hours = modifiedValue[0];
  const minutes = modifiedValue[1];
  const seconds = modifiedValue[2];

  const handleHoursChange = (value: string) => {
    // early return if the value is not a number or is greater than 23
    if (isNaN(Number(value)) || Number(value) > 23 || value.includes("-"))
      return;

    const newValue = `${value}:${minutes}:${seconds}`;

    onChange(newValue);
  };

  const handleMinutesChange = (value: string) => {
    // early return if the value is not a number or is greater than 59
    if (isNaN(Number(value)) || Number(value) > 59 || value.includes("-"))
      return;

    const newValue = `${hours}:${value}:${seconds}`;
    onChange(newValue);
  };

  const handleSecondsChange = (value: string) => {
    // early return if the value is not a number or is greater than 59
    if (isNaN(Number(value)) || Number(value) > 59 || value.includes("-"))
      return;

    const newValue = `${hours}:${minutes}:${value}`;
    onChange(newValue);
  };

  if (editorType === StepType.DISTANCE) {
    return (
      <div className="mt-[14px] border-b border-neutral-300 flex items-center justify-between">
        <input
          type="text"
          className="text-neutral-700 text-[14px] leading-[20px] w-full border-none focus:outline-none focus:ring-0"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="0.00"
        />
        {userInfo && (
          <label
            htmlFor="stepType"
            className="text-neutral-500 text-[14px] leading-[20px]"
          >
            {userInfo.profileMetricSetting === UnitPreference.METRIC
              ? "Kilometers"
              : "Miles"}
          </label>
        )}
      </div>
    );
  }

  //   return three number inputs for hours, minutes, seconds
  return (
    <div className="mt-[14px] border-b border-neutral-300 flex items-center justify-between">
      <div className="flex items-center justify-center flex-grow">
        <input
          // prevent scrolling from changing the value
          onWheel={(e) => e.currentTarget.blur()}
          type="number"
          className="text-neutral-700 pr-0 text-[14px] leading-[20px] border-none focus:outline-none focus:ring-0"
          value={hours}
          onChange={(e) => handleHoursChange(e.target.value)}
          placeholder="0"
          max={99}
          min={0}
          defaultValue={0}
        />
        <label
          htmlFor="stepType"
          className="text-neutral-500 text-[14px] leading-[20px]"
        >
          Hours
        </label>
      </div>

      <div className="flex items-center justify-center flex-grow">
        <input
          // prevent scrolling from changing the value
          onWheel={(e) => e.currentTarget.blur()}
          type="number"
          className="text-neutral-700  pr-0 text-[14px] leading-[20px] border-none focus:outline-none focus:ring-0"
          value={minutes}
          onChange={(e) => handleMinutesChange(e.target.value)}
          placeholder="0"
          max={59}
          min={0}
          defaultValue={0}
        />
        <label
          htmlFor="stepType"
          className="text-neutral-500 text-[14px] leading-[20px]"
        >
          Minutes
        </label>
      </div>

      <div className="flex items-center justify-center flex-grow">
        <input
          // prevent scrolling from changing the value
          onWheel={(e) => e.currentTarget.blur()}
          type="number"
          className="text-neutral-700  pr-0 text-[14px] leading-[20px] border-none focus:outline-none focus:ring-0"
          value={seconds}
          onChange={(e) => handleSecondsChange(e.target.value)}
          placeholder="0"
          max={59}
          min={0}
          defaultValue={0}
        />
        <label
          htmlFor="stepType"
          className="text-neutral-500 text-[14px] leading-[20px]"
        >
          Seconds
        </label>
      </div>
    </div>
  );
}
