import { useState } from "react";
import DetectableOverflow from "react-detectable-overflow";
import { trainingEvent } from "../../Api/TrainingEvent";

interface TitleCellInput {
  currentTitle: string;
  event: trainingEvent;
}

function TitleCell({ event, currentTitle }: TitleCellInput) {
  const [titleIsHovered, setTitleIsHovered] = useState<boolean>(false);
  const [titleShowMore, setTitleShowMore] = useState(false);

  function handleTitleMouseOver() {
    setTitleIsHovered(true);
  }

  function handleTitleMouseOut() {
    setTitleIsHovered(false);
  }

  function handleTitleOverFlow() {
    if (!titleShowMore) setTitleShowMore(true);
  }

  return (
    <td
      onMouseOver={() => handleTitleMouseOver()}
      onMouseOut={() => handleTitleMouseOut()}
      className={
        titleShowMore && titleIsHovered
          ? "relative max-w-[170px] px-4 py-2 text-sm text-left text-gray-600"
          : "w-10 max-w-[170px] px-4 py-2 text-sm text-left text-gray-600 "
      }
    >
      {!event.id.includes("activity") && (
        <>
          <DetectableOverflow
            onChange={() => handleTitleOverFlow()}
            style={{}}
            className="truncate"
          >
            {currentTitle}
          </DetectableOverflow>
          <div
            className={
              titleShowMore && titleIsHovered ? "card-hover" : "hidden"
            }
          >
            {currentTitle}
          </div>
        </>
      )}
    </td>
  );
}

export default TitleCell;
