import { useTrainingProfileOutput } from "./useTrainingProfile";
import { classNames } from "../../Utilities/classnames";
import ComboBox from "../Combobox/ComboBox";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

import AthleteQuickOverview from "./AthleteQuickOverview";
import { Link } from "react-router-dom";

function CoachingSidebar({
  trainingProfileHook,
}: {
  trainingProfileHook: useTrainingProfileOutput;
}) {
  return (
    <div className="z-20 hidden w-full h-full min-h-screen px-4 py-14 bg-white border-l border-neutral-100 sm:px-6 lg:px-1 lg:block lg:justify-end ">
      <nav
        aria-label="Sidebar"
        className="sticky divide-y divide-gray-300 top-24"
      >
        <div className="w-full">
          {/* <span className="relative z-0 inline-flex pb-2 rounded-md shadow-sm">
            <button
              onClick={() => trainingProfileHook.getPreviousTrainingProfile()}
              type="button"
              className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-brand focus:border-brand"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
            </button>
            <button
              onClick={() => trainingProfileHook.getNextTrainingProfile()}
              type="button"
              className="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-brand focus:border-brand"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </span> */}
          {trainingProfileHook.trainingProfiles && (
            <div className="w-full pb-4">
              <ComboBox
                onChange={(trainingProfileId: string) => {
                  if (trainingProfileHook.trainingProfiles)
                    trainingProfileHook.updateNavRoute(
                      trainingProfileHook.trainingProfiles.find(
                        (profile) =>
                          profile.miniProfile.id === trainingProfileId
                      )
                    );
                }}
                selected={trainingProfileHook.selectedProfile}
                options={trainingProfileHook.trainingProfiles}
                detailedOptions={trainingProfileHook.detailedOptions}
              />
            </div>
          )}
          {trainingProfileHook.navigation.map((item, i) => (
            <div key={i} className="overflow-hidden">
              {item.isShown && (
                <Link
                  replace
                  key={item.name}
                  to={`/coach/${trainingProfileHook.selectedProfileId}/${item.href}`}
                >
                  <div
                    className={classNames(
                      item.current
                        ? "bg-gray-200 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50",
                      "group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    {item.icon}
                    <span className="truncate">{item.name}</span>
                  </div>
                </Link>
              )}
            </div>
          ))}
        </div>
      </nav>
    </div>
  );
}

export default CoachingSidebar;
