import { useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import Options from "../../Assets/Options.svg";

interface DropdownProps {
  onEdit?: Function;
  onDelete: Function;
  onDuplicate: Function;
}

function Dropdown({ onEdit, onDelete, onDuplicate }: DropdownProps) {
  const [menuShown, setMenuShown] = useState<boolean>(false);

  function closeDropdown() {
    if (!menuShown) {
      setMenuShown(false);
    }
  }

  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  function handleClick(e: any) {
    e.stopPropagation();
    setMenuShown(!menuShown);
  }

  return (
    <div ref={ref} className="relative" onClick={(e) => handleClick(e)}>
      {menuShown ? (
        <>
          <div>
            <button
              type="button"
              className=".btn-dropdown invisible"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
            >
              <span></span>
              <img className="w-8 h-8" src={Options} alt="Options" />
            </button>
          </div>
          <div
            className="dropdown-show"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
          >
            {onEdit && (
              <div className="py-1 cursor-pointer" role="none">
                <a
                  className="dropdown-option"
                  role="menuitem"
                  onClick={() => {
                    onEdit();
                  }}
                >
                  Edit
                </a>
              </div>
            )}

            <div className="py-1 cursor-pointer" role="none">
              <a
                className="dropdown-option"
                role="menuitem"
                onClick={() => {
                  onDelete();
                }}
              >
                Delete
              </a>
            </div>
            <div className="py-1 cursor-pointer" role="none">
              <a
                className="dropdown-option"
                role="menuitem"
                onClick={() => {
                  onDuplicate();
                }}
              >
                Duplicate
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="relative z-0 inline-block">
            <div>
              <button
                type="button"
                className=".btn-dropdown"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                <span></span>
                <img className="w-8 h-8" src={Options} alt="Options" />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default Dropdown;
