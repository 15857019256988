import axios from "axios";
import { ActivityMetadata } from "./ActivityRoute";

export interface createActivityInput {
  name: string;
  description: string;
  startedAt: number;
  lengthSeconds: number;
  activityType: string;
  distanceInMeters: number;
}

export enum ActivityType {
  RUN = "RUN",
  INDOOR_RUN = "INDOOR_RUN",
  RIDE = "RIDE",
  INDOOR_RIDE = "INDOOR_RIDE",
  WORKOUT = "WORKOUT",
  WALK = "WALK",
  SWIM = "SWIM",
  YOGA = "YOGA",
  ROWING = "ROWING",
  ROCK_CLIMBING = "ROCK_CLIMBING",
  SOCCER = "SOCCER",
  OTHER = "OTHER",
}

export interface ActivityTypeAndName {
  name: string;
  type: ActivityType;
}

export const activityTypeLocalization: ActivityTypeAndName[] = [
  {
    type: ActivityType.RUN,
    name: "Run",
  },
  {
    type: ActivityType.INDOOR_RUN,
    name: "Indoor Run",
  },
  {
    type: ActivityType.RIDE,
    name: "Ride",
  },
  {
    type: ActivityType.INDOOR_RIDE,
    name: "Indoor Ride",
  },
  {
    type: ActivityType.WORKOUT,
    name: "Strength Training",
  },
  {
    type: ActivityType.WALK,
    name: "Walk",
  },
  {
    type: ActivityType.SWIM,
    name: "Swim",
  },
  {
    type: ActivityType.YOGA,
    name: "Yoga",
  },
  {
    type: ActivityType.ROWING,
    name: "Rowing",
  },
  {
    type: ActivityType.ROCK_CLIMBING,
    name: "Rock Climbing",
  },
  {
    type: ActivityType.SOCCER,
    name: "Soccer",
  },
  {
    type: ActivityType.OTHER,
    name: "Other",
  },
];

export function isDistanceBased(activityType: ActivityType): boolean {
  return (
    activityType == ActivityType.RUN ||
    activityType == ActivityType.INDOOR_RUN ||
    activityType == ActivityType.RIDE ||
    activityType == ActivityType.INDOOR_RIDE
  );
}
// export const Activity

export async function getActivities(day: Date, profileId: number) {
  const params = {
    limit: 1,
    offset: 100,
    filter: "day",
    day: day.toISOString().split("T")[0],
    profile: `perform:profile:${profileId}`,
  };

  try {
    const response = await axios.get("activities", { params: params });
    return response.data.items as ActivityMetadata[];
  } catch (e) {
    return null;
  }
}

export async function createActivity(payload: createActivityInput) {
  try {
    const response = await axios.post("activities", payload);

    return response.data as ActivityMetadata;
  } catch (e) {
    return null;
  }
}

export async function deleteActivity(activity: ActivityMetadata) {
  try {
    const id = activity.id.split(":").pop();
    await axios.delete(`activities/${id}`);
    return 200;
  } catch (e) {
    return null;
  }
}
