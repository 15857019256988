export function MusicFooter() {
  return (<footer className="bg-white pb-6" aria-labelledby="footer-heading">
    <h2 id="footer-heading" className="sr-only">Footer</h2>
    <div className="pt-8 mt-4 justify-between columns-2 flex max-w-7xl mx-auto px-4 sm:px-6 flex-wrap">
      <div className="text-sm text-gray-400 xl:text-left">
        Built with ❤️ &nbsp;in San Francisco &nbsp;&nbsp; | &nbsp;&nbsp; <a href="https://joinperform.com/privacy-policy/" target="_blank"
                                                                            className="hover:text-gray-900">Privacy</a> &nbsp;&nbsp; | &nbsp;&nbsp; <a
          href="https://joinperform.com/terms-of-service/" target="_blank" className="hover:text-gray-900">Terms</a> &nbsp;&nbsp; | &nbsp;&nbsp; <a href="mailto:help@runzy.com" target="_blank"
                                                                                                                             className="hover:text-gray-900">Contact</a>
      </div>
      <div className="text-sm text-gray-400 xl:text-right">
        &copy; 2022 Perform, Inc. All rights reserved.
      </div>
    </div>
  </footer>);
}
