import { useEffect, useState } from "react";
import { trainingProfile } from "../../Api/TrainingProfile";
import {
  getTrainingEventActions,
  TrainingEventAction,
} from "../../Api/TrainingEventAction";

interface useChangeLogInput {
  trainingProfile?: trainingProfile;
}

function useChangeLog({ trainingProfile }: useChangeLogInput) {
  const [trainingEventActions, setTrainingEventActions] = useState<
    TrainingEventAction[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  async function initChangeLog() {
    setIsLoading(true);
    if (trainingProfile) {
      const items = await getTrainingEventActions(
        trainingProfile.miniProfile.id
      );

      if (items) {
        setTrainingEventActions(items);
      }
      setIsLoading(false);
    }
  }

  useEffect(() => {
    initChangeLog();
  }, [trainingProfile]);

  return {
    isLoading,
    trainingEventActions,
  };
}

export default useChangeLog;
