import {Switch} from "@headlessui/react";
import {IonContent, IonPage, IonRouterLink, useIonRouter, UseIonRouterResult,} from "@ionic/react";
import Back from "../../../Assets/Back.svg";
import {useTrainingAttributes} from "../../../Context/TrainingAttributeContext";
import {ExternalAccount, StravaSettingField} from "../../../Types";
import {Preference, preferencesForAccount,} from "../ProfileSettings/ConnectedAccountInfo/preferences";
import useAthleteSettings from "../../../Pages/Hooks/useAthleteSettings";
import React from "react";
import {classNames} from "../../../Utilities/classnames";
import ArrowTopRightIcon from "../../../Assets/ArrowTopRightIcon.svg";
import {externalAccountFromAttributes} from "../../../Types/transformers";
import {signOutOfStrava} from "../../../Api/Settings";
import SettingsSubheader from "../ProfileSettings/SettingsSubheader";
import {navigate, NavigationOption} from "../ProfileSettings/navUtil";
import {openUrl} from "../../../Utilities/RedirectUtilities";

function Content({
  account,
  routerHook,
}: {
  account: ExternalAccount | undefined;
  routerHook: UseIonRouterResult;
}) {
  const accountPreferences = (account && preferencesForAccount(account)) ?? [];
  const { handleStravaSettingChange } = useAthleteSettings();
  const [currentSettings, setCurrentSettings] =
    React.useState<Preference[]>(accountPreferences);

  React.useEffect(() => {
    if (currentSettings.length === 0) {
      setCurrentSettings(accountPreferences);
    }
  }, [accountPreferences, currentSettings.length]);

  async function handleSignOutOfStrava() {
    const confirmation = window.confirm(
      "Are you sure you want to disconnect from Strava?"
    );

    if (confirmation) {
      const response = await signOutOfStrava();

      if (response) {
        routerHook.push("/profile/settings", "back");
      } else {
        alert("Something went wrong. Please try again later.");
      }
    }
  }

  if (account !== undefined) {
    return (
      <div className="p-4 bg-white border-t-1 border-neutral-100 flex flex-col h-full">
        <h2 className="block text-[12px] text-[#9CA3AF] spacing-[20px] tracking-[1px] mb-2">
          STRAVA
        </h2>
        <div className="flex-grow ">
          {currentSettings.map((setting, index) => (
            <div
              key={index}
              className={classNames(
                index !== currentSettings.length - 1 ? "border-b" : "",
                "flex items-center justify-between w-full py-[15px] border-neutral-100 gap-4"
              )}
            >
              <p className="text-[16px] font-medium leading-[24px] text-neutral-900">
                {setting.text}
              </p>
              <Switch
                checked={setting.value ?? false}
                onChange={(isChecked) => {
                  handleStravaSettingChange(
                    setting.updateField as StravaSettingField
                  );
                  setCurrentSettings((prev) => {
                    const newSettings = [...prev];
                    newSettings[index].value = isChecked;
                    return newSettings;
                  });
                }}
                className={classNames(
                  setting.value ? "bg-brand" : "bg-neutral-100",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    setting.value ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
          ))}
        </div>
        <div className="flex-grow-0 flex w-full justify-center">
          <div className="flex-grow-0 flex flex-col w-full justify-center">
            {account.profileUrl ? (
              <a
                // href={account.profileUrl}
                // target="_blank"
                // rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  if (account.profileUrl) {
                    openUrl(account.profileUrl);
                  }
                }}
                className="flex cursor-pointer items-center justify-center w-full bg-neutral-100 py-[10px] text-neutral-900 text-[18px] leading-[28px] font-medium rounded-[200px]"
              >
                View account
                <img
                  src={ArrowTopRightIcon}
                  alt="Arrow top right icon"
                  className="ml-[10px]"
                />
              </a>
            ) : null}
            <button
              onClick={(e) => {
                e.preventDefault();
                handleSignOutOfStrava().then(() => {
                  // routerHook.push("/profile/settings", "back");
                  window.location.href = "/profile/settings";
                });
              }}
              className="flex items-center justify-center w-full text-[#EF4444] text-[18px] leading-[28px] font-medium rounded-[200px] mt-4 mb-[10px]"
            >
              Disconnect Strava
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 bg-white border-t-1 border-neutral-100 flex flex-col h-full">
      <div className="flex-grow flex flex-col items-center justify-center">
        <h2 className="font-semibold text-[24px] leading-[32px] tracking-[-0.02em] mb-[2px] mt-16">
          Manage
        </h2>
        <p className="text-[18px] text-center leading-[28px] text-neutral-500 mt-2">
          Connect Strava so Runzy can track your running and keep everything
          up to date in real time.
        </p>
      </div>
      <div className="flex-grow-0 flex justify-center items-end mt-4">
        <button
          onClick={(e) => {
            e.preventDefault();
            // routerHook.push("/profile/settings/strava/connect", "forward");
            navigate(NavigationOption.CONNECT_STRAVA);
          }}
          className="w-full py-[10px] rounded-[200px] text-white text-[16px] font-medium bg-runzy-red-orange disabled:bg-neutral-100 disabled:text-neutral-500 disabled:cursor-not-allowed"
        >
          Connect to Strava
        </button>
      </div>
    </div>
  );
}

export default function ManageStrava() {
  const routerHook = useIonRouter();
  const { trainingAttributes } = useTrainingAttributes();

  const stravaAccount = trainingAttributes
    ? externalAccountFromAttributes(trainingAttributes)
    : undefined;

  const hasLoaded = !!trainingAttributes;

  return (
    <IonPage>
      <SettingsSubheader title="Manage Strava" />
      <IonContent>
        <div className="max-w-xl h-full sm:h-fit mx-auto sm:mt-4 lg:mt-20 sm:px-6 lg:px-8">
          <IonRouterLink routerLink="/profile/settings" routerDirection="back">
            <button className="items-center self-start hidden mb-12 ml-6 space-x-1 text-xs text-gray-400 rounded md:flex hover:text-gray-500">
              <img src={Back} alt="Back" className="w-auto h-4" />
              <p>Back</p>
            </button>
          </IonRouterLink>
          {hasLoaded ? (
            <Content account={stravaAccount} routerHook={routerHook} />
          ) : null}
        </div>
      </IonContent>
    </IonPage>
  );
}
