import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { classNames } from "../../../Utilities/classnames";
import TemplateContextMenu from "../../Template/ContextMenu/TemplateContextMenu";
import {
  VARIABLE_STRONG_START,
  VARIABLE_STRONG_END,
} from "../../../Api/TrainingEventTemplate";

interface SmartFieldProps {
  value: string;
  onChange: (value: string) => void;
  className?: string | undefined;
  isDisabled?: boolean;
  id?: string;
  maxCharacters?: number;
}

const options = [
  {
    name: "Zone 1",
    value: "{{Zone 1}}",
  },
  {
    name: "Zone 2",
    value: "{{Zone 2}}",
  },
  {
    name: "Zone 3",
    value: "{{Zone 3}}",
  },
  {
    name: "Zone 4",
    value: "{{Zone 4}}",
  },
  {
    name: "Zone 5",
    value: "{{Zone 5}}",
  },
  {
    name: "First name",
    value: "{{First name}}",
  },
  {
    name: "Day of the week",
    value: "{{Day of the week}}",
  },
];

export default function SmartField({
  value,
  onChange,
  className,
  isDisabled,
  id,
  maxCharacters,
}: SmartFieldProps) {
  const ref = useRef<ReactQuill>(null);
  const [toolTipX, setToolTipX] = useState<number>();
  const [toolTipY, setToolTipY] = useState<number>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [cursorPosition, setCursorPosition] = useState<number>();

  useEffect(() => {
    if (selectedIndex === undefined) return;

    const quill = ref.current?.getEditor();
    if (!quill) return;

    const range = quill.getSelection();
    if (!range) return;
    setCursorPosition(range.index);
  }, [selectedIndex]);

  function enterOption() {
    if (selectedIndex === undefined) return;
    if (!ref.current) return;
    const quill = ref.current.getEditor();

    const value = options[selectedIndex].name;

    if (cursorPosition) {
      quill.deleteText(cursorPosition - 1, 1);

      quill.insertText(cursorPosition - 1, value, {
        color: "#0891b2",
        background: "#cffafe",
        code: true,
      });

      quill.insertText(cursorPosition + value.length - 1, "\u200B", {
        color: "black",
        background: "white",
        code: false,
      });

      onChange(quill.root.innerHTML);
    }
  }

  function handleEnterPress() {
    enterOption();
    setToolTipX(undefined);
    setToolTipY(undefined);
  }

  function adjustStyle(value: string): string {
    const content = value;
    const quill = ref.current?.getEditor();
    if (!quill) return content;

    const variableTags = content.match(
      new RegExp(VARIABLE_STRONG_START + ".*?" + VARIABLE_STRONG_END, "g")
    );
    if (!variableTags) return content;

    variableTags.forEach((variableTag) => {
      const codeTagContent = variableTag
        .replace(new RegExp(VARIABLE_STRONG_START, "g"), "")
        .replace(new RegExp(VARIABLE_STRONG_END, "g"), "");
      if (!options.find((option) => option.name === codeTagContent)) {
        const innerHTML = quill.root.innerHTML;

        const index = innerHTML.indexOf(variableTag);
        const length = variableTag.length;

        quill.removeFormat(index - 3, length);
      }
    });

    return quill.root.innerHTML;
  }

  function fitMaxCharacters(value: string): string {
    if (!maxCharacters) return value;

    const quill = ref.current?.getEditor();
    if (!quill) return value;

    const text = quill.getText();
    if (text.length <= maxCharacters) return value;

    const deleteCount = text.length - maxCharacters;
    quill.deleteText(maxCharacters, deleteCount);

    return quill.root.innerHTML;
  }

  function handleChange(value: string) {
    let content = adjustStyle(value);

    if (maxCharacters) {
      content = fitMaxCharacters(content);
    }

    onChange(content);
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!ref.current) return;

    if (event.key === "/") {
      // get index of cursor
      const index = ref.current.getEditor().getSelection()?.index;
      const length = ref.current.getEditor().getSelection()?.length;

      if (index === undefined || length === undefined) return;

      const bounds = ref.current.getEditor().getBounds(index, length);

      if (bounds) {
        setToolTipX(bounds.left);
        setToolTipY(bounds.top);
      }
    } else if (event.key === "Escape") {
      setToolTipX(undefined);
      setToolTipY(undefined);
    } else if (event.key === "ArrowDown") {
      if (toolTipX !== undefined && toolTipY !== undefined) {
        event.preventDefault();
      }
      if (selectedIndex === undefined) {
        setSelectedIndex(0);
      } else if (selectedIndex < options.length - 1) {
        setSelectedIndex(selectedIndex + 1);
      }
    } else if (event.key === "ArrowUp") {
      if (toolTipX !== undefined && toolTipY !== undefined) {
        event.preventDefault();
      }

      if (selectedIndex === undefined) {
        setSelectedIndex(0);
      } else if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1);
      }
    } else if (
      event.key === "Enter" &&
      toolTipX !== undefined &&
      toolTipY !== undefined
    ) {
      event.preventDefault();
      handleEnterPress();
    } else {
      if (selectedIndex !== undefined) setSelectedIndex(undefined);
      if (toolTipX !== undefined) setToolTipX(undefined);
      if (toolTipY !== undefined) setToolTipY(undefined);
    }
  };

  function getXCoord() {
    if (!toolTipX) return 0;
    return toolTipX - 10;
  }

  function getYCoord() {
    if (!toolTipY) return 0;
    return toolTipY + 20;
  }

  useEffect(() => {
    const urlPatterns = [/https?:\/\/[^\s]+/, /www\.[^\s]+/];

    const quill = ref.current;

    if (!quill) return;

    // https://github.com/quilljs/quill/issues/109#issuecomment-459556004
    const clipboard = quill.getEditor().getModule("clipboard");
    clipboard.addMatcher(Node.TEXT_NODE, (node: Text, delta: any) => {
      const combinedPattern = urlPatterns
        .map((pattern) => `(${pattern.source})`)
        .join("|");
      const combinedRegexp = new RegExp(combinedPattern, "g");

      const ops: any[] = [];
      const str = node.data;
      let lastMatchEndIndex = 0;
      let match: RegExpExecArray | null = combinedRegexp.exec(str);
      while (match !== null) {
        if (match.index > lastMatchEndIndex) {
          ops.push({ insert: str.slice(lastMatchEndIndex, match.index) });
        }
        ops.push({ insert: match[0], attributes: { link: match[0] } });
        lastMatchEndIndex = match.index + match[0].length;
        match = combinedRegexp.exec(str);
      }

      if (lastMatchEndIndex < str.length) {
        ops.push({ insert: str.slice(lastMatchEndIndex) });
      }

      delta.ops = ops;
      return delta;
    });
  }, [ref]);
  return (
    <>
      <ReactQuill
        id={id}
        ref={ref}
        theme="bubble"
        className={className}
        value={value}
        readOnly={isDisabled}
        onKeyDown={handleKeyDown}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onChange={(content, delta, source, editor) => {
          if (source === "user") {
            handleChange(content);
          }
        }}
      />

      <div
        className={classNames(
          !toolTipX && !toolTipY ? "hidden" : "",
          "absolute z-50"
        )}
        style={{
          top: getYCoord(),
          left: getXCoord(),
        }}
      >
        <TemplateContextMenu
          options={options}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          handleEnterPress={handleEnterPress}
        />
      </div>
    </>
  );
}
