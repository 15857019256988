import { convertToHTMLDateFormat } from "../../../Utilities/DateUtilities";

export default function DateInput({
  isDisabled,
  currentScheduledAt,
  handleDateChange,
}: {
  isDisabled?: boolean;
  currentScheduledAt?: Date;
  handleDateChange: (date: string) => void;
}) {
  return (
    <div className="w-1/2">
      <label htmlFor="date" className="block text-sm font-medium text-gray-700">
        Date
      </label>
      <div className="flex mt-1 rounded-md shadow-sm">
        <input
          disabled={isDisabled}
          type="date"
          name="date"
          id="date"
          autoComplete="date"
          className="flex-1 block w-full sm:py-2.5 min-w-0 border-gray-300 disabled:cursor-not-allowed rounded-md focus:ring-brand focus:border-brand sm:text-sm"
          value={convertToHTMLDateFormat(currentScheduledAt ?? new Date())}
          onChange={(e) => {
            if (isDisabled) return;
            handleDateChange(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
