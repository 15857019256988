import React from "react";
import DistanceIcon from "../../../../Assets/Calendar/DistanceIcon.svg";
import { UnitPreference } from "../../../../Api/User";

import { formatCalendarDistance } from "../../../../Utilities/FormatUtilties";

export interface DistanceBlockCalendarEventInput {
  metricSetting: UnitPreference;
  assignedDistance: string;
  actualDistance: number;
}

export const DistanceBlock = ({
  assignedDistance,
  actualDistance,
  metricSetting,
}: DistanceBlockCalendarEventInput) => {
  //   format empty string or null to null or string with mi
  const formattedAssignedDistance =
    assignedDistance === "" ? null : assignedDistance + " mi";

  // format digit in meters to null or string with mi
  const formattedActualDistance = actualDistance
    ? formatCalendarDistance(actualDistance, metricSetting)
    : null;

  const toShowDistance = formattedAssignedDistance || formattedActualDistance;

  return (
    <>
      {toShowDistance && (
        <div className="flex flex-row items-center ">
          <img
            src={DistanceIcon}
            alt="distance icon"
            className="w-4 h-4 mb-[2px] mr-1"
          />
          <div className="flex justify-between text-[#374151]">
            {formattedAssignedDistance ? (
              <p>{formattedAssignedDistance}</p>
            ) : (
              <p>_</p>
            )}
          </div>
          <div className="flex justify-between text-[#6B7280]">
            {formattedActualDistance && (
              <p>
                <span>&nbsp;/&nbsp;</span>
                {formattedActualDistance}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};
