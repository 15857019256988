import { Combobox } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TrainingAdmin } from "../../Api/TrainingAdmin";
import { trainingProfile } from "../../Api/TrainingProfile";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";
import Contents from "./Contents";
import DetailedContents from "./DetailedContents";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const profilePicture = require("../../Assets/placeholder_profile.png");

export interface DetailedOptions {
  basicNeedsAttentionProfiles: trainingProfile[];
  premiumProfiles: trainingProfile[];
  allBasicProfiles: trainingProfile[];
}

interface ComboBoxInput {
  options: (trainingProfile | TrainingAdmin)[];
  detailedOptions?: DetailedOptions;
  selected: trainingProfile | TrainingAdmin | undefined;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
}

function ComboBox({
  options,
  detailedOptions,
  selected,
  onChange,
}: ComboBoxInput) {
  const [selectedPerson, setSelectedPerson] = useState(selected);
  const [query, setQuery] = useState("");
  const location = useLocation();

  const filteredOptions =
    query === ""
      ? options
      : options.filter((person: trainingProfile | TrainingAdmin) => {
          return person.miniProfile.name
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  const filteredDetailedOptions =
    query === ""
      ? detailedOptions
      : {
          allBasicProfiles:
            detailedOptions?.allBasicProfiles.filter(
              (person: trainingProfile | TrainingAdmin) => {
                return person.miniProfile.name
                  .toLowerCase()
                  .includes(query.toLowerCase());
              }
            ) ?? [],
          basicNeedsAttentionProfiles:
            detailedOptions?.basicNeedsAttentionProfiles.filter(
              (person: trainingProfile | TrainingAdmin) => {
                return person.miniProfile.name
                  .toLowerCase()
                  .includes(query.toLowerCase());
              }
            ) ?? [],
          premiumProfiles:
            detailedOptions?.premiumProfiles.filter(
              (person: trainingProfile | TrainingAdmin) => {
                return person.miniProfile.name
                  .toLowerCase()
                  .includes(query.toLowerCase());
              }
            ) ?? [],
        };

  useEffect(() => {
    if (selectedPerson) onChange(selectedPerson.miniProfile.id);
  }, [selectedPerson]);

  useEffect(() => {
    setSelectedPerson(selected);
  }, [location.pathname]);

  useEffect(() => {
    setSelectedPerson(selected);
  }, [selected]);

  return (
    <div className="">
      <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
        <div className="relative mt-1">
          <Combobox.Input
            className="z-40 w-full py-2 pl-8 pr-6 bg-white border border-gray-300 rounded-md shadow-sm focus:border-brand focus:outline-none focus:ring-1 focus:ring-brand sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(person: trainingProfile) =>
              person?.miniProfile.name.trim() !== ""
                ? person?.miniProfile.name
                : person.trainingAttribute.emailAddress
            }
            autoComplete="off"
            autoCorrect="off"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(e: any) => {
              e.target.value = "";
            }}
          />

          <ProfilePicture
            url={selectedPerson?.miniProfile.profilePicture ?? profilePicture}
            classNames="absolute left-0.5 w-8 h-8 p-1 rounded-full inset-y-0.5"
            alt=""
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
          {filteredDetailedOptions ? (
            <DetailedContents detailedOptions={filteredDetailedOptions} />
          ) : (
            <Contents filteredOptions={filteredOptions} />
          )}
        </div>
      </Combobox>
    </div>
  );
}

export default ComboBox;
