import { useState } from "react";
import { ActivityRoute, ActivitySplit } from "../../../../Api/ActivityRoute";
import { UnitPreference } from "../../../../Api/User";
import { useUserInfo } from "../../../../Context/UserContext";
import { classNames } from "../../../../Utilities/classnames";
import {
  formatDistance,
  formatPaceFromSpeed,
} from "../../../../Utilities/FormatUtilties";
import { Switch } from "@headlessui/react";

function Splits({
  currentActivityRoute,
}: {
  currentActivityRoute: ActivityRoute;
}) {
  const { userInfo } = useUserInfo();
  const [showManualSplits, setShowManualSplits] = useState(false);

  const splitData = showManualSplits
    ? currentActivityRoute.laps
    : currentActivityRoute.splits;

  function statGuard(input: string, type: "pace" | "elev" | "dis"): string {
    if (input === "") {
      switch (type) {
        case "dis":
          return "0.00";
        case "elev":
          return "0 ft";
        default:
          return "0:00";
      }
    }

    return `${input}`;
  }

  function hasElevationData(data: ActivitySplit[]) {
    return data.some((split) => split.elevationGain !== 0);
  }

  function Header() {
    return (
      <div className="flex flex-row items-center justify-between">
        <div className="text-sm font-medium text-gray-900">
          {showManualSplits ? "Laps" : "Splits"}
        </div>
        <div className="flex flex-row justify-end">
          <Switch.Group>
            <div className="flex items-center">
              <Switch
                checked={showManualSplits}
                onChange={setShowManualSplits}
                className={classNames(
                  showManualSplits ? "bg-primary-500" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    showManualSplits ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
          </Switch.Group>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header />
      {splitData.length > 0 && (
        <div className="flex flex-col mt-3 mb-5 border border-gray-300/50 rounded-xl">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 rounded-xl">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden sm:rounded-lg rounded-xl">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-900 uppercase"
                      >
                        {showManualSplits ? "Lap" : "Split"}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-900 uppercase"
                      >
                        Pace
                      </th>
                      {hasElevationData(splitData) && (
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-900 uppercase"
                        >
                          Elev
                        </th>
                      )}

                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-900 uppercase"
                      >
                        Distance
                      </th>
                      {splitData.filter((split) => split.heartRate !== null)
                        .length > 0 && (
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-900 uppercase"
                        >
                          HR
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {splitData.map((split, index) => (
                      <tr
                        key={index}
                        className={classNames(
                          index % 2 === 0 ? "bg-white" : "bg-gray-50",
                          "w-full"
                        )}
                      >
                        <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {statGuard(
                            formatPaceFromSpeed(
                              split.speed,
                              userInfo?.profileMetricSetting ??
                                UnitPreference.IMPERIAL
                            ),
                            "pace"
                          )}
                        </td>
                        {hasElevationData(splitData) && (
                          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                            {statGuard(
                              formatDistance(
                                split.elevationGain,
                                userInfo?.profileMetricSetting ??
                                  UnitPreference.IMPERIAL,
                                true,
                                true,
                                true
                              ),
                              "elev"
                            )}
                          </td>
                        )}
                        <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {statGuard(
                            formatDistance(
                              split.distance,
                              userInfo?.profileMetricSetting ??
                                UnitPreference.IMPERIAL,
                              false,
                              false,
                              false
                            ),
                            "dis"
                          )}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {split.heartRate ? split.heartRate : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Splits;
