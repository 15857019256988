import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { TrainingEventType } from "../../Api/TrainingEvent";
import {
  deleteTrainingEventTemplate,
  editTrainingEventTemplate,
  editTrainingEventTemplateParams,
  TrainingEventTemplate,
} from "../../Api/TrainingEventTemplate";
import { trainingProfile } from "../../Api/TrainingProfile";
import { isSuperAdmin, UnitPreference } from "../../Api/User";
import Spinner from "../../Coachee/Components/Spinner";
import { useTemplateContext } from "../../Context/TemplateContext";
import { useUserInfo } from "../../Context/UserContext";
import { classNames } from "../../Utilities/classnames";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";
import SlideOutContainer from "../../Utilities/Components/SlideOutContainer";
import { formatNotificationDate } from "../../Utilities/DateUtilities";
import {
  decodeDuration,
  encodeDistance,
  encodeDuration,
  formatDistance,
  formatDuration,
} from "../../Utilities/FormatUtilties";
import { CalendarDay } from "../Calendar";
import AssignedDistanceInput from "../Calendar/Input/AssignedDistanceInput";
import AssignedDurationInput from "../Calendar/Input/AssignedDurationInput";
import NameInput from "../Calendar/Input/NameInput";
import NoteInput from "../Calendar/Input/NoteInput";
import TitleInput from "../Calendar/Input/TitleInput";
import ActivityDropdown from "../Components/ActivityDropdown";
import Instructions from "../Components/Instructions";
import TypeDropdown from "../Components/TypeDropdown";
import { Switch } from "@headlessui/react";
import {
  EditableStep,
  getEditableSteps,
  getSteps,
  TrainingEventStepType,
} from "../../Api/Step";
import Steps from "../Components/Steps/Steps";
import _ from "lodash";

interface Props {
  template: TrainingEventTemplate | undefined;
  setSelectedContent: React.Dispatch<
    React.SetStateAction<CalendarDay | TrainingEventTemplate | undefined>
  >;
  profile: trainingProfile;
}

interface State {
  name: string;
  title: string;
  description: string[];
  type: TrainingEventType;
  assignedDuration: string;
  assignedDistance: string;
  note: string;
  isPrivate: boolean;
  steps: EditableStep[];
}

export default function TemplateEditor({
  template,
  setSelectedContent,
  profile,
}: Props) {
  if (!template) return null;
  /* eslint-disable */
  const { userInfo } = useUserInfo();
  const { initTemplates } = useTemplateContext();

  const history = useHistory();

  const initState: State = {
    name: template.name,
    title: template.title,
    description: template.description.split("$"),
    type: template.type,
    assignedDuration: decodeDuration(template.assignedDuration),
    assignedDistance: formatDistance(
      template.assignedDistance,
      userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL
    ),
    note: template.message,
    isPrivate: template.isPrivate,
    steps: getEditableSteps(template.steps),
  };

  const [state, setState] = useState(initState);
  const [isSaving, setIsSaving] = useState(false);

  function hasChanged() {
    if (!template) return false;

    function stepsHaveChanged() {
      const currentStepsFormatted = getSteps(state.steps).map((step) => {
        return {
          ...step,
          intensityMaxZone: step.intensityMaxZone ?? 0,
          intensityMinZone: step.intensityMinZone ?? 0,
          intensityMinZoneFraction: step.intensityMinZoneFraction ?? 1,
          intensityMaxZoneFraction: step.intensityMaxZoneFraction ?? 1,
          description: step.description?.replace(/(<([^>]+)>)/gi, "") ?? "",
          assignedDistance: formatDistance(
            step.assignedDistance ?? 0,
            userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL,
            true,
            false,
            true,
            2
          ),
          assignedDuration: formatDuration(step.assignedDuration ?? 0),
          subSteps: step.subSteps?.map((subStep) => {
            return {
              ...subStep,
              intensityMinZone: subStep.intensityMinZone ?? 0,
              intensityMaxZone: subStep.intensityMaxZone ?? 0,
              intensityMinZoneFraction: subStep.intensityMinZoneFraction ?? 1,
              intensityMaxZoneFraction: subStep.intensityMaxZoneFraction ?? 1,
              description:
                subStep.description?.replace(/(<([^>]+)>)/gi, "") ?? "",
              assignedDistance: formatDistance(
                subStep.assignedDistance ?? 0,
                userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL,
                true,
                false,
                true,
                2
              ),
              assignedDuration: formatDuration(subStep.assignedDuration ?? 0),
            };
          }),
        };
      });

      const templateStepsFormatted =
        template?.steps.map((step) => {
          return {
            ...step,
            intensityMaxZone: step.intensityMaxZone ?? 0,
            intensityMinZone: step.intensityMinZone ?? 0,
            intensityMinZoneFraction: step.intensityMinZoneFraction ?? 1,
            intensityMaxZoneFraction: step.intensityMaxZoneFraction ?? 1,
            description: step.description?.replace(/(<([^>]+)>)/gi, "") ?? "",
            assignedDistance: formatDistance(
              step.assignedDistance ?? 0,
              userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL,
              true,
              false,
              true,
              2
            ),
            assignedDuration: formatDuration(step.assignedDuration ?? 0),
            subSteps: step.subSteps?.map((subStep) => {
              return {
                ...subStep,
                intensityMinZone: subStep.intensityMinZone ?? 0,
                intensityMaxZone: subStep.intensityMaxZone ?? 0,
                intensityMinZoneFraction: subStep.intensityMinZoneFraction ?? 1,
                intensityMaxZoneFraction: subStep.intensityMaxZoneFraction ?? 1,
                description:
                  subStep.description?.replace(/(<([^>]+)>)/gi, "") ?? "",
                assignedDistance: formatDistance(
                  subStep.assignedDistance ?? 0,
                  userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL,
                  true,
                  false,
                  true,
                  2
                ),
                assignedDuration: formatDuration(subStep.assignedDuration ?? 0),
              };
            }),
          };
        }) ?? [];

      return !_.isEqual(currentStepsFormatted, templateStepsFormatted);
    }

    return (
      state.isPrivate !== template.isPrivate ||
      state.name !== template.name ||
      state.title !== template.title ||
      state.description.join("$") !== template.description ||
      state.type !== template.type ||
      state.assignedDuration !== decodeDuration(template.assignedDuration) ||
      state.assignedDistance !==
        formatDistance(
          template.assignedDistance,
          userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL
        ) ||
      template.message.replaceAll(/<.*?>/gm, "").trimEnd() !==
        state.note.replaceAll(/<.*?>/gm, "").trimEnd() ||
      stepsHaveChanged()
    );
  }

  async function handleEdit() {
    if (!template) return;
    if (!hasChanged()) return;
    if (!userInfo) return;
    if (isSaving) return;
    const {
      name,
      title,
      description,
      type,
      assignedDuration,
      assignedDistance,
      note,
      isPrivate,
      steps,
    } = state;

    // if there is a repeat step and the count is less than 2, then we need to throw an error
    // "Repeat steps must have a count of 2 or more"
    const repeatStep = steps.find(
      (step) => step.stepType === TrainingEventStepType.REPEAT
    );
    if (
      repeatStep &&
      repeatStep.repeats !== undefined &&
      repeatStep.repeats < 2
    ) {
      window.alert("Repeat steps must have a count of 2 or more");
      return;
    }

    // if there is a repeat step with less than 1 substep, then we need to throw an error
    // "Repeat steps must have at least 1 substep"
    if (
      repeatStep &&
      repeatStep.subSteps !== undefined &&
      repeatStep.subSteps.length < 1
    ) {
      window.alert(
        "All steps and substeps must have a minimum and maximum intensity fraction greater than 0"
      );
      return;
    }

    // no steps can have a max intensity fraction of 0 or a min intensity fraction of 0
    const invalidSteps = steps.filter(
      (step) =>
        step.intensityMaxZoneFraction === 0 ||
        step.intensityMinZoneFraction === 0
    );
    const invalidSubSteps = steps
      .filter((step) => step.subSteps)
      .flatMap((step) => step.subSteps)
      .filter(
        (subStep) =>
          (subStep?.intensityMinZoneFraction !== undefined &&
            subStep?.intensityMinZoneFraction === 0) ||
          (subStep?.intensityMaxZoneFraction !== undefined &&
            subStep?.intensityMaxZoneFraction === 0)
      );

    if (invalidSteps.length > 0 || invalidSubSteps.length > 0) {
      window.alert(
        "Intensity fractions cannot be 0. Please set them to 1 or remove them."
      );
      return;
    }

    const newTemplate: editTrainingEventTemplateParams = {
      ...template,
      name,
      title,
      description: description.join("$"),
      type,
      assignedDuration: encodeDuration(assignedDuration),
      assignedDistance: encodeDistance(
        assignedDistance,
        userInfo.profileMetricSetting
      ),
      message: note,
      isPrivate: isPrivate,
      steps: getSteps(steps),
    };
    setIsSaving(true);
    const response = await editTrainingEventTemplate(newTemplate);
    if (response) {
      template = response;
      initTemplates();
    }
    setIsSaving(false);
  }

  async function handleDelete() {
    if (!template) return;
    if (isSaving) return;

    setIsSaving(true);
    const response = await deleteTrainingEventTemplate(template.id);

    if (response) {
      handleUnselectContent();
      initTemplates();
    }
    setIsSaving(false);
  }

  function handleCancel() {
    handleUnselectContent();
  }

  function setName(name: string) {
    setState({ ...state, name });
  }

  function setIsPrivate(isPrivate: boolean) {
    setState({ ...state, isPrivate });
  }

  function setTitle(title: string) {
    setState({ ...state, title });
  }
  function setDescription(description: string[]) {
    setState({ ...state, description });
  }
  function setType(type: TrainingEventType) {
    setState({ ...state, type });
  }
  function setAssignedDuration(assignedDuration: string) {
    setState({ ...state, assignedDuration });
  }
  function setAssignedDistance(assignedDistance: string) {
    setState({ ...state, assignedDistance });
  }
  function setNote(note: string) {
    setState({ ...state, note });
  }
  function setSteps(steps: EditableStep[]) {
    setState({ ...state, steps });
  }

  const usesInstructions =
    state.description.length > 1 || state.description[0].length > 0;

  function handleUnselectContent() {
    setSelectedContent(undefined);
    history.push(`/coach/${profile.miniProfile.id}/calendar`);
  }

  return (
    <SlideOutContainer
      handleClose={handleUnselectContent}
      isOpen={template !== undefined}
    >
      <div className="px-4 py-10 sm:px-6">
        <form className="px-4 space-y-8 divide-y divide-gray-200">
          <span className="items-center w-full mb-4 rounded-md shadow-sm">
            <div className="flex justify-between mb-6">
              <div className="flex items-center gap-2">
                <h3 className="text-[24px] font-medium text-black">Template</h3>
                <p className="font-medium text-neutral-500 text-[14px]">
                  (Editing)
                </p>
              </div>
              <div className="flex items-center gap-2">
                {isSaving ? <Spinner isCentered={false} /> : <></>}
                <button
                  disabled={!hasChanged()}
                  onClick={() => handleEdit()}
                  type="button"
                  className={classNames(
                    "sm:inline-flex disabled:cursor-default disabled:bg-gray-300 cursor-pointer hidden justify-center px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                  )}
                >
                  Save
                </button>

                <button
                  onClick={() => handleCancel()}
                  type="button"
                  className={classNames(
                    "sm:inline-flex disabled:cursor-default cursor-pointer hidden justify-center px-4 py-2 text-sm font-medium text-brand"
                  )}
                >
                  Cancel
                </button>
                <ActivityDropdown
                  actions={[
                    {
                      key: "Delete",
                      action: () => handleDelete(),
                    },
                  ]}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <div className="flex mt-1 items-center gap-2">
                  <ProfilePicture
                    url={template.createdByMiniProfile.profilePicture}
                    classNames="w-6 h-6 rounded-full"
                  />

                  <p className="text-[14px] font-medium text-neutral-900">
                    Created by {template.createdByMiniProfile.name}
                  </p>
                </div>
                <label
                  htmlFor="about"
                  className="block mt-2 text-sm font-medium text-gray-400"
                >
                  Last edited {formatNotificationDate(template.updatedAt)} by{" "}
                  {template.updatedByMiniProfile.name}
                </label>
              </div>
            </div>
          </span>

          <div className="space-y-8">
            <div>
              {(userInfo?.id === template.createdByMiniProfile.id ||
                isSuperAdmin(userInfo)) && (
                <div>
                  <Switch.Group
                    as="div"
                    className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:pt-5"
                  >
                    <Switch.Label
                      as="dt"
                      className="text-sm font-medium text-gray-700"
                      passive
                    >
                      Private to {template.createdByMiniProfile.name}
                    </Switch.Label>

                    <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                      <Switch
                        checked={state.isPrivate}
                        onChange={(newValue) => {
                          setIsPrivate(newValue);
                        }}
                        className={classNames(
                          state.isPrivate ? "bg-brand" : "bg-gray-200",
                          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand sm:ml-auto"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            state.isPrivate ? "translate-x-5" : "translate-x-0",
                            "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                          )}
                        />
                      </Switch>
                    </dd>
                  </Switch.Group>
                </div>
              )}
              <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                <NameInput
                  currentName={state.name}
                  setCurrentName={setName}
                  isDisabled={isSaving}
                />

                <div className="sm:col-span-6">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Type
                  </label>
                  <TypeDropdown
                    selectedType={state.type}
                    setSelected={setType}
                    isDisabled={isSaving}
                  />
                </div>

                <TitleInput
                  currentTitle={state.title}
                  setCurrentTitle={setTitle}
                  isDisabled={isSaving}
                />

                <NoteInput currentNote={state.note} setCurrentNote={setNote} />

                <div className="flex w-full items-center justify-between sm:col-span-6 gap-2">
                  <AssignedDistanceInput
                    currentAssignedDistance={state.assignedDistance}
                    setCurrentAssignedDistance={setAssignedDistance}
                    isDisabled={isSaving}
                  />

                  <AssignedDurationInput
                    currentAssignedDuration={state.assignedDuration}
                    setCurrentAssignedDuration={setAssignedDuration}
                    isDisabled={isSaving}
                  />

                  {/* TODO: pace field? */}
                </div>

                <div className="sm:col-span-6">
                  {usesInstructions ? (
                    <Instructions
                      currentDescription={state.description}
                      setCurrentDescription={setDescription}
                      isDisabled={isSaving}
                    />
                  ) : (
                    <Steps
                      currentSteps={state.steps}
                      setCurrentSteps={setSteps}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </SlideOutContainer>
  );
}
