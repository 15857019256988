import { useContext } from "react";
import { trainingEvent } from "../../../Api/TrainingEvent";
import { UnitPreference } from "../../../Api/User";
import { UserInfoContext } from "../../../Context/UserContext";
import { formatPace } from "../../../Utilities/FormatUtilties";

export default function ActualPaceDisplay({ event }: { event: trainingEvent }) {
  const { userInfo } = useContext(UserInfoContext);
  const metricSetting =
    userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL;

  let paceString = "-";

  if (event.actualDistance && event.actualDuration) {
    const distance = event.actualDistance;
    const duration = event.actualDuration;

    if (distance > 0 && duration > 0) {
      paceString = formatPace(distance, duration, metricSetting);
    }
  }

  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="assigned-duration"
        className="block text-sm font-medium text-gray-700"
      >
        Actual pace
      </label>
      <div className="mt-1">
        <p className="block w-full pt-2 border-gray-300 rounded-md focus:ring-brand focus:border-brand sm:text-sm">
          {paceString}
        </p>
      </div>
    </div>
  );
}
