import { useState, useEffect } from "react";
import {
  editTrainingAttributes,
  trainingAttributes,
  getTrainingAttributes,
} from "../../Api/TrainingAttribute";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { trainingProfile } from "../../Api/TrainingProfile";
import { toast } from "react-toastify";
import { Prompt } from "react-router-dom";

interface NotesInput {
  selectedTrainingProfile: trainingProfile;
}

// function SaveIndicator({ displaySaved }: { displaySaved: boolean }) {
//   return (
//     <div
//       className={classNames(
//         displaySaved ? "opacity-100" : "opacity-0",
//         "flex items-center text-green-700 transition-opacity ease-in duration-200"
//       )}
//     >
//       <CheckIcon className="w-auto h-4 " />
//       <p className="font-medium text-[12px] my-3">Saved</p>
//     </div>
//   );
// }

function Notes({ selectedTrainingProfile }: NotesInput) {
  const [trainingAttributes, setTrainingAttributes] =
    useState<trainingAttributes>();

  const [savedNote, setSavedNote] = useState<string>();
  const [note, setNote] = useState<string>();

  const hasUnsavedChanges = note !== savedNote;

  async function saveNote() {
    if (trainingAttributes && note && trainingAttributes.notes !== note) {
      if (
        selectedTrainingProfile.trainingAttribute.id !== trainingAttributes.id
      )
        return;

      const response = await editTrainingAttributes(
        { ...trainingAttributes, notes: note },
        selectedTrainingProfile.miniProfile.id
      );

      if (response) {
        toast("Note saved");
        setSavedNote(note);
      }
    }
  }

  function SaveButton() {
    return (
      <button
        onClick={() => saveNote()}
        disabled={!hasUnsavedChanges}
        type="button"
        className="justify-center hidden px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer sm:inline-flex disabled:cursor-default disabled:bg-gray-300 bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
      >
        Save
      </button>
    );
  }

  async function initNote() {
    if (selectedTrainingProfile === undefined) {
      return;
    }
    // const trainingAttributes = selectedTrainingProfile.trainingAttribute;
    const trainingAttributes = await getTrainingAttributes(
      selectedTrainingProfile.miniProfile.id
    );

    if (trainingAttributes) {
      const initNote = trainingAttributes.notes;
      setNote(initNote);
      setSavedNote(initNote);
      setTrainingAttributes(trainingAttributes);
    }
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      initNote();
    }

    return () => {
      mounted = false;
    };
  }, [selectedTrainingProfile]);

  return (
    <>
      <Prompt
        message="Are you sure you want to leave with unsaved changes?"
        when={hasUnsavedChanges}
      />
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-end pb-3 pr-3 ">
          <SaveButton />
        </div>
        <ReactQuill
          className="bg-white"
          theme="snow"
          value={note ?? ""}
          onChange={setNote}
        />
      </div>
    </>
  );
}

export default Notes;
