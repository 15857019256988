import { useIonRouter } from "@ionic/react";
import { connect, StreamClient, StreamFeed } from "getstream";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Notification } from "../../Api/Stream";
import { trainingProfile } from "../../Api/TrainingProfile";
import { useTrainingAttributes } from "../../Context/TrainingAttributeContext";
import { UserInfoContext } from "../../Context/UserContext";
import Default from "../../Default/Default";

interface useNotificationsInput {
  trainingProfile?: trainingProfile;
}

function useNotifications({ trainingProfile }: useNotificationsInput) {
  const { userInfo } = useContext(UserInfoContext);
  const [streamClient, setStreamClient] = useState<StreamClient>();
  const [feed, setFeed] = useState<StreamFeed>();
  const [feedItems, setFeedItems] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReadMap, setIsReadMap] =
    useState<{ id: string; is_read: boolean }[]>();
  const { trainingAttributes } = useTrainingAttributes();
  const location = useLocation();
  const history = useHistory();
  const routerHook = useIonRouter();

  const initStreamClient = useCallback(() => {
    function initStreamClient() {
      if (userInfo) {
        const client = connect(
          Default.streamsApiKey,
          userInfo.streamAPIAuth,
          Default.streamsAppId
        );

        setStreamClient(client);
      }
    }

    initStreamClient();
  }, [userInfo]);

  const initNotifications = useCallback(() => {
    async function initNotifications() {
      setFeedItems([]);
      if (streamClient) {
        if (trainingProfile) {
          setFeed(
            streamClient.feed(
              "athlete_coach_notifications",
              streamClient.currentUser?.id
            )
          );
        } else {
          setFeed(streamClient.feed("notifications"));
        }
      }
    }

    initNotifications();
  }, [streamClient, trainingProfile]);

  async function handleClickNotification(feedItem: Notification) {
    if (streamClient) {
      if (!location.pathname.includes("coach")) {
        streamClient
          .feed("notifications")
          .get({ mark_read: feedItems.map((feedItem) => feedItem.id) });
        return;
      }

      streamClient
        .feed("athlete_coach_notifications", streamClient.currentUser?.id)
        .get({ mark_read: [feedItem.id] });
    }

    if (location.pathname.includes("coach") && feedItem.activities[0]) {
      if (feedItem.activities[0].trainingEvent) {
        history.replace(
          `calendar/${feedItem.activities[0].trainingEvent.id}/feedback`
        );
      } else if (feedItem.activities[0].object.includes("trainingEvent")) {
        history.replace(`calendar/${feedItem.activities[0].object}/feedback`);
      } else {
        const urlData = feedItem.activities[0].foreign_id
          .replace("perform:streamActivity:", "")
          .split(":");

        if (urlData[0] && urlData[1]) {
          const subUrl = `${urlData[0]}coach_feedback:${urlData[1]}`;

          history.push(
            `/coach/${trainingProfile?.miniProfile.id}/posts/${subUrl}`
          );
        }
      }
    } else if (["commented", "posted"].includes(feedItem.verb)) {
      routerHook.push(
        `/dashboard/training-log/${feedItem.activities[0].object.replace(
          "perform:streamActivity:",
          ""
        )}`,
        "back"
      );
    }
  }

  function hasAtleastOneUnreadNotification() {
    if (streamClient && location.pathname.includes("coach")) {
      return (
        feedItems.find((feedItem) => feedItem.is_read === false) !== undefined
      );
    }

    return false;
  }

  function markFeedItemsAsRead() {
    if (
      window.confirm(
        "Are you sure you want to mark all notifications for this athlete as read? This action cannot be undone"
      )
    ) {
      if (streamClient && location.pathname.includes("coach")) {
        streamClient
          .feed("athlete_coach_notifications")
          .get({ mark_read: feedItems.map((feedItem) => feedItem.id) });
      }

      initNotifications();
    }
  }

  useEffect(() => {
    async function initFeedItems() {
      if (streamClient && feed) {
        const feedContent = await feed.get({ limit: 100 });

        let items = (await feedContent.results) as Notification[];

        if (trainingProfile) {
          items = items.filter(
            (item) =>
              item.activities[0].actor ===
              trainingProfile.miniProfile.numericId.toString()
          );
        }

        setIsReadMap(
          items.map((item) => {
            return { id: item.id, is_read: item.is_read };
          })
        );

        setFeedItems(items);
        setIsLoading(false);
      }
    }

    initFeedItems();
  }, [feed, streamClient, trainingProfile]);

  useEffect(() => {
    initNotifications();
  }, [streamClient, initNotifications]);

  useEffect(() => {
    if (streamClient === undefined) {
      initStreamClient();
    }

    if (streamClient && trainingProfile) {
      initNotifications();
    }

    setFeedItems((feedItems) =>
      feedItems.filter(
        (feedItem) =>
          feedItem.activities[0].actor ===
          trainingProfile?.miniProfile.numericId.toString()
      )
    );
  }, [trainingProfile, initNotifications, initStreamClient, streamClient]);

  useEffect(() => {
    initStreamClient();
  }, [userInfo, initStreamClient]);

  useEffect(() => {
    initStreamClient();
  }, [initStreamClient]);

  return {
    feedItems,
    trainingAttributes,
    isReadMap,
    isLoading,
    handleClickNotification,
    markFeedItemsAsRead,
    hasAtleastOneUnreadNotification,
  };
}

export default useNotifications;
