export function idFromURN(urn: string, resource: string) {
  return urn.replace(`perform:${resource}:`, "");
}

export function idToURN(id: string, resource: string) {
  // Safe guard: if already urn, simply return
  if (id.includes("perform:")) {
    return id;
  }
  return `perform:${resource}:${id}`;
}
