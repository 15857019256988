import ReactLinkify from "react-linkify";
import { Comment } from "../../../../Api/Stream";
import { useUserInfo } from "../../../../Context/UserContext";
import { classNames } from "../../../../Utilities/classnames";
import StreamEditBox from "../../../Components/StreamEditBox";
import Lightbox from "react-image-lightbox";
import { useState } from "react";
import { EmojiAPIResponse, EmojiData } from "../ActivityFeedback";
import Picker from "emoji-picker-react";
import { StreamClient } from "getstream";
import Smileys from "./../../../../Assets/Smileys.svg";
import { deleteReaction } from "../../../../Api/Reaction";

function Body({
  commentContent,
  editMode,
  saveComment,
  cancelEdit,
  setCommentContent,
  comment,
  children,
  handleAddEmoji,
  streamClient,
  initComments,
}: {
  commentContent: string;
  editMode: boolean;
  saveComment: () => void;
  cancelEdit: () => void;
  setCommentContent: React.Dispatch<React.SetStateAction<string>>;
  comment: Comment;
  children?: EmojiAPIResponse[] | undefined;
  handleAddEmoji: (emoji: string) => void;
  streamClient: StreamClient | undefined;
  initComments: () => void;
}) {
  const initialReactions = children ?? [];
  const emojis = initialReactions.map((emoji) => emoji.data);

  const [showPicker, setShowPicker] = useState(false);

  const { userInfo } = useUserInfo();
  const [lightBoxIndex, setLightBoxIndex] = useState<number>();
  const imageAttachments = comment.data.imageAttachments;

  function ImageModal() {
    if (lightBoxIndex === undefined || !imageAttachments) return null;

    return (
      <Lightbox
        closeLabel="Close"
        mainSrc={imageAttachments[lightBoxIndex]}
        nextSrc={
          imageAttachments[(lightBoxIndex + 1) % imageAttachments.length]
        }
        prevSrc={
          imageAttachments[
            (lightBoxIndex + imageAttachments.length - 1) %
              imageAttachments.length
          ]
        }
        onCloseRequest={() => setLightBoxIndex(undefined)}
        onMovePrevRequest={() =>
          setLightBoxIndex(
            (lightBoxIndex + imageAttachments.length - 1) %
              imageAttachments.length
          )
        }
        onMoveNextRequest={() =>
          setLightBoxIndex((lightBoxIndex + 1) % imageAttachments.length)
        }
      />
    );
  }

  function CommentImage({ imageAttachment }: { imageAttachment: string }) {
    if (!imageAttachments) return null;

    return (
      <img
        src={imageAttachment}
        alt="comment image"
        className="object-cover w-64 h-64 mr-3 rounded-md cursor-pointer"
        onClick={() => {
          setLightBoxIndex(imageAttachments.indexOf(imageAttachment));
        }}
      />
    );
  }

  function CommentImages() {
    if (
      !comment.data.imageAttachments ||
      comment.data.imageAttachments.length === 0
    )
      return null;

    return (
      <div className="flex items-center py-4 overflow-x-auto">
        {comment.data.imageAttachments.map((imageAttachment, index) => (
          <CommentImage key={index} imageAttachment={imageAttachment} />
        ))}
      </div>
    );
  }

  function AddEmojiButton() {
    const onEmojiClick = (event: any, emojiObject: any) => {
      const emoji = emojiObject.emoji;

      handleAddEmoji(emoji);

      setShowPicker(false);
    };

    return (
      <div className="relative">
        <button
          onClick={() => setShowPicker(true)}
          className="flex items-center justify-center text-gray-400 hover:text-gray-500"
        >
          <img src={Smileys} alt="smileys" className="w-4 h-4" />
        </button>
        <div className="absolute z-50">
          {showPicker && <Picker onEmojiClick={onEmojiClick} />}
        </div>
      </div>
    );
  }

  function Emoji({ emoji }: { emoji: EmojiData }) {
    async function handleDelete() {
      const foundEmojiData = initialReactions.find((emojiData) => {
        return emojiData.data.emoji === emoji.emoji;
      });

      if (!foundEmojiData) {
        return;
      }

      const emojiActorId = foundEmojiData.data.actorMiniProfile.id;

      if (userInfo?.id !== emojiActorId) return;

      if (!streamClient || !userInfo) return;

      const emojiId = foundEmojiData.id;

      const response = await deleteReaction(emojiId);

      if (response) {
        initComments();
      }
    }

    const backgroundColor =
      emoji.actorMiniProfile.id !== userInfo?.id
        ? "bg-[#EEEEEE]"
        : "bg-[#E4F3F9]";

    return (
      <span
        className={classNames(
          backgroundColor,
          emoji.actorMiniProfile.id === userInfo?.id
            ? "cursor-pointer"
            : "cursor-default",
          "py-1 px-2 rounded-md mr-2 inline-block border border-gray-300"
        )}
        role="img"
        aria-label={emoji.emoji}
        onClick={handleDelete}
      >
        {emoji.emoji}
      </span>
    );
  }

  function Emojis() {
    return (
      <div className={classNames("flex flex-wrap mt-3 items-center")}>
        {emojis.map((emoji, index) => (
          <Emoji key={index} emoji={emoji} />
        ))}
        <AddEmojiButton />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        userInfo?.miniProfile.numericId.toString() === comment.user_id
          ? "bg-[#E4F3F9]"
          : "bg-[#EEEEEE]",
        "p-3 mt-2 rounded-b-[10px] rounded-tr-[10px] rounded-tl-3px rounded-tl-0 text-[#222222] text-[15px]"
      )}
    >
      {editMode ? (
        <StreamEditBox
          onCancel={cancelEdit}
          onSave={saveComment}
          setText={setCommentContent}
          text={commentContent}
        />
      ) : (
        <>
          <ReactLinkify>
            <p className="whitespace-pre-line">{commentContent}</p>
          </ReactLinkify>
          <CommentImages />
          <ImageModal />
          <Emojis />
        </>
      )}
    </div>
  );
}

export default Body;
