import { createContext, useContext, useMemo, useState } from "react";
import { trainingAttributes } from "../Api/TrainingAttribute";

export interface TrainingAttributesContext {
  trainingAttributes?: trainingAttributes;
  setTrainingAttributes: (trainingAttributes?: trainingAttributes) => void;
}

const initialTrainingAttributesContext: TrainingAttributesContext = {
  trainingAttributes: undefined,
  setTrainingAttributes: () => {
    // do nothing.
  },
};

export const AttributesContext = createContext<TrainingAttributesContext>(
  initialTrainingAttributesContext
);

export function useTrainingAttributes() {
  const context = useContext(AttributesContext);
  if (!context && typeof window !== "undefined") {
    throw new Error("useUserInfo must be used within a UserInfoContext");
  }
  return context;
}

export function TrainingAttributesProvider({
  children,
  injectedValue,
}: {
  children: JSX.Element;
  injectedValue?: TrainingAttributesContext;
}) {
  function setTrainingAttributes(trainingAttributes?: trainingAttributes) {
    setTrainingAttributesContextState({
      ...trainingAttributesContextState,
      trainingAttributes: trainingAttributes,
    });
  }

  const initTrainingAttributesState: TrainingAttributesContext = {
    trainingAttributes: undefined,
    setTrainingAttributes: setTrainingAttributes,
  };

  const [trainingAttributesContextState, setTrainingAttributesContextState] =
    useState<TrainingAttributesContext>(initTrainingAttributesState);

  const value = useMemo(
    () => ({
      trainingAttributesContextState,
      setTrainingAttributesContextState,
    }),
    [trainingAttributesContextState]
  );

  return (
    <AttributesContext.Provider
      value={injectedValue ?? value.trainingAttributesContextState}
    >
      {children}
    </AttributesContext.Provider>
  );
}
