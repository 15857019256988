/* eslint-disable @typescript-eslint/ban-types */
import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

interface StreamEditBoxInput {
  text: string;
  setText: Function;
  onSave: Function;
  onCancel: Function;
}

function StreamEditBox({
  text,
  setText,
  onSave,
  onCancel,
}: StreamEditBoxInput) {
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  return (
    <div className="relative" onClick={(e) => e.stopPropagation()}>
      <div className="w-full border border-gray-300 rounded-lg shadow-sm focus-within:border-race-200 focus-within:ring-1 focus-within:ring-race-200">
        <TextareaAutosize
          rows={1}
          name="comment"
          id="comment"
          className="block w-full py-3 text-sm text-gray-500 border-0 resize-none caret-brand focus:ring-0 sm:text-sm"
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />

        <div className="py-2" aria-hidden="true">
          <div className="py-px">
            <div className="h-9" />
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 right-0 flex justify-between py-2 pl-3 pr-2">
        <div className="flex-shrink-0 space-x-2">
          <button
            onClick={() => onCancel()}
            className="inline-flex items-center px-2 py-1 text-sm font-medium bg-white border border-transparent rounded-md shadow-sm border-brand text-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
          >
            Cancel
          </button>
          <button
            disabled={saveButtonDisabled}
            onClick={() => {
              setSaveButtonDisabled(true);
              onSave();
            }}
            className="inline-flex items-center px-2 py-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm disabled:bg-gray-500 bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default StreamEditBox;
