import { useContext, useEffect, useState } from "react";
import { trainingEvent, TrainingEventStatus } from "../../../Api/TrainingEvent";
import { trainingProfile } from "../../../Api/TrainingProfile";
import { UserInfoContext } from "../../../Context/UserContext";
import { getDayDifference } from "../../../Utilities/DateUtilities";
import useDashboardContentStream from "../../Hooks/useDashboardContentStream";
import ActivityResultsOverview from "../../Components/ActivityResultsOverview";
import ResultsStats from "./Stats/ResultsStats";
import OtherActivities from "./OtherActivities/OtherActivities";
import { PerceivedExertionCard } from "./PerceivedExertionCard";

interface ActivityResultInput {
  trainingEvent: trainingEvent;
  trainingProfile?: trainingProfile;
}

function ActivityResults({
  trainingEvent,
  trainingProfile,
}: ActivityResultInput) {
  const { userInfo } = useContext(UserInfoContext);
  const [imageUrl, setImageUrl] = useState<string>();

  const { streamClient, feed } = useDashboardContentStream({});

  function hasResults() {
    if (
      getDayDifference(trainingEvent.scheduledAt) === -1 &&
      (trainingEvent.status === TrainingEventStatus.COMPLETED ||
        trainingEvent.status === TrainingEventStatus.MISSED)
    )
      return true;

    return false;
  }

  async function initImageUrl() {
    setImageUrl(trainingEvent.routePreviewImageUrl);
  }

  useEffect(() => {
    initImageUrl();
  }, [streamClient, feed]);

  useEffect(() => {
    initImageUrl();
  }, [trainingEvent]);

  return (
    <div id="activityResults">
      {hasResults() && userInfo ? (
        <>
          {imageUrl && (
            <img
              src={imageUrl}
              className="flow-root mb-5 border rounded-xl border-gray-300/50"
              onError={(event) =>
                ((event.target as HTMLImageElement).style.display = "none")
              }
            />
          )}
          <ActivityResultsOverview
            trainingEvent={trainingEvent}
            trainingProfile={trainingProfile}
          />
          <PerceivedExertionCard value={trainingEvent.perceivedExertion} />
          <ResultsStats trainingEvent={trainingEvent} />
        </>
      ) : (
        <div className="mb-5 border border-gray-300/50 rounded-xl">
          <div className="flex items-center">
            <div className="flex flex-col">
              <p className="px-4 py-4 font-medium text-gray-900 border-b">
                Workout not completed
              </p>
              <p className="px-4 py-6 text-gray-500">
                Check back for feedback and details after you’ve finished the
                workout.
              </p>
            </div>
          </div>
        </div>
      )}{" "}
      {/* eslint-disable-next-line no-restricted-globals */}
      {!location.pathname.includes("coach") && (
        <OtherActivities date={trainingEvent.scheduledAt} />
      )}
    </div>
  );
}

export default ActivityResults;
