import { InformationCircleIcon } from "@heroicons/react/outline";
import { IonModal } from "@ionic/react";
import { isBefore, sub } from "date-fns";
import { useState } from "react";
import { subscriptionPlans } from "../../../../Api/TrainingAttribute";
import { useTrainingAttributes } from "../../../../Context/TrainingAttributeContext";
import { useUserInfo } from "../../../../Context/UserContext";
import { useActivityFeedbackOutput } from "../../../Hooks/useActivityFeedback";
import UpgradeModalContent from "./UpgradeModalContent";

function UpgradeCTA({
  activityFeedback,
}: {
  activityFeedback: useActivityFeedbackOutput;
}) {
  const { userInfo } = useUserInfo();
  const { trainingAttributes } = useTrainingAttributes();
  const comments = activityFeedback.state.comments;
  const [showModal, setShowModal] = useState(false);

  function canShow() {
    // eslint-disable-next-line no-restricted-globals
    if (location.pathname.includes("coach")) {
      return false;
    }

    if (!userInfo || !trainingAttributes) return false;
    if (
      !comments[comments.length - 1] ||
      !comments[comments.length - 1].user_id
    )
      return false;
    if (
      comments[comments.length - 1].user_id !==
      userInfo.miniProfile.numericId.toString()
    )
      return false;
    if (trainingAttributes.productName !== subscriptionPlans[0]) return false;
    if (
      isBefore(
        new Date(comments[comments.length - 1].updated_at),
        sub(new Date(), { days: 7 })
      )
    )
      return false;

    return true;
  }

  return (
    <>
      {canShow() && (
        <div className="flex items-center self-center justify-center ">
          <div
            onClick={() => {
              setShowModal(true);
            }}
            className="flex items-center justify-center gap-1 px-3 py-2 bg-white border rounded-full shadow"
          >
            <InformationCircleIcon className="w-auto h-4" />
            <p className="text-neutral-600 font-medium text-[13px]">
              Your coach will check-in weekly
            </p>
          </div>
          <IonModal
            isOpen={showModal}
            initialBreakpoint={0.45}
            breakpoints={[0, 0.45]}
            onDidDismiss={() => setShowModal(false)}
            swipeToClose
          >
            <UpgradeModalContent
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </IonModal>
        </div>
      )}
    </>
  );
}

export default UpgradeCTA;
