import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import {
  editTrainingEvent,
  editTrainingEventInput,
  trainingEvent,
  TrainingEventStatus,
  TrainingEventType,
} from "../../Api/TrainingEvent";
import { UnitPreference } from "../../Api/User";
import { datesAreOnTheSameDay } from "../../Utilities/DateUtilities";
import { CalendarDay } from "../Calendar";
import { trainingProfile } from "../../Api/TrainingProfile";
import { ActivityRoute, getActivityRoute } from "../../Api/ActivityRoute";
import { toast } from "react-toastify";
import UpdatedStatus from "./UpdatedStatus";
import SelectedDayContentDropdown from "./SelectedDayContentDropdown";
import { classNames } from "../../Utilities/classnames";
import ActivityFeedback from "../../Coachee/Activity/Feedback/ActivityFeedback";
import useActivityFeedback from "../../Coachee/Hooks/useActivityFeedback";
import SelectedDayTabs, { Tab } from "./SelectedDayTabs";
import SelectedDayInstructions from "./SelectedDayInstructions";
import { useHistory, useLocation } from "react-router-dom";
import SelectedDayMore from "./SelectedDayMore";
import { TrainingEventTemplate } from "../../Api/TrainingEventTemplate";
import {
  EditableStep,
  getEditableSteps,
  getSteps,
  Step,
  TrainingEventStepType,
} from "../../Api/Step";
import _ from "lodash";
import SelectedDayResults from "./SelectedDayResults";
import { formatDistance, formatDuration } from "../../Utilities/FormatUtilties";

interface SelectedDayContentInput {
  selectedDay?: CalendarDay;
  setSelectedContent: React.Dispatch<
    React.SetStateAction<CalendarDay | TrainingEventTemplate | undefined>
  >;
  getPreviousSelectedDay: () => void | null;
  getNextSelectedDay: () => void;
  metricSetting: UnitPreference;
  initTrainingEvents: () => void;
  initCalendarMonth: () => void;
  selectedTrainingProfile: trainingProfile;
  handleDuplicate: (event: trainingEvent) => Promise<void>;
  handleDelete: (id: string, isOverwrite?: boolean) => Promise<void>;
  setSelectedDayContentHasChanged: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  onShowActivityRoute: (
    activityRoute: ActivityRoute,
    stravaUrl?: string | undefined
  ) => void;
  trainingEvents: trainingEvent[];
  currentTab?: Tab;
  createTemplate: (event: trainingEvent) => Promise<void>;
}

function SelectedDayContent({
  selectedDay,
  setSelectedContent,
  getPreviousSelectedDay,
  getNextSelectedDay,
  metricSetting,
  initTrainingEvents,
  initCalendarMonth,
  selectedTrainingProfile,
  handleDuplicate,
  handleDelete,
  setSelectedDayContentHasChanged,
  onShowActivityRoute,
  trainingEvents,
  currentTab,
  createTemplate,
}: SelectedDayContentInput) {
  const history = useHistory();
  const location = useLocation();
  const [currentScheduledAt, setCurrentScheduledAt] = useState<Date>();
  const [currentTitle, setCurrentTitle] = useState<string>("");
  const [currentDescription, setCurrentDescription] = useState<string[]>([]);
  const [currentNote, setCurrentNote] = useState<string>("");
  const [currentStatus, setCurrentStatus] = useState<TrainingEventStatus>();
  const [currentType, setCurrentType] = useState<TrainingEventType>(
    TrainingEventType.BASE
  );
  const [currentAssignedDistance, setCurrentAssignedDistance] =
    useState<string>("");
  const [currentAssignedDuration, setCurrentAssignedDuration] =
    useState<string>("");
  const [currentActivityRoute, setCurrentActivityRoute] =
    useState<ActivityRoute | null>(null);
  const [currentPostActivityFeedback, setCurrentPostActivityFeedback] =
    useState<string | null>(null);
  const [currentSteps, setCurrentSteps] = useState<EditableStep[]>(
    getEditableSteps(selectedDay?.event?.steps ?? [])
  );

  const activityFeedback = useActivityFeedback({
    trainingEvent: selectedDay?.event ? selectedDay.event : undefined,
  });

  const isDisabled =
    selectedDay?.event?.status === TrainingEventStatus.UNSCHEDULED;

  function hasChanged() {
    if (!selectedDay || !selectedDay.event) return false;

    const freshEvent = selectedDay.event;

    function stepsHaveChanged() {
      const currentStepsFormatted = getSteps(currentSteps).map((step) => {
        return {
          ...step,
          intensityMinZone: step.intensityMinZone ?? 0,
          intensityMaxZone: step.intensityMaxZone ?? 0,
          intensityMinZoneFraction: step.intensityMinZoneFraction ?? 1,
          intensityMaxZoneFraction: step.intensityMaxZoneFraction ?? 1,
          description: step.description?.replace(/(<([^>]+)>)/gi, "") ?? "",
          assignedDistance: formatDistance(
            step.assignedDistance ?? 0,
            metricSetting,
            true,
            false,
            true,
            2
          ),
          assignedDuration: formatDuration(step.assignedDuration ?? 0),
          subSteps:
            step.subSteps?.map((subStep) => {
              return {
                ...subStep,
                intensityMinZone: subStep.intensityMinZone ?? 0,
                intensityMaxZone: subStep.intensityMaxZone ?? 0,
                intensityMinZoneFraction: subStep.intensityMinZoneFraction ?? 1,
                intensityMaxZoneFraction: subStep.intensityMaxZoneFraction ?? 1,
                description:
                  subStep.description?.replace(/(<([^>]+)>)/gi, "") ?? "",
                assignedDistance: formatDistance(
                  subStep.assignedDistance ?? 0,
                  metricSetting,
                  true,
                  false,
                  true,
                  2
                ),
                assignedDuration: formatDuration(subStep.assignedDuration ?? 0),
              };
            }) ?? [],
        };
      });

      const freshStepsFormatted = freshEvent.steps.map((step) => {
        return {
          ...step,
          intensityMinZone: step.intensityMinZone ?? 0,
          intensityMaxZone: step.intensityMaxZone ?? 0,
          intensityMinZoneFraction: step.intensityMinZoneFraction ?? 1,
          intensityMaxZoneFraction: step.intensityMaxZoneFraction ?? 1,
          description: step.description?.replace(/(<([^>]+)>)/gi, "") ?? "",
          assignedDistance: formatDistance(
            step.assignedDistance ?? 0,
            metricSetting,
            true,
            false,
            true,
            2
          ),
          assignedDuration: formatDuration(step.assignedDuration ?? 0),
          subSteps:
            step.subSteps?.map((subStep) => {
              return {
                ...subStep,
                intensityMinZone: subStep.intensityMinZone ?? 0,
                intensityMaxZone: subStep.intensityMaxZone ?? 0,
                intensityMinZoneFraction: subStep.intensityMinZoneFraction ?? 1,
                intensityMaxZoneFraction: subStep.intensityMaxZoneFraction ?? 1,
                description:
                  subStep.description?.replace(/(<([^>]+)>)/gi, "") ?? "",
                assignedDistance: formatDistance(
                  subStep.assignedDistance ?? 0,
                  metricSetting,
                  true,
                  false,
                  true,
                  2
                ),
                assignedDuration: formatDuration(subStep.assignedDuration ?? 0),
              };
            }) ?? [],
        };
      });

      return !_.isEqual(currentStepsFormatted, freshStepsFormatted);
    }

    if (
      datesAreOnTheSameDay(
        freshEvent.scheduledAt,
        currentScheduledAt ?? freshEvent.scheduledAt
      ) &&
      freshEvent.title === currentTitle &&
      freshEvent.description.join("$").replaceAll(/<.*?>/gm, "").trimEnd() ===
        currentDescription.join("$").replaceAll(/<.*?>/gm, "").trimEnd() &&
      freshEvent.message.replaceAll(/<.*?>/gm, "").trimEnd() ===
        currentNote.replaceAll(/<.*?>/gm, "").trimEnd() &&
      freshEvent.trainingEventType === (currentType ?? null) &&
      freshEvent.assignedDistance === currentAssignedDistance &&
      freshEvent.assignedDuration === currentAssignedDuration &&
      !stepsHaveChanged()
    ) {
      return false;
    }

    return true;
  }

  async function openActivity() {
    if (selectedDay && selectedDay.event && selectedDay.event.activity) {
      const activityId = selectedDay.event.activity.replace(
        "perform:activity:",
        ""
      );

      if (activityId) {
        const response = await getActivityRoute(activityId);

        if (response) {
          setCurrentActivityRoute(response);
          if (selectedDay.event.externalUrl) {
            onShowActivityRoute(response, selectedDay.event.externalUrl);
          } else {
            onShowActivityRoute(response);
          }
        }
      }
    }

    return;
  }

  async function handleEdit() {
    if (
      currentScheduledAt &&
      currentScheduledAt !== selectedDay?.event?.scheduledAt &&
      trainingEvents.find((event) =>
        datesAreOnTheSameDay(currentScheduledAt, event.scheduledAt)
      ) !== undefined
    ) {
      window.alert(
        "Edit cannot be completed, workout already scheduled on this day."
      );
      return;
    }

    // if there is a repeat step and the count is less than 2, then we need to throw an error
    // "Repeat steps must have a count of 2 or more"
    const repeatStep = currentSteps.find(
      (step) => step.stepType === TrainingEventStepType.REPEAT
    );
    if (
      repeatStep &&
      repeatStep.repeats !== undefined &&
      repeatStep.repeats < 2
    ) {
      window.alert("Repeat steps must have a count of 2 or more");
      return;
    }

    // if there is a repeat step with less than 1 substep, then we need to throw an error
    // "Repeat steps must have at least 1 substep"
    if (
      repeatStep &&
      repeatStep.subSteps !== undefined &&
      repeatStep.subSteps.length < 1
    ) {
      window.alert("Repeat steps must have at least 1 substep");
      return;
    }

    // no steps can have a max intensity fraction of 0 or a min intensity fraction of 0
    const invalidSteps = currentSteps.filter(
      (step) =>
        step.intensityMaxZoneFraction === 0 ||
        step.intensityMinZoneFraction === 0
    );
    const invalidSubSteps = currentSteps
      .filter((step) => step.subSteps !== undefined)
      .flatMap((step) => step.subSteps)
      .filter(
        (subStep) =>
          (subStep?.intensityMinZoneFraction !== undefined &&
            subStep?.intensityMinZoneFraction === 0) ||
          (subStep?.intensityMaxZoneFraction !== undefined &&
            subStep?.intensityMaxZoneFraction === 0)
      );

    if (invalidSteps.length > 0 || invalidSubSteps.length > 0) {
      window.alert(
        "All steps and substeps must have a minimum and maximum intensity fraction greater than 0"
      );
      return;
    }

    if (selectedDay && selectedDay.event && currentStatus) {
      const selectedEvent = selectedDay.event;

      let didChangeFeedback = false;
      if (
        currentPostActivityFeedback !== "" &&
        currentPostActivityFeedback !== selectedDay.event.postActivityFeedback
      ) {
        didChangeFeedback = true;
      }

      const payload: editTrainingEventInput = {
        assignedDistance: currentAssignedDistance,
        assignedDuration: currentAssignedDuration,
        description: currentDescription,
        eventId: selectedEvent.id,
        message: currentNote,
        profile: selectedEvent.profile,
        scheduledAt: currentScheduledAt ?? selectedEvent.scheduledAt,
        status: currentStatus,
        title: currentTitle,
        trainingEventType: currentType,
        unitPreference: metricSetting,
        postActivityFeedback: currentPostActivityFeedback,
        postActivityFeedbackDidChange: didChangeFeedback,
        steps: getSteps(currentSteps),
      };

      const response = await editTrainingEvent(payload);

      if (!response) {
        alert("Edit operation could not be completed");
      } else {
        initTrainingEvents();
        initCalendarMonth();
        resetTrainingEvent({
          assignedDistance: response.assignedDistance,
          assignedDuration: response.assignedDuration,
          scheduledAt: response.scheduledAt,
          description: response.description,
          note: response.message,
          title: response.title,
          trainingEventType: response.trainingEventType,
          postActivityFeedback: response.postActivityFeedback,
          steps: response.steps,
        });

        resetSelectedDayEvent({
          ...selectedDay.event,
          assignedDistance: response.assignedDistance,
          assignedDuration: response.assignedDuration,
          scheduledAt: response.scheduledAt,
          description: response.description,
          message: response.message,
          status: response.status,
          title: response.title,
          trainingEventType: response.trainingEventType,
          postActivityFeedback: response.postActivityFeedback,
          steps: response.steps,
        });

        toast("Event successfully edited");
      }
    }
  }

  function handleDateChange(e: string) {
    const dateArray = e.split("-").map((number) => parseInt(number, 10));

    setCurrentScheduledAt(
      new Date(dateArray[0], dateArray[1] - 1, dateArray[2])
    );
  }

  async function initActivityRoute() {
    if (selectedDay && selectedDay.event && selectedDay.event.activity) {
      const activityId = selectedDay.event.activity.replace(
        "perform:activity:",
        ""
      );

      if (activityId && !currentActivityRoute) {
        const response = await getActivityRoute(activityId);

        if (response) {
          setCurrentActivityRoute(response);
        }
      }
    }
  }

  interface resetTrainingEventInput {
    scheduledAt?: Date;
    title: string;
    description: string[];
    note: string;
    status?: TrainingEventStatus;
    trainingEventType: TrainingEventType;
    assignedDistance: string;
    assignedDuration: string;
    postActivityFeedback: string | null;
    steps: Step[] | undefined;
  }

  function resetTrainingEvent({ ...props }: resetTrainingEventInput) {
    setCurrentScheduledAt(props.scheduledAt);
    setCurrentTitle(props.title);
    setCurrentDescription(props.description);
    setCurrentNote(props.note);

    if (props.status) setCurrentStatus(props.status);

    setCurrentType(props.trainingEventType);
    setCurrentAssignedDistance(props.assignedDistance);
    setCurrentAssignedDuration(props.assignedDuration);
    setCurrentPostActivityFeedback(props.postActivityFeedback);
    setCurrentSteps(getEditableSteps(props.steps ?? []));
  }

  function resetSelectedDayEvent(event: trainingEvent) {
    setSelectedContent(
      selectedDay ? { ...selectedDay, event: event } : undefined
    );
  }

  function initTrainingEvent() {
    initActivityRoute();

    if (selectedDay && selectedDay.event) {
      setCurrentScheduledAt(selectedDay.event.scheduledAt);
      setCurrentTitle(selectedDay.event.title);
      setCurrentDescription(selectedDay.event.description);
      setCurrentNote(selectedDay.event.message);
      setCurrentStatus(selectedDay.event.status);
      setCurrentType(selectedDay.event.trainingEventType);
      setCurrentAssignedDistance(selectedDay.event.assignedDistance);
      setCurrentAssignedDuration(selectedDay.event.assignedDuration);
      setCurrentPostActivityFeedback(selectedDay.event.postActivityFeedback);
      setCurrentSteps(getEditableSteps(selectedDay.event.steps) ?? []);
    }
  }

  useEffect(() => {
    if (selectedDay && currentTab === undefined) {
      history.replace(`${location.pathname}/plan`);
    }
  }, [currentTab]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setSelectedDayContentHasChanged(hasChanged());
    }

    return () => {
      mounted = false;
    };
  }, [
    currentScheduledAt,
    currentTitle,
    currentDescription,
    currentNote,
    currentType,
    currentAssignedDistance,
    currentAssignedDuration,
    currentSteps,
  ]);

  useEffect(() => {
    // if there is a step that is being edited, then we need to set selectedDayContentHasChanged to true
    if (currentSteps.find((step) => step.editing)) {
      setSelectedDayContentHasChanged(true);
    } else {
      setSelectedDayContentHasChanged(hasChanged());
    }
  }, [currentSteps, setSelectedDayContentHasChanged]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      initTrainingEvent();
    }

    return () => {
      mounted = false;
    };
  }, [selectedDay]);

  useEffect(() => {
    let mounted = true;

    if (
      mounted &&
      selectedDay &&
      selectedDay.event &&
      currentStatus !== selectedDay.event.status
    )
      handleEdit();

    return () => {
      mounted = false;
    };
  }, [currentStatus]);

  return (
    <>
      {selectedDay && selectedDay.event && (
        <div className="px-4 py-10 sm:px-6">
          <span className="items-center w-full mb-4 rounded-md shadow-sm">
            <div className="flex justify-between mb-6">
              <div>
                <div>
                  <button
                    onClick={() => getPreviousSelectedDay()}
                    type="button"
                    className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-brand focus:border-brand"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                  </button>
                  <button
                    onClick={() => getNextSelectedDay()}
                    type="button"
                    className="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-brand focus:border-brand"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="flex items-center gap-2">
                {currentStatus &&
                currentStatus !== TrainingEventStatus.UNSCHEDULED ? (
                  <>
                    <button
                      disabled={!hasChanged()}
                      onClick={() => handleEdit()}
                      type="button"
                      className={classNames(
                        "sm:inline-flex disabled:cursor-default disabled:bg-gray-300 cursor-pointer hidden justify-center px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                      )}
                    >
                      Save
                    </button>
                    <UpdatedStatus
                      currentStatus={currentStatus}
                      setSelectedStatus={setCurrentStatus}
                      isDisabled={isDisabled}
                    />
                  </>
                ) : (
                  <p className="text-sm italic text-gray-500">
                    This activity is read-only.
                  </p>
                )}

                <SelectedDayContentDropdown
                  handleDelete={() => {
                    if (selectedDay.event) handleDelete(selectedDay.event?.id);
                  }}
                  handleDuplicate={() => {
                    if (selectedDay.event) handleDuplicate(selectedDay.event);
                  }}
                  createTemplate={() => {
                    if (selectedDay.event) createTemplate(selectedDay.event);
                  }}
                />
              </div>
            </div>
            <div className="mb-6">
              <SelectedDayTabs
                otherEvents={selectedDay.otherEvents}
                activityFeedback={activityFeedback}
              />
            </div>
          </span>

          <form className="px-4 space-y-8 divide-y divide-gray-200">
            <div className="space-y-8">
              <div>
                <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6"></div>
              </div>

              {currentTab === Tab.PLAN ? (
                <SelectedDayInstructions
                  currentAssignedDistance={currentAssignedDistance}
                  currentAssignedDuration={currentAssignedDuration}
                  currentDescription={currentDescription}
                  currentType={currentType}
                  isDisabled={isDisabled}
                  selectedDay={selectedDay}
                  setCurrentAssignedDistance={setCurrentAssignedDistance}
                  setCurrentAssignedDuration={setCurrentAssignedDuration}
                  setCurrentDescription={setCurrentDescription}
                  selectedTrainingProfile={selectedTrainingProfile}
                  activityRoute={currentActivityRoute}
                  openActivity={openActivity}
                  currentScheduledAt={currentScheduledAt}
                  currentNote={currentNote}
                  currentTitle={currentTitle}
                  handleDateChange={handleDateChange}
                  setCurrentNote={setCurrentNote}
                  setCurrentTitle={setCurrentTitle}
                  setCurrentType={setCurrentType}
                  setCurrentSteps={setCurrentSteps}
                  currentSteps={currentSteps}
                />
              ) : currentTab === Tab.RESULTS ? (
                <SelectedDayResults
                  activityRoute={currentActivityRoute}
                  currentAssignedDistance={currentAssignedDistance}
                  currentAssignedDuration={currentAssignedDuration}
                  selectedDay={selectedDay}
                  openActivity={openActivity}
                  selectedTrainingProfile={selectedTrainingProfile}
                />
              ) : currentTab === Tab.COMMENTS ? (
                <ActivityFeedback
                  activityFeedback={activityFeedback}
                  trainingEvent={selectedDay.event}
                />
              ) : (
                <SelectedDayMore selectedDay={selectedDay} />
              )}
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default SelectedDayContent;
