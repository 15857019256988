export default function SaveButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className="bg-gradient-linear-02 text-white font-medium text-[14px] px-[16px] py-[8px] rounded-full hover:bg-primary-600 leading-[20px] inline-flex items-center space-x-[11px]"
    >
      Save
    </button>
  );
}
