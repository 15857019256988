import { useLocalStorage } from 'usehooks-ts'

import CoachHeader from '../Coach/Components/CoachHeader'

interface AdminLayoutProps {
    children: string | JSX.Element | JSX.Element[],
    title: string,
}

const AdminLayout = ({ children, title }: AdminLayoutProps) => {
    const setShowAllAthletes = useLocalStorage('showAllAthletes', false)[1]

    return (
        <>
            <CoachHeader
                showAllAthletes={true}
                setShowAllAthletes={setShowAllAthletes}
            />
            <div className="min-h-screen bg-gray-50">
                <div className="py-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="px-4 sm:px-6 lg:px-0">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-semibold text-gray-900">
                                    {title}
                                </h1>
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminLayout
