import { trainingEvent, TrainingEventType } from "../../Api/TrainingEvent";
import { UnitPreference } from "../../Api/User";

interface ActivityPillsInput {
  event: trainingEvent;
  metricSetting: UnitPreference;
  withGradient?: boolean;
}

function ActivityPills({
  event,
  metricSetting = UnitPreference.IMPERIAL,
  withGradient = false,
}: ActivityPillsInput) {
  function getMetric(): string {
    if (metricSetting === UnitPreference.IMPERIAL) return "Miles";

    return "Kilometers";
  }

  function gradientPillStyle(event: trainingEvent) {
    let textColor: string;

    switch (event.trainingEventType) {
      case TrainingEventType.BASE:
        textColor = "text-eventIndicatorBaseTo";
        break;
      case TrainingEventType.LONG:
        textColor = "text-eventIndicatorLongTo";
        break;
      case TrainingEventType.RACE:
        textColor = "text-eventIndicatorRaceTo";
        break;
      case TrainingEventType.RECOVERY:
        textColor = "text-eventIndicatorRecoveryTo";
        break;
      case TrainingEventType.REST:
        textColor = "text-eventIndicatorRestTo";
        break;
      case TrainingEventType.SPEED:
        textColor = "text-eventIndicatorSpeedTo";
        break;
      case TrainingEventType.CROSS_TRAINING:
        textColor = "text-eventIndicatorRaceTo";
        break;
      case TrainingEventType.WALK:
        textColor = "text-eventIndicatorBaseTo";
        break;
      default:
        textColor = "text-black";
        break;
    }

    return (
      "flex items-center gap-1.5 px-3 py-1 border border-white bg-white rounded-lg text-sm font-medium " +
      textColor
    );
  }

  function formatDistancePill(): string {
    if (isNaN(parseFloat(event.assignedDistance))) return "";

    let assignedDistance = parseFloat(event.assignedDistance).toFixed(1);

    if (assignedDistance.endsWith(".0"))
      assignedDistance = assignedDistance.replace(".0", "");

    return `${assignedDistance} ${getMetric()}`;
  }

  function formatDurationPill(): string {
    if (
      event.assignedDuration === "0:00" ||
      !event.assignedDuration.includes(":")
    )
      return "";

    let assignedDuration = event.assignedDuration.split(":")[0];

    return `${assignedDuration} Min`;
  }

  function renderPills() {
    let pills = [];

    if (
      ![TrainingEventType.REST, TrainingEventType.CROSS_TRAINING].includes(
        event.trainingEventType
      )
    ) {
      if (formatDistancePill() !== "") {
        if (withGradient) {
          pills.push(
            <p className={gradientPillStyle(event)}>{formatDistancePill()}</p>
          );
        } else {
          pills.push(
            <p className="flex items-center gap-1.5 px-3 py-1 border rounded-2xl text-sm font-medium text-gray-500">
              {formatDistancePill()}
            </p>
          );
        }
      }
      if (formatDurationPill() !== "") {
        if (withGradient) {
          pills.push(
            <p className={gradientPillStyle(event)}>{formatDurationPill()}</p>
          );
        } else {
          pills.push(
            <p className="flex items-center gap-1.5 px-3 py-1 border rounded-2xl text-sm font-medium text-gray-500">
              {formatDurationPill()}
            </p>
          );
        }
      }

      if (pills.length === 0) {
        if (withGradient) {
          pills.push(<p className={gradientPillStyle(event)}>TBD</p>);
        } else {
          pills.push(
            <p className="flex items-center gap-1.5 px-3 py-1 border rounded-2xl text-sm font-medium text-gray-500">
              TBD
            </p>
          );
        }
      }
    }

    return pills;
  }

  return (
    <div className="flex gap-2">
      {renderPills().map((pill, index) => (
        <div key={index}>{pill}</div>
      ))}
    </div>
  );
}

export default ActivityPills;
