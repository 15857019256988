import { trainingEvent, TrainingEventType } from "../../../Api/TrainingEvent";
import { UnitPreference } from "../../../Api/User";
import { DistanceBlock } from "./CalendarEventsBlock/DistanceBlock";
import { DurationBlock } from "./CalendarEventsBlock/DurationBlock";
import { PaceBlock } from "./CalendarEventsBlock/PaceBlock";

interface CalendarEventInput {
  event: trainingEvent | null;
  metricSetting: UnitPreference;
  otherEventsCount?: number;
}

function CalendarEvent({ event, metricSetting }: CalendarEventInput) {
  // why cross training is not a running event ?
  const isNonRunningEvent =
    event?.trainingEventType === TrainingEventType.CROSS_TRAINING ||
    event?.trainingEventType === TrainingEventType.REST;

  return (
    <>
      {event && !isNonRunningEvent && (
        <div>
          <DistanceBlock
            assignedDistance={event?.assignedDistance}
            actualDistance={event?.actualDistance}
            metricSetting={metricSetting}
          />
          <PaceBlock
            assignedDistance={event?.assignedDistance}
            actualDistance={event?.actualDistance}
            metricSetting={metricSetting}
            assignedDuration={event.assignedDuration}
            actualDuration={event.actualDuration}
          />
          <DurationBlock
            assignedDuration={event.assignedDuration}
            actualDuration={event.actualDuration}
          />
        </div>
      )}
    </>
  );
}

export default CalendarEvent;
