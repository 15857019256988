import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  useIonRouter,
  IonRouterLink,
} from "@ionic/react";
import BackArrow from "../../../Assets/BackArrow.svg";
import Back from "../../../Assets/Back.svg";
import TerraConnect from "../../../Assets/TerraConnect.svg";
import TerraConnected from "../../../Assets/TerraConnected.svg";
import { isMobile } from "../../../Utilities/isMobile";
import CoacheeHeader from "../../Components/CoacheeHeader";
import { useTrainingAttributes } from "../../../Context/TrainingAttributeContext";
import { useParams } from "react-router-dom";
import { removeExternalAccount } from "../../../Api/ExternalAccount";
import SettingsSubheader from "../ProfileSettings/SettingsSubheader";

export default function ManageTerra() {
  const routerHook = useIonRouter();
  const { trainingAttributes } = useTrainingAttributes();
  const { accountId } = useParams<{ accountId: string | undefined }>();

  const hasLoaded = !!trainingAttributes;

  const foundAccount = trainingAttributes?.accounts.find(
    (account) => account.id === accountId
  );

  const handleDeleteAccount = async () => {
    if (foundAccount && foundAccount.id) {
      const response = await removeExternalAccount(foundAccount.id);

      if (response) {
        // routerHook.push("/profile/settings", "back");
        window.location.href = "/profile/settings";
      } else {
        alert("Error deleting account");
      }
    }
  };

  if (foundAccount) {
    return (
      <IonPage>
        <SettingsSubheader title={`Manage ${foundAccount.provider}`} />
        <IonContent>
          <div className="max-w-xl h-full sm:h-fit mx-auto sm:mt-4 lg:mt-20 sm:px-6 lg:px-8">
            {!isMobile && (
              <IonRouterLink
                routerLink="/profile/settings"
                routerDirection="back"
              >
                <button className="items-center self-start hidden mb-12 ml-6 space-x-1 text-xs text-gray-400 rounded md:flex hover:text-gray-500">
                  <img src={Back} alt="Back" className="w-auto h-4" />
                  <p>Back</p>
                </button>
              </IonRouterLink>
            )}
            <div className="p-4 bg-white border-t-1 border-neutral-100 flex flex-col h-full">
              <div className="flex-grow flex flex-col items-center justify-center">
                <img src={TerraConnected} alt="connect to strava" />
                <h2 className="font-semibold text-[24px] leading-[32px] tracking-[-0.02em] mb-[2px] mt-16">
                  Successfully Connected!
                </h2>
                <p className="text-[18px] text-center leading-[28px] text-neutral-500 mt-2">
                  Runzy is able to view your {foundAccount.provider}{" "}
                  activities with stats.
                </p>
              </div>
              <div className="flex-grow-0 flex justify-center items-end">
                {/* delete button red text no background "Disconnect ${foundAccount.provider}" */}
                <button
                  className="flex items-center justify-center w-full text-[#EF4444] text-[18px] leading-[28px] font-medium rounded-[200px] mt-4 mb-[10px]"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteAccount();
                  }}
                >
                  Disconnect {foundAccount.provider}
                </button>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      {isMobile ? (
        <IonHeader>
          <IonToolbar className="pb-2 mt-2">
            <IonTitle>Can't find account</IonTitle>
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  routerHook.push("/profile/settings", "back");
                }}
              >
                <img src={BackArrow} alt="go back to profile" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : (
        <CoacheeHeader trainingAttributes={trainingAttributes} />
      )}
      <IonContent>
        {hasLoaded && (
          <div className="max-w-xl h-full sm:h-fit mx-auto sm:mt-4 lg:mt-20 sm:px-6 lg:px-8">
            {!isMobile && (
              <IonRouterLink
                routerLink="/profile/settings"
                routerDirection="back"
              >
                <button className="items-center self-start hidden mb-12 ml-6 space-x-1 text-xs text-gray-400 rounded md:flex hover:text-gray-500">
                  <img src={Back} alt="Back" className="w-auto h-4" />
                  <p>Back</p>
                </button>
              </IonRouterLink>
            )}
            <div className="p-4 bg-white border-t-1 border-neutral-100 flex flex-col h-full">
              <div className="flex-grow flex flex-col items-center justify-center">
                <img src={TerraConnect} alt="connect to strava" />
                <h2 className="font-semibold text-[24px] leading-[32px] tracking-[-0.02em] mb-[2px] mt-16">
                  Account not found
                </h2>
                <p className="text-[18px] text-center leading-[28px] text-neutral-500 mt-2">
                  We can't find the account you're trying to connect.
                </p>
              </div>
              <div className="flex-grow-0 flex justify-center items-end">
                <button
                  className="flex items-center justify-center w-full text-[#EF4444] text-[18px] leading-[28px] font-medium rounded-[200px] mt-4 mb-[10px]"
                  onClick={(e) => {
                    e.preventDefault();
                    routerHook.push("/profile/settings", "back");
                  }}
                >
                  Go back to settings
                </button>
              </div>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}
