import React, { FC, useState } from 'react'
import Pencil from '../../Assets/Pencil.svg'

interface EditableProps {
    value: string,
    onSave: (newValue: string, oldValue: string) => void,
}

interface EditViewProps {
    value: string,
    changed: boolean,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onCancel: () => void,
    onSave: () => void,
}

interface ValueViewProps {
    value: string,
    onToggle: () => void,
}

const EditView: FC<EditViewProps> = ({value, changed, onChange, onCancel, onSave}) => (
    <>
        <div className="inline-flex rounded-md shadow-sm" role="group">
            <input type="text"
                   value={value}
                   onChange={onChange}
                   className="block w-full border-gray-300 shadow-sm focus:ring-brand focus:border-brand sm:text-sm rounded-l-lg"/>
            <button type="button"
                    onClick={onCancel}
                    className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:bg-black-100 focus:z-10 focus:ring-2 focus:ring-black-100 focus:text-black-100">
                Cancel
            </button>
            <button type="button"
                    disabled={!changed}
                    onClick={(e) => {
                        e.preventDefault()
                        onSave()
                    }}
                    className="px-4 py-2 text-sm font-medium text-white rounded-r-md disabled:cursor-default disabled:bg-gray-300 bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand">
                Save
            </button>
        </div>
    </>
)

const ValueView: FC<ValueViewProps> = ({value, onToggle}) => (
    <>
        <span>{value}</span>
        <img src={Pencil} alt="" onClick={onToggle}
             className="hover:cursor-pointer hover:underline hover:underline-offset-2"/>
    </>
)

export const Editable: FC<EditableProps> = ({value, onSave}) => {
    const [newValue, setNewValue] = useState<string>(value)
    const [isEditing, setIsEditing] = useState<boolean>(false)

    return (
        <div className="flex gap-2 mr-2">
            {isEditing ?
                <EditView value={newValue}
                          changed={newValue !== value}
                          onChange={(e) => setNewValue(e.target.value)}
                          onCancel={() => {
                              setNewValue(value)
                              setIsEditing(false)
                          }}
                          onSave={() => {
                              onSave(newValue, value)
                              setIsEditing(false)
                          }}/> :
                <ValueView value={newValue} onToggle={() => setIsEditing(true)}/>}
        </div>
    )
}
