import { useContext, useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { trainingEvent, getTrainingEvents } from "../../Api/TrainingEvent";
import { UserInfoContext } from "../../Context/UserContext";
import { isMobile } from "../../Utilities/isMobile";

function useCoacheeDashboardContainer() {
  const history = useHistory();

  const cookies = useCookies(["defaultPage"])[0];

  const { userInfo } = useContext(UserInfoContext);
  const [trainingEvents, setTrainingEvents] = useState<trainingEvent[]>();

  const initTrainingEvents = useCallback(() => {
    async function initTrainingEvents() {
      if (userInfo) {
        const trainingEvents = await getTrainingEvents(
          userInfo.id,
          true,
          userInfo.profileMetricSetting
        );

        if (trainingEvents) {
          setTrainingEvents(trainingEvents);
        }
      }
    }

    initTrainingEvents();
  }, [userInfo]);

  function triggerRefresh() {
    initTrainingEvents();
  }

  useEffect(() => {
    function navigateIfCookie() {
      if (
        !isMobile &&
        cookies.defaultPage &&
        cookies.defaultPage === "settings"
      ) {
        history.push("/profile/settings");
      }
    }

    navigateIfCookie();
  }, [cookies.defaultPage, history]);

  useEffect(() => {
    if (!trainingEvents) {
      initTrainingEvents();
    }
  }, [userInfo, initTrainingEvents, trainingEvents]);

  return { trainingEvents, triggerRefresh };
}

export default useCoacheeDashboardContainer;
