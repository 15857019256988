import { useCallback, useContext, useEffect, useState } from "react";

import Message from "../Coachee/Components/Message";
import DashboardContent from "../Coachee/Components/DashboardContent";

import { UserInfoContext } from "../Context/UserContext";
import CoacheeHeader from "../Coachee/Components/CoacheeHeader";
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  isPlatform,
  RefresherEventDetail,
  useIonRouter,
} from "@ionic/react";
import { StatusBar, Style } from "@capacitor/status-bar";

import { isMobile } from "../Utilities/isMobile";
import { useLocation, useParams } from "react-router-dom";
import EmptyState from "../Coachee/Components/EmptyState";
import DashboardModal from "../Coachee/Components/DashboardModal";
import { Post } from "../Api/Stream";
import { createOutline } from "ionicons/icons";
import TailwindDashboardModal from "../Coach/Components/TailwindDashboardModal";
import useDashboardContentStream from "../Coachee/Hooks/useDashboardContentStream";
import useCapacitorStatusBar from "../Utilities/Hooks/useCapacitorStatusBar";
import useCoacheeDashboardContainer from "../Coachee/Hooks/useCoacheeDashboardContainer";
import { useTrainingAttributes } from "../Context/TrainingAttributeContext";
import DefaultWebFooter from "../Coach/Components/Footer/DefaultWebFooter";

export type ModalContent =
  | {
    post?: Post;
    id: string;
    foreign_id: string;
  }
  | "new_post"
  | undefined;

interface CoacheeDashboardInput {
  router: HTMLIonRouterOutletElement | null;
}

function CoacheeDashboard({ router }: CoacheeDashboardInput) {
  const { userInfo } = useContext(UserInfoContext);
  const { triggerRefresh, trainingEvents } = useCoacheeDashboardContainer();
  const { trainingAttributes } = useTrainingAttributes();
  const location = useLocation();
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [modalContent, setModalContent] = useState<ModalContent>();
  const params = useParams<{ activityId?: string }>();
  const dashboardContentStreamHook = useDashboardContentStream({
    refreshTrigger: refreshTrigger,
  });
  const [modalContentHasLoaded, setModalContentHasLoaded] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const routerHook = useIonRouter();
  const { setStatusBarStyleDark, setStatusBarStyleLight } =
    useCapacitorStatusBar();

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    setRefreshTrigger(refreshTrigger + 1);
    triggerRefresh();
    dashboardContentStreamHook.setCanAppend(true);

    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  }

  useEffect(() => {
    if (location.pathname === "/dashboard/training-log") {
      setRefreshTrigger((r) => r + 1);
    }
  }, [location.pathname]);

  const adjustModalContent = useCallback(() => {
    function adjustStatusBar() {
      if (modalContent) {
        setStatusBarStyleDark();
      } else if (!isPlatform("android")) {
        setStatusBarStyleLight();
      }
    }

    function adjustRoute() {
      if (
        modalContent === undefined &&
        params.activityId &&
        modalContentHasLoaded
      ) {
        routerHook.push("/dashboard/training-log", "none");
      }

      if (
        modalContent !== undefined &&
        modalContent !== "new_post" &&
        !params.activityId
      ) {
        routerHook.push(
          `/dashboard/training-log/${modalContent.post?.id}`,
          "none"
        );
      }
    }

    adjustStatusBar();
    adjustRoute();
    if (modalContent && modalContent !== "new_post")
      setModalContentHasLoaded(true);
  }, [
    modalContent,
    modalContentHasLoaded,
    params.activityId,
    routerHook,
    setStatusBarStyleDark,
    setStatusBarStyleLight,
  ]);

  useEffect(() => {
    adjustModalContent();
  }, [adjustModalContent]);

  useEffect(() => {
    if (params.activityId && dashboardContentStreamHook.feedItems) {
      const foundFeedItem = dashboardContentStreamHook.feedItems.find(
        (feedItem) => feedItem.id === params.activityId
      );
      if (foundFeedItem) {
        setModalContent({
          post: foundFeedItem as Post,
          id: foundFeedItem.id,
          foreign_id: foundFeedItem.foreign_id,
        });
      }
    }
  }, [dashboardContentStreamHook.feedItems, params.activityId]);

  useEffect(() => {
    if (isPlatform("capacitor")) {
      StatusBar.setStyle({ style: Style.Default });
    }
  }, []);

  return (
    <IonPage>
      <CoacheeHeader
        trainingAttributes={trainingAttributes}
        hasScrolled={hasScrolled}
      />
      {userInfo && (
        <>
          <IonContent
            scrollEvents={true}
            onIonScroll={(e) => setHasScrolled(e.detail.scrollTop !== 0)}
          >
            {isMobile && (
              <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            <div className="mx-5 lg:max-w-xl lg:mx-auto lg:mt-20">
              {trainingEvents && trainingAttributes ? (
                <>
                  <Message attributes={trainingAttributes} />

                  {trainingEvents.length > 0 ? (
                    <>
                      <DashboardContent
                        events={trainingEvents}
                        refreshTrigger={refreshTrigger}
                        setModalContent={setModalContent}
                        dashboardContentStreamHook={dashboardContentStreamHook}
                      />
                    </>
                  ) : (
                    <>
                      <EmptyState />
                    </>
                  )}
                </>
              ) : (
                <div className="flex items-center justify-center pt-48 space-x-2">
                  <div
                    className="inline-block w-4 h-4 rounded-full text-brand spinner-border animate-spin border-1"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>{" "}
            {isMobile ? (
              <>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                  <IonFabButton
                    className="w-14 h-14"
                    color="secondary"
                    onClick={() => {
                      setModalContent("new_post");
                      if ("activeElement" in document) {
                        (document.activeElement as HTMLElement).blur();
                      }
                    }}
                  >
                    <IonIcon
                      style={{ "--ionicon-stroke-width": "46px" }}
                      className="pl-1 text-2xl"
                      icon={createOutline}
                    />
                  </IonFabButton>
                </IonFab>
                <>
                  <IonModal
                    isOpen={modalContent !== undefined}
                    swipeToClose={true}
                    presentingElement={router || undefined}
                    onDidDismiss={() => setModalContent(undefined)}
                  >
                    <DashboardModal
                      handleRefresh={() =>
                        setRefreshTrigger(refreshTrigger + 1)
                      }
                      closeModal={() => setModalContent(undefined)}
                      modalContent={modalContent}
                    />
                  </IonModal>
                </>
              </>
            ) : (
              <>
                <TailwindDashboardModal
                  modalContent={modalContent}
                  setOpen={setModalContent}
                >
                  <DashboardModal
                    handleRefresh={() => setRefreshTrigger(refreshTrigger + 1)}
                    closeModal={() => setModalContent(undefined)}
                    modalContent={modalContent}
                  />
                </TailwindDashboardModal>
              </>
            )}
          </IonContent>
          {!isMobile && <DefaultWebFooter />}
        </>
      )}
    </IonPage>
  );
}

export default CoacheeDashboard;
