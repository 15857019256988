import axios from 'axios'
import type { AxiosError } from 'axios'
import {FormValidation} from "./Common";

export type SubscriptionAction = {
  action: string;
  value?: string | null;
  email?: string | null;
  productType?: string | null;
  referral?: string | null;
  checkoutSessionId?: string | null;
};

export interface Subscription {
  transactionId: string;
  productId: string;
  expirationDate: number;
  subscriptionName?: string | null;
  subscriptionPrice?: string | null;
}

export enum ProductType {
  BASIC = "BASIC",
  CORE = "CORE",
  PLUS = "PLUS",
}

export async function getSubscriptionsForSignedInUser(): Promise<
  Subscription[] | null
> {
  try {
    const response = await axios.get("subscriptions");
    return await response.data.items;
  } catch (error: any) {
    return null;
  }
}

export async function getClientSecret(): Promise<FormValidation | null> {
  try {
    const subscriptionAction: SubscriptionAction = {
      action: "getStripeSecret",
      value: null,
    };
    const response = await axios.post("subscriptions", subscriptionAction);

    return response.data;
  } catch (error: any) {
    return null;
  }
}

export async function updatePaymentStatus(): Promise<FormValidation | null> {
  try {
    const subscriptionAction: SubscriptionAction = {
      action: "updatePaymentStatus",
      value: null,
    };
    const response = await axios.post("subscriptions", subscriptionAction);

    return response.data;
  } catch (error: any) {
    return null;
  }
}

export async function getPaymentUrlForExperiment(
  experimentId: string | null
): Promise<FormValidation | null> {
  try {
    const subscriptionAction: SubscriptionAction = {
      action: "getStripePaymentUrl",
      value: experimentId,
    };
    const response = await axios.post("subscriptions", subscriptionAction);

    return response.data;
  } catch (error: any) {
    return null;
  }
}

export async function getPaymentUrlForProduct(
    product: ProductType,
    email: string,
    referral?: string,
): Promise<FormValidation | null> {
    return new Promise(async (resolve, reject) => {
        const subscriptionAction: SubscriptionAction = {
            action: 'getStripePaymentUrlForEmail',
            email: email,
            referral: referral,
            productType: product,
        }

        try {
            const response = await axios.post('subscriptions', subscriptionAction)
            resolve(response.data)
        } catch (error: any) {
            reject(error.response?.data)
        }
    })
}

export async function refreshStripeSubscriptionForEmail(
  email: string
): Promise<FormValidation | null> {
  try {
    const subscriptionAction: SubscriptionAction = {
      action: "refreshStripeSubscriptionForEmail",
      email: email,
    };
    const response = await axios.post("subscriptions", subscriptionAction);

    return response.data;
  } catch (error: any) {
    return null;
  }
}

export async function refreshStripeSubscriptionFromCheckoutSessionId(
  email: string,
  checkoutSessionId?: string,
): Promise<FormValidation | null> {
  try {
    const subscriptionAction: SubscriptionAction = {
      action: "refreshStripeSubscriptionForCheckoutSessionId",
      checkoutSessionId: checkoutSessionId,
      email: email,
    };
    const response = await axios.post("subscriptions", subscriptionAction);

    return response.data;
  } catch (error: any) {
    return null;
  }
}
