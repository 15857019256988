import useAuth from "../../Common/Hooks/useAuth";

export function MusicHeader() {
  const { handleSignOut } = useAuth();

  return (<header>
    <div className="relative overflow-hidden overflow-y-hidden bg-white">
      <div className="relative pt-6">
        <div>
          <nav className="relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div className="flex flex-1 items-center">
              <div className="flex w-full items-center justify-between md:w-auto">
                <a href="https://joinperform.com" target="_blank">
                  <span className="sr-only">Perform</span>
                  <img className="h-8 w-auto sm:h-10" src="https://joinperform.com/img/perform-logo.svg" alt=""/>
                </a>
              </div>
            </div>
            <div className="md:flex">
              <a onClick={() => handleSignOut()}
                 className="cursor-pointer inline-flex items-center rounded-md border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-black hover:border-gray-400">Sign
                Out</a>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>);
}