import Bullseye from "../../../Assets/Bullseye.svg";
import { useTrainingAttributes } from "../../../Context/TrainingAttributeContext";
import { useUserInfo } from "../../../Context/UserContext";
import { classNames } from "../../../Utilities/classnames";
import Gear from "../../../Assets/Gear.svg";
import { useIonRouter } from "@ionic/react";
import {
  isMobileEmbeddedView
} from "../../../Api/Auth";
import ProfilePicture from "../../../Utilities/Components/ProfilePicture";

function QuickInfo() {
  const { userInfo } = useUserInfo();
  const { trainingAttributes } = useTrainingAttributes();
  const routerHook = useIonRouter();
  return (
    <div>
      {!isMobileEmbeddedView() && (
        <div className="flex flex-row-reverse">
          <button onClick={() => routerHook.push("/profile/settings")}>
            <img className="w-8" src={Gear} alt="go to settings" />
          </button>
        </div>
      )}
      <div className={classNames("flex items-center")}>
        <ProfilePicture
          url={userInfo?.profileImage ?? ""}
          alt=""
          classNames="h-[134.4px] w-[134.4px] rounded-3xl mr-5"
        />

        <div className="flex items-center justify-between gap-2 grow">
          {" "}
          <div className="space-y-1">
            <h2 className="font-semibold text-[#222222] text-xl text-ellipsis overflow-hidden max-w-[140px]">
              {userInfo?.firstName} {userInfo?.lastName}
            </h2>
            {trainingAttributes && trainingAttributes.goalName && (
              <div className="flex items-center gap-1">
                <img
                  src={Bullseye}
                  alt="goal icon"
                  className="h-[16px] self-start mt-1 w-[16px]"
                />
                <p className="text-[#717171] text-[15px]">
                  {trainingAttributes?.goalName}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuickInfo;
