import { connect, StreamClient } from "getstream";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { postComment } from "../../Api/Comment";
import { Comment } from "../../Api/Stream";
import { track, TrackingEventType } from "../../Api/Tracking";
import { trainingEvent } from "../../Api/TrainingEvent";
import { UserInfoContext } from "../../Context/UserContext";
import Default from "../../Default/Default";

interface useActivityFeedbackInput {
  trainingEvent?: trainingEvent;
  id?: string;
  foreign_id?: string;
}

export type State = {
  streamClient?: StreamClient;
  comments: Comment[];
  commentBoxContent: string;
  isDisabled: boolean;
  commentToBeDeleted?: Comment;
};

const initState: State = {
  streamClient: undefined,
  comments: [],
  commentBoxContent: "",
  isDisabled: false,
  commentToBeDeleted: undefined,
};

function useActivityFeedback({
  trainingEvent,
  id,
  foreign_id,
}: useActivityFeedbackInput) {
  const { userInfo } = useContext(UserInfoContext);
  const location = useLocation();

  const hookId = trainingEvent ? trainingEvent.feedActivityId : id;
  const hookForeignId = trainingEvent ? trainingEvent.id : foreign_id;

  const [state, setState] = useState<State>(initState);

  function handleCommentBoxContentChange(e: string) {
    setState({ ...state, commentBoxContent: e });
  }

  async function handleSubmitComment() {
    if (
      !state.streamClient ||
      state.commentBoxContent === "" ||
      !userInfo ||
      !hookForeignId ||
      !hookId
    )
      return;

    setState({ ...state, isDisabled: true });

    const entity = trainingEvent
      ? trainingEvent.id
      : `perform:streamActivity:${id}`;

    const response = await postComment(entity, state.commentBoxContent);

    if (response) {
      setState({
        ...state,
        isDisabled: false,
      });

      if (location.pathname.includes("coach"))
        track(
          TrackingEventType.TRACKING_APP_COACH_SUBMIT_COMMENT,
          `${userInfo.miniProfile.name}(${userInfo.miniProfile.numericId}) commented on ${entity}`,
          "notifications"
        );

      setupComments();
    }
  }

  function canSubmitComment() {
    if (trainingEvent && trainingEvent.feedActivityId) return true;
    return id || foreign_id ? true : false;
  }

  function activateDeleteModal(reactionId: string) {
    setState({
      ...state,
      commentToBeDeleted: state.comments.find(
        (comment) => comment.id === reactionId
      ) as Comment,
    });
  }

  function deactivateCommentModal() {
    setState({
      ...state,
      commentToBeDeleted: undefined,
    });
  }

  async function handleDeleteComment() {
    if (!state.streamClient || !userInfo || !state.commentToBeDeleted) return;

    const response = await state.streamClient.reactions.delete(
      state.commentToBeDeleted.id
    );

    if (response) {
      setupComments();
      deactivateCommentModal();
    }
  }

  const setupComments = useCallback(() => {
    async function initComments() {
      if (!state.streamClient || !hookId) return;

      const reactions = await state.streamClient.reactions.filter({
        // activity_id: event.feedActivityId,
        activity_id: hookId,
        kind: "comment",
        limit: 100,
      });

      const commentReactions = reactions.results;

      setState((state) => {
        return {
          ...state,
          commentBoxContent: "",
          commentToBeDeleted: undefined,
          comments: commentReactions.reverse() as Comment[],
        };
      });
    }

    initComments();
  }, [hookId, state.streamClient]);

  function initComments() {
    setupComments();
  }

  useEffect(() => {
    setupComments();
  }, [setupComments]);

  useEffect(() => {
    function initStreamClient() {
      if (userInfo) {
        const client = connect(
          Default.streamsApiKey,
          userInfo.streamAPIAuth,
          Default.streamsAppId
        );

        setState((state) => {
          return { ...state, streamClient: client };
        });
      }
    }

    initStreamClient();
  }, [userInfo]);

  return {
    state,
    activateDeleteModal,
    deactivateCommentModal,
    handleCommentBoxContentChange,
    handleSubmitComment,
    handleDeleteComment,
    canSubmitComment,
    initComments,
  } as useActivityFeedbackOutput;
}

export interface useActivityFeedbackOutput {
  state: State;
  activateDeleteModal: (reactionId: string) => void;
  deactivateCommentModal: () => void;
  handleCommentBoxContentChange: (e: string) => void;
  handleSubmitComment: () => Promise<void>;
  handleDeleteComment: () => Promise<void>;
  canSubmitComment: () => boolean;
  initComments: () => void;
}

export default useActivityFeedback;
