import { add, isSameWeek, isThisWeek } from "date-fns";
import { trainingEvent, TrainingEventType } from "../../Api/TrainingEvent";

export function getThisWeeksEvents(events: trainingEvent[]) {
  return events
    .filter((event) => isThisWeek(event.scheduledAt, { weekStartsOn: 1 }))
    .sort((a, b) => {
      return a.scheduledAt.getTime() - b.scheduledAt.getTime();
    });
}

export function getNextWeeksEvents(events: trainingEvent[]) {
  return events
    .filter((event) =>
      isSameWeek(add(new Date(), { days: 7 }), event.scheduledAt, {
        weekStartsOn: 1,
      })
    )
    .sort((a, b) => {
      return a.scheduledAt.getTime() - b.scheduledAt.getTime();
    });
}

export function getDecorationColor(event: trainingEvent) {
  switch (event.trainingEventType) {
    case TrainingEventType.BASE:
      return "bg-base-500";
    case TrainingEventType.CROSS_TRAINING:
      return "bg-race-500";
    case TrainingEventType.LONG:
      return "bg-long-500";
    case TrainingEventType.RACE:
      return "bg-race-500";
    case TrainingEventType.RECOVERY:
      return "bg-recovery-500";
    case TrainingEventType.REST:
      return "bg-rest-500";
    case TrainingEventType.SPEED:
      return "bg-speed-500";
    case TrainingEventType.WALK:
      return "bg-race-500";
    default:
  }
}
