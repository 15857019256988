import { trainingEvent, TrainingEventType } from "../../Api/TrainingEvent";
import { trainingProfile } from "../../Api/TrainingProfile";
import CompletionRing from "./CompletionRing";

interface ActivityResultsOverviewInput {
  trainingEvent: trainingEvent;
  trainingProfile?: trainingProfile;
  includeExertionInFeedback?: boolean;
}

function ActivityResultsOverview({
  trainingEvent,
  trainingProfile,
  includeExertionInFeedback = false,
}: ActivityResultsOverviewInput) {
  const subject = trainingProfile
    ? trainingProfile.miniProfile.name.split(" ")[0]
    : "You";

  const modifiedFeedback = trainingProfile
    ? trainingEvent.completionRatioFeedback.map((feedback) =>
        feedback
          .replaceAll(
            /[Y|y]our/gm,
            `${trainingProfile.miniProfile.name.split(" ")[0]}'s`
          )
          .replaceAll(
            /[Y|y]ou/gm,
            trainingProfile.miniProfile.name.split(" ")[0]
          )
          .replaceAll(/(?:\w+'s)\s*(race)/g, "their race")
      )
    : trainingEvent.completionRatioFeedback;

  const header = () => {
    const x = trainingEvent.completionRatio;

    if (
      x &&
      x <= 100 &&
      // trainingEvent.trainingEventType === TrainingEventType.REST
      [TrainingEventType.REST, TrainingEventType.CROSS_TRAINING].includes(
        trainingEvent.trainingEventType
      )
    )
      return "Way to go!";

    switch (x) {
      case null:
        return "Oh no...";
      case x && x < 25:
        return "Oh no...";
      case x && x < 50:
        return "Okay";
      case x && x < 75:
        return "Good job";
      case x && x < 95:
        return "Great job";
      default:
        return "Perfect!";
    }
  };

  const subHeader = () => {
    if (trainingEvent.trainingEventType !== TrainingEventType.REST) {
      if (trainingEvent.completionRatio && trainingEvent.completionRatio > 0) {
        return `${subject} completed the workout`;
      } else {
        return `${subject} didn't complete the workout`;
      }
    } else {
      if (trainingEvent.completionRatio && trainingEvent.completionRatio > 0) {
        return `${subject} took the time to recover`;
      } else {
        return "Rest is an important part of training";
      }
    }
  };

  if (trainingEvent.perceivedExertion && includeExertionInFeedback) {
    let level = "Hard";
    if (trainingEvent.perceivedExertion <= 7) {
      level = "Moderate";
    }
    if (trainingEvent.perceivedExertion <= 3) {
      level = "Easy";
    }
    modifiedFeedback.push(
      `${subject} reported a perceived exertion of ${trainingEvent.perceivedExertion}/10 (${level})`
    );
  }

  return (
    <div className="mb-5 border rounded-xl border-gray-300/50">
      <div className="flex items-center">
        <div className="flex flex-col w-full">
          <p className="flex items-center justify-between px-4 py-4 border-b">
            <div>
              <h3 className="font-medium">{header()}</h3>
              <p className="text-sm text-gray-400">{subHeader()}</p>
            </div>
            <CompletionRing event={trainingEvent} inView={true} />
          </p>
          <p className="px-4 py-6 space-y-2 text-sm">
            {modifiedFeedback.map((feedback, index) => (
              <div key={index} className="flex space-x-1.5">
                <div className="w-1.5 mt-2 h-1.5 bg-brand rounded-full"></div>
                <p>{feedback}</p>
              </div>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ActivityResultsOverview;
