import { IonContent, IonPage, useIonRouter, useIonToast } from "@ionic/react";
import Header from "./Header";
import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import { encodeSecondsTimestampFromDate } from "../../../Utilities/DateUtilities";
import {
  ActivityType,
  activityTypeLocalization,
  createActivity,
  isDistanceBased,
} from "../../../Api/Activity";
import { encodeDistance } from "../../../Utilities/FormatUtilties";
import { useUserInfo } from "../../../Context/UserContext";
import { isMobile } from "../../../Utilities/isMobile";
import { UnitPreference } from "../../../Api/User";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import DefaultWebFooter from "../../../Coach/Components/Footer/DefaultWebFooter";

function CreatePage() {
  const { userInfo } = useUserInfo();
  const routerHook = useIonRouter();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [present, dismiss] = useIonToast();
  const params = useParams<{ defaultDate: string | undefined }>();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startedAt, setStartedAt] = useState(getAccurateInitialDate());
  const [type, setType] = useState<ActivityType>(ActivityType.RUN);
  const [sec, setSec] = useState<number>();
  const [min, setMin] = useState<number>();
  const [hr, setHr] = useState<number>();
  const [distance, setDistance] = useState("");
  const [submitIsDisabled, setSubmitIsDisabled] = useState(false);

  function totalSeconds() {
    let totalSeconds = 0;

    if (hr) totalSeconds += hr * 3600;
    if (min) totalSeconds += min * 60;
    if (sec) totalSeconds += sec;

    return totalSeconds;
  }

  function getAccurateInitialDate() {
    if (!params.defaultDate) return new Date();

    const date = new Date(params.defaultDate);
    date.setDate(date.getDate() + 1);

    return date;
  }

  // useEffect(() => {
  //   console.log(type);

  //   const pairs = Object.keys(ActivityType).map((type) => {
  //     return { type, value: ActivityType[type as keyof typeof ActivityType] };
  //   });

  //   const foundKey = pairs.find((pair) => pair.value === type)?.type;
  //   console.log(foundKey);
  // }, [type]);

  async function handleSubmit() {
    if (!userInfo) return;

    setSubmitIsDisabled(true);

    const response = await createActivity({
      name: title,
      description: description,
      // activityType: type,
      activityType: type,
      startedAt: encodeSecondsTimestampFromDate(startedAt, false),
      lengthSeconds: totalSeconds(),
      distanceInMeters: encodeDistance(distance, userInfo.profileMetricSetting),
    });

    if (response) {
      // toast("A new activity has been created.");
      present({
        message: "A new activity has been created.",
        duration: 3000,
        position: "top",
        color: "dark",
        cssClass: "toastPosition",
      });
      // routerHook.push(`/activities/${response.id}`);
      routerHook.goBack();
    }

    setSubmitIsDisabled(false);
  }

  function handleSelectChange(e: string) {
    setType(ActivityType[e as keyof typeof ActivityType]);
  }

  return (
    <IonPage>
      <Header />
      <IonContent>
        {" "}
        <div className="mx-5 lg:max-w-xl lg:mx-auto lg:pt-20">
          {!isMobile && (
            <h1 className="font-semibold leading-tight mt-5 text-[28px] mb-2">
              Manual Entry
            </h1>
          )}
          <select
            onChange={(e) => handleSelectChange(e.target.value)}
            className="w-full rounded-lg text-[14px] placeholder:text-[#717171] mt-4 border-race-100 ring-race-100 focus:border-race-100 focus:ring-race-100"
          >
            {activityTypeLocalization.map((activityType) => (
              <option key={activityType.type} value={activityType.type}>
                {activityType.name}
              </option>
            ))}
          </select>
          <TextareaAutosize
            className="w-full rounded-lg resize-none text-[14px] placeholder:text-[#717171] mt-2 border-race-100 ring-race-100 focus:border-race-100 focus:ring-race-100"
            placeholder="Activity Title"
            minRows={1}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextareaAutosize
            className="w-full rounded-lg resize-none text-[14px] placeholder:text-[#717171] mt-2 border-race-100 ring-race-100 focus:border-race-100 focus:ring-race-100"
            placeholder="How’d it go? Share more about your activity"
            minRows={2}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <h2 className="mt-4 font-semibold text-[18px] text-black">
            Activity Stats
          </h2>{" "}
          <input
            className="flex-1 block w-full min-w-0  text-[14px] placeholder:text-[#717171] my-2 rounded-md border-race-100 ring-race-100 focus:border-race-100 focus:ring-race-100"
            type="datetime-local"
            name="date"
            id="date"
            autoComplete="date"
            value={format(startedAt, "yyyy-MM-dd'T'HH:mm")}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setStartedAt(new Date(e.target.value));
              }
            }}
          />{" "}
          <div className="flex items-center gap-2">
            <input
              className="rounded-lg text-[14px] placeholder:text-[#717171] border-race-100 ring-race-100 focus:border-race-100 focus:ring-race-100"
              placeholder="h"
              id="h"
              name="h"
              type="number"
              min="0"
              max="24"
              value={hr}
              onChange={(e) => setHr(parseInt(e.target.value))}
            />
            <input
              className="rounded-lg resize-none text-[14px] placeholder:text-[#717171] border-race-100 ring-race-100 focus:border-race-100 focus:ring-race-100"
              placeholder="m"
              id="m"
              name="m"
              type="number"
              min="0"
              max="59"
              value={min}
              onChange={(e) => setMin(parseInt(e.target.value))}
            />
            <input
              className="rounded-lg resize-none text-[14px] placeholder:text-[#717171] border-race-100 ring-race-100 focus:border-race-100 focus:ring-race-100"
              placeholder="s"
              id="s"
              name="s"
              type="number"
              min="0"
              max="59"
              value={sec}
              onChange={(e) => setSec(parseInt(e.target.value))}
            />
          </div>
          {isDistanceBased(type) && (
            <TextareaAutosize
              className="w-full rounded-lg resize-none text-[14px] placeholder:text-[#717171] mt-2 border-race-100 ring-race-100 focus:border-race-100 focus:ring-race-100"
              placeholder={`0.00 ${
                userInfo?.profileMetricSetting === UnitPreference.IMPERIAL
                  ? "mi"
                  : "km"
              }`}
              minRows={1}
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
            />
          )}{" "}
          <button
            disabled={submitIsDisabled}
            onClick={() => {
              handleSubmit();
            }}
            className="flex items-center justify-center w-full py-2 mt-4 text-white rounded-full disabled:bg-gray-500"
            style={
              submitIsDisabled
                ? {
                    background: "rgb(107 114 128)",
                  }
                : {
                    background: "linear-gradient(91.88deg, #F6747E 5.14%, #FF6388 99.6%)",
                  }
            }
          >
            Save Activity
          </button>
        </div>
      </IonContent>
      {!isMobile && <DefaultWebFooter />}
    </IonPage>
  );
}

export default CreatePage;
