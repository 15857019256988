import { trainingEvent, TrainingEventType } from "../../Api/TrainingEvent";
import { formatDateForTodaysEvent } from "../../Utilities/DateUtilities";
import { track, TrackingEventType } from "../../Api/Tracking";
import ActivityPills from "./ActivityPills";
import { UserInfo } from "../../Api/User";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";

interface EventInput {
  event: trainingEvent | null;
  userInfo: UserInfo;
}

function TodaysEvent({ event, userInfo }: EventInput) {
  let history = useHistory();
  const [backgroundStyle, setBackgroundStyle] = useState<string>(
    "items-center justify-between px-2 py-4 pl-5 border cursor-pointer rounded-2xl drop-shadow-sm bg-gradient-to-b from-eventBackgroundBaseFrom to-eventBackgroundBaseTo"
  );

  function handleEventClick() {
    if (event) {
      track(
        TrackingEventType.TRACKING_APP_WEB_ATHLETE_DASHBOARD,
        `Open event ${event.id}`
      );
      history.push(`/activity/${event.id}/plan`);
    }
  }

  useEffect(() => {
    function adjustBackgroundStyle() {
      if (event) {
        const eventType = event.trainingEventType;

        switch (eventType) {
          case TrainingEventType.BASE:
            setBackgroundStyle(
              "items-center justify-between px-2 py-4 pl-5 cursor-pointer rounded-2xl drop-shadow-sm bg-gradient-to-b from-eventBackgroundBaseFrom to-eventBackgroundBaseTo"
            );
            break;
          case TrainingEventType.LONG:
            setBackgroundStyle(
              "items-center justify-between px-2 py-4 pl-5 cursor-pointer rounded-2xl drop-shadow-sm bg-gradient-to-b from-eventBackgroundLongFrom to-eventBackgroundLongTo"
            );
            break;
          case TrainingEventType.RECOVERY:
            setBackgroundStyle(
              "items-center justify-between px-2 py-4 pl-5 cursor-pointer rounded-2xl drop-shadow-sm bg-gradient-to-b from-eventBackgroundRecoveryFrom to-eventBackgroundRecoveryTo"
            );
            break;
          case TrainingEventType.REST:
            setBackgroundStyle(
              "items-center justify-between px-2 py-4 pl-5 cursor-pointer rounded-2xl drop-shadow-sm bg-gradient-to-b from-eventBackgroundRestFrom to-eventBackgroundRestTo"
            );
            break;
          case TrainingEventType.SPEED:
            setBackgroundStyle(
              "items-center justify-between px-2 py-4 pl-5 cursor-pointer rounded-2xl drop-shadow-sm bg-gradient-to-b from-eventBackgroundSpeedFrom to-eventBackgroundSpeedTo"
            );
            break;
          case TrainingEventType.RACE:
            setBackgroundStyle(
              "items-center justify-between px-2 py-4 pl-5 cursor-pointer rounded-2xl drop-shadow-sm bg-gradient-to-b from-eventBackgroundRaceFrom to-eventBackgroundRaceTo"
            );
            break;
          case TrainingEventType.CROSS_TRAINING:
            setBackgroundStyle(
              "items-center justify-between px-2 py-4 pl-5 cursor-pointer rounded-2xl drop-shadow-sm bg-gradient-to-b from-eventBackgroundRaceFrom to-eventBackgroundRaceTo"
            );
            break;
          case TrainingEventType.WALK:
            setBackgroundStyle(
              "items-center justify-between px-2 py-4 pl-5 cursor-pointer rounded-2xl drop-shadow-sm bg-gradient-to-b from-eventBackgroundBaseFrom to-eventBackgroundBaseTo"
            );
            break;

          default:
            break;
        }
      } else {
        setBackgroundStyle(
          "items-center justify-between px-2 py-4 pl-5 cursor-pointer rounded-2xl drop-shadow-sm bg-gradient-to-b from-eventBackgroundEmptyFrom to-eventBackgroundEmptyTo"
        );
      }
    }

    adjustBackgroundStyle();
  }, [event]);

  return (
    <div
      onClick={() => {
        handleEventClick();
      }}
      className={backgroundStyle}
    >
      <div className="text-left">
        <p className="mb-2 text-sm font-medium text-left text-todaysEventDate">
          {formatDateForTodaysEvent(event ? event.scheduledAt : new Date())}
        </p>
        <h4 className="my-1.5 text-xl font-bold truncate text-white">
          {event ? event.title : "No Workout Scheduled"}
        </h4>
        <p className="pr-2 text-sm font-light text-white font-tableHeader">
          {event
            ? event.trainingEventTypeDescription
            : "Workouts will appear here when ready."}
        </p>
      </div>
      {event &&
      event.trainingEventType !== TrainingEventType.REST &&
      event.trainingEventType !== TrainingEventType.CROSS_TRAINING ? (
        <div className="flex items-center justify-between pr-4 mt-5 mb-1">
          <ActivityPills
            event={event}
            metricSetting={userInfo.profileMetricSetting}
            withGradient={true}
          />
          <ChevronRightIcon className="w-6 h-6 text-white" />
        </div>
      ) : (
        <div className="mb-2"></div>
      )}
    </div>
  );
}

export default TodaysEvent;
