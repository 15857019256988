import axios from "axios";
import {
  decodeDateFromSecondsFormat,
} from "../Utilities/DateUtilities";
import {MiniProfile} from "./TrainingProfile";

export type TrainingEventAction = {
  id: string,
  profile: string,
  createdAt: Date,
  action: string,
  actorMiniProfile?: MiniProfile | null
};

export async function getTrainingEventActions(
  trainingProfileId: string,
): Promise<TrainingEventAction[] | null> {
  try {
    const response = await axios.get(
      `training-event-actions/${trainingProfileId}?offset=0&limit=1000`
    );

    const trainingEventActions = response.data.items;
    return trainingEventActions.map(
        (trainingEventAction: any) => {
          return {
            ...trainingEventAction,
            createdAt: decodeDateFromSecondsFormat(trainingEventAction.createdAt),
          };
        }
    );
  } catch (error: any) {
    return null;
  }
}

