import {IonContent, IonPage, IonRouterLink} from "@ionic/react";
import Back from "../../../Assets/Back.svg";
import React from "react";
import {useTrainingAttributes} from "../../../Context/TrainingAttributeContext";
import {getInvoices, Invoice} from "../../../Api/Invoice";
import {decodeDateFromSecondsFormat, formatDateMonthYear,} from "../../../Utilities/DateUtilities";
import {track, TrackingEventType} from "../../../Api/Tracking";
import {isMobileEmbeddedView} from "../../../Api/Auth";
import SettingsSubheader from "../ProfileSettings/SettingsSubheader";
import {openUrl} from "../../../Utilities/RedirectUtilities";
import {getSubscriptionsForSignedInUser, Subscription} from "../../../Api/Payment";

export default function Membership() {
  const { trainingAttributes } = useTrainingAttributes();
  const [invoices, setInvoices] = React.useState<Invoice[] | undefined | null>(
    null
  );
  const [subscription, setSubscription] = React.useState<Subscription | null>();

  React.useEffect(() => {
    const getInvoicesData = async () => {
      const invoiceData = await getInvoices();
      if (invoiceData) {
        setInvoices(invoiceData);
      } else {
        setInvoices([]);
      }
      const response = await getSubscriptionsForSignedInUser();

      if (response && response.length > 0) {
        setSubscription(response[0]);
      }
    };
    getInvoicesData();
  }, []);

  const currentPlan = trainingAttributes?.productName;

  return (
    <IonPage>
      <SettingsSubheader title="Membership" />
      <IonContent>
        <div className="max-w-xl h-full sm:h-fit mx-auto sm:mt-4 lg:mt-20 sm:px-6 lg:px-8">
          <IonRouterLink routerLink="/profile/settings" routerDirection="back">
            <button className="items-center self-start hidden mb-12 ml-6 space-x-1 text-xs text-gray-400 rounded md:flex hover:text-gray-500">
              <img src={Back} alt="Back" className="w-auto h-4" />
              <p>Back</p>
            </button>
          </IonRouterLink>
          <div className="p-4 bg-white border-t-1 border-neutral-100 flex flex-col h-full">
            <div className="flex-grow-0">
              <h2 className="font-semibold text-[24px] leading-[32px] tracking-[-0.02em] mb-[2px]">
                Plan
              </h2>
            </div>
            <div className="flex-grow mt-4">
              {currentPlan ? (
                <div className=" py-[15px] border-b border-neutral-100">
                  <p className="font-medium text-neutral-900 text-[16px] leading-[24px]">
                    {currentPlan}
                  </p>
                  <p className=" text-neutral-500 text-[16px] leading-[24px]">
                    ${parseInt(subscription?.subscriptionPrice ?? "0")}
                  </p>
                </div>
              ) : null}
              <div className="mt-[14px] flex items-center pb-[15px] justify-between border-b border-neutral-100">
                <div>
                  <p className="font-medium text-neutral-900 text-[16px] leading-[24px]">
                    Billing
                  </p>
                  <p className="text-[16px] leading-[24px] text-neutral-500">
                    Renews on {subscription?.expirationDate && formatDateMonthYear(new Date(subscription.expirationDate * 1000))}
                  </p>
                </div>
                <a
                  href={
                    isMobileEmbeddedView("1.0.24")
                      ? window.location.href + "#contact"
                      : "mailto:help@runzy.com?subject=Runzy%20Coaching%20Question"
                  }
                  className="bg-neutral-100 rounded-[200px] py-2 px-5 font-medium text-[16px] leading-[24px] text-neutral-900"
                >
                  Manage
                </a>
              </div>
              <div className="mt-[14px]">
                <p className="font-medium text-neutral-900 text-[16px] leading-[24px]">
                  Invoices
                </p>
                <div className="">
                  {invoices && invoices.length > 0 ? (
                    invoices.map((invoice, index) => (
                      <div key={index} className="py-[15px]">
                        <p className="font-medium text-neutral-900 text-[16px] leading-[24px]">
                          {decodeDateFromSecondsFormat(
                            invoice.date
                          ).toDateString()}
                        </p>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            track(
                              TrackingEventType.TRACKING_APP_WEB_DOWNLOAD_INVOICE,
                              "Clicked download invoice " +
                                formatDateMonthYear(
                                  new Date(1000 * invoice.date)
                                ),
                              "profiles"
                            );
                            openUrl(invoice.url);
                          }}
                          className="text-[16px] leading-[24px] text-neutral-500 hover:underline cursor-pointer"
                        >
                          Download
                        </a>
                      </div>
                    ))
                  ) : (
                    <div className="py-[15px] border-b border-neutral-100">
                      <p className="text-[16px] leading-[24px] text-neutral-500">
                        No invoices
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
