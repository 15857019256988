import { trainingEvent } from "../../../Api/TrainingEvent";
import { decodeDuration } from "../../../Utilities/FormatUtilties";

export default function ActualDurationDisplay({
  event,
}: {
  event: trainingEvent;
}) {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="assigned-duration"
        className="block text-sm font-medium text-gray-700"
      >
        Actual duration
      </label>
      <div className="mt-1">
        <p className="block w-full pt-2 border-gray-300 rounded-md focus:ring-brand focus:border-brand sm:text-sm">
          {decodeDuration(event.actualDuration) === "0:00"
            ? "-"
            : decodeDuration(event.actualDuration)}
        </p>
      </div>
    </div>
  );
}
