import axios from "axios";

export const getTerraSessionId = async () => {
  try {
    const response = await axios.get("terra/session");
    console.log("RES: ", response);
    return response.data.sessionId;
  } catch (e) {
    return null;
  }
};
