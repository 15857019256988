import { SurveyAnswer, SurveyReport } from '../../../Api/Admin/Report/Survey'

import format from 'date-fns/format'

type SurveyReportRowProps = {
    row: SurveyReport
}

const GOAL_DATE = 202
const RECOMMENDED_PLAN = 303
const CONNECT_ACCOUNT = 102
const RUNNING_DATA_REVIEW = 201
const GENERATE_PLAN = 203
const ANALYZING = 103

const FILTER_QUESTIONS = [RECOMMENDED_PLAN, CONNECT_ACCOUNT, RUNNING_DATA_REVIEW, GENERATE_PLAN, ANALYZING]

const filterQuestions = (answer: SurveyAnswer) => answer.questionId && !FILTER_QUESTIONS.includes(answer.questionId)

const renderAnswer = (answer: SurveyAnswer, item: string, key: number) => {
    if (answer.questionId === GOAL_DATE && key === 0 && !isNaN(Number(item))) {
        try {
            return format(new Date(parseInt(item) * 1000), 'MM/dd/yyyy')
        } catch (e) {
            return 'INVALID_DATE_FORMAT'
        }
    }
    return item
}

const SurveyReportRow = ({row}: SurveyReportRowProps) => {
    return (
        <tr className="py-2 px-3 border-b border-b-black-500 hover:bg-gray-100">
            <td>

                <div className="flex flex-col whitespace-nowrap">
                    <div>{row.email} <span className="text-sm font-semibold">({row.countryCode})</span></div>
                    <div className="text-sm text-gray-500">
                        {format(new Date(row.createdAt), 'MM/dd/yyyy HH:mm')}
                    </div>
                </div>
            </td>
            <td className="p-2">
                <div className="flex whitespace-nowrap">
                    {row.surveyAnswers.filter(filterQuestions).map((answer, key) => (
                        <div className="flex flex-col ml-4" key={key}>
                            <div
                                className=" text-xs bg-orange-50 rounded-full px-2 py-1">{answer.questionKey}</div>
                            <div className="flex gap-1">{answer.answer.map(
                                (item, key) => (
                                    <span key={key}
                                          className="font-semibold text-xs bg-green-100 border-orange-200 rounded-full px-2 py-1">
                                        {renderAnswer(answer, item, key)}
                                    </span>
                                )
                            )}</div>
                        </div>
                    ))}
                </div>
            </td>
        </tr>
    )
}

export default SurveyReportRow