import GreyStrava from "../../../../Assets/GreyStrava.svg";

function SyncedFromStrava() {
  return (
    <div className="flex gap-1">
      <img className="w-auto h-5" src={GreyStrava} alt="strava" />
      <p className="text-[12px] text-gray-500">Synced from Strava</p>
    </div>
  );
}

export default SyncedFromStrava;
