import WarningModal from "../../Utilities/Components/WarningModal";

interface DeletePostModalInput {
  showDeleteModal: boolean;
  deactivatePostModal: () => void;
  handleDeletePost: () => Promise<void>;
}

function DeletePostModal({
  showDeleteModal,
  deactivatePostModal,
  handleDeletePost,
}: DeletePostModalInput) {
  return (
    <WarningModal
      action={handleDeletePost}
      buttonLabel="Delete"
      deactivateModal={deactivatePostModal}
      showModal={showDeleteModal}
      subtitle="This action cannot be undone and it will no longer be visible by anyone."
      title="Delete post?"
    />
  );
}

export default DeletePostModal;
