import { useContext, useState } from "react";
import CoachHeader from "../Coach/Components/CoachHeader";
import { UserInfoContext } from "../Context/UserContext";
import { useLocalStorage } from "usehooks-ts";

import Athletes from "../Coach/Athletes/Athletes";
import TrainingProfile from "../Coach/TrainingProfile/TrainingProfile";
import { IonContent, IonPage } from "@ionic/react";
import { useLocation } from "react-router-dom";
import DefaultWebFooter from "../Coach/Components/Footer/DefaultWebFooter";
import { isMobile } from "../Utilities/isMobile";

function CoachDashboard() {
  const location = useLocation();
  const blockedSubroutes = ["premium", "basic", "reviewed"];

  const { userInfo } = useContext(UserInfoContext);

  const [showAllAthletes, setShowAllAthletes] = useLocalStorage(
    "showAllAthletes",
    false
  );

  return (
    <IonPage>
      <IonContent>
        {userInfo && (
          <div className="min-h-screen bg-gray-50">
            <CoachHeader
              showAllAthletes={showAllAthletes}
              setShowAllAthletes={setShowAllAthletes}
            />
            {location.pathname.replace("/", "") !== "coach" &&
            !blockedSubroutes.some((blockedSubroute) =>
              location.pathname.replace("/", "").endsWith(blockedSubroute)
            ) ? (
              <TrainingProfile showAllAthletes={showAllAthletes} />
            ) : (
              <Athletes showAllAthletes={showAllAthletes} />
            )}
          </div>
        )}
        {!isMobile && userInfo && <DefaultWebFooter />}
      </IonContent>
    </IonPage>
  );
}

export default CoachDashboard;
