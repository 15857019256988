
export type StravaSettingField = "update" | "title" | "streak" | "score" | "goal"

interface StravaPreferenceFields {
  updatesEnabled: boolean
  titleUpdatesEnabled: boolean
  streakUpdatesEnabled: boolean
  scoreUpdatesEnabled: boolean
  goalUpdatesEnabled: boolean
}

export interface ExternalAccount {
  id?: string
  provider: string
  hasPreferences?: boolean
  stravaPreferences?: StravaPreferenceFields
  profileUrl?: string
  connectedAt?: Date
}

export enum ACCOUNT_PROVIDER {
  STRAVA = 'Strava',
  TERRA = 'Terra'
}
