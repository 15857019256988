import { useState } from "react";
import Info from "../../../Assets/Info.svg";

function Email({ email }: { email: string }) {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <>
      <div className="pb-[8px] mt-[16px] border-b border-race-50 mx-4">
        <label
          htmlFor="name"
          className="block text-[12px] text-[#9CA3AF] spacing-[20px] tracking-[1px]"
        >
          EMAIL
        </label>
        <div
          onClick={() => setShowDetails(true)}
          className="flex items-center mt-1"
        >
          <p className="block w-full font-medium text-[#111827] font-[16px] focus:border-[#717171] focus:ring-[#717171] focus:outline-[#717171]">
            {email}
          </p>
          <img src={Info} alt="see more" />
        </div>
      </div>
      {showDetails && (
        <p className="mt-1 ml-4 font-medium text-[11px] text-[#717171]">
          You can’t edit your email address after sign up.
        </p>
      )}
    </>
  );
}

export default Email;
