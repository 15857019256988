import { UnitPreference } from "../../../Api/User";
import Selector from "../../../Assets/Selector.svg";
import { classNames } from "../../../Utilities/classnames";
import { isMobile } from "../../../Utilities/isMobile";
import UnitSelector from "./UnitSelector";
import { useUnitPreferenceOutput } from "./useUnitPreference";

function Unit({
  unitPreferenceHook,
}: {
  unitPreferenceHook: useUnitPreferenceOutput;
}) {
  if (!unitPreferenceHook.unitPreference) return <></>;

  return (
    <div className="pb-[8px] mt-[16px] border-b border-race-50 mx-4 mb-4">
      <label
        htmlFor="units"
        className="block text-[12px] text-[#9CA3AF] spacing-[20px] tracking-[1px]"
      >
        UNITS
      </label>
      {isMobile ? (
        <div
          onClick={() => unitPreferenceHook.setDisplayModal(true)}
          className="flex items-center justify-between mt-1"
        >
          <p className="block w-full font-medium text-[#222222] font-[17px] focus:border-[#717171] focus:ring-[#717171]">
            {unitPreferenceHook.unitPreference === UnitPreference.IMPERIAL
              ? "Miles"
              : "Kilometers"}
          </p>
          <img
            className={classNames(
              unitPreferenceHook.displayModal ? "rotate-180" : ""
            )}
            src={Selector}
            alt="select unit preference"
          />
        </div>
      ) : (
        <UnitSelector unitPreferenceHook={unitPreferenceHook} />
      )}
    </div>
  );
}

export default Unit;
