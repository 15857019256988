import axios from "axios";

export interface Invoice {
  url: string;
  date: number;
}

export async function getInvoices() {

  try {
    const response = await axios.get("invoices");
    return response.data.items as Invoice[];
  } catch (e) {
    return null;
  }
}