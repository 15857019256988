import { trainingEvent, TrainingEventType } from "../../../Api/TrainingEvent";
import { CalendarDay, getTrainingEventsFromCalendarDays } from "../../Calendar";
import useCalendarContextMenu from "./useCalendarContextMenu";
import CalendarContextMenuContainer from "./CalendarContextMenuContainer";
import ItemContainer from "./ItemContainer";
import Action from "./Action";
import SubactionsContainer from "./SubactionsContainer";
import { Step } from "../../../Api/Step";

interface CalendarContextMenuInput {
  day: CalendarDay;
  selectedDays: CalendarDay[];
  handleDelete: (
    id: string,
    isOverwrite?: boolean | undefined
  ) => Promise<void>;
  handleAdd: (
    type?: TrainingEventType,
    scheduledAt?: Date,
    title?: string,
    description?: string[],
    assignedDistance?: string,
    assignedDuration?: string,
    message?: string,
    sportsType?: string,
    openToNewEvent?: boolean,
    steps?: Step[]
  ) => Promise<void>;
  handleDuplicate: (event: trainingEvent) => Promise<void>;
  handleRepeat: (event: trainingEvent, weeks: number) => Promise<void>;
  initTrainingEvents: () => void;
  setIsOperating: React.Dispatch<React.SetStateAction<boolean>>;
  openTemplateMenu: (date?: Date) => void;
  createTemplate: (event: trainingEvent) => Promise<void>;
}

function CalendarContextMenu({
  day,
  selectedDays,
  handleDelete,
  handleAdd,
  handleDuplicate,
  handleRepeat,
  initTrainingEvents,
  setIsOperating,
  openTemplateMenu,
  createTemplate,
}: CalendarContextMenuInput) {
  const {
    options,
    setCurrentFocusedOption,
    overflowRef,
    containerRef,
    displayOnLeft,
    displayOnTop,
    displayContainerOnLeft,
    currentFocusedOption,
  } = useCalendarContextMenu({
    day: day,
    handleAdd: handleAdd,
    handleDelete: handleDelete,
    handleDuplicate: handleDuplicate,
    handleRepeat: handleRepeat,
    initTrainingEvents: initTrainingEvents,
    selectedEvents: getTrainingEventsFromCalendarDays(selectedDays),
    setIsOperating: setIsOperating,
    openTemplateMenu: openTemplateMenu,
    createTemplate: createTemplate,
  });

  return (
    <>
      <CalendarContextMenuContainer
        displayOnTop={displayOnTop}
        displayContainerOnLeft={displayContainerOnLeft}
        containerRef={containerRef}
      >
        <>
          {options.map((option, key) => (
            <ItemContainer
              isEnabled={option.isEnabled}
              key={key}
              onClick={() => {
                if (option.action) option.action();
              }}
              onMouseEnter={() => {
                setCurrentFocusedOption(option.name);
              }}
              onMouseLeave={() => {
                setCurrentFocusedOption("");
              }}
            >
              <>
                <Action option={option} />
                <SubactionsContainer
                  currentFocusedOption={currentFocusedOption}
                  displayOnLeft={displayOnLeft}
                  displayOnTop={displayOnTop}
                  option={option}
                  overflowRef={overflowRef}
                />
              </>
            </ItemContainer>
          ))}
        </>
      </CalendarContextMenuContainer>
    </>
  );
}

export default CalendarContextMenu;
