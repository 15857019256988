import { Switch } from "@headlessui/react";
import { classNames } from "../../Utilities/classnames";

interface SmallToggleInput {
  isEnabled: boolean;
  setIsEnabled: Function;
}

function SmallToggle({ isEnabled, setIsEnabled }: SmallToggleInput) {
  return (
    <Switch
      checked={isEnabled}
      onChange={() => setIsEnabled(!isEnabled)}
      className="relative z-0 inline-flex items-center justify-center flex-shrink-0 w-10 h-5 mr-3 rounded-full cursor-pointer group focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
      // className="relative z-0 inline-flex items-center justify-center flex-shrink-0 w-10 h-5 mr-3 rounded-full cursor-pointer group"
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className="absolute w-full h-full bg-white rounded-md pointer-events-none"
      />
      <span
        aria-hidden="true"
        className={classNames(
          isEnabled ? "bg-brand" : "bg-gray-200",
          "pointer-events-none absolute h-5 w-10 mx-auto rounded-full transition-colors ease-in-out duration-200"
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          isEnabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200"
        )}
      />
    </Switch>
  );
}

export default SmallToggle;
