import {useEffect, useState} from "react";
import {editTrainingAttributes, trainingAttributes} from "../../../Api/TrainingAttribute";
import {PaceZone} from "../../../Api/PaceZones";

interface usePaceZonesInput {
  trainingAttributes: trainingAttributes;
  profileId: string;
}

function usePaceZones({
                        trainingAttributes,
                        profileId
                      }: usePaceZonesInput): usePaceZonesOutput {
  const [paceZones, setPaceZones] = useState<PaceZone[]>([]);
  const paceZoneValues = paceZones.map((paceZone: PaceZone) => paceZone.averageSpeed);

  async function updatePaceZoneToBackend(paceZoneValues: number[]): Promise<200 | null> {
    const updatedPaceZones = paceZoneValues.map((pace, index) => {
      paceZones[index].averageSpeed = pace;
      return paceZones[index];
    });
    setPaceZones(updatedPaceZones);
    if (!paceZones || !trainingAttributes) return null;

    try {
      await editTrainingAttributes(
          {
            ...trainingAttributes,
            paceZones: paceZones,
          },
          profileId
      );
      return 200;
    } catch (e) {
      return null;
    }
  }

  useEffect(() => {
    if (trainingAttributes) {
      setPaceZones(trainingAttributes.paceZones);
    }
  }, [trainingAttributes]);

  return {
    paceZoneValues: paceZoneValues,
    isOverride: trainingAttributes.isPaceZoneOverride,
    updatePaceZoneToBackend
  };
}

export interface usePaceZonesOutput {
  paceZoneValues: number[];
  isOverride: boolean;
  updatePaceZoneToBackend(paceZoneValues: number[]): Promise<200 | null>;
}

export default usePaceZones;
