import { split } from "lodash";
import { ActivityRoute, ActivitySplit } from "../../Api/ActivityRoute";
import { UnitPreference } from "../../Api/User";
import {
  formatDistance,
  formatPaceFromSpeed,
} from "../../Utilities/FormatUtilties";

interface ModalSplitsInput {
  metricSetting: UnitPreference;
  activityRoute: ActivityRoute | null;
}

function ModalSplits({ metricSetting, activityRoute }: ModalSplitsInput) {
  let splitsArray: ActivitySplit[] = [];

  if (activityRoute) {
    splitsArray = Object.values(activityRoute.splits);
  }

  return (
    <div className="flex flex-col mt-4">
      <div className="-my-2 overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    LAP
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    PACE
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    ELEV
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    DISTANCE
                  </th>
                  {splitsArray.filter((split) => split.cadence !== null)
                    .length > 0 && (
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      CADENCE
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {splitsArray.length > 0 &&
                  splitsArray.map((split, index) => (
                    <tr className="font-tableHeader" key={index}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">
                        {formatPaceFromSpeed(split.speed, metricSetting)}
                      </td>
                      <td>
                        {formatDistance(
                          split.elevationGain,
                          metricSetting,
                          true,
                          true,
                          true
                        )}
                      </td>
                      <td>
                        {formatDistance(split.distance, metricSetting, true)}
                      </td>
                      {split.cadence && <td>{split.cadence.toFixed(2)}</td>}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalSplits;
