import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import BackArrow from "../../../../Assets/BackArrow.svg";
import { isMobile } from "../../../../Utilities/isMobile";
import CoacheeHeader from "../../../Components/CoacheeHeader";

function PageHeader() {
  return (
    <>
      {isMobile ? (
        <IonHeader>
          <IonToolbar className="pb-2 mt-2">
            <IonTitle>Activity</IonTitle>
            <IonButtons slot="start">
              <IonBackButton>
                <img src={BackArrow} alt="go back to profile" />
              </IonBackButton>
            </IonButtons>
            {/* <IonButtons slot="end">
              <IonButton>
                <img src={Pencil} alt="edit" />
              </IonButton>
            </IonButtons> */}
          </IonToolbar>
        </IonHeader>
      ) : (
        <CoacheeHeader />
      )}
    </>
  );
}

export default PageHeader;
