import { trainingAttributes } from "../../Api/TrainingAttribute";
import { trainingEvent } from "../../Api/TrainingEvent";
import { ContentOptions } from "./ActivityContent";
import CoachsNote from "./CoachsNote";
import EventInstructions from "./EventInstructions";

interface ActivityPlanInput {
  trainingEvent: trainingEvent;
  trainingAttributes: trainingAttributes;
  contentOption: ContentOptions;
}

function ActivityPlan({
  trainingEvent,
  trainingAttributes,
  contentOption,
}: ActivityPlanInput) {
  return (
    <div id="activityPlan">
      <CoachsNote
        trainingEvent={trainingEvent}
        trainingAttributes={trainingAttributes}
        contentOption={contentOption}
      />
      <EventInstructions trainingEvent={trainingEvent} />
    </div>
  );
}

export default ActivityPlan;
