import { EventActivity, Post } from "../../Api/Stream";
import { formatActivityStreamDate } from "../../Utilities/DateUtilities";
import { parseISO } from "date-fns";
import CompletionRing from "./CompletionRing";
import { StreamClient } from "getstream";
import { useContext, useEffect, useState } from "react";
import { UserInfoContext } from "../../Context/UserContext";
import { useInView } from "react-hook-inview";
import { TrainingEventStatus } from "../../Api/TrainingEvent";
import { IonRouterLink } from "@ionic/react";
import ActivityDropdown from "../../Coach/Components/ActivityDropdown";
import StreamEditBox from "./StreamEditBox";
import { ModalContent } from "../../Pages/CoacheeDashboard";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";

interface StreamActivityInput {
  index: number;
  activitiesLength: number;
  activity: Post | EventActivity;
  streamClient: StreamClient;
  setModalContent: React.Dispatch<React.SetStateAction<ModalContent>>;
  editFeedItem: (feedId: string, content: string) => Promise<200 | null>;
  activateDeleteModal: (postId: string) => void;
}

function StreamActivity({
  index,
  activitiesLength,
  activity,
  streamClient,
  setModalContent,
  editFeedItem,
  activateDeleteModal,
}: StreamActivityInput) {
  const { userInfo } = useContext(UserInfoContext);
  const [ref, inView] = useInView({ threshold: 1 });
  const [activityContent, setActivityContent] = useState(activity.message);
  const [editMode, setEditMode] = useState(false);

  function cancelEdit() {
    setActivityContent(activity.message);
    setEditMode(false);
  }

  function getProfileImg() {
    if (
      userInfo &&
      streamClient.currentUser &&
      streamClient.currentUser.id === activity.actor
    ) {
      return userInfo.profileImage;
    }

    return activity.actorMiniProfile.profilePicture;
  }

  useEffect(() => {
    setActivityContent(activity.message);
  }, [activity]);

  return (
    <>
      {activity && (
        <>
          {!activity.trainingEvent ||
          activity.trainingEvent?.status === TrainingEventStatus.COMPLETED ||
          activity.trainingEvent?.status === TrainingEventStatus.MISSED ? (
            <>
              {activity.trainingEvent ? (
                <IonRouterLink
                  id="trainingEventStreamActivity"
                  className="border-white"
                  routerLink={`/activity/${activity.trainingEvent.id}/results`}
                  routerDirection="forward"
                >
                  <div ref={ref} className="relative pb-5 cursor-pointer">
                    {index !== activitiesLength - 1 && (
                      <span
                        className="absolute w-px h-full -ml-px bg-transparent border-0 border-l border-gray-300 border-dashed top-8 left-6"
                        aria-hidden="true"
                      />
                    )}

                    <div>
                      <div className="relative flex items-start space-x-3 border-white">
                        <>
                          <>
                            <div className="relative w-12 h-12">
                              <ProfilePicture
                                url={getProfileImg()}
                                classNames="flex items-center justify-center w-full h-full bg-[#f6f6f6] rounded-xl ring-4 ring-white"
                              />

                              <span className="absolute -bottom-2 left-2/4 bg-white rounded-full px-0.5 py-px -translate-x-2/4">
                                {activity.trainingEvent && (
                                  <CompletionRing
                                    className="w-4 h-4"
                                    event={activity.trainingEvent}
                                    inView={inView}
                                  />
                                )}
                              </span>
                            </div>
                            <div className="flex-1 min-w-0 px-5 pt-3.5 pb-5 bg-gray-100 rounded-xl">
                              <div className="flex items-center justify-between">
                                <div>
                                  <p className="mt-0.5 text-xs font-medium text-gray-400">
                                    {formatActivityStreamDate(
                                      parseISO(activity.published),
                                      activity.trainingEvent
                                    )}
                                  </p>
                                  <div className="mt-0.5 text-sm font-normal text-black">
                                    {activity.trainingEvent ? (
                                      <>
                                        You{" "}
                                        {activity.trainingEvent.status ===
                                        TrainingEventStatus.COMPLETED
                                          ? "completed"
                                          : "missed"}{" "}
                                        <span className="font-semibold cursor-pointer">
                                          {activity.trainingEvent.title}
                                        </span>
                                      </>
                                    ) : (
                                      <p className="whitespace-pre-line line-clamp-3 ">
                                        {activityContent ?? activity.message}
                                      </p>
                                    )}
                                  </div>

                                  {activity.trainingEvent &&
                                    activity.trainingEvent
                                      .routePreviewImageUrl && (
                                      <div>
                                        <img
                                          className="w-full h-auto mt-3 rounded-xl"
                                          src={
                                            activity.trainingEvent
                                              ?.routePreviewImageUrl
                                          }
                                          onError={(event) =>
                                            ((
                                              event.target as HTMLImageElement
                                            ).style.display = "none")
                                          }
                                          alt="route preview"
                                        />
                                        {/* <div className="flex items-center justify-around py-3 text-center text-black bg-white rounded-b-xl">
                                        <div className="">
                                          <p className="font-semibold">3 mi</p>
                                          <p className="text-[9px]">Distance</p>
                                        </div>
                                        <div className="">
                                          <p className="font-semibold">
                                            1h 23m
                                          </p>
                                          <p className="text-[9px]">Time</p>
                                        </div>
                                        <div className="">
                                          <p className="font-semibold">80 m</p>
                                          <p className="text-[9px]">Elv Gain</p>
                                        </div>
                                      </div> */}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </>
                        </>
                      </div>
                    </div>
                  </div>
                </IonRouterLink>
              ) : (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    if (!editMode) {
                      setModalContent({
                        id: activity.id,
                        foreign_id: activity.foreign_id,
                        post: activity as Post,
                      });
                    }
                  }}
                  ref={ref}
                  className="relative pb-5 cursor-pointer"
                >
                  {index !== activitiesLength - 1 && (
                    <span
                      className="absolute w-px h-full -ml-px bg-transparent border-0 border-l border-gray-300 border-dashed top-8 left-6"
                      aria-hidden="true"
                    />
                  )}

                  <div>
                    <div className="relative flex items-start space-x-3 border-white">
                      <>
                        <>
                          <div className="relative w-12 h-12">
                            <ProfilePicture
                              url={getProfileImg()}
                              classNames="flex items-center justify-center w-full h-full bg-[#f6f6f6] rounded-xl ring-4 ring-white"
                            />

                            <span className="absolute -bottom-2 left-2/4 bg-white rounded-full px-0.5 py-px -translate-x-2/4">
                              {activity.trainingEvent && (
                                <CompletionRing
                                  className="w-4 h-4"
                                  event={activity.trainingEvent}
                                  inView={inView}
                                />
                              )}
                            </span>
                          </div>
                          <div className="flex-1 min-w-0 px-5 pt-3.5 pb-5 bg-gray-100 rounded-xl">
                            <div className="flex items-center justify-between">
                              <div className="grow">
                                <p className="mt-0.5 text-xs font-medium text-gray-400">
                                  {formatActivityStreamDate(
                                    parseISO(activity.published),
                                    activity.trainingEvent
                                  )}
                                </p>
                                {editMode ? (
                                  <StreamEditBox
                                    onCancel={cancelEdit}
                                    onSave={() => {
                                      if (activityContent) {
                                        editFeedItem(
                                          activity.id,
                                          activityContent
                                        ).then((response) => {
                                          if (response) {
                                            setEditMode(false);
                                          }
                                        });
                                      }
                                    }}
                                    setText={setActivityContent}
                                    text={
                                      activityContent ?? activity.message ?? ""
                                    }
                                  />
                                ) : (
                                  <div className="mt-0.5 text-sm font-normal text-black">
                                    <p className="whitespace-pre-line line-clamp-3 ">
                                      {activityContent ?? activity.message}
                                    </p>{" "}
                                  </div>
                                )}
                              </div>
                              {userInfo &&
                                activity.actorMiniProfile.id ===
                                  userInfo.miniProfile.id && (
                                  <div className="self-start">
                                    <ActivityDropdown
                                      actions={[
                                        {
                                          key: "Edit",
                                          action: () => setEditMode(true),
                                        },
                                        {
                                          key: "Delete",
                                          action: () =>
                                            activateDeleteModal(activity.id),
                                        },
                                      ]}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        </>
                      </>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
}

export default StreamActivity;
