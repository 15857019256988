import { useActivityFeedbackOutput } from "../../Hooks/useActivityFeedback";
import SendOutline from "../../../Assets/SendOutline.svg";
import SendSolid from "../../../Assets/SendSolid.svg";
import { classNames } from "../../../Utilities/classnames";

interface SubmitButtonInput {
  isEnabled: boolean | undefined;
  activityFeedback?: useActivityFeedbackOutput;
}

function SubmitButton({ isEnabled, activityFeedback }: SubmitButtonInput) {
  return (
    <div
      className={classNames(
        isEnabled ? "right-0.5" : "right-3",
        "absolute flex items-center justify-center top-1 inset-y-0 pr-2.5"
      )}
    >
      <div className="">
        {isEnabled ? (
          <button
            data-testid="solidButton"
            className=" w-[43px] h-[43px]"
            onClick={(e) => {
              e.preventDefault();
              if (activityFeedback) activityFeedback.handleSubmitComment();
            }}
          >
            <img src={SendSolid} />
          </button>
        ) : (
          <button
            data-testid="outlineButton"
            className="w-[24px] h-[24px]"
            disabled
          >
            <img src={SendOutline} />
          </button>
        )}
      </div>
    </div>
  );
}

export default SubmitButton;
