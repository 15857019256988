import { PlusIcon } from "@heroicons/react/outline";
import { useIonRouter } from "@ionic/react";
import { useEffect, useState } from "react";
import { deleteActivity, getActivities } from "../../../../Api/Activity";
import { ActivityMetadata } from "../../../../Api/ActivityRoute";
import { useUserInfo } from "../../../../Context/UserContext";
import DeleteActivityModal from "./DeleteActivityModal";
import OtherActivity from "./OtherActivity";

function OtherActivities({ date }: { date: Date }) {
  const { userInfo } = useUserInfo();
  const [activities, setActivities] = useState<ActivityMetadata[]>();
  const routerHook = useIonRouter();
  const [activityToBeDeleted, setActivityToBeDeleted] =
    useState<ActivityMetadata>();

  async function initActivities() {
    if (!userInfo) return;
    const activities = await getActivities(
      date,
      userInfo.miniProfile.numericId
    );

    if (activities) setActivities(activities);
  }

  async function handleDeleteActivity() {
    if (!activityToBeDeleted) return;
    await deleteActivity(activityToBeDeleted);
    setActivityToBeDeleted(undefined);
    initActivities();
  }

  useEffect(() => {
    initActivities();
  }, [userInfo]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <h3 className="font-semibold text-[18px]">All Activities</h3>
        <button
          onClick={() =>
            routerHook.push(
              `/activities/create/${date.toISOString().split("T")[0]}`
            )
          }
          className="flex items-center gap-2 bg-white py-[8px] px-[16px] border rounded-full hover:shadow"
        >
          <PlusIcon className="w-4 h-4 text-neutral-600" />
          <p className="font-medium text-neutral-700 text-[14px]">
            Manual Entry
          </p>
        </button>
      </div>
      <div className="mb-4">
        {activities && (
          <>
            {activities.length > 0 ? (
              <>
                {activities.map((activity, index) => (
                  <div key={index}>
                    <OtherActivity
                      activity={activity}
                      index={index}
                      setActivityToBeDeleted={setActivityToBeDeleted}
                    />
                  </div>
                ))}
              </>
            ) : (
              <p className="text-center text-neutral-500 text-[14px] mt-4">
                No activities posted yet.
              </p>
            )}
          </>
        )}
      </div>
      <DeleteActivityModal
        showDeleteModal={activityToBeDeleted !== undefined}
        deactivateActivityModal={() => setActivityToBeDeleted(undefined)}
        handleDeleteActivity={handleDeleteActivity}
      />
    </div>
  );
}

export default OtherActivities;
