import { useHistory } from "react-router-dom";
import { classNames } from "../../Utilities/classnames";
import { ContentOptions } from "./ActivityContent";

interface ActivityContentSliderInput {
  option: ContentOptions;
}

function ActivityContentSlider({ option }: ActivityContentSliderInput) {
  const history = useHistory();

  return (
    <div
      className={classNames(
        "relative mt-3 border border-gray-300 px-1.5 py-1 inline-flex flex-shrink-0 h-11 w-full mb-3 rounded-full cursor-pointer"
      )}
    >
      <span className="sr-only">Use setting</span>
      {option === ContentOptions.PLAN ? (
        <span
          className={classNames(
            "pointer-events-none flex h-full w-1/3 rounded-full bg-rose-400 shadow ring-0"
          )}
        >
          <span
            className={classNames(
              "inset-0 h-full w-full flex items-center justify-center z-10"
            )}
            aria-hidden="true"
          >
            <p className="text-xs text-white">Plan</p>
          </span>
        </span>
      ) : (
        <a
          className={classNames(
            " flex items-center justify-center inset-0 w-1/3 bg-transparent ring-0 z-10"
          )}
          onClick={() => {
            history.replace(`plan`);
          }}
        >
          <p className="text-xs text-gray-300">Plan</p>
        </a>
      )}

      {option === ContentOptions.RESULTS ? (
        <span
          className={classNames(
            "pointer-events-none inline-block h-full w-1/3 rounded-full bg-rose-400 shadow ring-0"
          )}
        >
          <span
            className={classNames(
              "absolute inset-0 h-full w-full flex items-center justify-center z-10"
            )}
            aria-hidden="true"
          >
            <p className="text-xs text-white">Results</p>
          </span>
        </span>
      ) : (
        <a
          className={classNames(
            "flex items-center justify-center inset-0 w-1/3 bg-transparent ring-0 z-10"
          )}
          onClick={() => {
            history.replace(`results`);
          }}
        >
          <p className="text-xs text-gray-300">Results</p>
        </a>
      )}

      {option === ContentOptions.FEEDBACK ? (
        <span
          className={classNames(
            "pointer-events-none flex h-full w-1/3 rounded-full bg-rose-400 shadow ring-0"
          )}
        >
          <span
            className={classNames(
              "inset-0 h-full w-full flex items-center justify-center z-10"
            )}
            aria-hidden="true"
          >
            <p className="text-xs text-white">Comments</p>
          </span>
        </span>
      ) : (
        <a
          className={classNames(
            " flex items-center justify-center inset-0 w-1/3 bg-transparent ring-0 z-10"
          )}
          onClick={() => {
            history.replace("feedback");
          }}
        >
          <p className="text-xs text-gray-300">Comments</p>
        </a>
      )}
    </div>
  );
}

export default ActivityContentSlider;
