import { TrainingStats } from "../../../../Api/TrainingProfile";
import Runs from "./Runs";
import Distance from "./Distance";
import Streak from "./Streak";

function QuickStats({ stats }: { stats: TrainingStats }) {
  return (
    <div className="flex items-center justify-around mt-6">
      {stats && (
        <>
          <Runs stats={stats} />
          <Distance stats={stats} />
          <Streak stats={stats} />
        </>
      )}
    </div>
  );
}

export default QuickStats;
