import { TrainingStats } from "../../../../Api/TrainingProfile";
import Bolt from "../../../../Assets/Bolt.svg";
import { classNames } from "../../../../Utilities/classnames";
function Streak({ stats }: { stats: TrainingStats }) {
  return (
    <div className="text-center">
      <img className="mx-auto" src={Bolt} alt="bolt" />
      <div
        className={classNames(
          !stats.trainingStreak ||
            stats.trainingStreak < stats.allTimeTrainingStreak
            ? "justify-center"
            : "flex items-center gap-1.5"
        )}
      >
        <p className="font-semibold text-[#222222] text-xl">
          {stats?.trainingStreak}
        </p>
        {stats.trainingStreak &&
          stats.trainingStreak >= stats.allTimeTrainingStreak && (
            <div
              className="flex items-center gap-1 px-2 py-1 bg-runzy-red-orange rounded-full"
            >
              <p className="text-white text-[11px]">Best</p>
            </div>
          )}
      </div>

      <p className="text-[#717171] text-[11px] font-semibold">Streak</p>
    </div>
  );
}

export default Streak;
