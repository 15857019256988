import { useEffect, useState } from "react";
import { editTrainingAttributes } from "../../../Api/TrainingAttribute";
import { useTrainingAttributes } from "../../../Context/TrainingAttributeContext";
import { useUserInfo } from "../../../Context/UserContext";
import {
  altDayOfTheWeek,
  sortDaysOfTheWeek,
} from "../../../Utilities/DateUtilities";
import { titleCase } from "../../../Utilities/FormatUtilties";

function useWorkoutDays() {
  const [allowedDays, setAllowedDays] = useState<string[]>();
  const { trainingAttributes } = useTrainingAttributes();
  const { userInfo } = useUserInfo();

  const blockedDays = allowedDays
      ? altDayOfTheWeek
      .filter((day) => !allowedDays.includes(day))
      .map((day) => day.toUpperCase())
      : undefined;

  async function submitWorkoutDays() {
    if (!blockedDays || !trainingAttributes || !userInfo) return null;

    try {
      await editTrainingAttributes(
          {
            ...trainingAttributes,
            blockedDays: blockedDays,
          },
          userInfo.id
      );
      return 200;
    } catch (e) {
      return null;
    }
  }

  function handleWorkoutDayChange(day: string) {
    if (!allowedDays) return;
    if (allowedDays.includes(day)) {
      setAllowedDays(allowedDays.filter((allowedDay) => allowedDay !== day));
    } else {
      setAllowedDays(sortDaysOfTheWeek(allowedDays.concat([day])));
    }
  }

  useEffect(() => {
    if (trainingAttributes) {
      setAllowedDays(
          altDayOfTheWeek
          .filter(
              (day) => !trainingAttributes.blockedDays.includes(day.toUpperCase())
          )
          .map((day) => titleCase(day))
      );
    }
  }, [trainingAttributes]);

  return {
    allowedDays,
    blockedDays,
    handleWorkoutDayChange,
    submitWorkoutDays,
  };
}

export interface useWorkoutDaysOutput {
  allowedDays: string[] | undefined;
  handleWorkoutDayChange: (day: string) => void;
  submitWorkoutDays(): Promise<200 | null>;
}

export default useWorkoutDays;
