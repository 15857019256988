/* eslint-disable */
import { MinusSmIcon, MenuIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { classNames } from "../../Utilities/classnames";
import SmartField from "../Calendar/Input/SmartField";

type Instruction = {
  index: number;
  content: string;
};

function Instruction({
  instruction,
  index,
  isDisabled,
  updateInstruction,
  deleteInstruction,
  moveInstruction,
}: {
  instruction: Instruction;
  index: number;
  isDisabled: boolean;
  updateInstruction: (index: number, newValue: string) => void;
  deleteInstruction: (index: number) => void;
  moveInstruction: (instructionIndex: number, destinationIndex: number) => void;
}) {
  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: "instruction",
      drop: (item: any) => {
        moveInstruction(item.instruction.index, index);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      hover(item) {},
    }),
    [instruction]
  );

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: "instruction",

      item: { instruction },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [instruction]
  );

  useEffect(() => {}, [isOver]);

  useEffect(() => {
    if (!isDragging) {
    } else {
    }
  }, [isDragging]);

  useEffect(() => {
    if (instruction) {
    } else {
    }
  }, [instruction]);

  function updateThisInstruction(content: string) {
    updateInstruction(index, content);
  }

  return (
    <div ref={dropRef}>
      <div
        ref={dragRef}
        key={index}
        className={classNames(
          isOver ? "border-t border-brand pt-1.5" : "",
          isDragging ? "hidden" : "",
          "flex items-center relative"
        )}
      >
        <MenuIcon className="w-6 h-3 text-gray-600 shrink-0" />

        <SmartField
          isDisabled={isDisabled}
          id={`instruction-${index}`}
          className="block w-full mr-1.5 border border-gray-300 rounded-md shadow-sm resize-none disabled:cursor-not-allowed focus:ring-brand focus:border-brand sm:text-sm"
          value={instruction.content}
          onChange={updateThisInstruction}
        />

        <button
          type="button"
          disabled={isDisabled}
          onClick={(e) => {
            e.preventDefault();
            deleteInstruction(index);
          }}
          className="inline-flex items-center justify-center flex-initial w-6 h-6 text-xs font-medium text-red-800 bg-red-100 rounded-full disabled:cursor-not-allowed disabled:hidden focus:outline hover:bg-red-200 focus:outline-red-500"
        >
          <MinusSmIcon className="w-3 h-3" />
        </button>
      </div>
    </div>
  );
}

export default Instruction;
