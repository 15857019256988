import {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {getTrainingAttributes, trainingAttributes,} from "../Api/TrainingAttribute";
import {
  getUserInfo,
  isCoach,
  isMusicStandaloneUser,
  signIn,
  signInCredentials,
  signUp,
  SignUpCredentials,
  UserInfo,
  userInfoResponse,
} from "../Api/User";
import RunzyLogo from "./../Assets/RunzyLogo.svg";
import {track, TrackingEventType} from "../Api/Tracking";
import {useUserInfo} from "../Context/UserContext";
import {IonContent, IonPage} from "@ionic/react";
import Spinner from "../Coachee/Components/Spinner";
import {useTrainingAttributes} from "../Context/TrainingAttributeContext";

function SignIn({isSignUp = false}: {isSignUp: boolean}) {
  const history = useHistory();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMusicApp, setIsMusicApp] = useState<boolean>(false);
  const MUSIC_APP_SIGN_UP_PARAM = "sourceApp";
  const { setUserInfo } = useUserInfo();
  const [displayForm, setDisplayForm] = useState(false);
  const { setTrainingAttributes } = useTrainingAttributes();

  async function handleSignIn(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(null);

    const credentials: signInCredentials = {
      username: username,
      password: password,
    };

    const response = await signIn(credentials);
    setIsLoading(false);
    track(TrackingEventType.TRACKING_APP_WEB_ATHLETE_DASHBOARD, "Signed in");
    if (!response) {
      setErrorMessage("Something went wrong. Please try again later.");
    } else {
      if (response && response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        handleRedirect();
      }
    }
  }

  async function handleSignUp(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(null);
    const credentials: SignUpCredentials = {
      username: username,
      password: password,
      source: isMusicApp ? "SOURCE_IOS_MUSIC" : "FREE_TIER",
    };

    const response = await signUp(credentials);
    setIsLoading(false);
    if (!response) {
      setErrorMessage("Something went wrong. Please try again later.");
    } else {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
        return;
      }
      track(
          TrackingEventType.TRACKING_APP_WEB_SIGN_UP,
          "New user sign up. App: " + (isMusicApp ? "Music" : "Running"),
      );
      const meResponse = await getUserInfo();

      if (meResponse.userInfo) {
        setUserInfo(meResponse.userInfo);
        if (isMusicApp) {
          history.push("/music/accounts");
        } else {
          history.push("/");
        }
      }
    }
  }

  function getUrlParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params;
  }

  const handleRedirect = useCallback(() => {
    async function redirectToRoute(
      userInfo: UserInfo,
      trainingAttributes?: trainingAttributes
    ) {
      const params = getUrlParams();
      const musicApp = params[MUSIC_APP_SIGN_UP_PARAM] && params[MUSIC_APP_SIGN_UP_PARAM] == "music" || isMusicStandaloneUser(userInfo);


      if (musicApp) {
        setIsMusicApp(true);
      }
      if (isCoach(userInfo)) {
        history.push("/coach");
      } else {
        if (musicApp && !(params["c"] || params["eId"])) {
          history.push("/music/accounts");
        }
        // else if (trainingAttributes && trainingAttributes.onboardingProgress === 0) {
        //   history.push("/onboard-survey");
        // } else if (
        //   trainingAttributes &&
        //   trainingAttributes.onboardingProgress === 1
        // ) {
        //   history.push("/onboard-survey");
        // }
        else if (
          trainingAttributes &&
          trainingAttributes.onboardingProgress < 4
        ) {
          history.push("/pending");
        } else {
          history.replace("dashboard");
        }
      }
    }

    async function redirectIfLoggedIn() {
      const params = getUrlParams();
      const musicApp = params[MUSIC_APP_SIGN_UP_PARAM] && params[MUSIC_APP_SIGN_UP_PARAM] == "music";
      if (musicApp) {
        setIsMusicApp(true);
      }
      const userInfoData = await getUserInfo();
      const userInfo = userInfoData.userInfo;

      if (userInfoData.status === userInfoResponse.Invalid) {
        setDisplayForm(true);
      } else if (userInfo) {
        const trainingAttributesResponse = await getTrainingAttributes(
          userInfo.miniProfile.id
        );
        setUserInfo(userInfo);
        if (trainingAttributesResponse) {
          setUserInfo(userInfo);
          setTrainingAttributes(trainingAttributesResponse);
        }
        redirectToRoute(userInfo, trainingAttributesResponse ? trainingAttributesResponse : undefined);
        return;
      }

      setDisplayForm(true);
    }

    redirectIfLoggedIn();
  }, [history, setUserInfo, setTrainingAttributes]);

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  return (
    <IonPage>
      <IonContent forceOverscroll={false}>
        <div className="flex flex-col h-screen min-h-full py-12 bg-white sm:px-6 lg:px-8 ">
          {displayForm ? (
            <>
              {" "}
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                  className="w-auto h-8 mx-auto mt-10"
                  src={RunzyLogo}
                  alt="Runzy"
                />
                <div className="mt-10 text-center">
                  <h1 className="text-3xl font-semibold cursor-pointer">
                    {isSignUp ? (isMusicApp ? "Register for Runzy Music" : "Register for free") : "Welcome back."}
                  </h1>
                </div>
              </div>
              {errorMessage && (
                <div
                  className="max-w-4xl px-4 mx-auto relative py-3 my-3 text-xs text-center text-red-700 bg-red-100 border border-red-400 rounded"
                  role="alert"
                >
                  <strong className="font-bold">Error: </strong>
                  <span className="block sm:inline">{errorMessage}</span>
                </div>
              )}
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="px-4 py-8 bg-white sm:rounded-lg sm:px-10 ">
                  <form className="space-y-5">
                    <div>
                      <label
                        htmlFor="email"
                        className="block pl-2 text-sm font-medium text-left text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          onChange={(e) => setUsername(e.target.value)}
                          className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-brand focus:border-brand sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block pl-2 text-sm font-medium text-left text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                          className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-brand focus:border-brand sm:text-sm"
                        />
                      </div>
                      {!isSignUp && <div className="flex justify-end mt-3 text-sm font-semibold text-brand">
                        <a href="/forgot-password/">
                          Forgot your password?
                        </a>
                      </div>}
                    </div>

                    <div>
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="flex justify-center w-full px-4 py-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                        onClick={(e) => isSignUp ? handleSignUp(e) : handleSignIn(e)}
                      >
                        {isLoading && (
                          <div
                            className="inline-block w-4 h-4 text-white rounded-full spinner-border animate-spin border-1"
                            role="status"
                          ></div>
                        )}
                        &nbsp; {isSignUp ? "Create account" : "Sign in"}
                      </button>
                    </div>
                  </form>
                  {/*{!isSignUp && <div className="flex justify-center gap-1 mt-8 text-sm">*/}
                  {/*  <p>Don't have an account yet?</p>*/}
                  {/*  <button*/}
                  {/*    onClick={() => routerHook.push("/onboard-survey")}*/}
                  {/*    className="text-brand"*/}
                  {/*  >*/}
                  {/*    Sign up*/}
                  {/*  </button>*/}
                  {/*</div>}*/}
                  {/*{isSignUp && <div className="flex justify-center gap-1 mt-8 text-sm">*/}
                  {/*  <p>Already have an account?</p>*/}
                  {/*  <button*/}
                  {/*      onClick={() => routerHook.push("/sign-in")}*/}
                  {/*      className="text-brand"*/}
                  {/*  >*/}
                  {/*    Sign in*/}
                  {/*  </button>*/}
                  {/*</div>}*/}
                </div>{" "}
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
}

export default SignIn;
