import useAuth from "../../../Common/Hooks/useAuth";
import { useTrainingAttributes } from "../../../Context/TrainingAttributeContext";
import { getAuthToken, isMobileEmbeddedView } from "../../../Api/Auth";
import { deleteAccount } from "../../../Api/Settings";
import { isFree } from "../../../Api/TrainingAttribute";
import NavButton from "./NavButton";
import { navigate, NavigationOption } from "./navUtil";

function Management() {
  const { handleSignOut } = useAuth();
  const { trainingAttributes } = useTrainingAttributes();
  const currentPlan = trainingAttributes?.productName;

  return (
    <div className="p-4 bg-white">
      <h2 className="block text-[12px] text-[#9CA3AF] spacing-[20px] tracking-[1px] mb-2">
        ACCOUNT MANAGEMENT
      </h2>

      <NavButton
        title="Notifications"
        onClick={() => {
          navigate(NavigationOption.NOTIFICATIONS);
        }}
        hasBottomBorder={true}
      />

      <NavButton
        title="Membership"
        onClick={() => {
          navigate(NavigationOption.MEMBERSHIP);
        }}
        hasBottomBorder={true}
      />

      <NavButton
        onClick={() =>
          isMobileEmbeddedView("1.0.24")
            ? (window.location.href = window.location.href + "#contact")
            : (window.location.href =
                "mailto:help@runzy.com?subject=Runzy%20Coaching%20Question")
        }
        title="Contact Support"
        hasBottomBorder={true}
      />

      {!currentPlan || (trainingAttributes && isFree(trainingAttributes)) ? (
        <NavButton
          onClick={async () => {
            if (
            // eslint-disable-next-line no-restricted-globals
              confirm(
                "Are you sure you wish to permanently delete your account? This cannot be undone"
              )
            ) {
              const authToken = getAuthToken();
              if (authToken) {
                await deleteAccount(authToken);
                alert("Your account was successfully deleted");
                window.location.href = "/sign-out-redirect";
              } else {
                alert("Failed to delete your account");
              }

              alert("Your account was successfully deleted");
            }
          }}
          title="Delete Account"
        />
      ) : (
        <></>
      )}
      <button
        onClick={() => {
          handleSignOut();
        }}
        className="flex bg-neutral-100 items-center justify-center w-full gap-2.5 py-3 mt-3 rounded-[200px]"
      >
        <p className="font-medium text-neutral-900 text-[18px] leading-[28px]">
          Log out
        </p>
      </button>
    </div>
  );
}

export default Management;
