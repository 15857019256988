import { Comment } from "../../../../Api/Stream";
import { MiniProfile } from "../../../../Api/TrainingProfile";
import { useUserInfo } from "../../../../Context/UserContext";
import ProfilePicture from "../../../../Utilities/Components/ProfilePicture";
import { useActivityFeedbackOutput } from "../../../Hooks/useActivityFeedback";
import ActivityFeedbackCommentDropdown from "../ActivityFeedbackCommentDropdown";
import AthleteLabel from "./AthleteLabel";
import CoachLabel from "./CoachLabel";

function Header({
  actorMiniProfile,
  isAthlete,
  activityFeedback,
  comment,
  enableEditMode,
}: {
  actorMiniProfile: MiniProfile;
  isAthlete: boolean;
  activityFeedback: useActivityFeedbackOutput;
  comment: Comment;
  enableEditMode: () => void;
}) {
  const { userInfo } = useUserInfo();
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <ProfilePicture
          url={actorMiniProfile.profilePicture}
          alt=""
          classNames="w-8 h-8 rounded-lg"
        />
        <h3 className="font-medium text-[#222222]">
          {actorMiniProfile.name.split(" ")[0]}
        </h3>
        {isAthlete ? <AthleteLabel /> : <CoachLabel />}
      </div>
      {userInfo?.miniProfile.id === actorMiniProfile.id && (
        <ActivityFeedbackCommentDropdown
          comment={comment}
          activateDeleteModal={activityFeedback.activateDeleteModal}
          enableEditMode={enableEditMode}
        />
      )}
    </div>
  );
}

export default Header;
