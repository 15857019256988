import { trainingProfile } from "../../../Api/TrainingProfile";

import { useUserInfo } from "../../../Context/UserContext";
import { isSuperAdmin } from "../../../Api/User";
import Tooltip from "../../../Utilities/Components/Tooltip";
import RunsCompleted from "./RunsCompleted";
import CompletionScore from "./CompletionScore";
import MilesCompleted from "./MilesCompleted";
import ProfilePicture from "../../../Utilities/Components/ProfilePicture";
import { ReactComponent as TargetIcon } from "../../../Assets/Coach/Summary/targetIcon.svg";
import { ReactComponent as UserProfileIcon } from "../../../Assets/Coach/Summary/userProfileIcon.svg";
import { ReactComponent as HeightIcon } from "../../../Assets/Coach/Summary/heightIcon.svg";
import { ReactComponent as WeightIcon } from "../../../Assets/Coach/Summary/weightIcon.svg";
import { formatStringNumberDate } from "../../../Utilities/DateUtilities";
import { ReactComponent as LeftIcon } from "../../../Assets/Coach/Summary/leftIcon.svg";
import { ReactComponent as RightIcon } from "../../../Assets/Coach/Summary/rightIcon.svg";
import { useTrainingProfileOutput } from "../useTrainingProfile";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

// pure func that gets goal name and date transform date from string with numbers into readable string and return target text
function getTargetText(goalName: string, goalDate: string): string {
  let goalText = "";
  if (goalName && goalName !== "" && goalDate !== "") {
    if (goalName === "Not chosen yet") {
      goalText = formatStringNumberDate(goalDate);
    } else {
      goalText = `${goalName} on ${formatStringNumberDate(goalDate)}`;
    }
  }
  return goalText;
}

function Summary({
  profile,
  trainingProfileHook,
}: {
  profile: trainingProfile;
  trainingProfileHook: useTrainingProfileOutput;
}) {
  const { userInfo } = useUserInfo();

  const { coachingFeedbacks, productDescription } = profile;
  const { goalDate, goalName, emailAddress, weight, height, gender, age } = profile.trainingAttribute;
  const { name, profilePicture, numericId } = profile.miniProfile;
  const userName =
    userInfo && isSuperAdmin(userInfo)
      ? `${name.trim() !== "" ? name : emailAddress}`
      : `${name}`;
  const firstName = userName.split(" ")[0];
  const actionCount = coachingFeedbacks.length;

  return (
    <div className="mb-12 space-y-6 sm:px-6">
      <div className="flex flex-col gap-6 justify-between items-center md:flex-row md:items-start">
        {/* Here is user's info  */}
        <div className="flex items-center gap-3">
          <ProfilePicture
            url={profilePicture}
            alt=""
            classNames="w-auto h-[128px] rounded-full border-[1.5px] border-white"
          />
          <div className="flex flex-col">
            {coachingFeedbacks.length !== 1 ||
            coachingFeedbacks[0] !== "Everything is in order" ? (
              <Tooltip
                display={
                  <p className="text-[#6B7280] text-[14px] self-start">
                    📮 {firstName} has {actionCount}{" "}
                    {actionCount === 1 ? "action" : "actions"} needed.
                  </p>
                }
                content={
                  <ul className="self-start ml-6 space-y-4 text-left list-disc list-outside">
                    {coachingFeedbacks.map((feedback, i) => (
                      <li key={i}>{feedback}</li>
                    ))}
                  </ul>
                }
                isCentered={false}
              />
            ) : (
              <p className="text-[#6B7280] text-[14px] self-start">
                ✅ Everything is in order.
              </p>
            )}

            <h1 className="font-bold text-2xl inline-flex flex-col items-start md:text-[32px] md:flex-row md:items-center">
              {userName}
              <span className="text-xl text-gray-400 ml-2">({numericId})</span>
            </h1>
            {productDescription && productDescription !== "N/A" && (
              <p className="text-sm text-gray-500">
                {profile.productDescription} Plan
              </p>
            )}
            <div className="grid grid-cols-2 gap-x-2 gap-y-1 mt-1">
              {goalName && (
                <div className="inline-flex flex-row items-start gap-1 mt-1">
                  <TargetIcon />
                  <p className="text-sm text-gray-900">
                    {getTargetText(goalName || "", goalDate || "")}
                  </p>
                </div>
              )}
              {height && (
                <div className="inline-flex flex-row items-center gap-1 mt-1">
                  <HeightIcon />
                  <p className="text-sm text-gray-900">Height: ${height}</p>
                </div>
              )}
              {(age || gender) && (
                <div className="inline-flex flex-row items-center gap-1 mt-1">
                  <UserProfileIcon />
                  <p className="text-sm text-gray-900">{gender ? `${gender},` : ''} {age || ''}</p>
                </div>
              )}
              {weight && (
                <div className="inline-flex flex-row items-center gap-1 mt-1">
                  <WeightIcon />
                  <p className="text-sm text-gray-900">Weight: {weight}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Here is control for coaching to swap runner profiles */}
        <div className="flex items-center gap-8">
          <button
            onClick={() => trainingProfileHook.getPreviousTrainingProfile()}
            className="inline-flex flex-row items-center text-sm text-gray-900"
          >
            <LeftIcon className="w-6 h-6" />
            Previous athlete
          </button>
          <button
            onClick={() => trainingProfileHook.getNextTrainingProfile()}
            className="inline-flex flex-row items-center text-sm text-gray-900"
          >
            Next athlete
            <RightIcon className="w-6 h-6" />
          </button>
        </div>
      </div>
      {/* When will be task to refactore this part with new data it should be replaced with map and commonm component */}
      {/* TODO: remove after redesign*/}
      {/*<div className="flex ml-2 flex-col items-start gap-2 lg:flex-row md:ml-0">*/}
      {/*  /!* miles completed this week (x of y) *!/*/}
      {/*  <MilesCompleted profile={profile} />*/}
      {/*  /!* runs completed this week *!/*/}
      {/*  <RunsCompleted profile={profile} />*/}
      {/*  /!* completion score (no trend) *!/*/}
      {/*  <CompletionScore profile={profile} />*/}
      {/*</div>*/}
    </div>
  );
}

export default Summary;
