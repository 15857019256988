export default function NameInput({
  isDisabled,
  currentName,
  setCurrentName,
}: {
  isDisabled?: boolean;
  currentName: string;
  setCurrentName: (title: string) => void;
}) {
  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="title"
        className="block text-sm font-medium text-gray-700"
      >
        Template Name
      </label>
      <div className="flex mt-1 rounded-md shadow-sm">
        <input
          disabled={isDisabled}
          type="text"
          name="title"
          id="title"
          autoComplete="title"
          className="flex-1 block w-full min-w-0 border-gray-300 rounded-md disabled:cursor-not-allowed focus:ring-brand focus:border-brand sm:text-sm"
          value={currentName}
          onChange={(e) => {
            if (isDisabled) return;
            setCurrentName(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
