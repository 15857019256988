function EmptyState() {
  function emptyMessage() {
    // eslint-disable-next-line no-restricted-globals
    if (location.pathname.replaceAll("/", "").endsWith("basic")) {
      return "You don't have any basic athletes that need attending to. Way to go!";
      // eslint-disable-next-line no-restricted-globals
    } else if (location.pathname.replaceAll("/", "").endsWith("reviewed")) {
      return "You haven't reviewed any basic athletes recently.";
    } else {
      return "You don't have any core or pro athletes.";
    }
  }

  function emptyImage() {
    // eslint-disable-next-line no-restricted-globals
    if (location.pathname.replaceAll("/", "").endsWith("basic")) {
      // return <EmojiHappyIcon className="w-24 h-24 mx-auto text-gray-400" />;
      return <h1 className="text-[64px]">👏</h1>;
      // eslint-disable-next-line no-restricted-globals
    } else if (location.pathname.replaceAll("/", "").endsWith("reviewed")) {
      // return <EmojiSadIcon className="w-24 h-24 mx-auto text-gray-400" />;
      return <h1 className="text-[64px]">🙈</h1>;
    } else {
      // return (
      //   <svg
      //     className="w-12 h-12 mx-auto text-gray-400"
      //     fill="none"
      //     stroke="currentColor"
      //     viewBox="0 0 48 48"
      //     aria-hidden="true"
      //   >
      //     <path
      //       strokeLinecap="round"
      //       strokeLinejoin="round"
      //       strokeWidth={2}
      //       d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
      //     />
      //   </svg>
      // );
      return <h1 className="text-[64px]">🔍</h1>;
    }
  }

  return (
    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
      <div>
        <div className="mt-12 text-center">
          {emptyImage()}
          <h2 className="mt-2 text-lg font-medium text-gray-900">
            {emptyMessage()}
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            {/* You haven’t added any team members to your project
                yet. As the owner of this project, you can manage team
                member permissions. */}
          </p>
        </div>
      </div>
    </div>
  );
}

export default EmptyState;
