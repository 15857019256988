import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { classNames } from "../../Utilities/classnames";

interface ActivityDropdownInput {
  actions: { key: string; action?: Function }[];
}

function ActivityDropdown({ actions }: ActivityDropdownInput) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center text-gray-400 rotate-90 rounded-full hover:text-gray-600 focus:outline-none focus:ring-brand">
          <span className="sr-only">Open options</span>
          <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute top-0 right-0 z-20 origin-top-right bg-white rounded-md shadow-lg cursor-pointer ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {actions
              .filter((action) => action.action !== undefined)
              .map((action, key) => (
                <Menu.Item key={key}>
                  {({ active }) => (
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                        if (action.action) action.action();
                      }}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      {action.key}
                    </a>
                  )}
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default ActivityDropdown;
