import React from "react";
import ReactDOM from "react-dom";
import {IonReactRouter} from "@ionic/react-router";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import Default from "./Default/Default";
import {UserInfoProvider} from "./Context/UserContext";
import {CookiesProvider} from "react-cookie";
import {initAuth} from "./Api/Auth";
import {IonApp} from "@ionic/react";
import "@ionic/react/css/core.css";

import "./index.css";
import {TrainingAttributesProvider} from "./Context/TrainingAttributeContext";
import {TemplateContextProvider} from "./Context/TemplateContext";

axios.defaults.baseURL = Default.apiBaseURL;
axios.defaults.headers.common['x-timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
axios.defaults.withCredentials = Default.axiosWithCredentials;
axios.defaults.headers = {...axios.defaults.headers, ...Default.axiosHeaders};

initAuth();

ReactDOM.render(
    <React.StrictMode>
        <CookiesProvider>
            <UserInfoProvider>
                <TrainingAttributesProvider>
                    <IonApp>
                        <IonReactRouter>
                            <TemplateContextProvider>
                                <App/>
                            </TemplateContextProvider>
                        </IonReactRouter>
                    </IonApp>
                </TrainingAttributesProvider>
            </UserInfoProvider>
        </CookiesProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
