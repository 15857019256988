import { ActivityRoute } from "../../Api/ActivityRoute";
import { TrainingEventType } from "../../Api/TrainingEvent";
import { trainingProfile } from "../../Api/TrainingProfile";
import { CalendarDay } from "../Calendar";
import AssignedDistanceInput from "../Calendar/Input/AssignedDistanceInput";
import AssignedDurationInput from "../Calendar/Input/AssignedDurationInput";
import DateInput from "../Calendar/Input/DateInput";
import NoteInput from "../Calendar/Input/NoteInput";
import TitleInput from "../Calendar/Input/TitleInput";
import TypeDropdown from "./TypeDropdown";
import { EditableStep } from "../../Api/Step";
import Steps from "./Steps/Steps";
import Instructions from "./Instructions";

interface SelectedDayInstructionsInput {
  currentDescription: string[];
  setCurrentDescription: React.Dispatch<React.SetStateAction<string[]>>;
  isDisabled: boolean;
  currentType: TrainingEventType;
  currentAssignedDistance: string;
  setCurrentAssignedDistance: (value: React.SetStateAction<string>) => void;
  selectedDay: CalendarDay;
  currentAssignedDuration: string;
  setCurrentAssignedDuration: React.Dispatch<React.SetStateAction<string>>;
  selectedTrainingProfile: trainingProfile;
  activityRoute: ActivityRoute | null;
  openActivity: () => void;
  currentScheduledAt: Date | undefined;
  handleDateChange(e: string): void;
  setCurrentType: React.Dispatch<React.SetStateAction<TrainingEventType>>;
  currentTitle: string;
  currentNote: string;
  setCurrentNote: React.Dispatch<React.SetStateAction<string>>;
  setCurrentTitle: React.Dispatch<React.SetStateAction<string>>;
  currentSteps: EditableStep[];
  setCurrentSteps: React.Dispatch<React.SetStateAction<EditableStep[]>>;
}

function SelectedDayInstructions({
  currentDescription,
  setCurrentDescription,
  isDisabled,
  currentType,
  currentAssignedDistance,
  setCurrentAssignedDistance,
  currentAssignedDuration,
  setCurrentAssignedDuration,
  currentScheduledAt,
  handleDateChange,
  setCurrentType,
  currentTitle,
  setCurrentTitle,
  currentNote,
  setCurrentNote,
  currentSteps,
  setCurrentSteps,
  selectedTrainingProfile,
}: SelectedDayInstructionsInput) {
  return (
    <>
      <div>
        <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <div className="flex gap-2">
              <DateInput
                currentScheduledAt={currentScheduledAt}
                handleDateChange={handleDateChange}
                isDisabled={isDisabled}
              />
              <div className="w-1/2">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type
                </label>
                <div className="z-50 mt-1">
                  <TypeDropdown
                    selectedType={currentType}
                    setSelected={setCurrentType}
                    isDisabled={isDisabled}
                  />
                </div>
              </div>
            </div>
          </div>

          <TitleInput
            currentTitle={currentTitle}
            setCurrentTitle={setCurrentTitle}
            isDisabled={isDisabled}
          />

          <NoteInput
            currentNote={currentNote}
            setCurrentNote={setCurrentNote}
            isDisabled={isDisabled}
          />

          <div className="flex w-full items-center justify-between sm:col-span-6 gap-2">
            <AssignedDistanceInput
              currentAssignedDistance={currentAssignedDistance}
              setCurrentAssignedDistance={setCurrentAssignedDistance}
              isDisabled={isDisabled}
            />

            <AssignedDurationInput
              currentAssignedDuration={currentAssignedDuration}
              setCurrentAssignedDuration={setCurrentAssignedDuration}
              isDisabled={isDisabled}
            />
          </div>

          <div className="sm:col-span-6">
            {currentDescription.length === 0 ||
            (currentDescription.length === 1 &&
              currentDescription[0] === "") ? (
              <Steps
                currentSteps={currentSteps}
                setCurrentSteps={setCurrentSteps}
                selectedTrainingProfile={selectedTrainingProfile}
              />
            ) : (
              <Instructions
                currentDescription={currentDescription}
                setCurrentDescription={setCurrentDescription}
                isDisabled={isDisabled}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectedDayInstructions;
