import CoacheeHeader from "../Coachee/Components/CoacheeHeader";
import { AnnotationIcon } from "@heroicons/react/outline";
import useNotifications from "./Hooks/useNotifications";
import { formatNotificationDate } from "../Utilities/DateUtilities";
import Spinner from "../Coachee/Components/Spinner";
import { Notification } from "../Api/Stream";
import { IonContent, IonPage, IonRouterLink } from "@ionic/react";
import { isMobile } from "../Utilities/isMobile";
import MobilePageHeader from "../Coachee/Components/MobilePageHeader";
import { useState } from "react";
import profilePic from "../Assets/notificationAvatarPlaceholder.png";
import ProfilePicture from "../Utilities/Components/ProfilePicture";
import DefaultWebFooter from "../Coach/Components/Footer/DefaultWebFooter";

function Notifications() {
  const {
    feedItems,
    trainingAttributes,
    isReadMap,
    isLoading,
    handleClickNotification,
  } = useNotifications({});
  const [hasScrolled, setHasScrolled] = useState(false);

  function formatDate(feedItem: Notification) {
    if (!feedItem.activities[0].trainingEvent) return "";
    const dateSplit = (
      "" + feedItem.activities[0].trainingEvent.scheduledAt
    ).split("-");
    return dateSplit[1] + "/" + dateSplit[2];
  }

  return (
    <IonPage>
      {isMobile ? (
        <MobilePageHeader
          title="Notifications"
          defaultHref="/dashboard"
          hasScrolled={hasScrolled}
        />
      ) : (
        <CoacheeHeader trainingAttributes={trainingAttributes} />
      )}
      <IonContent
        scrollEvents={true}
        onIonScroll={(e) => setHasScrolled(e.detail.scrollTop !== 0)}
      >
        {" "}
        <div className="mx-5 lg:max-w-xl lg:mx-auto lg:mt-20">
          {!isMobile && (
            <div className="mx-1 mt-10 text-left">
              <h1 className="mb-4 text-xl font-semibold">Notifications</h1>
            </div>
          )}

          {isLoading ? (
            <>
              <Spinner />
            </>
          ) : (
            <>
              {feedItems.length > 0 ? (
                <ul className="divide-y divide-gray-100">
                  {feedItems.map((feedItem) => (
                    <IonRouterLink
                      routerLink={
                        feedItem.activities[0].trainingEvent
                          ? `/activity/${feedItem.activities[0].trainingEvent.id}/feedback`
                          : `/dashboard/training-log/${feedItem.activities[0].object.replace(
                              "perform:streamActivity:",
                              ""
                            )}`
                      }
                      key={feedItem.id}
                      onClick={() => {
                        handleClickNotification(feedItem);
                      }}
                    >
                      <div className="relative py-4 cursor-pointer">
                        <div className="relative flex items-center space-x-3">
                          <>
                            <>
                              <div className="relative">
                                <ProfilePicture
                                  url={
                                    feedItem.activities[0].actorMiniProfile
                                      .profilePicture ?? profilePic
                                  }
                                  classNames="flex items-center justify-center bg-gray-400 w-9 h-9 rounded-xl ring-8 ring-transparent"
                                />

                                <span className="absolute -bottom-0.5 -right-1 bg-white rounded-full px-0.5 py-0.5">
                                  <AnnotationIcon className="w-3.5 h-3.5 text-gray-600" />
                                </span>
                              </div>
                              <div className="flex-1 min-w-0">
                                <div className="flex items-center justify-between pr-1">
                                  <div className="pr-1">
                                    <div className="text-xs font-light text-black line-clamp-1">
                                      <span className="font-semibold">
                                        {
                                          feedItem.activities[0].actorMiniProfile.name.split(
                                            " "
                                          )[0]
                                        }
                                      </span>
                                      {"  "}
                                      {feedItem.verb === "posted"
                                        ? "posted "
                                        : "commented on "}
                                      {feedItem.activities[0].trainingEvent ? (
                                        <span className="font-semibold">
                                          {
                                            feedItem.activities[0].trainingEvent
                                              .title
                                          }{" "}
                                          ({formatDate(feedItem)})
                                        </span>
                                      ) : (
                                        <span className="font-semibold">
                                          a training note
                                        </span>
                                      )}
                                      {feedItem.activities[0].message && (
                                        <span>
                                          : {feedItem.activities[0].message}
                                        </span>
                                      )}
                                    </div>

                                    <p className="mt-0.5 text-xs font-thin text-gray-500">
                                      {formatNotificationDate(
                                        new Date(feedItem.updated_at + "Z")
                                      )}
                                    </p>
                                  </div>{" "}
                                  {!isReadMap?.find(
                                    (isReadMap) => isReadMap.id === feedItem.id
                                  )?.is_read ? (
                                    <div className="overflow-visible shrink-0 w-2.5 h-2.5 rounded-full bg-rose-400"></div>
                                  ) : (
                                    <div className="overflow-visible shrink-0 invisible w-2.5 h-2.5 rounded-full bg-rose-400"></div>
                                  )}
                                </div>
                              </div>
                            </>
                          </>
                        </div>
                      </div>
                    </IonRouterLink>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-400 ">
                  No notifications. Check back later!
                </p>
              )}
            </>
          )}
        </div>
      </IonContent>
      {!isMobile && <DefaultWebFooter />}
    </IonPage>
  );
}

export default Notifications;
