import axios from 'axios'
import { onboardingProgressStatus, trainingAttributes, } from './TrainingAttribute'
import { FormValidation } from './Common'

export type TrainingStats = {
  favoriteRunningDay: string;
  activitiesLast7Days: number;
  activitiesLast28Days: number;
  allTimeTrainingStreak: number;
  averageWeeklyDistanceLast28Days: number;
  completionScore: number;
  distanceLast7Days: number;
  distanceLast28Days: number;
  distanceTrendLast7Days: number;
  distanceTrendLast28Days: number;
  durationLast7Days: number;
  durationLast28Days: number;
  totalDistance: number;
  totalRuns: number;
  trainingStreak: number;
  trendActivitiesLast7Days: number;
  trendActivitiesLast28Days: number;
  trendDurationLast7Days: number;
  trendDurationLast28Days: number;
  assignedRunsThisWeek: number;
  assignedDistanceThisWeek: number;
  assignedDurationThisWeek: number;
  isTimeBasedAthlete: boolean;
};

export type trainingProfile = {
  miniProfile: MiniProfile;
  trainingAttribute: trainingAttributes;
  scheduledConsecutiveTrainingEvents: number | null;
  upcomingDrafts: number | null;
  coachingStatus: string;
  coachingFeedbacks: string[];
  productDescription: string;
  daysSinceLastCoachInteraction: number;
  stats: TrainingStats;
  unreadNotificationsForAthlete: number;
  trainingAdminAttributes: TrainingAdminAttributes | null;
};

export type MiniProfile = {
  isTest: boolean;
  phone: string | null;
  created: number;
  id: string;
  name: string;
  profilePicture: string;
  numericId: number;
};

export type TrainingAdminAttributes = {
  id: string;
  profile: string;
  updatedAt: number;
  reviewDayOfWeek: string;
};

type TrainingProfileParams = {
  filter?: string;
  onboardingProgress?: string;
  toggle_test?: string | number;
  adminView?: boolean;
};

export enum TrainingPlanBuilderAlgorithm {
  SIMPLE = "SIMPLE",
  CONSTRAINT_PROGRAMMING = "CONSTRAINT_PROGRAMMING",
  TEMPLATED = "TEMPLATED",
}

export async function getTrainingProfiles(
  showAllAthletes: boolean,
  onboardingProgressStatuses?: onboardingProgressStatus[],
  enableTestUsers: boolean = false,
): Promise<trainingProfile[] | null> {
  try {
    const params: TrainingProfileParams = {};

    if (!showAllAthletes) params.filter = "own";

    if (onboardingProgressStatuses) {
      params.onboardingProgress = onboardingProgressStatuses
          .map((status) => status.id)
          .join(",");
      params.toggle_test = enableTestUsers ? 1 : 0
    } else {
      return [];
    }
    const response = await axios.get("training-profiles", { params: params });

    return await response.data.items;
  } catch (error: any) {
    return null;
  }
}

export async function getBasicProfilesThatNeedAttention(
  showAllAthletes = false
) {
  try {
    const params: TrainingProfileParams = {
      filter: "basicUsersNeedingAttention",
      adminView: showAllAthletes ? true : undefined,
    };
    const response = await axios.get("training-profiles", { params: params });

    return response.data.items;
  } catch (error: any) {
    return null;
  }
}

export async function getPremiumProfiles(showAllAthletes = false) {
  try {
    const params: TrainingProfileParams = {
      filter: "premiumUsers",
      adminView: showAllAthletes ? true : undefined,
    };
    const response = await axios.get("training-profiles", {
      params: params,
    });

    return response.data.items;
  } catch (error: any) {
    return null;
  }
}

export async function getAllBasicProfiles(showAllAthletes = false) {
  try {
    const params: TrainingProfileParams = {
      filter: "basicUsers",
      adminView: showAllAthletes ? true : undefined,
    };
    const response = await axios.get("training-profiles", {
      params: params,
    });

    return response.data.items;
  } catch (error: any) {
    return null;
  }
}

export async function getTrainingProfile(
  profileId: string
): Promise<trainingProfile | null> {
  try {
    const response = await axios.get(`training-profiles/${profileId}`);

    return await response.data;
  } catch (error: any) {
    return null;
  }
}

export async function searchTrainingProfiles(
  searchQuery: string,
  enableTestUser: boolean,
): Promise<trainingProfile[] | null> {
  try {
    const response = await axios.get("training-profiles", {
      params: { search: searchQuery, toggle_test: Number(enableTestUser) },
    });
    return await response.data.items;
  } catch (error: any) {
    return null;
  }
}

export async function rebootStrava(
  trainingProfile: trainingProfile
): Promise<number | null> {
  try {
    const profileId = trainingProfile.miniProfile.numericId;

    await axios.post("training-profiles", {
      profileId: profileId,
      action: "bootstrapTrainingProfileWithData",
    });

    return 200;
  } catch (error: any) {
    return null;
  }
}

export async function grantCoachPrivileges(
  trainingProfile: trainingProfile
): Promise<number | null> {
  try {
    const profileId = trainingProfile.miniProfile.numericId;

    await axios.post("training-profiles", {
      profileId: profileId,
      action: "addCoachPrivilege",
    });

    return 200;
  } catch (error: any) {
    return null;
  }
}

export async function setPreferredCoachingDay(
  trainingProfile: trainingProfile,
  dayOfTheWeek: string
): Promise<number | null> {
  try {
    const profileId = trainingProfile.miniProfile.numericId;

    await axios.post("training-profiles", {
      profileId: profileId,
      action: "setPreferredCoachingDay",
      reviewDayOfTheWeek: dayOfTheWeek,
    });

    return 200;
  } catch (error: any) {
    return null;
  }
}

export async function resetPassword(
  trainingProfile: trainingProfile
): Promise<number | null> {
  try {
    const profileId = trainingProfile.miniProfile.numericId;

    await axios.post("training-profiles", {
      profileId: profileId,
      action: "resetPassword",
    });

    return 200;
  } catch (error: any) {
    return null;
  }
}

export async function signInAsTrainingProfile(
  trainingProfile: trainingProfile
): Promise<FormValidation | null> {
  try {
    const profileId = trainingProfile.miniProfile.numericId;

    const response = await axios.post("training-profiles", {
      profileId: profileId,
      action: "liam",
    });

    return response.data;
  } catch (error: any) {
    return null;
  }
}

export async function signInAsTrainingProfileId(
  profileId: number
): Promise<FormValidation | null> {
  try {
    const response = await axios.post("training-profiles", {
      profileId: profileId,
      action: "liam",
    });
    return response.data;
  } catch (error: any) {
    return null;
  }
}

export async function generateDrafts(
  profile: trainingProfile,
  days: number,
  weeklyDistance: number,
  algorithm: TrainingPlanBuilderAlgorithm
): Promise<number | null> {
  try {
    const profileId = profile.miniProfile.numericId;

    await axios.post("training-profiles", {
      action: "bootstrapTrainingProgram",
      profileId: profileId,
      daysPerWeek: days,
      currentWeeklyDistance: weeklyDistance,
      algorithm: algorithm,
    });

    return 200;
  } catch (error: any) {
    return null;
  }
}

export async function clearDrafts(
  profile: trainingProfile
): Promise<number | null> {
  try {
    const profileId = profile.miniProfile.numericId;

    await axios.post("training-profiles", {
      action: "clearDrafts",
      profileId: profileId,
    });

    return 200;
  } catch (error: any) {
    return null;
  }
}

export async function adaptDrafts(
  profile: trainingProfile,
  weeks: number
): Promise<number | null> {
  try {
    const profileId = profile.miniProfile.numericId;

    await axios.post("training-profiles", {
      action: "adaptNextWeeksOfDrafts",
      profileId: profileId,
      numWeeks: weeks,
    });

    return 200;
  } catch (error: any) {
    return null;
  }
}
