import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  editTrainingAttributes,
  trainingAttributes,
} from "../../Api/TrainingAttribute";
import { useUserInfo } from "../../Context/UserContext";
import {
  altDayOfTheWeek,
  sortDaysOfTheWeek,
} from "../../Utilities/DateUtilities";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import {
  getSubscriptionsForSignedInUser,
  Subscription,
} from "../../Api/Payment";
import { titleCase } from "../../Utilities/FormatUtilties";
import { StravaSettingField } from "../../Types";
import { useTrainingAttributes } from "../../Context/TrainingAttributeContext";
import { UserInfo } from "../../Api/User";
import { isMobile } from "../../Utilities/isMobile";

function useAthleteSettings() {
  const location = useLocation();
  const { userInfo } = useUserInfo();
  const [subscription, setSubscription] = useState<Subscription>();
  const setCookie = useCookies(["defaultPage"])[1];
  const { trainingAttributes, setTrainingAttributes } = useTrainingAttributes();

  const workoutDays = useCallback(() => {
    function workoutDays() {
      if (trainingAttributes) {
        const allowedDays = altDayOfTheWeek.filter(
          (day) => !trainingAttributes.blockedDays.includes(day.toUpperCase())
        );

        return allowedDays.map((day) => titleCase(day));
      }
    }

    return workoutDays();
  }, [trainingAttributes]);

  async function handleStravaSettingChange(setting: StravaSettingField) {
    if (trainingAttributes && userInfo) {
      let currentTrainingAttributes = trainingAttributes;
      const updatesEnabled = trainingAttributes.stravaUpdatesEnabled;

      switch (setting) {
        case "update":
          currentTrainingAttributes = {
            ...currentTrainingAttributes,
            stravaUpdatesEnabled:
              !currentTrainingAttributes.stravaUpdatesEnabled,
            stravaGoalUpdatesEnabled: true,
            stravaScoreUpdatesEnabled: true,
            stravaStreakUpdatesEnabled: true,
            stravaTitleUpdatesEnabled: true,
          };

          break;
        case "title":
          if (updatesEnabled) {
            currentTrainingAttributes = {
              ...currentTrainingAttributes,
              stravaTitleUpdatesEnabled:
                !currentTrainingAttributes.stravaTitleUpdatesEnabled,
            };
          }
          break;
        case "streak":
          if (updatesEnabled) {
            currentTrainingAttributes = {
              ...currentTrainingAttributes,
              stravaStreakUpdatesEnabled:
                !currentTrainingAttributes.stravaStreakUpdatesEnabled,
            };
          }
          break;
        case "score":
          if (updatesEnabled) {
            currentTrainingAttributes = {
              ...currentTrainingAttributes,
              stravaScoreUpdatesEnabled:
                !currentTrainingAttributes.stravaScoreUpdatesEnabled,
            };
          }
          break;

        case "goal":
          if (updatesEnabled) {
            currentTrainingAttributes = {
              ...currentTrainingAttributes,
              stravaGoalUpdatesEnabled:
                !currentTrainingAttributes.stravaGoalUpdatesEnabled,
            };
          }
          break;

        default:
          currentTrainingAttributes = {
            ...currentTrainingAttributes,
            stravaUpdatesEnabled:
              !currentTrainingAttributes.stravaUpdatesEnabled,
            stravaGoalUpdatesEnabled: true,
            stravaScoreUpdatesEnabled: true,
            stravaStreakUpdatesEnabled: true,
            stravaTitleUpdatesEnabled: true,
          };
          break;
      }

      const response = await editTrainingAttributes(
        currentTrainingAttributes,
        userInfo.id
      );

      if (response) {
        setTrainingAttributes(response);
        if (!isMobile) toast("Strava settings have been successfully edited.");
      }
    }
  }

  async function handleWorkoutDayChange(day: string) {
    if (trainingAttributes && userInfo) {
      let currentBlockedDays = trainingAttributes.blockedDays;

      if (workoutDays()?.includes(day)) {
        currentBlockedDays = sortDaysOfTheWeek(
          currentBlockedDays.concat([day.toUpperCase()])
        );
      } else {
        currentBlockedDays = currentBlockedDays.filter(
          (blockedDay) => blockedDay !== day.toUpperCase()
        );
      }

      const currentTrainingAttributes: trainingAttributes = {
        ...trainingAttributes,
        blockedDays: currentBlockedDays,
      };

      const response = await editTrainingAttributes(
        currentTrainingAttributes,
        userInfo.id
      );

      if (response) {
        setTrainingAttributes(response);
        if (!isMobile) toast("Workout Days have been successfully edited.");
      }
    }
  }

  async function updateWorkoutDays(days: string[]) {
    if (!trainingAttributes || !userInfo) return;

    const currentBlockedDays = altDayOfTheWeek.filter(
      (day) => !days.includes(day)
    );

    const sortedBlockedDays = sortDaysOfTheWeek(currentBlockedDays);
    const uppercaseBlockedDays = sortedBlockedDays.map((day) =>
      day.toUpperCase()
    );

    const currentTrainingAttributes: trainingAttributes = {
      ...trainingAttributes,
      blockedDays: uppercaseBlockedDays,
    };

    const response = await editTrainingAttributes(
      currentTrainingAttributes,
      userInfo.id
    );

    if (response) {
      setTrainingAttributes(response);
      if (!isMobile) toast("Workout Days have been successfully edited.");
    }
  }

  async function handleConnect() {
    const url =
      process.env.REACT_APP_BASE_URL_API +
      "/sign-in?uuid=" +
      userInfo?.token +
      "&redir=" +
      process.env.REACT_APP_BASE_URL_API +
      "/server/authenticate/strava-sync?auth_strava=true&prevent_redir=true";
    setCookie("defaultPage", "settings", { path: "/" });
    window.location.href =
      process.env.REACT_APP_BASE_URL + "/redirect-url?redir=" +
      encodeURI(url) +
      "&uuid=" +
      encodeURIComponent(userInfo?.token ?? "");
  }

  useEffect(() => {
    if (userInfo && trainingAttributes) {
      if (trainingAttributes.onboardingProgress < 3) {
        // TODO: Are we forcing a sign out here? Why?
        // Is there a better way we can handle this?
        // history.replace("/sign-in");
      }

      if (location.hash === "") {
        window.scrollTo(0, 0);
      }
      // else scroll to id
      else {
        setTimeout(() => {
          const id = location.hash.replace("#", "");
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
      }
    }
  }, [userInfo, trainingAttributes, location]);

  useEffect(() => {
    async function initSubscription() {
      if (subscription) return;

      if (userInfo) {
        const response = await getSubscriptionsForSignedInUser();

        if (response && response.length > 0) {
          setSubscription(response[0]);
        }
      }
    }

    initSubscription();
  }, [userInfo]);

  const createCookie = useCallback(() => {
    if (!isMobile) setCookie("defaultPage", "", { path: "/" });
  }, [setCookie]);

  useEffect(() => {
    createCookie();
  }, [createCookie]);

  return {
    userInfo,
    trainingAttributes,
    workoutDays,
    handleWorkoutDayChange,
    handleStravaSettingChange,
    handleConnect,
    subscription,
    updateWorkoutDays,
  } as useAthleteSettingsOutput;
}

export const defaultUseAthleteSettings: useAthleteSettingsOutput = {
  userInfo: null,
  trainingAttributes: undefined,
  workoutDays: function (): string[] | undefined {
    return undefined;
  },
  handleWorkoutDayChange: function (): Promise<void> {
    throw new Error("Function not implemented.");
  },
  handleStravaSettingChange: function (): Promise<void> {
    throw new Error("Function not implemented.");
  },
  handleDisconnectStrava: function (): Promise<void> {
    return new Promise(() => {
      return;
    });
  },
  handleConnect: function (): Promise<void> {
    return new Promise(() => {
      return;
    });
  },
  subscription: undefined,
  updateWorkoutDays: function (): Promise<void> {
    throw new Error("Function not implemented.");
  },
};

export interface useAthleteSettingsOutput {
  userInfo: UserInfo | null;
  trainingAttributes: trainingAttributes | undefined;
  workoutDays: () => string[] | undefined;
  handleWorkoutDayChange: (day: string) => Promise<void>;
  handleStravaSettingChange: (setting: StravaSettingField) => Promise<void>;
  handleDisconnectStrava: () => Promise<void>;
  handleConnect: () => Promise<void>;
  subscription: Subscription | undefined;
  updateWorkoutDays: (days: string[]) => Promise<void>;
}

export default useAthleteSettings;
