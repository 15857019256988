import { trainingProfile } from "../../Api/TrainingProfile";
import { DocumentTextIcon } from "@heroicons/react/outline";
import { formatNotificationDate } from "../../Utilities/DateUtilities";
import useChangeLog from "../../Pages/Hooks/useChangeLog";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";
import RunzyMobileLogo from "../../Assets/RunzyMobileLogo.svg";

interface ChangeLog {
  selectedTrainingProfile: trainingProfile;
}

function ChangeLog({ selectedTrainingProfile }: ChangeLog) {
  const { trainingEventActions } = useChangeLog({
    trainingProfile: selectedTrainingProfile,
  });

  function lowerCaseFirstLetter(string: string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  return (
    <>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="px-4 pt-10 pb-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
            <div className="space-y-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Change log
              </h3>
              <p className="max-w-2xl text-sm text-gray-500"></p>
            </div>
            <div className="mt-6">
              <>
                {trainingEventActions.length > 0 ? (
                  <ul role="list" className="divide-y divide-gray-100">
                    {trainingEventActions.map((trainingEventAction) => (
                      <li
                        onClick={(e: any) => {
                          e.preventDefault();
                        }}
                        key={trainingEventAction.id}
                        className="relative py-4"
                      >
                        <div>
                          <div className="relative flex items-center space-x-3">
                            <>
                              <>
                                <div className="relative">
                                  <ProfilePicture
                                    url={
                                      trainingEventAction.actorMiniProfile &&
                                      trainingEventAction.actorMiniProfile
                                        .profilePicture
                                        ? trainingEventAction.actorMiniProfile
                                            .profilePicture
                                        : RunzyMobileLogo
                                    }
                                    classNames="flex items-center justify-center w-9 h-9 rounded-xl ring-8 ring-transparent"
                                    alt=""
                                  />

                                  <span className="absolute -bottom-0.5 -right-1 bg-white rounded-full px-0.5 py-0.5">
                                    <DocumentTextIcon className="w-3.5 h-3.5 text-gray-600" />
                                  </span>
                                </div>
                                <div className="flex-1 min-w-0">
                                  <div className="flex items-center justify-between pr-1">
                                    <div className="pr-1">
                                      <div className="text-xs font-light text-black">
                                        <span className="font-semibold">
                                          {trainingEventAction.actorMiniProfile
                                            ? trainingEventAction.actorMiniProfile.name.split(
                                                " "
                                              )[0]
                                            : "System"}
                                        </span>
                                        {"  "}
                                        {lowerCaseFirstLetter(
                                          trainingEventAction.action
                                        )}{" "}
                                      </div>

                                      <p className="mt-0.5 text-xs font-thin text-gray-500">
                                        {formatNotificationDate(
                                          trainingEventAction.createdAt
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            </>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-400 ">
                    No changes made to this athlete. Check back later!
                  </p>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeLog;
