import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
} from "@ionic/react";
import { classNames } from "../../Utilities/classnames";
import {isMobileEmbeddedView} from "../../Api/Auth";

function MobilePageHeader({
  title,
  defaultHref,
  hasScrolled,
}: {
  title: string;
  defaultHref?: string;
  hasScrolled: boolean;
}) {
  return (
      !isMobileEmbeddedView() ?
    <IonHeader>
      <IonToolbar
        className={classNames(
          hasScrolled ? "border-b border-gray-200" : "",
          "pb-2 mt-2"
        )}
      >
        <IonButtons className="" slot="start">
          <IonBackButton className="text-brand" defaultHref={defaultHref} />
        </IonButtons>
        <IonTitle>{title}</IonTitle>
      </IonToolbar>
    </IonHeader> : <></>
  );
}

export default MobilePageHeader;
