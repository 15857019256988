import { formatNotificationDate } from "../../../../Utilities/DateUtilities";

function CommentDate({ date }: { date: string }) {
  return (
    <div className="flex items-center justify-end text-[11px] mt-2 text-[#717171]">
      {formatNotificationDate(new Date(date), false)}
    </div>
  );
}

export default CommentDate;
