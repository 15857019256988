import { PlusSmIcon } from "@heroicons/react/outline";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useInstructions from "../Hooks/useInstructions";
import Instruction from "./Instruction";

interface InstructionsInput {
  currentDescription: string[];
  setCurrentDescription: (value: string[]) => void;
  isDisabled?: boolean;
}

function Instructions({
  currentDescription,
  setCurrentDescription,
  isDisabled = false,
}: InstructionsInput) {
  const {
    updateInstruction,
    addInstruction,
    deleteInstruction,
    moveInstruction,
  } = useInstructions({ currentDescription, setCurrentDescription });

  return (
    <>
      <div className="flex items-center gap-2">
        <label
          htmlFor="about"
          className="block text-sm font-medium text-gray-700"
        >
          Instructions
        </label>
        <button
          type="button"
          disabled={isDisabled}
          onClick={(e) => {
            e.preventDefault();
            addInstruction();
          }}
          className="inline-flex items-center px-2.5 py-0.5 disabled:cursor-not-allowed rounded-full disabled:hidden text-xs font-medium bg-green-100 focus:outline hover:bg-green-200 focus:outline-green-500 text-green-800"
        >
          <PlusSmIcon className="w-4 h-4" />
          <p>Add Instruction</p>
        </button>
      </div>

      <div className="mt-2">
        <DndProvider backend={HTML5Backend}>
          <div className="space-y-2">
            {currentDescription.map((instruction, index) => (
              <div key={index}>
                <Instruction
                  instruction={{ index: index, content: instruction }}
                  index={index}
                  isDisabled={isDisabled}
                  updateInstruction={updateInstruction}
                  deleteInstruction={deleteInstruction}
                  moveInstruction={moveInstruction}
                />
              </div>
            ))}
          </div>
        </DndProvider>
      </div>
    </>
  );
}

export default Instructions;
