import React from "react";
import DurationIcon from "../../../../Assets/Calendar/DurationIcon.svg";
import {
  formatDuration,
  encodeDuration,
} from "../../../../Utilities/FormatUtilties";

export interface DurationBlockCalendarEventInput {
  assignedDuration: string;
  actualDuration: number;
}

export const DurationBlock = ({
  assignedDuration,
  actualDuration,
}: DurationBlockCalendarEventInput) => {
  //   format 0:00 string to null or string with time as it is
  const formattedAssignedDuration =
    assignedDuration === "0:00" ? null : assignedDuration;

  // format digit in seconds to null or string in *:** format
  const formattedActualDuration = actualDuration
    ? formatDuration(actualDuration)
    : null;

  const toShowDuration = formattedAssignedDuration || formattedActualDuration;

  return (
    <>
      {toShowDuration && (
        <div className="flex flex-row items-center ">
          <img
            src={DurationIcon}
            alt="distance icon"
            className="w-4 h-4 mb-[2px] mr-1"
          />
          <div className="flex justify-between text-[#374151]">
            {formattedAssignedDuration ? (
              <p>{formattedAssignedDuration}</p>
            ) : (
              <p>_</p>
            )}
          </div>
          <div className="flex justify-between text-[#6B7280]">
            {formattedActualDuration && (
              <p>
                <span>&nbsp;/&nbsp;</span>
                {formattedActualDuration}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};
