export default function TitleInput({
  isDisabled,
  currentTitle,
  setCurrentTitle,
}: {
  isDisabled?: boolean;
  currentTitle: string;
  setCurrentTitle: (title: string) => void;
}) {
  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="title"
        className="block text-sm font-medium text-gray-700"
      >
        Title
      </label>
      <div className="flex mt-1 rounded-md shadow-sm">
        <input
          disabled={isDisabled}
          type="text"
          name="title"
          id="title"
          autoComplete="title"
          className="flex-1 block w-full min-w-0 border-gray-300 rounded-md disabled:cursor-not-allowed focus:ring-brand focus:border-brand sm:text-sm"
          value={currentTitle}
          onChange={(e) => {
            if (isDisabled) return;
            setCurrentTitle(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
