import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { refreshStripeSubscriptionFromCheckoutSessionId } from "../Api/Payment";
import ReactGA from "react-ga";
import { track, TrackingEventType } from "../Api/Tracking";
import DefaultWebFooter from "../Coach/Components/Footer/DefaultWebFooter";
import { isMobile } from "../Utilities/isMobile";
import CoacheeHeader from "../Coachee/Components/CoacheeHeader";
import { ReactComponent as MessageIcon } from "../Assets/ThankyouPage/messageIcon.svg";
import { ReactComponent as DownloadIcon } from "../Assets/ThankyouPage/downloadIcon.svg";
import { ReactComponent as MailIcon } from "../Assets/ThankyouPage/mailIcon.svg";

function CheckoutSuccess() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const { productName, email, checkoutSessionId } = useParams<{
    productName: string;
    email: string;
    checkoutSessionId?: string;
  }>();

  async function refreshPayment() {
    const response = await refreshStripeSubscriptionFromCheckoutSessionId(
      email.toLowerCase(),
      checkoutSessionId
    );
    track(
      TrackingEventType.TRACKING_APP_GO_TO_CHECKOUT,
      "Checkout Success. Session id: " +
        (checkoutSessionId ?? "Unknown") +
        " Email: " +
        email
    );

    if (response && response.token) {
      let price = 89.0;
      if (productName == "basic") {
        price = 39.0;
      } else if (productName == "plus") {
        price = 149;
      }
      ReactGA.event({
        category: "CheckoutFlow",
        action: "PaymentCompleted",
        value: price,
      });
      setSuccessMessage(
        "🎉 Your payment was received. Check your inbox for your receipt and instructions for account setup (don't forget to check your spam folder)."
      );
    } else {
      setErrorMessage("Something went wrong. Please try again later.");
    }
  }
  useEffect(() => {
    refreshPayment();
  }, []);

  return (
    <div className="min-h-screen flex flex-col justify-between ">
      <CoacheeHeader />

      <div className="flex items-center justify-center">
        {errorMessage || successMessage ? (
          errorMessage ? (
            <div role="alert">
              <div className="px-4 py-2 font-bold text-white bg-red-500 rounded-t">
                Error
              </div>
              <div className="px-4 py-3 text-red-700 bg-red-100 border border-t-0 border-red-400 rounded-b">
                <p>{errorMessage}</p>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-10 justify-between px-2 md:max-w-2xl">
              <div
                className="flex items-center p-6 justify-between gap-5 rounded-2xl flex-col leading-none text-gray-600 bg-gray-100 md:p-8 "
                role="alert"
              >
                <span className="flex px-2 py-1 mr-3 text-xs font-bold text-white uppercase bg-rose-500 rounded-full">
                  Congrats!
                </span>
                <h2 className="flex-auto mr-2 font-semibold text-center">
                  {successMessage}
                </h2>
              </div>
              <section className="">
                <h5 className="text-xl text-center text-gray-800">
                  Next steps:
                </h5>
                <ul className="mt-3 flex flex-col gap-4 text-gray-700 text-md items-center md:items-start md:text-lg ">
                  <li className="flex flex-col items-center md:flex-row gap-4">
                    <MailIcon className="fill-white text-white" />
                    <span>Check your email to set your password</span>
                  </li>
                  <li className="flex flex-col items-center md:flex-row gap-4">
                    <DownloadIcon />
                    <span>Download the Runzy app and login</span>
                  </li>
                  <li className="flex flex-col items-center md:flex-row gap-4 text-center md:text-left">
                    <MessageIcon className="min-w-[32px]" />
                    <span>
                      Send your first message to your coach to let the know you
                      are ready to get started!
                    </span>
                  </li>
                </ul>
              </section>
            </div>
          )
        ) : (
          <div
            className="inline-block w-8 h-8 border-4 rounded-full spinner-border animate-spin text-brand"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </div>
      {!isMobile && <DefaultWebFooter />}
    </div>
  );
}

export default CheckoutSuccess;
