import axios from "axios";
import { toast } from "react-toastify";
import { decodeDateFromSecondsFormat } from "../Utilities/DateUtilities";
import { encodeDistance, encodeDuration } from "../Utilities/FormatUtilties";
import { Step } from "./Step";
import {
  blankTrainingEvent,
  trainingEvent,
  TrainingEventType,
} from "./TrainingEvent";
import { MiniProfile } from "./TrainingProfile";
import { UnitPreference } from "./User";

export interface TrainingEventTemplate {
  id: string;
  name: string;
  createdBy: string;
  createdAt: Date;
  createdByMiniProfile: MiniProfile;
  updatedBy: string;
  updatedByMiniProfile: MiniProfile;
  updatedAt: Date;
  assignedDuration: number;
  assignedDistance: number;
  title: string;
  description: string;
  message: string;
  type: TrainingEventType;
  isPrivate: boolean;
  steps: Step[];
}

export enum TemplateAction {
  createTemplateFromTrainingEvent = "createTemplateFromTrainingEvent",
  createTrainingEventFromTemplate = "createTrainingEventFromTemplate",
}

export const VARIABLE_STRONG_START = "<code[^>]*>";
export const VARIABLE_STRONG_END = "<\\/code>";

// <code style="background-color: rgb(207, 250, 254); color: rgb(8, 145, 178);">Day of the week</code>

export function decodeTemplateMessage(message: string): string {
  const regex = /\{\{(.*?)\}\}/g;

  return message.replace(regex, (match, p1) => {
    return `<code style="background-color: rgb(207, 250, 254); color: rgb(8, 145, 178);">${p1}</code>`;
  });
}

export function encodeTemplateMessage(message: string): string {
  //  convert VARIABLE_STRONG_STARTxVARIABLE_STRONG_END format in message to {{x}} format
  const regex = new RegExp(
    `${VARIABLE_STRONG_START}(.*?)${VARIABLE_STRONG_END}`,
    "g"
  );

  let newMessage = message.replace(regex, (match, p1) => {
    return `{{${p1}}}`;
  });

  // remove all span tags
  newMessage = newMessage.replace(/<span[^>]*>/g, "");
  newMessage = newMessage.replace(/<\/span>/g, "");

  return newMessage;
}

function formatTrainingEventTemplate(data: any): TrainingEventTemplate {
  return {
    id: data.id,
    name: data.name,
    createdBy: data.createdBy,
    createdAt: decodeDateFromSecondsFormat(data.createdAt),
    createdByMiniProfile: data.createdByMiniProfile,
    updatedBy: data.updatedBy,
    updatedByMiniProfile: data.updatedByMiniProfile,
    updatedAt: decodeDateFromSecondsFormat(data.updatedAt),
    assignedDuration: data.assignedDuration,
    assignedDistance: data.assignedDistance,
    title: data.title,
    description: decodeTemplateMessage(data.description),
    message: decodeTemplateMessage(data.message),
    type: data.type,
    isPrivate: data.isPrivate,
    steps: data.steps.map((step: any) => {
      return {
        ...step,
        description: step.description
          ? decodeTemplateMessage(step.description)
          : "",
      };
    }),
  };
}

export async function getTrainingEventTemplate({
  id,
}: {
  id: string;
}): Promise<TrainingEventTemplate | null> {
  try {
    const response = await axios.get(`training-event-templates/${id}`);
    return formatTrainingEventTemplate(response.data);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getMyTrainingEventTemplates(): Promise<
  TrainingEventTemplate[]
> {
  try {
    const response = await axios.get(
      `training-event-templates?filter=own&limit=100`
    );
    return response.data.map((data: any) => formatTrainingEventTemplate(data));
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getAllTrainingEventTemplates(): Promise<
  TrainingEventTemplate[]
> {
  try {
    const response = await axios.get(
      `training-event-templates?filter=all&limit=100`
    );
    return response.data.map((data: any) => formatTrainingEventTemplate(data));
  } catch (e) {
    console.error(e);
    return [];
  }
}

async function postTrainingEventTemplate({
  action,
  trainingEvent,
  unitPreference = UnitPreference.IMPERIAL,
  title,
}: {
  action: TemplateAction;
  trainingEvent: trainingEvent;
  unitPreference: UnitPreference;
  title: string;
}): Promise<TrainingEventTemplate | null> {
  try {
    const modifiedTrainingEvent = {
      ...trainingEvent,
      description: encodeTemplateMessage(trainingEvent.description.join("$")),
      message: encodeTemplateMessage(trainingEvent.message),
      assignedDuration: encodeDuration(
        trainingEvent.assignedDuration ?? "0:00"
      ),
      assignedDistance: encodeDistance(
        trainingEvent.assignedDistance ?? "0",
        unitPreference
      ),
      steps: trainingEvent.steps.map((step) => {
        return {
          ...step,
          description: step.description
            ? encodeTemplateMessage(step.description)
            : undefined,
        };
      }),
    };

    const response = await axios.post(`training-event-templates`, {
      action: action,
      name: title,
      trainingEvent: modifiedTrainingEvent,
    });

    toast("Template successfully created", { type: "success" });

    return formatTrainingEventTemplate(response.data);
  } catch (e) {
    console.error(e);
    toast("Template creation failed", { type: "error" });
    return null;
  }
}

export async function createTemplateFromTrainingEvent(
  trainingEvent: trainingEvent,
  unitPreference: UnitPreference,
  title: string
): Promise<TrainingEventTemplate | null> {
  return postTrainingEventTemplate({
    action: TemplateAction.createTemplateFromTrainingEvent,
    trainingEvent: trainingEvent,
    unitPreference: unitPreference,
    title: title,
  });
}

export async function createBlankTemplate(
  title: string
): Promise<TrainingEventTemplate | null> {
  const result = await postTrainingEventTemplate({
    action: TemplateAction.createTemplateFromTrainingEvent,
    trainingEvent: blankTrainingEvent,
    unitPreference: UnitPreference.IMPERIAL,
    title: title,
  });

  return result;
}

export async function deleteTrainingEventTemplate(
  id: string
): Promise<boolean> {
  try {
    await axios.delete(`training-event-templates/${id}`);
    toast("Template successfully deleted", { type: "success" });
    return true;
  } catch (e) {
    console.error(e);
    toast("Template deletion failed", { type: "error" });
    return false;
  }
}

export interface editTrainingEventTemplateParams {
  id: string;
  name: string;
  assignedDuration: number;
  assignedDistance: number;
  title: string;
  description: string;
  message: string;
  type: TrainingEventType;
  isPrivate: boolean;
  steps: Step[];
}

export async function editTrainingEventTemplate({
  id,
  name,
  assignedDuration,
  assignedDistance,
  title,
  description,
  message,
  type,
  isPrivate,
  steps,
}: editTrainingEventTemplateParams): Promise<TrainingEventTemplate | null> {
  try {
    const response = await axios.put(`training-event-templates/${id}`, {
      name,
      assignedDuration,
      assignedDistance,
      title,
      description,
      message,
      type,
      isPrivate,
      steps,
    });
    toast("Template successfully updated", { type: "success" });
    return formatTrainingEventTemplate({
      ...response.data,
      message: encodeTemplateMessage(message),
    });
  } catch (e) {
    console.error(e);
    toast("Template update failed", { type: "error" });
    return null;
  }
}
