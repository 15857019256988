import axios from "axios";

export enum QuestionType {
  TEXT = "TEXT",
  EMAIL = "EMAIL",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  CALENDAR = "CALENDAR",
  READ_ONLY_INFO = "READ_ONLY_INFO",
}

export interface Question {
  id: string;
  title: string;
  subtitle: string | null;
  image: string | null;
  possibleAnswers: string[] | null;
  questionType: QuestionType;
  textAnswerRegEx: string | null;
  minAnswersRequired: number | null;
  maxAnswersRequired: number | null;
  metaData: string | null;
}

export interface QuestionAnswer {
  questionId: string;
  answer: string[];
  surveySessionId: string;
}

export async function postAnswer(answer: QuestionAnswer): Promise<any | null> {
  try {
    const response = await axios.post("survey-questions", answer);
    return response.data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getSurveyQuestions(): Promise<Question[] | null> {
  try {
    const response = await axios.get("survey-questions");
    return response.data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

