import { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { trainingEvent, TrainingEventType } from "../../Api/TrainingEvent";

interface CompletionRingInput {
  event: trainingEvent;
  inView: boolean;
  className?: string;
}

function CompletionRing({
  event,
  inView,
  className = "w-10 h-10 lg:w-12 lg:h-12",
}: CompletionRingInput) {
  const [ringColor, setRingColor] = useState<string>("#73E8D2");
  const [ringState, setRingState] = useState<number>(0);

  const ringMax = () => {
    if (event.completionRatio && event.completionRatio > 0)
      return event.completionRatio;

    return 0.01;
  };

  useEffect(() => {
    function adjustRingColor() {
      const eventType = event.trainingEventType;

      switch (eventType) {
        case TrainingEventType.BASE:
          setRingColor("#3CC9A1");
          break;
        case TrainingEventType.LONG:
          setRingColor("#E87E73");
          break;
        case TrainingEventType.RECOVERY:
          setRingColor("#9C72E5");
          break;
        case TrainingEventType.REST:
          setRingColor("#72A8E0");
          break;
        case TrainingEventType.SPEED:
          setRingColor("#EF72A1");
          break;
        case TrainingEventType.RACE:
          setRingColor("#343434");
          break;
        case TrainingEventType.CROSS_TRAINING:
          setRingColor("#343434");
          break;
        case TrainingEventType.WALK:
          setRingColor("#3CC9A1");
          break;

        default:
          break;
      }
    }

    adjustRingColor();
  }, [event.trainingEventType]);

  useEffect(() => {
    setTimeout(() => {
      if (inView && ringState < ringMax()) {
        setRingState(ringState + 0.05);
      }
    }, 20);
  }, [ringState]);

  useEffect(() => {
    if (inView && ringState < ringMax()) {
      setRingState(ringState + 0.05);
    }
  }, [inView]);

  // console.log(ringState);
  // console.log(event);

  const resultInPercentage =
    ringState > 1 ? "100%" : `${Math.round(ringState * 100)}%`;
  const calcOffset = (ringState: number) => {
    const xOffset = ringState < 0.1 ? -15 : ringState < 1 ? -25 : -30;
    const yOffset = 10;

    return `translate(${xOffset}px, ${yOffset}px)`;
  };

  // temporary method to hide the circle if training is failed. When right percantage will be send from backend should be removed
  if (ringState === 0.05) {
    return null;
  }
  return (
    <>
      <CircularProgressbar
        className={className}
        value={ringState}
        text={resultInPercentage}
        minValue={0}
        maxValue={1}
        strokeWidth={15}
        styles={{
          path: {
            stroke: ringColor,
          },
          trail: {
            stroke: "rgb(241 245 249)",
          },
          text: {
            fontSize: "27px",
            transform: calcOffset(ringState),
          },
        }}
      />
    </>
  );
}

export default CompletionRing;
