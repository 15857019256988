import { ActivityPoint } from "../../Api/ActivityRoute";
import { UnitPreference } from "../../Api/User";
import {
  convertMinutesFloat,
  formatDataPoints,
} from "../../Utilities/FormatUtilties";

import { useState } from "react";
import { Switch } from "@headlessui/react";

import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";

interface ModalGraphInput {
  metricSetting: UnitPreference;
  activityRoutePoints: ActivityPoint[];
}

function ModalGraph({ metricSetting, activityRoutePoints }: ModalGraphInput) {
  const [paceEnabled, setPaceEnabled] = useState(true);
  const [heartRateEnabled, setHeartRateEnabled] = useState(false);
  const [cadenceEnabled, setCadenceEnabled] = useState(false);

  return (
    <div>
      {formatDataPoints(activityRoutePoints, metricSetting) !== undefined && (
        <>
          <LineChart
            width={900}
            height={250}
            data={formatDataPoints(
              activityRoutePoints,
              metricSetting,
              paceEnabled,
              heartRateEnabled,
              cadenceEnabled
            )}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip
              formatter={(value: any, name: any) => {
                if (name == "pace") {
                  return convertMinutesFloat(value) + " ";
                } else {
                  return value;
                }
              }}
              labelFormatter={() => {
                return "";
              }}
            />
            <Legend />
            {paceEnabled && (
              <Line
                unit={
                  metricSetting === UnitPreference.IMPERIAL
                    ? "min/mi"
                    : "min/km"
                }
                type="monotone"
                dataKey="pace"
                stroke="#F6747E"
              />
            )}
            {heartRateEnabled && (
              <Line
                unit="bpm"
                type="monotone"
                dataKey="heartRate"
                stroke="#E8CE73"
              />
            )}
            {cadenceEnabled && (
              <Line
                unit="spm"
                type="monotone"
                dataKey="cadence"
                stroke="#73E8D2"
              />
            )}
          </LineChart>
          <div className="flex">
            <Switch
              checked={paceEnabled}
              onChange={setPaceEnabled}
              className={`${
                paceEnabled ? "bg-blue-600" : "bg-gray-200"
              } relative inline-flex items-center h-6 rounded-full w-11`}
            >
              <span className="sr-only">Enable pace</span>
              <span
                className={`${
                  paceEnabled ? "translate-x-6" : "translate-x-1"
                } inline-block w-4 h-4 transform bg-white rounded-full`}
              />
            </Switch>
            <p>Pace</p>
            <Switch
              checked={heartRateEnabled}
              onChange={setHeartRateEnabled}
              className={`${
                heartRateEnabled ? "bg-blue-600" : "bg-gray-200"
              } relative inline-flex items-center h-6 rounded-full w-11`}
            >
              <span className="sr-only">Enable Heartrate</span>
              <span
                className={`${
                  heartRateEnabled ? "translate-x-6" : "translate-x-1"
                } inline-block w-4 h-4 transform bg-white rounded-full`}
              />
            </Switch>
            <p>Heartrate</p>
            <Switch
              checked={cadenceEnabled}
              onChange={setCadenceEnabled}
              className={`${
                cadenceEnabled ? "bg-blue-600" : "bg-gray-200"
              } relative inline-flex items-center h-6 rounded-full w-11`}
            >
              <span className="sr-only">Enable Cadence</span>
              <span
                className={`${
                  cadenceEnabled ? "translate-x-6" : "translate-x-1"
                } inline-block w-4 h-4 transform bg-white rounded-full`}
              />
            </Switch>
            <p>Cadence</p>
          </div>
        </>
      )}
    </div>
  );
}

export default ModalGraph;
