import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import React from "react";
import { TrainingAdmin } from "../../Api/TrainingAdmin";
import { trainingProfile } from "../../Api/TrainingProfile";
import { classNames } from "../../Utilities/classnames";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";

type Props = {
  filteredOptions: (trainingProfile | TrainingAdmin)[];
};

export default function Contents({ filteredOptions }: Props) {
  return (
    <>
      {filteredOptions.length > 0 && (
        <Combobox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredOptions.map((person: trainingProfile | TrainingAdmin) => (
            <Combobox.Option
              key={person.miniProfile.id}
              value={person}
              className={({ active }) =>
                classNames(
                  "relative cursor-default select-none py-2 pl-3 pr-9",
                  active ? "bg-brand text-white" : "text-gray-900"
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <div className="flex items-center">
                    <ProfilePicture
                      url={person.miniProfile.profilePicture}
                      classNames="flex-shrink-0 w-6 h-6 rounded-full"
                      alt=""
                    />
                    <span
                      className={classNames(
                        "ml-3 truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {person.miniProfile.name}
                    </span>
                  </div>

                  {selected && (
                    <span
                      className={classNames(
                        "absolute inset-y-0 right-0 flex items-center pr-4",
                        active ? "text-white" : "text-brand"
                      )}
                    >
                      <CheckIcon className="w-5 h-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}
    </>
  );
}
