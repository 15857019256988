import { TrainingEventStatus } from "../../Api/TrainingEvent";
import { titleCase } from "../../Utilities/FormatUtilties";

interface StatusTagInput {
  status: TrainingEventStatus;
  type: string;
}

export function statusTags(currentTag: TrainingEventStatus, type: string) {
  let tags = [
    {
      value: TrainingEventStatus.SCHEDULED,
      component: (
        <StatusTag status={TrainingEventStatus.SCHEDULED} type={type} />
      ),
    },
    {
      value: TrainingEventStatus.COMPLETED,
      component: (
        <StatusTag status={TrainingEventStatus.COMPLETED} type={type} />
      ),
    },
    {
      value: TrainingEventStatus.DRAFT,
      component: <StatusTag status={TrainingEventStatus.DRAFT} type={type} />,
    },
    {
      value: TrainingEventStatus.MISSED,
      component: <StatusTag status={TrainingEventStatus.MISSED} type={type} />,
    },
  ];

  tags = tags.filter((tag) => tag.value !== currentTag);

  return tags;
}

function StatusTag({ status, type }: StatusTagInput) {
  function getBackgroundStyle(): string {
    let backgroundStyle =
      "flex w-fit items-center gap-1 pl-2 pr-1 rounded bg-slate-300/50";

    if (status === TrainingEventStatus.COMPLETED) {
      backgroundStyle =
        "flex w-fit items-center gap-1 pl-2 pr-1 rounded bg-green-300/50";
    } else if (status === TrainingEventStatus.DRAFT) {
      backgroundStyle =
        "flex w-fit items-center gap-1 pl-2 pr-1 rounded bg-neutral-300/50";
    }

    return backgroundStyle;
  }

  function getSquareStyle(): string {
    let squareStyle = "flex w-1.5 h-1.5 bg-slate-500 rounded-sm";

    if (status === TrainingEventStatus.COMPLETED) {
      squareStyle = "flex w-1.5 h-1.5 bg-green-500 rounded-sm";
    } else if (status === TrainingEventStatus.DRAFT) {
      squareStyle = "flex w-1.5 h-1.5 bg-neutral-500 rounded-sm";
    }

    return squareStyle;
  }

  function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt) {
      return titleCase(str);
    });
  }

  return (
    <div className={getBackgroundStyle()}>
      <div className={getSquareStyle()}></div>
      <p className="flex-initial text-sm leading-normal text-gray-500">
        {toTitleCase(TrainingEventStatus[status])}
      </p>
    </div>
  );
}

export default StatusTag;
