import { useEffect, useState } from "react";
import { TrainingEventType } from "../../Api/TrainingEvent";

interface EventTypeIndicatorInput {
  eventType: TrainingEventType;
  onActivityScreen?: boolean;
}

function EventTypeIndicator({
  eventType,
  onActivityScreen,
}: EventTypeIndicatorInput) {
  const [style, setStyle] = useState<string>(
    "rounded-full bg-gradient-to-b from-eventIndicatorBaseFrom to-eventIndicatorBaseTo w-2 h-2"
  );

  useEffect(() => {
    function adjustStyle() {
      if (eventType === TrainingEventType.SPEED) {
        if (onActivityScreen) {
          setStyle(
            "rounded-full bg-gradient-to-b from-eventIndicatorSpeedFrom to-eventIndicatorSpeedTo w-3 h-3"
          );
        } else {
          setStyle(
            "rounded-full bg-gradient-to-b from-eventIndicatorSpeedFrom to-eventIndicatorSpeedTo w-2 h-2"
          );
        }
      } else if (eventType === TrainingEventType.LONG) {
        if (onActivityScreen) {
          setStyle(
            "rounded-full bg-gradient-to-b from-eventIndicatorLongFrom to-eventIndicatorLongTo w-3 h-3"
          );
        } else {
          setStyle(
            "rounded-full bg-gradient-to-b from-eventIndicatorLongFrom to-eventIndicatorLongTo w-2 h-2"
          );
        }
      } else if (eventType === TrainingEventType.REST) {
        if (onActivityScreen) {
          setStyle(
            "rounded-full bg-gradient-to-b from-eventIndicatorRestFrom to-eventIndicatorRestTo w-3 h-3"
          );
        } else {
          setStyle(
            "rounded-full bg-gradient-to-b from-eventIndicatorRestFrom to-eventIndicatorRestTo w-2 h-2"
          );
        }
      } else if (eventType === TrainingEventType.RECOVERY) {
        if (onActivityScreen) {
          setStyle(
            "rounded-full bg-gradient-to-b from-eventIndicatorRecoveryFrom to-eventIndicatorRecoveryTo w-3 h-3"
          );
        } else {
          setStyle(
            "rounded-full bg-gradient-to-b from-eventIndicatorRecoveryFrom to-eventIndicatorRecoveryTo w-2 h-2"
          );
        }
      } else if (
        eventType === TrainingEventType.RACE ||
        eventType === TrainingEventType.CROSS_TRAINING
      ) {
        if (onActivityScreen) {
          setStyle(
            "rounded-full bg-gradient-to-b from-eventIndicatorRaceFrom to-eventIndicatorRaceTo w-3 h-3"
          );
        } else {
          setStyle(
            "rounded-full bg-gradient-to-b from-eventIndicatorRaceFrom to-eventIndicatorRaceTo w-2 h-2"
          );
        }
      } else if (
        [TrainingEventType.BASE, TrainingEventType.WALK].includes(eventType)
      ) {
        if (onActivityScreen) {
          setStyle(
            "rounded-full bg-gradient-to-b from-eventIndicatorBaseFrom to-eventIndicatorBaseTo w-3 h-3"
          );
        } else {
          setStyle(
            "rounded-full bg-gradient-to-b from-eventIndicatorBaseFrom to-eventIndicatorBaseTo w-2 h-2"
          );
        }
      } else if (onActivityScreen) {
        setStyle(
          "rounded-full bg-gradient-to-b from-eventIndicatorBaseFrom to-eventIndicatorBaseTo w-2.5 h-2.5"
        );
      }
    }

    if (eventType) {
      adjustStyle();
    }
  }, [eventType, onActivityScreen]);

  return <div className={style}></div>;
}

export default EventTypeIndicator;
