import { useCallback, useContext, useEffect, useState } from "react";
import CoacheeHeader from "../CoacheeHeader";
import { CheckIcon } from "@heroicons/react/solid";
import { UserInfoContext } from "../../../Context/UserContext";
import { getTrainingAttributes } from "../../../Api/TrainingAttribute";
import { getUserInfo } from "../../../Api/User";
import { useTrainingAttributes } from "../../../Context/TrainingAttributeContext";
import Spinner from "../Spinner";
import { Link } from "react-router-dom";

interface PendingPageStepsInput {
  currentStep: number;
}

interface StepElement {
  name: string;
  description: string;
  status: string;
  hasButton?: boolean;
}

interface Steps {
  elements: StepElement[];
}

const initialValue: Steps = {
  elements: [
    {
      name: "RUNNER QUESTIONNAIRE",
      description: "A few questions to learn about your fitness history",
      status: "current", // upcoming, current, completed
      hasButton: true,
    },
    {
      name: "COACHING SUBSCRIPTION ACTIVATED",
      description: "Your account is set up and we have begun the coach matching process. Don't forget to download the app on your mobile phone and connect it to Strava or Apple Health.",
      status: "upcoming",
    },
    {
      name: "MEET YOUR COACH AND START TRAINING",
      description:
        "Meet your coach and receive your training plan in the Runzy app",
      status: "upcoming",
    },
  ],
};

function PendingStepsPage({ currentStep }: PendingPageStepsInput) {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const { trainingAttributes, setTrainingAttributes } = useTrainingAttributes();
  const [loading, setLoading] = useState(true);

  // if < 3 so redirect to funnel 
  // 1 - create acc
  // 2 - funnel not paid
  // 3 - paid 
  const initTrainingAttributes = useCallback(() => {
    async function initTrainingAttributes() {
      if (userInfo) {
        if (!trainingAttributes) {
          const response = await getTrainingAttributes(userInfo.id);

          if (response) {
            setTrainingAttributes(response);
          }
        }
      } else {
        const refreshedUserInfo = await getUserInfo();
        if (refreshedUserInfo.userInfo) {
          setUserInfo(refreshedUserInfo.userInfo);

          if (!trainingAttributes) {
            const response = await getTrainingAttributes(
              refreshedUserInfo.userInfo.id
            );

            if (response) {
              setTrainingAttributes(response);
            }
          }
        }
      }
    }

    initTrainingAttributes();
  }, [setTrainingAttributes, setUserInfo, trainingAttributes, userInfo]);

  useEffect(() => {
    setLoading(false);
    initTrainingAttributes();
  }, [initTrainingAttributes]);

  const makeSteps = (step: number) => {
    const steps = initialValue.elements;
    if (step < 3) {
      return steps;
    } else {
      steps[0].status = "complete";
      steps[0].hasButton = false;
      steps[1].status = "complete"
      return steps
    }
  }

  const steps = makeSteps(currentStep)

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <CoacheeHeader trainingAttributes={trainingAttributes} />
      <div className="relative mx-8">
        <div id="modalPosition" className="absolute w-1/2 h-1/2"></div>
        <div className="lg:max-w-xl lg:mx-auto">
          <h1 className="mt-16 text-xl font-semibold text-left">Welcome!</h1>
          <h2 className="mt-2 text-sm text-left text-[#767676]">
            We're excited to help you reach your goals.
          </h2>
          <div className="flex flex-col items-start mt-12 lg:max-w-xl lg:mx-auto">
            <nav aria-label="Progress">
              <ol className="overflow-hidden" id="scheduleCallContent">
                {steps.map((step, stepIdx) => (
                  <li key={step.name} className="pb-10 relative">
                    {stepIdx !== steps.length - 1 && (
                      <div
                        className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                        aria-hidden="true"
                      />
                    )}
                    <div className="relative flex items-start group">
                      <span className="flex items-center h-9" aria-hidden="true">
                        {step.status === "complete" && (
                          <span className="relative z-10 flex items-center justify-center w-8 h-8 rounded-full bg-brand group-hover:bg-brand-dark">
                            <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                          </span>
                        )}
                        {step.status === "current" && (
                          <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white border-2 rounded-full border-brand-dark">
                            <span className="h-2.5 w-2.5 bg-brand rounded-full" />
                          </span>
                        )}
                        {step.status === "upcoming" && (
                          <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white border-2 border-gray-300 rounded-full">
                            <span className="h-2.5 w-2.5 bg-transparent rounded-full" />
                          </span>
                        )}
                      </span>
                      <span className="flex flex-col min-w-0 ml-4">
                        <span className={`text-xs font-semibold tracking-wide uppercase ${step.status === "current" ? 'text-brand-dark' : 'text-gray-500'}`}>
                          {step.name}
                        </span>
                        <span className={`text-sm ${step.status === "complete" ? 'text-gray-500' : 'text-gray-500'}`}>
                          {step.description}
                        </span>
                        {step.hasButton && (
                          <a
                            href="https://runzy.com/coaching/funnel/welcome/"
                            className="px-4 py-1 mt-3 text-sm font-medium text-white rounded-md shadow-sm bg-brand  w-max"
                          >
                            Go to Questionnaire →
                          </a>
                        )}
                      </span>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
          {currentStep > 2 && (
            <Link
              to='/dashboard/training-log/'
              className="flex items-center justify-center w-max mx-auto mt-8 px-8 py-4 text-lg font-medium text-white rounded-full cursor-pointer bg-brand">
              Continue
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

export default PendingStepsPage;