import { classNames } from "../../../Utilities/classnames";
import SmartField from "./SmartField";

export default function NoteInput({
  isDisabled,
  currentNote,
  setCurrentNote,
}: {
  isDisabled?: boolean;
  currentNote: string;
  setCurrentNote: (note: string) => void;
}) {
  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="about"
        className="block text-sm font-medium text-gray-700"
      >
        Coach's Note
      </label>
      <div className="mt-1 relative">
        <SmartField
          onChange={setCurrentNote}
          value={currentNote}
          isDisabled={isDisabled}
          className={classNames(
            isDisabled ? "cursor-not-allowed" : "",
            "block w-full border border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm h-24 resize-y"
          )}
        />
      </div>
    </div>
  );
}
