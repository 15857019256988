import axios from "axios";

export async function uploadProfilePicture(file: File) {
  try {
    const formData = new FormData();
    formData.append("image", file);
    const response = await axios.post(`profile-picture`, formData);

    return response.data;
  } catch (e: any) {
    return null;
  }
}
