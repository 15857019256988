import { useEffect } from "react";
import {Route, useHistory} from "react-router-dom";
import {getAuthToken, setAuthToken, updateMobileEmbeddedStatusIfNeeded} from "../../Api/Auth";
import { getTrainingAttributes } from "../../Api/TrainingAttribute";
import { getUserInfo } from "../../Api/User";
import { useTrainingAttributes } from "../../Context/TrainingAttributeContext";
import { useUserInfo } from "../../Context/UserContext";

interface PrivateRouteInput {
  children: JSX.Element;
  [x: string]: any;
}

function PrivateRoute(props: PrivateRouteInput) {
  const { setUserInfo } = useUserInfo();
  const { setTrainingAttributes } = useTrainingAttributes();
  const history = useHistory();
  useEffect(() => {
    async function initUserInfoAndTrainingAttributes() {
      const response = await getUserInfo();

      if (response.userInfo) {
        updateMobileEmbeddedStatusIfNeeded();
        if (getAuthToken() == null) {
          setAuthToken(response.userInfo.token);
        }
        setUserInfo(response.userInfo);

        const attributesResponse = await getTrainingAttributes(
          response.userInfo.miniProfile.id
        );

        if (attributesResponse) {
          setTrainingAttributes(attributesResponse);
        }
      } else {
        history.push("/sign-in", { from: props.location });
      }
    }

    initUserInfoAndTrainingAttributes();
  }, [setUserInfo, setTrainingAttributes]);

  return (
    <>
      <Route {...props}>{props.children}</Route>
    </>
  );
}

export default PrivateRoute;
