import { StreamClient } from "getstream";
import { useEffect, useState } from "react";
import { Comment } from "../../Api/Stream";

interface useActivityFeedbackCommentInput {
  comment: Comment;
  streamClient?: StreamClient;
}

function useActivityFeedbackComment({
  comment,
  streamClient,
}: useActivityFeedbackCommentInput) {
  const [editMode, setEditMode] = useState(false);
  const [commentContent, setCommentContent] = useState(comment.data.text);

  function enableEditMode() {
    setEditMode(true);
  }

  function saveComment() {
    if (streamClient) {
      streamClient.reactions.update(comment.id, {
        ...comment.data,
        text: commentContent,
        isEdited: true,
      });
      setEditMode(false);
    }
  }

  function cancelEdit() {
    setCommentContent(comment.data.text);
    setEditMode(false);
  }

  useEffect(() => {
    setCommentContent(comment.data.text);
  }, [comment]);

  return {
    editMode,
    enableEditMode,
    saveComment,
    cancelEdit,
    commentContent,
    setCommentContent,
  };
}

export default useActivityFeedbackComment;
