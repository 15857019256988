import axios from "axios";
import {
  datesAreOnTheSameDay,
  decodeDateFromSecondsFormat,
  decodeDateFromStringFormat,
  encodeSecondsTimestampFromDate,
  encodeStringFromDate,
  getDayOfTheWeek,
} from "../Utilities/DateUtilities";
import { trainingAttributes } from "./TrainingAttribute";
import {
  decodeDuration,
  encodeDistance,
  encodeDuration,
  formatDistance,
  titleCase,
} from "../Utilities/FormatUtilties";
import { UnitPreference } from "./User";
import { generateActivityMapURL, getActivityRoute } from "./ActivityRoute";
import {
  decodeTemplateMessage,
  encodeTemplateMessage,
  TemplateAction,
} from "./TrainingEventTemplate";
import { Step } from "./Step";

export type trainingEvent = {
  id: string;
  description: string[];
  profile: string;
  scheduledAt: Date;
  trainingEventScheduledAtInMilliseconds?: boolean;
  title: string;
  status: TrainingEventStatus;
  assignedDuration: string;
  assignedDistance: string;
  actualDuration: number;
  actualDistance: number;
  externalUrl?: string;
  message: string;
  sportsType: string;
  trainingEventType: TrainingEventType;
  trainingEventTypeDescription: string;
  isInEditMode?: boolean;
  activity: string | null;
  completionRatio: number | null;
  postActivityFeedback: string | null;
  postActivityFeedbackSentAt: Date | null;
  completedAt: Date | null;
  completionRatioFeedback: string[];
  feedActivityId?: string | null;
  routePreviewImageUrl?: string;
  wasAutoMoved?: boolean;
  wasMovedByAthlete?: boolean;
  perceivedExertion?: number;
  steps: Step[];
};

export enum TrainingEventType {
  BASE = "BASE",
  SPEED = "SPEED",
  LONG = "LONG",
  REST = "REST",
  RECOVERY = "RECOVERY",
  RACE = "RACE",
  CROSS_TRAINING = "CROSS_TRAINING",
  WALK = "WALK",
  UNPLANNED = "UNPLANNED",
}

export enum TrainingEventStatus {
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  COMPLETED = "COMPLETED",
  MISSED = "MISSED",
  UNSCHEDULED = "UNSCHEDULED",
}

export const blankTrainingEvent: trainingEvent = {
  trainingEventType: TrainingEventType.BASE,
  description: [],
  assignedDuration: "0:00",
  assignedDistance: "0",
  activity: null,
  actualDistance: 0,
  actualDuration: 0,
  completedAt: null,
  completionRatio: null,
  completionRatioFeedback: [],
  id: "",
  message: "",
  postActivityFeedback: null,
  postActivityFeedbackSentAt: null,
  profile: "",
  scheduledAt: new Date(),
  sportsType: "",
  status: TrainingEventStatus.DRAFT,
  title: "",
  trainingEventTypeDescription: "",
  externalUrl: undefined,
  feedActivityId: undefined,
  isInEditMode: false,
  routePreviewImageUrl: undefined,
  trainingEventScheduledAtInMilliseconds: undefined,
  wasAutoMoved: false,
  wasMovedByAthlete: false,
  steps: [],
};

export function formatStatus(status: TrainingEventStatus) {
  return titleCase(status.toString());
}

function formatTrainingEvents(
  trainingEvents: trainingEvent[],
  hideActivities?: boolean,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
): trainingEvent[] {
  let updatedTrainingEvents: trainingEvent[] = trainingEvents.map(
    (event: any) => {
      return {
        ...event,
        message: decodeTemplateMessage(event.message),
        description: decodeTemplateMessage(event.description).split("$"),
        scheduledAt: decodeDateFromStringFormat(event.scheduledAt),
        assignedDuration: decodeDuration(event.assignedDuration),
        assignedDistance: formatDistance(
          event.assignedDistance,
          unitPreference
        ),
        postActivityFeedbackSentAt: decodeDateFromSecondsFormat(
          event.postActivityFeedbackSentAt
        ),
        completedAt: decodeDateFromSecondsFormat(event.completedAt),
        steps: event.steps.map((step: any) => {
          return {
            ...step,
            description: step.description
              ? decodeTemplateMessage(step.description)
              : undefined,
            subSteps: step.subSteps?.map((subStep: any) => {
              return {
                ...subStep,
                description: subStep.description
                  ? decodeTemplateMessage(subStep.description)
                  : undefined,
              };
            }),
          };
        }),
      };
    }
  );

  if (hideActivities) {
    updatedTrainingEvents = updatedTrainingEvents.filter(
      (event) => !event.id.includes("activity")
    );
  }

  updatedTrainingEvents = updatedTrainingEvents.sort();

  return updatedTrainingEvents;
}

export async function getTrainingEvents(
  trainingProfileId: string,
  hideActivities?: boolean,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
): Promise<trainingEvent[] | null> {
  try {
    const response = await axios.get(
      `training-events/${trainingProfileId}?offset=0&limit=500`
    ); // TODO: Paginate

    const trainingEvents = response.data.items;

    return formatTrainingEvents(trainingEvents, hideActivities, unitPreference);
  } catch (error: any) {
    return null;
  }
}

export async function getTrainingEventById(
  trainingProfileId: string,
  trainingEventId: string,
  hideActivities?: boolean,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
): Promise<trainingEvent | null> {
  const events = await getTrainingEvents(
    trainingProfileId,
    hideActivities,
    unitPreference
  );

  if (events) {
    const selectedTrainingEvent = events.find((i) => i.id === trainingEventId);

    if (selectedTrainingEvent === undefined) {
      return null;
    } else {
      return selectedTrainingEvent;
    }
  } else {
    return null;
  }
}

export async function getTrainingEventsByDate(
  trainingProfileId: string,
  date: Date
): Promise<trainingEvent[] | null> {
  const formattedDate = date.toISOString().split("T")[0];
  const response = await axios.get(
    `training-events/${trainingProfileId}?filter=day&day=${formattedDate}`
  );

  if (response) return response.data.items as trainingEvent[];

  return null;
}

export type createTrainingEventInput = {
  profile: string;
  status?: TrainingEventStatus;
  title?: string;
  description?: string[];
  message?: string;
  assignedDistance?: string;
  assignedDuration?: string;
  sportsType?: string;
  trainingEventType?: TrainingEventType;
  unitPreference?: UnitPreference;
  scheduledAt?: Date;
  trainingAttributes?: trainingAttributes;
  steps: Step[];
};

export type createTrainingEventOutput = {
  id: string;
  description: string[];
  actualDistance: number | null;
  actualDuration: number | null;
  assignedDistance: string;
  assignedDuration: string;
  profile: string;
  scheduledAt: Date;
  status: TrainingEventStatus;
  title: string;
  message: string;
  sportsType: string;
  trainingEventType: TrainingEventType;
  trainingEventTypeDescription: string;
  steps: Step[];
};

const containsSameDayDate = (datesArray: Date[], dateToFind: Date) => {
  const needle = datesArray.find((date: Date) =>
    datesAreOnTheSameDay(dateToFind, date)
  );
  return needle !== undefined;
};

export async function createTrainingEvent({
  profile,
  status,
  title,
  description,
  message,
  assignedDistance,
  assignedDuration,
  sportsType,
  trainingEventType,
  unitPreference = UnitPreference.IMPERIAL,
  scheduledAt,
  trainingAttributes,
  steps,
}: createTrainingEventInput): Promise<createTrainingEventOutput | null> {
  try {
    let currentDate = new Date();
    let currentTrainingEventType = TrainingEventType.BASE;

    if (trainingEventType) {
      currentTrainingEventType = trainingEventType;
    }
    const trainingEvents = await getTrainingEvents(profile);

    if (trainingEvents && trainingEvents.length > 0) {
      const formattedDates = trainingEvents
        .filter((event) => event.scheduledAt.getTime() > new Date().getTime())
        .map((event) => event.scheduledAt)
        .sort((a, b) => a.getTime() - b.getTime());

      formattedDates.unshift(new Date());

      while (containsSameDayDate(formattedDates, currentDate)) {
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
      }
    }

    if (scheduledAt) currentDate = scheduledAt;

    if (trainingAttributes) {
      const blockedDays = trainingAttributes.blockedDays.map((day) =>
        titleCase(day)
      );

      if (
        blockedDays.includes(getDayOfTheWeek(currentDate)) &&
        currentTrainingEventType === TrainingEventType.BASE
      ) {
        currentTrainingEventType = TrainingEventType.REST;
      }
    }

    // remove empty strings from description array
    if (description) {
      description = description.filter((description) => description !== "");
    }

    currentDate.setHours(0, 0, 0, 0);
    const modifiedScheduledAt = encodeStringFromDate(currentDate);

    const payload = {
      profile: profile,
      scheduledAt: modifiedScheduledAt,
      status: status ?? TrainingEventStatus.DRAFT,
      title: title,
      description: encodeTemplateMessage(description?.join("$") ?? ""),
      message: encodeTemplateMessage(message ?? ""),
      trainingEventType: currentTrainingEventType,
      assignedDuration: encodeDuration(assignedDuration ?? "0:00"),
      assignedDistance: encodeDistance(assignedDistance ?? "0", unitPreference),
      sportsType: sportsType,
      steps: steps.map((step) => {
        return {
          ...step,
          description: step.description
            ? encodeTemplateMessage(step.description)
            : undefined,
        };
      }),
    };

    const response = await axios.post(`training-events/${profile}`, payload);

    const output: createTrainingEventOutput = {
      ...response.data,
      description: decodeTemplateMessage(response.data.description).split("$"),
      message: decodeTemplateMessage(response.data.message),
      scheduledAt: currentDate,
      assignedDistance: formatDistance(
        response.data.assignedDistance,
        unitPreference
      ),
      assignedDuration: decodeDuration(response.data.assignedDuration),
    };

    return output;
  } catch (error: any) {
    return null;
  }
}

export function assignedDurationIsFormattedCorrectly(
  duration: string
): boolean {
  const regex = /^\d*\d:\d{1,2}$|^$|^\d*\d$/gm;

  const found = duration.match(regex);

  return found ? true : false;
}

export function assignedDistanceIsFormattedCorrectly(
  distance: string
): boolean {
  const regex = /^\d+$|^\d+\.\d{1,2}$|^$/gm;

  const found = distance.match(regex);

  return found ? true : false;
}

export type editTrainingEventInput = createTrainingEventInput & {
  eventId: string;
  scheduledAt: Date;
  trainingEventType: TrainingEventType;
  assignedDistance: string;
  assignedDuration: string;
  unitPreference: UnitPreference;
  status: TrainingEventStatus;
  postActivityFeedback: string | null;
  postActivityFeedbackDidChange: boolean;
};

export type editTrainingEventOutput = createTrainingEventOutput & {
  assignedDuration: string;
  assignedDistance: string;
  postActivityFeedback: string | null;
  postActivityFeedbackSentAt: Date | null;
  completedAt: Date | null;
};

export async function editTrainingEvent({
  profile,
  title,
  status,
  description,
  scheduledAt,
  eventId,
  message,
  trainingEventType,
  assignedDistance,
  assignedDuration,
  unitPreference = UnitPreference.IMPERIAL,
  postActivityFeedback = null,
  postActivityFeedbackDidChange = false,
  steps,
}: editTrainingEventInput): Promise<editTrainingEventOutput | null> {
  try {
    const scheduledAtDate = scheduledAt;

    if (!assignedDurationIsFormattedCorrectly(assignedDuration)) return null;

    let modifiedTitle = title;

    Object.values(TrainingEventType).forEach((type) => {
      if (
        modifiedTitle &&
        modifiedTitle.toLowerCase().includes(type.toString().toLowerCase())
      ) {
        const pattern = new RegExp(type.toString(), "ig");

        modifiedTitle = modifiedTitle.replace(
          pattern,
          titleCase(trainingEventType.toString())
        );

        if (
          [
            TrainingEventType.RACE,
            TrainingEventType.CROSS_TRAINING,
            TrainingEventType.WALK,
          ].includes(trainingEventType)
        ) {
          modifiedTitle = modifiedTitle
            .replaceAll(/run/gi, "day")
            .replaceAll("_", " ");
        } else if (trainingEventType === TrainingEventType.REST) {
          modifiedTitle = "Rest Day";
        } else {
          modifiedTitle = modifiedTitle.replaceAll(/day/gi, "run");
        }

        return;
      }
    });

    let modifiedDistance = assignedDistance;
    let modifiedDuration = assignedDuration;

    if (trainingEventType === TrainingEventType.REST) {
      modifiedDistance = "";
      modifiedDuration = "";
    }

    // remove empty strings from description array
    if (description) {
      description = description.filter((description) => description !== "");
    }

    const payload: any = {
      profile: profile,
      scheduledAt: encodeStringFromDate(scheduledAtDate),
      status: status,
      title: modifiedTitle,
      description: encodeTemplateMessage(description?.join("$") ?? ""),
      message: encodeTemplateMessage(message ?? ""),
      trainingEventType: trainingEventType,
      assignedDuration: encodeDuration(modifiedDuration),
      assignedDistance: encodeDistance(modifiedDistance, unitPreference),
      postActivityFeedback: postActivityFeedback,
      steps: steps.map((step) => {
        return {
          ...step,
          description: step.description
            ? encodeTemplateMessage(step.description)
            : undefined,
        };
      }),
    };

    if (postActivityFeedbackDidChange)
      payload.postActivityFeedbackSentAt = encodeSecondsTimestampFromDate(
        new Date()
      );

    const response = await axios.put(
      `training-events/${profile}/${eventId}`,
      payload
    );

    const modifiedTrainingEvent: editTrainingEventOutput = {
      ...response.data,
      // description:  response.data.description.split("$"),
      description: decodeTemplateMessage(response.data.description).split("$"),
      message: decodeTemplateMessage(response.data.message),
      scheduledAt: decodeDateFromStringFormat(response.data.scheduledAt),
      assignedDistance: formatDistance(
        response.data.assignedDistance,
        unitPreference
      ),
      assignedDuration: decodeDuration(response.data.assignedDuration),
      postActivityFeedback: response.data.postActivityFeedback,
      postActivityFeedbackSentAt: decodeDateFromSecondsFormat(
        response.data.postActivityFeedbackSentAt
      ),
      steps: response.data.steps.map((step: any) => {
        return {
          ...step,
          description: step.description
            ? decodeTemplateMessage(step.description)
            : undefined,
        };
      }),
    };

    return modifiedTrainingEvent;
  } catch (error: any) {
    return null;
  }
}

export type deleteTrainingEventInput = {
  profileId: string;
  eventId: string;
};

export async function deleteTrainingEvent({
  profileId,
  eventId,
}: deleteTrainingEventInput): Promise<object | null> {
  try {
    const response = await axios.delete(
      `training-events/${profileId}/${eventId}`
    );

    return response;
  } catch (error: any) {
    return null;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getMapURL(event: trainingEvent) {
  if (!event.activity) return;

  const routeID = event.activity.replace("perform:activity:", "");

  const route = await getActivityRoute(routeID);

  return generateActivityMapURL(route?.points);
}

export async function createTrainingEventFromTemplate(
  templateId: string,
  profileId: string,
  scheduledAt: Date,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
): Promise<createTrainingEventOutput | null> {
  try {
    const response = await axios.post(`training-events/${profileId}`, {
      trainingEventTemplate: templateId,
      scheduledAt: encodeStringFromDate(scheduledAt),
      action: TemplateAction.createTrainingEventFromTemplate,
    });

    const data = response.data;

    return formatTrainingEvents([data], true, unitPreference)[0];
  } catch (error: any) {
    return null;
  }
}
