import { classNames } from "../classnames";

interface TooltipInput {
  display: JSX.Element;
  content?: JSX.Element | string;
  isCentered?: boolean;
}

function Tooltip({ display, content, isCentered = true }: TooltipInput) {
  return (
    <div
      className={classNames(
        isCentered ? "items-center" : "items-start",
        "relative flex flex-col overflow-visible group"
      )}
    >
      {display}
      {content ? (
        <div className="absolute right-0 left-0 bottom-0 flex-col items-center hidden mb-6 overflow-visible group-hover:flex no-wrap">
          <span className="relative z-50 max-w-sm p-2 text-xs leading-none text-white break-words rounded shadow-lg w-max bg-slate-500">
            {content}
          </span>
          <div className="w-3 h-3 -mt-2 rotate-45 bg-slate-500" />
        </div>
      ) : null}
    </div>
  );
}

export default Tooltip;
