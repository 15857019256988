export default function ListViewer({ children }: { children: JSX.Element }) {
  return (
    <>
      {/* <div className="fixed inset-0" /> */}
      {/* TODO: bring back animations */}
      {/* <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              > */}
      <div className="relative flex-1 text-left bg-white h-full overflow-y-scroll">
        {children}
      </div>
      {/* </Transition.Child> */}
    </>
  );
}
