import { IonContent, IonPage } from "@ionic/react";

function AccountAuthenticationFailure() {
  return (
    <IonPage>
      <IonContent>
        <h1 className="mt-12 text-2xl text-center">
          Your account could not be linked. Please try again later.
        </h1>
      </IonContent>
    </IonPage>
  );
}

export default AccountAuthenticationFailure;
