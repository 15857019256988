import { TrainingStats } from "../../../../Api/TrainingProfile";
import { UnitPreference } from "../../../../Api/User";
import Clock from "../../../../Assets/Clock.svg";
import { useUserInfo } from "../../../../Context/UserContext";
import { formatDistance } from "../../../../Utilities/FormatUtilties";

function Distance({ stats }: { stats: TrainingStats }) {
  const { userInfo } = useUserInfo();
  const totalDistance =
    formatDistance(
      stats?.totalDistance ?? 0,
      userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL,
      false,
      false,
      true,
      undefined
    ) !== ""
      ? formatDistance(
          stats?.totalDistance ?? 0,
          userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL,
          false,
          false,
          true,
          undefined
        )
      : "0";

  function totalDistanceLabel() {
    if (userInfo?.profileMetricSetting === UnitPreference.METRIC) return "Km";

    return totalDistance === "1" ? "Mile" : "Miles";
  }

  return (
    <div className="text-center">
      <img src={Clock} alt="clock" />
      <p className="font-semibold text-[#222222] text-xl">{totalDistance}</p>
      <p className="text-[#717171] text-[11px] font-semibold">
        {totalDistanceLabel()}
      </p>
    </div>
  );
}

export default Distance;
