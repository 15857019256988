import { useHistory } from "react-router-dom";
import { TrainingEventTemplate } from "../../Api/TrainingEventTemplate";
import SlideOutContainer from "../../Utilities/Components/SlideOutContainer";
import { CalendarDay } from "../Calendar";

interface SelectedDayInput {
  selectedDay: CalendarDay | undefined;
  setSelectedContent: React.Dispatch<
    React.SetStateAction<CalendarDay | TrainingEventTemplate | undefined>
  >;
  children: JSX.Element;
  selectedDayContentHasChanged: boolean;
  showModal: boolean;
}

function SelectedDay({
  selectedDay,
  setSelectedContent,
  children,
  selectedDayContentHasChanged,
  showModal,
}: SelectedDayInput) {
  const history = useHistory();

  function handleUnselectDay() {
    if (showModal) return;

    if (selectedDayContentHasChanged) {
      const confirmation = window.confirm(
        `Are you sure you want to leave with unsaved changes?`
      );

      if (confirmation) {
        setSelectedContent(undefined);

        if (selectedDay?.event)
          history.push(`/coach/${selectedDay.event.profile}/calendar`);
      }
    } else {
      setSelectedContent(undefined);
      if (selectedDay?.event)
        history.push(`/coach/${selectedDay.event.profile}/calendar`);
    }
  }

  return (
    <>
      <SlideOutContainer
        handleClose={handleUnselectDay}
        isOpen={selectedDay !== undefined && selectedDay.event !== null}
      >
        {children}
      </SlideOutContainer>
    </>
  );
}

export default SelectedDay;
