import { FC } from 'react'

import SortUp from '../../Assets/sortAlphaUp.svg'
import SortDown from '../../Assets/sortAlphaDown.svg'

import { OrderDirection } from '../../Coach/Hooks/useAdminFilter'
import clsx from 'clsx'

interface ColumnSortableProps {
    show: boolean
    dir: OrderDirection,
    onClick: () => void
    className: string
    children: string | JSX.Element | JSX.Element[]
}

export const ColumnSortable: FC<ColumnSortableProps> = ({show, dir, className, onClick, children}) => (
    <th scope="col" className={clsx(className, 'select-none')} onClick={onClick}>
        <div className="flex cursor-pointer gap-2">
            {children}
            {show ? (
                dir === OrderDirection.DESC ?
                    <img src={SortUp} alt="" style={{width: 20, minWidth: 20}}/> :
                    <img src={SortDown} alt="" style={{width: 20, minWidth: 20}}/>
            ) : null}
        </div>
    </th>
)
