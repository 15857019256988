import profilePicture from '../../Assets/placeholder_profile.svg'

type ProfilePictureProps = {
    url?: string | null;
    classNames?: string;
    alt?: string;
}

export default function ProfilePicture({url, classNames, alt}: ProfilePictureProps) {
    return (
        <img
            src={url && url.length ? url : profilePicture}
            className={classNames}
            alt={alt}
            onError={(e) => {
                e.currentTarget.src = profilePicture
            }}
        />
    )
}
