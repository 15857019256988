import axios from "axios";

const authTokenKey = "authToken";
const isImpersonatedKey = "isImpersonated";
const isMobileEmbeddedV2Key = "isMobileEmbeddedV2";
const performAuthTokenHeaderKey = "x-perform-auth-token";
const embedUrlV2Param = "embedV2";

export function initAuth() {
  const authToken = getAuthToken();
  if (authToken) {
    axios.defaults.headers.common[performAuthTokenHeaderKey] = authToken;
  }
}

export function setIsImpersonated(isImpersonated: boolean) {
  if (isImpersonated) {
    localStorage.setItem(isImpersonatedKey, "true");
  } else {
    localStorage.removeItem(isImpersonatedKey);
  }
}

export function isImpersonated(): boolean {
  return localStorage.getItem(isImpersonatedKey) === "true";
}

export function isMobileEmbeddedView(minVersionRequired= "1.0.19"): boolean {
  const value = localStorage.getItem(isMobileEmbeddedV2Key);
  if (!value) {
    return false;
  }

  if (minVersionRequired == "1.0.19" && value == "true") {
    return true;
  }

  return isNewerOrSameVersion(minVersionRequired, value);
}

function isNewerOrSameVersion(oldVer: string, newVer: string) {
  if (oldVer == newVer) {
    return true;
  }
  const oldParts = oldVer.split('.')
  const newParts = newVer.split('.')
  for (let i = 0; i < newParts.length; i++) {
    const a = ~~newParts[i] // parse int
    const b = ~~oldParts[i] // parse int
    if (a > b) return true
    if (a < b) return false
  }
  return false
}

export function iOS() {
  return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function updateMobileEmbeddedStatusIfNeeded() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const paramFieldV2 = params[embedUrlV2Param];
  if (paramFieldV2) {
    if (paramFieldV2 == "true") {
      setIsMobileEmbeddedViewVersion("1.0.19");
    } else if (paramFieldV2 == "false") {
      setIsMobileEmbeddedViewVersion(undefined);
    }
    setIsMobileEmbeddedViewVersion(paramFieldV2);
  }
}

function setIsMobileEmbeddedViewVersion(value?: string) {
  if (value) {
    localStorage.setItem(isMobileEmbeddedV2Key, value);
  } else {
    localStorage.removeItem(isMobileEmbeddedV2Key);
  }
}

export function setAuthToken(authToken: string, isImpersonated = false) {
  if (isImpersonated) {
    setIsImpersonated(isImpersonated);
  }
  localStorage.setItem(authTokenKey, authToken);
  initAuth();
}

export function resetAuth() {
  localStorage.removeItem(authTokenKey);
  setIsImpersonated(false);
  delete axios.defaults.headers.common[performAuthTokenHeaderKey];
}

export function getAuthToken(): string | null {
  return localStorage.getItem(authTokenKey);
}
