import { useContext } from "react";
import { trainingEvent } from "../../../Api/TrainingEvent";
import { UnitPreference } from "../../../Api/User";
import { UserInfoContext } from "../../../Context/UserContext";
import { formatDistance } from "../../../Utilities/FormatUtilties";

export default function ActualDistanceInput({
  event,
}: {
  event: trainingEvent;
}) {
  const { userInfo } = useContext(UserInfoContext);

  const metricSetting =
    userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL;

  let distanceString = "-";
  if (formatDistance(event.actualDistance, metricSetting) !== "") {
    distanceString = formatDistance(event.actualDistance, metricSetting);
  }

  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="assigned-distance"
        className="block text-sm font-medium text-gray-700"
      >
        Actual distance (
        {metricSetting === UnitPreference.IMPERIAL ? "mi" : "km"})
      </label>
      <div className="mt-1">
        <p className="block w-full pt-2 border-gray-300 rounded-md focus:ring-brand focus:border-brand sm:text-sm">
          {distanceString}
        </p>
      </div>
    </div>
  );
}
