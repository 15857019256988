import { IonContent, IonPage } from "@ionic/react";
import Spinner from "../Coachee/Components/Spinner";
import {useEffect} from "react";

function RedirectUrl() {

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params["uuid"] && params["redir"]) {
      window.location.href = process.env.REACT_APP_BASE_URL_API + "/sign-in?uuid=" +
          encodeURIComponent(params["uuid"]) + "&redir=" + encodeURIComponent(params["redir"]);
    } else if (params["redir"]) {
      window.location.href = params["redir"];
    }
  }, []);


  return (
    <IonPage>
      <IonContent>
        <Spinner/>
      </IonContent>
    </IonPage>
  );
}

export default RedirectUrl;
