import { useEffect, useState } from "react";
import CoacheeHeader from "../Components/CoacheeHeader";
import { IonContent, IonModal, IonPage, IonRouterLink } from "@ionic/react";
import MobilePageHeader from "../Components/MobilePageHeader";
import { isMobile } from "../../Utilities/isMobile";
import { useTrainingAttributes } from "../../Context/TrainingAttributeContext";
import { useUserInfo } from "../../Context/UserContext";
import { getTrainingEvents, trainingEvent } from "../../Api/TrainingEvent";
import UpcomingContent from "./UpcomingContent";
import ModalContent from "./Modal/ModalContent";
import Back from "../../Assets/Back.svg";
import DefaultWebFooter from "../../Coach/Components/Footer/DefaultWebFooter";

function CoacheeUpcoming() {
  const { userInfo } = useUserInfo();
  const { trainingAttributes } = useTrainingAttributes();
  const [events, setEvents] = useState<trainingEvent[]>();
  const [showModal, setShowModal] = useState(false);

  const [hasScrolled, setHasScrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function initEvents() {
    if (!userInfo) return;
    setIsLoading(true);

    const response = await getTrainingEvents(
      userInfo.miniProfile.id,
      true,
      userInfo.profileMetricSetting
    );

    if (response) {
      setEvents(response);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    initEvents();
  }, [userInfo]);

  return (
    <IonPage>
      {isMobile ? (
        <MobilePageHeader title="Schedule" hasScrolled={hasScrolled} />
      ) : (
        <CoacheeHeader trainingAttributes={trainingAttributes} />
      )}{" "}
      <IonContent
        style={{ "--ion-background-color": "#F3F4F6" }}
        scrollEvents={true}
        onIonScroll={(e) => setHasScrolled(e.detail.scrollTop !== 0)}
      >
        <div className="mb-4 lg:max-w-xl lg:mx-auto lg:mt-20">
          <IonRouterLink
            routerLink="/dashboard/training-log"
            routerDirection="back"
          >
            <button className="items-center self-start hidden mb-12 ml-6 space-x-1 text-xs text-gray-400 rounded md:flex hover:text-gray-500">
              <img src={Back} alt="Back" className="w-auto h-4" />
              <p>Back</p>
            </button>
          </IonRouterLink>
          <UpcomingContent
            events={events}
            setShowModal={setShowModal}
            isLoading={isLoading}
          />
        </div>

        <IonModal
          isOpen={showModal}
          initialBreakpoint={0.9}
          breakpoints={[0.9, 1]}
          onDidDismiss={() => setShowModal(false)}
          swipeToClose={false}
        >
          <ModalContent
            events={events}
            setShowModal={setShowModal}
            initEvents={initEvents}
          />
        </IonModal>
        {!isMobile && <DefaultWebFooter />}
      </IonContent>
    </IonPage>
  );
}

export default CoacheeUpcoming;
