import WarningModal from "../../../../Utilities/Components/WarningModal";

interface DeleteActivityModalInput {
  showDeleteModal: boolean;
  deactivateActivityModal: () => void;
  handleDeleteActivity: () => Promise<void>;
}

function DeleteActivityModal({
  showDeleteModal,
  deactivateActivityModal,
  handleDeleteActivity,
}: DeleteActivityModalInput) {
  return (
    <WarningModal
      action={handleDeleteActivity}
      buttonLabel="Delete"
      deactivateModal={deactivateActivityModal}
      showModal={showDeleteModal}
      subtitle="This action cannot be undone and it will be removed from your activity history."
      title="Delete activity?"
    />
  );
}

export default DeleteActivityModal;
