import { useIonRouter } from "@ionic/react";
import {
  ActivityMetadata,
  ActivitySource,
} from "../../../../Api/ActivityRoute";
import ActivityDropdown from "../../../../Coach/Components/ActivityDropdown";
import { classNames } from "../../../../Utilities/classnames";
import StatsRow from "../Stats/StatsRow";
import SyncedFromStrava from "./SyncedFromStrava";

function OtherActivity({
  activity,
  index,
  setActivityToBeDeleted,
}: {
  activity: ActivityMetadata;
  index: number;
  setActivityToBeDeleted: React.Dispatch<
    React.SetStateAction<ActivityMetadata | undefined>
  >;
}) {
  const routerHook = useIonRouter();

  const willRenderStatsRow =
    activity.distanceInMeters !== null && activity.movingTime !== 0;

  return (
    <div className="rounded-[10px] border border-neutral-200 mt-4">
      {/* <IonRouterLink routerLink={`/activities/${activity.id}`}> */}
      <button
        className="w-full"
        onClick={() => routerHook.push(`/activities/${activity.id}`)}
      >
        <div className="py-[20px]">
          <div
            className={classNames(
              willRenderStatsRow ? "pb-3 border-b border-neutral-200" : "",
              "px-[16px]"
            )}
          >
            <div className="flex justify-between w-full text-left">
              <div>
                <h1 className="font-medium text-[16px] text-black hover:underline">
                  Activity #{index + 1}
                </h1>

                <p className="text-neutral-600 text-[14px] mt-1">
                  {activity.userTitle}
                </p>
              </div>
              {activity.activitySource === ActivitySource.STRAVA ? (
                <SyncedFromStrava />
              ) : (
                <ActivityDropdown
                  actions={[
                    {
                      key: "Delete",
                      action: () => setActivityToBeDeleted(activity),
                    },
                  ]}
                />
              )}
            </div>
          </div>
          <StatsRow
            distance={activity.distanceInMeters ?? undefined}
            movingTime={activity.movingTime}
          />
        </div>{" "}
      </button>
      {/* </IonRouterLink> */}
    </div>
  );
}

export default OtherActivity;
