const Default = {
  apiBaseURL: process.env.REACT_APP_BASE_URL_API,
  axiosWithCredentials: true,
  axiosHeaders: { "x-platform-version": "web" },
  mapboxToken:
    "pk.eyJ1IjoiZGVzY290byIsImEiOiJjbDBiZ3g3Y28wbWl0M2RydDNkYnYwanpwIn0.-31HExMLeKwsFpAhTGMfTQ",
  streamsApiKey: "xqpdvdur4b7w",
  streamsAppId: "1247920",
};

export default Default;
