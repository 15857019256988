interface useInstructionsInput {
  currentDescription: string[];
  setCurrentDescription: (value: string[]) => void;
}

function useInstructions({
  currentDescription,
  setCurrentDescription,
}: useInstructionsInput) {
  const removeUndefined = (arr: any[]) => {
    return arr.filter((item) => item !== undefined);
  };

  function updateInstruction(index: number, newValue: string) {
    let updatedInstructions = currentDescription;

    updatedInstructions = updatedInstructions.map((instruction, i) =>
      i === index ? newValue.replace(/(\r\n|\n|\r)/gm, "") : instruction
    );

    setCurrentDescription(removeUndefined(updatedInstructions));
  }

  function addInstruction() {
    setCurrentDescription(currentDescription.concat([""]));
  }

  function deleteInstruction(index: number) {
    const array = currentDescription;
    delete array[index];
    setCurrentDescription(array);
  }

  function moveInstruction(instructionIndex: number, destinationIndex: number) {
    const array = currentDescription;
    const instruction = array[instructionIndex];
    delete array[instructionIndex];
    array.splice(destinationIndex, 0, instruction);
    const updatedArray = array.filter((item) => item !== "");

    setCurrentDescription(removeUndefined(updatedArray));
  }

  function handleEnterPress(i: number) {
    const newInstructions = currentDescription;
    newInstructions.splice(i + 1, 0, "");
    setCurrentDescription(removeUndefined(newInstructions));
  }

  return {
    updateInstruction,
    addInstruction,
    deleteInstruction,
    handleEnterPress,
    moveInstruction,
  };
}

export default useInstructions;
