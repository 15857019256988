import WarningModal from "../../../Utilities/Components/WarningModal";

interface DeleteCommentModalInput {
  showDeleteModal: boolean;
  deactivateCommentModal: () => void;
  handleDeleteComment: () => Promise<void>;
}

function DeleteCommentModal({
  showDeleteModal,
  deactivateCommentModal,
  handleDeleteComment,
}: DeleteCommentModalInput) {
  return (
    <WarningModal
      action={handleDeleteComment}
      buttonLabel="Delete"
      deactivateModal={deactivateCommentModal}
      showModal={showDeleteModal}
      subtitle="This action cannot be undone and it will be removed from the comment thread."
      title="Delete comment?"
    />
  );
}

export default DeleteCommentModal;
