import {
  IonContent,
  IonModal,
  IonPage,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getTrainingAttributes } from "../../../Api/TrainingAttribute";
import { getUserInfo } from "../../../Api/User";
import { useTrainingAttributes } from "../../../Context/TrainingAttributeContext";
import { useUserInfo } from "../../../Context/UserContext";
import { classNames } from "../../../Utilities/classnames";
import { sortDaysOfTheWeek } from "../../../Utilities/DateUtilities";
import { isMobile } from "../../../Utilities/isMobile";
import CoacheeHeader from "../../Components/CoacheeHeader";
import AccountConnections from "./AccountConnections";
import Email from "./Email";
import Management from "./Management";
import MobileHeader from "./MobileHeader";
import Name from "./Name";
import ProfilePicture from "./ProfilePicture";
import Unit from "./Unit";
import UnitModalContents from "./UnitModalContents";
import useNameSettings from "./useNameSettings";
import useUnitPreference from "./useUnitPreference";
import useWorkoutDays from "./useWorkoutDays";
import WorkoutDays from "./WorkoutDays";
import { isMobileEmbeddedView } from "../../../Api/Auth";
import DefaultWebFooter from "../../../Coach/Components/Footer/DefaultWebFooter";
import { useLocalStorage } from "usehooks-ts";

function ProfileSettings() {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isForcedDisabled, setIsForcedDisabled] = useState(false);
  const { userInfo, setUserInfo } = useUserInfo();
  const { trainingAttributes, setTrainingAttributes } = useTrainingAttributes();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [present, dismiss] = useIonToast();
  const nameSettingsHook = useNameSettings();
  const unitPreferenceHook = useUnitPreference();
  const workoutDaysHook = useWorkoutDays();
  const routerHook = useIonRouter();

  const [isAppMobileFooter, setIsAppMobileFooter] = useLocalStorage(
    "isMobileFooter",
    false
  );

  useEffect(() => {
    if (
      routerHook.routeInfo.search.includes("app=mobile") ||
      routerHook.routeInfo.search.includes(encodeURIComponent("app=mobile"))
    ) {
      setIsAppMobileFooter(true);
    }
  }, []);

  function isSaveDisabled() {
    if (
      !userInfo ||
      !trainingAttributes ||
      !workoutDaysHook.blockedDays ||
      isForcedDisabled
    )
      return true;

    const nameHasChanged =
      `${userInfo.miniProfile.name}` !== nameSettingsHook.currentName;
    const unitsHasChanged =
      userInfo.profileMetricSetting !== unitPreferenceHook.unitPreference;
    const workoutDaysHasChanged =
      JSON.stringify(sortDaysOfTheWeek(workoutDaysHook.blockedDays)) !==
      JSON.stringify(sortDaysOfTheWeek(trainingAttributes.blockedDays));

    return !nameHasChanged && !unitsHasChanged && !workoutDaysHasChanged;
  }

  async function handleSubmit() {
    if (!trainingAttributes || !userInfo) return;

    setIsForcedDisabled(true);

    const unitPreferenceResponse =
      await unitPreferenceHook.handleUnitPreferenceChange();
    const nameResponse = await nameSettingsHook.handleNameChange();
    const workoutDaysResponse = await workoutDaysHook.submitWorkoutDays();

    if (unitPreferenceResponse && nameResponse && workoutDaysResponse) {
      const response = await getUserInfo();

      if (response.userInfo) {
        setUserInfo(response.userInfo);

        const attributesResponse = await getTrainingAttributes(
          response.userInfo.miniProfile.id
        );

        if (attributesResponse) {
          setTrainingAttributes(attributesResponse);
        }
      }

      if (!isMobile) {
        toast("Settings have been saved successfully.", {
          position: "bottom-right",
          style: { backgroundColor: "black", color: "white" },
          className: "font-poppins",
        });
      } else {
        present({
          message: "Settings have been saved successfully.",
          duration: 3000,
          position: "top",
          color: "dark",
          cssClass: "toastPosition",
        });
      }
    }

    setIsForcedDisabled(false);
  }

  if (!trainingAttributes) return <></>;

  return (
    <IonPage>
      {isMobile ? (
        <MobileHeader
          saveDisabled={isSaveDisabled}
          hasScrolled={hasScrolled}
          handleSubmit={handleSubmit}
        />
      ) : (
        <CoacheeHeader />
      )}
      <IonContent
        scrollEvents={true}
        onIonScroll={(e) => setHasScrolled(e.detail.scrollTop !== 0)}
      >
        <div className="max-w-xl sm:h-fit mx-auto mt-4 lg:mt-20 sm:px-6 lg:px-8">
          {!isMobile && !isMobileEmbeddedView() && (
            <div className="flex items-center justify-between mx-4 mb-8 px-4">
              <div className="flex items-center gap-2">
                <h1 className="text-[28px] font-semibold leading-tight">
                  Settings
                </h1>
                {isForcedDisabled && (
                  <div
                    className="inline-block w-4 h-4 rounded-full text-brand spinner-border animate-spin border-1"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>

              <div className="flex items-center gap-2">
                {!routerHook.routeInfo.search.includes("app=mobile") &&
                !routerHook.routeInfo.search.includes(
                  encodeURIComponent("app=mobile")
                ) &&
                !isAppMobileFooter ? (
                  <button
                    onClick={() => routerHook.push("/profile")}
                    type="button"
                    className={classNames(
                      "cursor-pointer justify-center px-4 py-1.5  text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    )}
                  >
                    Back
                  </button>
                ) : null}
                <button
                  disabled={isSaveDisabled()}
                  type="button"
                  onClick={() => handleSubmit()}
                  className={classNames(
                    "inline-flex disabled:cursor-default disabled:bg-gray-300 cursor-pointer justify-center px-4 py-1.5 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700"
                  )}
                >
                  Save
                </button>
              </div>
            </div>
          )}
          <ProfilePicture />

          <Name nameInterface={nameSettingsHook} />
          <Email email={trainingAttributes.emailAddress} />
          <Unit unitPreferenceHook={unitPreferenceHook} />

          {(isMobile || isMobileEmbeddedView()) && (
            <div className="flex items-center justify-center m-4">
              <button
                disabled={isSaveDisabled()}
                type="button"
                onClick={() => handleSubmit()}
                className={classNames(
                  "w-full py-[10px] rounded-[200px] text-white text-[16px] font-medium bg-primary-500 disabled:bg-neutral-100 disabled:text-neutral-500 disabled:cursor-not-allowed sm:mt-4"
                )}
              >
                Save
              </button>
            </div>
          )}

          {/* <Upgrade attributes={trainingAttributes} /> */}
          <WorkoutDays />

          <AccountConnections accounts={trainingAttributes.accounts} />
          <Management />

          {/*<AdminCard />*/}
        </div>
        {isMobile && (
          <IonModal
            isOpen={unitPreferenceHook.displayModal}
            initialBreakpoint={0.25}
            breakpoints={[0, 0.25]}
            onDidDismiss={() => unitPreferenceHook.setDisplayModal(false)}
          >
            <UnitModalContents unitPreferenceHook={unitPreferenceHook} />
          </IonModal>
        )}
        {!isMobile && <DefaultWebFooter />}
      </IonContent>
    </IonPage>
  );
}

export default ProfileSettings;
