import {
  IonReorderGroup,
  IonReorder,
  ItemReorderEventDetail,
} from "@ionic/react";
import { weekEvents } from "./ModalContent";
import SwappableCard from "./SwappableCard";

interface SwapperInput {
  initialEventsByDayOfWeek: weekEvents;
  eventsByDayOfWeek: weekEvents;
  setEventsByDayOfWeek: React.Dispatch<React.SetStateAction<weekEvents>>;
}

function Swapper({
  initialEventsByDayOfWeek,
  eventsByDayOfWeek,
  setEventsByDayOfWeek,
}: SwapperInput) {
  function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively

    const newEvents = eventsByDayOfWeek ? [...eventsByDayOfWeek] : undefined;
    const element = newEvents?.splice(event.detail.from, 1)[0];
    newEvents?.splice(event.detail.to, 0, element);

    setEventsByDayOfWeek(newEvents);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete();
  }

  if (!initialEventsByDayOfWeek) return <></>;

  return (
    <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
      {initialEventsByDayOfWeek.map((day, index) => (
        <IonReorder className="mb-2" key={index}>
          <SwappableCard event={day} />
        </IonReorder>
      ))}
    </IonReorderGroup>
  );
}

export default Swapper;
