import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IonContent, IonPage } from '@ionic/react'
import { validateEmail } from '../Utilities/Validation'
import { track, TrackingEventType } from '../Api/Tracking'
import { getPaymentUrlForProduct, ProductType } from '../Api/Payment'
import RunzyLogo from '../Assets/RunzyLogo.svg'

function AlreadyHasSubscription() {
    return <div className="px-3 md:px-0">
        <h5 className="text-lg font-semibold text-fonts">You already have an active subscription, so no need to pay
            again :)</h5>
        <h6 className="font-semibold mt-3">To proceed:</h6>
        <ul className="list-disc list-inside my-3">
            <li>Download the Runzy app <a className="underline"
                                          href="https://apps.apple.com/us/app/runzy/id6448893640">iOS</a> or <a
                className="underline"
                href="https://play.google.com/store/apps/details?id=com.runzy.runzycoaching&pli=1">Android</a></li>
            <li>If you haven’t set up your password, <a className="underline"
                                                        href="https://coaching.runzy.com/forgot-password">get your
                password here</a></li>
            <li>Log in and connect with your coach.</li>
        </ul>
        <p>If you have any questions, please contact our support team at <a className="underline"
                                                                            href={'mailto:help@runzy.com?subject=' + encodeURIComponent('Help getting started with runzy')}>help@runzy.com</a>
        </p>
    </div>
}

function Checkout() {
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isErrorHasSubscription, setIsErrorHasSubscription] = useState<boolean>(false)
    const {productName, email, referral} = useParams<{
        productName: string;
        email: string;
        referral?: string;
    }>()

    async function redirectToPaymentLink() {
        setIsLoading(true)
        setErrorMessage(null)
        setIsErrorHasSubscription(false)

        const isValidEmail = validateEmail(email)
        const cleanEmail = isValidEmail ? email.toLowerCase().trim() : 'Invalid email: ' + email

        track(
            TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
            cleanEmail + ' landed on checkout page for product: ' + productName,
            'profiles'
        )

        const productType = productName
            ? ProductType[productName.toUpperCase() as keyof typeof ProductType]
            : ProductType.BASIC

        try {
            const response = await getPaymentUrlForProduct(productType, cleanEmail, referral)
            if (response && response.token) {
                window.location.href = response.token
            }
        } catch (response: any) {
            if (response && response.code === 'email_already_has_subscription') {
                setIsErrorHasSubscription(true)
            } else {
                track(
                    TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
                    email +
                    ' failed to redirect to Stripe. 🚨🚨🚨',
                    'profiles'
                )
                setErrorMessage('Whoops. Something went wrong.')
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        redirectToPaymentLink()
    }, [])

    return (
        <IonPage>
            <IonContent>
                <div className="flex h-screen flex-col min-h-full py-12 sm:px-6 lg:px-8 bg-[#F9FAFB]">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md flex items-center justify-center">
                        <a href="/"><img
                            className="w-auto h-8 mx-auto mr-3"
                            src={RunzyLogo}
                            alt="Runzy"
                        /></a>
                        <h1 className="text-2xl font-semibold">
                            Payment Page
                        </h1>
                    </div>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
                        <div className="flex items-center justify-center">
                            {
                                errorMessage && !isLoading ? (
                                    <div role="alert">
                                        <div className="px-4 py-2 font-bold text-white bg-red-500 rounded-t">
                                            Error
                                        </div>
                                        <div
                                            className="px-4 py-3 text-red-700 bg-red-100 border border-t-0 border-red-400 rounded-b">
                                            <p>{errorMessage}</p>
                                        </div>
                                    </div>
                                ) : <></>
                            }

                            {
                                isErrorHasSubscription && !isLoading ? (
                                    <AlreadyHasSubscription/>
                                ) : <></>
                            }

                            {
                                isLoading ? (
                                    <div
                                        className="inline-block w-8 h-8 border-4 rounded-full spinner-border animate-spin text-brand"
                                        role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : <></>
                            }
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Checkout
