import "./App.css";
import { Redirect, Route, useLocation } from "react-router-dom";

import CoachDashboard from "./Pages/CoachDashboard";
import SignIn from "./Pages/SignIn";
import Onboard from "./Pages/Onboard";

import ReactGA from "react-ga";
import { useEffect, useRef } from "react";
import Pending from "./Pages/Pending";
import OnboardSurvey from "./Pages/OnboardSurvey";
import Admin from "./Coach/Tabs/Admin";
import { ToastContainer } from "react-toastify";
import ResetPassword from "./Pages/ResetPassword";
import Notifications from "./Pages/Notifications";
import CoacheeActivityContainer from "./Pages/CoacheeActivityContainer";
import Checkout from "./Pages/Checkout";
import CheckoutSuccess from "./Pages/CheckoutSuccess";
import CheckoutFailure from "./Pages/CheckoutFailure";
import PrivateRoute from "./Utilities/Components/PrivateRoute";
import AppUrlListener from "./Mobile/AppUrlListener";
import PushNotificationHandler, {
  requestNotificationPermissions,
} from "./Mobile/PushNotificationHandler";
import AccountAuthenticationSuccess from "./Pages/AccountAuthenticationSuccess";
import { initIonicConfig } from "./Utilities/IonConfig";
import CoacheeUpcoming from "./Coachee/Schedule/CoacheeUpcoming";
import CoacheeDashboard from "./Pages/CoacheeDashboard";
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
} from "@ionic/react";
import { classNames } from "./Utilities/classnames";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import Home from "./Assets/Home.svg";
import SelectedHome from "./Assets/SelectedHome.svg";
import Person from "./Assets/Person.svg";
import SelectedPerson from "./Assets/SelectedPerson.svg";
import { isMobile } from "./Utilities/isMobile";
import ScheduleCallStandalone from "./Pages/ScheduleCallStandalone";
import AppDownload from "./Pages/AppDownload";
import Referrals from "./Pages/Referrals";
import useCapacitorStatusBar from "./Utilities/Hooks/useCapacitorStatusBar";
import { useTrainingAttributes } from "./Context/TrainingAttributeContext";
import Profile from "./Coachee/Profile/Profile";
import ProfileSettings from "./Coachee/Profile/ProfileSettings/ProfileSettings";
import Upgrade from "./Pages/Coachee/Upgrade/Upgrade";
import AccountAuthenticationFailure from "./Pages/AccountAuthenticationFailure";
import OtherActivityPage from "./Coachee/Activity/Results/OtherActivities/OtherActivitiesPage/OtherActivityPage";
import CreatePage from "./Coachee/Activity/Create/CreatePage";
import SignOutRedirect from "./Pages/SignOutRedirect";
import RedirectUrl from "./Pages/Redirect";
import PostOnboardSurvey from "./Pages/PostOnboardSurvey";
import Availability from "./Coachee/Profile/Availability/Availability";
import ConnectStrava from "./Coachee/Profile/Strava/ConnectStrava";
import ManageStrava from "./Coachee/Profile/Strava/ManageStrava";
import ConnectAppleHealth from "./Coachee/Profile/AppleHealth/ConnectAppleHealth";
import NotificationsPreference from "./Coachee/Profile/NotificationsPreference";
import Membership from "./Coachee/Profile/Membership/Membership";
import ConnectTerra from "./Coachee/Profile/Terra/ConnectTerra";
import ManageTerra from "./Coachee/Profile/Terra/ManageTerra";
import MusicStravaSettings from "./Pages/Music/MusicStravaSettings";
import MusicAccounts from "./Pages/Music/MusicAccounts";
import MusicSpotifySettings from "./Pages/Music/MusicSpotifySettings";
import ForgotPassword from "./Pages/ForgotPassword";
import SurveyReportPage from './Pages/Admin/Report/SurveyReportPage'
import CreateUserPage from "./Pages/Admin/CreateUserPage/CreateUserPage";


if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-133462202-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function App() {
  initIonicConfig();
  const { setStatusBarStyleDark } = useCapacitorStatusBar();

  if (isPlatform("android")) {
    setStatusBarStyleDark();
  }

  const location = useLocation();

  const { trainingAttributes } = useTrainingAttributes();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // track(
    //   TrackingEventType.TRACKING_APP_WEB_ATHLETE_DASHBOARD,
    //   "PageView: " + document.location
    // );
    requestNotificationPermissions();
  }, [location]);

  const routerRef = useRef<HTMLIonRouterOutletElement | null>(null);

  return (
    <>
      <PushNotificationHandler />
      <AppUrlListener />
      <IonTabs>
        <IonTabBar
          slot="bottom"
          className={classNames(
            isMobile &&
              !location.pathname.includes("coach") &&
              !location.pathname.includes("sign-in") &&
              !location.pathname.includes("onboard") &&
              !location.pathname.includes("pending") &&
              !location.pathname.includes("upgrade") &&
              trainingAttributes &&
              trainingAttributes.onboardingProgress >= 4
              ? ""
              : "hidden",
            "bg-white border-t"
          )}
        >
          <IonTabButton
            tab="dashboard"
            href={
              trainingAttributes && trainingAttributes.onboardingProgress < 4
                ? "/pending"
                : "/dashboard/training-log"
            }
            className="bg-white"
          >
            {location.pathname.includes("training-log") ||
              location.pathname.includes("pending") ? (
              <img src={SelectedHome} alt="Selected Home Icon" />
            ) : (
              <img src={Home} alt="Home Icon" />
            )}
          </IonTabButton>

          <IonTabButton tab="profile" href="/profile" className="bg-white">
            {location.pathname.includes("profile") ? (
              <img src={SelectedPerson} alt="Selected Person Icon" />
            ) : (
              <img src={Person} alt="Person Icon" />
            )}{" "}
          </IonTabButton>
        </IonTabBar>

        <IonRouterOutlet animated={isMobile} ref={routerRef}>
          <PrivateRoute strict path="/admin">
            <Admin />
          </PrivateRoute>
          <PrivateRoute strict path="/admin/report/survey">
            <SurveyReportPage />
          </PrivateRoute>
          <PrivateRoute strict path="/admin/create-user">
            <CreateUserPage />
          </PrivateRoute>
          <PrivateRoute exact path="/stats">
            <Redirect to="/settings" />
          </PrivateRoute>
          <PrivateRoute exact path="/home">
            <Redirect to="/dashboard/training-log" />
          </PrivateRoute>

          {/* Need to rework bg and fix width */}
          <PrivateRoute exact path="/upgrade">
            <Upgrade />
          </PrivateRoute>

          <PrivateRoute path="/coach">
            <CoachDashboard />
          </PrivateRoute>

          <PrivateRoute exact path="/dashboard/upcoming">
            <CoacheeUpcoming />
          </PrivateRoute>

          <PrivateRoute strict path="/dashboard/training-log/:activityId?">
            <CoacheeDashboard router={routerRef.current} />
          </PrivateRoute>

          <Route
            exact
            path="/dashboard"
            render={() => <Redirect to="/dashboard/training-log" />}
          />

          <PrivateRoute exact path="/notifications">
            <Notifications />
          </PrivateRoute>
          <PrivateRoute exact path="/referrals">
            <Referrals />
          </PrivateRoute>
          <PrivateRoute path="/activity/:activityId">
            <>
              <CoacheeActivityContainer />
            </>
          </PrivateRoute>

          <PrivateRoute exact path="/activities/:activityId">
            <>
              <OtherActivityPage />
            </>
          </PrivateRoute>

          <PrivateRoute path="/activities/create/:defaultDate?">
            <>
              <CreatePage />
            </>
          </PrivateRoute>

          <Route path="/onboard">
            <Onboard />
          </Route>
          <Route path="/pending">
            <Pending />
          </Route>

          <Route path="/sign-in">
            <SignIn isSignUp={false} />
          </Route>
          <Route path="/onboard-survey">
            <OnboardSurvey />
          </Route>
          <Route path="/account-auth-success">
            <>
              <AccountAuthenticationSuccess />
            </>
          </Route>
          <Route path="/redirect-url">
            <>
              <RedirectUrl />
            </>
          </Route>
          <Route path="/sign-out-redirect">
            <>
              <SignOutRedirect />
            </>
          </Route>
          <Route path="/account-auth-failure">
            <>
              <AccountAuthenticationFailure />
            </>
          </Route>
          <Route path="/reset-password/:token">
            <>
              <ResetPassword />
            </>
          </Route>
          <Route path="/forgot-password">
            <>
              <ForgotPassword />
            </>
          </Route>
          <Route path="/checkout/:productName/:email/:referral?">
            <Checkout />
          </Route>
          <Route path="/payment-completed/:productName/:email/:checkoutSessionId?">
            <>
              <CheckoutSuccess />
            </>
          </Route>
          <Route path="/payment-failed/:productName/:email">
            <>
              <CheckoutFailure />
            </>
          </Route>

          {/* Now this schedule call doesn't work so we just lead to the pedning steps to redirect on the funnel */}
          {/* <Route path="/schedule-call">
            <ScheduleCallStandalone />
          </Route> */}

          {/* Need to delete this route it's legacy */}
          <Route path="/setup">
            <PostOnboardSurvey />
          </Route>

          <Route path="/sign-up">
            <SignIn isSignUp={true} />
          </Route>

          <PrivateRoute exact path="/app">
            <AppDownload />
          </PrivateRoute>

          <Route exact path="/settings">
            <Redirect to="/profile" />
          </Route>

          <Redirect exact from="/music" to="/sign-in?sourceApp=music" />

          <PrivateRoute exact path="/music/settings-strava">
            <MusicStravaSettings />
          </PrivateRoute>

          <PrivateRoute exact path="/music/settings-spotify">
            <MusicSpotifySettings />
          </PrivateRoute>

          <PrivateRoute exact path="/music/accounts">
            <MusicAccounts />
          </PrivateRoute>

          <PrivateRoute exact path="/profile">
            <Profile />
          </PrivateRoute>

          <PrivateRoute exact path="/profile/settings">
            <ProfileSettings />
          </PrivateRoute>

          <PrivateRoute exact path="/profile/settings/availability">
            <Availability />
          </PrivateRoute>

          <PrivateRoute exact path="/profile/settings/connect-strava">
            <ConnectStrava />
          </PrivateRoute>

          <PrivateRoute exact path="/profile/settings/manage-strava">
            <ManageStrava />
          </PrivateRoute>

          <PrivateRoute exact path="/profile/settings/connect-apple-health">
            <ConnectAppleHealth />
          </PrivateRoute>

          <PrivateRoute exact path="/profile/settings/notifications">
            <NotificationsPreference />
          </PrivateRoute>

          <PrivateRoute exact path="/profile/settings/membership">
            <Membership />
          </PrivateRoute>

          <PrivateRoute exact path="/profile/settings/connect-terra">
            <ConnectTerra />
          </PrivateRoute>

          <PrivateRoute exact path="/profile/settings/manage-terra/:accountId">
            <ManageTerra />
          </PrivateRoute>

          <Redirect exact from="/" to="/sign-in" />
        </IonRouterOutlet>
      </IonTabs>
      <ToastContainer
        position={isMobile ? "top-right" : "bottom-left"}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
        autoClose={3000}
      />
    </>
  );
}

export default App;
