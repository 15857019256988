import TextareaAutosize from "react-textarea-autosize";
import { trainingEvent, TrainingEventStatus } from "../../../Api/TrainingEvent";
import { Keyboard } from "@capacitor/keyboard";
import { useActivityFeedbackOutput } from "../../Hooks/useActivityFeedback";
import SubmitButton from "./SubmitButton";
import { useState } from "react";
import { isMobile } from "../../../Utilities/isMobile";
import { classNames } from "../../../Utilities/classnames";
import UpgradeCTA from "./Upgrade/UpgradeCTA";

interface CommentBoxInput {
  trainingEvent?: trainingEvent;
  activityFeedback: useActivityFeedbackOutput;
}

function CommentBox({ trainingEvent, activityFeedback }: CommentBoxInput) {
  const [showKeyboard, setShowKeyboard] = useState(false);

  if (isMobile) {
    Keyboard.addListener("keyboardWillShow", () => {
      setShowKeyboard(true);
    });

    Keyboard.addListener("keyboardWillHide", () => {
      setShowKeyboard(false);
    });
  }

  const isEnabled =
    activityFeedback.state.commentBoxContent !== "" &&
    !activityFeedback.state.isDisabled &&
    !(trainingEvent && trainingEvent.status === TrainingEventStatus.DRAFT);

  return (
    <>
      <UpgradeCTA activityFeedback={activityFeedback} />
      <div
        className={classNames(
          showKeyboard ? "border-t border-race-200 bg-[#EEEEEE]" : "",
          "py-2.5 relative px-2.5 w-full"
        )}
      >
        <TextareaAutosize
          maxRows={2}
          disabled={
            (trainingEvent &&
              trainingEvent.status === TrainingEventStatus.DRAFT) ||
            !activityFeedback.canSubmitComment()
          }
          rows={2}
          name="comment"
          id="comment"
          className={classNames(
            "block z-50 w-full py-4 pl-6 pr-16 border font-normal text-[#717171] caret-brand rounded-full resize-none border-race-100 ring-race-100 focus:border-race-200 focus:ring-race-200"
          )}
          value={activityFeedback.state.commentBoxContent}
          placeholder={
            !activityFeedback.canSubmitComment()
              ? "Comments are currently disabled..."
              : trainingEvent &&
                trainingEvent.status === TrainingEventStatus.DRAFT
              ? "You cannot comment on Draft events."
              // eslint-disable-next-line no-restricted-globals
              : location.pathname.includes("coach")
              ? "Add feedback..."
              : "How are you feeling?..."
          }
          onChange={(e) =>
            activityFeedback.handleCommentBoxContentChange(e.target.value)
          }
        />

        <SubmitButton
          activityFeedback={activityFeedback}
          isEnabled={isEnabled}
        />
      </div>
    </>
  );
}

export default CommentBox;
