import { FC } from "react";

interface PerceivedExertionProps {
  value?: number;
}

const MAX_FEEDBACK = 10;

export const PerceivedExertionCard: FC<PerceivedExertionProps> = ({
  value,
}) => {
  if (!value) return <></>;
  return (
    <div className="mb-5 border rounded-xl border-gray-300/50">
      <div className="flex items-center">
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between px-4 py-4 border-b">
            <h3 className="font-medium">
              Perceived Exertion ({value}/{MAX_FEEDBACK})
            </h3>
          </div>
          <div className="mx-8 flex items-center justify-between">
            {[...Array(MAX_FEEDBACK)].map((_, i) => {
              let fillDot = "bg-gray-200";
              let fillLine = "bg-gray-200";
              if (i < value) {
                fillDot = "bg-brand";
              }
              if (i < value - 1) {
                fillLine = "bg-brand";
              }
              return (
                <div className="relative z-10" key={`loe-fill-${i}`}>
                  {i < MAX_FEEDBACK - 1 && (
                    <div
                      className={`absolute bottom-2 left-5 w-8 h-1 ${fillLine}`}
                    ></div>
                  )}
                  <div className={`w-5 mt-2 h-5 rounded-full ${fillDot}`}></div>
                </div>
              );
            })}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex-none">
              <p className="ml-4 px-4 py-6 space-y-2 text-sm">Easy</p>
            </div>
            <div className="flex-none">
              <p className="px-4 py-6 space-y-2 text-sm">Moderate</p>
            </div>
            <div className="flex-none">
              <p className="mr-4 px-4 py-6 space-y-2 text-sm">Hard</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
