import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { sortingOptions } from "./Athletes";

interface TableHeaderInput {
  option: sortingOptions;
  setOption: React.Dispatch<React.SetStateAction<sortingOptions>>;
  reversed: boolean;
  setIsReversed: React.Dispatch<React.SetStateAction<boolean>>;
}

function TableHeader({
  option,
  setOption,
  reversed,
  setIsReversed,
}: TableHeaderInput) {
  const renderMarkAsComplete =
    // eslint-disable-next-line no-restricted-globals
    location.pathname.replaceAll("/", "").endsWith("basic") ||
    // eslint-disable-next-line no-restricted-globals
    location.pathname.replaceAll("/", "").endsWith("reviewed");

  return (
    <thead className="font-poppins bg-white text-center font-semibold text-[12px] text-[#374151] tracking-[1px]">
      <tr>
        <th
          onClick={() => {
            if (option === "name") {
              setIsReversed(!reversed);
            } else {
              setOption("name");
            }
          }}
          scope="col"
          className="px-3 py-3.5 text-left flex items-center cursor-pointer gap-1"
        >
          <p>ATHLETE</p>
          {option === "name" && (
            <>
              {reversed ? (
                <ChevronDownIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              ) : (
                <ChevronUpIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              )}
            </>
          )}
        </th>
        <th
          onClick={() => {
            if (option === "status") {
              setIsReversed(!reversed);
            } else {
              setOption("status");
            }
          }}
          scope="col"
        >
          <div className="flex items-center justify-center gap-1 cursor-pointer">
            <p>STATUS</p>
            {option === "status" && (
              <>
                {reversed ? (
                  <ChevronDownIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronUpIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </>
            )}
          </div>
        </th>
        <th scope="col">WORKOUTS SCHEDULED</th>
        <th scope="col">COMPLETION SCORE (14D)</th>
        <th scope="col">
          <div className="flex items-center justify-center gap-1">
            <p>ALERTS</p>
          </div>
        </th>{" "}
        {/* <th scope="col">ACTIONS</th> */}
        {renderMarkAsComplete && <th scope="col">MARK AS COMPLETE</th>}
      </tr>
    </thead>
  );
}

export default TableHeader;
