import { trainingProfile } from '../../Api/TrainingProfile'
import Tooltip from '../../Utilities/Components/Tooltip'

export default function useAthleteRatingIcon() {
    const ratings = [
        {
            id: 'GOOD',
            component: (
                <span
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            Good
          </span>
            ),
        },
        {
            id: 'OKAY',
            component: (
                <span
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            Okay
          </span>
            ),
        },
        {
            id: 'NEEDS_ATTENTION',
            component: (
                <span
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            Action Needed
          </span>
            ),
        },
    ]

    function getRatingIcon(trainingProfile: trainingProfile) {
        return getRatingIconByStatus(trainingProfile.coachingStatus, trainingProfile.coachingFeedbacks)
    }

    function getRatingIconByStatus(status: string, feedbacks: string[] | null) {
        let ratingDom = ratings.find(
            (rating) => rating.id === status
        )?.component

        if (feedbacks && feedbacks.length > 0 && ratingDom) {
            ratingDom = (
                <Tooltip
                    display={ratingDom}
                    content={
                        <ul className="ml-6 space-y-4 text-left list-disc list-outside">
                            {feedbacks.map((feedback, i) => (
                                <li key={i}>{feedback}</li>
                            ))}
                        </ul>
                    }
                />
            )
        }
        return ratingDom
    }

    return {
        getRatingIcon,
        getRatingIconByStatus,
    }
}
