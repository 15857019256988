import {
  trainingEvent,
  TrainingEventStatus,
  TrainingEventType,
} from "../../../Api/TrainingEvent";
import { UnitPreference } from "../../../Api/User";
import { CalendarDay } from "../../Calendar";
import CalendarEvent from "./CalendarEvent";
import { useDrag, useDrop } from "react-dnd";
import { useEffect, useRef, useState } from "react";
import { classNames } from "../../../Utilities/classnames";
import { dateIsOnBlockedDay } from "../../../Utilities/DateUtilities";
import Tooltip from "../../../Utilities/Components/Tooltip";
import useContextMenu from "use-context-menu";
import CalendarContextMenu from "../../Calendar/CalendarContextMenu/CalendarContextMenu";
import { trainingProfile } from "../../../Api/TrainingProfile";
import { Step } from "../../../Api/Step";
import EventTypeIndicator from "../../../Coachee/Components/EventTypeIndicator";
import { CalendarTooltips } from "./CalendarTooltips";

interface CalendarDateInput {
  day: CalendarDay;
  metricSetting: UnitPreference;
  selectedDay?: CalendarDay;
  handleSelectedDayChange: (date: string) => void;
  handleEventDrag: (
    sendingEvent: trainingEvent,
    recievingDate: Date,
    occupyingEvent: trainingEvent | null
  ) => Promise<void>;
  handleDelete: (id: string, isOverwrite?: boolean) => Promise<void>;
  handleAdd: (
    type?: TrainingEventType,
    scheduledAt?: Date,
    title?: string,
    description?: string[],
    assignedDistance?: string,
    assignedDuration?: string,
    message?: string,
    sportsType?: string,
    openToNewEvent?: boolean,
    steps?: Step[]
  ) => Promise<void>;
  handleDuplicate: (event: trainingEvent) => Promise<void>;
  handleRepeat: (event: trainingEvent, weeks: number) => Promise<void>;
  selectedDays: CalendarDay[];
  handleSelectedDaysChange: (
    day: CalendarDay,
    metaKeyIsPressed?: boolean | undefined,
    shiftKeyIsPressed?: boolean | undefined
  ) => void;
  initTrainingEvents: () => void;
  setIsOperating: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTrainingProfile: trainingProfile;
  openTemplateMenu: (date?: Date) => void;
  createTemplate: (event: trainingEvent) => Promise<void>;
  hideDay?: boolean;
}

function CalendarDate({
  day,
  metricSetting,
  selectedDay,
  handleSelectedDayChange,
  handleEventDrag,
  handleDelete,
  handleAdd,
  handleDuplicate,
  handleRepeat,
  selectedDays,
  handleSelectedDaysChange,
  initTrainingEvents,
  setIsOperating,
  selectedTrainingProfile,
  openTemplateMenu,
  createTemplate,
  hideDay,
}: CalendarDateInput) {
  const [hoverEvent, setHoverEvent] = useState<trainingEvent | null>(null);
  const [containsElement, setContainsElement] = useState<trainingEvent | null>(
    null
  );

  const isSelected = selectedDays.includes(day);
  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: "event",
      drop: (item: any) => {
        handleEventDrag(item.day.event, day.date, day.event);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      hover(item) {
        setHoverEvent(item.day.event);
      },
    }),
    [day]
  );
  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: "event",

      item: { day },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [day]
  );

  const menu = useRef(null);
  const contextMenuTargetRef = useRef(null);

  const { target } = useContextMenu({
    menu,
    targets: [{ id: "target", target: contextMenuTargetRef }],
  });

  function getBackgroundColor(event: trainingEvent | null): string {
    if (!event) {
      if (
        dateIsOnBlockedDay(day.date, selectedTrainingProfile.trainingAttribute)
      ) {
        return "bg-repeating-linear-gradient";
      } else {
        return "bg-white";
      }
    }
    switch (event.status) {
      case TrainingEventStatus.COMPLETED:
        return "bg-[#F3F8F6]";
      case TrainingEventStatus.SCHEDULED:
        return "bg-[#F7F7F7]";
      case TrainingEventStatus.DRAFT:
        return "bg-[#FFFFFF]";
      case TrainingEventStatus.MISSED:
        return "bg-[#FFF1EF]";

      default:
        return "bg-white";
    }
  }

  useEffect(() => {
    if (!isOver) setHoverEvent(null);
  }, [isOver]);

  useEffect(() => {
    if (!isDragging) {
      setContainsElement(day.event);
    } else {
      setContainsElement(null);
    }
  }, [isDragging]);

  useEffect(() => {
    if (day.event) {
      setContainsElement(day.event);
    } else {
      setContainsElement(null);
    }
  }, [day]);

  const otherEventsCount = day.otherEvents ? day.otherEvents.length : 0;
  const isDayBlocked = !!dateIsOnBlockedDay(
    day.date,
    selectedTrainingProfile.trainingAttribute
  );
  return (
    <div className="relative " ref={contextMenuTargetRef}>
      <div
        ref={dropRef}
        className={day.event ? "cursor-pointer" : "select-none"}
        onClick={(e) => {
          // TODO: ask Tanya about functional
          handleSelectedDayChange(day.formattedDate);
          // if (e.detail === 1) {
          //   if (navigator.userAgent.includes("Macintosh")) {
          //     handleSelectedDaysChange(day, e.metaKey, e.shiftKey);
          //   } else {
          //     handleSelectedDaysChange(day, e.altKey, e.shiftKey);
          //   }
          // } else if (e.detail === 2) {
          //   handleSelectedDayChange(day.formattedDate);
          // }
        }}
        key={day.formattedDate}
      >
        <div
          ref={day.event ? dragRef : undefined}
          className={classNames(
            getBackgroundColor(hoverEvent ? hoverEvent : containsElement),
            isSelected ? "outline-brand z-20" : "outline-gray-200",
            hideDay ? "opacity-10" : "",
            "relative py-4 px-3 outline outline-1 flex flex-col h-40 "
          )}
        >
          <div className={"flex justify-between pb-[10px]"}>
            {containsElement ? (
              <div className="flex items-center gap-1 group max-w-full overflow-hidden pr-[4px]">
                <div className="shrink-0">
                  <EventTypeIndicator
                    eventType={containsElement.trainingEventType}
                  />
                </div>
                <p className="text-[14px] font-medium text-gray-900 truncate group-hover:text-brand">
                  {containsElement.title.length > 0 ? (
                    containsElement.title
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </p>
                {/* If there are more events on the same day */}
                {otherEventsCount > 0 && (
                  <Tooltip
                    display={
                      <sup className="shrink-0 text-brand text-[12px]">
                        {otherEventsCount && otherEventsCount > 0
                          ? `+${otherEventsCount}`
                          : ""}
                      </sup>
                    }
                    content={`This date also contains ${otherEventsCount} ${
                      otherEventsCount === 1 ? "activity" : "activities"
                    }`}
                  />
                )}
              </div>
            ) : (
              <p className="text-[14px] font-medium text-gray-400 truncate group-hover:text-brand">
                {isDayBlocked ? <span>Blocked day</span> : <span>&nbsp;</span>}
              </p>
            )}
            <time
              dateTime={day.formattedDate}
              className={
                day.isToday
                  ? "flex h-6 min-w-[24px] w-6 items-center justify-center rounded-full bg-[#F43F5E] font-semibold text-white text-[14px]"
                  : "text-[14px]"
              }
            >
              {day.formattedDate.split("-").pop()?.replace(/^0/, "")}
            </time>
          </div>
          <CalendarTooltips
            day={day}
            selectedTrainingProfile={selectedTrainingProfile}
          />
          <CalendarEvent
            event={hoverEvent ? hoverEvent : containsElement}
            metricSetting={metricSetting}
            otherEventsCount={day.otherEvents ? day.otherEvents.length : 0}
          />
        </div>
      </div>
      {target.isOpen && (
        <div className="fixed z-50" ref={menu}>
          <CalendarContextMenu
            day={day}
            selectedDays={selectedDays}
            handleDelete={handleDelete}
            handleAdd={handleAdd}
            handleDuplicate={handleDuplicate}
            handleRepeat={handleRepeat}
            initTrainingEvents={initTrainingEvents}
            setIsOperating={setIsOperating}
            openTemplateMenu={openTemplateMenu}
            createTemplate={createTemplate}
          />
        </div>
      )}
    </div>
  );
}

export default CalendarDate;
