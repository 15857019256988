import { useUserInfo } from "../../Context/UserContext";

function Header() {
  const { userInfo } = useUserInfo();

  return (
    <div className="mb-10 font-poppins sm:flex sm:items-center">
      <div className="sm:flex-auto">
        {userInfo && (
          <h2 className="text-[14px] text-[#6B7280]">
            Welcome back, {userInfo.firstName} 👋
          </h2>
        )}

        <h1 className="font-bold text-[36px] text-[#111827]">My Athletes</h1>
        <p className="mt-2 text-sm text-gray-700"></p>
      </div>
    </div>
  );
}

export default Header;
