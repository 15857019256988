export enum TrainingEventStepType {
  WARMUP = "WARMUP",
  RUN = "RUN",
  DRILLS = "DRILLS",
  STRIDES = "STRIDES",
  // RECOVER = "RECOVER",
  // REST = "REST",
  COOL_DOWN = "COOL_DOWN",
  OTHER = "OTHER",
  REPEAT = "REPEAT",
  WALK = "WALK",
}

export const modifiedStepTypeNames = {
  [TrainingEventStepType.WARMUP]: "Warm-up",
  [TrainingEventStepType.COOL_DOWN]: "Cool-down",
  [TrainingEventStepType.STRIDES]: "Strides",
};

export function getStepTypeName(stepType: TrainingEventStepType) {
  if (stepType in modifiedStepTypeNames) {
    return modifiedStepTypeNames[
      stepType as keyof typeof modifiedStepTypeNames
    ];
  }
  return stepType
    .split("_")
    .map((word) => word[0] + word.slice(1).toLowerCase())
    .join(" ");
}

export type Step = {
  stepType: TrainingEventStepType;
  assignedDuration?: number;
  assignedDistance?: number;
  assignedCalories?: number;
  assignedHeartRateFloor?: number;
  assignedHeartRateCeiling?: number;
  requireLapButtonPress?: boolean;
  description?: string;
  intensityMinSpeed?: number;
  intensityMaxSpeed?: number;
  intensityMinCadence?: number;
  intensityMaxCadence?: number;
  intensityMinHeartRate?: number;
  intensityMaxHeartRate?: number;
  intensityMinZone?: number;
  intensityMaxZone?: number;
  intensityMinZoneFraction?: number;
  intensityMaxZoneFraction?: number;
  repeats?: number;
  subSteps?: Step[];
  stepOption?: string | undefined;
  stepOtherOption?:string | undefined;
};

export enum GenericZone {
  EASY = 1000,
  MARATHON = 1001,
  THRESHOLD = 1002,
  GOAL_MARATHON = 1003,
  FIVE_K = 1004,
  HALF_MARATHON = 1005,
}

export const genericZoneNames = Object.keys(GenericZone)
  .filter((key) => isNaN(Number(key)))
  .map((key) => key.replace(/_/g, " ").toLowerCase())
  // every word starts with a capital letter and the rest is lower case
  .map((key) =>
    key.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
    ).replace("Five ", "5")
  );

export function getGenericZoneNumber(zoneName: string): number | undefined {
  const zoneNameUpper = zoneName.toUpperCase();
  const zoneNameUnderscore = zoneNameUpper.replace("5", "FIVE ").replace(/ /g, "_");
  return GenericZone[zoneNameUnderscore as keyof typeof GenericZone];
}

export const getGenericZoneName = (zone: number) => {
  const zoneName = Object.keys(GenericZone).find(
    (key) => GenericZone[key as keyof typeof GenericZone] === zone
  );
  if (zoneName) {
    return zoneName
      .replace(/_/g, " ")
      .replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
      )
      .replace("Five ", "5");
  }
  return undefined;
};

export enum StepType {
  "DISTANCE" = "DISTANCE",
  "DURATION" = "DURATION",
}

export type EditableStep = Omit<Step, "subSteps"> & {
  editing: boolean;
  subSteps?: EditableStep[];
};

export const getEditableSteps = (steps: Step[]): EditableStep[] => {
  return steps.map((step) => ({
    ...step,
    editing: false,
    subSteps: getEditableSteps(step.subSteps ?? []),
  }));
};

export const getSteps = (editableSteps: EditableStep[]): Step[] => {
  return editableSteps.map((editableStep) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { editing, ...step } = editableStep;
    return {
      ...step,
      subSteps: getSteps(step.subSteps ?? []),
    };
  });
};

export const TrainingEventStepTypeValues = Object.keys(
  TrainingEventStepType
).filter((key) => key !== "REPEAT") as TrainingEventStepType[];

// given an enum, return an array of its values
export const getEnumValues = (enumType: any): string[] => {
  return Object.keys(enumType).map(
    (key) => enumType[key as keyof typeof enumType]
  );
};

// given an enum type, return in as a string or undefined
export function getEnumAsString(
  enumType: any,
  enumValue: string
): string | undefined {
  return getEnumValues(enumType).includes(enumValue)
    ? enumValue.toString()
    : undefined;
}

export function getStepTypeAsString(
  stepType: TrainingEventStepType
): string | undefined {
  return TrainingEventStepTypeValues.includes(stepType)
    ? stepType.toString()
    : undefined;
}
