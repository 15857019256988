import { ClockIcon } from "@heroicons/react/outline";
import { useIonRouter } from "@ionic/react";
import { useEffect, useState } from "react";

function UpgradeModalContent({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const routerHook = useIonRouter();
  const [goToUpgrade, setGoToUpgrade] = useState(false);

  useEffect(() => {
    if (!showModal && goToUpgrade) {
      routerHook.push("/upgrade");
    }
  }, [showModal, goToUpgrade]);

  return (
    <div className="mt-6 text-center">
      <div className="flex items-center justify-center">
        <ClockIcon className="w-8 h-8 text-neutral-400" />
      </div>
      <h1 className="mt-3  font-medium text-[16px] text-black">
        Your Coach Response May Be Delayed
      </h1>
      <p className="text-[14px] mx-6 mt-3 text-neutral-500">
        Friendly reminder that your plan is not for inmediate coach responses
        and can be delayed. For unlimited interactions and more, consider
        upgrading!
      </p>
      <div className="mx-3 space-y-2">
        <button
          onClick={() => {
            setShowModal(false);
            setGoToUpgrade(true);
          }}
          className="flex items-center justify-center w-full py-2 mt-4 text-white rounded-full"
          style={{
            background: "linear-gradient(98.41deg, #F6747E 0%, #FF6388 100%)",
          }}
        >
          Explore Plans
        </button>
        <button
          onClick={() => setShowModal(false)}
          className="flex items-center justify-center w-full py-2 mt-4 text-black bg-white border border-gray-300 rounded-full"
        >
          OK
        </button>
      </div>
    </div>
  );
}

export default UpgradeModalContent;
