import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {IonContent, IonPage} from "@ionic/react";
import {useUserInfo} from "../../Context/UserContext";
import {classNames} from "../../Utilities/classnames";
import {Switch} from "@headlessui/react";
import {setDailyMixSetting} from "../../Api/Settings";
import {MusicHeader} from "./MusicHeader";
import {MusicFooter} from "./MusicFooter";

function MusicStravaSettings() {
  const history = useHistory();

  const { userInfo } = useUserInfo();
  const [enableDailyMix, setEnableDailyMix] = React.useState(false);

  useEffect(() => {
    if (!userInfo) {
      history.push("/sign-in");
    } else {
      setEnableDailyMix(userInfo.enableDailyMixSetting);
    }
  }, [userInfo]);

  return (
    <IonPage>
      <IonContent forceOverscroll={false}>
        <div className="flex flex-col h-screen justify-between">
        <MusicHeader/>
          <main>
            <div className="relative bg-white">
              <div className="mx-auto max-w-3xl">
                <div className="bg-white sm:rounded-lg">

                  <div className="mb-8 px-4 sm:px-6">
                    <a onClick={ () => {history.push("/music/accounts");}}
                       className="cursor-pointer inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:text-gray-700">
                      <svg className="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                           aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
                              clip-rule="evenodd"/>
                      </svg>
                      Back
                    </a>
                  </div>

                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Spotify Settings</h3>
                    <div>
                      <div className="flex items-center justify-between py-5">
                        <span className="flex flex-grow flex-col">
                          <span className="text-sm font-medium text-gray-900" id="availability-label">Daily Runzy Playlist</span>
                          <span className="text-sm text-gray-500" id="availability-description">Create a daily Spotify playlist based on my top songs</span>
                        </span>
                        <Switch
                            checked={enableDailyMix}
                            onChange={async () => {
                              const newVal = !enableDailyMix;
                              await setDailyMixSetting(newVal);
                              setEnableDailyMix(newVal);
                            }}
                            className={classNames(
                                enableDailyMix ? "bg-primary-500" : "bg-neutral-100",
                                "relative inline-flex flex-shrink-0 h-[24px] w-[48px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            )}
                        >
                          <span
                              aria-hidden="true"
                              className={classNames(
                                  enableDailyMix ? "translate-x-[24px]" : "translate-x-0",
                                  "pointer-events-none inline-block h-[20px] w-[20px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200"
                              )}
                          />
                        </Switch>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </main>
          <MusicFooter/>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default MusicStravaSettings;
