import { contextMenuOption } from "./useCalendarContextMenu";

function Action({ option }: { option: contextMenuOption }) {
  return (
    <div className="flex items-center gap-1">
      <div className="w-4 h-4 text-gray-500">{option.icon}</div>
      <p>{option.name}</p>
    </div>
  );
}

export default Action;
