import { TrainingStats } from "../../../Api/TrainingProfile";
import { UnitPreference } from "../../../Api/User";
import { useUserInfo } from "../../../Context/UserContext";
import { formatDistanceForAthletePage } from "../../../Utilities/FormatUtilties";
import { formatToHourAndMinuteString } from "./format";
import Trend from "./Trend";

function LastTwentyEightDays({ stats }: { stats: TrainingStats }) {
  const { userInfo } = useUserInfo();

  return (
    <div className="py-4 mb-6 bg-white border border-gray-10 rounded-xl">
      <h4 className="px-4 mb-4 font-semibold text-gray-900">This Month</h4>
      <div className="divide-y-2 divide-gray-100">
        <div className="flex items-center justify-between px-4 pb-4">
          <div>
            <p className="font-semibold text-[20px]">
              {formatDistanceForAthletePage(
                stats.distanceLast28Days,
                userInfo?.profileMetricSetting,
                false,
                false,
                true,
                undefined,
                false
              ).replace(" ", "")}
            </p>
            <p className="text-neutral-500 text-[13px]">
              {userInfo?.profileMetricSetting === UnitPreference.METRIC
                ? "Kilometers"
                : "Miles"}
            </p>
          </div>
          <Trend metric={stats.distanceTrendLast28Days} />
        </div>
        <div className="flex items-center justify-between px-4 pt-4">
          <div>
            <p className="font-semibold text-[20px]">
              {formatToHourAndMinuteString(stats.durationLast28Days)}
            </p>
            <p className="text-neutral-500 text-[13px]">Hours</p>
          </div>
          <Trend metric={stats.trendDurationLast28Days} />
        </div>
      </div>
    </div>
  );
}

export default LastTwentyEightDays;
