import {useState} from "react";
import {requestResetPassword,} from "../Api/User";
import RunzyLogo from "./../Assets/RunzyLogo.svg";
import {track, TrackingEventType} from "../Api/Tracking";
import {IonContent, IonPage} from "@ionic/react";

function ForgotPassword() {

  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessFul, setIsSuccessful] = useState<boolean>(false);

  async function handleResetPassword(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(null);


    const response = await requestResetPassword(email);
    setIsLoading(false);
    track(TrackingEventType.TRACKING_APP_WEB_ATHLETE_DASHBOARD, "Reset password attempt");
    if (!response) {
      setErrorMessage("Something went wrong. Please try again later.");
    } else {
      setIsSuccessful(true);
    }
  }


  return (
    <IonPage>
      <IonContent forceOverscroll={false}>
        <div className="flex flex-col h-screen min-h-full py-12 bg-white sm:px-6 lg:px-8 ">
            <>
              {" "}
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                  className="w-auto h-8 mx-auto mt-10"
                  src={RunzyLogo}
                  alt="Runzy"
                />
                <div className="mt-10 text-center">
                  <h1 className="text-3xl font-semibold cursor-pointer">
                    Reset Password
                  </h1>
                </div>
                {!isSuccessFul && <div className="mt-4 text-center">
                  <p>Enter the e-mail you used to sign up for
                    Runzy. If the e-mail matches our records, a password reset
                    link will be sent to the account holder.</p>
                </div>}
              </div>
              {isSuccessFul ? (
                  <div
                      className="relative py-3 my-3 text-xs text-center text-slate-700 bg-slate-100 border border-slate-400 rounded max-w-4xl mx-auto px-8 mt-8"
                      role="alert"
                  >
                    <p className="text-center">Your password reset request was submitted. Check your inbox and follow the instructions.</p>
                  </div>

              ) : (<>

              {errorMessage && (
                <div
                  className="max-w-4xl px-4 mx-auto relative py-3 my-3 text-xs text-center text-red-700 bg-red-100 border border-red-400 rounded"
                  role="alert"
                >
                  <strong className="font-bold">Error: </strong>
                  <span className="block sm:inline">{errorMessage}</span>
                </div>
              )}
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="px-4 py-8 bg-white sm:rounded-lg sm:px-10 ">
                  <form className="space-y-5">
                    <div>
                      <label
                        htmlFor="email"
                        className="block pl-2 text-sm font-medium text-left text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                          className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-brand focus:border-brand sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="flex justify-center w-full px-4 py-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                        onClick={(e) => handleResetPassword(e)}
                      >
                        {isLoading && (
                          <div
                            className="inline-block w-4 h-4 text-white rounded-full spinner-border animate-spin border-1"
                            role="status"
                          ></div>
                        )}
                        &nbsp; Reset password
                      </button>
                    </div>
                  </form>
                </div>{" "}
              </div></>)}
            </>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default ForgotPassword;
