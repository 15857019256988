import { UnitPreference } from "../../../Api/User";
import { useUnitPreferenceOutput } from "./useUnitPreference";

export const options = [
  { name: "Miles", value: UnitPreference.IMPERIAL, abbreviation: "mi" },
  { name: "Kilometers", value: UnitPreference.METRIC, abbreviation: "km" },
];

function UnitModalContents({
  unitPreferenceHook,
}: {
  unitPreferenceHook: useUnitPreferenceOutput;
}) {
  return (
    <div className="pt-6 divide-y divide-race-100">
      {options.map((option, index) => (
        <div
          key={index}
          className="flex items-center justify-between px-8 py-6"
          onClick={() => unitPreferenceHook.setUnitPreference(option.value)}
        >
          <div className="flex items-center gap-2">
            <p className="text-[20px] font-medium">{option.name}</p>
            <p className="pt-1 font-medium text-[13px] text-[#717171]">
              ({option.abbreviation})
            </p>
          </div>
          {option.value === unitPreferenceHook.unitPreference && (
            <input
              id="isChecked"
              name="isChecked"
              type="checkbox"
              checked={true}
              className="w-4 h-4 border-gray-300 rounded cursor-pointer text-brand focus:ring-brand"
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default UnitModalContents;
