import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import { TrashIcon, PencilIcon } from "@heroicons/react/solid";
import { Step } from "../../../../Api/Step";

export default function Buttons({
  step,
  steps,
  onMoveUp,
  onMoveDown,
  onDelete,
  onEdit,
  canSwap,
  isInRepeat,
}: {
  step: Step;
  steps: Step[];
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  canSwap: boolean;
  isInRepeat?: boolean;
}) {
  const isFirstStep = steps.indexOf(step) === 0;
  const isLastStep = steps.indexOf(step) === steps.length - 1;

  // up botton is never disabled if it's a substep
  // if its not a substep, it's disabled if it's the first step or if it can't be swapped
  const isUpDisabled = !isInRepeat && (isFirstStep || !canSwap);
  // down botton is never disabled if it's a substep
  // if its not a substep, it's disabled if it's the last step or if it can't be swapped
  const isDownDisabled = !isInRepeat && (isLastStep || !canSwap);

  return (
    <div className="flex-shrink-0 flex items-center space-x-[10px]">
      {onMoveUp && (
        <button
          type="button"
          disabled={isUpDisabled}
          className="text-neutral-700 hover:text-neutral-900 disabled:text-neutral-300"
          onClick={(e) => {
            e.preventDefault();
            onMoveUp();
          }}
        >
          <ArrowUpIcon className="w-4 h-4" />
        </button>
      )}
      {onMoveDown && (
        <button
          type="button"
          disabled={isDownDisabled}
          className="text-neutral-700 hover:text-neutral-900 disabled:text-neutral-300"
          onClick={(e) => {
            e.preventDefault();
            onMoveDown();
          }}
        >
          <ArrowDownIcon className="w-4 h-4" />
        </button>
      )}
      {onEdit && (
        <button
          type="button"
          className="text-neutral-700 hover:text-neutral-900"
          onClick={(e) => {
            e.preventDefault();
            onEdit();
          }}
        >
          <PencilIcon className="w-4 h-4" />
        </button>
      )}
      {onDelete && (
        <button
          type="button"
          className="text-neutral-500 hover:text-neutral-700"
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          <TrashIcon className="w-4 h-4" />
        </button>
      )}
    </div>
  );
}
