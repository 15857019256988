import { trainingAttributes } from "../Api/TrainingAttribute";
import {
  differenceInCalendarDays,
  format,
  formatDistanceToNow,
  isToday,
  isYesterday,
  differenceInDays,
} from "date-fns";
import { TrainingEventStatus, TrainingEventType } from "../Api/TrainingEvent";
import { titleCase } from "./FormatUtilties";
import { DateTime } from "luxon";

const abvDayOfTheWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const dayOfTheWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const altDayOfTheWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const abvMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export function formatAMPM(date: Date, detailMode = "") {
  if (detailMode === "min") {
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  } else if (detailMode === "sec") {
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  }

  return date.toLocaleString("en-US", { hour: "numeric", hour12: true });
}

export function decodeDateFromSecondsFormat(scheduledAt: number): Date {
  const milliSeconds = scheduledAt * 1000;

  return new Date(milliSeconds);
}

export function decodeDateFromStringFormat(scheduledAt: string): Date {
  const date = new Date(scheduledAt);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset); // Hack to ensure we always show time in UTC.
}

export function encodeSecondsTimestampFromDate(
  date: Date,
  disableTime = true
): number {
  if (disableTime) date.setHours(0, 0, 0, 0);

  const milleseconds = date.getTime();

  return milleseconds / 1000;
}

export function encodeStringFromDate(date: Date): string {
  date.setHours(0, 0, 0, 0);

  const adjustedDate = new Date(
    date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
  );

  return adjustedDate.toISOString().split("T")[0];
}

export function getTimeZoneOffset(date: Date) {
  return date.getTimezoneOffset();
}

export function getDayOfTheWeek(date: Date): string {
  return dayOfTheWeek[date.getDay()];
}

export function formatDate(date: Date): string {
  const abbreviatedDayOfTheWeek = abvDayOfTheWeek[date.getDay()];
  const dayOfTheMonth = date.getDate();
  const month = months[date.getMonth()];

  return `${abbreviatedDayOfTheWeek} ${dayOfTheMonth} ${month}`;
}

export function formatDateMonthDay(date: Date): string {
  const dayOfTheMonth = date.getDate();
  const month = date.getMonth();

  return `${month}/${dayOfTheMonth}`;
}

export function formatDateForUser(date: Date): string {
  const now = DateTime.now();
  const luxonDate = DateTime.fromJSDate(date);
  const weekEndpoint = now.plus({ days: 7 });

  if (isDateTomorrow(date)) {
    return "Tomorrow";
  }

  if (
    !now.hasSame(luxonDate, "day") &&
    weekEndpoint > luxonDate &&
    luxonDate > now
  ) {
    return luxonDate.toFormat("cccc");
  }

  const dayOfTheMonth = date.getDate();
  const month = abvMonths[date.getMonth()];

  return `${month} ${dayOfTheMonth}`;
}

export function formatDateForTodaysEvent(date: Date): string {
  const weekDay = dayOfTheWeek[date.getDay()];
  const month = months[date.getMonth()];

  const dayOfTheMonth = date.getDate();

  return `${weekDay}, ${month} ${dayOfTheMonth}`;
}

export function formatDateMonthYear(date: Date): string {
  const month = months[date.getMonth()];

  const dayOfTheMonth = date.getDate();

  return `${month} ${dayOfTheMonth}, ${date.getFullYear()}`;
}

export function formatDateForEmail(emailDate: Date): string {
  const timeFormat = formatAMPM(emailDate);

  const weekDay = dayOfTheWeek[emailDate.getDay()];
  const month = months[emailDate.getMonth()];
  const dayOfTheMonth = emailDate.getDate();
  const year = emailDate.getFullYear();

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return `${timeFormat} ${timezone} - ${weekDay} ${month} ${dayOfTheMonth}, ${year}`;
}

export function generateGreeting(): string {
  const hour = new Date().getHours();

  if (hour < 12) {
    return "Morning";
  } else if (hour <= 17) {
    return "Afternoon";
  } else {
    return "Evening";
  }
}

export function convertToHTMLDateFormat(date: Date) {
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  return [date.getFullYear(), month, day].join("-");
}

export function datesAreOnTheSameDay(a: Date, b: Date): boolean {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
}

export function isDateToday(date: Date): boolean {
  const now = new Date();
  return (
    now.getDate() === date.getDate() &&
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear()
  );
}

export function isDateTomorrow(date: Date): boolean {
  const now = new Date();
  return (
    now.getDate() + 1 === date.getDate() &&
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear()
  );
}

export function isDateInThePast(date: Date): boolean {
  if (isDateToday(date) || dateIsTomorrowOrAfter(date)) return false;

  return true;
}

export function getDayDifference(date: Date) {
  if (isDateToday(date) || new Date().getTime() > date.getTime()) return -1;

  const diffTime = Math.abs(new Date().getTime() - date.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
}

export function dateIsTomorrowOrAfter(date: Date): boolean {
  return getDayDifference(date) > 0;
}

export function formatDateForActivityDisplay(date: Date): string {
  let weekDay = dayOfTheWeek[date.getDay()];

  if (isDateToday(date)) {
    weekDay = "Today";
  } else if (isDateTomorrow(date)) {
    weekDay = "Tomorrow";
  }

  const month = months[date.getMonth()];
  const dayOfTheMonth = date.getDate();

  return `${weekDay}, ${month} ${dayOfTheMonth}`;
}

export function getDisabledDaysFromAttributes(
  trainingAttributes: trainingAttributes
): number[] {
  const blockedDays = trainingAttributes.blockedDays.map((day) =>
    titleCase(day)
  );

  const disabledDays: number[] = [];

  for (let i = 0; i < dayOfTheWeek.length; i++) {
    if (blockedDays.includes(dayOfTheWeek[i])) {
      disabledDays.push(i);
    }
  }

  return disabledDays;
}

export function dateIsOnBlockedDay(
  date: Date,
  trainingAttributes: trainingAttributes
): boolean {
  const dateDayOfTheWeek = getDayOfTheWeek(date);

  const blockedDays = trainingAttributes.blockedDays.map((day) =>
    titleCase(day)
  );

  return blockedDays.includes(dateDayOfTheWeek);
}

export function formatFullDuration(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const remSeconds = seconds - hours * 3600 - minutes * 60;

  let minutesFormat: string | number = minutes;

  if (hours > 0) {
    minutesFormat = minutes > 9 ? minutes : `0${minutes}`;
  }

  const remSecondsFormat = remSeconds > 9 ? remSeconds : `0${remSeconds}`;

  if (hours === 0) return `${minutesFormat}:${remSecondsFormat}`;

  return `${hours}:${minutesFormat}:${remSecondsFormat}`;
}

export function sortDaysOfTheWeek(days: string[]): string[] {
  return days.sort((a, b) => {
    const modifiedA = titleCase(a);
    const modifiedB = titleCase(b);

    return dayOfTheWeek.indexOf(modifiedA) - dayOfTheWeek.indexOf(modifiedB);
  });
}

export function formatCalendarDate(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function formatCoachMessageDate(date: Date): string {
  let dayString = "";
  const timeString = format(date, "h:mmaaa");

  if (isToday(date)) {
    dayString = "Today";
  } else if (isYesterday(date)) {
    dayString = "Yesterday";
  } else if (differenceInCalendarDays(new Date(), date) < 7) {
    dayString = format(date, "cccc");
  } else {
    dayString = format(date, "LLL d");
  }

  return `${dayString} at ${timeString}`;
}

export function convertCalendarDateToLocalTimezone(date: Date) {
  // Because Rest days are not completed at certain time and are UTC, make sure to reflect them in the user's
  // own timezone so the date is not off.
  // See https://stackoverflow.com/questions/948532/how-to-convert-a-date-to-utc for more info.
  const nowUtc = new Date();
  return new Date(date.getTime() + nowUtc.getTimezoneOffset() * 60000);
}

export function convertLocalCalendarDateToUTC(date: Date) {
  // Because Rest days are not completed at certain time and are UTC, make sure to reflect them in the user's
  // own timezone so the date is not off.
  // See https://stackoverflow.com/questions/948532/how-to-convert-a-date-to-utc for more info.
  const nowUtc = new Date();
  return new Date(date.getTime() - nowUtc.getTimezoneOffset() * 60000);
}

export function formatActivityStreamDate(
  date: Date,
  trainingEvent?: any
): string {
  const modifiedUtc = convertCalendarDateToLocalTimezone(
    new Date(trainingEvent ? trainingEvent.scheduledAt : date)
  );

  const dateString = format(modifiedUtc, "MMMM d, Y");
  if (
    (trainingEvent && trainingEvent.status === TrainingEventStatus.MISSED) ||
    (trainingEvent &&
      [TrainingEventType.REST, TrainingEventType.CROSS_TRAINING].includes(
        trainingEvent.trainingEventType
      ))
  ) {
    if (isToday(modifiedUtc)) {
      return `Today`;
    } else if (isYesterday(modifiedUtc)) {
      return `Yesterday`;
    }

    return dateString;
  }

  return dateString;
}

// This function will take string '10 15 2023' and return October 15, 2023
export function formatStringNumberDate(date: string): string {
  return format(new Date(date), "MMMM d, Y");
}

export function formatNotificationDate(date: Date, removeAgo = false) {
  let formattedDate = formatDistanceToNow(date);

  if (differenceInDays(new Date(), date) > 2) return format(date, "MMM/dd/y");
  if (formattedDate === "less than a minute") return "now";

  formattedDate = formattedDate.replace("about ", "");

  const formattedDateArray = formattedDate.split(" ");

  formattedDate = `${formattedDateArray[0]}${formattedDateArray[1].charAt(0)} ${
    !removeAgo ? "ago" : ""
  }`;

  return formattedDate;
}
