import { useContext } from "react";
import { trainingEvent } from "../../Api/TrainingEvent";
import { UserInfoContext } from "../../Context/UserContext";
import { formatDateForActivityDisplay } from "../../Utilities/DateUtilities";
import ActivityPills from "./ActivityPills";
import EventTypeIndicator from "./EventTypeIndicator";

interface ActivityHeaderInput {
  trainingEvent: trainingEvent;
}

function ActivityHeader({ trainingEvent }: ActivityHeaderInput) {
  const { userInfo } = useContext(UserInfoContext);

  return (
    <>
      {userInfo && (
        <div>
          <div className="flex items-center gap-2">
            <div>
              <EventTypeIndicator
                eventType={trainingEvent.trainingEventType}
                onActivityScreen={true}
              />
            </div>

            <p className="text-sm font-normal text-neutral-500">
              {formatDateForActivityDisplay(trainingEvent.scheduledAt)}
            </p>
          </div>
          <h1 className="my-3 text-2xl font-semibold text-neutral-800">
            {trainingEvent.title}
          </h1>
          <h2 className="pb-4 text-sm font-normal text-textBlack line-clamp-3">
            {trainingEvent.trainingEventTypeDescription}
          </h2>
          <div className="pb-6">
            <ActivityPills
              event={trainingEvent}
              metricSetting={userInfo.profileMetricSetting}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default ActivityHeader;
