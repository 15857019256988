import { EditableStep } from "../../../../Api/Step";
import StepDisplay from "../StepDisplay/StepDisplay";
import StepEditor from "../StepEditor/StepEditor";
import { trainingProfile } from "../../../../Api/TrainingProfile";
import {
  PencilIcon,
  CheckIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/solid";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import RepeatIcon from "../../../../Assets/RepeatIcon.svg";
import { useState } from "react";
import CreateStep from "../CreateStep";

export default function StepRepeat({
  index,
  step,
  steps,
  onDelete,
  onChangeRepeatCount,
  onMoveUp,
  onMoveDown,
  onAddStep,
  canSwap,
  onMoveSubStepUp,
  onMoveSubStepDown,
  onDeleteSubStep,
  onEditSubStep,
  onSaveSubStep,
  onCancelSubStep,
  selectedTrainingProfile,
}: {
  index: number;
  step: EditableStep;
  steps: EditableStep[];
  onDelete?: () => void;
  onChangeRepeatCount?: (repeatIndex: number, repeats: number) => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onAddStep?: () => void;
  canSwap: boolean;
  onMoveSubStepUp?: (subStep: EditableStep) => void;
  onMoveSubStepDown?: (subStep: EditableStep) => void;
  onDeleteSubStep?: (subStep: EditableStep) => void;
  onEditSubStep?: (subStep: EditableStep) => void;
  onSaveSubStep?: (
    stepIndex: number,
    subStepIndex: number,
    subStep: EditableStep
  ) => void;
  onCancelSubStep?: (
    stepIndex: number,
    subStepIndex: number,
    subStep: EditableStep
  ) => void;
  selectedTrainingProfile?: trainingProfile;
}) {
  const handleSave = (subStepIndex: number, step: EditableStep) => {
    onSaveSubStep && onSaveSubStep(index, subStepIndex, step);
  };

  const handleCancel = (subStepIndex: number, step: EditableStep) => {
    onCancelSubStep && onCancelSubStep(index, subStepIndex, step);
  };

  const isFirstStep = index === 0;
  const isLastStep = index === steps.length - 1;

  const isUpDisabled = isFirstStep || !canSwap;
  const isDownDisabled = isLastStep || !canSwap;

  const [isChangingRepeatCount, setIsChangingRepeatCount] = useState(false);
  const [repeatCount, setRepeatCount] = useState(step.repeats);

  return (
    <div className="bg-neutral-50 rounded-[6px] p-4 border-neutral-300 border">
      <div className="bg-neutral-200 rounded-[8px] p-[10px] flex flex-col space-y-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <img src={RepeatIcon} alt="Repeat" />
            <h1 className="font-medium text-neutral-900 text-[14px] leading-[20px]">
              Repeat {step.repeats ?? 1} time{step.repeats !== 1 && "s"}
            </h1>
          </div>
          <div className="flex items-center gap-2">
            {isChangingRepeatCount ? (
              <>
                <button
                  type="button"
                  className="text-neutral-700 hover:text-neutral-900 disabled:text-neutral-300 w-4 h-4"
                  onClick={(e) => {
                    e.preventDefault();

                    if (repeatCount === undefined) {
                      window.alert("Please enter a valid number");
                      return;
                    }

                    if (repeatCount < 2) {
                      window.alert("Please enter a number greater than 1");
                      return;
                    }

                    if (repeatCount > 16) {
                      window.alert("Please enter a number less than 17");
                      return;
                    }

                    setIsChangingRepeatCount(false);
                    onChangeRepeatCount &&
                      repeatCount &&
                      onChangeRepeatCount(index, repeatCount);
                  }}
                >
                  <CheckIcon />
                </button>

                <button
                  type="button"
                  className="text-neutral-700 hover:text-neutral-900 disabled:text-neutral-300 w-4 h-4"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsChangingRepeatCount(false);
                    setRepeatCount(step.repeats);
                  }}
                >
                  <XIcon />
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="text-neutral-700 hover:text-neutral-900 disabled:text-neutral-300 w-4 h-4"
                  onClick={(e) => {
                    e.preventDefault();
                    onMoveUp && onMoveUp();
                  }}
                  disabled={isUpDisabled}
                >
                  <ArrowUpIcon />
                </button>
                <button
                  type="button"
                  className="text-neutral-700 hover:text-neutral-900 disabled:text-neutral-300 w-4 h-4"
                  onClick={(e) => {
                    e.preventDefault();
                    onMoveDown && onMoveDown();
                  }}
                  disabled={isDownDisabled}
                >
                  <ArrowDownIcon />
                </button>
                <button
                  type="button"
                  className="text-neutral-700 hover:text-neutral-900 disabled:text-neutral-300 w-4 h-4"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsChangingRepeatCount(true);
                  }}
                >
                  <PencilIcon />
                </button>
                <button
                  type="button"
                  className="text-neutral-700 hover:text-neutral-900 disabled:text-neutral-300 w-4 h-4"
                  onClick={(e) => {
                    e.preventDefault();
                    onDelete && onDelete();
                  }}
                >
                  <TrashIcon />
                </button>
              </>
            )}
          </div>
        </div>
        {isChangingRepeatCount ? (
          <input
            type="number"
            className="border border-neutral-300 rounded-[6px] text-neutral-900 text-[14px] inline-flex font-medium leading-[20px] focus:outline-none focus:ring-1 focus:ring-brand focus:border-brand"
            value={repeatCount}
            onChange={(e) => {
              setRepeatCount(parseInt(e.target.value));
            }}
            max={20}
            min={2}
          />
        ) : null}
      </div>
      <div className="space-y-2 mt-2">
        {step.subSteps !== undefined &&
          step.subSteps.map((subStep, index) => {
            if (subStep.editing) {
              return (
                <StepEditor
                  index={(step.subSteps ?? []).indexOf(subStep)}
                  step={subStep}
                  key={index}
                  isSubStep={true}
                  saveSubStep={handleSave}
                  cancelSubStep={handleCancel}
                  selectedTrainingProfile={selectedTrainingProfile}
                />
              );
            } else {
              return (
                <StepDisplay
                  key={index}
                  step={subStep}
                  steps={step.subSteps ?? []}
                  onMoveDown={() =>
                    onMoveSubStepDown && onMoveSubStepDown(subStep)
                  }
                  onMoveUp={() => onMoveSubStepUp && onMoveSubStepUp(subStep)}
                  onDelete={() => onDeleteSubStep && onDeleteSubStep(subStep)}
                  onEdit={() => onEditSubStep && onEditSubStep(subStep)}
                  canSwap={true}
                  isInRepeat={true}
                  selectedTrainingProfile={selectedTrainingProfile}
                />
              );
            }
          })}
      </div>
      {onAddStep ? (
        <div className="flex justify-center mt-4">
          <CreateStep
            onClick={(e) => {
              e.preventDefault();
              onAddStep && onAddStep();
            }}
            noText={true}
          />
        </div>
      ) : null}
    </div>
  );
}
