import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {editTrainingAttributes, trainingAttributes,} from "../../Api/TrainingAttribute";
import {trainingProfile} from "../../Api/TrainingProfile";
import {
  convertCalendarDateToLocalTimezone,
  convertToHTMLDateFormat,
  decodeDateFromSecondsFormat,
  decodeDateFromStringFormat,
  encodeSecondsTimestampFromDate,
  encodeStringFromDate,
  sortDaysOfTheWeek,
} from "../../Utilities/DateUtilities";
import _ from "lodash";

interface ProfileState {
  miniProfile: {
  isTest: boolean,
    phone: string | null;
    created: number;
  };
  isLoading: boolean;
  trainingAttributes: trainingAttributes | null;
  goalTitle: string | null;
  goalDate: Date | null;
  productIdOverrideExpiresAt: Date | null;
  productName: string | null;
  goalDistance: number | null;
  goalTime: string | null;
  blockedDays: string[];
  height: string | null;
  weight: string | null;
  age: string | null;
  gender: string | null;
}

const initProfileState: ProfileState = {
  miniProfile: {
    isTest: false,
    phone: null,
    created: 0,
  },
  productIdOverrideExpiresAt: null,
  productName: null,
  blockedDays: [],
  goalDate: null,
  goalDistance: null,
  goalTime: null,
  goalTitle: "",
  isLoading: false,
  trainingAttributes: null,
  height: '',
  weight: '',
  age: '',
  gender: '',
};

interface useProfileInput {
  selectedTrainingProfile: trainingProfile;
}

function useProfile({ selectedTrainingProfile }: useProfileInput) {
  const [currentProfileState, setCurrentProfileState] = useState<ProfileState>({
    ...initProfileState,
  });

  function handleCheck(day: string) {
    if (
      !currentProfileState.blockedDays ||
      !currentProfileState.trainingAttributes
    ) {
      return;
    }

    let modifiedBlockedDays = currentProfileState.blockedDays;

    if (currentProfileState.blockedDays.includes(day)) {
      modifiedBlockedDays = modifiedBlockedDays.filter((item) => {
        return item !== day;
      });
    } else {
      modifiedBlockedDays = modifiedBlockedDays.concat([day]);
    }

    modifiedBlockedDays = sortDaysOfTheWeek(modifiedBlockedDays);

    setCurrentProfileState({
      ...currentProfileState,
      blockedDays: modifiedBlockedDays,
    });
  }

  async function onEdit(payload: trainingAttributes) {
    const response = await editTrainingAttributes(
      payload,
      selectedTrainingProfile.miniProfile.id
    );

    if (!response) {
      alert("Edit operation could not be completed");
    } else {
      initTrainingAttributes();
      toast("Profile settings successfully saved");
    }
  }

  async function initTrainingAttributes() {
    if (selectedTrainingProfile) {
      const trainingAttributes = selectedTrainingProfile.trainingAttribute;

      if (trainingAttributes) {
        setCurrentProfileState({
          ...currentProfileState,
          trainingAttributes: trainingAttributes,
          miniProfile: {
            isTest: selectedTrainingProfile.miniProfile.isTest,
            phone: selectedTrainingProfile.miniProfile.phone,
            created: selectedTrainingProfile.miniProfile.created,
          }
        });
      }
    }
  }

  async function handleSubmit() {
    if (currentProfileState.trainingAttributes) {
      const payload = currentProfileState.trainingAttributes;

      payload.goalName = currentProfileState.goalTitle;
      if (currentProfileState.goalDate) {
        payload.goalDate = encodeStringFromDate(currentProfileState.goalDate);
      } else {
        payload.goalDate = null;
      }
      if (currentProfileState.productIdOverrideExpiresAt) {
        payload.productIdOverrideExpiresAt = encodeSecondsTimestampFromDate(currentProfileState.productIdOverrideExpiresAt);
      } else {
        payload.productIdOverrideExpiresAt = null;
      }
      payload.goalDistance = currentProfileState.goalDistance;
      if (currentProfileState.goalTime)
        payload.goalTime = currentProfileState.goalTime;
      if (currentProfileState.blockedDays)
        payload.blockedDays = currentProfileState.blockedDays;
      if (currentProfileState.height)
        payload.height = currentProfileState.height;
      if (currentProfileState.weight)
        payload.weight = currentProfileState.weight;
      if (currentProfileState.age)
        payload.age = currentProfileState.age;
      if (currentProfileState.gender)
        payload.gender = currentProfileState.gender;

      onEdit(payload);
    }
  }

  function initFields() {
    if (currentProfileState.trainingAttributes) {
      const newAttributes = currentProfileState.trainingAttributes;
      setCurrentProfileState({
        ...currentProfileState,
        goalTitle: newAttributes.goalName,
        goalDate: newAttributes.goalDate
          ? decodeDateFromStringFormat(newAttributes.goalDate)
          : null,
        goalDistance: newAttributes.goalDistance,
        goalTime: newAttributes.goalTime,
        weight: newAttributes.weight,
        height: newAttributes.height,
        age: newAttributes.age,
        gender: newAttributes.gender,
        blockedDays: newAttributes.blockedDays,
        productIdOverrideExpiresAt: newAttributes.productIdOverrideExpiresAt ?
            decodeDateFromSecondsFormat(newAttributes.productIdOverrideExpiresAt) : null,
        productName: newAttributes.productName,
        miniProfile: {
          isTest: currentProfileState.miniProfile.isTest,
          phone: currentProfileState.miniProfile.phone,
          created: currentProfileState.miniProfile.created,
        }
      });
    }
  }

  function hasChanged() {
    if (!currentProfileState.trainingAttributes) return false;

    const currentData = {
      title: currentProfileState.goalTitle ?? "",
      date: currentProfileState.goalDate
        ? convertToHTMLDateFormat(currentProfileState.goalDate)
        : null,
      distance: currentProfileState.goalDistance ?? "",
      time: currentProfileState.goalTime ?? "",
      weight: currentProfileState.weight ?? "",
      height: currentProfileState.height ?? "",
      age: currentProfileState.age ?? "",
      gender: currentProfileState.gender ?? "",
      blockedDays: currentProfileState.blockedDays.sort(),
      productIdOverrideExpiresAt: currentProfileState.productIdOverrideExpiresAt ? encodeSecondsTimestampFromDate(
          currentProfileState.productIdOverrideExpiresAt, false
      ) : null,
      productName: currentProfileState.productName ?? ""
    };

    const storedData = {
      title: currentProfileState.trainingAttributes.goalName ?? "",
      date: currentProfileState.trainingAttributes.goalDate,
      distance: currentProfileState.trainingAttributes.goalDistance ?? "",
      time: currentProfileState.trainingAttributes.goalTime ?? "",
      weight: currentProfileState.trainingAttributes.weight ?? "",
      height: currentProfileState.trainingAttributes.height ?? "",
      age: currentProfileState.trainingAttributes.age ?? "",
      gender: currentProfileState.trainingAttributes.gender ?? "",
      blockedDays: currentProfileState.trainingAttributes.blockedDays.sort(),
      productIdOverrideExpiresAt: currentProfileState.trainingAttributes.productIdOverrideExpiresAt,
      productName: currentProfileState.trainingAttributes.productName ?? ""
    };

    return !_.isEqualWith(currentData, storedData);
  }

  function goalDistanceToString(distance: number): string {
    switch (distance) {
      case 50000:
        return "50 K";
      case 42195:
        return "Marathon";
      case 21097.5:
        return "Half Marathon";
      case 16093.4:
        return "10 Mi";
      case 12000:
        return "12 K";
      case 10000:
        return "10 K";
      case 8046.72:
        return "5 Mi";
      case 5000:
        return "5 K";

      default:
        return "";
    }
  }

  enum Distance {
    FIFTY_K = 50000,
    MARATHON = 42195,
    HALF_MARATHON = 21097.5,
    TEN_M = 16093.4,
    TWELVE_K = 12000,
    TEN_K = 10000,
    FIVE_M = 8046.72,
    FIVE_K = 5000,
  }

  function handleCurrentGoalDistanceChange(newGoalDistance: string) {
    switch (newGoalDistance) {
      case "50 K":
        setCurrentProfileState({
          ...currentProfileState,
          goalDistance: Distance.FIFTY_K,
        });
        break;
      case "Marathon":
        setCurrentProfileState({
          ...currentProfileState,
          goalDistance: Distance.MARATHON,
        });
        break;
      case "Half Marathon":
        setCurrentProfileState({
          ...currentProfileState,
          goalDistance: Distance.HALF_MARATHON,
        });
        break;
      case "10 Mi":
        setCurrentProfileState({
          ...currentProfileState,
          goalDistance: Distance.TEN_M,
        });
        break;
      case "12 K":
        setCurrentProfileState({
          ...currentProfileState,
          goalDistance: Distance.TWELVE_K,
        });
        break;
      case "10 K":
        setCurrentProfileState({
          ...currentProfileState,
          goalDistance: Distance.TEN_K,
        });
        break;
      case "5 Mi":
        setCurrentProfileState({
          ...currentProfileState,
          goalDistance: Distance.FIVE_M,
        });
        break;
      case "5 K":
        setCurrentProfileState({
          ...currentProfileState,
          goalDistance: Distance.FIVE_K,
        });
        break;

      default:
        setCurrentProfileState({
          ...currentProfileState,
          goalDistance: null,
        });
        break;
    }
  }

  function handleGoalTitle(newTitle: string) {
    setCurrentProfileState({
      ...currentProfileState,
      goalTitle: newTitle,
    });
  }

  function handleWeight(newWeight: string) {
    setCurrentProfileState({
      ...currentProfileState,
      weight: newWeight,
    });
  }

  function handleHeight(newHeight: string) {
    setCurrentProfileState({
      ...currentProfileState,
      height: newHeight,
    });
  }

  function handleAge(newAge: string) {
    setCurrentProfileState({
      ...currentProfileState,
      age: newAge,
    });
  }

  function handleGender(newGender: string) {
    setCurrentProfileState({
      ...currentProfileState,
      gender: newGender,
    });
  }

  function handleGoalDate(newDate: Date | null) {
    let adjustedDate = newDate;

    if (adjustedDate) {
      adjustedDate = convertCalendarDateToLocalTimezone(adjustedDate);
    }

    setCurrentProfileState({
      ...currentProfileState,
      goalDate: adjustedDate,
    });
  }

  function handleProductIdOverride(newDate: Date | null) {
    let adjustedDate = newDate;

    if (adjustedDate) {
      adjustedDate = convertCalendarDateToLocalTimezone(adjustedDate);
    }
    setCurrentProfileState({
      ...currentProfileState,
      productIdOverrideExpiresAt: adjustedDate,
    });
  }

  function handleGoalTime(newTime: string) {
    setCurrentProfileState({
      ...currentProfileState,
      goalTime: newTime,
    });
  }

  function resetFields() {
    setCurrentProfileState({ ...initProfileState });
  }

  useEffect(() => {
    initFields();
  }, [currentProfileState.trainingAttributes]);

  useEffect(() => {
    initTrainingAttributes();
    resetFields();
  }, [selectedTrainingProfile]);

  useEffect(() => {
    initTrainingAttributes();
  }, []);

  return {
    currentProfileState,
    handleGoalTitle,
    handleGoalDate,
    handleHeight,
    handleWeight,
    handleAge,
    handleGender,
    handleProductIdOverride,
    goalDistanceToString,
    handleCurrentGoalDistanceChange,
    handleGoalTime,
    handleCheck,
    handleSubmit,
    hasChanged,
  };
}

export default useProfile;
