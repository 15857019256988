import {
  IonButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { classNames } from "../../Utilities/classnames";
import Gear from "../../Assets/Gear.svg";

function Header({ hasScrolled }: { hasScrolled: boolean }) {
  const routerHook = useIonRouter();
  return (
    <IonHeader>
      <IonToolbar
        className={classNames(
          hasScrolled ? "border-b border-gray-200" : "",
          "pb-2 mt-2"
        )}
      >
        <IonTitle>Profile</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={() => routerHook.push("/profile/settings")}>
            <img src={Gear} alt="enable editing settings" />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
}

export default Header;
