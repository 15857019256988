import { Comment } from "../../../Api/Stream";
import ActivityDropdown from "../../../Coach/Components/ActivityDropdown";

interface ActivityFeedbackCommentDropdownInput {
  enableEditMode?: () => void;
  activateDeleteModal?: (reactionId: string) => void;
  comment: Comment;
}

function ActivityFeedbackCommentDropdown({
  enableEditMode,
  activateDeleteModal,
  comment,
}: ActivityFeedbackCommentDropdownInput) {
  return (
    <ActivityDropdown
      actions={[
        { key: "Edit", action: enableEditMode },
        {
          key: "Delete",
          action: () => activateDeleteModal?.(comment.id),
        },
      ]}
    />
  );
}

export default ActivityFeedbackCommentDropdown;
