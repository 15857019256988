import { MapIcon } from "@heroicons/react/outline";
import { useContext } from "react";
import { UnitPreference } from "../../../Api/User";
import { UserInfoContext } from "../../../Context/UserContext";

export default function AssignedDistanceInput({
  isDisabled,
  currentAssignedDistance,
  setCurrentAssignedDistance,
}: {
  isDisabled?: boolean;
  currentAssignedDistance: string;
  setCurrentAssignedDistance: (assignedDistance: string) => void;
}) {
  const { userInfo } = useContext(UserInfoContext);

  const metricSetting = userInfo?.profileMetricSetting;

  return (
    <div className="flex-grow">
      <label
        htmlFor="assigned-distance"
        className="block text-sm font-medium text-gray-700"
      >
        Assigned distance (
        {metricSetting === UnitPreference.IMPERIAL ? "mi" : "km"})
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <MapIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="assigned-distance"
          id="assigned-distance"
          className="block w-full pl-10 border-gray-300 rounded-md focus:ring-brand focus:border-brand sm:text-sm"
          value={currentAssignedDistance}
          onChange={(e) => {
            if (isDisabled) return;
            setCurrentAssignedDistance(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
