import { useEffect, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { uploadProfilePicture } from "../../../Api/ProfilePicture";
import { useUserInfo } from "../../../Context/UserContext";
import placeholder from "../../../Assets/placeholder_profile.png";
import { toast } from "react-toastify";
import { isMobile } from "../../../Utilities/isMobile";

function ProfilePicture() {
  const { userInfo } = useUserInfo();
  const [currentPhoto, setCurrentPhoto] = useState<ImageListType>([]);
  function onPhotoChange(imageList: ImageListType) {
    setCurrentPhoto(imageList);
  }

  useEffect(() => {
    if (currentPhoto.length > 0 && currentPhoto[0].file) {
      uploadProfilePicture(currentPhoto[0].file).then(() => {
        if (!isMobile) {
          toast("Profile picture have been saved successfully.", {
            position: "bottom-right",
            style: { backgroundColor: "black", color: "white" },
            className: "font-poppins",
          });
        }
      });
    }
  }, [currentPhoto]);

  return (
    <ImageUploading value={currentPhoto} onChange={onPhotoChange}>
      {({ onImageUpload }) => (
        <img
          onClick={onImageUpload}
          src={
            currentPhoto.length > 0
              ? currentPhoto[0].dataURL
              : userInfo?.profileImage ?? placeholder
          }
          alt="profile picture"
          className="h-[100px] w-[100px] rounded-[25px] mx-auto cursor-pointer object-cover"
        />
      )}
    </ImageUploading>
  );
}

export default ProfilePicture;
