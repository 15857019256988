import { ClockIcon } from "@heroicons/react/outline";

export default function AssignedDurationInput({
  isDisabled,
  currentAssignedDuration,
  setCurrentAssignedDuration,
}: {
  isDisabled?: boolean;
  currentAssignedDuration: string;
  setCurrentAssignedDuration: (assignedDuration: string) => void;
}) {
  return (
    <div className="flex-grow">
      <label
        htmlFor="assigned-duration"
        className="block text-sm font-medium text-gray-700"
      >
        Assigned duration
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <ClockIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="assigned-duration"
          id="assigned-duration"
          className="block w-full pl-10 border-gray-300 rounded-md focus:ring-brand focus:border-brand sm:text-sm"
          value={
            currentAssignedDuration === "0:00" ? "" : currentAssignedDuration
          }
          onChange={(e) => {
            if (isDisabled) return;
            setCurrentAssignedDuration(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
