import { useUserInfo } from "../../../../Context/UserContext";
import {
  fancyDecodeDuration,
  formatDistance,
  formatPaceFromSpeed,
} from "../../../../Utilities/FormatUtilties";

interface StatsRowInput {
  distance?: number | undefined | null;
  movingTime?: number | undefined | null;
}

function StatsRow({ distance, movingTime }: StatsRowInput) {
  const { userInfo } = useUserInfo();

  if (!userInfo) return <></>;

  if (!distance && !movingTime) return <></>;

  return (
    <div className="flex px-4 mt-3 text-left gap-9">
      {distance ? (
        <div className="flex flex-col">
          <p className="text-sm font-medium">
            {formatDistance(distance, userInfo.profileMetricSetting, true)}
          </p>
          <p className="mt-1.5 text-xs text-gray-500">Distance</p>
        </div>
      ) : (
        <></>
      )}

      {movingTime ? (
        <div className="flex flex-col">
          <p className="text-sm font-medium">
            {fancyDecodeDuration(movingTime)}
          </p>
          <p className="mt-1.5 text-xs text-gray-500">Time</p>
        </div>
      ) : (
        <></>
      )}

      {movingTime && distance ? (
        <div className="flex flex-col">
          <p className="text-sm font-medium">
            {formatPaceFromSpeed(
              distance / movingTime,
              userInfo.profileMetricSetting
            )}
          </p>
          <p className="mt-1.5 text-xs text-gray-500">Pace</p>
        </div>
      ) : (
        <></>
      )}
      <div></div>
    </div>
  );
}

export default StatsRow;
