import { useContext } from "react";
import { trainingEvent, TrainingEventStatus } from "../../Api/TrainingEvent";

import TodaysEvent from "./TodaysEvent";
import { UserInfoContext } from "../../Context/UserContext";
import DashboardContentStream from "./DashboardContentStream";
import { useLocation } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { IonRouterLink } from "@ionic/react";
import { isMobile } from "../../Utilities/isMobile";
import { PlusIcon } from "@heroicons/react/outline";
import { ModalContent } from "../../Pages/CoacheeDashboard";
import { useDashboardContentStreamOutput } from "../Hooks/useDashboardContentStream";

interface DashboardContentInput {
  events: trainingEvent[];
  refreshTrigger: number;
  setModalContent: React.Dispatch<React.SetStateAction<ModalContent>>;
  dashboardContentStreamHook: useDashboardContentStreamOutput;
}

export function getTodaysEvents(events: trainingEvent[]): trainingEvent | null {
  if (!events || events.length == 0) return null;

  let todaysEvent = null;
  const today = new Date();

  for (const event of events) {
    if (
      event.scheduledAt.getDate() === today.getDate() &&
      event.scheduledAt.getMonth() === today.getMonth() &&
      event.scheduledAt.getFullYear() === today.getFullYear()
    ) {
      todaysEvent = event;
      break;
    }
  }

  if (todaysEvent && todaysEvent.status !== TrainingEventStatus.DRAFT) {
    return todaysEvent;
  }
  return null;
}

function DashboardContent({
  events,
  setModalContent,
  dashboardContentStreamHook,
}: DashboardContentInput) {
  const { userInfo } = useContext(UserInfoContext);
  const location = useLocation();

  return (
    <>
      {userInfo && (
        <div className="mt-9">
          <div className="flex items-center justify-between mx-1 mb-4">
            <h3 className="text-lg font-semibold text-left text-black ">
              Today's Activity
            </h3>

            <IonRouterLink
              routerLink="/dashboard/upcoming"
              routerDirection="forward"
            >
              <div className="flex items-center">
                <p className="font-medium text-rose-500">Schedule</p>
                <ChevronRightIcon className="w-5 h-5 mt-0.5 text-rose-500" />
              </div>
            </IonRouterLink>
          </div>

          <TodaysEvent event={getTodaysEvents(events)} userInfo={userInfo} />
        </div>
      )}
      <>
        <div className="flex items-center justify-between gap-2 mx-2 mt-8 mb-2">
          <p className="text-lg font-semibold">
            {location.pathname.includes("upcoming")
              ? "Schedule"
              : "Training Log"}
          </p>
          {!isMobile && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setModalContent("new_post");
              }}
            >
              <div className="flex items-center gap-0.5">
                <p className="font-medium text-rose-500">Add Note</p>
                <PlusIcon className="w-4 h-4 text-rose-500" />
              </div>
            </button>
          )}
        </div>
        <div className="flex flex-col gap-4">
          <DashboardContentStream
            setModalContent={setModalContent}
            dashboardContentStreamHook={dashboardContentStreamHook}
          />
        </div>
      </>
    </>
  );
}

export default DashboardContent;
