import axios from "axios";
import Default from "../Default/Default";
import { encode } from "@googlemaps/polyline-codec";
import { trainingEvent } from "./TrainingEvent";

export type ActivityRoute = {
  points: ActivityPoint[];
  activity: ActivityMetadata;
  splits: ActivitySplit[];
  laps: ActivitySplit[];
};

export enum ActivitySource {
  PERFORM = "PERFORM",
  TERRA = "TERRA",
  STRAVA = "STRAVA",
}

export type ActivityMetadata = {
  id: string;
  name: number;
  startedAt: number;
  lengthSeconds: number;
  activityType: string;
  distanceInMeters: number | null;
  averageHeartRate: number | null;
  localizedTimeAndPlace: string | null;
  geoMapImage: string | null;
  averageSpeed: number | null;
  description: string;
  miniProfile: any;
  userTitle: string;
  movingTime: number;
  activitySource: ActivitySource;
  trainingEvent: trainingEvent | null;
  elevationGain: number | null;
};

export type ActivityPoint = {
  altitude: number | null;
  latitude: number | null;
  longitude: number | null;
  speed: number | null;
  heartRate: number | null;
  cadence: number | null;
  time: number;
  segmentId: number;
  distance: number | null;
};

export type ActivitySplit = {
  splitId: number;
  speed: number;
  elevationGain: number;
  distance: number;
  cadence: number;
  heartRate?: number;
};

export async function getActivityRoute(
  routeId: string
): Promise<ActivityRoute | null> {
  try {
    const response = await axios.get(`routes/${routeId}`);

    return response.data;
  } catch (e: any) {
    return null;
  }
}

export function generateActivityMapURL(points?: ActivityPoint[]) {
  let formattedPoints: any = [];

  if (points) {
    for (let i = 0; i < points.length; i++) {
      if (i % 10 === 0 && points[i].latitude && points[i].longitude) {
        const appendedElement = [points[i].latitude, points[i].longitude];

        formattedPoints = formattedPoints.concat([appendedElement]);
      }
    }

    const encodedString = encode(formattedPoints, 5);

    return (
      "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s-a+9ed4bd(-122.46589,37.77343),pin-s-b+000(-122.42816,37.75965),path-5+f44-0.5(" +
      encodedString +
      ")/auto/500x300?access_token=" +
      Default.mapboxToken
    );
  }
}
