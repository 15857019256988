import { ExternalAccount } from "."
import { trainingAttributes } from "../Api/TrainingAttribute"


export const externalAccountFromAttributes = (attr: trainingAttributes): ExternalAccount => (
  { 
    id: `strava-${attr.id}`,
    provider: 'Strava',
    hasPreferences: true,
    profileUrl: attr.stravaProfileUrl as string,
    stravaPreferences: {
      updatesEnabled: attr.stravaUpdatesEnabled,
      titleUpdatesEnabled: attr.stravaTitleUpdatesEnabled,
      streakUpdatesEnabled: attr.stravaStreakUpdatesEnabled,
      scoreUpdatesEnabled: attr.stravaScoreUpdatesEnabled,
      goalUpdatesEnabled: attr.stravaGoalUpdatesEnabled,
    },
    connectedAt: undefined,
  }
);
