import { useContext, useEffect, useState } from "react";
import { getUserInfo, isCoach } from "../Api/User";
import { UserInfoContext } from "../Context/UserContext";
import PendingStepsPage from "../Coachee/Components/Pending/PendingStepsPage";
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { useTrainingAttributes } from "../Context/TrainingAttributeContext";
import { isMobile } from "../Utilities/isMobile";
import DefaultWebFooter from "../Coach/Components/Footer/DefaultWebFooter";

function Pending() {
  const routerHook = useIonRouter();
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const [currentStep, setCurrentStep] = useState<number>();
  const { trainingAttributes } = useTrainingAttributes();

  useEffect(() => {
    async function handleUserInfo() {
      const response = await getUserInfo();
      if (response && response.userInfo) {
        if (!userInfo) {
          setUserInfo(response.userInfo);
        }

        if (trainingAttributes) {
          if (!currentStep) {
            setCurrentStep(trainingAttributes.onboardingProgress);
          }
          if (trainingAttributes.onboardingProgress >= 4) {
            if (userInfo && isCoach(userInfo) && !isMobile) {
              routerHook.push("/coach");
            } else {
              routerHook.push("/dashboard/training-log");
            }
          }
          return;
        }
      }
      if (!currentStep) {
        setCurrentStep(2);
      }
    }

    handleUserInfo();
  }, [currentStep, setUserInfo, trainingAttributes, userInfo]);

  return (
    <IonPage>
      <IonContent>
        {currentStep && <PendingStepsPage currentStep={currentStep} />}
      </IonContent>
      {!isMobile && <DefaultWebFooter />}
    </IonPage>
  );
}

export default Pending;
