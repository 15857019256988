import {Fragment, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {IonContent, IonPage} from "@ionic/react";
import {useUserInfo} from "../../Context/UserContext";
import {Dialog, Transition} from '@headlessui/react'
import {signOutOfSpotify, signOutOfStrava} from "../../Api/Settings";
import {MusicHeader} from "./MusicHeader";
import {MusicFooter} from "./MusicFooter";

function MusicAccounts() {
  const history = useHistory();
  const { userInfo } = useUserInfo();
  const [ confirmStravaDisconnect, setConfirmStravaDisconnect ] = useState(false);
  const [ confirmSpotifyDisconnect, setConfirmSpotifyDisconnect ] = useState(false);
  const stravaCancelButtonRef = useRef(null);
  const spotifyCancelButtonRef = useRef(null);

  useEffect(() => {
    if (!userInfo) {
      history.push("/sign-in");
    }
  }, [userInfo]);

  async function disconnectStrava() {
    await signOutOfStrava();
    window.location.href = "/sign-in?sourceApp=music";
  }

  async function disconnectSpotify() {
    await signOutOfSpotify();
    window.location.href = "/sign-in?sourceApp=music";
  }

  function StravaConfirmModal() {
    return (
        <Transition.Root show={confirmStravaDisconnect} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={spotifyCancelButtonRef} onClose={setConfirmStravaDisconnect}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">Disconnect Strava</h3>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">Are you sure you want to disconnect Strava? We will be unable to sync your music to
                              your activities.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => {
                            disconnectStrava();
                          }}
                      >
                        Disconnect
                      </button>
                      <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => setConfirmStravaDisconnect(false)}
                          ref={stravaCancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
    )
  }

  function SpotifyConfirmModal() {
    return (
        <Transition.Root show={confirmSpotifyDisconnect} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={spotifyCancelButtonRef} onClose={setConfirmSpotifyDisconnect}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">Disconnect Spotify</h3>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">Are you sure you want to disconnect Spotify? We will be unable to sync your music to
                              your activities.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => {
                            disconnectSpotify();
                          }}
                      >
                        Disconnect
                      </button>
                      <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => setConfirmSpotifyDisconnect(false)}
                          ref={spotifyCancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
    )
  }

  return (
    <IonPage>
      <IonContent forceOverscroll={false}>
        <div className="flex flex-col h-screen justify-between">
          <MusicHeader />
          <main>
            <div className="relative bg-white">
              <div className="mx-auto max-w-3xl">
                <div className="bg-white sm:rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Accounts</h3>
                    <div className="mt-5">
                      <div className="rounded-md bg-white border border-gray-200 px-6 py-5 sm:flex sm:items-center sm:justify-between">
                        <h4 className="sr-only">Spotify</h4>
                        <div className="sm:flex sm:items-center">
                          <svg className="h-8 w-auto sm:h-6 sm:flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="#1CD05D" viewBox="0 0 16 16"
                               aria-hidden="true">
                            <path
                                d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.669 11.538a.498.498 0 0 1-.686.165c-1.879-1.147-4.243-1.407-7.028-.77a.499.499 0 0 1-.222-.973c3.048-.696 5.662-.397 7.77.892a.5.5 0 0 1 .166.686zm.979-2.178a.624.624 0 0 1-.858.205c-2.15-1.321-5.428-1.704-7.972-.932a.625.625 0 0 1-.362-1.194c2.905-.881 6.517-.454 8.986 1.063a.624.624 0 0 1 .206.858zm.084-2.268C10.154 5.56 5.9 5.419 3.438 6.166a.748.748 0 1 1-.434-1.432c2.825-.857 7.523-.692 10.492 1.07a.747.747 0 1 1-.764 1.288z"/>
                          </svg>
                          <div className="mt-3 sm:mt-0 sm:ml-4">
                            <div className="text-sm font-medium text-gray-900">Spotify</div>
                          </div>
                        </div>

                        {/*State for Disconnect */}
                        {userInfo && userInfo.isSpotifyAuthenticated &&
                        <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                          <button type="button"
                                  onClick={() => { history.push("/music/settings-spotify"); }}
                                  className="inline-flex items-center rounded-md border border-gray-200 bg-white mr-2 px-4 py-2 font-medium text-gray-700 shadow-sm hover:border-gray-400 sm:text-sm">Settings
                          </button>
                          <button
                              onClick={() => { setConfirmSpotifyDisconnect(true); }}
                              type="button" className="inline-flex items-center rounded-md border border-gray-200 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:border-gray-400 sm:text-sm">Disconnect</button>
                        </div>}

                       {/*State for Connect */}
                        {userInfo && !userInfo.isSpotifyAuthenticated &&
                        <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                          <button type="button" onClick={() => {
                            const url =
                                "https://api.joinperform.com/api/sign-in?uuid=" +
                                userInfo?.token +
                                "&redir=https://api.joinperform.com/api/server/authenticate/spotify?auth_spotify=true";
                            window.location.href = url;
                          }}
                                  className="inline-flex items-center rounded-md border border-gray-200 bg-gradient-to-r from-pink-500 to-rose-500 px-4 py-2 font-medium text-white shadow-sm hover:to-rose-600 hover:from-pink-600 sm:text-sm">Connect
                          </button>
                        </div>}
                      </div>
                    </div>
                    <div className="mt-5">
                      <div className="rounded-md bg-white border border-gray-200 px-6 py-5 sm:flex sm:items-center sm:justify-between">
                        <h4 className="sr-only">Strava</h4>
                        <div className="sm:flex sm:items-center">
                          <svg className="h-8 w-auto sm:h-6 sm:flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="#F44903" aria-hidden="true"
                               viewBox="0 0 512 512">
                            <path d="M226.8,31L93.8,287.6h78.4l54.7-102l54.2,102h77.8L226.8,31z M358.8,287.6l-38.6,77.5L281,287.6h-59.4L320.2,481l98-193.4
                        H358.8z"/>
                          </svg>

                          <div className="mt-3 sm:mt-0 sm:ml-4">
                            <div className="text-sm font-medium text-gray-900">Strava</div>
                          </div>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                          {/*State for Disconnect */}
                          {userInfo && userInfo.isStravaAuthenticated &&
                              <>
                                <button type="button"
                                        onClick={() => {          history.push("/music/settings-strava"); }}
                                        className="inline-flex items-center rounded-md border border-gray-200 bg-white mr-2 px-4 py-2 font-medium text-gray-700 shadow-sm hover:border-gray-400 sm:text-sm">Settings
                                </button>
                                <button
                                    onClick={() => { setConfirmStravaDisconnect(true); }}
                                    type="button" className="inline-flex items-center rounded-md border border-gray-200 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:border-gray-400 sm:text-sm">Disconnect</button>
                              </>
                          }

                          {/*State for Connect */}
                          {userInfo && !userInfo.isStravaAuthenticated &&
                                <button type="button" onClick={() => {
                                  const url =
                                      process.env.REACT_APP_BASE_URL_API + "/sign-in?uuid=" +
                                      userInfo?.token +
                                      "&redir=" + process.env.REACT_APP_BASE_URL_API + "/server/authenticate/strava-sync?auth_strava=true&prevent_redir=true";
                                  window.location.href = url;
                                }}
                                        className="inline-flex items-center rounded-md border border-gray-200 bg-gradient-to-r from-pink-500 to-rose-500 px-4 py-2 font-medium text-white shadow-sm hover:to-rose-600 hover:from-pink-600 sm:text-sm">Connect
                                </button>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <StravaConfirmModal/>
            <SpotifyConfirmModal/>
          </main>
          <MusicFooter/>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default MusicAccounts;
