import StreamActivity from "./StreamActivity";
import { useDashboardContentStreamOutput } from "../Hooks/useDashboardContentStream";
import EmptyState from "./EmptyState";
import DeletePostModal from "./DeletePostModal";
import { ModalContent } from "../../Pages/CoacheeDashboard";
import Spinner from "./Spinner";

function DashboardContentStream({
  setModalContent,
  dashboardContentStreamHook,
}: {
  setModalContent: React.Dispatch<React.SetStateAction<ModalContent>>;
  dashboardContentStreamHook: useDashboardContentStreamOutput;
}) {
  const {
    feedItems,
    streamClient,
    paginationRef,
    deleteFeedItem,
    editFeedItem,
    activateDeleteModal,
    deactivateDeleteModal,
    postIdToBeDeleted,
  } = dashboardContentStreamHook;

  return (
    <>
      {streamClient ? (
        <div className="mx-2 mt-2">
          {feedItems ? (
            <>
              {feedItems.map((feedItem, feedItemIdx) => (
                <div key={feedItemIdx}>
                  <StreamActivity
                    index={feedItemIdx}
                    activitiesLength={feedItems.length}
                    activity={feedItem}
                    streamClient={streamClient}
                    setModalContent={setModalContent}
                    editFeedItem={editFeedItem}
                    activateDeleteModal={activateDeleteModal}
                  />
                </div>
              ))}
            </>
          ) : (
            <Spinner />
          )}
          <div ref={paginationRef}>
            {feedItems && feedItems.length === 0 && <EmptyState />}
          </div>
        </div>
      ) : null}
      <DeletePostModal
        deactivatePostModal={deactivateDeleteModal}
        handleDeletePost={deleteFeedItem}
        showDeleteModal={postIdToBeDeleted !== undefined}
      />
    </>
  );
}

export default DashboardContentStream;
