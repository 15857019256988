import { toast } from "react-toastify";
import { Step } from "../Api/Step";
import {
  createTrainingEvent,
  createTrainingEventInput,
  trainingEvent,
  TrainingEventStatus,
  TrainingEventType,
} from "../Api/TrainingEvent";
import { trainingProfile } from "../Api/TrainingProfile";

import {
  datesAreOnTheSameDay,
  formatCalendarDate,
} from "../Utilities/DateUtilities";
import { titleCase } from "../Utilities/FormatUtilties";

export type CalendarDay = {
  date: Date;
  formattedDate: string; // 2021-12-27
  event: trainingEvent | null;
  isCurrentMonth?: boolean;
  isToday?: boolean;
  isSelected?: boolean;
  otherEvents?: trainingEvent[];
};

export function generateCalendarMonth(
    month: number,
    year: number,
    events: trainingEvent[]
): CalendarDay[] {
  const calendarMonth: CalendarDay[] = [];

  const firstDayOfTheMonth = new Date(year, month, 1);

  const prefixCount = (firstDayOfTheMonth.getDay() || 7) - 1;

  //   get prefix days
  for (let i = 1; i <= prefixCount; i++) {
    const prefixDate = new Date(year, month, 1 - i);
    const formattedDate = formatCalendarDate(prefixDate);

    const matchedEvents = events
        .filter((event) => datesAreOnTheSameDay(event.scheduledAt, prefixDate))
        .sort((a, b) => (a.id < b.id ? 1 : -1));

    const prefixDay: CalendarDay = {
      date: prefixDate,
      formattedDate: formattedDate,
      event: null,
    };

    if (matchedEvents.length > 0) prefixDay.event = matchedEvents[0];

    if (matchedEvents.length > 1)
      prefixDay.otherEvents = matchedEvents
          .slice(1)
          .filter((event) => event.activity !== null);

    calendarMonth.unshift(prefixDay);
  }

  //   get month days
  const dayCount = new Date(year, month + 1, 0).getDate();

  for (let i = 1; i <= dayCount; i++) {
    const monthDate = new Date(year, month, i);
    const formattedDate = formatCalendarDate(monthDate);

    let matchedEvents = events.filter((event) =>
        datesAreOnTheSameDay(event.scheduledAt, monthDate)
    );

    matchedEvents = matchedEvents.sort((a, b) => (a.id < b.id ? 1 : -1));

    const monthDay: CalendarDay = {
      date: monthDate,
      formattedDate: formattedDate,
      event: null,
      isCurrentMonth: true,
      isToday: false,
    };

    if (matchedEvents.length > 0) monthDay.event = matchedEvents[0];
    if (matchedEvents.length > 1)
      monthDay.otherEvents = matchedEvents
          .slice(1)
          .filter((event) => event.activity !== null);

    if (datesAreOnTheSameDay(monthDate, new Date())) monthDay.isToday = true;

    calendarMonth.push(monthDay);
  }

  //   get suffix days
  const suffixCount = 42 - dayCount - prefixCount;

  for (let i = 1; i <= suffixCount; i++) {
    const suffixDate = new Date(year, month, dayCount + i);
    const formattedDate = formatCalendarDate(suffixDate);

    const matchedEvents = events
        .filter((event) => datesAreOnTheSameDay(event.scheduledAt, suffixDate))
        .sort((a, b) => (a.id < b.id ? 1 : -1));

    const suffixDay: CalendarDay = {
      date: suffixDate,
      formattedDate: formattedDate,
      event: null,
    };

    if (matchedEvents.length > 0) suffixDay.event = matchedEvents[0];
    if (matchedEvents.length > 1)
      suffixDay.otherEvents = matchedEvents
          .slice(1)
          .filter((event) => event.activity !== null);

    calendarMonth.push(suffixDay);
  }

  return calendarMonth;
}

export async function calendarAdd(
    selectedTrainingProfile: trainingProfile,
    trainingEvents: trainingEvent[],
    type: TrainingEventType = TrainingEventType.BASE,
    scheduledAt?: Date,
    title?: string,
    description?: string[],
    assignedDistance?: string,
    assignedDuration?: string,
    message?: string,
    sportsType?: string,
    steps?: Step[]
) {
  let typeTitle: string = TrainingEventType[type];

  typeTitle = titleCase(typeTitle) + " Run";

  if (type === TrainingEventType.REST) {
    typeTitle = "Rest Day";
  } else if (type === TrainingEventType.CROSS_TRAINING) {
    typeTitle = "Cross Training Day";
  } else if (type === TrainingEventType.WALK) {
    typeTitle = "Walk Day";
  }

  const payload: createTrainingEventInput = {
    profile: selectedTrainingProfile.miniProfile.id,
    description: description ?? [""],
    status: TrainingEventStatus.DRAFT,
    title: title ?? typeTitle,
    message: message ?? "",
    trainingEventType: type,
    scheduledAt: scheduledAt,
    assignedDistance: assignedDistance,
    assignedDuration: assignedDuration,
    sportsType: sportsType,
    steps: steps ?? [],
  };

  const response = await createTrainingEvent(payload);

  if (response) {
    const newTrainingEvent: trainingEvent = {
      ...response,
      actualDistance: response.actualDistance ?? 0,
      actualDuration: response.actualDuration ?? 0,
      assignedDistance: response.assignedDistance ?? "",
      assignedDuration: response.assignedDuration ?? "",
      status: response.status,
      isInEditMode: false,
      activity: null,
      completionRatio: null,
      postActivityFeedback: null,
      postActivityFeedbackSentAt: null,
      completedAt: null,
      completionRatioFeedback: [],
    };

    const newTrainingEvents = [newTrainingEvent].concat(trainingEvents);

    toast("Event successfully created");

    return {
      new: newTrainingEvent,
      all: newTrainingEvents,
    };
  } else {
    alert(
        "Add operation could not be completed, please check if the athlete has at-least one unblocked day"
    );
  }
}

// given a list of CalendarDays, return a list of their trainingEvents
export function getTrainingEventsFromCalendarDays(
    calendarDays: CalendarDay[]
): trainingEvent[] {
  return calendarDays.reduce((acc, day) => {
    if (day.event) acc.push(day.event);
    return acc;
  }, [] as trainingEvent[]);
}
