import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  getAllTrainingEventTemplates,
  getMyTrainingEventTemplates,
  TrainingEventTemplate,
} from "../Api/TrainingEventTemplate";

export interface TemplateContextInput {
  ownTemplates: TrainingEventTemplate[] | null;
  sharedTemplates: TrainingEventTemplate[] | null;
  initTemplates: () => void;
}

const initialTemplateContext: TemplateContextInput = {
  ownTemplates: null,
  sharedTemplates: null,
  initTemplates: () => {
    // do nothing
  },
};

export const TemplateContext = createContext<TemplateContextInput>(
  initialTemplateContext
);

export function useTemplateContext() {
  const context = useContext(TemplateContext);
  if (!context && typeof window !== "undefined") {
    throw new Error("useTemplateContext must be used within a TemplateContext");
  }
  return context;
}

export function TemplateContextProvider({
  children,
  injectedValue,
}: {
  children: JSX.Element;
  injectedValue?: TemplateContextInput;
}) {
  const location = useLocation();

  async function initTemplates() {
    if (!location.pathname.includes("coach")) return;

    const templates = await getMyTrainingEventTemplates();
    setOwnTemplates(templates);

    const sharedTemplates = await getAllTrainingEventTemplates();
    setSharedTemplates(sharedTemplates);
  }

  const [ownTemplates, setOwnTemplates] = useState<
    TrainingEventTemplate[] | null
  >(null);
  const [sharedTemplates, setSharedTemplates] = useState<
    TrainingEventTemplate[] | null
  >(null);

  useEffect(() => {
    initTemplates();
  }, []);

  const value = useMemo(
    () => ({
      ownTemplates,
      sharedTemplates,
      initTemplates,
    }),
    [ownTemplates, sharedTemplates]
  );

  return (
    <TemplateContext.Provider value={injectedValue ?? value}>
      {children}
    </TemplateContext.Provider>
  );
}

export function getAllTemplates(
  ownTemplates: TrainingEventTemplate[] | null,
  sharedTemplates: TrainingEventTemplate[] | null
): TrainingEventTemplate[] {
  // ensure no duplicates
  const allTemplates = [...(ownTemplates || []), ...(sharedTemplates || [])];
  return allTemplates.filter((template, index) => {
    return allTemplates.findIndex((t) => t.id === template.id) === index;
  });
}
