import { trainingAttributes } from "../../Api/TrainingAttribute";
import { UserInfo } from "../../Api/User";
import CoacheeHeader from "./CoacheeHeader";

interface StepperInput {
  count: number;
  stepperStyle?: string;
  userInfo?: UserInfo;
  trainingAttributes?: trainingAttributes;
}

function previousStep() {
  return <div className="w-2 h-2 rounded-full bg-brand"></div>;
}

function currentStep() {
  return (
    <div className="w-2 h-2 rounded-full bg-brand outline-brand/30 outline outline-4"></div>
  );
}

function upcomingStep() {
  return <div className="w-2 h-2 rounded-full bg-neutral-300"></div>;
}

function Stepper({
  count,
  stepperStyle,
  userInfo,
  trainingAttributes,
}: StepperInput) {
  let steps = [];

  for (let i = 1; i <= 3; i++) {
    if (i < count) {
      steps.push(previousStep());
    } else if (i == count) {
      steps.push(currentStep());
    } else {
      steps.push(upcomingStep());
    }
  }

  return (
    <div>
      <CoacheeHeader trainingAttributes={trainingAttributes} />
      <div className={stepperStyle}>
        <div className="flex items-center mt-10 space-x-3">
          <p className="text-xs font-semibold"> Step {count} of 3</p>
          {steps.map((step, index) => (
            <div key={index}>{step}</div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Stepper;
