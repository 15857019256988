import { useLocation } from "react-router-dom";
import { getGenericZoneName, Step } from "../../../../Api/Step";
import { UnitPreference } from "../../../../Api/User";
import { useUserInfo } from "../../../../Context/UserContext";
import {
  formatDistance,
  fancyDecodeDuration,
  formatPaceFromSpeed,
} from "../../../../Utilities/FormatUtilties";
import Tooltip from "../../../../Utilities/Components/Tooltip";
import { trainingProfile } from "../../../../Api/TrainingProfile";

interface StepCell {
  enabled: boolean;
  value: string | JSX.Element;
  label: string;
}

export default function StepCells({
  step,
  selectedTrainingProfile,
}: {
  step: Step;
  selectedTrainingProfile?: trainingProfile;
}) {
  const { userInfo } = useUserInfo();
  const attributes = selectedTrainingProfile
    ? selectedTrainingProfile.trainingAttribute
    : undefined;
  const location = useLocation();
  const userZones = attributes?.paceZones ?? [];

  const isCoachSide = location.pathname.includes("coach");

  function generatePaces() {
    const paces: string[] = [];

    const minZone = step.intensityMinZone;
    const minIntensityFraction =
      step.intensityMinZoneFraction !== undefined
        ? step.intensityMinZoneFraction
        : 1;
    let adjustedMinZoneSpeed = -1;
    if (minZone) {
      const minZoneInUserZones = userZones.find(
        (zone) => zone.zone === minZone
      );
      if (minZoneInUserZones && minZoneInUserZones.averageSpeed) {
        adjustedMinZoneSpeed =
          minZoneInUserZones.averageSpeed * minIntensityFraction;
        const formattedPace = formatPaceFromSpeed(
          adjustedMinZoneSpeed,
          userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL
        );
        if (formattedPace) {
          paces.push(formattedPace);
        } else {
          paces.push(
            `Zone ${minZone}${
              minIntensityFraction !== 1
                ? ` (${minIntensityFraction * 100}%)`
                : ""
            }`
          );
        }
      } else {
        paces.push(
          `Zone ${minZone}${
            minIntensityFraction !== 1
              ? ` (${minIntensityFraction * 100}%)`
              : ""
          }`
        );
      }
    }

    const maxZone = step.intensityMaxZone;
    const maxIntensityFraction =
      step.intensityMaxZoneFraction !== undefined
        ? step.intensityMaxZoneFraction
        : 1;
    if (maxZone) {
      const maxZoneInUserZones = userZones.find(
        (zone) => zone.zone === maxZone
      );
      if (maxZoneInUserZones && maxZoneInUserZones.averageSpeed) {
        const adjustedMaxZoneSpeed =
          maxZoneInUserZones.averageSpeed * maxIntensityFraction;
        const formattedPace = formatPaceFromSpeed(
          adjustedMaxZoneSpeed,
          userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL
        );
        if (formattedPace) {
          if (adjustedMaxZoneSpeed < adjustedMinZoneSpeed) {
            // move formatted pace to the front
            paces.unshift(formattedPace);
          } else {
            paces.push(formattedPace);
          }
        } else {
          paces.push(
            `Zone ${maxZone}${
              maxIntensityFraction !== 1
                ? ` (${Math.round(maxIntensityFraction * 100)}%)`
                : ""
            }`
          );
        }
      } else {
        paces.push(
          `Zone ${maxZone}${
            maxIntensityFraction !== 1
              ? ` (${Math.round(maxIntensityFraction * 100)}%)`
              : ""
          }`
        );
      }
    }

    const content =
      paces.join(" - ") +
      ` ${
        userInfo?.profileMetricSetting === UnitPreference.IMPERIAL
          ? " /mi"
          : " /km"
      }`;

    return content;
  }

  function minZoneString(): string | undefined {
    switch (step.intensityMinZone) {
      case 1:
        return "Easy";
      case 2:
        return "Easy Moderate";
      case 3:
        return "Medium";
      case 4:
        return "Medium Hard";
      case 5:
        return "Hard";
      default:
        return "";
    }
    // if (step.intensityMinZone) {
    //   if (getGenericZoneName(step.intensityMinZone)) {
    //     return `${getGenericZoneName(step.intensityMinZone)} ${
    //       step.intensityMinZoneFraction !== undefined &&
    //       step.intensityMinZoneFraction !== 1
    //         ? `(${Math.round(step.intensityMinZoneFraction * 100)}%)`
    //         : ""
    //     }`;
    //   }
    //   return `Zone ${step.intensityMinZone}${
    //     step.intensityMinZoneFraction !== undefined &&
    //     step.intensityMinZoneFraction !== 1
    //       ? ` (${Math.round(step.intensityMinZoneFraction * 100)}%)`
    //       : ""
    //   }`;
    // }
  }

  function maxZoneString(): string | undefined {
    if (step.intensityMaxZone) {
      if (getGenericZoneName(step.intensityMaxZone)) {
        return `${getGenericZoneName(step.intensityMaxZone)} ${
          step.intensityMaxZoneFraction !== undefined &&
          step.intensityMaxZoneFraction !== 1
            ? `(${Math.round(step.intensityMaxZoneFraction * 100)}%)`
            : ""
        }`;
      }
      return `Zone ${step.intensityMaxZone}${
        step.intensityMaxZoneFraction !== undefined &&
        step.intensityMaxZoneFraction !== 1
          ? ` (${Math.round(step.intensityMaxZoneFraction * 100)}%)`
          : ""
      }`;
    }
  }

  const Cell = ({
    value,
    label,
  }: {
    value: string | JSX.Element;
    label: string;
  }) => {
    return (
      <div className="space-y-1 flex-grow w-1/2">
        <p className="text-neutral-900 text-[16px] leading-[24px] font-medium">
          {value}
        </p>
        <p className="text-neutral-500 text-[12px] leading-[20px] font-medium">
          {label}
        </p>
      </div>
    );
  };

  const minZoneInUserZones = userZones.find(
    (zone) => zone.zone === step.intensityMinZone
  );

  const minZoneSpeed =
    (minZoneInUserZones?.averageSpeed ?? 0) *
    (step.intensityMinZoneFraction ?? 1);

  const minZoneTooltipMessage =
    step.intensityMinZone && minZoneSpeed ? (
      <p>
        {`${
          selectedTrainingProfile
            ? `${selectedTrainingProfile.miniProfile.name.split(" ")[0]}'s `
            : ""
        } ` +
          "Zone " +
          step.intensityMinZone +
          (step.intensityMinZoneFraction !== undefined &&
          step.intensityMinZoneFraction !== 1
            ? ` (${Math.round(step.intensityMinZoneFraction * 100)}%)`
            : "") +
          " is " +
          formatPaceFromSpeed(
            minZoneSpeed,
            userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL
          ) +
          (userInfo?.profileMetricSetting === UnitPreference.IMPERIAL
            ? " /mi"
            : " /km")}
      </p>
    ) : undefined;

  const maxZoneInUserZones = userZones.find(
    (zone) => zone.zone === step.intensityMaxZone
  );

  const maxZoneSpeed =
    (maxZoneInUserZones?.averageSpeed ?? 0) *
    (step.intensityMaxZoneFraction ?? 1);

  const maxZoneTooltipMessage =
    step.intensityMaxZone && maxZoneSpeed ? (
      <p>
        {`${
          selectedTrainingProfile
            ? `${selectedTrainingProfile.miniProfile.name.split(" ")[0]}'s `
            : ""
        } ` +
          "Zone " +
          step.intensityMaxZone +
          (step.intensityMaxZoneFraction !== undefined &&
          step.intensityMaxZoneFraction !== 1
            ? ` (${Math.round(step.intensityMaxZoneFraction * 100)}%)`
            : "") +
          " is " +
          formatPaceFromSpeed(
            maxZoneSpeed,
            userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL
          ) +
          (userInfo?.profileMetricSetting === UnitPreference.IMPERIAL
            ? " /mi"
            : " /km")}
      </p>
    ) : undefined;

  const cells: StepCell[] = [
    {
      enabled:
        step.assignedDistance !== undefined &&
        formatDistance(
          step.assignedDistance,
          userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL
        ) !== "",
      value: formatDistance(
        step.assignedDistance ?? 0,
        userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL,
        true,
        false,
        true,
        2
      ).replace("miles", "mi"),
      label: "Distance",
    },
    {
      enabled: step.stepOption !== undefined,
      // value: isCoachSide ? (
      value: (
          <p className="flex flex-wrap items-center gap-1">
            <Tooltip
                content={
                  selectedTrainingProfile ? minZoneTooltipMessage : undefined
                }
                display={
                  <span className="text-neutral-900 text-[16px] leading-[24px] font-medium">
                  {step.stepOption === 'Other' ? `Other: ${step.stepOtherOption}` : step.stepOption}
                </span>
                }
                isCentered={false}
            />
          </p>
      ),
      label: "Option",
    },
    {
      enabled: step.assignedDuration !== undefined && step.assignedDuration > 0,
      value: fancyDecodeDuration(step.assignedDuration ?? 0),
      label: "Duration",
    },
    {
      enabled:
        step.intensityMinZone !== undefined &&
        step.intensityMaxZone !== undefined,
      // value: isCoachSide ? () : generatePaces()
      value: (
          <>
            <p className="flex items-center gap-1">
              <Tooltip
                  content={
                    selectedTrainingProfile ? minZoneTooltipMessage : undefined
                  }
                  display={
                    <span className="text-neutral-900 text-[16px] leading-[24px] font-medium">
                  {minZoneString()}
                </span>
                  }
                  isCentered={false}
              />
              {/*<span className="text-neutral-900 text-[16px] leading-[24px] font-medium">*/}
              {/*  -*/}
              {/*</span>*/}
              {/*<Tooltip*/}
              {/*  content={*/}
              {/*    selectedTrainingProfile ? maxZoneTooltipMessage : undefined*/}
              {/*  }*/}
              {/*  display={*/}
              {/*    <span className="text-neutral-900 text-[16px] leading-[24px] font-medium">*/}
              {/*      {maxZoneString()}*/}
              {/*    </span>*/}
              {/*  }*/}
              {/*  isCentered={false}*/}
              {/*/>*/}
            </p>
          </>
      ),

      label: "Intensity Target",
    },
    {
      enabled: !!step.intensityMinZone && !step.intensityMaxZone,
      // value: isCoachSide ? () : generatePaces()
      value: (
          <>
            <p className="flex items-center gap-1">
              <Tooltip
                  content={
                    selectedTrainingProfile ? minZoneTooltipMessage : undefined
                  }
                  display={
                    <span className="text-neutral-900 text-[16px] leading-[24px] font-medium">
                  {minZoneString()}
                </span>
                  }
                  isCentered={false}
              />
            </p>
          </>
      ),
      label: "Intensity",
    },
  ];

  const enabledCells = cells.filter((cell) => cell.enabled);
  const topTwoCells = enabledCells.slice(0, 2);

  return (
    <div className="flex w-full items-start gap-3.5">
      {topTwoCells.map((cell, index) => (
        <Cell key={index} value={cell.value} label={cell.label} />
      ))}
    </div>
  );
}
