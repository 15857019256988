import axios from 'axios'
import { PaginationResponse } from './index'
import { AthleteOrderBy, OrderDirection } from '../Coach/Hooks/useAdminFilter'

export type Athlete = {
    id: number
    idHash: string
    name: string
    firstName: string
    lastName: string
    email: string,
    profileImage: string | null
    onboardingProgress: number
    onboardingProgressLabel: string
    dailyUpdatesEnabled: boolean
    created: string
    coachId: number
    status: string
    feedbacks: string[] | null
    productDescription: string | null
}

export type AthletesSearch = {
    search: string | null
    perPage: number
    page: number
    toggleTest: boolean | number
    onboardingProgress: string | null
    orderBy: AthleteOrderBy
    orderDir: OrderDirection
}

export const getAthletes = async (params: AthletesSearch): Promise<PaginationResponse<Athlete>> => {
    return new Promise((resolve, reject) => {
        axios.get('athletes', {params})
            .then(({data}) => resolve(data as unknown as PaginationResponse<Athlete>))
            .catch((error) => reject(error))
    })
}
