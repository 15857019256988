import { IonContent, IonPage, IonRouterLink, isPlatform } from "@ionic/react";
import Back from "../../../Assets/Back.svg";
import TerraConnect from "../../../Assets/TerraConnect.svg";
import { isMobile } from "../../../Utilities/isMobile";
import { useTrainingAttributes } from "../../../Context/TrainingAttributeContext";
import { isFree } from "../../../Api/TrainingAttribute";
import { track, TrackingEventType } from "../../../Api/Tracking";
import { getTerraSessionId } from "../../../Api/Terra";
import { Browser } from "@capacitor/browser";
import SettingsSubheader from "../ProfileSettings/SettingsSubheader";

export default function ConnectTerra() {
  const { trainingAttributes } = useTrainingAttributes();

  const handleConnectViaTerra = async () => {
    if (trainingAttributes && isFree(trainingAttributes)) {
      alert(
        "Connecting Garmin, Peloton, Polar, and other accounts requires a paid subscription."
      );
      track(
        TrackingEventType.TRACKING_APP_WEB_FREE_USER_ATTEMPTED_TO_CONNECT_OTHER_ACCOUNTS,
        "Free user attempted to connect Terra",
        "profiles"
      );
      return;
    }
    const sessionId = await getTerraSessionId();
    if (!sessionId) return;
    const redirectUrl = `https://widget.tryterra.co/session/${sessionId}`;
    const url =
      "https://app.joinperform.com/redirect-url?redir=" +
      encodeURI(redirectUrl);
    if (isPlatform("capacitor")) {
      Browser.addListener("browserFinished", () => {
        window.location.reload();
      });
      await Browser.open({ url });
    } else {
      window.location.href = url;
    }
  };

  return (
    <IonPage>
      <SettingsSubheader title="Connect to Different Account" />
      <IonContent>
        <div className="max-w-xl h-full sm:h-fit mx-auto sm:mt-4 lg:mt-20 sm:px-6 lg:px-8">
          {!isMobile && (
            <IonRouterLink
              routerLink="/profile/settings"
              routerDirection="back"
            >
              <button className="items-center self-start hidden mb-12 ml-6 space-x-1 text-xs text-gray-400 rounded md:flex hover:text-gray-500">
                <img src={Back} alt="Back" className="w-auto h-4" />
                <p>Back</p>
              </button>
            </IonRouterLink>
          )}
          <div className="p-4 bg-white border-t-1 border-neutral-100 flex flex-col h-full">
            <div className="flex-grow flex flex-col items-center justify-center">
              <img src={TerraConnect} alt="connect to strava" />
              <h2 className="font-semibold text-[24px] leading-[32px] tracking-[-0.02em] mb-[2px] mt-16">
                Connect A Different Account
              </h2>
              <p className="text-[18px] text-center leading-[28px] text-neutral-500 mt-2">
                Connect an account so Runzy can track your running and keep
                everything up to date in real time.
              </p>
            </div>
            <div className="flex-grow-0 flex justify-center items-end">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleConnectViaTerra();
                }}
                className="w-full py-[10px] mt-4 rounded-[200px] text-white text-[16px] font-medium bg-primary-500 disabled:bg-neutral-100 disabled:text-neutral-500 disabled:cursor-not-allowed"
              >
                Connect
              </button>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
