import DOMPurify from 'dompurify'

import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {updatePassword} from "../Api/User";

import RunzyLogo from "./../Assets/RunzyLogo.svg";
import {IonContent, IonPage} from "@ionic/react";

function ResetPassword() {
  const { token } = useParams<{ token: string }>();
  const [password, setPassword] = useState<string>("");
  const [passwordRepeated, setPasswordRepeated] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);


  useEffect(() => {
    if (password === passwordRepeated) {
      setIsLoading(false);
    }
  }, [password, passwordRepeated]);

  async function handleResetPassword(e: any) {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(null);
    if (password != passwordRepeated) {
      setErrorMessage("Your passwords do not match");
      return;
    }
    const passwordResponse = await updatePassword(token, password);
    setIsLoading(false);
    if (!passwordResponse) {
      setErrorMessage("Something went wrong. Please try again later.");
    } else {
      if (passwordResponse.errorMessage) {
        setErrorMessage(passwordResponse.errorMessage);
        return;
      }
      setIsSuccessful(true);
    }
  }

  return (
    <IonPage>
      <IonContent>
        <div className="flex h-screen flex-col min-h-full py-12 sm:px-6 lg:px-8 bg-[#F9FAFB]">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <a href="/"><img
              className="w-auto h-8 mx-auto mt-10"
              src={RunzyLogo}
              alt="Runzy"
            /></a>
            <div className="mt-10 text-center">
              <h1 className="text-3xl font-semibold cursor-pointer">
                Pick your password
              </h1>
            </div>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            {isSuccessful ? (
                <div
                    className="relative py-3 my-3 text-xs text-center text-slate-700 bg-slate-100 border border-slate-400 rounded"
                    role="alert"
                >
                  <p className="text-center">Your password was successfully reset. Sign back in to continue.</p>
                </div>

            ) : (
            <div className="px-4 py-8 bg-white sm:rounded-lg sm:px-10 drop-shadow-md">
              <form className="space-y-6">
                {errorMessage && (
                  <div
                    className="relative p-3 px-8 my-3 text-xs text-center text-red-700 bg-red-100 border border-red-400 rounded alert"
                    role="alert"
                  >
                    <strong className="font-bold">Error: </strong>
                    <span className="block sm:inline" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(errorMessage)}}></span>
                  </div>
                )}
                <div>
                  <label
                    htmlFor="password"
                    className="block pl-2 text-sm font-medium text-left text-gray-700"
                  >
                    New password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-brand focus:border-brand sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block pl-2 text-sm font-medium text-left text-gray-700"
                  >
                    Repeat new password
                  </label>
                  <div className="mt-1">
                    <input
                      id="passwordRepeated"
                      name="passwordRepeated"
                      type="password"
                      required
                      onChange={(e) => setPasswordRepeated(e.target.value)}
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-brand focus:border-brand sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                    onClick={(e) => handleResetPassword(e)}
                  >
                    Update password
                  </button>
                </div>
              </form>
            </div>)}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default ResetPassword;
