import {Fragment, useContext, useEffect, useRef, useState} from "react";
import {track, TrackingEventType} from "../Api/Tracking";
import {classNames} from "../Utilities/classnames";
import {CheckCircleIcon, XIcon} from "@heroicons/react/solid";
import {CalendlyEventListener, InlineWidget} from "react-calendly";
import ReactGA from "react-ga";
import {Dialog, Transition} from "@headlessui/react";
import {getSurveyQuestions, Question, QuestionType} from "../Api/SurveyQuestion";
import {getCurrentSource} from "../Utilities/Experiments";
import Spinner from "../Coachee/Components/Spinner";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import {Swiper as SwiperClass} from "swiper/types";
import {UserInfoContext} from "../Context/UserContext";
import {initUserInfo, UserInfo} from "../Api/User";


function CalendlyModal({ isOpen,
                         setOpen,
                         userInfo,
                         onClose}: {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userInfo: UserInfo | null;
  onClose: () => void;
}) {

  return (
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {setOpen(false); onClose();}}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"/>
          </Transition.Child>

          <div
              className={classNames(
                  "top-0",
                  "fixed inset-x-0 z-10 overflow-y-auto"
              )}
          >
            <div className="flex items-end justify-center min-h-full p-4 overflow-y-scroll text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    id="panel"
                    className={classNames(
                        "mb-5",
                        "relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
                    )}
                >
                  <div className="absolute top-0 right-0 block pt-4 pr-4">
                    <button
                        type="button"
                        className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-perform"
                        onClick={() => {setOpen(false); onClose();}}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-6 h-6" aria-hidden="true"/>
                    </button>
                  </div>

                  <InlineWidget
                      url="https://calendly.com/d/z8d-zp3-cbr/intro-call?hide_landing_page_details=1&hide_gdpr_banner=1&primary_color=085550"
                      prefill={{
                        email: userInfo?.email ?? "",
                      }}
                      pageSettings={{
                        backgroundColor: "ffffff",
                        hideEventTypeDetails: true,
                        hideLandingPageDetails: true,
                        primaryColor: "E25D62",
                        textColor: "4d5055",
                        hideGdprBanner: true,
                      }}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  );
}

function PostOnboardSurvey() {
  const [showModal, setShowModal] = useState(false);
  const [hasScheduledTime, setHasScheduledTime] = useState(false);
  const [continueDisabled, setContinueDisabled] = useState(false);
  const [surveyQuestions, setSurveyQuestions] = useState<Question[] | null>(null);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const navigationNextRef = useRef<HTMLDivElement>(null);
  const slideNext = () => {
    if (swiper && swiper.activeIndex < swiper.slides.length - 1) {
      swiper.slideNext();
    } else {
      initUserInfo(userInfo, setUserInfo).then(() => {
        setContinueDisabled(true);
        setShowModal(true);
      });
    }
  }

  const onEventScheduled = () => {
    window.lintrk("track", {conversion_id: 7357844});
    window.gtag_report_scheduled_call_conversion();
    window.fbq("track", "Schedule");
    ReactGA.event({
      category: "CheckoutFlow",
      action: "CallScheduled",
    });
    track(
        TrackingEventType.TRACKING_APP_WEB_SCHEDULED_EVENT,
        "🗓🗓🗓 ScheduledEvent\n*Source:* " + getCurrentSource() + "\n*Flow:* PostOnboardSurveyV2",
        "profiles"
    );
    setHasScheduledTime(true);
  };

  const onDateAndTimeSelected = () => {
    track(
        TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
        "Clicked Calendly on date and time"
    );
  };

  const onEventTypeViewed = () => {
    track(
        TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
        "Opened Calendly modal"
    );
  };

  const onProfilePageViewed = () => {
    track(
        TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
        "Viewed Calendly profile page"
    );
  };

  async function fetchSurveyQuestions() {
    const questions = await getSurveyQuestions();
    setSurveyQuestions(questions);
  }

  useEffect(() => {
    fetchSurveyQuestions();
  }, []);


  if (!surveyQuestions) {
    return <Spinner/>;
  }

  if (hasScheduledTime) {
    return (
        <div className="w-full h-full flex flex-col p-6 justify-center">
          <div className="mt-auto">
            <div className="mb-4 text-center w-full justify-center">
              <CheckCircleIcon className="mx-auto h-auto w-[100px] text-green-400"/>
            </div>
            <h2 className="font-bold text-3xl text-neutral-900 text-center mb-4">All set!</h2>
            <p className="text-lg text-neutral-500 text-center mb-4">
              We've sent your appointment confirmation to your e-mail. <br/><br/>If you have any questions
              in the meantime, feel free to reach out using the link below.<br/><br/>


            </p>
          </div>
          <div className="w-full text-center self-end mt-auto mb-8">
            <a
                onClick={() => {
                  window.location.href = "mailto:help@runzy.com?subject=Getting%20Started"
                }}
                className="inline text-primary-500 cursor-pointer">
              Contact Us
            </a>
          </div>

        </div>);
  }

  const pagination = {
    clickable: true,
    bulletActiveClass: "bg-primary-500 opacity-100"
  };

  return (
      <>
        <Swiper navigation={{
          nextEl: navigationNextRef.current ? navigationNextRef.current : undefined,
        }} onSwiper={setSwiper} pagination={pagination} modules={[Pagination]} className="w-full h-full bg-white">
          {surveyQuestions.map((question) => {
            if (question.questionType === QuestionType.READ_ONLY_INFO || question.questionType == QuestionType.CALENDAR && question.image) {
              return (
                  <SwiperSlide className="w-full h-full flex flex-col p-6 justify-center">
                    <div className="mt-auto">
                      {question.image && <div className="mb-4 text-center w-full justify-center"><img className="inline w-[90%] max-w-[400px] h-auto" src={question.image}/></div>}
                      <h2 className="font-bold text-3xl text-neutral-900 text-center mb-4">{question.title}</h2>
                      <p className="text-lg text-neutral-500 text-center mb-4">
                        {question.subtitle}
                      </p>
                    </div>
                    <div className="w-full text-center self-end mt-auto mb-32">
                      {" "}
                    </div>
                  </SwiperSlide>);
            }
            return (<></>);
          })}
          <div className="absolute bottom-16 w-full z-50 text-center" ref={navigationNextRef}>
            <button
                disabled={continueDisabled}
                onClick={slideNext}
                className="inline text-white w-[90%] max-w-[400px] pt-6 pb-6 bg-gradient-linear-02 active:bg-gradient-primary-200 disabled:bg-gradient-primary-200 cursor-pointer rounded-full">
              Continue
            </button>

          </div>
        </Swiper>
        <div id="modalPosition" className="absolute w-1/2 h-1/2"/>
        <CalendlyEventListener
            onEventScheduled={onEventScheduled}
            onDateAndTimeSelected={onDateAndTimeSelected}
            onEventTypeViewed={onEventTypeViewed}
            onProfilePageViewed={onProfilePageViewed}
        />
        <CalendlyModal
            isOpen={showModal}
            setOpen={setShowModal}
            userInfo={userInfo}
            onClose={() => {setContinueDisabled(false);}}
        />
      </>
  );
}

export default PostOnboardSurvey;
