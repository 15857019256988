import EventMissing from "../../Assets/Event-Missing.svg";

function EmptyState() {
  return (
    <div className="flex flex-col">
      <div className="my-24 text-center grow">
        <img
          className="w-12 h-12 mx-auto mb-2 text-gray-400"
          src={EventMissing}
          alt="Event Missing"
        />
        <h3 className="mx-2 font-medium text-gray-600">No assigned workouts</h3>
        <p className="mt-1 text-sm text-gray-500">
          Check back soon for the latest.
        </p>
      </div>
    </div>
  );
}

export default EmptyState;
