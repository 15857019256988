import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
} from "@ionic/react";
import { isMobile } from "../../../Utilities/isMobile";
import CoacheeHeader from "../../Components/CoacheeHeader";
import BackArrow from "../../../Assets/BackArrow.svg";
import { useTrainingAttributes } from "../../../Context/TrainingAttributeContext";
import { navigate, NavigationOption } from "./navUtil";

export default function SettingsSubheader({ title }: { title: string }) {
  const { trainingAttributes } = useTrainingAttributes();

  return (
    <>
      {isMobile ? (
        <IonHeader>
          <IonToolbar className="pb-2 mt-2">
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  //   routerHook.push("/profile/settings", "back");
                  navigate(NavigationOption.PROFILE_SETTINGS);
                }}
              >
                <img src={BackArrow} alt="go back to profile" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : (
        <CoacheeHeader trainingAttributes={trainingAttributes} />
      )}
    </>
  );
}
