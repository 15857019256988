import {useContext, useEffect, useState} from "react";
import {adaptDrafts, clearDrafts, generateDrafts, TrainingPlanBuilderAlgorithm, trainingProfile} from "../../Api/TrainingProfile";
import {getUserInfo, UnitPreference} from "../../Api/User";
import {UserInfoContext} from "../../Context/UserContext";
import {getPaceZones, PaceZone} from "../../Api/PaceZones";
import {velocityToLocalizedPace} from "../../Utilities/FormatUtilties";
import {useTrainingAttributes} from "../../Context/TrainingAttributeContext";
/* eslint-disable */
const convert = require("convert-units");

interface ToolsInput {
  selectedTrainingProfile: trainingProfile;
}

function Tools({ selectedTrainingProfile }: ToolsInput) {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const [currentDaysPerWeek, setCurrentDaysPerWeek] = useState(3);
  const [currentWeeklyDistance, setCurrentWeeklyDistance] = useState(20);
  const [currentGenerationDuration, setCurrentGenerationDuration] = useState(1);
  const { trainingAttributes } = useTrainingAttributes();

  const [hours, setHours] = useState(1);
  const [minutes, setMinutes] = useState(45);
  const [paceZones, setPaceZones] = useState<PaceZone[]|null>([]);
  const [planBuilderAlgo, setPlanBuilderAlgo] = useState(TrainingPlanBuilderAlgorithm.SIMPLE);
  const [distanceInMiles, setDistanceInMiles] = useState(13.1);

  const [metricSetting, setMetricSetting] = useState<UnitPreference>(
    UnitPreference.IMPERIAL
  );

  useEffect(() => {
    if (trainingAttributes) {
      setCurrentWeeklyDistance(trainingAttributes.initialWeeklyRunningDistance);
    }
  }, [trainingAttributes]);

  function handleChangeCurrentDaysPerWeek(newValue: string) {
    switch (newValue) {
      case "3":
        setCurrentDaysPerWeek(3);
        return;
      case "4":
        setCurrentDaysPerWeek(4);
        return;
      case "5":
        setCurrentDaysPerWeek(5);
        return;

      default:
        setCurrentDaysPerWeek(3);
        return;
    }
  }

  function handleChangeCurrentGenerationDuration(duration: number) {
    setCurrentGenerationDuration(duration);
  }

  function handleChangeCurrentWeeklyDistance(distance: number) {
    setCurrentWeeklyDistance(distance);
  }

  async function handleCalculateZones() {
    const paceZones = await getPaceZones(60 * 60 * hours + 60 * minutes, distanceInMiles * 1609.34);
    setPaceZones(paceZones);
  }

  async function initMetricSetting() {
    if (userInfo) {
      setMetricSetting(userInfo.profileMetricSetting);
    } else {
      const response = await getUserInfo();
      if (response.userInfo) {
        setUserInfo(response.userInfo);
        setMetricSetting(response.userInfo.profileMetricSetting);
      }
    }
  }

  async function handleGenerateDrafts() {
    if (currentWeeklyDistance < 0 || currentWeeklyDistance > 60000) {
      alert(
        `Please ensure a weekly distance between 0 and 60 ${
          metricSetting === UnitPreference.IMPERIAL ? "mi" : "km"
        }`
      );
      return;
    }

    const confirmation = window.confirm(
      `Are you sure you want to generate workouts?`
    );

    let convertedDistance = currentWeeklyDistance;

    if (metricSetting === UnitPreference.IMPERIAL) {
      convertedDistance = convert(convertedDistance).from("mi").to("m");
    } else {
      convertedDistance = convert(convertedDistance).from("km").to("m");
    }

    if (confirmation) {
      const response = await generateDrafts(
        selectedTrainingProfile,
        currentDaysPerWeek,
        convertedDistance,
        planBuilderAlgo
      );

      if (!response) {
        alert("Failed to generate drafts");
      }
    }
  }

  async function handleClearDrafts() {
    const confirmation = window.confirm(`Are you sure you want to clear drafts?`);

    if (confirmation) {
      const response = await clearDrafts(selectedTrainingProfile);

      if (!response) {
        alert("Failed to clear drafts");
      }
    }
  }

  async function handleAdoptDrafts() {
    if (currentGenerationDuration < 1 || currentGenerationDuration > 20) {
      alert("Please choose between 1 and 20 weeks to adopt");
      return;
    }

    const confirmation = window.confirm(`Are you sure you want to adapt drafts?`);

    if (confirmation) {
      const response = await adaptDrafts(
        selectedTrainingProfile,
        currentGenerationDuration
      );

      if (!response) {
        alert("Failed to adapt drafts");
      }
    }
  }


  function handleAlgoChange(e: string) {
    setPlanBuilderAlgo(TrainingPlanBuilderAlgorithm[e as keyof typeof TrainingPlanBuilderAlgorithm]);
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      initMetricSetting();
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <form className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200">
          <div>
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Generate Drafts
              </h3>
              <p className="mt-1 text-sm text-gray-500"></p>
            </div>

            <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="days"
                  className="block text-sm font-medium text-gray-700"
                >
                  Weekly Workouts
                </label>
                <div className="flex mt-1 rounded-md">
                  <select
                    value={currentDaysPerWeek}
                    onChange={(e) =>
                      handleChangeCurrentDaysPerWeek(e.target.value)
                    }
                    className="block py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-brand focus:border-brand sm:text-sm"
                  >
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
              </div>
              <div className="sm:col-span-4">
                <label
                  htmlFor="weekly distance"
                  className="block text-sm font-medium text-gray-700"
                >
                  Current Weekly Distance (
                  {metricSetting === UnitPreference.IMPERIAL ? "mi" : "km"}){" "}
                </label>
                <div className="flex mt-1 rounded-md">
                  <input
                    type="number"
                    className="block border-gray-300 rounded-md focus:ring-rose-400 focus:border-rose-400 sm:text-sm"
                    value={currentWeeklyDistance}
                    onChange={(e) =>
                      handleChangeCurrentWeeklyDistance(
                        parseInt(e.target.value, 10)
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end gap-3">
            <button
              onClick={() => handleClearDrafts()}
              type="button"
              className="px-4 py-2 text-sm font-medium text-white border rounded-md bg-rose-400 border-rose-400 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-400"
            >
              Clear Drafts
            </button>
            <button
              onClick={() => handleGenerateDrafts()}
              type="button"
              className="px-4 py-2 text-sm font-medium text-white border rounded-md bg-rose-400 border-rose-400 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-400"
            >
              Generate Drafts
            </button>

            <select  onChange={(e) => handleAlgoChange(e.target.value)}>
              <option value="">Builder algorithm</option>
              <option value="SIMPLE" selected>Simple</option>
              <option value="CONSTRAINT_PROGRAMMING">Constraint programming</option>
              <option value="TEMPLATED">Templated</option>
            </select>
          </div>
        </div>
      </form>
      <form className="space-y-8 text-left divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200">
          <div>
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Convert Drafts to Scheduled
              </h3>
              <p className="mt-1 text-sm text-gray-500"></p>
            </div>

            <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="days"
                  className="block text-sm font-medium text-gray-700"
                >
                  Number of Weeks to Schedule
                </label>
                <div className="flex mt-1 rounded-md">
                  <input
                    type="number"
                    min={1}
                    max={20}
                    className="block border-gray-300 rounded-md focus:ring-rose-400 focus:border-rose-400 sm:text-sm"
                    value={currentGenerationDuration}
                    onChange={(e) =>
                      handleChangeCurrentGenerationDuration(
                        parseInt(e.target.value, 10)
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end gap-3">
            <button
              onClick={() => handleAdoptDrafts()}
              type="button"
              className="px-4 py-2 text-sm font-medium text-white border rounded-md bg-rose-400 border-rose-400 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-400"
            >
              Schedule {currentGenerationDuration} Week(s) of Drafts
            </button>
          </div>
        </div>
      </form>



      <form className="space-y-8 text-left divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200">
          <div>
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Pace Zone Calculator
              </h3>
              <p className="mt-1 text-sm text-gray-500"></p>
            </div>

            <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                    htmlFor="hours"
                    className="block text-sm font-medium text-gray-700"
                >
                  Hrs:
                </label>
                <div className="flex mt-1 rounded-md">
                  <input
                      type="number"
                      min={0}
                      max={20}
                      className="block border-gray-300 rounded-md focus:ring-rose-400 focus:border-rose-400 sm:text-sm"
                      value={hours}
                      onChange={(e) =>
                          setHours(
                              parseInt(e.target.value, 10)
                          )
                      }
                  />
                </div>
                <label
                    htmlFor="hours"
                    className="block text-sm font-medium text-gray-700"
                >
                  Mins:
                </label>
                <div className="flex mt-1 rounded-md">
                  <input
                      type="number"
                      min={0}
                      max={59}
                      className="block border-gray-300 rounded-md focus:ring-rose-400 focus:border-rose-400 sm:text-sm"
                      value={minutes}
                      onChange={(e) =>
                          setMinutes(
                              parseInt(e.target.value, 10)
                          )
                      }
                  />
                </div>
                <label
                    htmlFor="hours"
                    className="block text-sm font-medium text-gray-700"
                >
                  Miles:
                </label>
                <div className="flex mt-1 rounded-md">
                  <input
                      type="number"
                      min={0}
                      max={200}
                      className="block border-gray-300 rounded-md focus:ring-rose-400 focus:border-rose-400 sm:text-sm"
                      value={distanceInMiles}
                      onChange={(e) =>
                          setDistanceInMiles(
                              parseFloat(e.target.value)
                          )
                      }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-200">
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Zone
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Pace
                    </th>
                  </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                  {paceZones && paceZones.map((paceZone) => (
                      <tr key={paceZone.zone} className="divide-x divide-gray-200">
                        <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                          {paceZone.zone}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">{velocityToLocalizedPace(paceZone.averageSpeed)} min/mi</td>
                      </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end gap-3">
            <button
                onClick={() => handleCalculateZones()}
                type="button"
                className="px-4 py-2 text-sm font-medium text-white border rounded-md bg-rose-400 border-rose-400 hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-400"
            >
              Calculate zones
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Tools;
