import { trainingEvent } from "../../Api/TrainingEvent";
import Linkify from "react-linkify";
import StepDisplay from "../../Coach/Components/Steps/StepDisplay/StepDisplay";
import StepRepeat from "../../Coach/Components/Steps/StepRepeat/StepRepeat";
import { TrainingEventStepType, getEditableSteps } from "../../Api/Step";

interface EventInstructionsInput {
  trainingEvent: trainingEvent;
}

function EventInstructions({ trainingEvent }: EventInstructionsInput) {
  const usesSteps =
    (trainingEvent && trainingEvent.steps && trainingEvent.steps.length > 0) ??
    false;

  if (usesSteps) {
    return (
      <div>
        <div className="flex items-center justify-between">
          <h1 className="mt-4 font-medium text-neutral-900 text-[14px] mb-2">
            Steps
          </h1>
        </div>

        <div className="space-y-2 mb-2">
          {trainingEvent.steps.map((step, index) => (
            <div key={index}>
              {/* <StepDisplay
                step={step}
                steps={trainingEvent.steps}
                canSwap={false}
              /> */}
              {step.stepType === TrainingEventStepType.REPEAT ? (
                <>
                  <StepRepeat
                    step={getEditableSteps([step])[0]}
                    steps={getEditableSteps(trainingEvent.steps)}
                    index={index}
                    canSwap={false}
                  />
                </>
              ) : (
                <StepDisplay
                  step={step}
                  steps={trainingEvent.steps}
                  canSwap={false}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  function shouldRenderInstructions() {
    return (
      trainingEvent.description.length > 1 ||
      trainingEvent.description[0] !== ""
    );
  }

  return (
    <>
      {shouldRenderInstructions() ? (
        <div className="mb-5 border rounded-xl border-gray-300/50">
          <div className="flex items-center">
            <div className="flex flex-col w-full">
              <p className="px-4 py-4 font-medium text-gray-900 border-b">
                Instructions
              </p>
              <ul className="px-4 py-6 space-y-2">
                {trainingEvent.description.map((message, index) => (
                  <div key={index}>
                    {message.length !== 0 && (
                      <li
                        key={index}
                        className="flex space-x-1.5 text-sm text-gray-800"
                      >
                        <div className="w-1.5 mt-2 mr-1 h-1.5 bg-brand rounded-full overflow-visible shrink-0"></div>
                        <Linkify>{message}</Linkify>
                      </li>
                    )}
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="mb-5 border rounded-xl border-gray-300/50">
            <div className="flex items-center">
              <div className="flex flex-col">
                <p className="px-4 py-4 font-medium text-gray-900 border-b">
                  Instructions
                </p>
                <p className="px-4 py-6 text-gray-500">
                  {/* {isAICore(userInfo!) ? (
                    <>
                      Instructions for this workout may be added soon. Workouts
                      instructions may change based on recent runs.
                    </>
                  ) : ( */}
                  <>
                    Instructions for this workout may be added soon. Workouts
                    instructions may change based on recent runs.
                  </>
                  {/* )} */}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default EventInstructions;
