import { useTrainingProfileOutput } from "./useTrainingProfile";
import Activity from "../Tabs/Activity";
import Notes from "../Tabs/Notes";
import Email from "../Tabs/Email";
import Profile from "../Tabs/Profile";
import Calendar from "../Tabs/Calendar";
import Tools from "../Tabs/Tools";
import CoachNotifications from "../Tabs/CoachNotifications";
import Stats from "../Tabs/Stats";
import CoachPosts from "../../Pages/CoachPosts";
import ChangeLog from "../Tabs/ChangeLog";
import { Switch, Route } from "react-router-dom";
import { classNames } from "../../Utilities/classnames";

function Contents({
  trainingProfileHook,
  showSidebar,
}: {
  trainingProfileHook: useTrainingProfileOutput;
  showSidebar: boolean;
}) {
  const selectedProfile = trainingProfileHook.selectedProfile;
  const selectedProfileId = trainingProfileHook.selectedProfileId;

  const defaultClasses = "py-10 mx-auto max-w-7xl sm:px-6 lg:px-8";
  const calendarClasses = " mx-auto max-w-[95rem] sm:pl-6 lg:pl-8";

  return (
    <main className={classNames("grow h-full  sm:pr-6 lg:pr-8")}>
      <div
        className={classNames(
          // eslint-disable-next-line no-restricted-globals
          location.pathname.includes("calendar")
            ? calendarClasses
            : defaultClasses
        )}
      >
        <Switch>
          {selectedProfile && (
            <>
              {/* what the hell is this ? */}
              <Route path={`/coach/${selectedProfileId}/activity`}>
                <Activity selectedTrainingProfile={selectedProfile} />
              </Route>
              <Route path={`/coach/${selectedProfileId}/notes`}>
                <Notes selectedTrainingProfile={selectedProfile} />
              </Route>
              <Route path={`/coach/${selectedProfileId}/email`}>
                <Email selectedTrainingProfile={selectedProfile} />
              </Route>
              <Route path={`/coach/${selectedProfileId}/posts/:idforeignid?`}>
                <CoachPosts selectedTrainingProfile={selectedProfile} />
              </Route>
              <Route path={`/coach/${selectedProfileId}/profile`}>
                <Profile selectedTrainingProfile={selectedProfile} />
              </Route>
              <Route path={`/coach/${selectedProfileId}/stats`}>
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                  <div className="px-4 pt-10 pb-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <Stats stats={selectedProfile.stats} />
                  </div>
                </div>
              </Route>
              <Route
                path={`/coach/${selectedProfileId}/calendar/:id?/:workoutTab?`}
              >
                <Calendar
                  selectedTrainingProfile={selectedProfile}
                  trainingProfileHook={trainingProfileHook}
                />
              </Route>
              <Route path={`/coach/${selectedProfileId}/tools`}>
                <Tools selectedTrainingProfile={selectedProfile} />
              </Route>
              <Route path={`/coach/${selectedProfileId}/change-log`}>
                <ChangeLog selectedTrainingProfile={selectedProfile} />
              </Route>
              <Route path={`/coach/${selectedProfileId}/notifications`}>
                <CoachNotifications selectedTrainingProfile={selectedProfile} />
              </Route>
            </>
          )}
        </Switch>
      </div>
    </main>
  );
}

export default Contents;
