import {
  getOnboardingProgressDescription,
  markUserAsTest,
  updateUserName,
} from "../../Api/TrainingAttribute";
import {
  grantCoachPrivileges,
  rebootStrava,
  resetPassword,
  setPreferredCoachingDay,
  signInAsTrainingProfile,
  trainingProfile,
} from "../../Api/TrainingProfile";
import {
  altDayOfTheWeek,
  convertToHTMLDateFormat,
  decodeDateFromStringFormat,
  getDayDifference,
} from "../../Utilities/DateUtilities";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useProfile from "../Hooks/useProfile";
import { setAuthToken } from "../../Api/Auth";
import { useContext, useEffect, useState } from "react";
import { UserInfoContext } from "../../Context/UserContext";
import { isSuperAdmin, UserInfo } from "../../Api/User";
import { Prompt, } from "react-router-dom";
import PaceZones from "../../Coachee/Profile/ProfileSettings/PaceZones";
import usePaceZones from "../../Coachee/Profile/ProfileSettings/usePaceZones";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";
import { format } from "date-fns";
import SmallToggle from "../Components/SmallToggle";
import { toast } from "react-toastify";
import { Editable } from "../../Components";

interface ProfileInput {
  selectedTrainingProfile: trainingProfile;
}

function Profile({ selectedTrainingProfile }: ProfileInput) {
  const {
    currentProfileState,
    handleGoalTitle,
    handleGoalDate,
    handleWeight,
    handleHeight,
    handleAge,
    handleGender,
    handleProductIdOverride,
    goalDistanceToString,
    handleCurrentGoalDistanceChange,
    handleGoalTime,
    handleCheck,
    handleSubmit,
    hasChanged,
  } = useProfile({
    selectedTrainingProfile,
  });

  const { userInfo } = useContext(UserInfoContext);
  const paceZonesHook = usePaceZones({
    trainingAttributes: selectedTrainingProfile.trainingAttribute,
    profileId: selectedTrainingProfile.miniProfile.id,
  });
  const [isLoadingGrantPermissions, setIsLoadingGrantPermissions] =
    useState<boolean>(false);
  const [isLoadingResetPassword, setIsLoadingResetPassword] =
    useState<boolean>(false);

  const [isTestUser, setIsTestUser] = useState(
    selectedTrainingProfile.miniProfile.isTest
  );

  const [name, setName] = useState(selectedTrainingProfile.miniProfile.name);

  function alertUser(e: any) {
    if (hasChanged()) {
      const confirmation = window.confirm(
        `Are you sure you want to leave this page with unsaved changes?`
      );

      if (!confirmation) {
        e.preventDefault();
        e.returnValue = "";
      }
    }
  }

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  return (
    <>
      <Prompt
        message="Are you sure you want to leave this page with unsaved changes?"
        when={hasChanged()}
      />
      {selectedTrainingProfile && currentProfileState.trainingAttributes && (
        <div className="mx-auto max-w-7xl sm:px-6">
          <div className="pt-10 pb-10 mx-auto max-w-7xl">
            <div className="flex justify-end py-4">
              <>
                <button
                  disabled={!hasChanged()}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  className="justify-center hidden px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer sm:inline-flex disabled:cursor-default disabled:bg-gray-300 bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                >
                  Save
                </button>
              </>
            </div>
            <div className="overflow-hidden bg-white rounded-lg shadow">
              <div className="px-4 py-5 sm:p-6">
                {" "}
                <div className="divide-y divide-gray-200">
                  <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Connection
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      View the athlete's current coach.
                    </p>
                  </div>
                  <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Athlete
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex items-center gap-2 mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <ProfilePicture
                                url={
                                  selectedTrainingProfile.miniProfile
                                    .profilePicture
                                }
                                classNames="w-8 h-8 rounded-full"
                              />
                              <span className="flex-grow flex">
                                <Editable
                                  value={name}
                                  onSave={(newValue, oldValue) => {
                                    updateUserName(
                                      selectedTrainingProfile.miniProfile.id,
                                      newValue
                                    )
                                      .then(() => {
                                        toast("Name successfully saved.", {
                                          type: "success",
                                        });
                                        setName(newValue);
                                      })
                                      .catch(() => {
                                        toast("Failed to save name.", {
                                          type: "error",
                                        });
                                        setName(oldValue);
                                      });
                                  }}
                                />
                                <span>
                                  (
                                  {
                                    selectedTrainingProfile.miniProfile
                                      .numericId
                                  }
                                  )
                                </span>
                              </span>
                            </dd>
                          </span>
                        </div>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Coach
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex items-center gap-2 mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <img
                                className="w-8 h-8 rounded-full"
                                src={
                                  selectedTrainingProfile.trainingAttribute
                                    .trainingAdminProfile.profilePicture
                                }
                                alt=""
                              />
                              <span className="flex-grow">
                                {
                                  selectedTrainingProfile.trainingAttribute
                                    .trainingAdminProfile.name
                                }
                              </span>
                            </dd>
                          </span>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 overflow-hidden bg-white rounded-lg shadow">
              <div className="px-4 py-5 sm:p-6">
                {" "}
                <div className="divide-y divide-gray-200">
                  <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Goal Details
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      View and adjust the athlete's goal details.
                    </p>
                  </div>
                  <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Title
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <input
                                type="text"
                                name="title"
                                id="title"
                                autoComplete="title"
                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                value={currentProfileState.goalTitle ?? ""}
                                onChange={(e) => {
                                  handleGoalTitle(e.target.value);
                                }}
                              />
                            </dd>
                          </span>
                        </div>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Date
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <input
                                type="date"
                                name="date"
                                id="date"
                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                value={
                                  currentProfileState.goalDate
                                    ? convertToHTMLDateFormat(
                                      currentProfileState.goalDate
                                    )
                                    : new Date().toDateString()
                                }
                                onChange={(e) => {
                                  handleGoalDate(
                                    decodeDateFromStringFormat(e.target.value)
                                  );
                                }}
                              />
                            </dd>
                          </span>
                        </div>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Days Remaining
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <p className="flex-1 block w-full min-w-0 border-gray-300 rounded-md sm:text-sm">
                                {currentProfileState.goalDate &&
                                  getDayDifference(
                                    currentProfileState.goalDate
                                  )}
                              </p>
                            </dd>
                          </span>
                        </div>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Goal Distance
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <select
                                id="Distance"
                                name="Distance"
                                className="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-brand focus:border-brand sm:text-sm"
                                value={goalDistanceToString(
                                  currentProfileState.goalDistance ?? -1
                                )}
                                onChange={(e) => {
                                  handleCurrentGoalDistanceChange(
                                    e.target.value
                                  );
                                }}
                              >
                                <option value=""></option>
                                <option value="50 K">50 K</option>
                                <option value="Marathon">Marathon</option>
                                <option value="Half Marathon">
                                  Half Marathon
                                </option>
                                <option value="10 Mi">10 Mi</option>
                                <option value="12 K">12 K</option>
                                <option value="10 K">10 K</option>
                                <option value="5 Mi">5 Mi</option>
                                <option value="5 K">5 K</option>
                              </select>
                            </dd>
                          </span>
                        </div>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Goal Time
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <input
                                type="text"
                                id="username"
                                name="username"
                                className="flex-1 block w-full min-w-0 border-gray-300 rounded-md focus:ring-brand focus:border-brand sm:text-sm"
                                value={
                                  currentProfileState.goalTime
                                    ? currentProfileState.goalTime
                                    : ""
                                }
                                onChange={(e) => {
                                  handleGoalTime(e.target.value);
                                }}
                              />
                            </dd>
                          </span>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 overflow-hidden bg-white rounded-lg shadow">
              <div className="px-4 py-5 sm:p-6">
                {" "}
                <div className="divide-y divide-gray-200">
                  <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Physical characteristics
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      Save and edit the athlete's physical characteristics.
                    </p>
                  </div>
                  <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Height
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <input
                                type="text"
                                name="height"
                                id="height"
                                placeholder="5'49"
                                autoComplete="height"
                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                value={currentProfileState?.height ?? ""}
                                onChange={(e) => {
                                  handleHeight(e.target.value);
                                }}
                              />
                            </dd>
                          </span>
                        </div>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Weight
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <input
                                type="text"
                                name="weight"
                                id="weight"
                                placeholder="190 lbs"
                                autoComplete="weight"
                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                value={currentProfileState.weight ?? ""}
                                onChange={(e) => {
                                  handleWeight(e.target.value);
                                }}
                              />
                            </dd>
                          </span>
                        </div>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Age
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <input
                                type="text"
                                name="age"
                                id="age"
                                autoComplete="age"
                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                value={currentProfileState.age ?? ""}
                                onChange={(e) => {
                                  handleAge(e.target.value);
                                }}
                              />
                            </dd>
                          </span>
                        </div>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Gender
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <select
                                name="gender"
                                id="gender"
                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                value={currentProfileState.gender ?? ""}
                                onChange={(e) => {
                                  handleGender(e.target.value);
                                }}
                              >
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Prefer not to answer">
                                  Prefer not to answer
                                </option>
                              </select>
                            </dd>
                          </span>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 overflow-hidden bg-white rounded-lg shadow ">
              <div className="px-4 py-5 sm:p-6">
                <div className="divide-y divide-gray-200">
                  <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Blocked Days
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      Select which days that you cannot workout on.
                    </p>
                  </div>
                  <div className="z-10 mt-6 border-t border-b border-gray-200 divide-y divide-gray-200">
                    {selectedTrainingProfile.trainingAttribute.blockedDays &&
                      altDayOfTheWeek.map((day, index) => (
                        <div
                          key={index}
                          className="relative z-10 flex items-start py-4"
                        >
                          <div className="flex-1 min-w-0 text-sm">
                            <label
                              htmlFor={day}
                              className="font-medium text-gray-700 select-none"
                            >
                              {day}
                            </label>
                          </div>
                          <div className="flex items-center h-5 ml-3">
                            <input
                              id={day}
                              name={day}
                              type="checkbox"
                              checked={currentProfileState.blockedDays.includes(
                                day.toUpperCase()
                              )}
                              onChange={() => {
                                handleCheck(day.toUpperCase());
                              }}
                              className="w-4 h-4 border-gray-300 rounded cursor-pointer text-brand focus:ring-brand"
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 bg-white rounded-lg shadow ">
              <div className="px-4 py-5 sm:p-6">
                <div className="divide-y divide-gray-200">
                  <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Pace Zones
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      See and adjust the athlete's pace zones. If the zones are
                      all set to zero, it means we do not yet have enough data
                      to generate the athlete's zones automatically.
                    </p>
                  </div>
                  <div className="z-10 mt-6 border-t border-b border-gray-200 divide-y divide-gray-200 pt-2">
                    <PaceZones adminView={true} paceZonesHook={paceZonesHook} />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 overflow-hidden bg-white rounded-lg shadow ">
              <div className="px-4 py-5 sm:p-6">
                <div className="divide-y divide-gray-200">
                  <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Account
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      Manage the athlete's access to external services.
                    </p>
                  </div>
                  <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                      {isSuperAdmin(userInfo) && (
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="my-auto text-sm font-medium text-gray-500">
                            E-mail
                          </dt>
                          <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow"></span>
                            <span className="flex-shrink-0 ml-4">
                              <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <p className="flex-1 block w-full min-w-0 border-gray-300 rounded-md sm:text-sm">
                                  {currentProfileState.trainingAttributes
                                    .emailAddress
                                    ? currentProfileState.trainingAttributes
                                      .emailAddress
                                    : "Not set"}
                                </p>
                              </dd>
                            </span>
                          </div>
                        </div>
                      )}

                      {isSuperAdmin(userInfo) && (
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="my-auto text-sm font-medium text-gray-500">
                            Phone number
                          </dt>
                          <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow"></span>
                            <span className="flex-shrink-0 ml-4">
                              <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <p className="flex-1 block w-full min-w-0 border-gray-300 rounded-md sm:text-sm">
                                  {currentProfileState.miniProfile.phone
                                    ? currentProfileState.miniProfile.phone
                                    : "Not set"}
                                </p>
                              </dd>
                            </span>
                          </div>
                        </div>
                      )}

                      {isSuperAdmin(userInfo) && (
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="my-auto text-sm font-medium text-gray-500">
                            Date joined
                          </dt>
                          <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow"></span>
                            <span className="flex-shrink-0 ml-4">
                              <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <p className="flex-1 block w-full min-w-0 border-gray-300 rounded-md sm:text-sm">
                                  {/* Convert timestamp to milliseconds*/}
                                  {currentProfileState.miniProfile.created
                                    ? format(
                                      new Date(
                                        currentProfileState.miniProfile
                                          .created * 1000
                                      ),
                                      "MM/dd/yyyy"
                                    )
                                    : "Not set"}
                                </p>
                              </dd>
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Athlete Status
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <p className="flex-1 block w-full min-w-0 border-gray-300 rounded-md sm:text-sm">
                                {currentProfileState.trainingAttributes &&
                                  getOnboardingProgressDescription(
                                    currentProfileState.trainingAttributes
                                      .onboardingProgress
                                  )}
                              </p>
                            </dd>
                          </span>
                        </div>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Paid Product
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <p className="flex-1 block w-full min-w-0 border-gray-300 rounded-md sm:text-sm">
                                {(selectedTrainingProfile.productDescription === 'N/A' &&
                                  currentProfileState.trainingAttributes.emailAddress)
                                  ?
                                  <div className="flex gap-2">
                                    <div className="flex flex-col gap-3 items-center">
                                      <a target="_blank" rel="noreferrer"
                                        className=" underline leading-3	"
                                        href={`https://coaching.runzy.com/checkout/basic/${currentProfileState?.trainingAttributes?.emailAddress}`}
                                      >
                                        Basic
                                      </a>
                                      <button
                                        onClick={() => {
                                          navigator.clipboard.writeText(`https://coaching.runzy.com/checkout/basic/${currentProfileState?.trainingAttributes?.emailAddress}`)
                                        }}
                                        className="px-2 py-1 font-medium bg-white border rounded-md border-brand text-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                                      >
                                        Copy Basic
                                      </button>
                                    </div>
                                    <div className="flex flex-col gap-3 items-center">
                                      <a target="_blank" rel="noreferrer"
                                        className="underline leading-3	"
                                        href={`https://coaching.runzy.com/checkout/core/${currentProfileState?.trainingAttributes?.emailAddress}`}
                                      >
                                        Core
                                      </a>
                                      <button
                                        onClick={() => {
                                          navigator.clipboard.writeText(`https://coaching.runzy.com/checkout/core/${currentProfileState?.trainingAttributes?.emailAddress}`)
                                        }}
                                        className="px-2 py-1 font-medium bg-white border rounded-md border-brand text-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                                      >
                                        Copy Core
                                      </button>
                                    </div>
                                    <div className="flex flex-col gap-3 items-center">
                                      <a target="_blank" rel="noreferrer"
                                        className="underline leading-3	"
                                        href={`https://coaching.runzy.com/checkout/plus/${currentProfileState?.trainingAttributes?.emailAddress}`}
                                      >
                                        Plus
                                      </a>
                                      <button
                                        onClick={() => {
                                          navigator.clipboard.writeText(`https://coaching.runzy.com/checkout/plus/${currentProfileState?.trainingAttributes?.emailAddress}`)
                                        }}
                                        className="px-2 py-1 font-medium bg-white border rounded-md border-brand text-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                                      >
                                        Copy Plus
                                      </button>
                                    </div>
                                  </div>
                                  : selectedTrainingProfile.productDescription
                                }
                              </p>
                            </dd>
                          </span>
                        </div>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Free Upgrade (if any)
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <p className="flex-1 block w-full min-w-0 border-gray-300 rounded-md sm:text-sm">
                                {currentProfileState.trainingAttributes &&
                                  currentProfileState.trainingAttributes
                                    .productName}
                              </p>
                            </dd>
                          </span>
                        </div>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Free Upgrade Available Until
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <input
                                type="date"
                                name="date"
                                id="date"
                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                value={
                                  currentProfileState.productIdOverrideExpiresAt
                                    ? convertToHTMLDateFormat(
                                      currentProfileState.productIdOverrideExpiresAt
                                    )
                                    : new Date().toDateString()
                                }
                                onChange={(e) => {
                                  handleProductIdOverride(
                                    decodeDateFromStringFormat(e.target.value)
                                  );
                                }}
                              />
                            </dd>
                          </span>
                        </div>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Strava Connection
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <p className="flex-1 block w-full min-w-0 border-gray-300 rounded-md sm:text-sm">
                                {currentProfileState.trainingAttributes &&
                                  currentProfileState.trainingAttributes
                                    .stravaProfileUrl ? (
                                  <a
                                    href={
                                      currentProfileState.trainingAttributes
                                        .stravaProfileUrl
                                    }
                                    target="_blank"
                                    className="flex-1 block w-full min-w-0 text-brand rounded-md hover:underline hover:text-green-800 sm:text-sm"
                                  >
                                    {
                                      currentProfileState.trainingAttributes
                                        .stravaProfileUrl
                                    }
                                  </a>
                                ) : (
                                  <span className="flex-1 block w-full min-w-0 text-brand rounded-md sm:text-sm">
                                    Not connected
                                  </span>
                                )}
                              </p>
                            </dd>
                          </span>
                        </div>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Resync Strava Activities
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <button
                                disabled={currentProfileState.isLoading}
                                onClick={async (e) => {
                                  e.preventDefault();
                                  if (userInfo && isSuperAdmin(userInfo)) {
                                    if (
                                      window.confirm(
                                        "Are you sure you wish to re-sync Strava?"
                                      )
                                    ) {
                                      setIsLoadingGrantPermissions(true);
                                      e.preventDefault();
                                      await rebootStrava(
                                        selectedTrainingProfile
                                      );
                                      setIsLoadingGrantPermissions(false);
                                    }
                                  } else {
                                    alert(
                                      "Having trouble syncing Strava data? Let the team know on Slack and they can assist"
                                    );
                                  }
                                }}
                                className="px-2 py-1 font-medium bg-white border rounded-md border-brand text-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                              >
                                {isLoadingGrantPermissions && (
                                  <div
                                    className="inline-block w-4 h-4 rounded-full text-brand spinner-border animate-spin border-1"
                                    role="status"
                                  />
                                )}
                                Resync
                              </button>
                            </dd>
                          </span>
                        </div>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Send reset password e-mail
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <button
                                disabled={currentProfileState.isLoading}
                                onClick={async (e) => {
                                  if (
                                    window.confirm(
                                      "Are you sure you wish to reset the user's password?"
                                    )
                                  ) {
                                    setIsLoadingResetPassword(true);
                                    e.preventDefault();
                                    await resetPassword(
                                      selectedTrainingProfile
                                    );
                                    setIsLoadingResetPassword(false);
                                  }
                                }}
                                className="px-2 py-1 font-medium bg-white border rounded-md border-brand text-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                              >
                                {isLoadingResetPassword && (
                                  <div
                                    className="inline-block w-4 h-4 rounded-full text-brand spinner-border animate-spin border-1"
                                    role="status"
                                  />
                                )}
                                Reset Password
                              </button>
                            </dd>
                          </span>
                        </div>
                      </div>
                      {isSuperAdmin(userInfo as UserInfo) && (
                        <>
                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="my-auto text-sm font-medium text-gray-500">
                              Grant Coach Privileges
                            </dt>
                            <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <span className="flex-grow"></span>
                              <span className="flex-shrink-0 ml-4">
                                <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  {selectedTrainingProfile.trainingAdminAttributes ? (
                                    <>Granted</>
                                  ) : (
                                    <button
                                      disabled={currentProfileState.isLoading}
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        if (
                                          window.confirm(
                                            "Are you sure you wish to make this profile a coach?"
                                          )
                                        ) {
                                          await grantCoachPrivileges(
                                            selectedTrainingProfile
                                          );
                                          window.alert(
                                            "Successfully granted Coach Privileges"
                                          );
                                        }
                                      }}
                                      className="px-2 py-1 font-medium bg-white border rounded-md border-brand text-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                                    >
                                      Grant permission
                                    </button>
                                  )}
                                </dd>
                              </span>
                            </div>
                          </div>

                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="my-auto text-sm font-medium text-gray-500">
                              Sign In As User
                            </dt>
                            <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <span className="flex-grow"></span>
                              <span className="flex-shrink-0 ml-4">
                                <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  <button
                                    disabled={currentProfileState.isLoading}
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      const result =
                                        await signInAsTrainingProfile(
                                          selectedTrainingProfile
                                        );
                                      if (result && result.token) {
                                        setAuthToken(result.token, true);
                                        window.location.href = "/";
                                      }
                                    }}
                                    className="px-2 py-1 font-medium bg-white border rounded-md border-brand text-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                                  >
                                    Sign In
                                  </button>
                                </dd>
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Strava Authentication Link
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <CopyToClipboard text="https://api.joinperform.com/api/server/authenticate/strava-sync?auth_strava=true">
                                <button
                                  onClick={(e) => e.preventDefault()}
                                  className="px-2 py-1 font-medium bg-white border rounded-md border-brand text-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                                >
                                  Copy To Clipboard
                                </button>
                              </CopyToClipboard>
                            </dd>
                          </span>
                        </div>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="my-auto text-sm font-medium text-gray-500">
                          Mark as a test user
                        </dt>
                        <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow"></span>
                          <span className="flex-shrink-0 ml-4">
                            <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <SmallToggle
                                isEnabled={isTestUser}
                                setIsEnabled={() => {
                                  setIsTestUser(!isTestUser);
                                  markUserAsTest(
                                    selectedTrainingProfile.miniProfile.id,
                                    !isTestUser
                                  ).then(() =>
                                    toast(
                                      `${currentProfileState.trainingAttributes
                                        ?.emailAddress
                                      } has been ${!isTestUser ? "marked" : "unmarked"
                                      }  as test user.`
                                    )
                                  );
                                }}
                              />
                            </dd>
                          </span>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            {isSuperAdmin(userInfo as UserInfo) &&
              selectedTrainingProfile.trainingAdminAttributes && (
                <div className="mt-10 overflow-hidden bg-white rounded-lg shadow ">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="divide-y divide-gray-200">
                      <div className="space-y-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Coaching Preferences
                        </h3>
                        <p className="max-w-2xl text-sm text-gray-500">
                          This profile is a coach.
                        </p>
                      </div>
                      <div className="z-10 mt-6 border-t border-b border-gray-200 divide-y divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="my-auto text-sm font-medium text-gray-500">
                            Set preferred coaching day of the week
                          </dt>
                          <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow"></span>
                            <span className="flex-shrink-0 ml-4">
                              <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <select
                                  defaultValue={
                                    selectedTrainingProfile
                                      .trainingAdminAttributes.reviewDayOfWeek
                                  }
                                  onChange={async (e) => {
                                    if (
                                      // eslint-disable-next-line no-restricted-globals
                                      confirm(
                                        "Are you sure you want to change the day to " +
                                        e.target.value +
                                        "?"
                                      )
                                    ) {
                                      await setPreferredCoachingDay(
                                        selectedTrainingProfile,
                                        e.target.value
                                      );
                                    }
                                  }}
                                  className="w-full rounded-lg text-[14px] placeholder:text-[#717171] mt-4 border-race-100 ring-race-100 focus:border-race-100 focus:ring-race-100"
                                >
                                  <option value="MONDAY">Monday</option>
                                  <option value="TUESDAY">Tuesday</option>
                                  <option value="WEDNESDAY">Wednesday</option>
                                  <option value="THURSDAY">Thursday</option>
                                  <option value="FRIDAY">Friday</option>
                                  <option value="SATURDAY">Saturday</option>
                                  <option value="SUNDAY">Sunday</option>
                                </select>
                              </dd>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
}

export default Profile;
