import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  ActionPerformed,
  PushNotifications,
} from "@capacitor/push-notifications";
import { isPlatform } from "@ionic/react";
import { setAndroidDeviceID, setAppleDeviceID } from "../Api/Settings";
import { isMobile } from "../Utilities/isMobile";
import { Capacitor } from "@capacitor/core";
import { track, TrackingEventType } from "../Api/Tracking";
import { isImpersonated } from "../Api/Auth";

const PushNotificationHandler = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      function registerEventHandlers() {
        if (!isMobile) return;
        PushNotifications.addListener("registration", (token) => {
          if (isImpersonated()) {
            return;
          }
          if (isPlatform("ios")) {
            setAppleDeviceID(token.value);
          } else if (isPlatform("android")) {
            setAndroidDeviceID(token.value);
          }
        });

        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (res: ActionPerformed) => {
            const url = res.notification.data.urlToOpen;
            history.push(url);

            track(
              TrackingEventType.TRACKING_APP_WEB_PUSH_NOTIFICATION,
              `Open push notification to ${url}.`,
              "notifications"
            );
          }
        );
      }

      registerEventHandlers();
    }, 1000);
  }, [history]);

  return null;
};

export const requestNotificationPermissions = async () => {
  if (!Capacitor.isPluginAvailable("PushNotifications")) return;

  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    // TODO: Set setting to denied.
  }

  await PushNotifications.register();
};

export default PushNotificationHandler;
