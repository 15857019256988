import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import {
  CheckIcon,
  CalendarIcon,
  XIcon,
  PencilAltIcon,
  FlagIcon,
} from "@heroicons/react/outline";
import { TrainingEventStatus } from "../../Api/TrainingEvent";
import { classNames } from "../../Utilities/classnames";

const tags = [
  {
    id: 1,
    tag: TrainingEventStatus.DRAFT,
    title: "Draft",
    description: "This event cannot be seen by the athlete.",
    textColor: "text-white",
    icon: <PencilAltIcon aria-hidden="true" />,
  },
  {
    id: 2,
    tag: TrainingEventStatus.SCHEDULED,
    title: "Scheduled",
    description: "This event will appear as an upcoming workout.",
    textColor: "text-white",
    icon: <CalendarIcon aria-hidden="true" />,
  },
  {
    id: 3,
    tag: TrainingEventStatus.COMPLETED,
    title: "Completed",
    description: "This event was completed successfully.",
    textColor: "text-white",
    icon: <CheckIcon aria-hidden="true" />,
  },
  {
    id: 4,
    tag: TrainingEventStatus.MISSED,
    title: "Missed",
    description: "This event was not completed.",
    textColor: "text-white",
    icon: <XIcon aria-hidden="true" />,
  },
];

interface StatusDropdownInput {
  currentStatus: TrainingEventStatus;
  setSelectedStatus: Function;
  isDisabled?: boolean;
}

function getTagColor(tag: typeof tags[0]) {
  switch (tag.tag) {
    case TrainingEventStatus.COMPLETED:
      return {
        background: "bg-green-500",
        hover: "hover:bg-green-500",
        focus: "focus:ring-green-500",
        text: "text-green-500",
        divide: "divide-green-500",
      };
    case TrainingEventStatus.MISSED:
      return {
        background: "bg-red-500",
        hover: "hover:bg-red-500",
        focus: "focus:ring-red-500",
        text: "text-red-500",
        divide: "divide-red-500",
      };
    case TrainingEventStatus.DRAFT:
      return {
        background: "bg-gray-500",
        hover: "hover:bg-gray-500",
        focus: "focus:ring-gray-500",
        text: "text-gray-500",
        divide: "divide-gray-500",
      };

    default:
      return {
        background: "bg-blue-500",
        hover: "hover:bg-blue-500",
        focus: "focus:ring-blue-500",
        text: "text-blue-500",
        divide: "divide-blue-500",
      };
  }
}

function StatusDropdown({
  currentStatus,
  setSelectedStatus,
  isDisabled = false,
}: StatusDropdownInput) {
  const currentTag = () => {
    if (tags.filter((tag) => tag.tag === currentStatus))
      return tags.filter((tag) => tag.tag === currentStatus)[0];

    return tags[0];
  };

  return (
    <div className="z-50">
      {" "}
      <Listbox
        disabled={isDisabled}
        value={currentTag}
        onChange={(element: any) => {
          setSelectedStatus(element.tag);
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">
              Change event status
            </Listbox.Label>
            <div className="relative z-50">
              <div
                className={`inline-flex divide-x ${
                  getTagColor(currentTag()).divide
                } rounded-md shadow-sm`}
              >
                <div
                  className={`relative z-0 inline-flex divide-x ${
                    getTagColor(currentTag()).divide
                  } rounded-md shadow-sm`}
                >
                  <div
                    className={`relative inline-flex items-center py-2 pl-3 pr-4 text-white ${
                      getTagColor(currentTag()).background
                    } border border-transparent shadow-sm rounded-l-md`}
                  >
                    {/* <CheckIcon className="w-5 h-5" aria-hidden="true" /> */}
                    <div className="w-5 h-5">{currentTag().icon}</div>
                    <p className="ml-2.5 text-sm font-medium">
                      {currentTag().title}
                    </p>
                  </div>
                  <Listbox.Button
                    className={`relative inline-flex items-center p-2 text-sm font-medium text-white ${
                      getTagColor(currentTag()).background
                    } rounded-l-none rounded-r-md ${
                      getTagColor(currentTag()).hover
                    } focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 ${
                      getTagColor(currentTag()).focus
                    }`}
                  >
                    <span className="sr-only">Change published status</span>
                    <ChevronDownIcon
                      className="w-5 h-5 text-white"
                      aria-hidden="true"
                    />
                  </Listbox.Button>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute right-0 z-10 mt-2 overflow-hidden origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg w-72 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {tags.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classNames(
                          active
                            ? `text-white ${getTagColor(option).background}`
                            : "text-gray-900",
                          " cursor-pointer select-none relative p-4 text-sm"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <div className="flex flex-col">
                          <div className="flex justify-between">
                            <p
                              className={
                                selected ? "font-semibold" : "font-normal"
                              }
                            >
                              {option.title}
                            </p>
                            <span
                              className={classNames(
                                active
                                  ? `text-white`
                                  : `${getTagColor(option).text}`,
                                "h-5 w-5"
                              )}
                            >
                              {option.icon}
                            </span>
                          </div>
                          <p
                            className={classNames(
                              active ? "text-white" : `text-gray-900`,
                              "mt-2"
                            )}
                          >
                            {option.description}
                          </p>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}

export default StatusDropdown;
