import { trainingProfile } from "../../Api/TrainingProfile";
import useNotifications from "../../Pages/Hooks/useNotifications";
import NotificationPreview from "../Notifications/NotificationPreview";

interface CoachNotifications {
  selectedTrainingProfile: trainingProfile;
}

function CoachNotifications({ selectedTrainingProfile }: CoachNotifications) {
  const {
    feedItems,
    isReadMap,
    handleClickNotification,
    markFeedItemsAsRead,
    hasAtleastOneUnreadNotification,
  } = useNotifications({
    trainingProfile: selectedTrainingProfile,
  });

  return (
    <>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="px-4 pt-10 pb-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
            <div className="space-y-1">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Notifications
                </h3>
                <button
                  disabled={!hasAtleastOneUnreadNotification()}
                  onClick={() => {
                    markFeedItemsAsRead();
                  }}
                  className="justify-center hidden px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer sm:inline-flex disabled:cursor-default disabled:bg-gray-300 bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                >
                  Mark all as read
                </button>
              </div>

              <p className="max-w-2xl text-sm text-gray-500"></p>
            </div>
            <div className="mt-6">
              <>
                {feedItems.length > 0 ? (
                  <ul role="list" className="divide-y divide-gray-100">
                    {feedItems.map((feedItem) => (
                      <NotificationPreview
                        feedItem={feedItem}
                        selectedTrainingProfile={selectedTrainingProfile}
                        handleClickNotification={handleClickNotification}
                        isReadMap={isReadMap}
                      />
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-400 ">
                    No notifications. Check back later!
                  </p>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoachNotifications;
