import axios from 'axios'
import { PaginationResponse } from '../../index'

export type SurveyReportSearch = {
    page: string
}

export type SurveyAnswer = {
    answer: string[]
    country: string | null
    createdAt: string
    questionId: number | null
    questionKey: string | null
}

export type SurveyReport = {
    email: string
    createdAt: string
    isTest: boolean
    countryCode: string | null
    surveyAnswers: SurveyAnswer[]
}

export const getSurveyReport = async (params: SurveyReportSearch): Promise<PaginationResponse<SurveyReport>> => {
    return new Promise((resolve, reject) => {
        axios.get('report/survey', {params})
            .then(({data}) => resolve(data as unknown as PaginationResponse<SurveyReport>))
            .catch((error) => reject(error))
    })
}
