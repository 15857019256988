/* eslint-disable */
// TODO @wbert - type this file

import { useHistory, useLocation } from "react-router-dom";
import { Fragment, useContext } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import {
  AtSymbolIcon,
  BellIcon,
  CalendarIcon,
  ChartSquareBarIcon,
  ChatAlt2Icon,
  ClipboardListIcon,
  CogIcon,
  DocumentTextIcon,
  IdentificationIcon,
  MenuIcon,
  PencilAltIcon,
  XIcon,
} from "@heroicons/react/outline";
import { isSuperAdmin, roleDescription } from "../../Api/User";
import { UserInfoContext } from "../../Context/UserContext";
import { classNames } from "../../Utilities/classnames";
import CoachHeaderSwitch from "./CoachHeaderSwitch";
import useAuth from "../../Common/Hooks/useAuth";
import MenuBars from "../../Assets/Menu.svg";
import RunzyLogo from "../../Assets/RunzyLogo.svg";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";
// TODO replace with react router
import { IonRouterLink } from "@ionic/react";

interface CoachHeaderProps {
  showAllAthletes: boolean;
  setShowAllAthletes: Function;
}

export enum CoachHeaderTab {
  DASHBOARD = "dashboard",
  ADMIN = "admin",
}

function CoachHeader({
  showAllAthletes,
  setShowAllAthletes,
}: CoachHeaderProps) {
  const { userInfo } = useContext(UserInfoContext);
  const { handleSignOut } = useAuth();
  const history = useHistory();

  let location = useLocation();
  const { pathname } = location;
  // what the hell is this ?
  // TODO: remove history and location
  
  const headerNavigation = [
    {
      name: "Dashboard",
      current: pathname.split("/")[1] === "coach",
      action: () => {
        history.push("/coach");
      },
      requiresSuperAdmin: false,
    },
    {
      name: "Runner Experience",
      current: false,
      action: () => {
        history.push("/dashboard");
      },
      requiresSuperAdmin: true,
    },
    {
      name: "Admin",
      current: pathname.includes("admin"),
      action: () => {
        history.push("/admin");
      },
      requiresSuperAdmin: true,
    },
  ];

  // this is for mobile only navigation instead of sidebar
  // TODO: rework this, remove location.pathname and so on, move out links array to utilities
  const navigation = [
    {
      name: "Calendar",
      href: "calendar",
      icon: CalendarIcon,
      current: pathname.includes("calendar"),
    },
    {
      name: "Activity",
      href: "activity",
      icon: ClipboardListIcon,
      current: pathname.includes("activity"),
    },
    {
      name: "Notes",
      href: "notes",
      icon: PencilAltIcon,
      current: pathname.includes("notes"),
    },
    {
      name: "Email",
      href: "email",
      icon: AtSymbolIcon,
      current: pathname.includes("email"),
    },
    {
      name: "Posts",
      href: "posts",
      icon: ChatAlt2Icon,
      current: pathname.includes("posts"),
    },
    {
      name: "Profile",
      href: "profile",
      icon: IdentificationIcon,
      current: pathname.includes("profile"),
    },
    {
      name: "Notifications",
      href: "notifications",
      icon: BellIcon,
      current: pathname.includes("notifications"),
    },
    {
      name: "Stats",
      href: "stats",
      icon: ChartSquareBarIcon,
      current: pathname.includes("stats"),
    },
    {
      name: "Tools",
      href: "tools",
      icon: CogIcon,
      current: pathname.includes("tools"),
    },
    {
      name: "Change log",
      href: "change-log",
      icon: DocumentTextIcon,
      current: pathname.includes("change-log"),
    },
  ];

  const userNavigation = [
    {
      name: "My training profile",
      onClick: () => {
        if (userInfo) {
          history.push("/coach/" + userInfo.id + "/profile");
        }
      },
    },
    {
      name: "My profile",
      onClick: () => {
        if (userInfo) {
          history.push("/profile");
        }
      },
    },
    { name: "Sign out", onClick: () => handleSignOut() },
  ];

  return (
    <Popover
      as="header"
      className={({ open }) =>
        classNames(
          open ? "fixed inset-0 z-40 overflow-y-auto" : "",
          "bg-gray-50 shadow-sm lg:overflow-y-visible sticky top-0 z-50 shadow-none"
        )
      }
    >
      {({ open }) => (
        <>
          <div className="py-3 mx-auto sm:px-6 lg:px-8 bg-gray-50">
            <div className="relative flex justify-between mx-2 lg:mx-0 lg:gap-8">
              <div className="flex md:static md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                <div className="flex items-center flex-shrink-0">
                  {/* <button
                    onClick={() => {
                      if (setShowMenu) setShowMenu((showMenu) => !showMenu);
                    }}
                    className="mr-4"
                  >
                    <img src={MenuBars} alt="open/close menu" />
                  </button> */}
                  <IonRouterLink routerLink="/coach">
                    <div className="flex items-center">
                      <img src={RunzyLogo} className="h-7" alt="Runzy" />
                    </div>
                  </IonRouterLink>

                  <div className="hidden md:ml-6 md:mt-1.5 md:flex md:items-center md:space-x-4">
                    {headerNavigation.map((item, i) => (
                      <div key={i}>
                        {(!item.requiresSuperAdmin ||
                          (item.requiresSuperAdmin &&
                            isSuperAdmin(userInfo!))) && (
                          <a
                            key={item.name}
                            onClick={(e) => {
                              e.preventDefault();
                              if (item.action) {
                                item.action();
                              }
                            }}
                            className={classNames(
                              item.current
                                ? "bg-[#FDFDFD] text-black"
                                : "text-gray-300 hover:bg-gray-300 hover:text-black cursor-pointer",
                              "px-3 py-1 rounded-md text-sm font-medium cursor-pointer"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
                <Popover.Button className="inline-flex items-center justify-center p-2 -mx-2 text-gray-400 rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Popover.Button>
              </div>
              <div className="hidden lg:flex lg:items-center lg:justify-end">
                <Menu as="div" className="relative flex-shrink-0 ml-5">
                  <div className="flex items-center gap-2">
                    <Menu.Button className="flex items-center">
                      <span className="sr-only">Open user menu</span>

                      <ProfilePicture
                        classNames="w-8 h-8 rounded-full"
                        url={userInfo?.profileImage ?? ""}
                        alt=""
                      />
                      <div className="ml-3">
                        <div className="text-sm text-neutral-900 text-left">
                          {userInfo?.firstName} {userInfo?.lastName}
                        </div>
                        <div className="text-xs text-neutral-500 text-left">
                          {roleDescription(userInfo!)}
                        </div>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-50 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item, i) => (
                        <Menu.Item key={i}>
                          {({ active }) => (
                            <a
                              onClick={(e) => {
                                item.onClick();
                              }}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-1 px-4 text-sm text-gray-700 cursor-pointer"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="max-w-3xl px-2 py-3 mx-auto space-y-1 sm:px-4">
              {headerNavigation.map((item, key) => (
                <a
                  key={key}
                  onClick={(e) => {
                    e.preventDefault();
                    if (item.action) {
                      item.action();
                    }
                  }}
                  aria-current={item.current ? "page" : undefined}
                  className={classNames(
                    item.current
                      ? "bg-gray-100 text-gray-900"
                      : "hover:bg-gray-50",
                    "block rounded-md py-1 px-3 text-base font-medium"
                  )}
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="max-w-3xl px-2 py-3 pt-4 mx-auto space-y-1 border-t border-gray-200 sm:px-4">
              {navigation.map((item, i) => (
                <a
                  key={i}
                  href={item.href}
                  aria-current={item.current ? "page" : undefined}
                  className={classNames(
                    item.current
                      ? "bg-gray-100 text-gray-900"
                      : "hover:bg-gray-50",
                    "block rounded-md py-1 px-3 text-base font-medium"
                  )}
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="pt-4 border-t border-gray-200">
              <div className="flex items-center max-w-3xl px-4 mx-auto sm:px-6">
                <div className="flex-shrink-0">
                  <img
                    className="w-10 h-10 rounded-full"
                    src={userInfo?.profileImage}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {userInfo?.firstName} {userInfo?.lastName}
                  </div>
                  <div className="text-sm font-medium text-gray-500"></div>
                </div>
                <CoachHeaderSwitch
                  setShowAllAthletes={setShowAllAthletes}
                  showAllAthletes={showAllAthletes}
                />
              </div>

              <div className="max-w-3xl px-2 mx-auto mt-3 space-y-1 sm:px-4">
                {userNavigation.map((item, i) => (
                  <a
                    key={i}
                    className="block px-3 py-1 text-base font-medium text-gray-500 rounded-md cursor-pointer hover:bg-gray-50 hover:text-gray-900"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}

export default CoachHeader;
