import { InformationCircleIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";

function ConfirmModal({
  showModal,
  setShowModal,
  confirmChanges,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  confirmChanges: () => void;
}) {
  const [confirmSave, setConfirmSave] = useState(false);

  useEffect(() => {
    if (!showModal && confirmSave) {
      confirmChanges();
    }
  }, [showModal, confirmSave]);

  return (
    <div className="mt-8 text-center">
      <div className="flex items-center justify-center">
        <InformationCircleIcon className="w-8 h-8 text-[#F59E0B]" />
      </div>
      <h1 className="mt-3  font-medium text-[16px] text-black">
        Confirm Your Changes{" "}
      </h1>
      <p className="text-[14px] mx-6 mt-3 text-neutral-500">
        Are you sure you want to save changes? We only recommend making changes
        if you need to make adjustments in your week.
      </p>
      <div className="mx-3 space-y-3">
        {" "}
        <button
          onClick={() => setShowModal(false)}
          className="flex items-center text-[18px] justify-center w-full py-2  mt-4 h-[48px] text-black bg-white border border-gray-300 rounded-full"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            setShowModal(false);
            setConfirmSave(true);
          }}
          className="flex items-center text-[18px] bg-runzy-red-orange justify-center w-full h-[48px] mt-4 text-white rounded-full"
        >
          Confirm
        </button>
      </div>
    </div>
  );
}

export default ConfirmModal;
