import { PlusIcon } from "@heroicons/react/outline";

export default function CreateStep({
  onClick,
  noText,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  noText?: boolean;
}) {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="bg-primary-100 text-primary-500 flex items-center rounded-full px-[10px] py-[6px] space-x-[11px] focus:outline-none focus:ring-0 font-medium text-[14px] leading-[20px] inline-flex space-x-1"
    >
      <PlusIcon className="w-4 h-4" />
      {noText ? null : <p>Add a step</p>}
    </button>
  );
}
