import { useEffect, useState } from "react";
import { editTrainingAttributes } from "../../../Api/TrainingAttribute";
import { useTrainingAttributes } from "../../../Context/TrainingAttributeContext";
import { useUserInfo } from "../../../Context/UserContext";

function useNotifications() {
  const [sendDailyWorkoutNotification, setSendDailyWorkoutNotification] = useState<boolean>();
  const { trainingAttributes } = useTrainingAttributes();
  const { userInfo } = useUserInfo();


  async function submitSendDailyWorkoutNotification(newValue: boolean) {
    if (!trainingAttributes || !userInfo) return null;

    try {
      await editTrainingAttributes(
          {
            ...trainingAttributes,
            sendDailyWorkoutNotification: newValue
          },
          userInfo.id
      );
      return 200;
    } catch (e) {
      return null;
    }
  }


  async function handleSetSendDailyWorkoutNotification(enabled: boolean) {
    setSendDailyWorkoutNotification(enabled);
    await submitSendDailyWorkoutNotification(enabled);
  }

  useEffect(() => {
    if (trainingAttributes) {
      setSendDailyWorkoutNotification(trainingAttributes.sendDailyWorkoutNotification);
    }
  }, [trainingAttributes]);

  return {
    sendDailyWorkoutNotification,
    handleSetSendDailyWorkoutNotification
  };
}

export interface useNotificationsOutput {
  sendDailyWorkoutNotification: boolean | undefined;
  handleSetSendDailyWorkoutNotification: (enabled: boolean) => void;
}

export default useNotifications;
