import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { useTrainingAttributes } from "../../Context/TrainingAttributeContext";
import {useLocalStorage} from 'usehooks-ts';

const ctaText = `Connect your Strava or another account so Runzy can automatically import your workout results.`;

function NoExternalAccountBanner() {
  const { trainingAttributes } = useTrainingAttributes();

  const [collapseNotification, setCollapseNotification] =
      useLocalStorage("stravaNotificationCollapse", false);

  if (trainingAttributes != null && trainingAttributes?.accounts?.length > 0) {
    return <></>;
  }

  return collapseNotification ? (
    <div
      className="relative mx-auto cursor-pointer max-w-7xl sm:px-6 lg:px-8"
      onClick={() => setCollapseNotification(!collapseNotification)}
    >
      <div className="relative inline-block px-2 mt-10 w-14 float-right">
        <div className="p-2 rounded-lg shadow-lg bg-brand">
          <ExclamationCircleIcon
            className="w-6 h-6 text-white"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="relative mx-auto mt-12 max-w-7xl sm:px-6 lg:px-8">
      <button
        className="absolute inline-flex items-center justify-center bg-brand md:rounded-full md:right-4 right-0 w-8 h-8 z-10 md:-top-2 p-1 font-medium text-white"
        onClick={() => setCollapseNotification(!collapseNotification)}
      >
        x
      </button>
      <div className="p-2 rounded-lg shadow-lg bg-brand">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center flex-1 w-0">
            <span className="flex p-2 rounded-lg bg-brand">
              <ExclamationCircleIcon
                className="w-6 h-6 text-white"
                aria-hidden="true"
              />
            </span>
            <p className="ml-3 font-medium text-white">{ctaText}</p>
          </div>
          <div className="flex-shrink-0 order-3 w-full mt-2 sm:order-2 sm:mt-0 sm:w-auto">
            <Link
              to="/profile/settings/connect-strava"
              className="flex items-center justify-center px-4 py-2 text-sm font-medium bg-white border border-transparent rounded-md shadow-sm text-brand hover:bg-indigo-50"
            >
              Finish setup.
            </Link>
          </div>
          <div className="flex-shrink-0 order-2 sm:order-3 sm:ml-2">
            <div className="flex p-2 -mr-1 rounded-md hover:bg-brand focus:outline-none focus:ring-2 focus:ring-white"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoExternalAccountBanner;
