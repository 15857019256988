import Spinner from '../../Coachee/Components/Spinner'
import AdminFilter from '../Components/AdminFilter'
import SmallToggle from '../Components/SmallToggle'
import useAdmin from '../Hooks/useAdmin'
import useAdminFilter, { AthleteOrderBy } from '../Hooks/useAdminFilter'
import { classNames } from "../../Utilities/classnames";
import { useLocalStorage } from 'usehooks-ts'
import CoachHeader from '../Components/CoachHeader'
import { useContext } from 'react'
import { UserInfoContext } from '../../Context/UserContext'
import { IonContent, IonPage } from '@ionic/react'
import ProfilePicture from '../../Utilities/Components/ProfilePicture'
import useAthleteRatingIcon from '../Hooks/useAthleteRatingIcon'
import format from 'date-fns/format'
import DefaultWebFooter from '../Components/Footer/DefaultWebFooter'
import { isMobile } from '../../Utilities/isMobile'
import { ColumnSortable } from '../../Components/ColumnSortable'
import ComboBox from "../Combobox/ComboBox";
import OnboardingProgressDropdown from '../Components/OnboardingProgressDropdown'

function Admin() {
  const { userInfo } = useContext(UserInfoContext);

  const setShowAllAthletes = useLocalStorage("showAllAthletes", false)[1];

  const {
    filters,
    filteredOnboardingProgressStatuses,
    searchQuery,
    toggleFilteredOnboardingProgressStatuses,
    toggleSearch,
    toggleTestUsers,
    enableTestUsers,
    orderBy,
    orderDir,
    changeOrder
  } = useAdminFilter();

  const {
    state,
    updateAthletesCoach,
    toggleAthleteEmail,
    hasChanged,
    saveProfileAttribute,
    toggleOnboardingProgress,
  } = useAdmin({
    enableTestUsers,
    filteredOnboardingProgressStatuses,
    searchQuery,
    orderBy,
    orderDir,
  });

  const { getRatingIconByStatus } = useAthleteRatingIcon();

  return (
    <IonPage>
      <IonContent>
        {userInfo && (
          <>
            <CoachHeader
              showAllAthletes={true}
              setShowAllAthletes={setShowAllAthletes}
            />
            <div className="min-h-screen bg-gray-50">
              <div className="py-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="px-4 sm:px-6 lg:px-0">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-xl font-semibold text-gray-900">
                        Admin Athlete Management
                      </h1>
                      <p className="mt-2 text-sm text-gray-700"></p>
                    </div>
                  </div>
                  <div className="flex flex-col mt-8">
                    <div className="mb-4">
                      <AdminFilter
                        enableTestUsers={enableTestUsers}
                        toggleTestUsers={toggleTestUsers}
                        results={state.meta.total}
                        filters={filters}
                        filteredOnboardingProgressStatuses={filteredOnboardingProgressStatuses}
                        toggleFilteredOnboardingProgressStatuses={toggleFilteredOnboardingProgressStatuses}
                        toggleSearch={toggleSearch}
                      />
                    </div>

                    <div className="-mx-4 -my-2 overflow-x-visible overflow-y-visible sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-visible shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <ColumnSortable
                                  show={orderBy === AthleteOrderBy.ATHLETE}
                                  dir={orderDir}
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  onClick={() => changeOrder(AthleteOrderBy.ATHLETE)}
                                >
                                  Athlete
                                </ColumnSortable>
                                <ColumnSortable
                                  show={orderBy === AthleteOrderBy.COACH}
                                  dir={orderDir}
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  onClick={() => changeOrder(AthleteOrderBy.COACH)}
                                >
                                  Coach
                                </ColumnSortable>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Status
                                </th>
                                <ColumnSortable
                                  show={orderBy === AthleteOrderBy.ONBOARDING_PROGRESS}
                                  dir={orderDir}
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  onClick={() => changeOrder(AthleteOrderBy.ONBOARDING_PROGRESS)}
                                >
                                  Life-cycle
                                </ColumnSortable>
                                <ColumnSortable
                                  show={orderBy === AthleteOrderBy.CREATED}
                                  dir={orderDir}
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  onClick={() => changeOrder(AthleteOrderBy.CREATED)}
                                >
                                  Date joined
                                </ColumnSortable>
                                <ColumnSortable
                                  show={orderBy === AthleteOrderBy.DAILY_UPDATES_ENABLED}
                                  dir={orderDir}
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  onClick={() => changeOrder(AthleteOrderBy.DAILY_UPDATES_ENABLED)}
                                >
                                  Daily Email
                                </ColumnSortable>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                ></th>
                              </tr>
                            </thead>
                            {!state.isDisabled ? (
                              <tbody className="bg-white divide-y divide-gray-200">
                                {state.assignments.map((assignment, index) => (
                                  <tr key={index}>
                                    <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
                                      <a
                                        className="flex items-center cursor-pointer"
                                        href={`/coach/${assignment.athlete.idHash}/calendar`}
                                      >
                                        <div className="flex-shrink-0 w-10 h-10 cursor-pointer">
                                          <ProfilePicture
                                            classNames="w-10 h-10 rounded-full"
                                            url={
                                              assignment.athlete.profileImage
                                            }
                                          />
                                        </div>
                                        <div className="ml-4 overflow-hidden max-w-[18rem]">
                                          <div className="font-medium text-gray-900  truncate">
                                            {assignment.athlete.name.length <= 1
                                              ? assignment.athlete.email
                                              : assignment.athlete.name}{" "}
                                            (
                                            {
                                              assignment.athlete.id
                                            }
                                            )
                                          </div>
                                          <div className="text-gray-500">
                                            {assignment.athlete.productDescription}
                                          </div>
                                        </div>
                                      </a>
                                    </td>
                                    <td
                                      className="px-3 py-4 text-sm whitespace-nowrap"
                                      style={{ minWidth: "150px" }}
                                    >
                                      <ComboBox
                                        onChange={(coachId: string) => {
                                          updateAthletesCoach(
                                            assignment.athlete.id.toString(),
                                            coachId
                                          );
                                        }}
                                        selected={assignment.coach}
                                        options={state.trainingAdmins}
                                      />
                                    </td>
                                    <td className="px-3 py-4 text-sm whitespace-nowrap">
                                      {getRatingIconByStatus(assignment.athlete.status, assignment.athlete.feedbacks)}
                                    </td>
                                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                      <OnboardingProgressDropdown
                                        setSelected={(value: number) => {
                                          toggleOnboardingProgress(
                                            assignment,
                                            value
                                          );
                                        }}
                                        selectedStatus={
                                          assignment.onboardingProgress
                                        }
                                      />
                                    </td>
                                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                      {format(new Date(assignment.athlete.created), 'MM/dd/yyyy')}
                                    </td>
                                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                      <SmallToggle
                                        isEnabled={assignment.dailyUpdatesEnabled}
                                        setIsEnabled={() =>
                                          toggleAthleteEmail(assignment.athlete.id)
                                        }
                                      />
                                    </td>
                                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                      <button
                                        disabled={!hasChanged(assignment)}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          saveProfileAttribute(assignment);
                                        }}
                                        type="button"
                                        className={classNames(
                                          "inline-flex items-center rounded-md bg-brand px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                                        )}
                                      >
                                        Save
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan={7}>
                                    <Spinner />
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isMobile && <DefaultWebFooter />}
          </>
        )}
      </IonContent>
    </IonPage>
  );
}

export default Admin;
