export enum NavigationOption {
  PROFILE_SETTINGS = "PROFILE_SETTINGS",
  AVAILABILITY = "AVAILABILITY",
  CONNECT_STRAVA = "CONNECT_STRAVA",
  MANAGE_STRAVA = "MANAGE_STRAVA",
  CONNECT_APPLE_HEALTH = "CONNECT_APPLE_HEALTH",
  NOTIFICATIONS = "NOTIFICATIONS",
  MEMBERSHIP = "MEMBERSHIP",
  CONNECT_TERRA = "CONNECT_TERRA",
  MANAGE_TERRA = "MANAGE_TERRA",
}

export function navigate(option: NavigationOption, id?: string) {
  switch (option) {
    case NavigationOption.PROFILE_SETTINGS:
      window.location.href = "/profile/settings";
      break;
    case NavigationOption.AVAILABILITY:
      window.location.href = "/profile/settings/availability";
      break;
    case NavigationOption.CONNECT_STRAVA:
      window.location.href = "/profile/settings/connect-strava";
      break;
    case NavigationOption.MANAGE_STRAVA:
      window.location.href = "/profile/settings/manage-strava";
      break;
    case NavigationOption.CONNECT_APPLE_HEALTH:
      window.location.href = "/profile/settings/connect-apple-health";
      break;
    case NavigationOption.NOTIFICATIONS:
      window.location.href = "/profile/settings/notifications";
      break;
    case NavigationOption.MEMBERSHIP:
      window.location.href = "/profile/settings/membership";
      break;
    case NavigationOption.CONNECT_TERRA:
      window.location.href = "/profile/settings/connect-terra";
      break;
    case NavigationOption.MANAGE_TERRA:
      if (id) {
        window.location.href = `/profile/settings/manage-terra/${id}`;
      }
      break;
    default:
      break;
  }
}
