import axios from "axios";

export type SettingOption = {
  settingName: string;
  settingValue: string;
};

export async function updateSetting(
  settingOption: SettingOption
): Promise<object | null> {
  try {
    return await axios.post("settings", settingOption);
  } catch (error: any) {
    return null;
  }
}

export async function signOutOfStrava(): Promise<object | null> {
  const settingOption: SettingOption = {
    settingName: "signOutOfActivityProvider",
    settingValue: "true",
  };
  return updateSetting(settingOption);
}

export async function signOutOfSpotify(): Promise<object | null> {
  const settingOption: SettingOption = {
    settingName: "signOutOfMusicProvider",
    settingValue: "Spotify",
  };
  return updateSetting(settingOption);
}

export async function setOverrideTitleSetting(value: boolean): Promise<object | null> {
  const settingOption: SettingOption = {
    settingName: "overrideActivityTitleSetting",
    settingValue: value ? "true" : "false",
  };
  return updateSetting(settingOption);
}

export async function setOverrideBodySetting(value: boolean): Promise<object | null> {
  const settingOption: SettingOption = {
    settingName: "overrideActivityBodySetting",
    settingValue: value ? "true" : "false",
  };
  return updateSetting(settingOption);
}

export async function setDailyMixSetting(value: boolean): Promise<object | null> {
  const settingOption: SettingOption = {
    settingName: "enableDailyMixSetting",
    settingValue: value ? "true" : "false",
  };
  return updateSetting(settingOption);
}



export async function deleteAccount(token: string): Promise<object | null> {
  const settingOption: SettingOption = {
    settingName: "deleteAccount",
    settingValue: token,
  };
  return updateSetting(settingOption);
}

export async function setAppleDeviceID(
  deviceId: string
): Promise<object | null> {
  const settingOption: SettingOption = {
    settingName: "setSecondaryAppleDeviceID",
    settingValue: deviceId,
  };
  return updateSetting(settingOption);
}

export async function setAndroidDeviceID(
  deviceId: string
): Promise<object | null> {
  const settingOption: SettingOption = {
    settingName: "setAndroidDeviceID",
    settingValue: deviceId,
  };
  return updateSetting(settingOption);
}
