import axios from "axios";

export async function createPost(
  feedOwnerProfileId: string,
  message: string
): Promise<number | null> {
  try {
    const response = await axios.post("posts", {
      feedOwnerProfileId: feedOwnerProfileId,
      message: message,
    });

    return 200;
  } catch (error: any) {
    return null;
  }
}

export async function editPost(streamId: string, message: string) {
  try {
    const response = await axios.put("posts", {
      streamId: `perform:streamActivity:${streamId}`,
      message: message,
    });

    return 200;
  } catch (error: any) {
    return null;
  }
}

export async function deletePost(streamId: string) {
  try {
    const response = await axios.delete(
      `posts/perform:streamActivity:${streamId}`
    );

    return 200;
  } catch (error: any) {
    return null;
  }
}
