import axios from "axios";
import {ExternalAccount} from "../Types";
import {decodeDateFromSecondsFormat, encodeSecondsTimestampFromDate,} from "../Utilities/DateUtilities";
import {encodeDuration, formatDuration} from "../Utilities/FormatUtilties";
import {PaceZone} from "./PaceZones";
import { AthleteUpdateAttribute } from '../Coach/Hooks/useAdmin'

export const subscriptionPlans = ["Basic", "Core", "Plus"];

export type trainingAttributes = {
  id: string;
  runningMetrics: any[];
  trainingZones: any[];
  notes: string;
  emailEnabled: boolean;
  nextEmailSendDate: Date;
  lastEmailSendDate: Date | null;
  emailSubject: string;
  emailAddress: string;
  emailPreviewUrl: string;
  goalDate: string | null;
  goalName: string | null;
  weight: string | null;
  height: string | null;
  age: string | null;
  gender: string | null;
  blockedDays: string[];
  onboardingProgress: number;
  goalDistance: number | null;
  goalTime: string | null;
  stravaProfileUrl: string | null;
  trainingAdmin: string;
  trainingAdminProfile: any | null;
  stravaUpdatesEnabled: boolean;
  stravaTitleUpdatesEnabled: boolean;
  stravaStreakUpdatesEnabled: boolean;
  stravaScoreUpdatesEnabled: boolean;
  stravaGoalUpdatesEnabled: boolean;
  accounts: ExternalAccount[];
  quickNotes: string;
  productName: string | null;
  productIdOverrideExpiresAt: number | null;
  paceZones: PaceZone[];
  isPaceZoneOverride: boolean;
  sendDailyWorkoutNotification: boolean;
  initialWeeklyRunningDistance: number;
};

export const formatProviderName = (provider: string): string =>
  provider.charAt(0).toUpperCase() + provider.slice(1).toLowerCase();

const formatAccountFromApi = (apiAccount: any): ExternalAccount => {
  const connectedAt = apiAccount.connectedAt
    ? new Date(apiAccount.connectedAt * 1000)
    : null;

  return {
    ...apiAccount,
    provider: formatProviderName(apiAccount.provider),
    connectedAt,
  };
};

export const formatTrainingAttributesFromApi = (
  data: any
): trainingAttributes => {
  const lastEmailSendDate = data.lastEmailSendDate
    ? decodeDateFromSecondsFormat(data.lastEmailSendDate)
    : null;

  const accounts = data.accounts ? data.accounts.map(formatAccountFromApi) : [];

  return {
    ...data,
    lastEmailSendDate,
    nextEmailSendDate: decodeDateFromSecondsFormat(data.nextEmailSendDate),
    goalTime: data.goalTime ? formatDuration(data.goalTime) : null,
    accounts,
  } as trainingAttributes;
};

export async function getTrainingAttributes(
  TrainingProfileId: string
): Promise<trainingAttributes | null> {
  try {
    const response = await axios.get(
      `training-attributes/${TrainingProfileId}`
    );

    return formatTrainingAttributesFromApi(response.data);
  } catch (e: any) {
    return null;
  }
}

export const updateAthlete = async (attributes: AthleteUpdateAttribute) => {
  const response = await axios.put(`athletes/${attributes.id}`, attributes)

  return response.data
}

export async function editTrainingAttributes(
  attributes: trainingAttributes,
  TrainingProfileId: string
): Promise<trainingAttributes | null> {
  try {
    const trainingAttributes: any = attributes;

    if (trainingAttributes.goalTime) {
      trainingAttributes.goalTime = encodeDuration(trainingAttributes.goalTime);
    }

    // ensure no duplicates
    trainingAttributes.blockedDays = attributes.blockedDays
      .sort()
      .filter(function (item, pos, ary) {
        return !pos || item != ary[pos - 1];
      });

    const response = await axios.put(
      `training-attributes/${TrainingProfileId}`,
      trainingAttributes
    );

    response.data.goalTime = formatDuration(response.data.goalTime);

    return formatTrainingAttributesFromApi(response.data);
  } catch (e: any) {
    return null;
  }
}

export async function markBasicUserAsFixed(
  TrainingProfileId: string,
  isNull = false
) {
  try {
    const data = {
      trainingAdminReviewedAt: isNull
        ? null
        : encodeSecondsTimestampFromDate(new Date(), false),
    };

    const response = await axios.put(
      `training-attributes/${TrainingProfileId}`,
      data
    );

    return response.data;
  } catch (error: any) {
    return null;
  }
}

export async function markUserAsTest(
    TrainingProfileId: string,
    isTest = false
) {
  try {
    const data = {
      isTest: isTest,
    };

    const response = await axios.put(
        `training-attributes/${TrainingProfileId}`,
        data
    );

    return response.data;
  } catch (error: any) {
    return null;
  }
}

export async function updateUserName(TrainingProfileId: string, name: string) {
  return new Promise((resolve, reject) => {
    axios.put(`training-attributes/${TrainingProfileId}`, {name})
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
  })
}

export type onboardingProgressStatus = {
  id: number;
  description: string;
};

export const onboardingProgressStatuses: onboardingProgressStatus[] = [
  { id: -1, description: "Churned" },
  { id: 1, description: "Completed intake survey" },
  { id: 2, description: "Pending payment" },
  { id: 3, description: "Pending coach assignment" },
  { id: 4, description: "Assigned to coach" },
  { id: 5, description: "Subscription Paused" },
  { id: 0, description: "New" },
  { id: 6, description: "Scheduled intro call" },
  { id: 7, description: "Canceled intro call" },
];

export const defaultOnboardingProgressStatuses: onboardingProgressStatus[] = [
  onboardingProgressStatuses[4],
];

export const defaultOnboardingProgressStatusesNavBar: onboardingProgressStatus[] =
  [
    onboardingProgressStatuses[3],
    onboardingProgressStatuses[4],
    onboardingProgressStatuses[5],
  ];

export function getOnboardingProgressDescription(status: number) {
  const description = onboardingProgressStatuses.find(
    (onboardingStatus) => onboardingStatus.id === status
  )?.description;

  if (description) return description;

  return onboardingProgressStatuses[0].description;
}

export function isFree(attribute: trainingAttributes): boolean {
  return attribute.productName === "Free";
}
