import PowerIcon from "../../../../Assets/PowerIcon.svg";

function CoachLabel() {
  return (
    <div
      className="flex items-center gap-1 px-3 py-2 rounded-full"
      style={{
        background: "linear-gradient(91.88deg, #F6747E 5.14%, #FF6388 99.6%)",
      }}
    >
      <img src={PowerIcon} />
      <p className="text-sm text-white ">Coach</p>
    </div>
  );
}

export default CoachLabel;
