import { StreamClient } from "getstream";
import { useEffect, useState } from "react";
import { editPost } from "../../Api/Post";
import { Post } from "../../Api/Stream";

interface useCoachPostInput {
  post: Post;
  streamClient?: StreamClient;
}

function useCoachPost({ post, streamClient }: useCoachPostInput) {
  const [editMode, setEditMode] = useState(false);
  const [postContent, setPostContent] = useState(post.message);

  function enableEditMode() {
    setEditMode(true);
  }

  async function savePost() {
    if (streamClient) {
      const response = await editPost(post.id, postContent);
      if (response) {
        post.message = postContent;
        setEditMode(false);
      }
    }
  }

  function cancelEdit() {
    setPostContent(post.message);
    setEditMode(false);
  }

  useEffect(() => {
    setPostContent(post.message);
  }, [post.message]);

  return {
    editMode,
    enableEditMode,
    savePost,
    cancelEdit,
    postContent,
    setPostContent,
  } as useCoachPostOutput;
}

export interface useCoachPostOutput {
  editMode: boolean;
  enableEditMode: () => void;
  savePost: () => void;
  cancelEdit: () => void;
  postContent: string;
  setPostContent: React.Dispatch<React.SetStateAction<string>>;
}

export default useCoachPost;
