/* eslint-disable */
// TODO: @wbert refactor seven day distance eslint issue

import { useEffect, useState } from "react";
import Spinner from "../../Coachee/Components/Spinner";
import useAthletes from "../Hooks/useAthletes";
import AthleteRow from "./AthleteRow";
import EmptyState from "./EmptyState";
import PageHeader from "./PageHeader";
import TableHeader from "./TableHeader";
import Tabs from "./Tabs";

interface AthletesInput {
  showAllAthletes: boolean;
}

export type sortingOptions = "name" | "status";

function Athletes({ showAllAthletes }: AthletesInput) {
  const athletesHook = useAthletes({ showAllAthletes });

  const [currentSortingOption, setCurrentSortingOption] =
    useState<sortingOptions>("status");
  const [isReversed, setIsReversed] = useState(false);

  const statusSortingOrder = ["NEEDS_ATTENTION", "OKAY", "GOOD"];

  const selectedAthletes = athletesHook
    .athletesToRender()
    ?.sort(function (a, b) {
      let sortIndex = 0;
      if (currentSortingOption === "name") {
        // return a.miniProfile.name - b.miniProfile.name;
        sortIndex = a.miniProfile.name.localeCompare(b.miniProfile.name);
      } else {
        sortIndex =
          statusSortingOrder.indexOf(a.coachingStatus) -
          statusSortingOrder.indexOf(b.coachingStatus);
      }

      if (isReversed) {
        return sortIndex * -1;
      }
      return sortIndex;
    });

  return (
    <div className="flex items-center w-full">
      <div className="flex flex-col flex-1 h-full">
        <main className="bg-gray-50">
          <div className="py-10 mx-auto max-w-7xl">
            <div className="px-4 sm:px-6 lg:px-8">
              <PageHeader />
              <Tabs athletesHook={athletesHook} />
              <div className="flex flex-col mt-8">
                {!athletesHook.isLoading ? (
                  <div
                    id="athleteTable"
                    className="z-10 -mx-4 -my-2 overflow-y-visible sm:-mx-6 lg:-mx-8"
                  >
                    {athletesHook.isLoading ||
                    (selectedAthletes && selectedAthletes.length > 0) ? (
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-visible shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-300">
                            <TableHeader
                              option={currentSortingOption}
                              reversed={isReversed}
                              setIsReversed={setIsReversed}
                              setOption={setCurrentSortingOption}
                            />
                            <tbody className="bg-white divide-y divide-gray-200">
                              {selectedAthletes &&
                                selectedAthletes.map((profile, index) => (
                                  <AthleteRow
                                    key={index}
                                    profile={profile}
                                    showAllAthletes={showAllAthletes}
                                    athletesHook={athletesHook}
                                  />
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <EmptyState />
                    )}
                  </div>
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Athletes;
