import { useContext, useState } from "react";

import { trainingEvent } from "../../Api/TrainingEvent";

import Spinner from "./Spinner";

import Back from "../../Assets/Back.svg";

import ActivityHeader from "./ActivityHeader";
import ActivityContent, { ContentOptions } from "./ActivityContent";
import { UserInfoContext } from "../../Context/UserContext";
import CoacheeHeader from "./CoacheeHeader";
import { IonContent, IonRouterLink } from "@ionic/react";
import { isMobile } from "../../Utilities/isMobile";
import MobilePageHeader from "./MobilePageHeader";
import { useTrainingAttributes } from "../../Context/TrainingAttributeContext";
import CommentBox from "../Activity/Feedback/CommentBox";
import useActivityFeedback from "../Hooks/useActivityFeedback";
import DefaultWebFooter from "../../Coach/Components/Footer/DefaultWebFooter";

interface CoacheeActivityInput {
  selectedTab: ContentOptions;
  trainingEvent?: trainingEvent;
}

function CoacheeActivity({ selectedTab, trainingEvent }: CoacheeActivityInput) {
  const { userInfo } = useContext(UserInfoContext);
  const { trainingAttributes } = useTrainingAttributes();
  const [hasScrolled, setHasScrolled] = useState(false);

  const activityFeedback = useActivityFeedback({
    trainingEvent: trainingEvent,
  });

  return (
    <>
      {isMobile ? (
        <MobilePageHeader title="Activity" hasScrolled={hasScrolled} />
      ) : (
        <CoacheeHeader trainingAttributes={trainingAttributes} />
      )}
      {userInfo && (
        <>
          <IonContent
            className=""
            scrollEvents={true}
            onIonScroll={(e) => setHasScrolled(e.detail.scrollTop !== 0)}
          >
            {userInfo && (
              <>
                {trainingEvent && trainingAttributes ? (
                  <div className="md:h-full md:h-fit lg:max-w-xl lg:mx-auto lg:mt-20">
                    <div>
                      <IonRouterLink
                        routerLink="/dashboard"
                        routerDirection="back"
                      >
                        <button className="items-center self-start hidden mb-12 ml-6 space-x-1 text-xs text-gray-400 rounded md:flex hover:text-gray-500">
                          <img src={Back} alt="Back" className="w-auto h-4" />
                          <p>Back</p>
                        </button>
                      </IonRouterLink>

                      <div className="flex flex-col h-full px-6 pt-8 text-left md:h-3/4">
                        <ActivityHeader trainingEvent={trainingEvent} />
                      </div>

                      <ActivityContent
                        trainingEvent={trainingEvent}
                        trainingAttributes={trainingAttributes}
                        selectedTab={selectedTab}
                        activityFeedback={activityFeedback}
                      />
                    </div>
                  </div>
                ) : (
                  <Spinner />
                )}
              </>
            )}
            {/* eslint-disable-next-line no-restricted-globals */}
            {location.pathname.endsWith("feedback") && isMobile && (
              <div
                slot="fixed"
                className="bottom-0 flex flex-col justify-center w-full grow md:h-2"
              >
                <div>
                  <CommentBox
                    trainingEvent={trainingEvent}
                    activityFeedback={activityFeedback}
                  />
                </div>
              </div>
            )}
            {!isMobile && <DefaultWebFooter />}
          </IonContent>
        </>
      )}
    </>
  );
}

export default CoacheeActivity;
