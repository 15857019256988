import { useContext } from "react";
import { TrainingStats } from "../../Api/TrainingProfile";

import LastSevenDays from "../../Coachee/Profile/Stats/LastSevenDays";
import LastTwentyEightDays from "../../Coachee/Profile/Stats/LastTwentyEightDays";
import { UserInfoContext } from "../../Context/UserContext";
import { formatDistanceForAthletePage } from "../../Utilities/FormatUtilties";

interface StatsInput {
  stats: TrainingStats;
}

function Stats({ stats }: StatsInput) {
  const { userInfo } = useContext(UserInfoContext);

  return (
    <div className="">
      <div className="py-4 mb-6 bg-white border border-gray-10 rounded-xl">
        <h4 className="px-4 mb-4 font-semibold text-gray-900">
          Runzy Milestones
        </h4>
        <div className="divide-y-2 divide-gray-100">
          <div className="flex items-center gap-2 px-4 pb-4">
            <p className="text-xl font-semibold text-red-500">
              {formatDistanceForAthletePage(
                stats.totalDistance,
                userInfo?.profileMetricSetting,
                true,
                false,
                true,
                undefined,
                true
              )}
            </p>
          </div>
          <div className="flex items-center gap-2 px-4 pt-4">
            <p className="text-xl font-semibold text-red-500">
              {stats.totalRuns} {stats.totalRuns === 1 ? "run" : "runs"}
            </p>
          </div>
        </div>
      </div>

      <div className="py-4 mb-6 bg-white border border-gray-10 rounded-xl">
        <h4 className="px-4 mb-4 font-semibold text-gray-900">Streaks</h4>
        <div className="divide-y-2 divide-gray-100">
          <div className="flex items-center gap-2 px-4 pb-2">
            <p className="text-xl font-semibold text-red-500">
              {stats.trainingStreak}{" "}
              {stats.trainingStreak === 1 ? "day" : "days"}
            </p>
            {stats.allTimeTrainingStreak <= stats.trainingStreak && (
              <p className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
                Best
              </p>
            )}
          </div>
        </div>
      </div>

      <LastSevenDays stats={stats} />
      <LastTwentyEightDays stats={stats} />
    </div>
  );
}

export default Stats;
