import axios from "axios";
import { TrainingStats } from "./TrainingProfile";

export async function getStats(): Promise<TrainingStats | null> {
  try {
    const response = await axios.get("/training-stats/me");

    return response.data;
  } catch (error: any) {
    return null;
  }
}
