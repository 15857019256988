import { trainingProfile } from "../../Api/TrainingProfile";
import { useState, useEffect } from "react";
import {
  editTrainingAttributes,
  getTrainingAttributes,
  trainingAttributes,
} from "../../Api/TrainingAttribute";
import { formatDateForEmail } from "../../Utilities/DateUtilities";
import { Switch } from "@headlessui/react";
import { classNames } from "../../Utilities/classnames";
import { getEmailPreviewContentForAthlete } from "../../Api/Email";

interface EmailInput {
  selectedTrainingProfile: trainingProfile;
}

function Email({ selectedTrainingProfile }: EmailInput) {
  const [emailPreviewContent, setEmailPreviewContent] = useState<string>("");
  const [emailEnabled, setEmailEnabled] = useState<boolean>(false);
  const [trainingAttributes, setTrainingAttributes] =
    useState<trainingAttributes>();

  async function initIFrameAndEmailEnabled() {
    if (selectedTrainingProfile) {
      const trainingAttributes = await getTrainingAttributes(
        selectedTrainingProfile.miniProfile.id
      );

      if (trainingAttributes) {
        setTrainingAttributes(trainingAttributes);

        const emailContent = await getEmailPreviewContentForAthlete(
          trainingAttributes.emailPreviewUrl
        );
        if (emailContent) {
          setEmailPreviewContent(emailContent);
        }

        const isEmailEnabled = trainingAttributes.emailEnabled;
        setEmailEnabled(isEmailEnabled);
      }
    }
  }

  async function handleSwitchChange() {
    const confirmation = window.confirm(
      `Are you sure you want to ${emailEnabled ? "disable" : "enable"} emails?`
    );

    if (confirmation && trainingAttributes) {
      const updatedTrainingAttributes = trainingAttributes;

      updatedTrainingAttributes.emailEnabled =
        !updatedTrainingAttributes.emailEnabled;

      if (selectedTrainingProfile) {
        const response = await editTrainingAttributes(
          updatedTrainingAttributes,
          selectedTrainingProfile.miniProfile.id
        );

        if (response) {
          setEmailEnabled(response.emailEnabled);
        }
      }
    }
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      initIFrameAndEmailEnabled();
    }

    return () => {
      mounted = false;
    };
  }, [selectedTrainingProfile]);

  return (
    <>
      <div className="sm:px-6">
        <form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <div className="space-y-6 sm:space-y-5">
                <div className="items-center sm:grid sm:grid-cols-3 sm:gap-4">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Enable Automated Emails
                  </label>
                  <div></div>
                  <div className="sm:mt-px sm:pt-2 sm:col-span-1">
                    <div className="flex max-w-lg rounded-md sm:justify-end">
                      <Switch
                        checked={emailEnabled}
                        onChange={() => handleSwitchChange()}
                        className={classNames(
                          emailEnabled ? "bg-brand" : "bg-gray-200",
                          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-runzy-evergreen"
                        )}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            emailEnabled ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                          )}
                        />
                      </Switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  To
                </label>
                <div className="flex mt-1 sm:justify-end sm:mt-0 sm:col-span-2">
                  <p className="mt-2 text-sm text-gray-500">
                    {trainingAttributes && trainingAttributes.emailAddress}
                  </p>
                </div>
              </div>{" "}
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Subject
                </label>
                <div className="flex mt-1 sm:justify-end sm:mt-0 sm:col-span-2">
                  <p className="mt-2 text-sm text-gray-500">
                    {trainingAttributes && trainingAttributes.emailSubject}
                  </p>
                </div>
              </div>{" "}
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Last E-mail Sent
                </label>
                <div className="flex mt-1 sm:justify-end sm:mt-0 sm:col-span-2">
                  <p className="mt-2 text-sm text-gray-500">
                    {trainingAttributes &&
                    trainingAttributes.lastEmailSendDate ? (
                      formatDateForEmail(trainingAttributes.lastEmailSendDate)
                    ) : (
                      <>Never</>
                    )}
                  </p>
                </div>
              </div>{" "}
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Next E-mail Send
                </label>
                <div className="flex mt-1 sm:justify-end sm:mt-0 sm:col-span-2">
                  <p className="mt-2 text-sm text-gray-500">
                    {trainingAttributes &&
                      formatDateForEmail(trainingAttributes.nextEmailSendDate)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
        {emailPreviewContent.length > 0 && (
          <iframe
            width="800"
            height="1500"
            srcDoc={emailPreviewContent}
            title="email"
            className="mx-auto"
          ></iframe>
        )}
      </div>
    </>
  );
}

export default Email;
