import { trainingAttributes } from "../../Api/TrainingAttribute";
import { trainingEvent } from "../../Api/TrainingEvent";
import { ContentOptions } from "./ActivityContent";
import useCoachsNote from "../Hooks/useCoachsNote";
import { format } from "date-fns";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";

interface CoachsNoteInput {
  trainingEvent: trainingEvent;
  trainingAttributes?: trainingAttributes;
  contentOption: ContentOptions;
}

function CoachsNote({ trainingEvent, trainingAttributes }: CoachsNoteInput) {
  const {
    hasCoachesNote,
    handleExpandCoachNote,
    coachNoteIsExpanded,
    createNoteMarkup,
  } = useCoachsNote({ trainingEvent });

  return (
    <>
      {hasCoachesNote() ? (
        <div className="mb-5 border border-gray-300/50 rounded-xl">
          <div className="flex items-center">
            <div className="flex flex-col w-full">
              <p className="px-4 py-4 font-medium text-gray-900 border-b ">
                Coach's Note
              </p>
              <div
                className="px-4 py-6"
                onClick={() => handleExpandCoachNote()}
              >
                <div className="relative flex items-start px-1 space-x-3">
                  <div className="relative">
                    <ProfilePicture
                      classNames="flex items-center justify-center bg-gray-400 w-9 h-9 rounded-xl ring-8 ring-white"
                      url={
                        trainingAttributes?.trainingAdminProfile
                          .profilePicture ?? ""
                      }
                    />
                  </div>
                  <div className="flex-1 block min-w-0">
                    <div className="flex items-center gap-3">
                      <div className="text-sm font-semibold text-black">
                        Coach{" "}
                        {
                          trainingAttributes?.trainingAdminProfile.name.split(
                            " "
                          )[0]
                        }
                      </div>
                      <p className="mt-0.5 text-xs font-thin text-gray-400">
                        {format(trainingEvent.scheduledAt, "MMM d")}
                      </p>{" "}
                    </div>
                    {coachNoteIsExpanded ? (
                      <p
                        dangerouslySetInnerHTML={{ __html: createNoteMarkup() }}
                        className="text-sm whitespace-pre-line "
                      ></p>
                    ) : (
                      <p
                        dangerouslySetInnerHTML={{ __html: createNoteMarkup() }}
                        className="inline-block text-sm whitespace-pre-line cursor-pointer line-clamp-3 "
                      ></p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-5 border border-gray-300/50 rounded-xl">
          <div className="flex items-center">
            <div className="flex flex-col">
              <p className="px-4 py-4 font-medium text-gray-900 border-b">
                Coach's Note
              </p>
              <p className="px-4 py-6 text-gray-500">
                Your coach may provide tips and context for your workouts.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CoachsNote;
