import { useHistory, useLocation } from "react-router-dom";
import { trainingEvent } from "../../Api/TrainingEvent";
import { useActivityFeedbackOutput } from "../../Coachee/Hooks/useActivityFeedback";
import { classNames } from "../../Utilities/classnames";

export enum Tab {
  PLAN = "PLAN",
  RESULTS = "RESULTS",
  COMMENTS = "COMMENTS",
  MORE = "MORE",
}

type TabInfo = {
  name: string;
  routeName: string;
  requirement?: boolean;
};

function SelectedDayTabs({
  otherEvents,
  activityFeedback,
}: {
  otherEvents: trainingEvent[] | undefined;
  activityFeedback: useActivityFeedbackOutput;
}) {
  const location = useLocation();
  const history = useHistory();

  const tabs: TabInfo[] = [
    {
      name: "Plan",
      routeName: "plan",
    },
    {
      name: "Results",
      routeName: "results",
    },
    {
      name: `Comments${
        activityFeedback.state.comments.length > 0
          ? ` (${activityFeedback.state.comments.length})`
          : ""
      }`,
      routeName: "comments",
    },
    {
      name: `More${
        otherEvents && otherEvents.length > 0 ? ` (${otherEvents.length})` : ""
      }`,
      routeName: "more",
      requirement: otherEvents && otherEvents.length > 0 ? true : false,
    },
  ];

  function handleTabChange(tab: TabInfo) {
    const locationArray = location.pathname
      .split("/")
      .filter((path) => path.length > 0);
    const lastInArray = locationArray[locationArray.length - 1];

    const foundLocation = tabs.find((tab) => tab.routeName === lastInArray);

    if (foundLocation) {
      locationArray.pop();
    }

    locationArray.push(tab.routeName);
    history.replace(`/${locationArray.join("/")}`);
  }

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full border-gray-300 rounded-md focus:ring-red-500 focus:border-red-500"
          onChange={(e) => {
            let newTab = tabs.find((tab) => tab.name === e.target.value);
            newTab && handleTabChange(newTab);
          }}
          //   defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.filter((tab) => tab.requirement === undefined || tab.requirement).map((tab) => (
            <option 
              value={tab.name}
              key={tab.name}>
                {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs
            .filter((tab) => tab.requirement === undefined || tab.requirement)
            .map((tab) => (
              <a
                onClick={() =>  handleTabChange(tab)}
                key={tab.name}
                className={classNames(
                  location.pathname.endsWith(tab.routeName)
                    ? "bg-red-100 text-red-700"
                    : "text-gray-500 hover:text-gray-700",
                  "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                )}
                aria-current={
                  location.pathname.endsWith(tab.routeName) ? "page" : undefined
                }
              >
                {tab.name}
              </a>
            ))}
        </nav>
      </div>
    </div>
  );
}

export default SelectedDayTabs;
