import { FeedbackActivity, EventActivity, Post } from "../../Api/Stream";
import { StreamClient } from "getstream";
import useStreamActivityReaction from "../Hooks/useStreamActivityReaction";

interface StreamActivityReactionInput {
  activity: FeedbackActivity | EventActivity | Post;
  streamClient: StreamClient;
}

function StreamActivityReaction({
  activity,
  streamClient,
}: StreamActivityReactionInput) {
  const { state } = useStreamActivityReaction({
    streamClient: streamClient,
    activity: activity,
  });

  return (
    <>
      <div className="flex items-center gap-3 px-4 pt-2 pb-4 text-xs text-gray-600">
        {(activity.verb === "feedbacked" && !activity.trainingEvent) ||
          (activity.foreign_id.includes("feedback") && (
            <p className="cursor-pointer hover:underline">
              Reply {state.comments.length > 0 && `(${state.comments.length})`}
            </p>
          ))}
      </div>
    </>
  );
}

export default StreamActivityReaction;
