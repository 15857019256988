import { useActivityFeedbackOutput } from "../../Hooks/useActivityFeedback";
import ActivityFeedbackComment from "./FeedbackItem/ActivityFeedbackComment";
import { Comment } from "../../../Api/Stream";
import DeleteCommentModal from "./DeleteCommentModal";

import NoCommentsDisplay from "./NoCommentsDisplay";
import CommentBox from "./CommentBox";
import { trainingEvent } from "../../../Api/TrainingEvent";
import { isMobile } from "../../../Utilities/isMobile";
import { EnrichedReactionAPIResponse, ReactionAPIResponse } from "getstream";
import { MiniProfile } from "../../../Api/TrainingProfile";
import { addEmojiToReaction } from "../../../Api/Reaction";
import { useState } from "react";

interface ActivityFeedbackInput {
  trainingEvent?: trainingEvent;
  activityFeedback: useActivityFeedbackOutput;
}

export type EmojiData = {
  emoji: string;
  time: string;
  actorMiniProfile: MiniProfile;
};

export type EmojiAPIResponse = ReactionAPIResponse<EmojiData>;

function ActivityFeedback({
  trainingEvent,
  activityFeedback,
}: ActivityFeedbackInput) {
  const hasComments = activityFeedback.state.comments.length > 0;

  const [canAddEmoji, setCanAddEmoji] = useState(true);

  function isAthlete(comment: ReactionAPIResponse) {
    if (!activityFeedback.state.streamClient) return true;
    /* eslint-disable */
    if (location.pathname.includes("coach")) {
      if (activityFeedback.state.streamClient.userId === comment.user_id) {
        return false;
      }
    }

    if (!location.pathname.includes("coach")) {
      if (activityFeedback.state.streamClient.userId !== comment.user_id) {
        return false;
      }
    }

    return true;
  }

  function getChildren(comment: Comment): EmojiAPIResponse[] {
    const modifiedComment = comment as unknown as EnrichedReactionAPIResponse;
    return modifiedComment.latest_children[
      "emoji"
    ] as unknown as EmojiAPIResponse[];
  }

  async function addEmoji(comment: ReactionAPIResponse, emoji: string) {
    if (!canAddEmoji) return false;
    setCanAddEmoji(false);

    const response = await addEmojiToReaction(
      comment.id,
      emoji,
      comment.user_id,
      trainingEvent?.id
    );

    setCanAddEmoji(true);
    return response ?? false;
  }

  return (
    <div
      id="activityComments"
      className="flex flex-col justify-between grow"
    >
      {hasComments ? (
        <div className="">
          <div className="flex flex-col h-full px-6 pt-2 text-left md:h-3/4">
            {activityFeedback.state.comments
              .sort(
                (a, b) =>
                  new Date(a.created_at).getTime() -
                  new Date(b.created_at).getTime()
              )
              .map((comment, index) => (
                <div key={index} className="mt-2">
                  <ActivityFeedbackComment
                    comment={comment as Comment}
                    streamClient={activityFeedback.state.streamClient}
                    activityFeedback={activityFeedback}
                    isAthlete={isAthlete(comment)}
                    children={getChildren(comment)}
                    addEmoji={addEmoji}
                  />
                </div>
              ))}
          </div>
        </div>
      ) : (
        <NoCommentsDisplay
          isAthlete={!location.pathname.startsWith("/coach")}
        />
      )}

      {!isMobile ? (
        <div className="sticky bottom-3 justify-self-end">
          <CommentBox
            trainingEvent={trainingEvent}
            activityFeedback={activityFeedback}
          />
        </div>
      ) : (
        // invisible comment box for padding on mobile
        <div className="bottom-0 flex flex-col justify-center invisible w-full mt-2 grow md:h-2">
          <div>
            <CommentBox
              trainingEvent={trainingEvent}
              activityFeedback={activityFeedback}
            />
          </div>
        </div>
      )}

      <DeleteCommentModal
        showDeleteModal={
          activityFeedback.state.commentToBeDeleted !== undefined
        }
        deactivateCommentModal={activityFeedback.deactivateCommentModal}
        handleDeleteComment={activityFeedback.handleDeleteComment}
      />
    </div>
  );
}

export default ActivityFeedback;
