import { Popover } from "@headlessui/react";
import { classNames } from "../../../Utilities/classnames";

export default function TemplateContextMenu({
  options,
  selectedIndex,
  setSelectedIndex,
  handleEnterPress,
}: {
  options: {
    name: string;
    value: string;
  }[];
  selectedIndex: number | undefined;
  setSelectedIndex: (index: number | undefined) => void;
  handleEnterPress: () => void;
}) {
  return (
    <>
      <Popover
        id="context-menu-container"
        as="div"
        className="relative inline-block text-left"
      >
        <Popover.Panel
          static
          className="w-52 z-50 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="relative py-1 overflow-visible">
            {options.map((option, key) => (
              <div
                onMouseEnter={() => setSelectedIndex(key)}
                onMouseLeave={() => setSelectedIndex(undefined)}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleEnterPress();
                }}
                key={key}
                className={classNames(
                  selectedIndex === key ? "bg-gray-100" : "",
                  "text-gray-700 flex items-center justify-between pl-4 pr-2 py-2 text-sm cursor-pointer relative z-50"
                )}
              >
                {option.name}
              </div>
            ))}
          </div>
        </Popover.Panel>
      </Popover>
    </>
  );
}
