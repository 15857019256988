import { IonContent, IonPage, IonRouterLink } from "@ionic/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ActivityRoute,
  getActivityRoute,
} from "../../../../../Api/ActivityRoute";
import {
  getTrainingEventsByDate,
  trainingEvent,
} from "../../../../../Api/TrainingEvent";
import { useUserInfo } from "../../../../../Context/UserContext";
import { decodeDateFromSecondsFormat } from "../../../../../Utilities/DateUtilities";
import PageHeader from "../PageHeader";
import Details from "./Details";
import Back from "../../../../../Assets/Back.svg";
import Spinner from "../../../../Components/Spinner";

function OtherActivityPage() {
  const params = useParams<{ activityId: string }>();
  const { userInfo } = useUserInfo();
  const [activityRoute, setActivityRoute] = useState<ActivityRoute>();
  const [matchedTrainingEvents, setMatchedTrainingEvents] =
    useState<trainingEvent[]>();

  async function initRoute() {
    if (params.activityId) {
      const activity = await getActivityRoute(
        params.activityId.replace("perform:activity:", "")
      );

      if (activity) setActivityRoute(activity);
    }
  }

  async function initMatchedTrainingEvents() {
    if (!activityRoute || !userInfo || matchedTrainingEvents !== undefined)
      return;

    const date = decodeDateFromSecondsFormat(activityRoute.activity.startedAt);

    const events = await getTrainingEventsByDate(userInfo.miniProfile.id, date);
    if (events) setMatchedTrainingEvents(events);
  }

  useEffect(() => {
    initRoute();
  }, []);

  useEffect(() => {
    initMatchedTrainingEvents();
  }, [activityRoute, userInfo]);

  return (
    <IonPage>
      <PageHeader />
      <IonContent style={{ "--ion-background-color": "#F9FAFB" }}>
        {activityRoute ? (
          <div>
            <div className="z-10 mx-5 lg:max-w-xl lg:mx-auto md:pt-20">
              {matchedTrainingEvents && matchedTrainingEvents.length > 0 && (
                <IonRouterLink
                  routerLink={`/activity/${matchedTrainingEvents[0].id}/results`}
                  routerDirection="back"
                >
                  <button className="items-center self-start hidden mb-12 space-x-1 text-xs text-gray-400 rounded md:flex hover:text-gray-500">
                    <img src={Back} alt="Back" className="w-auto h-4" />
                    <p>Back</p>
                  </button>
                </IonRouterLink>
              )}

              <Details activity={activityRoute} />
            </div>
          </div>
        ) : (
          <>
            <Spinner />
          </>
        )}
      </IonContent>
    </IonPage>
  );
}

export default OtherActivityPage;
