import { useContext, useState } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { UserInfoContext } from '../../../Context/UserContext'
// import {
// 	decodeDateFromStringFormat,
// } from "../../../Utilities/DateUtilities";
import AdminLayout from '../../../Layout/AdminLayout'
import axios from 'axios';

interface InputFieldProps {
	label: string;
	name: string;
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type UserDataType = {
	first_name: string;
	last_name: string;
	email: string;
	gender: string;
	phone: string;
	age: string;
};

type GoalDetailsType = {
	goal_name: string;
	goal_date: string;
	goal_distance: string;
}

const initialUserData: UserDataType = {
	first_name: '',
	last_name: '',
	email: '',
	gender: '',
	phone: '',
	age: '',
}
const initialGoal: GoalDetailsType = {
	goal_name: '',
	goal_date: '',
	goal_distance: '',
}

function goalDistanceToNumber(distanceString: string): number {
	switch (distanceString) {
		case "50 K":
			return 50000;
		case "Marathon":
			return 42195;
		case "Half Marathon":
			return 21097.5;
		case "10 Mi":
			return 16093.4;
		case "12 K":
			return 12000;
		case "10 K":
			return 10000;
		case "5 Mi":
			return 8046.72;
		case "5 K":
			return 5000;
		default:
			return 0;
	}
}

const InputField = ({ label, name, value, onChange }: InputFieldProps) => (
	<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
		<dt className="my-auto text-sm font-medium text-gray-500">{label}</dt>
		<div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
			<span className="flex-grow"></span>
			<span className="flex-shrink-0 ml-4">
				<dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
					<input
						type={"text"}
						name={name}
						className="flex-1 block w-full min-w-0 border-gray-300 rounded-md disabled:cursor-not-allowed focus:ring-brand focus:border-brand sm:text-sm"
						value={value}
						onChange={onChange}
					/>
				</dd>
			</span>
		</div>
	</div>
);


const CreateUserPage
	= () => {
		const { userInfo } = useContext(UserInfoContext)
		const [userData, setUserData] = useState<UserDataType>(initialUserData);
		const [emailValidMessage, setEmailValidationMessage] = useState<null | string>(null);

		const [goal, setGoal] = useState<GoalDetailsType>(initialGoal);

		async function handleSubmit() {
			const payload = {
				...userData,
				...goal,
				goal_distance: goalDistanceToNumber(goal.goal_distance),
			}
			return new Promise((resolve, reject) => {
				axios.post('/profile/save', payload)
					.then((response) => {
						window.alert('User Created');
						setGoal(initialGoal)
						setUserData(initialUserData)
						console.log(response.data)
					})
					.catch((e) => {
						console.log(e.response.data)
						window.alert(`User Creation Failed: ${e.response.data.message}`);
					})
			})

		};

		const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
			const email = e.target.value;
			const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
			if (!emailPattern.test(email)) {
				setEmailValidationMessage('Invalid email address');
			} else {
				setEmailValidationMessage(null);
			}
			setUserData({ ...userData, email });
		}

		return (
			<IonPage>
				<IonContent>
					{userInfo &&
						<AdminLayout title="Create New User">
							<div className='max-w-lg mx-auto p-4 flex flex-col gap-6'>
								<div className="mt-10 overflow-hidden bg-white rounded-lg shadow">
									<div className="px-4 py-5 sm:p-6">
										<div className="divide-y divide-gray-200">
											<div className="space-y-1">
												<h3 className="text-lg font-medium leading-6 text-gray-900">
													User Details
												</h3>
											</div>
											<div className="mt-6">
												<dl className="divide-y divide-gray-200">
													<InputField
														label="First Name"
														name="firstName"
														value={userData.first_name}
														onChange={(e) => { setUserData({ ...userData, first_name: e.target.value }) }}
													/>
													<InputField
														label="Second Name"
														name="secondName"
														value={userData.last_name}
														onChange={(e) => { setUserData({ ...userData, last_name: e.target.value }) }}
													/>
													<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
														<dt className="my-auto text-sm font-medium text-gray-500">
															Gender
														</dt>
														<div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
															<span className="flex-grow"></span>
															<span className="flex-shrink-0 ml-4">
																<dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
																	<select
																		name="Gender"
																		className="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-brand focus:border-brand sm:text-sm"
																		value={userData.gender}
																		onChange={(e) => {
																			setUserData({
																				...userData,
																				gender: e.target.value
																			});
																		}}
																	>
																		<option value="Male">Male</option>
																		<option value="Female">Femal</option>
																		<option value="Non-binary">Non-Binary</option>
																		<option value="Prefer not to say">Prefer not to say</option>
																	</select>
																</dd>
															</span>
														</div>
													</div>
													<InputField
														label='Phone'
														name='phone'
														value={userData.phone}
														onChange={(e) => { setUserData({ ...userData, phone: e.target.value }) }}
													/>
													<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
														<dt className="my-auto text-sm font-medium text-gray-500">Email</dt>
														<div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
															<span className="flex-grow"></span>
															<span className="flex-shrink-0 ml-4">
																<dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
																	<input
																		type={"email"}
																		name={'email'}
																		className="flex-1 block w-full min-w-0 border-gray-300 rounded-md disabled:cursor-not-allowed focus:ring-brand focus:border-brand sm:text-sm"
																		value={userData.email}
																		onChange={(e) => handleEmail(e)}
																	/>
																</dd>
															</span>
														</div>
													</div>
													{emailValidMessage && (
														<p className="text-red-500 text-sm mt-1">{emailValidMessage}</p>
													)}
												</dl>
											</div>
										</div>
									</div>
								</div>
								<div className="mt-10 overflow-hidden bg-white rounded-lg shadow">
									<div className="px-4 py-5 sm:p-6">
										<div className="divide-y divide-gray-200">
											<div className="space-y-1">
												<h3 className="text-lg font-medium leading-6 text-gray-900">
													Goal Details
												</h3>
											</div>
											<div className="mt-6">
												<dl className="divide-y divide-gray-200">
													<InputField
														label="Goal Name"
														name="goalName"
														value={goal.goal_name}
														onChange={(e) => setGoal({ ...goal, goal_name: e.target.value })}
													/>
													<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
														<dt className="my-auto text-sm font-medium text-gray-500">
															Date
														</dt>
														<div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
															<span className="flex-grow"></span>
															<span className="flex-shrink-0 ml-4">
																<dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
																	<input
																		type="date"
																		name="date"
																		id="date"
																		className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
																		value={
																			goal.goal_date ? goal.goal_date : new Date().toDateString()
																		}
																		onChange={(e) => {
																			setGoal({
																				...goal,
																				goal_date: e.target.value
																			})
																		}}
																	/>
																</dd>
															</span>
														</div>
													</div>
													<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
														<dt className="my-auto text-sm font-medium text-gray-500">
															Goal Distance
														</dt>
														<div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
															<span className="flex-grow"></span>
															<span className="flex-shrink-0 ml-4">
																<dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
																	<select
																		name="Distance"
																		className="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-brand focus:border-brand sm:text-sm"
																		value={goal.goal_distance}
																		onChange={(e) => {
																			setGoal({
																				...goal,
																				goal_distance: e.target.value
																			});
																		}}
																	>
																		<option value=""></option>
																		<option value="50 K">50 K</option>
																		<option value="Marathon">Marathon</option>
																		<option value="Half Marathon"> Half Marathon</option>
																		<option value="10 Mi">10 Mi</option>
																		<option value="12 K">12 K</option>
																		<option value="10 K">10 K</option>
																		<option value="5 Mi">5 Mi</option>
																		<option value="5 K">5 K</option>
																	</select>
																</dd>
															</span>
														</div>
													</div>
												</dl>
											</div>
										</div>
									</div>
								</div>
								<button
									className="inline-block text-sm font-medium bg-brand text-white px-4 py-2 rounded disabled:bg-gray-400 "
									type="submit"
									disabled={!!emailValidMessage || userData.email === ""}
									onClick={() => handleSubmit()}
								>
									Add new user
								</button>
							</div>
						</AdminLayout>
					}
				</IonContent>
			</IonPage>
		)
	}

export default CreateUserPage

