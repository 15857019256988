import {
  connect,
  ReactionAPIResponse,
  StreamClient,
  StreamFeed,
} from "getstream";
import { useContext, useEffect, useState } from "react";
import { deletePost } from "../../Api/Post";
import { Post } from "../../Api/Stream";
import { trainingProfile } from "../../Api/TrainingProfile";
import { UserInfoContext } from "../../Context/UserContext";
import Default from "../../Default/Default";

function useCoachPosts({
  selectedTrainingProfile,
}: {
  selectedTrainingProfile: trainingProfile;
}) {
  const { userInfo } = useContext(UserInfoContext);
  const [streamClient, setStreamClient] = useState<StreamClient>();
  const [feed, setFeed] = useState<StreamFeed>();
  const [posts, setPosts] = useState<Post[]>();
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [postToBeDeleted, setPostToBeDeleted] = useState<Post>();

  function initStreamClient() {
    if (userInfo) {
      const client = connect(
        Default.streamsApiKey,
        userInfo.streamAPIAuth,
        Default.streamsAppId
      );

      setStreamClient(client);
    }
  }

  function triggerRefresh() {
    setRefreshTrigger(refreshTrigger + 1);
  }

  async function initFeed() {
    if (streamClient) {
      setFeed(streamClient.feed("coach_feedback"));
    }
  }

  async function initPosts() {
    if (streamClient && feed) {
      let offset = 0;
      let postList: Post[] = [];

      while (offset < 400) {
        const items = await (
          await feed.get({
            limit: 250,
            offset,
            withReactionCounts: true,
            withOwnReactions: true,
          })
        ).results;
        postList = [...postList, ...(items as Post[])];
        offset += 100;
      }

      // remove duplicate posts
      const uniquePosts = postList.filter(
        (post, index) => postList.findIndex((p) => p.id === post.id) === index
      );

      setPosts(uniquePosts);
    }
  }

  async function currentUserLiked(likesReactions: ReactionAPIResponse[]) {
    if (streamClient && streamClient.currentUser) {
      const ownLikes = likesReactions.find(
        (reaction) => reaction.user_id === streamClient.currentUser?.id
      );

      return ownLikes ? true : false;
    }
  }

  async function handleLike(post: Post) {
    if (!userInfo || !streamClient || !streamClient.currentUser) return;
    const reactions = await streamClient.reactions.filter({
      activity_id: post.id,
      kind: "like",
    });

    const userHasLiked = await currentUserLiked(reactions.results);

    if (userHasLiked) {
      for (let i = 0; i < reactions.results.length; i++) {
        if (reactions.results[i].user_id === streamClient.currentUser.id) {
          streamClient.reactions.delete(reactions.results[i].id);
        }
      }
    } else {
      await streamClient.reactions.add("like", post.id, {});
    }

    initPosts();
  }

  function activateDeleteModal(postId: string) {
    posts && setPostToBeDeleted(posts.find((post) => post.id === postId));
  }

  function deactivateDeleteModal() {
    setPostToBeDeleted(undefined);
  }

  async function handleDeletePost() {
    if (!postToBeDeleted) return;

    // const response = await feed.removeActivity(postToBeDeleted.id);
    const response = await deletePost(postToBeDeleted.id);

    if (response) {
      initPosts();
      deactivateDeleteModal();
    }
  }

  const filteredPosts =
    posts &&
    userInfo &&
    posts.filter(
      (post) =>
        post.viewerMiniProfile.numericId.toString() ===
          selectedTrainingProfile.miniProfile.numericId.toString() ||
        post.actor === selectedTrainingProfile.miniProfile.numericId.toString()
    );

  useEffect(() => {
    initPosts();
  }, [feed, refreshTrigger]);

  useEffect(() => {
    initFeed();
  }, [streamClient]);

  useEffect(() => {
    initStreamClient();
  }, [userInfo]);

  return {
    filteredPosts,
    streamClient,
    handleLike,
    triggerRefresh,
    activateDeleteModal,
    deactivateDeleteModal,
    handleDeletePost,
    postToBeDeleted,
  };
}

export default useCoachPosts;
