import { ActivityRoute } from "../../Api/ActivityRoute";
import { TrainingEventStatus } from "../../Api/TrainingEvent";
import { trainingProfile } from "../../Api/TrainingProfile";
import { UnitPreference } from "../../Api/User";
import ActivityResults from "../../Coachee/Activity/Results/ActivityResults";
import { useUserInfo } from "../../Context/UserContext";
import { CalendarDay } from "../Calendar";
import ActualDistanceInput from "../Calendar/Input/ActualDistanceInput";
import ActualDurationDisplay from "../Calendar/Input/ActualDurationDisplay";
import ActualPaceDisplay from "../Calendar/Input/ActualPaceDisplay";
import AssignedDistanceDisplay from "../Calendar/Input/AssignedDistanceDisplay";
import AssignedDurationDisplay from "../Calendar/Input/AssignedDurationDisplay";
import AssignedPaceDisplay from "../Calendar/Input/AssignedPaceDisplay";

interface Props {
  selectedDay: CalendarDay;
  activityRoute: ActivityRoute | null;
  currentAssignedDistance: string;
  currentAssignedDuration: string;
  selectedTrainingProfile: trainingProfile;
  openActivity: () => void;
}

export default function SelectedDayResults({
  selectedDay,
  activityRoute,
  currentAssignedDistance,
  currentAssignedDuration,
  selectedTrainingProfile,
  openActivity,
}: Props) {
  const { userInfo } = useUserInfo();

  return (
    <>
      {selectedDay.event && (
        <div className="grid justify-center grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
          <AssignedDistanceDisplay
            currentAssignedDistance={currentAssignedDistance}
            metricSetting={
              userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL
            }
          />

          <ActualDistanceInput event={selectedDay.event} />

          <AssignedDurationDisplay
            currentAssignedDuration={currentAssignedDuration}
          />

          <ActualDurationDisplay event={selectedDay.event} />

          <AssignedPaceDisplay
            assignedDistance={currentAssignedDistance}
            assignedDuration={currentAssignedDuration}
          />

          <ActualPaceDisplay event={selectedDay.event} />

          {activityRoute && activityRoute.activity.description.length > 0 && (
            <div className="sm:col-span-6">
              <label
                htmlFor="assigned-duration"
                className="block text-sm font-medium text-gray-700"
              >
                Event Description
              </label>
              <div className="mt-1">
                <p className="block w-full pt-2 pl-2 whitespace-pre-line border-gray-300 rounded-md focus:ring-brand focus:border-brand sm:text-sm ">
                  {activityRoute.activity.description}
                </p>
              </div>
            </div>
          )}
        </div>
      )}

      {selectedDay.event &&
        (selectedDay.event.status === TrainingEventStatus.COMPLETED ||
          selectedDay.event.status === TrainingEventStatus.MISSED) && (
          <div className="pt-8">
            <label
              htmlFor="date"
              className="block mb-4 text-sm font-medium text-gray-700"
            >
              Activity Data
            </label>
            <ActivityResults
              trainingEvent={selectedDay.event}
              trainingProfile={selectedTrainingProfile}
            />
          </div>
        )}

      <div className="pt-5">
        <div className="flex justify-end">
          <div className="px-1 py-2 text-sm text-gray-400 cursor-pointer whitespace-nowrap">
            {selectedDay && selectedDay.event && selectedDay.event.activity ? (
              <>
                <a onClick={() => openActivity()}>&nbsp;Details</a>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
