import NavButton from "./NavButton";
import { navigate, NavigationOption } from "./navUtil";

function WorkoutDays() {
  return (
    <div className="p-4 bg-white border-t-[16px] border-neutral-100">
      <h2 className="block text-[12px] text-[#9CA3AF] spacing-[20px] tracking-[1px]">
        WORKOUT DAYS
      </h2>
      <NavButton
        title="Change Availability"
        onClick={() => {
          // navigate to availability page
          navigate(NavigationOption.AVAILABILITY);
        }}
      />
    </div>
  );
}

export default WorkoutDays;
