import { IonRouterLink } from "@ionic/react";
import { useLocation } from "react-router-dom";
import { classNames } from "../../Utilities/classnames";
import { useAthletesOutput } from "../Hooks/useAthletes";

function Tabs({ athletesHook }: { athletesHook: useAthletesOutput }) {
  const location = useLocation();

  const tabs = [
    {
      name: "Core/Plus",
      count: athletesHook.unreadCount("premium"),
      current: location.pathname.replaceAll("/", "").endsWith("coach"),
      route: "/coach",
      id: "core",
    },
    {
      name: "Basic",
      count: athletesHook.unreadCount("basic"),
      current: location.pathname.replaceAll("/", "").endsWith("basic"),
      route: "/coach/basic",
      id: "basic",
    },
    {
      name: "Reviewed",
      count: athletesHook.unreadCount("reviewed"),
      current: location.pathname.replaceAll("/", "").endsWith("reviewed"),
      route: "/coach/reviewed",
      id: "reviewed",
    },
  ];

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none sm:text-sm"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <IonRouterLink
                key={tab.name}
                className={classNames(
                  tab.current
                    ? "border-[#F6747E]"
                    : "border-transparent  hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-3 border-b font-medium text-[14px] text-[#374151]"
                )}
                aria-current={tab.current ? "page" : undefined}
                routerLink={tab.route}
              >
                <p id={tab.id}>
                  {tab.name}{" "}
                  {tab.count > 0 && (
                    <span className="text-[12px] text-white border ml-1 rounded-full border-brand bg-brand px-2 py-1">
                      {tab.count}
                    </span>
                  )}
                </p>
              </IonRouterLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Tabs;
