import { trainingEvent } from "../../Api/TrainingEvent";
import { classNames } from "../../Utilities/classnames";
import { getDecorationColor } from "./util";
import { format } from "date-fns";
import { IonRouterLink } from "@ionic/react";

function ThisWeeksEvent({ event }: { event: trainingEvent }) {
  return (
    <IonRouterLink routerLink={`/activity/${event.id}/plan`}>
      <div className="bg-white px-3 rounded-[6px] py-4 mb-2 flex items-center gap-4">
        <div
          className={classNames(getDecorationColor(event), "w-[3px] h-[58px]")}
        ></div>
        <div className="space-y-1">
          <p className="font-semibold text-[12px] text-neutral-500 tracking-[1px]">
            {format(event.scheduledAt, "eeee, LLLL d").toUpperCase()}
          </p>
          <p className="font-semibold text-[18px] text-black truncate ">
            {event.title}
          </p>
        </div>
      </div>
    </IonRouterLink>
  );
}

export default ThisWeeksEvent;
