import { Combobox } from "@headlessui/react";
import { CheckIcon, ClockIcon } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";
import React from "react";
import { TrainingAdmin } from "../../Api/TrainingAdmin";
import { trainingProfile } from "../../Api/TrainingProfile";
import { classNames } from "../../Utilities/classnames";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";
import { DetailedOptions } from "./ComboBox";

type Props = { detailedOptions: DetailedOptions };

export default function DetailedContents({ detailedOptions }: Props) {
  function renderPeople(people: trainingProfile[]) {
    return (
      <>
        {people.map((person: trainingProfile | TrainingAdmin) => (
          <Combobox.Option
            key={person.miniProfile.id}
            value={person}
            className={({ active }) =>
              classNames(
                "relative cursor-default select-none py-2 pl-3 pr-9",
                active ? "bg-brand text-white" : "text-gray-900"
              )
            }
          >
            {({ active, selected }) => (
              <>
                <div className="flex items-center">
                  <ProfilePicture
                    url={person.miniProfile.profilePicture}
                    classNames="flex-shrink-0 w-6 h-6 rounded-full"
                    alt=""
                  />
                  <span
                    className={classNames(
                      "ml-3 truncate",
                      selected && "font-semibold"
                    )}
                  >
                    {person.miniProfile.name}
                  </span>
                </div>

                {selected && (
                  <span
                    className={classNames(
                      "absolute inset-y-0 right-0 flex items-center pr-4",
                      active ? "text-white" : "text-brand"
                    )}
                  >
                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                  </span>
                )}
              </>
            )}
          </Combobox.Option>
        ))}
      </>
    );
  }

  const { allBasicProfiles, basicNeedsAttentionProfiles, premiumProfiles } =
    detailedOptions;

  // profiles that are in allBasicProfiles, but not in basicNeedsAttentionProfiles
  const reviewedBasicProfiles = allBasicProfiles.filter(
    (person: trainingProfile | TrainingAdmin) => {
      return !basicNeedsAttentionProfiles.some(
        (basicProfile: trainingProfile | TrainingAdmin) => {
          return basicProfile.miniProfile.id === person.miniProfile.id;
        }
      );
    }
  );

  return (
    <>
      {allBasicProfiles.length +
        basicNeedsAttentionProfiles.length +
        premiumProfiles.length >
        0 && (
        <Combobox.Options className="absolute z-50 w-full mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <div>
            {basicNeedsAttentionProfiles.length > 0 && (
              <div className="sticky top-0 z-50 flex items-center w-full gap-1 py-1 pl-1 text-xs font-medium text-gray-700 bg-gray-100">
                <ClockIcon className="w-3 h-3" />
                <h2 className="">Basic (Need Service)</h2>
              </div>
            )}

            {renderPeople(basicNeedsAttentionProfiles)}
          </div>
          <div>
            {premiumProfiles.length > 0 && (
              <div className="sticky top-0 z-50 flex items-center w-full gap-1 py-1 pl-1 text-xs font-medium text-gray-700 bg-gray-100">
                <StarIcon className="w-3 h-3" />
                <h2 className="">Core & Pro</h2>
              </div>
            )}

            {renderPeople(premiumProfiles)}
          </div>
          <div>
            {allBasicProfiles.length > 0 && (
              <div className="sticky top-0 z-50 flex items-center w-full gap-1 py-1 pl-1 text-xs font-medium text-gray-700 bg-gray-100">
                <CheckIcon className="w-3 h-3" />
                <h2 className="">Basic (Reviewed)</h2>
              </div>
            )}

            {renderPeople(reviewedBasicProfiles)}
          </div>
        </Combobox.Options>
      )}
    </>
  );
}
