import { ChevronRightIcon } from "@heroicons/react/solid";
import { classNames } from "../../../Utilities/classnames";

interface NavButtonProps {
  title: string;
  onClick: () => void;
  hasBottomBorder?: boolean;
}

export default function NavButton({
  title,
  onClick,
  hasBottomBorder,
}: NavButtonProps) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "flex items-center justify-between w-full py-[15px] border-neutral-100",
        hasBottomBorder ? "border-b" : ""
      )}
    >
      <p className="font-medium text-neutral-900 text-[16px] leading-[24px]">
        {title}
      </p>
      <ChevronRightIcon
        className="h-6 w-6 text-neutral-900"
        aria-hidden="true"
      />
    </button>
  );
}
