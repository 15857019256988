export default function AssignedDurationDisplay({
  currentAssignedDuration,
}: {
  currentAssignedDuration: string;
}) {
  let durationDisplay = "-";

  if (currentAssignedDuration !== "" && currentAssignedDuration !== "0:00") {
    durationDisplay = currentAssignedDuration;
  }

  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="assigned-duration"
        className="block text-sm font-medium text-gray-700"
      >
        Assigned duration
      </label>
      <div className="mt-1">
        <p className="block w-full pt-2 pl-2 border-gray-300 rounded-md focus:ring-brand focus:border-brand sm:text-sm">
          {durationDisplay}
        </p>
      </div>
    </div>
  );
}
