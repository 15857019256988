import { EditableStep, TrainingEventStepType } from "../../../Api/Step";
import { trainingProfile } from "../../../Api/TrainingProfile";
import CreateRepeatStep from "./CreateRepeatStep";
import CreateStep from "./CreateStep";
import StepDisplay from "./StepDisplay/StepDisplay";
import StepEditor from "./StepEditor/StepEditor";
import StepRepeat from "./StepRepeat/StepRepeat";
interface StepsInput {
  currentSteps: EditableStep[];
  setCurrentSteps: (steps: EditableStep[]) => void;
  selectedTrainingProfile?: trainingProfile;
}

export default function Steps({
  currentSteps,
  setCurrentSteps,
  selectedTrainingProfile,
}: StepsInput) {
  const addStep = () => {
    const newStep: EditableStep = {
      editing: true,
      stepType: TrainingEventStepType.RUN,
    };
    setCurrentSteps([...currentSteps, newStep]);
  };

  const addRepeatStep = () => {
    const newStep: EditableStep = {
      editing: true,
      stepType: TrainingEventStepType.REPEAT,
      repeats: 2,
      subSteps: [],
    };
    setCurrentSteps([...currentSteps, newStep]);
  };

  const addStepToRepeat = (repeatIndex: number) => {
    const newStep: EditableStep = {
      editing: true,
      stepType: TrainingEventStepType.RUN,
    };
    const newSteps = [...currentSteps];
    (newSteps[repeatIndex].subSteps ?? []).push(newStep);
    setCurrentSteps(newSteps);
  };

  const editRepeatStepRepeatCount = (repeatIndex: number, repeats: number) => {
    const newSteps = [...currentSteps];
    newSteps[repeatIndex].repeats = repeats;
    setCurrentSteps(newSteps);
  };

  const moveStepUp = (stepIndex: number) => {
    if (stepIndex === 0) return;
    const newSteps = [...currentSteps];
    const step = newSteps[stepIndex];
    const destinationStep = newSteps[stepIndex - 1];
    if (
      destinationStep.stepType === TrainingEventStepType.REPEAT &&
      step.stepType !== TrainingEventStepType.REPEAT
    ) {
      newSteps.splice(stepIndex, 1);
      (destinationStep.subSteps ?? []).push(step);
    } else {
      newSteps[stepIndex] = destinationStep;
      newSteps[stepIndex - 1] = step;
    }
    setCurrentSteps(newSteps);
  };

  const moveSubStepUp = (subStep: EditableStep) => {
    const repeatStepIndex = currentSteps.findIndex(
      (step) => step.subSteps?.includes(subStep) ?? false
    );
    if (repeatStepIndex === -1) return;
    const repeatStep = currentSteps[repeatStepIndex];
    const subStepIndex = repeatStep.subSteps?.findIndex(
      (step) => step === subStep
    );
    if (subStepIndex === undefined) return;
    if (subStepIndex === 0) {
      // move the substep out of the repeat step
      const newSteps = [...currentSteps];
      const newRepeatStep = { ...repeatStep };
      newRepeatStep.subSteps?.splice(subStepIndex, 1);
      newSteps[repeatStepIndex] = newRepeatStep;
      newSteps.splice(repeatStepIndex, 0, subStep);
      setCurrentSteps(newSteps);
    } else if (subStepIndex > 0) {
      // move the substep up
      const newRepeatStep = { ...repeatStep };
      newRepeatStep.subSteps?.splice(subStepIndex, 1);
      newRepeatStep.subSteps?.splice(subStepIndex - 1, 0, subStep);
      const newSteps = [...currentSteps];
      newSteps[repeatStepIndex] = newRepeatStep;
      setCurrentSteps(newSteps);
    }
  };

  const moveStepDown = (step: EditableStep) => {
    const stepIndex = currentSteps.findIndex((s) => s === step);
    if (stepIndex === -1) return;
    if (stepIndex === currentSteps.length - 1) return;
    const newSteps = [...currentSteps];
    const destinationStep = newSteps[stepIndex + 1];
    if (
      destinationStep.stepType === TrainingEventStepType.REPEAT &&
      step.stepType !== TrainingEventStepType.REPEAT
    ) {
      newSteps.splice(stepIndex, 1);
      (destinationStep.subSteps ?? []).splice(0, 0, step);
    } else {
      newSteps[stepIndex] = destinationStep;
      newSteps[stepIndex + 1] = step;
    }
    setCurrentSteps(newSteps);
  };

  const moveSubStepDown = (step: EditableStep, subStep: EditableStep) => {
    const stepIndex = currentSteps.findIndex((s) => s === step);
    if (stepIndex === -1) return;
    const repeatStep = currentSteps[stepIndex];
    const subStepIndex = repeatStep.subSteps?.findIndex((s) => s === subStep);
    if (subStepIndex === undefined) return;
    if (repeatStep.subSteps === undefined) return;
    if (subStepIndex === repeatStep.subSteps?.length - 1) {
      // move the substep out of the repeat step
      const newSteps = [...currentSteps];
      const newRepeatStep = { ...repeatStep };
      newRepeatStep.subSteps?.splice(subStepIndex, 1);
      newSteps[stepIndex] = newRepeatStep;
      newSteps.splice(stepIndex + 1, 0, subStep);
      setCurrentSteps(newSteps);
    } else if (subStepIndex < repeatStep.subSteps?.length - 1) {
      // move the substep down
      const newRepeatStep = { ...repeatStep };
      newRepeatStep.subSteps?.splice(subStepIndex, 1);
      newRepeatStep.subSteps?.splice(subStepIndex + 1, 0, subStep);
      const newSteps = [...currentSteps];
      newSteps[stepIndex] = newRepeatStep;
      setCurrentSteps(newSteps);
    }
  };

  const deleteStep = (step: EditableStep) => {
    const index = currentSteps.indexOf(step);
    if (index === -1) {
      return;
    }

    const newSteps = [...currentSteps];
    newSteps.splice(index, 1);
    setCurrentSteps(newSteps);
  };

  const deleteSubStep = (step: EditableStep, subStep: EditableStep) => {
    const index = currentSteps.indexOf(step);
    const subIndex = step.subSteps?.indexOf(subStep) ?? -1;
    if (index === -1 || subIndex === -1) {
      return;
    }

    const newSteps = [...currentSteps];
    newSteps[index] = {
      ...step,
      subSteps: [
        ...(step.subSteps?.slice(0, subIndex) as EditableStep[]),
        ...(step.subSteps?.slice(subIndex + 1) as EditableStep[]),
      ],
    };
    setCurrentSteps(newSteps);
  };

  const editStep = (step: EditableStep) => {
    const index = currentSteps.indexOf(step);
    if (index === -1) {
      return;
    }

    const newSteps = [...currentSteps];
    newSteps[index] = { ...step, editing: true };
    setCurrentSteps(newSteps);
  };

  const editSubStep = (step: EditableStep, subStep: EditableStep) => {
    const index = currentSteps.indexOf(step);
    const subIndex = step.subSteps?.indexOf(subStep) ?? -1;
    if (index === -1 || subIndex === -1) {
      return;
    }

    const newSteps = [...currentSteps];
    newSteps[index] = {
      ...step,
      subSteps: [
        ...(step.subSteps?.slice(0, subIndex) as EditableStep[]),
        { ...subStep, editing: true },
        ...(step.subSteps?.slice(subIndex + 1) as EditableStep[]),
      ],
    };
    setCurrentSteps(newSteps);
  };

  const saveStep = (index: number, step: EditableStep) => {
    const newSteps = [...currentSteps];
    newSteps[index] = step;
    newSteps[index].editing = false;
    setCurrentSteps(newSteps);
  };

  const saveSubStep = (
    stepIndex: number,
    subStepIndex: number,
    subStep: EditableStep
  ) => {
    const newSteps = [...currentSteps];
    newSteps[stepIndex] = {
      ...newSteps[stepIndex],
      subSteps: [
        ...(newSteps[stepIndex].subSteps?.slice(
          0,
          subStepIndex
        ) as EditableStep[]),
        subStep,
        ...(newSteps[stepIndex].subSteps?.slice(
          subStepIndex + 1
        ) as EditableStep[]),
      ],
    };
    const step = newSteps[stepIndex];
    const substep = step.subSteps?.[subStepIndex];
    if (substep) {
      substep.editing = false;
    }
    setCurrentSteps(newSteps);
  };

  const cancelStep = (index: number, step: EditableStep) => {
    const newSteps = [...currentSteps];
    newSteps[index] = step;
    newSteps[index].editing = false;
    setCurrentSteps(newSteps);
  };

  const cancelSubStep = (
    stepIndex: number,
    subStepIndex: number,
    subStep: EditableStep
  ) => {
    const newSteps = [...currentSteps];
    newSteps[stepIndex] = {
      ...newSteps[stepIndex],
      subSteps: [
        ...(newSteps[stepIndex].subSteps?.slice(
          0,
          subStepIndex
        ) as EditableStep[]),
        subStep,
        ...(newSteps[stepIndex].subSteps?.slice(
          subStepIndex + 1
        ) as EditableStep[]),
      ],
    };
    const step = newSteps[stepIndex];
    const substep = step.subSteps?.[subStepIndex];
    if (substep) {
      substep.editing = false;
    }
    setCurrentSteps(newSteps);
  };

  const canSwap = currentSteps
    .filter((step) => step.stepType !== TrainingEventStepType.REPEAT)
    .every((step) => !step.editing);

  return (
    <div>
      <h1 className="mt-4 font-medium text-neutral-900 text-[14px] mb-4">
        Instructions
      </h1>
      <div className="flex items-center gap-2 mb-4">
        <CreateStep onClick={addStep} />
        <CreateRepeatStep onClick={addRepeatStep} />
      </div>

      <div className="space-y-2">
        {currentSteps.map((step, index) => (
          <div key={index}>
            {step.stepType === TrainingEventStepType.REPEAT ? (
              <StepRepeat
                index={index}
                step={step}
                steps={currentSteps}
                onDelete={() => deleteStep(step)}
                onChangeRepeatCount={editRepeatStepRepeatCount}
                onMoveUp={() => moveStepUp(index)}
                onMoveDown={() => moveStepDown(step)}
                onAddStep={() => addStepToRepeat(index)}
                canSwap={canSwap}
                onMoveSubStepUp={(subStep: EditableStep) =>
                  moveSubStepUp(subStep)
                }
                onMoveSubStepDown={(subStep: EditableStep) =>
                  moveSubStepDown(step, subStep)
                }
                onDeleteSubStep={(subStep: EditableStep) =>
                  deleteSubStep(step, subStep)
                }
                onEditSubStep={(subStep: EditableStep) =>
                  editSubStep(step, subStep)
                }
                onSaveSubStep={saveSubStep}
                onCancelSubStep={cancelSubStep}
                selectedTrainingProfile={selectedTrainingProfile}
              />
            ) : step.editing ? (
              <StepEditor
                step={step}
                index={index}
                onSave={saveStep}
                onCancel={cancelStep}
                selectedTrainingProfile={selectedTrainingProfile}
              />
            ) : (
              <StepDisplay
                step={step}
                steps={currentSteps}
                onMoveUp={() => moveStepUp(index)}
                onMoveDown={() => moveStepDown(step)}
                onDelete={() => deleteStep(step)}
                onEdit={() => editStep(step)}
                canSwap={canSwap}
                selectedTrainingProfile={selectedTrainingProfile}
              />
            )}
          </div>
        ))}
      </div>
      {currentSteps.length > 0 && (
        <div className="flex justify-center mt-8 gap-2">
          <CreateStep onClick={addStep} noText />
          <CreateRepeatStep onClick={addRepeatStep} noText />
        </div>
      )}
    </div>
  );
}
