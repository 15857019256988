import { Notification } from "../../Api/Stream";
import { trainingProfile } from "../../Api/TrainingProfile";
import { Link } from "react-router-dom";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";
import profilePic from "../../Assets/notificationAvatarPlaceholder.png";
import { AnnotationIcon } from "@heroicons/react/outline";
import { formatNotificationDate } from "../../Utilities/DateUtilities";

export default function NotificationPreview({
  feedItem,
  selectedTrainingProfile,
  handleClickNotification,
  isReadMap,
}: {
  feedItem: Notification;
  selectedTrainingProfile: trainingProfile;
  handleClickNotification: (feedItem: Notification) => Promise<void>;
  isReadMap:
    | {
        id: string;
        is_read: boolean;
      }[]
    | undefined;
}) {
  const calendarPath = (feedItem: Notification) => {
    if (!selectedTrainingProfile || !feedItem.activities[0].trainingEvent)
      return "";

    return `/coach/${selectedTrainingProfile.miniProfile.id}/calendar/${feedItem.activities[0].trainingEvent.id}`;
  };

  function formatDate(feedItem: Notification) {
    if (!feedItem.activities[0].trainingEvent) return "";

    const dateSplit = (
      "" + feedItem.activities[0].trainingEvent.scheduledAt
    ).split("-");
    return dateSplit[1] + "/" + dateSplit[2];
  }

  function PreviewContent() {
    if (feedItem.activities[0].trainingEvent) {
      return (
        <div className="text-xs font-light text-black">
          <span className="font-semibold">
            {feedItem.activities[0].actorMiniProfile.name.split(" ")[0]}
          </span>
          {"  "}
          commented on{" "}
          <span className="font-semibold">
            {feedItem.activities[0].trainingEvent.title} ({formatDate(feedItem)}
            )
          </span>
        </div>
      );
    } else if (feedItem.activities[0].verb === "reacted") {
      return (
        <div className="text-xs font-light text-black">
          <span className="font-semibold">
            {feedItem.activities[0].actorMiniProfile.name.split(" ")[0]}
          </span>
          {"  "}
          left a reaction:{" "}
          <span className="font-semibold">
            {feedItem.activities[0].message}
          </span>
        </div>
      );
    } else {
      return (
        <div className="text-xs font-light text-black line-clamp-1">
          <span className="font-semibold">
            {feedItem.activities[0].actorMiniProfile.name.split(" ")[0]}
          </span>
          {"  "}
          {feedItem.activities[0].verb === "commented"
            ? "commented on"
            : "posted"}{" "}
          a <span className="font-semibold">training note</span>
          {feedItem.activities[0].message && (
            <span>: {feedItem.activities[0].message}</span>
          )}
        </div>
      );
    }
  }

  return (
    <Link replace to={calendarPath(feedItem)}>
      <li
        onClick={(e: any) => {
          e.preventDefault();
          handleClickNotification(feedItem);
        }}
        key={feedItem.id}
        className="relative py-4 cursor-pointer"
      >
        <div>
          <div className="relative flex items-center space-x-3">
            <>
              <>
                <div className="relative">
                  <ProfilePicture
                    classNames="flex items-center justify-center bg-gray-400 w-9 h-9 rounded-xl ring-8 ring-transparent"
                    url={
                      feedItem.activities[0].actorMiniProfile.profilePicture ??
                      profilePic
                    }
                  />

                  <span className="absolute -bottom-0.5 -right-1 bg-white rounded-full px-0.5 py-0.5">
                    <AnnotationIcon className="w-3.5 h-3.5 text-gray-600" />
                  </span>
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between pr-1">
                    <div className="pr-1">
                      <PreviewContent />

                      <p className="mt-0.5 text-xs font-thin text-gray-500">
                        {formatNotificationDate(
                          new Date(feedItem.updated_at + "Z")
                        )}
                      </p>
                    </div>{" "}
                    {!isReadMap?.find(
                      (isReadMap) => isReadMap.id === feedItem.id
                    )?.is_read ? (
                      <div className="overflow-visible shrink-0 w-2.5 h-2.5 rounded-full bg-rose-400"></div>
                    ) : (
                      <div className="overflow-visible shrink-0 invisible w-2.5 h-2.5 rounded-full bg-rose-400"></div>
                    )}
                  </div>
                </div>
              </>
            </>
          </div>
        </div>
      </li>
    </Link>
  );
}
