export default function CancelButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className="bg-white text-neutral-700 border border-neutral-300 font-medium text-[14px] px-[16px] py-[8px] rounded-full hover:bg-neutral-200 leading-[20px] inline-flex items-center space-x-[11px]"
    >
      Cancel
    </button>
  );
}
