import React from "react";
import PaceIcon from "../../../../Assets/Calendar/PaceIcon.svg";
import {
  encodeDistance,
  encodeDuration,
  formatPace,
} from "../../../../Utilities/FormatUtilties";
import { DistanceBlockCalendarEventInput } from "./DistanceBlock";
import { DurationBlockCalendarEventInput } from "./DurationBlock";

interface PaceBlockCalendarEventInput
  extends DistanceBlockCalendarEventInput,
    DurationBlockCalendarEventInput {}

export const PaceBlock = ({
  assignedDistance,
  actualDistance,
  metricSetting,
  assignedDuration,
  actualDuration,
}: PaceBlockCalendarEventInput) => {
  const formattedAssignedPace =
    assignedDistance !== "" && assignedDuration !== "0:00"
      ? formatPace(
          encodeDistance(assignedDistance, metricSetting),
          encodeDuration(assignedDuration),
          metricSetting
        )
      : null;

  const formattedActualPace =
    actualDistance && actualDuration
      ? formatPace(actualDistance, actualDuration, metricSetting)
      : null;

  const toShowPace = formattedAssignedPace || formattedActualPace;

  return (
    <>
      {toShowPace && (
        <div className="flex flex-row items-center ">
          <img
            src={PaceIcon}
            alt="distance icon"
            className="w-4 h-4 mb-[2px] mr-1"
          />
          <div className="flex justify-between text-[#374151]">
            {formattedAssignedPace ? <p>{formattedAssignedPace}</p> : <p>_</p>}
          </div>
          <div className="flex justify-between text-[#6B7280]">
            {formattedActualPace && (
              <p>
                <span>&nbsp;/&nbsp;</span>
                {formattedActualPace}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};
