/* eslint-disable */
import { useCallback, useContext, useEffect, useState } from "react";

import { getUserInfo } from "../Api/User";
import Stepper from "../Coachee/Components/Stepper";

import { PopupButton } from "@typeform/embed-react";

import "@typeform/embed/build/css/widget.css";
import { getTrainingAttributes } from "../Api/TrainingAttribute";
import Bookmark from "../Assets/RedBookmark.svg";
import { track, TrackingEventType } from "../Api/Tracking";
import { getClientSecret, getPaymentUrlForExperiment } from "../Api/Payment";
import {
  getCurrentExperiment,
  getCurrentPrice,
  isCollectCCOnly,
} from "../Utilities/Experiments";
import { UserInfoContext } from "../Context/UserContext";

import CoacheeHeader from "../Coachee/Components/CoacheeHeader";
import PendingStepsPage from "../Coachee/Components/Pending/PendingStepsPage";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { useTrainingAttributes } from "../Context/TrainingAttributeContext";
import { classNames } from "../Utilities/classnames";
import { isMobile } from "../Utilities/isMobile";
import DefaultWebFooter from "../Coach/Components/Footer/DefaultWebFooter";

type OnboardState =
  | "Goals"
  | "Strava"
  | "Strava embed"
  | "Checkout"
  | "Payment"
  | "ScheduleCall"
  | "Welcome";

function Onboard() {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const { trainingAttributes } = useTrainingAttributes();

  const currentPrice = getCurrentPrice();
  const history = useHistory();
  const routerHook = useIonRouter();

  const [onboardState, setOnboardState] = useState<OnboardState>();

  // function openSurvey() {
  //   if (userInfo) {
  //     window.open(
  //       "https://igz6897qpqi.typeform.com/to/PQM6njMG#perform_auth_token=" +
  //         userInfo.token,
  //       "_blank"
  //     );
  //   }
  // }

  function connectToStrava(e: any) {
    track(
      TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
      "Clicked connect Strava"
    );

    window.location.replace(
      process.env.REACT_APP_BASE_URL_API +
      "/server/authenticate/strava-sync?auth_strava=true"
    );
  }

  const renderOnboardState = useCallback(() => {
    function Goals() {
      return (
        <div className={classNames(isMobile ? "" : "mx-8")}>
          <Stepper
            stepperStyle={`lg:mx-auto lg:max-w-xl lg:mt-64 ${isMobile ? "mx-8" : ""
              }`}
            count={1}
            userInfo={userInfo!}
            trainingAttributes={trainingAttributes}
          />
          <div className={classNames(isMobile ? "mx-8" : "")}>
            <div className="flex flex-col items-start lg:max-w-xl lg:mx-auto">
              <h1 className="self-start mt-6 text-xl font-semibold text-left">
                Tell us about your goals
              </h1>
              <h2 className="mt-4 text-sm text-left text-[#767676]">
                Tell us about your fitness background and running goals so that
                we can tailor your program to you.
              </h2>
              <PopupButton
                id="PQM6njMG"
                hidden={{ perform_auth_token: userInfo!.token }}
                onReady={() => {
                  track(
                    TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
                    "Loaded survey"
                  );
                }}
                onSubmit={() => {
                  track(
                    TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
                    "Finished survey"
                  );
                  setOnboardState("ScheduleCall");
                }}
                className="w-10/12 px-4 py-4 mt-8 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
              >
                Get Started
              </PopupButton>
            </div>
          </div>
        </div>
      );
    }

    function Strava() {
      return (
        <div className={classNames(isMobile ? "" : "mx-8")}>
          <Stepper
            stepperStyle={`lg:mx-auto lg:max-w-xl lg:mt-64 ${isMobile ? "mx-8" : ""
              }`}
            count={3}
            userInfo={userInfo!}
            trainingAttributes={trainingAttributes}
          />
          <div className={classNames(isMobile ? "mx-8" : "")}>
            <div className="lg:max-w-xl lg:mx-auto">
              <h1 className="mt-6 text-xl font-semibold text-left">
                Connect Strava
              </h1>
              <h2 className="mt-4 text-sm text-left text-[#767676]">
                Runzy connects with Strava to automatically track and anaylze
                completed runs.
              </h2>
              <div className="flex flex-col items-start">
                <button
                  onClick={(e) => connectToStrava(e)}
                  className="w-10/12 px-4 py-4 mt-8 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                >
                  Connect
                </button>
                <button
                  onClick={() => {
                    track(
                      TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
                      "Skipped Strava"
                    );
                    routerHook.push("/pending");
                  }}
                  className="mt-6 text-sm text-brand"
                >
                  Skip for now
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    function ScheduleOnboardingCall() {
      return <PendingStepsPage currentStep={1} />;
    }

    function CheckoutWithSaveCard() {
      const [isVisible, setIsVisible] = useState(false);
      const currentPrice = getCurrentPrice();
      const onClose = useCallback(() => {
        setIsVisible(false);
      }, []);

      return (
        <div className={classNames(isMobile ? "" : "mx-8")}>
          <Stepper
            stepperStyle={`lg:mx-auto lg:max-w-xl lg:mt-64 ${isMobile ? "mx-8" : ""
              }`}
            count={3}
            userInfo={userInfo!}
            trainingAttributes={trainingAttributes}
          />{" "}
          <div className={classNames(isMobile ? "mx-8" : "")}>
            <div className="lg:max-w-xl lg:mx-auto">
              <h1 className="mt-6 text-xl font-semibold text-left">
                Complete checkout
              </h1>
              <h2 className="mt-4 mr-6 text-sm text-left text-[#767676]">
                Get your AI-powered training program for just ${currentPrice}
                /month. Cancel anytime.
              </h2>
              <div className="flex flex-col mt-4 mr-6 start-left">
                <button
                  onClick={() => {
                    track(
                      TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
                      "Clicked continue to checkout"
                    );
                    setIsVisible(true);
                  }}
                  // onClick={() => navigate("../payment")}
                  className="w-10/12 px-4 py-4 mt-8 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    function CheckoutWithSubscription() {
      const [isLoading, setIsLoading] = useState(false);

      return (
        <div className={classNames(isMobile ? "" : "mx-8")}>
          <Stepper
            stepperStyle={`lg:mx-auto lg:max-w-xl lg:mt-64 ${isMobile ? "mx-8" : ""
              }`}
            count={3}
            userInfo={userInfo!}
          />
          <div className={classNames(isMobile ? "mx-8" : "")}>
            <div className="flex flex-col items-start lg:max-w-xl lg:mx-auto">
              <h1 className="self-start mt-6 text-xl font-semibold text-left">
                Complete checkout
              </h1>
              <h2 className="mt-4 mr-6 text-sm text-left text-[#767676]">
                Get unlimited personal coaching and adaptive training plans for
                just ${currentPrice}/month. Runzy is risk-free. Cancel anytime
                in the next 7 days for a full refund.
              </h2>
              <button
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  track(
                    TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
                    "Clicked continue to checkout"
                  );
                  getClientSecret().then((_) => {
                    getPaymentUrlForExperiment(getCurrentExperiment()).then(
                      (value: any) => {
                        if (!value) {
                          setIsLoading(false);
                          alert(
                            "Something went wrong. Please try again later."
                          );
                          track(
                            TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
                            "🚨 Failed to open payment checkout due to backend error"
                          );
                        }
                        if (value!.token != null) {
                          window.location.href = value!.token;
                        }
                      }
                    );
                  });
                  //setIsVisible(true);
                }}
                // onClick={() => navigate("../payment")}
                className="w-10/12 px-4 py-4 mt-8 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-brand hover:bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      );
    }

    function Welcome() {
      return (
        <div>
          <CoacheeHeader trainingAttributes={trainingAttributes} />
          <div className="lg:mx-auto lg:max-w-xl lg:mt-64">
            <h1 className="mt-6 text-xl font-semibold text-left">
              {userInfo!.firstName === ""
                ? "Welcome!"
                : `Welcome, ${userInfo!.firstName}!`}
            </h1>
            <h2 className="mt-4 mr-6 text-sm text-left text-[#767676]">
              Thanks for signing up for Perform. We can't wait to work with you
              towards your running goals. We're currently reviewing your
              questionnaire and matching you with the best coach. We'll be in
              touch with you soon to kick off your personalized training
              program.
            </h2>
            <div className="flex items-center gap-5 px-4 py-6 my-5 mr-6 border border-gray-300 rounded-xl">
              <img
                className="w-auto rounded-full h-7 bg-brand"
                src={Bookmark}
                alt="Activity Info not available"
              />
              <p className="text-xs text-left text-gray-500">
                Your personalized plan and feedback will live here soon. We
                recommend bookmarking this page for easy access.
              </p>
            </div>
          </div>
        </div>
      );
    }

    function renderOnboardState() {
      switch (onboardState) {
        case "Goals":
          return <Goals />;
        case "Strava":
          return <Strava />;
        case "ScheduleCall":
          return <ScheduleOnboardingCall />;
        case "Checkout":
          return isCollectCCOnly() ? (
            <CheckoutWithSaveCard />
          ) : (
            <CheckoutWithSubscription />
          );
        case "Welcome":
          return <Welcome />;
      }
    }

    return renderOnboardState();
  }, [onboardState, currentPrice, routerHook, trainingAttributes, userInfo]);

  // function paymentStatus() {
  //   const paramField = searchParams.get("paymentSuccess");

  //   if (!paramField) {
  //     return null;
  //   }

  //   return paramField == "true";
  // }

  useEffect(() => {
    async function handleUserInfo() {
      let response = await getUserInfo();

      if (response.userInfo) {
        setUserInfo(response.userInfo);
      }
    }

    if (userInfo) return;
    handleUserInfo();
  }, [setUserInfo, userInfo]);

  useEffect(() => {
    async function initOnboardState() {
      if (userInfo) {
        let trainingAttributes = await getTrainingAttributes(userInfo.id);
        if (trainingAttributes) {
          const onboardingProgress = trainingAttributes.onboardingProgress;
          switch (onboardingProgress) {
            case 0:
              setOnboardState("Goals");
              break;
            case 1:
              setOnboardState("ScheduleCall");
              break;
            case 2:
              history.push("/pending");
              break;
            case 3:
              history.push("/pending");
              break;
            case 4:
            case 5:
              history.push("/dashboard");
              break;
            default:
              break;
          }
        }
      }
    }

    if (onboardState) return;
    initOnboardState();
  }, [userInfo, history, onboardState]);

  useEffect(() => {
    renderOnboardState();
  }, [onboardState, renderOnboardState]);

  return (
    <IonPage>
      <IonContent>{userInfo && renderOnboardState()}</IonContent>
      {/* {!isMobile && <DefaultWebFooter />} */}
    </IonPage>
  );
}

export default Onboard;
