import { useState } from "react";
import DetectableOverflow from "react-detectable-overflow";
import { trainingEvent } from "../../Api/TrainingEvent";

interface DescriptionCellInput {
  currentDescription: string[];
  event: trainingEvent;
}

function DescriptionCell({ currentDescription, event }: DescriptionCellInput) {
  const [descriptionIsHovered, setDescriptionIsHovered] =
    useState<boolean>(false);
  const [descriptionShowMore, setDescriptionShowMore] = useState(false);

  function handleDescriptionMouseOver() {
    setDescriptionIsHovered(true);
  }

  function handleDescriptionMouseOut() {
    setDescriptionIsHovered(false);
  }

  function handleDescriptionOverFlow() {
    if (!descriptionShowMore) setDescriptionShowMore(true);
  }

  return (
    <td
      onMouseOver={() => handleDescriptionMouseOver()}
      onMouseOut={() => handleDescriptionMouseOut()}
      className={
        descriptionShowMore && descriptionIsHovered
          ? "relative max-w-[170px] 2xl:max-w-[340px] px-4 py-2 text-sm text-left text-gray-600"
          : "w-10 max-w-[170px] 2xl:max-w-[340px] px-4 py-2 text-sm text-left text-gray-600 "
      }
    >
      {!event.id.includes("activity") && (
        <>
          <DetectableOverflow
            onChange={() => handleDescriptionOverFlow()}
            style={{}}
            className="truncate"
          >
            {currentDescription.join("$")}
          </DetectableOverflow>
          <div
            className={
              descriptionShowMore && descriptionIsHovered
                ? "card-hover"
                : "hidden"
            }
          >
            <ol className="list-decimal list-inside">
              {currentDescription.map((instruction, index) => (
                <li key={index}>{instruction}</li>
              ))}
            </ol>
          </div>
        </>
      )}
    </td>
  );
}

export default DescriptionCell;
