import { Listbox, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CheckIcon,
} from "@heroicons/react/outline";
import { Fragment, useEffect } from "react";
import { classNames } from "../../../../Utilities/classnames";

interface ComboBoxInput {
  options: string[];
  subOptions?: string[];
  selected: string | undefined;
  onChange: (value: string) => void;
}

function EditorDropdown({ options, selected, onChange }: ComboBoxInput) {
  const formatString = (str: string | undefined) => {
    if (!str) return " ";
    return str;
  };

  useEffect(() => {
    if (options.length > 0 && selected && !options.includes(selected)) {
      onChange(options[0]);
    }
  }, [options]);

  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer disabled:cursor-not-allowed focus:outline-none focus:ring-1 focus:ring-brand focus:border-brand sm:text-sm">
              <span className="block truncate">
                {formatString(selected ?? "")}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                {open ? (
                  <ChevronUpIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronDownIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-brand/20" : "text-gray-900",
                        "cursor-pointer select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected }) => (
                      <>
                        <div className="flex items-center gap-1">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {formatString(option)}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              "absolute inset-y-0 right-0 flex text-brand items-center pr-4"
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default EditorDropdown;
