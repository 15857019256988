import CoacheeHeader from "../Coachee/Components/CoacheeHeader";

import AppleLogo from "../Assets/apple.svg";
import { IonContent, IonPage } from "@ionic/react";
import { track, TrackingEventType } from "../Api/Tracking";
import { useTrainingAttributes } from "../Context/TrainingAttributeContext";
import appleDownload from "../Assets/appledownload.png";

import AndroidLogo from "../Assets/android.png";
import androidDownload from "../Assets/androiddownload.png";

function AppDownload() {
  const { trainingAttributes } = useTrainingAttributes();

  const actions = [
    {
      title: "Runzy iPhone App",
      href: "https://apps.apple.com/us/app/perform-running/id1617179475",
      action: () => {
        track(
          TrackingEventType.TRACKING_APP_WEB_IOS_LINK,
          "Clicked link to download iOS app.",
          "profiles"
        );
      },
      icon: (
        <img
          className="w-auto h-36 sm:w-36 sm:h-auto sm:mt-3"
          src={AppleLogo}
          alt="Apple Logo"
        />
      ),
      downloadIcon: (
        <img
          className="w-auto h-10"
          src={appleDownload}
          alt="Apple Download Logo"
        />
      ),
    },
    {
      title: "Runzy Android App",
      href: "https://play.google.com/store/apps/details?id=com.joinperform.PerformRunning",
      action: () => {
        track(
          TrackingEventType.TRACKING_APP_WEB_ANDROID_LINK,
          "Clicked link to download Android app.",
          "profiles"
        );
      },
      icon: (
        <img
          className="w-auto h-36 sm:w-36 sm:h-auto sm:mt-3"
          src={AndroidLogo}
          alt="Android Logo"
        />
      ),
      downloadIcon: (
        <img
          className="w-auto h-10"
          src={androidDownload}
          alt="Android Download Logo"
        />
      ),
    },
  ];

  return (
    <IonPage>
      <CoacheeHeader trainingAttributes={trainingAttributes} />
      <IonContent>
        <div className="min-h-screen bg-gray-100 min-w-screen">
          <div className="min-h-screen px-6 pb-10 mx-auto bg-gray-100 max-w-7xl sm:px-6 lg:px-8">
            {" "}
            <h2 className="pt-10 pb-6 mx-1 text-xl font-semibold text-left">
              Apps
            </h2>
            <div className="bg-gray-100">
              <ul className="grid grid-cols-1 gap-6 mx-8 mb-12 sm:grid-cols-2 lg:grid-cols-2">
                {actions.map((action, index) => (
                  <li
                    onClick={() => action.action()}
                    key={index}
                    className="col-span-1 bg-white divide-y divide-gray-200 rounded-lg drop-shadow-xl"
                  >
                    <a href={action.href}>
                      <div className="flex flex-col items-center gap-4 p-6 mx-auto sm:flex-row">
                        <div className="flex-shrink sm:self-start">
                          {" "}
                          {action.icon}
                        </div>
                        <div className="flex flex-col items-center text-center sm:text-left sm:items-start">
                          <p className="mt-3 text-sm font-medium text-gray-500">
                            Mobile App
                          </p>
                          <p className="mt-3 text-xl font-medium text-gray-500">
                            {action.title}
                          </p>
                          <p className="mt-3 text-sm text-gray-500">
                            Reach your running goals on the go.
                          </p>
                          <div className="my-12 sm:my-3">
                            {action.downloadIcon}
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default AppDownload;
