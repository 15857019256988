import { UnitPreference } from "../../../Api/User";

export default function AssignedDistanceDisplay({
  currentAssignedDistance,
  metricSetting,
}: {
  currentAssignedDistance: string;
  metricSetting: UnitPreference;
}) {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="assigned-duration"
        className="block text-sm font-medium text-gray-700"
      >
        Assigned distance (
        {metricSetting === UnitPreference.IMPERIAL ? "mi" : "km"})
      </label>
      <div className="mt-1">
        <p className="block w-full pt-2 pl-2 border-gray-300 rounded-md focus:ring-brand focus:border-brand sm:text-sm">
          {/* {formatPace(
            encodeDistance(assignedDistance, metricSetting),
            encodeDuration(assignedDuration),
            metricSetting
          ) === ""
            ? "-"
            : formatPace(
                encodeDistance(assignedDistance, metricSetting),
                encodeDuration(assignedDuration),
                metricSetting
              )}{" "} */}

          {currentAssignedDistance === "" ? "-" : currentAssignedDistance}
        </p>
      </div>
    </div>
  );
}
