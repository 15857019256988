import { ActivityRoute } from "../../Api/ActivityRoute";
import { UnitPreference } from "../../Api/User";
import {
  decodeDateFromSecondsFormat,
  formatAMPM,
  formatFullDuration,
} from "../../Utilities/DateUtilities";
import {
  formatDistance,
  formatNetElevation,
  formatPace,
  titleCase,
} from "../../Utilities/FormatUtilties";

interface ModalInfoInput {
  sportsType?: string;
  metricSetting: UnitPreference;
  activityRoute: ActivityRoute | null;
}

function ModalInfo({
  sportsType,
  metricSetting,
  activityRoute,
}: ModalInfoInput) {
  return (
    <div className="flex flex-col mt-4">
      <div className="-my-2 overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    LOCAL TIME
                  </th>
                  {sportsType && (
                    <th
                      scope="col"
                      className="p-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      TYPE
                    </th>
                  )}
                  {activityRoute?.activity.distanceInMeters ?
                  <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    DISTANCE
                  </th> : <></>}
                  <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    DURATION
                  </th>
                  {activityRoute?.activity.distanceInMeters ?
                  <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    AVG PACE
                  </th> : <></>}
                  {activityRoute?.activity.distanceInMeters ?
                    <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    ELEV
                  </th> : <></>}
                  <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    HR
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    USER TITLE
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    DESCRIPTION
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr className="font-tableHeader">
                  <td className="p-3">
                    {/* local time */}
                    {activityRoute?.activity.startedAt &&
                      formatAMPM(
                        decodeDateFromSecondsFormat(
                          activityRoute.activity.startedAt
                        ),
                        "min"
                      )}
                  </td>
                  {sportsType && (
                    <td className="p-3">
                      {/* sports type */}
                      {titleCase(sportsType)}
                    </td>
                  )}
                  {activityRoute?.activity.distanceInMeters ? <td className="p-3">
                    {/* distance */}
                    {activityRoute?.activity.distanceInMeters &&
                      formatDistance(
                        activityRoute.activity.distanceInMeters,
                        metricSetting,
                        true
                      )}
                  </td> : <></>}
                  <td className="p-3">
                    {/* duration */}
                    {activityRoute?.activity.movingTime &&
                      formatFullDuration(activityRoute?.activity.movingTime)}
                  </td>
                  {activityRoute?.activity.distanceInMeters ? <td className="p-3">
                    {/* avg pace */}
                    {activityRoute?.activity.distanceInMeters &&
                      activityRoute.activity.movingTime &&
                      formatPace(
                        activityRoute.activity.distanceInMeters,
                        activityRoute.activity.movingTime,
                        metricSetting
                      )}
                  </td> : <></>}
                  {activityRoute?.activity.distanceInMeters ? <td className="p-3">
                    {/* elev */}
                    {activityRoute?.points &&
                      formatNetElevation(activityRoute.activity.elevationGain, activityRoute.points, metricSetting)}
                  </td> : <></>}
                  <td className="p-3">
                    {/* heart rate */}
                    {activityRoute?.activity.averageHeartRate &&
                      Math.round(activityRoute?.activity.averageHeartRate)}
                  </td>
                  <td className="p-3">
                    {/* user title */} {activityRoute?.activity.userTitle}
                  </td>
                  <td className="p-3">
                    {/* description */}
                    {activityRoute?.activity &&
                      activityRoute.activity.description}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalInfo;
