import { connect, StreamClient, StreamFeed } from "getstream";
import { useContext, useState, useEffect } from "react";
import Default from "../../Default/Default";
import { EventActivity, Post } from "../../Api/Stream";
import { UserInfoContext } from "../../Context/UserContext";
import { useInView } from "react-hook-inview";
import { trainingEvent } from "../../Api/TrainingEvent";
import { deletePost, editPost } from "../../Api/Post";
import { parseISO } from "date-fns";
import { convertCalendarDateToLocalTimezone } from "../../Utilities/DateUtilities";

function useDashboardContentStream({
  refreshTrigger,
}: {
  refreshTrigger?: number;
}) {
  const { userInfo } = useContext(UserInfoContext);
  const [streamClient, setStreamClient] = useState<StreamClient>();
  const [feed, setFeed] = useState<StreamFeed>();
  const [feedItems, setFeedItems] = useState<(EventActivity | Post)[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [paginationRef, inView] = useInView({ threshold: 1 });
  const [canAppend, setCanAppend] = useState(true);
  const [postIdToBeDeleted, setPostIdToBeDeleted] = useState<string>();

  function initStreamClient() {
    if (userInfo) {
      const client = connect(
        Default.streamsApiKey,
        userInfo.streamAPIAuth,
        Default.streamsAppId
      );

      setStreamClient(client);
    }
  }

  async function initFeed() {
    if (streamClient) {
      setFeed(streamClient.feed("news"));
    }
  }

  async function initFeedItems() {
    if (streamClient && feed) {
      const items = (await (
        await feed.get({ limit: 100 })
      ).results) as (EventActivity | Post)[];
      // @ts-ignorex
      let updatedItems = items.filter(item => !item.trainingEvent || item.trainingEvent.trainingEventType !== 'CHALLENGE_DAY')
      setFeedItems(sortFeedItems(updatedItems));
      setIsLoading(false);
    }
  }

  function sortFeedItems(items: (EventActivity | Post)[]) {
    items.sort((activityA: any, activityB: any) => {
      const aPublishedDate = parseISO(activityA.published);
      const aScheduledDate = activityA.trainingEvent
        ? convertCalendarDateToLocalTimezone(
            new Date(activityA.trainingEvent.scheduledAt)
          )
        : null;
      const aDate = aScheduledDate ?? aPublishedDate;

      const bPublishedDate = parseISO(activityB.published);
      const bScheduledDate = activityB.trainingEvent
        ? convertCalendarDateToLocalTimezone(
            new Date(activityB.trainingEvent.scheduledAt)
          )
        : null;
      const bDate = bScheduledDate ?? bPublishedDate;

      return bDate.getTime() - aDate.getTime();
    });

    return items;
  }

  function activateDeleteModal(postId: string) {
    setPostIdToBeDeleted(postId);
  }

  function deactivateDeleteModal() {
    setPostIdToBeDeleted(undefined);
  }

  async function deleteFeedItem() {
    if (postIdToBeDeleted) {
      const response = await deletePost(postIdToBeDeleted);

      if (response) {
        setPostIdToBeDeleted(undefined);
        initFeedItems();
      }
    }
  }

  async function editFeedItem(feedId: string, content: string) {
    const response = await editPost(feedId, content);

    return response;
  }

  async function appendActivities() {
    if (streamClient && feed && canAppend && feedItems) {
      setIsLoading(true);

      const newItems = (await (
        await feed.get({ id_lt: feedItems[feedItems.length - 1].id })
      ).results) as (Post | EventActivity)[];
      // @ts-ignorex
      let updatedItems = newItems.filter(item => !item.trainingEvent || item.trainingEvent.trainingEventType !== 'CHALLENGE_DAY');
      let filteredItems = updatedItems.filter(item => !feedItems.find(i => item.id === i.id));
      setFeedItems(sortFeedItems(feedItems.concat(filteredItems)));
      if (filteredItems.length === 0) {
        setCanAppend(false);
      }
      setIsLoading(false);
    }
  }

  async function getFeedItemImage(trainingEvent: trainingEvent) {
    if (streamClient && feed && trainingEvent.feedActivityId) {
      const items = (await (
        await feed.get({ id_gte: trainingEvent.feedActivityId, limit: 1 })
      ).results) as EventActivity[];

      if (items && items.length > 0 && items[0].trainingEvent) {
        return items[0].trainingEvent.routePreviewImageUrl;
      }
    }
  }

  useEffect(() => {
    initFeedItems();
  }, [feed]);

  useEffect(() => {
    appendActivities();
  }, [inView]);

  useEffect(() => {
    initFeed();
  }, [streamClient, refreshTrigger]);

  useEffect(() => {
    initStreamClient();
  }, [userInfo]);

  return {
    streamClient,
    feed,
    feedItems,
    isLoading,
    paginationRef,
    getFeedItemImage,
    deleteFeedItem,
    editFeedItem,
    activateDeleteModal,
    deactivateDeleteModal,
    postIdToBeDeleted,
    setCanAppend,
  } as useDashboardContentStreamOutput;
}

export interface useDashboardContentStreamOutput {
  streamClient: StreamClient | undefined;
  feed: StreamFeed | undefined;
  feedItems: (EventActivity | Post)[] | undefined;
  isLoading: boolean;
  paginationRef: (node: Element | null) => void;
  getFeedItemImage: (
    trainingEvent: trainingEvent
  ) => Promise<string | undefined>;
  deleteFeedItem: () => Promise<void>;
  editFeedItem: (feedId: string, content: string) => Promise<200 | null>;
  activateDeleteModal: (postId: string) => void;
  deactivateDeleteModal: () => void;
  postIdToBeDeleted: string | undefined;
  setCanAppend: (canAppend: boolean) => void;
}

export default useDashboardContentStream;
