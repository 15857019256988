import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
} from "@ionic/react";
import { classNames } from "../../../Utilities/classnames";
import BackArrow from "../../../Assets/BackArrow.svg";
import Check from "../../../Assets/Check.svg";

function MobileHeader({
  hasScrolled,
  handleSubmit,
  saveDisabled,
}: {
  hasScrolled: boolean;
  handleSubmit(): Promise<void>;
  saveDisabled: () => boolean;
}) {
  return (
    <IonHeader>
      <IonToolbar
        className={classNames(
          hasScrolled ? "border-b border-gray-200" : "",
          "pb-2 mt-2"
        )}
      >
        <IonTitle>Settings</IonTitle>
        <IonButtons slot="start">
          <IonButton routerDirection="back" routerLink="/profile">
            <img src={BackArrow} alt="go back to profile" />
          </IonButton>
        </IonButtons>
        <IonButtons slot="end">
          <IonButton disabled={saveDisabled()} onClick={() => handleSubmit()}>
            <img src={Check} alt="save settings" />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
}

export default MobileHeader;
