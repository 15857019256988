import { useCallback, useContext, useEffect, useState } from "react";
import {
  createTrainingEvent,
  createTrainingEventInput,
  deleteTrainingEvent,
  editTrainingEvent,
  editTrainingEventInput,
  getTrainingEvents,
  trainingEvent,
  TrainingEventStatus,
} from "../../Api/TrainingEvent";
import TrainingEvent from "./TrainingEvent";
import AddEventButton from "./AddEventButton";
import { trainingProfile } from "../../Api/TrainingProfile";
import { getUserInfo, UnitPreference } from "../../Api/User";
import TrainingEventDetails from "./TrainingEventDetails";
import { ActivityRoute, generateActivityMapURL } from "../../Api/ActivityRoute";
import { toast } from "react-toastify";
import { UserInfoContext } from "../../Context/UserContext";
import { datesAreOnTheSameDay } from "../../Utilities/DateUtilities";

interface TrainingEventsProps {
  selectedTrainingProfile: trainingProfile;
}

function TrainingEvents({ selectedTrainingProfile }: TrainingEventsProps) {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);

  const [metricSetting, setMetricSetting] = useState<UnitPreference>(
    UnitPreference.IMPERIAL
  );
  const [trainingEvents, setTrainingEvents] = useState<trainingEvent[]>([]);

  const [showActivityDetailModal, setShowActivityDetailModal] = useState(false);
  const [activityDetailStravaLink, setActivityDetailStravaLink] =
    useState<string>();
  const [activityRoute, setActivityRoute] = useState<ActivityRoute | null>(
    null
  );

  async function onAdd() {
    const payload: createTrainingEventInput = {
      profile: selectedTrainingProfile.miniProfile.id,
      description: [""],
      status: TrainingEventStatus.SCHEDULED,
      title: "",
      message: "",
      trainingAttributes: selectedTrainingProfile.trainingAttribute,
      steps: [],
    };

    const response = await createTrainingEvent(payload);

    if (response) {
      const newTrainingEvent: trainingEvent = {
        ...response,
        actualDistance: response.actualDistance ?? 0,
        actualDuration: response.actualDuration ?? 0,
        assignedDistance: "",
        assignedDuration: "",
        status: TrainingEventStatus.SCHEDULED,
        isInEditMode: true,
        activity: null,
        completionRatio: null,
        postActivityFeedback: null,
        postActivityFeedbackSentAt: null,
        completionRatioFeedback: [],
        completedAt: null,
        steps: [],
      };

      const newTrainingEvents = [newTrainingEvent].concat(trainingEvents);
      setTrainingEvents(newTrainingEvents);

      toast("Event successfully created");
    } else {
      alert(
        "Add operation could not be completed, please check if the athlete has at-least one unblocked day"
      );
    }
  }

  async function onEdit(payload: editTrainingEventInput) {
    if (
      trainingEvents.find((event) =>
        datesAreOnTheSameDay(event.scheduledAt, payload.scheduledAt)
      )
    ) {
      toast("Edit cannot be completed, workout already scheduled on this day.");
      return;
    }

    const response = await editTrainingEvent({
      ...payload,
    });

    if (!response) {
      alert("Edit operation could not be completed");
    } else {
      initTrainingEvents();
      toast("Event successfully edited");
    }
  }

  async function onDelete(id: string) {
    const confirmation = window.confirm(
      `Are you sure you want to delete this event?`
    );

    if (confirmation) {
      const response = await deleteTrainingEvent({
        profileId: selectedTrainingProfile.miniProfile.id,
        eventId: id,
      });

      if (response) {
        const updatedTrainingEvents = trainingEvents.filter((event) => {
          return event.id !== id;
        });
        setTrainingEvents(updatedTrainingEvents);
        toast("Event successfully deleted");
      } else {
        alert("Delete operation could not be completed");
      }
    }
  }

  async function onDuplicate(event: trainingEvent) {
    const payload: createTrainingEventInput = {
      ...event,
      profile: selectedTrainingProfile.miniProfile.id,
      unitPreference: metricSetting,
    };

    const response = await createTrainingEvent(payload);
    if (response) {
      const newTrainingEvent: trainingEvent = {
        ...response,
        actualDistance: response.actualDistance ?? 0,
        actualDuration: response.actualDuration ?? 0,
        isInEditMode: false,
        activity: null,
        completionRatio: null,
        postActivityFeedback: null,
        postActivityFeedbackSentAt: null,
        completionRatioFeedback: [],
        completedAt: null,
        steps: [],
      };
      const newTrainingEvents = [newTrainingEvent].concat(trainingEvents);
      setTrainingEvents(newTrainingEvents);
      toast("Event successfully duplicated");
    }
  }

  function onShowActivityRoute(
    activityRoute: ActivityRoute,
    stravaUrl?: string
  ) {
    setActivityRoute(activityRoute);
    setShowActivityDetailModal(true);

    if (stravaUrl) {
      setActivityDetailStravaLink(stravaUrl);
    }
  }

  const initTrainingEvents = useCallback(() => {
    async function initTrainingEvents() {
      if (selectedTrainingProfile) {
        const trainingEvents = await getTrainingEvents(
          selectedTrainingProfile?.miniProfile.id
        );

        if (trainingEvents) {
          setTrainingEvents(trainingEvents);
        }
      }
    }

    initTrainingEvents();
  }, [selectedTrainingProfile]);

  useEffect(() => {
    async function initMetricSetting() {
      if (userInfo) {
        setMetricSetting(userInfo.profileMetricSetting);
      } else {
        const response = await getUserInfo();
        if (response.userInfo) {
          setUserInfo(response.userInfo);
          setMetricSetting(response.userInfo.profileMetricSetting);
        }
      }
    }

    let mounted = true;

    if (mounted) {
      initTrainingEvents();
      initMetricSetting();
    }

    return () => {
      mounted = false;
    };
  }, [selectedTrainingProfile, initTrainingEvents, setUserInfo, userInfo]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      generateActivityMapURL(activityRoute?.points);
    }

    return () => {
      mounted = false;
    };
  }, [activityRoute]);

  return (
    <div className="space-y-6">
      <div className="flex justify-end">
        <AddEventButton onClick={() => onAdd()} />
      </div>
      <div className="flex flex-col mt-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="mb-64 overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 table-fixed">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      DATE
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      STATUS
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      TYPE
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      ASSIGNED WORKOUT
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      INSTRUCTIONS
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      NOTE
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      {metricSetting === UnitPreference.IMPERIAL ? (
                        <>MILES</>
                      ) : (
                        <>KM</>
                      )}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      PACE
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      TIME
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    ></th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    ></th>
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  <>
                    {selectedTrainingProfile.trainingAttribute &&
                      trainingEvents.map((event) => (
                        <TrainingEvent
                          key={event.id}
                          metricSetting={metricSetting}
                          event={event}
                          onEdit={onEdit}
                          onDelete={onDelete}
                          onShowActivityRoute={onShowActivityRoute}
                          trainingAttributes={
                            selectedTrainingProfile.trainingAttribute
                          }
                          defaultEditMode={event.isInEditMode}
                          onDuplicate={onDuplicate}
                        />
                      ))}
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <TrainingEventDetails
          event={trainingEvents.find(
            (event) => event.externalUrl === activityDetailStravaLink
          )}
          metricSetting={metricSetting}
          activityRoute={activityRoute}
          showModal={showActivityDetailModal}
          onCloseModal={() => setShowActivityDetailModal(false)}
          stravaUrl={activityDetailStravaLink}
        />
      </div>
    </div>
  );
}

export default TrainingEvents;
