import { IonContent, IonPage } from "@ionic/react";
import Spinner from "../Coachee/Components/Spinner";
import {isMobileEmbeddedView, resetAuth} from "../Api/Auth";
import {useEffect} from "react";
import {signOut} from "../Api/User";

function SignOutRedirect() {
  useEffect(() => {
    async function signOutUser() {
      await signOut();
      resetAuth();
      if (!isMobileEmbeddedView()) {
        window.location.href = '/sign-in';
      }
    }
    signOutUser();
  },[]);

  return (
    <IonPage>
      <IonContent>
        <h1 className="mt-12 text-2xl text-center">
          <Spinner/>
        </h1>
      </IonContent>
    </IonPage>
  );
}

export default SignOutRedirect;
