import { trainingProfile } from "../../Api/TrainingProfile";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import {
  createTrainingEvent,
  createTrainingEventInput,
  deleteTrainingEvent,
  editTrainingEvent,
  editTrainingEventInput,
  getTrainingEvents,
  trainingEvent,
  TrainingEventStatus,
  TrainingEventType,
} from "../../Api/TrainingEvent";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

import {
  calendarAdd,
  CalendarDay,
  generateCalendarMonth,
  getTrainingEventsFromCalendarDays,
} from "../Calendar";
import {
  datesAreOnTheSameDay,
  formatCalendarDate,
  months,
} from "../../Utilities/DateUtilities";

import "react-quill/dist/quill.bubble.css";
import "../updatedBubble.css";

import SelectedDayContent from "../Components/SelectedDayContent";
import CalendarDate from "../Components/Calendar/CalendarDate";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast } from "react-toastify";
import { UserInfoContext } from "../../Context/UserContext";
import CalendarDropdown from "../Components/Calendar/CalendarDropdown";
import TrainingEventDetails from "../Components/TrainingEventDetails";
import { ActivityRoute, generateActivityMapURL } from "../../Api/ActivityRoute";
import CalendarAddEvent from "../Calendar/CalendarAddEvent";
import CalendarFilter from "../Calendar/CalendarFilter";
import CalendarMobileGrid from "../Components/Calendar/CalendarMobileGrid";
import SelectedDay from "../Components/SelectedDay";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tab } from "../Components/SelectedDayTabs";
import { useDetectClickOutside } from "react-detect-click-outside";
import { UnitPreference } from "../../Api/User";
import Summary from "../TrainingProfile/Summary/Summary";
import DaysOfTheWeek from "../Calendar/DaysOfTheWeek";
import { TemplateIcon } from "@heroicons/react/outline";
import {
  createTemplateFromTrainingEvent,
  TrainingEventTemplate,
} from "../../Api/TrainingEventTemplate";
import {
  getAllTemplates,
  useTemplateContext,
} from "../../Context/TemplateContext";
import TemplateEditor from "../Template/TemplateEditor";
import { decodeDuration, formatDistance, titleCase } from "../../Utilities/FormatUtilties";
import { ViewerContentType } from "../Template/util";
import Container from "../Template/Container";
import ListViewer from "../Template/ListViewer";
import ListContents from "../Template/ListContents";
import { Step } from "../../Api/Step";
import { useTrainingProfileOutput } from "../TrainingProfile/useTrainingProfile";

interface CalendarInput {
  selectedTrainingProfile: trainingProfile;
  trainingProfileHook: useTrainingProfileOutput;
}

export const handleSelectedEvents = (
  eventId: string,
  selectedEvents: trainingEvent[]
) =>
  selectedEvents.length > 1 &&
  selectedEvents.find((event) => event.id === eventId);

function Calendar({
  selectedTrainingProfile,
  trainingProfileHook,
}: CalendarInput) {
  const params = useParams<{
    id: string | undefined;
    workoutTab: string | undefined;
  }>();
  const location = useLocation();
  const history = useHistory();
  const ref = useDetectClickOutside({
    onTriggered: () => setSelectedDays([]),
  });

  const { userInfo } = useContext(UserInfoContext);
  const { ownTemplates, sharedTemplates, initTemplates } = useTemplateContext();

  const [templateDateToAdd, setTemplateDateToAdd] = useState<Date>();
  const [listViewerContents, setListViewerContents] =
    useState<ViewerContentType>();

  const [trainingEvents, setTrainingEvents] = useState<trainingEvent[]>([]);

  const [selectedContent, setSelectedContent] = useState<
    CalendarDay | TrainingEventTemplate
  >();
  const [selectedDayContentHasChanged, setSelectedDayContentHasChanged] =
    useState(false);

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [currentCalendarMonth, setCurrentCalendarMonth] = useState<
    CalendarDay[]
  >([]);

  const [showActivityDetailModal, setShowActivityDetailModal] = useState(false);
  const [activityDetailStravaLink, setActivityDetailStravaLink] =
    useState<string>();
  const [activityRoute, setActivityRoute] = useState<ActivityRoute | null>(
    null
  );

  const [selectedDays, setSelectedDays] = useState<CalendarDay[]>([]);
  const [hasLoadedInitialSelectedDay, setHasLoadedInitialSelectedDay] =
    useState(false);

  const [showTemplateContainer, setShowTemplateContainer] = useState(false);

  const [isOperating, setIsOperating] = useState(false);

  const selectedEvents = getTrainingEventsFromCalendarDays(selectedDays);

  // filter types of activities state
  type filterTypesProfile = {
    name: string;
    type: TrainingEventType;
    isHide: boolean;
  };

  const eventTypes = (
    Object.keys(TrainingEventType) as Array<keyof typeof TrainingEventType>
  ).map((key) => {
    return {
      name: titleCase(key.toString()).replaceAll("_", " "),
      type: key,
      isHide: false,
    };
  });

  const [filterTypes, setFilterTypes] = useState(eventTypes);
  const [shouldHide, setShouldHide] = useState<string[]>([]);

  function toggleFilterTypes(
    type?: string 
  ) {
    if (!type) {
      setShouldHide([]);
      setFilterTypes(eventTypes);
    } else {
      if (shouldHide.includes(type)){
        setShouldHide(shouldHide.filter(i => i !== type))
      } else setShouldHide([... shouldHide, type])
      let newFilter = filterTypes.map(item => {
        return item.type === type ? {... item, isHide: !item.isHide} : item
      })
      setFilterTypes(newFilter);
    };
  }

  function isCalendarDay(
    day: CalendarDay | TrainingEventTemplate
  ): day is CalendarDay {
    return (day as CalendarDay).date !== undefined;
  }

  function isTrainingEventTemplate(
    day: CalendarDay | TrainingEventTemplate
  ): day is TrainingEventTemplate {
    return (day as TrainingEventTemplate).createdBy !== undefined;
  }

  const selectedDay =
    selectedContent && isCalendarDay(selectedContent)
      ? selectedContent
      : undefined;

  const selectedTemplate =
    selectedContent && isTrainingEventTemplate(selectedContent)
      ? selectedContent
      : undefined;

  function eventInParams() {
    if (!params.id) return false;

    return (
      params.id.includes("trainingEvent") && !params.id.includes("Template")
    );
  }

  function activityInParams() {
    if (!params.id) return false;

    return (
      params.id.includes("perform:activity") &&
      !params.id.includes("Template") &&
      !params.id.includes("trainingEvent")
    );
  }

  function templateInParams() {
    if (!params.id) return false;

    return params.id.includes("trainingEventTemplate");
  }

  function handleOpenTemplate(template: TrainingEventTemplate) {
    handleSelectedTemplateChange(template);
    setShowTemplateContainer(true);
  }

  function openTemplateMenu(date?: Date) {
    if (date) {
      setTemplateDateToAdd(date);
    }

    setShowTemplateContainer(true);
    setListViewerContents(ViewerContentType.PERSONAL);
  }

  function handleSelectedDaysChange(
    day: CalendarDay,
    metaKeyIsPressed?: boolean,
    shiftKeyIsPressed?: boolean
  ) {
    if (metaKeyIsPressed) {
      setSelectedDays(selectedDays.concat([day]));
      return;
    }

    if (shiftKeyIsPressed && selectedDays.length === 1) {
      let firstIndex: number;
      let lastIndex: number;
      if (day.date.getTime() >= selectedDays[0].date.getTime()) {
        lastIndex = currentCalendarMonth.findIndex(
          (myDay) => myDay.date.getTime() === day.date.getTime()
        );
        firstIndex = currentCalendarMonth.findIndex(
          (myDay) => myDay.date.getTime() === selectedDays[0].date.getTime()
        );
      } else {
        lastIndex = currentCalendarMonth.findIndex(
          (myDay) => myDay.date.getTime() === selectedDays[0].date.getTime()
        );
        firstIndex = currentCalendarMonth.findIndex(
          (myDay) => myDay.date.getTime() === day.date.getTime()
        );
      }

      setSelectedDays(currentCalendarMonth.slice(firstIndex, lastIndex + 1));
      return;
    }

    setSelectedDays([day]);
  }

  function handleMonthChange(monthStep: number) {
    const newDate = new Date(currentYear, currentMonth + monthStep, 1);

    setCurrentMonth(newDate.getMonth());
    setCurrentYear(newDate.getFullYear());
  }

  function setCalendarToToday() {
    const now = new Date();
    setCurrentMonth(now.getMonth());
    setCurrentYear(now.getFullYear());
  }

  const initTrainingEvents = useCallback(() => {
    async function initTrainingEvents() {
      if (userInfo && selectedTrainingProfile) {
        const trainingEvents = await getTrainingEvents(
          selectedTrainingProfile.miniProfile.id,
          undefined,
          userInfo.profileMetricSetting
        );

        if (trainingEvents) {
          setTrainingEvents(trainingEvents);
        }
      }
    }

    initTrainingEvents();
  }, [selectedTrainingProfile, userInfo]);

  const initCalendarMonth = useCallback(() => {
    function initCalendarMonth() {
      if (params.id !== undefined && trainingEvents.length > 0) {
        const foundEvent = trainingEvents.find(
          (event) => event.id === params.id
        );

        if (foundEvent) {
          const foundEventInCurrentCalendarMonth = currentCalendarMonth.find(
            (day) => day.event?.id === foundEvent.id
          );

          if (foundEventInCurrentCalendarMonth) {
            return;
          }

          const month = foundEvent.scheduledAt.getMonth();
          const year = foundEvent.scheduledAt.getFullYear();

          setCurrentCalendarMonth(
            generateCalendarMonth(month, year, trainingEvents)
          );
        } else {
          setCurrentCalendarMonth(
            generateCalendarMonth(currentMonth, currentYear, trainingEvents)
          );
        }
      } else {
        setCurrentCalendarMonth(
          generateCalendarMonth(currentMonth, currentYear, trainingEvents)
        );
      }
    }
    initCalendarMonth();
  }, [currentMonth, currentYear, params.id, trainingEvents]);

  function handleSelectedDayChange(date: string) {
    const selectedDays = currentCalendarMonth.filter(
      (day) => date === day.formattedDate && day.event
    );

    if (selectedDays.length > 0 && selectedDays[0].event) {
      history.push(
        `/coach/${selectedTrainingProfile.miniProfile.id}/calendar/${selectedDays[0].event.id}`
      );
    }
  }

  function handleSelectedTemplateChange(template: TrainingEventTemplate) {
    history.push(
      `/coach/${selectedTrainingProfile.miniProfile.id}/calendar/${template.id}`
    );
  }

  function getPreviousSelectedDay(): void | null {
    if (selectedDay && currentCalendarMonth) {
      const selectedDayIndex = currentCalendarMonth.findIndex(
        (day) => day.formattedDate === selectedDay.formattedDate
      );

      for (let i = selectedDayIndex - 1; i > -1; i--) {
        if (currentCalendarMonth[i].event) {
          handleSelectedDayChange(currentCalendarMonth[i].formattedDate);
          return;
        }
      }
    }
    return null;
  }

  function getNextSelectedDay(): void | null {
    if (selectedDay && currentCalendarMonth) {
      const selectedDayIndex = currentCalendarMonth.findIndex(
        (day) => day.formattedDate === selectedDay.formattedDate
      );

      for (let i = selectedDayIndex + 1; i < currentCalendarMonth.length; i++) {
        if (currentCalendarMonth[i].event) {
          handleSelectedDayChange(currentCalendarMonth[i].formattedDate);
          return;
        }
      }
    }
    return null;
  }

  async function handleEventDrag(
    sendingEvent: trainingEvent,
    recievingDate: Date,
    occupyingEvent: trainingEvent | null
  ) {
    if (isOperating) return;

    if (userInfo && occupyingEvent) {
      const payload: editTrainingEventInput = {
        assignedDistance: occupyingEvent.assignedDistance || "",
        assignedDuration: occupyingEvent.assignedDuration || "",
        description: occupyingEvent.description,
        eventId: occupyingEvent.id,
        message: occupyingEvent.message,
        profile: occupyingEvent.profile,
        scheduledAt: sendingEvent.scheduledAt,
        status: occupyingEvent.status,
        title: occupyingEvent.title,
        trainingEventType: occupyingEvent.trainingEventType,
        unitPreference: userInfo.profileMetricSetting,
        postActivityFeedback: occupyingEvent.postActivityFeedback,
        postActivityFeedbackDidChange: false,
        steps: occupyingEvent.steps,
      };
      await editTrainingEvent(payload);
    }

    const payload: editTrainingEventInput = {
      assignedDistance: sendingEvent.assignedDistance || "",
      assignedDuration: sendingEvent.assignedDuration || "",
      description: sendingEvent.description,
      eventId: sendingEvent.id,
      message: sendingEvent.message,
      profile: sendingEvent.profile,
      scheduledAt: recievingDate,
      status: sendingEvent.status,
      title: sendingEvent.title,
      trainingEventType: sendingEvent.trainingEventType,
      unitPreference: userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL,
      postActivityFeedback: sendingEvent.postActivityFeedback,
      postActivityFeedbackDidChange: false,
      steps: sendingEvent.steps,
    };
    await editTrainingEvent(payload);

    initTrainingEvents();
  }

  async function handleDelete(id: string, isOverwrite = false) {
    if (isOperating) return;
    if (handleSelectedEvents(id, selectedEvents)) {
      setIsOperating(true);
      const confirmation = window.confirm(
        `Are you sure you want to delete these events?`
      );

      if (confirmation) {
        for (let i = 0; i < selectedEvents.length; i++) {
          const response = await deleteTrainingEvent({
            profileId: selectedTrainingProfile.miniProfile.id,
            eventId: selectedEvents[i].id,
          });

          if (!response) {
            alert("Delete operation could not be finished");
            setIsOperating(false);

            return;
          }
        }

        initTrainingEvents();
        initCalendarMonth();
        setSelectedDays([]);
        toast("Events successfully deleted");
      }

      setIsOperating(false);
      return;
    }

    const confirmation = window.confirm(
      `Are you sure you want to ${
        isOverwrite ? "overwrite" : "delete"
      } this event?`
    );

    if (confirmation) {
      const response = await deleteTrainingEvent({
        profileId: selectedTrainingProfile.miniProfile.id,
        eventId: id,
      });

      if (response) {
        initTrainingEvents();
        initCalendarMonth();
        setSelectedContent(undefined);
        toast("Event successfully deleted");
      } else {
        alert("Delete operation could not be completed");
      }
    }
  }

  async function handleTemplateAdd(
    template: TrainingEventTemplate,
    metricSetting: UnitPreference
  ) {
    const {
      type,
      title,
      description,
      assignedDistance,
      assignedDuration,
      message,
      steps,
    } = template;

    await handleAdd(
      type,
      templateDateToAdd ?? undefined,
      title,
      description.split("$"),
      formatDistance(assignedDistance, metricSetting),
      decodeDuration(assignedDuration),
      message,
      undefined,
      false,
      steps
    );

    setTemplateDateToAdd(undefined);
    setListViewerContents(undefined);
  }

  async function handleAdd(
    type?: TrainingEventType,
    scheduledAt?: Date,
    title?: string,
    description?: string[],
    assignedDistance?: string,
    assignedDuration?: string,
    message?: string,
    sportsType?: string,
    openToNewEvent: boolean = true,
    steps?: Step[]
  ) {
    if (isOperating) return;

    const response = await calendarAdd(
      selectedTrainingProfile,
      trainingEvents,
      type,
      scheduledAt,
      title,
      description,
      assignedDistance,
      assignedDuration,
      message,
      sportsType,
      steps ?? []
    );
    if (response) {
      setTrainingEvents(response.all);
      initCalendarMonth();

      if (openToNewEvent) {
        history.push(
          `/coach/${selectedTrainingProfile.miniProfile.id}/calendar/${response.new.id}`
        );
      }
    }
  }

  async function handleDuplicate(event: trainingEvent) {
    if (isOperating) return;

    const payload: createTrainingEventInput = {
      ...event,
      profile: selectedTrainingProfile.miniProfile.id,
      unitPreference: userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL,
      status: TrainingEventStatus.DRAFT,
      scheduledAt: undefined,
    };

    const response = await createTrainingEvent(payload);
    if (response) {
      const newTrainingEvent: trainingEvent = {
        ...response,
        actualDistance: response.actualDistance ?? 0,
        actualDuration: response.actualDuration ?? 0,
        isInEditMode: false,
        activity: null,
        completionRatio: null,
        postActivityFeedback: null,
        postActivityFeedbackSentAt: null,
        completionRatioFeedback: [],
        completedAt: null,
        steps: [],
      };

      initTrainingEvents();
      initCalendarMonth();
      handleSelectedDayChange(formatCalendarDate(newTrainingEvent.scheduledAt));
      toast("Event successfully duplicated");
    }
  }

  async function handleRepeat(event: trainingEvent, weeks: number) {
    if (isOperating) return;

    if (handleSelectedEvents(event.id, selectedEvents)) {
      setIsOperating(true);
      for (let i = 0; i < selectedEvents.length; i++) {
        const response = await repeatEvent(selectedEvents[i], weeks);

        if (!response) {
          toast("Repeated events could not be created successfully");
          setIsOperating(false);

          return;
        }
      }
      setSelectedDays([]);
    } else {
      const response = await repeatEvent(event, weeks);

      if (!response) {
        toast("Repeated events could not be created successfully");
        return;
      }
    }

    initTrainingEvents();
    initCalendarMonth();
    toast("Repeated events successfully created.");
    setIsOperating(false);
  }

  async function repeatEvent(event: trainingEvent, weeks: number) {
    if (!userInfo || isOperating) return;

    let potentialDates: Date[] = [];

    for (let i = 1; i <= weeks; i++) {
      const newDate = new Date(event.scheduledAt);
      newDate.setDate(newDate.getDate() + 7 * i);

      potentialDates = potentialDates.concat([newDate]);
    }

    let conflictingEventIds: string[] = [];
    for (let i = 0; i < trainingEvents.length; i++) {
      for (let j = 0; j < potentialDates.length; j++) {
        if (
          datesAreOnTheSameDay(trainingEvents[i].scheduledAt, potentialDates[j])
        ) {
          conflictingEventIds = conflictingEventIds.concat([
            trainingEvents[i].id,
          ]);
        }
      }
    }

    if (conflictingEventIds.length > 0) {
      const confirmation = window.confirm(
        "Are you sure you want to overwrite existing events?"
      );

      if (!confirmation) return;

      for (let i = 0; i < conflictingEventIds.length; i++) {
        const response = await deleteTrainingEvent({
          profileId: selectedTrainingProfile.miniProfile.id,
          eventId: conflictingEventIds[i],
        });

        if (!response) return response;
      }
    }

    for (let i = 0; i < potentialDates.length; i++) {
      const newPayload: createTrainingEventInput = {
        profile: event.profile,
        assignedDistance: event.assignedDistance,
        assignedDuration: event.assignedDuration,
        description: event.description,
        message: event.message,
        scheduledAt: potentialDates[i],
        sportsType: event.sportsType,
        status: TrainingEventStatus.DRAFT,
        title: event.title,
        trainingEventType: event.trainingEventType,
        unitPreference: userInfo?.profileMetricSetting,
        steps: event.steps,
      };

      const response = await createTrainingEvent(newPayload);
      if (!response) return response;
    }

    return true;
  }

  function getBackgroundColor(event: trainingEvent | null): string {
    if (!event) return "bg-white";

    if (event.completionRatio && event.completionRatio < 0.6) {
      return "bg-[#E9E5D2]";
    }

    switch (event.status) {
      case TrainingEventStatus.COMPLETED:
        return "bg-[#D2E9DE]";
      case TrainingEventStatus.SCHEDULED:
        return "bg-[#F2F2F2]";
      case TrainingEventStatus.DRAFT:
        return "bg-[#FFFFFF]";
      case TrainingEventStatus.MISSED:
        return "bg-[#E9D2D2]";

      default:
        return "bg-white";
    }
  }

  async function createTemplate(event: trainingEvent) {
    if (!userInfo) return;

    const name = prompt("Template name");

    if (!name) return;

    const response = await createTemplateFromTrainingEvent(
      event,
      userInfo.profileMetricSetting,
      name
    );

    if (response) {
      await initTemplates();

      history.push(
        `/coach/${selectedTrainingProfile.miniProfile.id}/calendar/${response.id}`
      );
    }
  }

  function getCurrentTab() {
    if (location.pathname.endsWith("plan")) {
      return Tab.PLAN;
    } else if (location.pathname.endsWith("results")) {
      return Tab.RESULTS;
    } else if (location.pathname.endsWith("comments")) {
      return Tab.COMMENTS;
    } else if (location.pathname.endsWith("more")) {
      return Tab.MORE;
    }

    return undefined;
  }

  // function alertUser(e: any) {
  //   if (selectedDayContentHasChanged) {
  //     let confirmation = window.confirm(
  //       `Are you sure you want to leave this page with unsaved changes?`
  //     );

  //     if (!confirmation) {
  //       e.preventDefault();
  //       e.returnValue = "";
  //     }
  //   }
  // }

  function onShowActivityRoute(
    activityRoute: ActivityRoute,
    stravaUrl?: string
  ) {
    setActivityRoute(activityRoute);
    setShowActivityDetailModal(true);

    if (stravaUrl) {
      setActivityDetailStravaLink(stravaUrl);
    }
  }

  function toggleShowTemplateContainer() {
    setShowTemplateContainer(!showTemplateContainer);
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      initCalendarMonth();
    }

    return () => {
      mounted = false;
    };
  }, [trainingEvents, currentMonth, currentYear, initCalendarMonth]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setHasLoadedInitialSelectedDay(true);
    }

    return () => {
      mounted = false;
    };
  }, [
    selectedDay,
    hasLoadedInitialSelectedDay,
    history,
    params.id,
    selectedTrainingProfile.miniProfile.id,
  ]);

  // const [startDateCalendarView, setStartDateCalendarView] = useState<string>('');
  // const [endDateCalendarView, setEndDateCalendarView] = useState<string>('');

  useEffect(() => {
    function initSelectedContent() {
      if (eventInParams()) {
        const selectedContent = currentCalendarMonth.find(
          (day) => day.event?.id === params.id
        );

        if (selectedContent) {
          setSelectedContent(selectedContent);
        }
      } else if (templateInParams()) {
        const allTemplates = getAllTemplates(ownTemplates, sharedTemplates);

        const selectedTemplate = allTemplates.find(
          (template) => template.id === params.id
        );

        if (selectedTemplate) {
          setSelectedContent(selectedTemplate);
        }
      } else if (activityInParams()) {
        const selectedActivity = currentCalendarMonth.find(
          (day) => day.event?.id === params.id
        );

        if (selectedActivity) {
          setSelectedContent(selectedActivity);
        }
      }
    }

    let mounted = true;

    if (mounted && currentCalendarMonth.length > 0) {
      initSelectedContent();
    }

    // if (currentCalendarMonth.length) {
    //   setStartDateCalendarView(`${
    //       months[currentCalendarMonth[0].date.getMonth()]
    //   }, ${currentCalendarMonth[0].date.getDate()}`);
    //   setEndDateCalendarView(`${
    //       months[
    //           currentCalendarMonth[
    //           currentCalendarMonth.length - 1
    //               ].date.getMonth()
    //           ]
    //   }, ${currentCalendarMonth[
    //   currentCalendarMonth.length - 1
    //       ].date.getDate()}`);
    // }

    return () => {
      mounted = false;
    };
  }, [
    currentCalendarMonth,
    location.pathname,
    params.id,
    ownTemplates,
    sharedTemplates,
  ]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      initTrainingEvents();
    }

    return () => {
      mounted = false;
    };
  }, [selectedTrainingProfile, initTrainingEvents]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      generateActivityMapURL(activityRoute?.points);
    }

    return () => {
      mounted = false;
    };
  }, [activityRoute]);

  useEffect(() => {
    if (!listViewerContents) {
      setTemplateDateToAdd(undefined);
    }
  }, [listViewerContents]);

  let weeklyTotal = 0;
  let weeklyAssignedTotal = 0;

  return (
    <>
      <div className="flex justify-between h-full w-full gap-2">
        <div className="grow relative h-full">
          <div
            onClick={() => {
              setListViewerContents(undefined);
            }}
            className="py-10"
          >
            <Summary
              profile={selectedTrainingProfile}
              trainingProfileHook={trainingProfileHook}
            />
            {selectedTrainingProfile && currentCalendarMonth && (
              <div id="calendar" ref={ref} className="font-poppins">
                <div className="lg:flex lg:h-full lg:flex-col">
                  <header className="relative z-20 flex items-center justify-between pb-5 border-b border-gray-200 lg:flex-none">
                    <div className="flex items-center gap-2">
                      <div className="flex items-center rounded-md md:items-stretch">
                        <button
                          onClick={() => handleMonthChange(-1)}
                          type="button"
                          className="flex items-center justify-center text-gray-400 rounded-l-md hover:text-gray-500 focus:relative md:hover:bg-gray-50"
                        >
                          <span className="sr-only">Previous month</span>
                          <ChevronLeftIcon
                            className="w-8 h-8"
                            aria-hidden="true"
                          />
                        </button>
                        <button
                          onClick={() => setCalendarToToday()}
                          type="button"
                          className="px-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
                        >
                          <h1 className="text-xl md:text-[28px] font-bold text-gray-900">
                            <time
                              dateTime={`${currentYear}-${currentMonth + 1}`}
                            >
                              {months[currentMonth]} {currentYear}
                            </time>
                          </h1>
                        </button>
                        <button
                          onClick={() => handleMonthChange(1)}
                          type="button"
                          className="flex items-center justify-center text-gray-400 rounded-r-md hover:text-gray-500 focus:relative md:hover:bg-gray-50"
                        >
                          <span className="sr-only">Next month</span>
                          <ChevronRightIcon
                            className="w-8 h-8"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                      {isOperating && (
                        <div
                          className="inline-block w-4 h-4 rounded-full text-brand spinner-border animate-spin border-1"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </div>

                    <div className="flex items-center">
                      <div className="hidden md:ml-4 md:flex md:items-center">
                        <CalendarFilter
                          toggleFilterTypes={toggleFilterTypes}
                          filterTypes={filterTypes}
                        />
                        <CalendarAddEvent
                          handleAdd={(type) => handleAdd(type)}
                        />
                        <div className="w-px h-6 ml-6 bg-gray-300" />
                        <div className="flex items-center ml-6">
                          <button
                            onClick={() => toggleShowTemplateContainer()}
                            className="px-4 py-2 border rounded-md border-neutral-400 bg-white"
                          >
                            <TemplateIcon className="w-5 h-5 text-neutral-400" />
                          </button>
                        </div>
                      </div>
                      <CalendarDropdown
                        setCalendarToToday={setCalendarToToday}
                        handleSelectedDayChange={handleSelectedDayChange}
                        initCalendarMonth={initCalendarMonth}
                        selectedTrainingProfile={selectedTrainingProfile}
                        setTrainingEvents={setTrainingEvents}
                        trainingEvents={trainingEvents}
                      />
                    </div>
                  </header>
                  <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                    <DaysOfTheWeek />
                    <div className="flex text-xs leading-6 text-gray-700 bg-gray-200 lg:flex-auto">
                      <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-5 lg:gap-px">
                        <DndProvider backend={HTML5Backend}>
                          {/*{console.log(currentCalendarMonth)}*/}
                          {currentCalendarMonth.map((day, index) => {
                            if (index % 7 === 0 || index === 0) {
                              weeklyTotal = 0;
                              weeklyAssignedTotal = 0;
                            }
                            // @ts-ignorex
                            if ([TrainingEventType.BASE, TrainingEventType.SPEED, TrainingEventType.LONG, TrainingEventType.RACE].includes(day.event?.trainingEventType)) {
                              weeklyTotal += Number(day.event?.actualDistance || 0);
                              weeklyAssignedTotal += Number(day.event?.assignedDistance || 0);
                            }
                            let hideDay
                            if (day?.event?.trainingEventType) {
                              // @ts-ignorex
                              hideDay = shouldHide.includes(day?.event?.trainingEventType) || (shouldHide.includes('UNPLANNED') && day?.event?.trainingEventType === 'CHALLENGE_DAY')
                            }
                            return (
                                <Fragment key={index}>
                                  <CalendarDate
                                      day={day}
                                      handleSelectedDayChange={handleSelectedDayChange}
                                      metricSetting={
                                          userInfo?.profileMetricSetting ??
                                          UnitPreference.IMPERIAL
                                      }
                                      selectedDay={selectedDay}
                                      handleEventDrag={handleEventDrag}
                                      handleDelete={handleDelete}
                                      handleAdd={handleAdd}
                                      handleDuplicate={handleDuplicate}
                                      handleRepeat={handleRepeat}
                                      selectedDays={selectedDays}
                                      handleSelectedDaysChange={
                                        handleSelectedDaysChange
                                      }
                                      initTrainingEvents={initTrainingEvents}
                                      setIsOperating={setIsOperating}
                                      selectedTrainingProfile={selectedTrainingProfile}
                                      openTemplateMenu={openTemplateMenu}
                                      createTemplate={createTemplate}
                                      hideDay={hideDay}
                                  />

                                  {index % 7 === 6 && (
                                      <div className="bg-[#FFFFFF] outline-gray-200 relative py-4 px-3 outline outline-1 flex flex-col h-40 col-start-8 w-20" key={`divider-${index}`}>
                                        {weeklyAssignedTotal.toFixed(2)} mi / {formatDistance(weeklyTotal, UnitPreference.IMPERIAL, false) || 0} mi
                                      </div>
                                  )}
                                </Fragment>
                            )
                          })}
                        </DndProvider>
                      </div>

                      <CalendarMobileGrid
                        currentCalendarMonth={currentCalendarMonth}
                        getBackgroundColor={getBackgroundColor}
                        handleSelectedDayChange={handleSelectedDayChange}
                        selectedDay={selectedDay}
                        shouldHide={shouldHide}
                      />
                    </div>
                  </div>
                  {userInfo && (
                    <>
                      <SelectedDay
                        selectedDay={selectedDay}
                        setSelectedContent={setSelectedContent}
                        selectedDayContentHasChanged={
                          selectedDayContentHasChanged
                        }
                        showModal={showActivityDetailModal}
                      >
                        <SelectedDayContent
                          getNextSelectedDay={getNextSelectedDay}
                          getPreviousSelectedDay={getPreviousSelectedDay}
                          initCalendarMonth={initCalendarMonth}
                          initTrainingEvents={initTrainingEvents}
                          metricSetting={userInfo.profileMetricSetting}
                          selectedDay={selectedDay}
                          selectedTrainingProfile={selectedTrainingProfile}
                          setSelectedContent={setSelectedContent}
                          handleDuplicate={handleDuplicate}
                          handleDelete={handleDelete}
                          setSelectedDayContentHasChanged={
                            setSelectedDayContentHasChanged
                          }
                          onShowActivityRoute={onShowActivityRoute}
                          trainingEvents={trainingEvents}
                          currentTab={getCurrentTab()}
                          createTemplate={createTemplate}
                        />
                      </SelectedDay>
                      <TrainingEventDetails
                        event={
                          selectedDay && selectedDay.event
                            ? selectedDay.event
                            : undefined
                        }
                        metricSetting={userInfo.profileMetricSetting}
                        activityRoute={activityRoute}
                        showModal={showActivityDetailModal}
                        onCloseModal={() => setShowActivityDetailModal(false)}
                        stravaUrl={activityDetailStravaLink}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {listViewerContents !== undefined && (
            <div className="absolute top-0 bottom-0 h-full -right-2 bg-white border-x z-30 w-2/3">
              <div className="sticky-top h-full">
                <ListViewer>
                  <ListContents
                    content={listViewerContents}
                    handleOpenTemplate={handleOpenTemplate}
                    handleTemplateAdd={handleTemplateAdd}
                    setListViewerOptions={setListViewerContents}
                  />
                </ListViewer>
              </div>
            </div>
          )}
        </div>
        <>
          {showTemplateContainer && (
            <Container
              handleOpenTemplate={handleOpenTemplate}
              setListViewerContents={setListViewerContents}
            />
          )}
        </>
        <TemplateEditor
          template={selectedTemplate}
          setSelectedContent={setSelectedContent}
          profile={selectedTrainingProfile}
        />
      </div>
    </>
  );
}

export default Calendar;
