import { Comment } from "../../../../Api/Stream";
import { ReactionAPIResponse, StreamClient } from "getstream";
import useActivityFeedbackComment from "../../../Hooks/useActivityFeedbackComment";

import Header from "./Header";
import Body from "./Body";
import Date from "./Date";
import { useActivityFeedbackOutput } from "../../../Hooks/useActivityFeedback";
import { EmojiAPIResponse } from "../ActivityFeedback";
import { useUserInfo } from "../../../../Context/UserContext";
import { toast } from "react-toastify";

interface ActivityFeedbackCommentInput {
  comment: Comment;
  streamClient?: StreamClient;
  isAthlete: boolean;
  activityFeedback: useActivityFeedbackOutput;
  children?: EmojiAPIResponse[];
  addEmoji: (
    comment: ReactionAPIResponse,
    emoji: string
  ) => Promise<any | false>;
}

function ActivityFeedbackComment({
  comment,
  streamClient,
  isAthlete,
  activityFeedback,
  children,
  addEmoji,
}: ActivityFeedbackCommentInput) {
  const {
    editMode,
    enableEditMode,
    saveComment,
    cancelEdit,
    commentContent,
    setCommentContent,
  } = useActivityFeedbackComment({
    comment: comment,
    streamClient: streamClient,
  });

  const { userInfo } = useUserInfo();

  function handleAddEmoji(emoji: string) {
    if (!userInfo || !streamClient) {
      return;
    }

    const response = addEmoji(comment, emoji);

    response
      .then(() => {
        activityFeedback.initComments();
      })
      .catch(() => {
        toast.error("Failed to add emoji");
      });
  }

  return (
    <>
      {streamClient && (
        <>
          <Header
            actorMiniProfile={comment.data.actorMiniProfile}
            isAthlete={isAthlete}
            activityFeedback={activityFeedback}
            comment={comment}
            enableEditMode={enableEditMode}
          />
          <Body
            commentContent={commentContent}
            cancelEdit={cancelEdit}
            editMode={editMode}
            saveComment={saveComment}
            setCommentContent={setCommentContent}
            comment={comment}
            children={children}
            handleAddEmoji={handleAddEmoji}
            streamClient={streamClient}
            initComments={activityFeedback.initComments}
          />
          <Date date={comment.created_at} />
        </>
      )}
    </>
  );
}

export default ActivityFeedbackComment;
