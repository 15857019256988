/* eslint-disable @typescript-eslint/ban-types */

import { classNames } from "../../../Utilities/classnames";

interface ItemContainerInput {
  children: JSX.Element;
  key: number;
  isEnabled: boolean;
  onMouseEnter: Function;
  onMouseLeave: Function;
  onClick: Function;
}

function ItemContainer({
  children,
  key,
  isEnabled,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: ItemContainerInput) {
  return (
    <div key={key}>
      <div>
        {isEnabled && (
          <div
            onMouseEnter={() => onMouseEnter()}
            onMouseLeave={() => onMouseLeave()}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick();
            }}
            className={classNames(
              "hover:bg-gray-100 hover:text-gray-900 text-gray-700 flex items-center justify-between pl-4 pr-2 py-2 text-sm cursor-pointer relative"
            )}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemContainer;
