import { useState } from "react";
import DetectableOverflow from "react-detectable-overflow";
import { trainingEvent } from "../../Api/TrainingEvent";
import * as DOMPurify from "dompurify";

interface NoteCellInput {
  currentMessage: string;
  event: trainingEvent;
}

function NoteCell({ currentMessage, event }: NoteCellInput) {
  const [noteIsHovered, setNoteIsHovered] = useState<boolean>(false);
  const [noteShowMore, setNoteShowMore] = useState(false);

  function handleNoteMouseOver() {
    setNoteIsHovered(true);
  }

  function handleNoteMouseOut() {
    setNoteIsHovered(false);
  }

  function handleNoteOverFlow() {
    if (!noteShowMore) setNoteShowMore(true);
  }

  function createMarkup() {
    const sanitizedMessage = DOMPurify.sanitize(currentMessage);

    return { __html: sanitizedMessage };
  }

  return (
    <td
      onMouseOver={() => handleNoteMouseOver()}
      onMouseOut={() => handleNoteMouseOut()}
      className={
        noteShowMore && noteIsHovered
          ? "relative max-w-[170px] px-4 py-2 text-sm text-left text-gray-600"
          : "w-10 max-w-[170px] px-4 py-2 text-sm text-left text-gray-600"
      }
    >
      {!event.id.includes("activity") && (
        <>
          <DetectableOverflow
            onChange={() => handleNoteOverFlow()}
            style={{}}
            className="truncate"
          >
            <p dangerouslySetInnerHTML={createMarkup()}></p>
          </DetectableOverflow>
          <div
            className={noteShowMore && noteIsHovered ? "card-hover" : "hidden"}
          >
            <p dangerouslySetInnerHTML={createMarkup()}></p>
          </div>
        </>
      )}
    </td>
  );
}

export default NoteCell;
