import React, { useEffect } from "react";
import AndroidBadge from "../../../Assets/Footer/google-play-badge.svg";
import AppleBadge from "../../../Assets/Footer/apple-store-badge.svg";
import { useIonRouter } from "@ionic/react";
import { useLocalStorage } from "usehooks-ts";

function DefaultWebFooter() {
  const routerHook = useIonRouter();

  const [isAppMobileFooter, setIsAppMobileFooter] = useLocalStorage(
    "isMobileFooter",
    false
  );

  useEffect(() => {
    if (
      routerHook.routeInfo.search.includes("app=mobile") ||
      routerHook.routeInfo.search.includes(encodeURIComponent("app=mobile"))
    ) {
      setIsAppMobileFooter(true);
    }
  }, []);

  return !routerHook.routeInfo.search.includes("app=mobile") &&
    !routerHook.routeInfo.search.includes(encodeURIComponent("app=mobile")) &&
    !isAppMobileFooter ? (
    <footer className="text-[14px] font-normal leading-5 px-0">
      <hr className="text-gray-600 hidden md:block" />
      <div className="flex flex-wrap gap-y-4 text-[#0D0D0D] justify-between items-baseline md:px-24 md:py-10">
        <ul className="flex text-[#0D0D0D] justify-start">
          <li className="whitespace-nowrap m-0 hidden md:block">
            <a href="https://runzy.com/privacy/">Privacy Policy</a>
          </li>
          <li className="whitespace-nowrap m-0 ml-[60px] hidden md:block">
            <a href="https://runzy.com/athlete-waiver/">Athlete Waiver</a>
          </li>
          <li className="whitespace-nowrap m-0 ml-[60px] hidden md:block">
            <a href="https://runzy.com/terms-of-service/">Terms of Service</a>
          </li>
          <li className="whitespace-nowrap m-0 ml-[60px] hidden md:block">
            <a href="https://runzy.com/contact-us/">Contact Us</a>
          </li>
        </ul>
        <div className="flex flex-row items-center gap-x-6 hidden md:flex">
          <p className="text-[#000]">Find and train your best race:</p>
          <a
            href="https://play.google.com/store/apps/details?id=com.runzy.runzycoaching"
            target="_blank"
            rel="noreferrer"
          >
            <img src={AndroidBadge} alt="android market logo" />
          </a>
          <a
            href="https://apps.apple.com/us/app/runzy/id6448893640"
            target="_blank"
            rel="noreferrer"
          >
            <img src={AppleBadge} alt="apple store logo" />
          </a>
        </div>
      </div>
      <hr className="text-gray-600" />
      <div className="py-5 md:py-10">
        <div className="flex flex-col items-center gap-y-4 mb-5 md:hidden">
          <p>Find and train your best race:</p>
          <div className="flex flex-row gap-x-4">
            <a
              href="https://play.google.com/store/apps/details?id=com.runzy.runzycoaching"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-30 h-10"
                src={AndroidBadge}
                alt="android market logo"
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/runzy/id6448893640"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-30 h-10"
                src={AppleBadge}
                alt="apple store logo"
              />
            </a>
          </div>
        </div>
        <div className="m-0 mr-0 text-gray-400 text-center md:text-left md:px-24">
          ©2024 Runzy, Inc. All rights reserved.
        </div>
      </div>
    </footer>
  ) : null;
}
export default DefaultWebFooter;
