import { XIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import {
  deleteTrainingEventTemplate,
  TrainingEventTemplate,
} from "../../Api/TrainingEventTemplate";
import { UnitPreference } from "../../Api/User";
import { TemplateContext } from "../../Context/TemplateContext";
import { useUserInfo } from "../../Context/UserContext";
import { classNames } from "../../Utilities/classnames";
import { decodeDuration, formatDistance } from "../../Utilities/FormatUtilties";
import ActivityDropdown from "../Components/ActivityDropdown";
import WorkoutTypeTag from "../Components/WorkoutTypeTag";
import { ViewerContentType } from "./util";

enum ListContentTab {
  WORKOUTS = "workouts",
  WORKOUT_GROUPS = "workout groups",
}

export default function ListContents({
  content,
  handleOpenTemplate,
  handleTemplateAdd,
  setListViewerOptions,
}: {
  content: ViewerContentType | undefined;
  handleOpenTemplate: (template: TrainingEventTemplate) => void;
  handleTemplateAdd: (
    template: TrainingEventTemplate,
    metricSetting: UnitPreference
  ) => Promise<void>;
  setListViewerOptions: React.Dispatch<
    React.SetStateAction<ViewerContentType | undefined>
  >;
}) {
  const { initTemplates, ownTemplates, sharedTemplates } =
    useContext(TemplateContext);
  const { userInfo } = useUserInfo();

  if (content === undefined) return null;

  function getStringValue(tab: ListContentTab) {
    switch (tab) {
      case ListContentTab.WORKOUTS:
        return "Workouts";
      case ListContentTab.WORKOUT_GROUPS:
        return "Workout Groups";
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [tab, setTab] = useState<ListContentTab>(ListContentTab.WORKOUTS);

  function Title() {
    return (
      <div className="flex items-center justify-between px-4">
        <h1 className="font-semibold text-[#9CA3AF] text-[12px] tracking-[1px]">
          {content === ViewerContentType.PERSONAL ? "PERSONAL" : "SHARED"}
        </h1>
        <button
          onClick={() => {
            setListViewerOptions(undefined);
          }}
        >
          <XIcon className="h-5 w- 5 text-[#9CA3AF]" />
        </button>
      </div>
    );
  }

  function Tab({
    title,
    handleClick,
  }: {
    title: string;
    handleClick: () => void;
  }) {
    function tabIsSelected() {
      const stringValue = getStringValue(tab);

      // lower case and remove spaces from both title and stringValue
      const titleLowerCase = title.toLowerCase().replace(/\s/g, "");
      const stringValueLowerCase = stringValue.toLowerCase().replace(/\s/g, "");

      return titleLowerCase === stringValueLowerCase;
    }

    return (
      <button
        onClick={handleClick}
        className={classNames(
          tabIsSelected()
            ? "text-primary-500 font-medium bg-primary-50"
            : "text-neutral-700 font-normal bg-white",
          "p-2 text-[14px] rounded-[4px]"
        )}
      >
        {title}
      </button>
    );
  }

  function Tabs() {
    return null;

    return (
      <div className="flex items-center mt-2 gap-[2px]">
        <Tab
          title="Workouts"
          handleClick={() => {
            setTab(ListContentTab.WORKOUTS);
          }}
        />
        {/* <Tab
          title="Workout Groups"
          handleClick={() => {
            setTab(ListContentTab.WORKOUT_GROUPS);
          }}
        /> */}
      </div>
    );
  }

  function ContentSwitch() {
    switch (tab) {
      case ListContentTab.WORKOUTS:
        return <Workouts />;
      case ListContentTab.WORKOUT_GROUPS:
        return <WorkoutGroups />;
    }
  }

  function WorkoutsEmptyState() {
    return (
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-neutral-700 mt-4 text-[14px]">
          You have no templates here.
        </h1>
      </div>
    );
  }

  function Workouts() {
    const data =
      content === ViewerContentType.PERSONAL ? ownTemplates : sharedTemplates;

    if (data === null || data.length === 0) return <WorkoutsEmptyState />;

    const templatesV2 = data.filter(
      (template) =>
        template.description.replace(/(<([^>]+)>)/gi, "").length === 0
    );
    const templatesV1 = data.filter(
      (template) =>
        template.description.replace(/(<([^>]+)>)/gi, "").length !== 0
    );

    const V1Header = () => {
      if (templatesV1.length === 0) return null;

      return (
        <div className="sticky top-0 z-50 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
          <h3>Workouts V1</h3>
        </div>
      );
    };

    const V2Header = () => {
      if (templatesV2.length === 0) return null;

      return (
        <div className="sticky top-0 z-50 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
          <h3>Workouts V2</h3>
        </div>
      );
    };

    return (
      <div className="w-full pt-2 divide-y divide-neutral-300 relative h-full">
        <>
          <V2Header />
          {templatesV2.map((workout, index) => (
            <Workout key={index} template={workout} />
          ))}
          <V1Header />
          {templatesV1.map((workout, index) => (
            <Workout key={index} template={workout} />
          ))}
        </>
      </div>
    );
  }

  function Workout({ template }: { template: TrainingEventTemplate }) {
    function TypeColumn() {
      return <WorkoutTypeTag type={template.type} />;
    }

    function DistanceColumn() {
      const formattedDistance = formatDistance(
        template.assignedDistance,
        userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL,
        true,
        false,
        true,
        1
      );

      // replace "miles" with "mi" for imperial
      const formattedDistanceImperial = formattedDistance.replace(
        "miles",
        "mi"
      );

      // remove '.0' from string
      const formattedDistanceNoDecimal = formattedDistanceImperial.replace(
        ".0",
        ""
      );

      // if string is empty, return "-"
      if (formattedDistanceNoDecimal === "") {
        return <div className="text-neutral-600 text-[14px] ">-</div>;
      }

      return (
        <p className="text-neutral-600 text-[14px] ">
          {formattedDistanceNoDecimal}
        </p>
      );
    }

    function DurationColumn() {
      const formattedDuration = decodeDuration(template.assignedDuration);

      // if string is empty, return "-"
      if (formattedDuration === "0:00") {
        return <div className="text-neutral-600 text-[14px] ">-</div>;
      }

      return (
        <p className="text-neutral-600 text-[14px] ">{formattedDuration}</p>
      );
    }

    async function deleteWorkout(template: TrainingEventTemplate) {
      const response = await deleteTrainingEventTemplate(template.id);

      if (response) {
        initTemplates();
      }
    }

    async function createEvent() {
      if (!userInfo) return;

      await handleTemplateAdd(template, userInfo.profileMetricSetting);
    }

    function ActionContainer() {
      return (
        <div className="flex items-center gap-2 shrink-0">
          <button
            onClick={() => createEvent()}
            className="font-medium text-left text-[14px] text-primary-500 hover:text-primary-600"
          >
            Insert
          </button>
          <ActivityDropdown
            actions={[
              {
                key: "Delete",
                action: () => deleteWorkout(template),
              },
            ]}
          />
        </div>
      );
    }

    return (
      <div className="flex items-center justify-between gap-2 px-4 py-3">
        <div className="text-left truncate grow ">
          <div className="grid grid-cols-5 gap-2 items-center">
            <div className="col-span-2">
              <div
                className="cursor-pointer"
                onClick={() => handleOpenTemplate(template)}
              >
                <p className="text-neutral-900 text-[14px] font-medium whitespace-nowrap truncate">
                  {template.name}
                </p>
              </div>
              <p className="text-neutral-600 text-[12px] ">
                {template.createdByMiniProfile.name}
              </p>
            </div>
            <div className="flex items-center justify-center">
              <TypeColumn />
            </div>
            <div className="flex items-center justify-center">
              <DistanceColumn />
            </div>
            <div className="flex items-center justify-center">
              <DurationColumn />
            </div>
          </div>
        </div>

        <ActionContainer />
      </div>
    );
  }

  function WorkoutGroups() {
    return null;
  }

  return (
    <div className="pt-9 h-full">
      <Title />
      <Tabs />
      <ContentSwitch />
    </div>
  );
}
