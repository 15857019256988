import { activityTypeLocalization } from "../../../../../Api/Activity";
import {
  ActivityRoute,
  ActivitySource,
} from "../../../../../Api/ActivityRoute";
import { UnitPreference } from "../../../../../Api/User";
import { useUserInfo } from "../../../../../Context/UserContext";
import {
  decodeDateFromSecondsFormat,
  formatActivityStreamDate,
} from "../../../../../Utilities/DateUtilities";
import {
  fancyDecodeDuration,
  formatDistance,
  formatNetElevation,
  formatPace,
} from "../../../../../Utilities/FormatUtilties";
import Splits from "../../Stats/Splits";
import SyncedFromStrava from "../SyncedFromStrava";

function Details({ activity }: { activity: ActivityRoute }) {
  const { userInfo } = useUserInfo();
  const data = [
    {
      name: "DATE",
      content: formatActivityStreamDate(
        decodeDateFromSecondsFormat(activity.activity.startedAt)
      ),
    },
    { name: "TITLE", content: activity.activity.userTitle },

    {
      name: "TYPE",
      content: activityTypeLocalization.find(
        (type) => type.type === activity.activity.activityType
      )?.name,
    },
    {
      name: "DISTANCE",
      content:
        activity.activity.distanceInMeters &&
        formatDistance(
          activity.activity.distanceInMeters,
          userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL,
          true,
          false,
          true,
          2
        ),
    },
    {
      name: "DURATION",
      content: fancyDecodeDuration(activity.activity.lengthSeconds),
    },
    {
      name: "PACE",
      content:
        activity.activity.distanceInMeters &&
        formatPace(
          activity.activity.distanceInMeters,
          activity.activity.movingTime
        ),
    },
    {
      name: "ELEVATION GAIN",
      content: formatNetElevation(activity.activity.elevationGain, activity.points),
    },
    {
      name: "HEART RATE",
      content: activity.activity.averageHeartRate
        ? Math.round(activity.activity.averageHeartRate).toString()
        : undefined,
    },
    { name: "DESCRIPTION", content: activity.activity.description },
  ];

  return (
    <div className="pt-4">
      {activity.activity.geoMapImage && (
        <img
          className="w-full h-auto my-4 rounded-xl"
          src={activity.activity.geoMapImage}
          onError={(event) =>
            ((event.target as HTMLImageElement).style.display = "none")
          }
          alt="route preview"
        />
      )}
      <div className="rounded-[10px] p-4 border border-neutral-200 bg-white">
        <div className="flex items-center justify-between px-4">
          <h1>Details</h1>
          {activity.activity.activitySource === ActivitySource.STRAVA && (
            <SyncedFromStrava />
          )}{" "}
        </div>
        <div className="mt-4 divide-y divide-neutral-200">
          {data
            .filter((datum) => datum.content)
            .map((datum, index) => (
              <div key={index} className="p-4">
                <h3 className="font-semibold text-[12px] text-neutral-500 tracking-[1px]">
                  {datum.name}
                </h3>
                {typeof datum.content === "string" ? (
                  <>
                    {" "}
                    <p className="text-neutral-700 text-[14px] whitespace-pre-line	">
                      {datum.content}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="text-neutral-700 text-[14px]">
                      {datum.content}
                    </p>
                  </>
                )}
                {/* <p className="text-neutral-700 text-[14px]">{datum.content}</p> */}
              </div>
            ))}
        </div>
      </div>
      {activity.splits.length > 0 && (
        <div className="rounded-[10px] mt-4 bg-white">
          <Splits currentActivityRoute={activity} />
        </div>
      )}
    </div>
  );
}

export default Details;
