import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  getPaymentUrlForProduct,
  ProductType,
  refreshStripeSubscriptionForEmail,
} from "../Api/Payment";
import { track, TrackingEventType } from "../Api/Tracking";
import ReactGA from "react-ga";
import { IonContent, IonPage } from "@ionic/react";

function CheckoutFailure() {
  const history = useHistory();
  const { productName, email } = useParams<{
    productName: string;
    email: string;
  }>();

  useEffect(() => {
    track(
      TrackingEventType.TRACKING_APP_WEB_ONBOARDING,
      email + " exited Stripe payment flow"
    );
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div className="flex items-center justify-center h-screen">
          <div role="alert">
            <div className="px-4 py-2 font-bold text-white bg-red-500 rounded-t">
              Payment cancelled
            </div>
            <div className="px-4 py-3 text-red-700 bg-red-100 border border-t-0 border-red-400 rounded-b">
              <p>To return to payment, click the original payment link.</p>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default CheckoutFailure;
