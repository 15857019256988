import { Switch } from "@headlessui/react";
import { useContext } from "react";
import { isSuperAdmin } from "../../Api/User";
import { UserInfoContext } from "../../Context/UserContext";
import SmallToggle from "./SmallToggle";

interface CoachHeaderSwitchInput {
  showAllAthletes: boolean;
  setShowAllAthletes: Function;
}

function CoachHeaderSwitch({
  showAllAthletes,
  setShowAllAthletes,
}: CoachHeaderSwitchInput) {
  const { userInfo } = useContext(UserInfoContext);

  return (
    <>
      {userInfo && isSuperAdmin(userInfo) && (
        <SmallToggle
          isEnabled={showAllAthletes}
          setIsEnabled={setShowAllAthletes}
        />
      )}
    </>
  );
}

export default CoachHeaderSwitch;
