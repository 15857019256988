/* eslint-disable */

import { round } from "lodash";
import { toast } from "react-toastify";
import { markBasicUserAsFixed } from "../../Api/TrainingAttribute";
import { trainingProfile } from "../../Api/TrainingProfile";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";
import { useAthletesOutput } from "../Hooks/useAthletes";

function AthleteRow({
  profile,
  athletesHook,
}: {
  profile: trainingProfile;
  showAllAthletes: boolean;
  athletesHook: useAthletesOutput;
}) {
  return (
    <tr>
      {/* athlete */}
      <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6 ">
        <a id="athleteLink" href={`/coach/${profile.miniProfile.id}/calendar`}>
          <div className="flex items-center cursor-pointer">
            <div className="flex-shrink-0 w-10 h-10 cursor-pointer">
              <ProfilePicture
                url={profile.miniProfile.profilePicture}
                alt=""
                classNames="w-10 h-10 rounded-full"
              />
            </div>
            <a className="ml-4 cursor-pointer">
              <div className="font-medium text-gray-900 hover:text-brand hover:underline">
                {profile.miniProfile.name.length <= 1
                  ? profile.trainingAttribute.emailAddress
                  : profile.miniProfile.name}{" "}
                &nbsp;(
                {profile.miniProfile.numericId})
              </div>
              <div className="text-gray-500">
                {profile.trainingAttribute.productName ??
                  profile.productDescription}
              </div>
            </a>
          </div>
        </a>
      </td>
      {/* rating */}
      <td className="px-3 py-4 overflow-visible text-sm text-center">
        <a href={`/coach/${profile.miniProfile.id}/calendar`}>
          {athletesHook.getRatingIcon(profile)}
        </a>
      </td>
      {/* workouts scheduled */}
      <td className="px-3 py-4 text-sm text-center whitespace-nowrap">
        <a
          href={`/coach/${profile.miniProfile.id}/calendar`}
          className="hover:underline"
        >
          {(profile.scheduledConsecutiveTrainingEvents ?? 0) >= 14 ? (
            <>14+</>
          ) : (
            profile.scheduledConsecutiveTrainingEvents ?? 0
          )}
        </a>
      </td>
      {/* completion score */}
      <td className="px-3 py-4 text-sm text-center whitespace-nowrap">
        <a
          href={`/coach/${profile.miniProfile.id}/calendar`}
          className="hover:underline"
        >
          {round(profile.stats.completionScore * 100)}%
        </a>
      </td>
      {/* alerts */}
      <td className="px-3 py-4 text-sm text-center whitespace-nowrap">
        <a
          href={`/coach/${profile.miniProfile.id}/notifications`}
          className="hover:underline"
        >
          {profile.unreadNotificationsForAthlete > 0 ? (
              <span className="inline-flex items-center px-2.5 py-2.5 rounded-full text-[14px] border border-[#D1D5DB] font-medium bg-brand text-white">
              {profile.unreadNotificationsForAthlete}
            </span>
          ) : (
            <></>
          )}
        </a>
      </td>
      {/* details */}
      {/* <td className="px-3 py-4 text-sm text-center whitespace-nowrap">
        <button className="font-medium text-[14px] text-[#F6747E]">
          Details
        </button>
      </td> */}
      {/* mark as complete */}
      {location.pathname.replaceAll("/", "").endsWith("basic") && (
        <td className="px-3 py-4 text-sm text-center whitespace-nowrap">
          <button
            onClick={async () => {
              if (
                window.confirm(
                  "Are you sure you want to mark this basic athlete as complete?"
                )
              ) {
                const response = await markBasicUserAsFixed(
                  profile.miniProfile.id
                );
                if (response) {
                  toast(
                    `${profile.miniProfile.name} has been marked as complete.`
                  );
                  athletesHook.reInitAthleteStates();
                }
              }
            }}
            className="px-2 py-1 font-medium text-white border rounded-md bg-brand border-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
          >
            Submit
          </button>
        </td>
      )}

      {location.pathname.replaceAll("/", "").endsWith("reviewed") && (
        <td className="px-3 py-4 text-sm text-center whitespace-nowrap">
          <button
            onClick={async () => {
              if (
                window.confirm(
                  "Are you sure you want to revert this basic athlete back to incomplete?"
                )
              ) {
                const response = await markBasicUserAsFixed(
                  profile.miniProfile.id,
                  true
                );
                if (response) {
                  toast(
                    `${profile.miniProfile.name} has been marked as incomplete.`
                  );
                  athletesHook.reInitAthleteStates();
                }
              }
            }}
            className="px-2 py-1 font-medium bg-white border rounded-md text-brand border-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
          >
            Revert
          </button>
        </td>
      )}
    </tr>
  );
}

export default AthleteRow;
