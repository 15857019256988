import { UnitPreference } from "../../Api/User";
import { ActivityRoute } from "../../Api/ActivityRoute";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import "react-day-picker/lib/style.css";
import "react-quill/dist/quill.bubble.css";

import EventDetailContents from "./EventDetailContents";
import { trainingEvent } from "../../Api/TrainingEvent";
interface TrainingEventDetailsProps {
  event?: trainingEvent;
  metricSetting: UnitPreference;
  activityRoute: ActivityRoute | null;
  stravaUrl?: string;
  showModal: boolean;
  onCloseModal: Function;
}

function TrainingEventDetails({
  event,
  metricSetting,
  activityRoute,
  stravaUrl,
  showModal,
  onCloseModal,
}: TrainingEventDetailsProps) {
  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => onCloseModal}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-4">
              <div className="hidden sm:flex sm:gap-x-4">
                <button
                  type="button"
                  className="self-start text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => onCloseModal(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="w-6 h-6" aria-hidden="true" />
                </button>

                <EventDetailContents
                  event={event}
                  stravaUrl={stravaUrl}
                  activityRoute={activityRoute}
                  metricSetting={metricSetting}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default TrainingEventDetails;
