import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import { classNames } from "../../../Utilities/classnames";

function Trend({ metric }: { metric: number }) {
  function formatTrend(trend: number) {
    return Math.round(trend * 100) + "%";
  }

  return (
    <div
      className={classNames(
        metric >= 0 ? "bg-green-100" : "bg-red-100",
        "flex items-center px-1.5 py-0.5 rounded-full"
      )}
    >
      {metric >= 0 ? (
        <ArrowSmUpIcon className="w-4 h-4 text-green-500" />
      ) : (
        <ArrowSmDownIcon className="w-4 h-4 text-red-500" />
      )}
      <p
        className={classNames(
          metric >= 0 ? "text-green-800" : "text-red-800",
          " text-xs "
        )}
      >
        {formatTrend(metric)}
      </p>
    </div>
  );
}

export default Trend;
