import { IonPage, IonContent, IonRouterLink } from "@ionic/react";
import StravaConnect from "../../../Assets/StravaConnect.svg";
import useAthleteSettings from "../../../Pages/Hooks/useAthleteSettings";
import Back from "../../../Assets/Back.svg";
import SettingsSubheader from "../ProfileSettings/SettingsSubheader";

export default function ConnectStrava() {
  const { handleConnect } = useAthleteSettings();

  return (
    <IonPage>
      <SettingsSubheader title="Membership" />
      <IonContent>
        <div className="max-w-xl h-full sm:h-fit mx-auto sm:mt-4 lg:mt-20 sm:px-6 lg:px-8">
          <IonRouterLink routerLink="/profile/settings" routerDirection="back">
            <button className="items-center self-start hidden mb-12 ml-6 space-x-1 text-xs text-gray-400 rounded md:flex hover:text-gray-500">
              <img src={Back} alt="Back" className="w-auto h-4" />
              <p>Back</p>
            </button>
          </IonRouterLink>
          <div className="p-4 bg-white border-t-1 border-neutral-100 flex flex-col h-full">
            <div className="flex-grow flex flex-col items-center justify-center">
              <img src={StravaConnect} alt="connect to strava" />
              <h2 className="font-semibold text-[24px] leading-[32px] tracking-[-0.02em] mb-[2px] mt-16">
                Connect to Strava
              </h2>
              <p className="text-[18px] text-center leading-[28px] text-neutral-500 mt-2">
                Connect Strava so Runzy can track your running and keep
                everything up to date in real time.
              </p>
            </div>
            <div className="flex-grow-0 flex justify-center items-end mt-4">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleConnect();
                }}
                className="w-full py-[10px] rounded-[200px] text-white text-[16px] font-medium bg-runzy-red-orange disabled:bg-neutral-100 disabled:text-neutral-500 disabled:cursor-not-allowed"
              >
                Connect to Strava
              </button>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
