import { IonContent, IonPage, IonRouterLink } from "@ionic/react";
// import BackArrow from "../../../Assets/BackArrow.svg";
import Back from "../../Assets/Back.svg";
import React, { useEffect } from "react";
import { classNames } from "../../Utilities/classnames";
import { Switch } from "@headlessui/react";
import useNotifications from "./ProfileSettings/useNotifications";
import SettingsSubheader from "./ProfileSettings/SettingsSubheader";

export default function NotificationsPreference() {
  const {
    sendDailyWorkoutNotification,
    handleSetSendDailyWorkoutNotification,
  } = useNotifications();
  const [isEnabled, setIsEnabled] = React.useState(
    sendDailyWorkoutNotification
  );
  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  useEffect(() => {
    if (isEnabled === undefined) {
      setIsEnabled(sendDailyWorkoutNotification);
    }
  }, [sendDailyWorkoutNotification, isEnabled]);

  async function saveNotificationPreference() {
    if (isEnabled !== undefined) {
      await handleSetSendDailyWorkoutNotification(isEnabled);
    }
  }

  return (
    <IonPage>
      <SettingsSubheader title="Notifications" />
      <IonContent>
        <div className="max-w-xl h-full sm:h-fit mx-auto sm:mt-4 lg:mt-20 sm:px-6 lg:px-8">
          <IonRouterLink routerLink="/profile/settings" routerDirection="back">
            <button className="items-center self-start hidden mb-12 ml-6 space-x-1 text-xs text-gray-400 rounded md:flex hover:text-gray-500">
              <img src={Back} alt="Back" className="w-auto h-4" />
              <p>Back</p>
            </button>
          </IonRouterLink>
          <div className="p-4 bg-white border-t-1 border-neutral-100 flex flex-col h-full">
            <div className="flex-grow-0">
              <h2 className="font-semibold text-[24px] leading-[32px] tracking-[-0.02em] mb-[2px]">
                Notifications Preference
              </h2>
              <p className="text-[16px] leading-[24px] text-neutral-700 mb-4">
                Select what type of notifications you want to receive from
                Perform
              </p>
            </div>
            <div className="flex-grow-0">
              <div className="flex items-center justify-between py-[15px] border-b border-neutral-100">
                <div>
                  <p className="font-medium text-neutral-900 text-[16px] leading-[24px]">
                    Push Notifications
                  </p>
                  <p className="text-neutral-500 text-[16px] leading-[24px]">
                    Daily workout notifications
                  </p>
                </div>
                <Switch
                  checked={isEnabled ?? false}
                  onChange={toggleSwitch}
                  className={classNames(
                    isEnabled ? "bg-primary-500" : "bg-neutral-100",
                    "relative inline-flex flex-shrink-0 h-[24px] w-[48px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isEnabled ? "translate-x-[24px]" : "translate-x-0",
                      "pointer-events-none inline-block h-[20px] w-[20px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200"
                    )}
                  />
                </Switch>
              </div>
            </div>
            <div className="flex-grow flex flex-col justify-end mt-4">
              <div className="flex justify-center">
                <button
                  disabled={
                    isEnabled === undefined ||
                    isEnabled === sendDailyWorkoutNotification
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    saveNotificationPreference();
                  }}
                  className="w-full py-[10px] rounded-[200px] text-white text-[16px] font-medium bg-primary-500 disabled:bg-neutral-100 disabled:text-neutral-500 disabled:cursor-not-allowed"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
