import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import useTrainingProfile from "./useTrainingProfile";
import Contents from "./Contents";
import CoachingSidebar from "./CoachingSidebar";
import SmallToggle from "../Components/SmallToggle";
import { ReactComponent as OpenSidebarIcon } from "../../Assets/Sidebar/menu_to_open.svg";
import { ReactComponent as CloseSidebarIcon } from "../../Assets/Sidebar/menu_to_close.svg";
import { classNames } from "../../Utilities/classnames";

interface TrainingProfileInput {
  showAllAthletes: boolean;
}

function TrainingProfile({ showAllAthletes }: TrainingProfileInput) {
  const trainingProfileHook = useTrainingProfile({
    showAllAthletes: showAllAthletes,
  });

  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  return (
    <div className="relative h-full min-h-screen bg-gray-50">
      <div
        className={classNames(
          "w-full mx-auto lg:grid lg:gap-8",
          showSidebar ? "grid-cols-[300px,auto]" : ""
        )}
      >
        {showSidebar && (
          <CoachingSidebar trainingProfileHook={trainingProfileHook} />
        )}
        <div className="hidden md:inline-flex absolute top-4 left-6 z-20">
          <button onClick={() => setShowSidebar(!showSidebar)}>
            {showSidebar ? <CloseSidebarIcon /> : <OpenSidebarIcon />}
          </button>
        </div>
        <Contents
          showSidebar={showSidebar}
          trainingProfileHook={trainingProfileHook}
        />
      </div>
    </div>
  );
}

export default TrainingProfile;
