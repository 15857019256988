import { trainingEvent, TrainingEventStatus } from "../../Api/TrainingEvent";
import { useActivityFeedbackOutput } from "../Hooks/useActivityFeedback";
import { Comment } from "../../Api/Stream";
import { useContext, useEffect } from "react";
import { UserInfoContext } from "../../Context/UserContext";
import { initUserInfo } from "../../Api/User";
import TextareaAutosize from "react-textarea-autosize";
import { PaperAirplaneIcon } from "@heroicons/react/solid";
import DeleteCommentModal from "../Activity/Feedback/DeleteCommentModal";
import { useLocation } from "react-router-dom";

import PostFeedbackComment from "./PostFeedbackComment";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";

interface ActivityFeedbackInput {
  trainingEvent?: trainingEvent;
  activityFeedback: useActivityFeedbackOutput;
}

function PostFeedback({
  trainingEvent,
  activityFeedback,
}: ActivityFeedbackInput) {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const location = useLocation();

  useEffect(() => {
    initUserInfo(userInfo, setUserInfo);
  }, []);

  return (
    <>
      {activityFeedback.state.comments
        .sort((a, b) => {
          return (
            new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
          );
        })
        .map((comment, index) => (
          <div key={index}>
            <PostFeedbackComment
              comment={comment as Comment}
              streamClient={activityFeedback.state.streamClient}
              activateDeleteModal={activityFeedback.activateDeleteModal}
            />
          </div>
        ))}
      <div className="relative flex items-start space-x-3">
        <ProfilePicture
          classNames="flex items-center justify-center bg-gray-400 w-9 h-9 rounded-xl ring-8 ring-white"
          url={userInfo?.profileImage ?? ""}
        />
        <div className="flex-1 min-w-0">
          <TextareaAutosize
            disabled={
              trainingEvent &&
              trainingEvent.status === TrainingEventStatus.DRAFT
            }
            rows={2}
            name="comment"
            id="comment"
            className="block w-full text-sm border-transparent rounded-md resize-none focus:ring-brand focus:border-brand"
            value={activityFeedback.state.commentBoxContent}
            placeholder={
              trainingEvent &&
              trainingEvent.status === TrainingEventStatus.DRAFT
                ? "You cannot comment on Draft events."
                : location.pathname.includes("coach")
                ? "Add feedback..."
                : "How are you feeling?"
            }
            onChange={(e) =>
              activityFeedback.handleCommentBoxContentChange(e.target.value)
            }
          />{" "}
        </div>
      </div>
      <div className="flex justify-end mt-2">
        {activityFeedback.state.commentBoxContent === "" ||
        activityFeedback.state.isDisabled ||
        (trainingEvent &&
          trainingEvent.status === TrainingEventStatus.DRAFT) ? (
          <button disabled>
            <PaperAirplaneIcon className="w-5 h-5 text-gray-500 rotate-90" />
          </button>
        ) : (
          <button
            onClick={(e) => {
              e.preventDefault();
              activityFeedback.handleSubmitComment();
            }}
          >
            <PaperAirplaneIcon className="w-5 h-5 rotate-90 text-brand" />
          </button>
        )}
      </div>
      <DeleteCommentModal
        showDeleteModal={
          activityFeedback.state.commentToBeDeleted !== undefined
        }
        deactivateCommentModal={activityFeedback.deactivateCommentModal}
        handleDeleteComment={activityFeedback.handleDeleteComment}
      />
    </>
  );
}

export default PostFeedback;
