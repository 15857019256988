/* eslint-disable */
// TODO @wbert - type this file

import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { UserInfoContext } from "../../Context/UserContext";
import { connect, StreamClient } from "getstream";
import Default from "../../Default/Default";
import { trainingAttributes } from "../../Api/TrainingAttribute";
import { useIonRouter } from "@ionic/react";
import { isMobile } from "../../Utilities/isMobile";

type navigationDisplay = {
  name: string;
  current?: boolean;
  representedRoutes?: string[];
  hiddenRoute?: string[];
  action?: Function;
  href?: string;
  canShow?: boolean;
  requiresAuth: boolean;
  minOnboardingProgress?: number;
};

function useCoacheeHeader(trainingAttributes?: trainingAttributes) {
  const history = useHistory();
  const [streamClient, setStreamClient] = useState<StreamClient>();
  const [hasNotifications, setHasNotifications] = useState(false);
  const [showNotificationIcon, setShowNotificationIcon] = useState(true);
  const routerHook = useIonRouter();

  const location = useLocation();
  const { userInfo } = useContext(UserInfoContext);

  const route =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  function navigateTo(destination: string) {
    history.push(destination);
  }

  function navigateHome() {
    if (
      !userInfo ||
      (trainingAttributes && trainingAttributes.onboardingProgress < 3)
    ) {
      // If not signed in, send people to marketing website in new tab.
      window.open("https://runzy.com/coaching/?ref=onboarding", "_blank")!.focus();
      return;
    }
    navigateTo("/dashboard");
  }

  function navigateToNotifications() {
    navigateTo("/notifications");
  }

  function canShow(nav: navigationDisplay): boolean {
    if (nav.requiresAuth && !userInfo) {
      return false;
    }

    if (nav.canShow && !nav.canShow) return false;

    if (!trainingAttributes) return true;

    if (nav.hiddenRoute) {
      if (nav.hiddenRoute.includes(route)) {
        return false;
      }
    }

    if (nav.minOnboardingProgress) {
      if (
        trainingAttributes &&
        trainingAttributes.onboardingProgress >= nav.minOnboardingProgress
      ) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }

  const navigation: navigationDisplay[] = [
    {
      name: "Dashboard",
      current: ["training-log"].includes(route),
      action: () => {
        userInfo?.isTrainingAdmin
            ? routerHook.push('/coach')
            : routerHook.push('/dashboard')
      },
      hiddenRoute: ["onboard", "pending"],
      requiresAuth: true,
      minOnboardingProgress: 3,
    },
    // {
    //   name: "Download Our App",
    //   current: ["app"].includes(route),
    //   canShow: !isMobile,
    //   action: () => {
    //     routerHook.push("/app", "root");
    //   },
    //   hiddenRoute: ["onboard", "pending"],
    //   requiresAuth: true,
    //   minOnboardingProgress: 3,
    // },
  ];

  const setupNotificationIcon = useCallback(() => {
    async function initHasNotificationIcon() {
      if (
        !userInfo ||
        (trainingAttributes && trainingAttributes.onboardingProgress < 4) ||
        location.pathname.includes("onboard") ||
        location.pathname.includes("pending") ||
        location.pathname.includes("activity")
      ) {
        setShowNotificationIcon(false);
      } else {
        setShowNotificationIcon(true);
      }
    }

    initHasNotificationIcon();
  }, [location.pathname, trainingAttributes, userInfo]);

  useEffect(() => {
    async function initHasNotifications() {
      if (location.pathname.includes("notifications")) {
        setHasNotifications(false);
        return;
      }

      if (streamClient) {
        const unreadCount = (await streamClient.feed("notifications").get())
          .unread;

        if (unreadCount && unreadCount > 0) setHasNotifications(true);
      }
    }

    initHasNotifications();
  }, [streamClient, location.pathname]);

  useEffect(() => {
    function initStreamClient() {
      if (userInfo && streamClient === undefined) {
        const client = connect(
          Default.streamsApiKey,
          userInfo!.streamAPIAuth,
          Default.streamsAppId
        );

        setStreamClient(client);
      }
    }

    initStreamClient();
    setupNotificationIcon();
  }, [userInfo, setupNotificationIcon, streamClient]);

  useEffect(() => {
    setupNotificationIcon();
  }, [trainingAttributes, setupNotificationIcon]);

  return {
    navigation,
    hasNotifications,
    showNotificationIcon,
    canShow,
    navigateHome,
    navigateToNotifications,
  };
}

export default useCoacheeHeader;
