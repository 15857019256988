import {
  ClipboardCheckIcon,
  CollectionIcon,
  RefreshIcon,
  DuplicateIcon,
  ClipboardCopyIcon,
  TrashIcon,
  PlusIcon,
  ClipboardIcon,
  TemplateIcon,
} from "@heroicons/react/outline";
import { useContext, useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocalStorage } from "usehooks-ts";
import { Step } from "../../../Api/Step";
import {
  trainingEvent,
  TrainingEventStatus,
  TrainingEventType,
  editTrainingEventInput,
  editTrainingEvent,
} from "../../../Api/TrainingEvent";
import { UserInfoContext } from "../../../Context/UserContext";
import { formatDate } from "../../../Utilities/DateUtilities";
import { titleCase } from "../../../Utilities/FormatUtilties";
import { CalendarDay } from "../../Calendar";
import { handleSelectedEvents } from "../../Tabs/Calendar";

export type Subaction = {
  name: string;
  action: () => void;
  isEnabled?: boolean | null;
};

export type contextMenuOption = {
  name: string;
  icon: JSX.Element;
  isEnabled: boolean;
  action?: () => void;
  subActions?: Subaction[];
};

export type storedTrainingEventContents = {
  assignedDistance: string;
  assignedDuration: string;
  description: string[];
  message: string;
  sportsType: string;
  status: TrainingEventStatus;
  title: string;
  trainingEventType: TrainingEventType;
  steps: Step[];
};

interface useCalendarContextMenuInput {
  day: CalendarDay;
  selectedEvents: trainingEvent[];
  setIsOperating: React.Dispatch<React.SetStateAction<boolean>>;
  initTrainingEvents: () => void;
  handleDuplicate: (event: trainingEvent) => Promise<void>;
  handleRepeat: (event: trainingEvent, weeks: number) => Promise<void>;
  handleDelete: (
    id: string,
    isOverwrite?: boolean | undefined
  ) => Promise<void>;
  handleAdd: (
    type?: TrainingEventType,
    scheduledAt?: Date,
    title?: string,
    description?: string[],
    assignedDistance?: string,
    assignedDuration?: string,
    message?: string,
    sportsType?: string,
    openToNewEvent?: boolean,
    steps?: Step[]
  ) => Promise<void>;
  openTemplateMenu: (date?: Date) => void;
  createTemplate: (event: trainingEvent) => Promise<void>;
}

function useCalendarContextMenu({
  day,
  selectedEvents,
  setIsOperating,
  initTrainingEvents,
  handleDuplicate,
  handleRepeat,
  handleDelete,
  handleAdd,
  openTemplateMenu,
  createTemplate,
}: useCalendarContextMenuInput) {
  const { userInfo } = useContext(UserInfoContext);
  const [storedTrainingEvent, setStoredTrainingEvent] = useLocalStorage<
    storedTrainingEventContents | undefined
  >("storedTrainingEvent", undefined);

  function hasRepeatConflicts(
    selectedEvents: trainingEvent[],
    weeks: number
  ): boolean {
    const formattedDates = selectedEvents.map((event) =>
      formatDate(event.scheduledAt)
    );

    for (let i = 0; i < selectedEvents.length; i++) {
      for (let j = 1; j <= weeks; j++) {
        const newDate = new Date(selectedEvents[i].scheduledAt);
        newDate.setDate(newDate.getDate() + 7 * j);

        if (formattedDates.includes(formatDate(newDate))) {
          return true;
        }
      }
    }

    return false;
  }

  function handleCopyEvent(event: trainingEvent) {
    const copyData: storedTrainingEventContents = {
      assignedDistance: event.assignedDistance,
      assignedDuration: event.assignedDuration,
      description: event.description,
      message: event.message,
      sportsType: event.sportsType,
      status: TrainingEventStatus.DRAFT,
      title: event.title,
      trainingEventType: event.trainingEventType,
      steps: event.steps,
    };
    // copy(JSON.stringify(copyData));
    setStoredTrainingEvent(copyData);
  }

  async function handleChangeEvent(
    status?: TrainingEventStatus,
    type?: TrainingEventType
  ) {
    if (!day.event || !userInfo) return;

    if (handleSelectedEvents(day.event.id, selectedEvents) && status && !type) {
      setIsOperating(true);
      for (let i = 0; i < selectedEvents.length; i++) {
        const payload: editTrainingEventInput = {
          profile: selectedEvents[i].profile,
          title: selectedEvents[i].title,
          status: status ?? selectedEvents[i].status,
          description: selectedEvents[i].description,
          scheduledAt: selectedEvents[i].scheduledAt,
          eventId: selectedEvents[i].id,
          assignedDistance: selectedEvents[i].assignedDistance,
          assignedDuration: selectedEvents[i].assignedDuration,
          trainingEventType: type ?? selectedEvents[i].trainingEventType,
          unitPreference: userInfo?.profileMetricSetting,
          postActivityFeedback: selectedEvents[i].postActivityFeedback,
          postActivityFeedbackDidChange: false,
          message: selectedEvents[i].message,
          sportsType: selectedEvents[i].sportsType,
          steps: selectedEvents[i].steps,
        };

        const response = await editTrainingEvent(payload);

        if (!response) {
          toast("Unable to complete edit operation.");
          setIsOperating(false);

          return;
        }
      }

      initTrainingEvents();
      toast("Events successfully edited");
      setIsOperating(false);

      return;
    }

    const payload: editTrainingEventInput = {
      profile: day.event.profile,
      title: day.event.title,
      status: status ?? day.event.status,
      description: day.event.description,
      scheduledAt: day.event.scheduledAt,
      eventId: day.event.id,
      assignedDistance: day.event.assignedDistance,
      assignedDuration: day.event.assignedDuration,
      trainingEventType: type ?? day.event.trainingEventType,
      unitPreference: userInfo?.profileMetricSetting,
      postActivityFeedback: day.event.postActivityFeedback,
      postActivityFeedbackDidChange: false,
      message: day.event.message,
      sportsType: day.event.sportsType,
      steps: day.event.steps,
    };

    const response = await editTrainingEvent(payload);

    if (response) {
      initTrainingEvents();
      toast("Event successfully edited");
    }
  }

  const options: contextMenuOption[] = [
    {
      name: "Status",
      icon: <ClipboardCheckIcon />,
      isEnabled: day.event !== null && day.event.id.includes("trainingEvent"),
      subActions: Object.values(TrainingEventStatus)
        .filter((status) => status !== TrainingEventStatus.UNSCHEDULED)
        .map((key) => {
          return {
            name: titleCase(key.toString()),
            action: () => {
              if (key in TrainingEventStatus)
                handleChangeEvent(key as TrainingEventStatus, undefined);
            },
            isEnabled:
              day.event &&
              day.event.status === key &&
              !handleSelectedEvents(day.event.id, selectedEvents),
          };
        }),
    },
    {
      name: "Type",
      icon: <CollectionIcon />,
      isEnabled:
        day.event !== null &&
        day.event.id.includes("trainingEvent") &&
        !handleSelectedEvents(day.event.id, selectedEvents),
      subActions: Object.values(TrainingEventType).map((key) => {
        return {
          name: titleCase(key.toString()).replace("_", " "),
          action: () => {
            if (key in TrainingEventType)
              handleChangeEvent(undefined, key as TrainingEventType);
          },
          isEnabled: day.event && day.event.trainingEventType === key,
        };
      }),
    },
    {
      name: "Repeat",
      icon: <RefreshIcon />,
      isEnabled:
        day.event !== null &&
        day.event.id.includes("trainingEvent") &&
        !hasRepeatConflicts(selectedEvents, 1),
      subActions: Array.from({ length: 4 }, (_, i) => i + 1)
        .filter((number) => !hasRepeatConflicts(selectedEvents, number))
        .map((weekIdx) => {
          return {
            name: `${weekIdx} ${weekIdx === 1 ? "Week" : "Weeks"}`,
            action: () => {
              if (day.event) handleRepeat(day.event, weekIdx);
            },
          };
        }),
    },
    {
      name: "Duplicate",
      icon: <DuplicateIcon />,
      isEnabled:
        day.event !== null &&
        day.event.id.includes("trainingEvent") &&
        !handleSelectedEvents(day.event.id, selectedEvents),
      action: () => {
        if (day.event) handleDuplicate(day.event);
      },
    },
    {
      name: "Copy",
      icon: <ClipboardCopyIcon />,
      isEnabled:
        day.event !== null &&
        day.event.id.includes("trainingEvent") &&
        !handleSelectedEvents(day.event.id, selectedEvents),
      action: () => {
        if (day.event) handleCopyEvent(day.event);
      },
    },
    {
      name: "Delete",
      icon: <TrashIcon />,
      isEnabled: day.event !== null && day.event.id.includes("trainingEvent"),
      action: () => {
        if (day.event) handleDelete(day.event.id);
      },
    },

    {
      name: "Create Event",
      icon: <PlusIcon />,
      isEnabled: day.event === null || !day.event.id.includes("trainingEvent"),
      subActions: [
        {
          name: "Base",
          action: () => {
            handleAdd(TrainingEventType.BASE, day.date);
          },
        },
        {
          name: "Long",
          action: () => {
            handleAdd(TrainingEventType.LONG, day.date);
          },
        },
        {
          name: "Race",
          action: () => {
            handleAdd(TrainingEventType.RACE, day.date);
          },
        },
        {
          name: "Recovery",
          action: () => {
            handleAdd(TrainingEventType.RECOVERY, day.date);
          },
        },
        {
          name: "Rest",
          action: () => {
            handleAdd(TrainingEventType.REST, day.date);
          },
        },
        {
          name: "Speed",
          action: () => {
            handleAdd(TrainingEventType.SPEED, day.date);
          },
        },
        {
          name: "Cross Training",
          action: () => {
            handleAdd(TrainingEventType.CROSS_TRAINING, day.date);
          },
        },
        {
          name: "Walk",
          action: () => {
            handleAdd(TrainingEventType.WALK, day.date);
          },
        },
        // {
        //   name: "Use Template",
        //   action: () => {
        //     openTemplateMenu(day.date);
        //   },
        // },
      ],
    },

    {
      name: "Use Template",
      icon: <TemplateIcon />,
      isEnabled: day.event === null || !day.event.id.includes("trainingEvent"),
      action: () => {
        openTemplateMenu(day.date);
      },
    },

    {
      name: "Create Template",
      icon: <TemplateIcon />,
      isEnabled: day.event !== null && day.event.id.includes("trainingEvent"),
      action: () => {
        if (!day.event) return;
        createTemplate(day.event);
      },
    },

    {
      name: "Paste",
      icon: <ClipboardIcon />,
      isEnabled: storedTrainingEvent !== undefined,
      action: async () => {
        const newTrainingEventData = storedTrainingEvent;
        if (newTrainingEventData) {
          if (day.event) handleDelete(day.event.id, true);

          handleAdd(
            newTrainingEventData.trainingEventType,
            day.date,
            newTrainingEventData.title,
            newTrainingEventData.description,
            newTrainingEventData.assignedDistance,
            newTrainingEventData.assignedDuration,
            newTrainingEventData.message,
            newTrainingEventData.sportsType,
            true,
            newTrainingEventData.steps
          );
        }
      },
    },
  ];

  const [currentFocusedOption, setCurrentFocusedOption] = useState("");
  const [displayOnLeft, setDisplayOnLeft] = useState(false);
  const [displayContainerOnLeft, setDisplayContainerOnLeft] = useState(false);
  const [displayOnTop, setDisplayOnTop] = useState(false);
  const overflowRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (
      overflowRef.current &&
      (overflowRef.current as HTMLElement).getBoundingClientRect().right +
        (overflowRef.current as HTMLElement).getBoundingClientRect().width >
        window.innerWidth
    ) {
      setDisplayOnLeft(true);
    }

    if (
      containerRef.current &&
      (containerRef.current as HTMLElement).getBoundingClientRect().top +
        (containerRef.current as HTMLElement).getBoundingClientRect().height >
        window.innerHeight
    ) {
      setDisplayOnTop(true);
    }
  }, [overflowRef]);

  useEffect(() => {
    if (
      containerRef.current &&
      (containerRef.current as HTMLElement).getBoundingClientRect().right +
        (containerRef.current as HTMLElement).getBoundingClientRect().width >
        window.innerWidth
    ) {
      setDisplayContainerOnLeft(true);
    }
  }, [containerRef]);

  return {
    options,
    setCurrentFocusedOption,
    overflowRef,
    containerRef,
    displayOnLeft,
    displayOnTop,
    displayContainerOnLeft,
    currentFocusedOption,
  };
}

export default useCalendarContextMenu;
