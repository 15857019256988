import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { classNames } from "../../../Utilities/classnames";
import { options } from "./UnitModalContents";
import { useUnitPreferenceOutput } from "./useUnitPreference";

function UnitSelector({
  unitPreferenceHook,
}: {
  unitPreferenceHook: useUnitPreferenceOutput;
}) {
  const currentOption = options.find(
    (option) => option.value === unitPreferenceHook.unitPreference
  );

  return (
    <Listbox
      value={unitPreferenceHook.unitPreference}
      onChange={unitPreferenceHook.setUnitPreference}
    >
      {({ open }) => (
        <>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm">
              <span className="inline-flex w-full truncate">
                <span className="truncate">{currentOption?.name}</span>
                <span className="ml-2 text-gray-500 truncate">
                  {currentOption?.abbreviation}
                </span>
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active ? "text-gray-900 bg-[#EEEEEE]" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={option.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "truncate"
                            )}
                          >
                            {option.name}
                          </span>
                          <span
                            className={classNames(
                              active ? "text-gray-500" : "text-gray-500",
                              "ml-2 truncate"
                            )}
                          >
                            {option.abbreviation}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-gray-600" : "text-gray-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default UnitSelector;
