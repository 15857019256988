import {useContext, useEffect, useState} from "react";
import {ActivityRoute, getActivityRoute} from "../../Api/ActivityRoute";
import {trainingEvent} from "../../Api/TrainingEvent";
import {UserInfoContext} from "../../Context/UserContext";
import {decodeDateFromSecondsFormat} from "../../Utilities/DateUtilities";
import {formatDistance, formatDuration, formatNetElevation, formatPace, titleCase,} from "../../Utilities/FormatUtilties";

function ExtraActivity({
  otherEvent,
  index,
}: {
  otherEvent: trainingEvent;
  index: number;
}) {
  const { userInfo } = useContext(UserInfoContext);

  const [activityRoute, setActivityRoute] = useState<ActivityRoute>();

  async function initRoute() {
    if (!otherEvent.activity) return;

    const activityId = otherEvent.activity.replace("perform:activity:", "");

    const response = await getActivityRoute(activityId);

    if (response) {
      setActivityRoute(response);
    }
  }

  useEffect(() => {
    initRoute();
  }, []);

  const activityDateFromRoute = activityRoute
    ? decodeDateFromSecondsFormat(activityRoute?.activity.startedAt)
    : undefined;

  const activityDate = activityDateFromRoute
    ? activityDateFromRoute.toLocaleString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZoneName: "short",
      })
    : undefined;

  const data: { key: string; value: string | undefined }[] = [
    { key: "Date", value: activityDate },
    {
      key: "Title",
      value: activityRoute ? activityRoute.activity.userTitle : undefined,
    },
    {
      key: "Description",
      value: activityRoute ? activityRoute.activity.description : undefined,
    },
    {
      key: "Type",
      value: titleCase(activityRoute ? activityRoute.activity.activityType : otherEvent.sportsType),
    },
    {
      key: "Distance",
      value:
        activityRoute && activityRoute.activity.distanceInMeters && userInfo
          ? formatDistance(
              activityRoute.activity.distanceInMeters,
              userInfo.profileMetricSetting,
              true
            )
          : undefined,
    },
    {
      key: "Duration",
      value:
        activityRoute && formatDuration(activityRoute?.activity.movingTime),
    },
    {
      key: "Pace",
      value:
        activityRoute && activityRoute.activity.distanceInMeters && userInfo
          ? formatPace(
              activityRoute.activity.distanceInMeters,
              activityRoute.activity.movingTime,
              userInfo.profileMetricSetting
            )
          : undefined,
    },
    {
      key: "Elevation Gain",
      value:
        activityRoute && userInfo
          ? formatNetElevation(
              activityRoute.activity.elevationGain,
              activityRoute.points,
              userInfo?.profileMetricSetting
            )
          : undefined,
    },
    {
      key: "Heart Rate",
      // ensure no decimals for heart rate
      value:
        activityRoute && activityRoute.activity.averageHeartRate
          ? Math.round(activityRoute.activity.averageHeartRate).toString()
          : undefined,
      // value:
      //   activityRoute && activityRoute.activity.averageHeartRate
      //     ? activityRoute.activity.averageHeartRate.toString()
      //     : undefined,
    },
  ];

  return (
    <>
      {activityRoute && (
        <>
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Activity #{index + 1}
            </h3>
            <p className="max-w-2xl mt-1 text-sm text-gray-500"></p>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="divide-y divide-gray-200">
              {data
                .filter(
                  (datum) => datum.value !== undefined && datum.value.length > 0
                )
                .map((datum, key) => (
                  <div key={key}>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        {datum.key}
                      </dt>
                      <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                        <span className="flex-grow">{datum.value}</span>
                      </dd>
                    </div>
                  </div>
                ))}
              {otherEvent.externalUrl && (
                <div className="py-4 sm:grid sm:py-5 sm:grid-cols-2 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Source</dt>
                  <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                    <span className="flex-grow">
                      <a href={otherEvent.externalUrl} target="_blank">
                        <button
                          type="button"
                          className="font-medium bg-white rounded-md text-brand hover:text-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                        >
                          Strava
                        </button>
                      </a>
                    </span>
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </>
      )}
    </>
  );
}

export default ExtraActivity;
