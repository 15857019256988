import { useNameSettingsOutput } from "./useNameSettings";

function Name({ nameInterface }: { nameInterface: useNameSettingsOutput }) {
  return (
    <div className="pb-[8px] mt-[16px] border-b border-race-50 mx-4">
      <label
        htmlFor="name"
        className="block text-[12px] text-[#9CA3AF] spacing-[20px] tracking-[1px]"
      >
        NAME
      </label>
      <div>
        <input
          type="name"
          name="name"
          id="name"
          className="block w-full font-medium text-[#111827] font-[16px] focus:border-[#717171] focus:ring-[#717171] focus:outline-[#717171]"
          value={nameInterface.currentName}
          onChange={(e) => nameInterface.setCurrentName(e.target.value)}
          aria-describedby="name"
        />
      </div>
    </div>
  );
}

export default Name;
