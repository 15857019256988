import React, { FC } from "react";

import { useTrainingAttributes } from "../../../../Context/TrainingAttributeContext";
import { ExternalAccount } from "../../../../Types";
import { iOS, isMobileEmbeddedView } from "../../../../Api/Auth";
import NavButton from "../NavButton";
// import { isFree } from "../../../../Api/TrainingAttribute";
import { navigate, NavigationOption } from "../navUtil";
import { handleConnectHealthKit } from "../../AppleHealth/ConnectAppleHealth";

// const MAX_TERRA_ACCOUNTS_CONNECTED = 4;

interface AccountConnectionsProps {
  accounts: ExternalAccount[];
}

const AccountConnections: FC<AccountConnectionsProps> = ({ accounts }) => {
  const { trainingAttributes } = useTrainingAttributes();

  // const terraAccounts = accounts.filter((acc) => acc.provider !== "Strava");
  const stravaAccount = accounts.find((acc) => acc.provider === "Strava");

  if (!trainingAttributes) return <></>;
  return (
    <div className="p-4 bg-white">
      <h2 className="block text-[12px] text-[#9CA3AF] spacing-[20px] tracking-[1px] mb-2">
        CONNECTIONS
      </h2>

      <NavButton
        title={stravaAccount ? "Manage Strava" : "Connect to Strava"}
        onClick={() => {
          if (stravaAccount) {
            // routerHook.push("/profile/settings/manage-strava");
            navigate(NavigationOption.MANAGE_STRAVA);
          } else {
            // routerHook.push("/profile/settings/connect-strava");
            navigate(NavigationOption.CONNECT_STRAVA);
          }
        }}
        hasBottomBorder={true}
      />

      {/*{iOS() && isMobileEmbeddedView() && (*/}
      {iOS() && (
        <NavButton
          title="Connect to Apple Health"
          onClick={() => {
            // routerHook.push("/profile/settings/connect-apple-health");
            // navigate(NavigationOption.CONNECT_APPLE_HEALTH);
            handleConnectHealthKit();
          }}
          hasBottomBorder={true}
        />
      )}

      {/*{terraAccounts*/}
      {/*  .filter((a) => !!a.id)*/}
      {/*  .map((acc, idx) => {*/}
      {/*    return (*/}
      {/*      <NavButton*/}
      {/*        title={`Manage ${acc.provider}`}*/}
      {/*        onClick={() => {*/}
      {/*          navigate(NavigationOption.MANAGE_TERRA, acc.id);*/}
      {/*        }}*/}
      {/*        hasBottomBorder={*/}
      {/*          terraAccounts.length < MAX_TERRA_ACCOUNTS_CONNECTED*/}
      {/*        }*/}
      {/*        key={`connected-acc-${idx}`}*/}
      {/*      />*/}
      {/*    );*/}
      {/*  })}*/}

      {/*{terraAccounts.length < MAX_TERRA_ACCOUNTS_CONNECTED &&*/}
      {/*  trainingAttributes &&*/}
      {/*  !isFree(trainingAttributes) && (*/}
      {/*    <NavButton*/}
      {/*      title="Connect a different account"*/}
      {/*      onClick={() => {*/}
      {/*        navigate(NavigationOption.CONNECT_TERRA);*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  )}*/}
    </div>
  );
};

export default AccountConnections;
