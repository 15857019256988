import axios from "axios";

export async function postComment(
  entity: string,
  message: string
): Promise<number | null> {
  try {
    const response = await axios.post("comments", {
      entity: entity,
      message: message,
    });

    return 200;
  } catch (error: any) {
    return null;
  }
}
