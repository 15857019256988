import { useContext, useEffect } from "react";
import { trainingAttributes } from "../../Api/TrainingAttribute";
import { initUserInfo } from "../../Api/User";
import { UserInfoContext } from "../../Context/UserContext";
import {
  decodeDateFromStringFormat,
  generateGreeting,
  getDayDifference,
} from "../../Utilities/DateUtilities";
import Bullseye from "../../Assets/Bullseye.svg";
import { IonRouterLink } from "@ionic/react";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";
import DefaultProfileImage from './../../Assets/placeholder_profile.png';

interface MessageInput {
  attributes: trainingAttributes;
}

function Message({ attributes }: MessageInput) {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);

  const daysTillEvent =
    attributes.goalDate &&
    getDayDifference(decodeDateFromStringFormat(attributes.goalDate));

  useEffect(() => {
    initUserInfo(userInfo, setUserInfo);
  }, []);

  function generateMessage() {
    if (daysTillEvent && daysTillEvent > -1) {
      return (
        <h2 className="text-sm font-normal text-gray-500">
          You have{" "}
          <span className="font-semibold text-black">
            {daysTillEvent} {daysTillEvent === 1 ? "day" : "days"}
          </span>{" "}
          until your{" "}
          <span className="font-semibold text-black">
            {attributes.goalName}
          </span>
          .
        </h2>
      );
    }
  }

  return (
    <>
      {userInfo && (
        <div className="mx-1 mt-5 text-left">
          <div className="text-[28px] mb-2 flex items-center justify-between">
            <div className="font-semibold">
              {userInfo.firstName !== "" && (
                <div className="font-semibold leading-tight">
                  {generateGreeting()},<br />
                  {userInfo.firstName}!
                </div>
              )}

              <h1 className="font-semibold"></h1>
            </div>
            <IonRouterLink routerLink="/settings" routerDirection="root">
              <ProfilePicture
                classNames="cursor-pointer rounded-xl w-14 h-14"
                url={userInfo.profileImage || DefaultProfileImage}
              />
            </IonRouterLink>
          </div>
          {attributes.goalDate !== "" &&
            attributes.goalName !== "" &&
            daysTillEvent &&
            daysTillEvent > -1 && (
              <div className="flex items-center gap-2 p-3.5 mt-3 bg-[#F6F6F6] rounded-xl">
                <img src={Bullseye} alt="bullseye" />
                <div>{generateMessage()}</div>
              </div>
            )}
        </div>
      )}
    </>
  );
}
export default Message;
