import { PaperAirplaneIcon } from "@heroicons/react/solid";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { createPost } from "../../Api/Post";
import { trainingProfile } from "../../Api/TrainingProfile";
import { UserInfoContext } from "../../Context/UserContext";
import { ModalContent } from "../../Pages/CoacheeDashboard";
import { formatNotificationDate } from "../../Utilities/DateUtilities";
import { isMobile } from "../../Utilities/isMobile";
import useActivityFeedback from "../Hooks/useActivityFeedback";
import PostFeedback from "./PostFeedback";
import NewPost from "./NewPost";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";

interface PostModalInput {
  selectedTrainingProfile?: trainingProfile;
  handleRefresh?: () => void;
  closeModal: () => void;
  modalContent: ModalContent;
}

function DashboardModal({
  selectedTrainingProfile,
  handleRefresh,
  closeModal,
  modalContent,
}: PostModalInput) {
  const { userInfo } = useContext(UserInfoContext);
  const [modalActionDisabled, setModalActionDisabled] = useState(true);

  const [postContent, setPostContent] = useState("");

  const activityFeedback = useActivityFeedback({
    id: modalContent !== "new_post" ? modalContent?.id : undefined,
    foreign_id:
      modalContent !== "new_post" ? modalContent?.foreign_id : undefined,
  });

  useEffect(() => {
    setModalActionDisabled(postContent === "");
  }, [postContent]);

  async function handleActionClick() {
    if (!userInfo || postContent === "") return;
    setModalActionDisabled(true);

    const feedProfileId = selectedTrainingProfile
      ? selectedTrainingProfile.miniProfile.numericId.toString()
      : userInfo.miniProfile.numericId.toString();

    const response = await createPost(feedProfileId, postContent);

    if (response) {
      if (handleRefresh) handleRefresh();
      closeModal();
    }
  }

  function createMarkup() {
    if (!modalContent || modalContent === "new_post" || !modalContent.post)
      return;

    return modalContent.post.message;
  }

  return (
    <>
      {isMobile && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={() => closeModal()}
                style={{ color: "#E25D62" }}
              >
                Close
              </IonButton>
            </IonButtons>

            <IonTitle>
              {modalContent === "new_post" && "New "} Training Note
            </IonTitle>
            {modalContent === "new_post" && (
              <IonButtons slot="end">
                <IonButton
                  onClick={() => {
                    handleActionClick();
                  }}
                  disabled={modalActionDisabled}
                >
                  <PaperAirplaneIcon className="w-5 h-5 mr-1 rotate-90 text-brand" />
                </IonButton>
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>
      )}

      <IonContent>
        {modalContent !== "new_post" && modalContent?.post ? (
          <div className="px-3 mt-5">
            <div className="relative pb-10">
              <span
                className="absolute w-px h-full -ml-px bg-gray-200 top-8 left-5"
                aria-hidden="true"
              />

              <div className="relative flex items-start space-x-3">
                <ProfilePicture
                  url={modalContent?.post.actorMiniProfile.profilePicture}
                  classNames="flex items-center justify-center bg-gray-400 w-9 h-9 rounded-xl ring-[12px] ring-white"
                />

                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div className="text-sm font-semibold text-black">
                        {modalContent?.post.actor ===
                        userInfo?.miniProfile.numericId.toString() ? (
                          <p>{modalContent?.post.actorMiniProfile.name}</p>
                        ) : (
                          <p>
                            Coach{" "}
                            {
                              modalContent?.post.actorMiniProfile.name.split(
                                " "
                              )[0]
                            }
                          </p>
                        )}
                      </div>
                      {modalContent && (
                        <p className="text-xs font-thin text-gray-400">
                          {formatNotificationDate(
                            new Date(modalContent?.post.time),
                            true
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  <p className="text-sm text-gray-500 whitespace-pre-line ">
                    {createMarkup()}
                  </p>
                </div>
              </div>
            </div>
            {activityFeedback && (
              <PostFeedback activityFeedback={activityFeedback} />
            )}
          </div>
        ) : (
          <NewPost
            postContent={postContent}
            setPostContent={setPostContent}
            handleActionClick={handleActionClick}
            modalActionDisabled={modalActionDisabled}
          />
        )}
      </IonContent>
    </>
  );
}

export default DashboardModal;
