import { idToURN } from "../Utilities/UrnUtilities";
import axios from "axios";

export async function addEmojiToReaction(
  reactionId: string,
  emoji: string,
  athleteId: string,
  trainingEventId?: string
) {
  try {
    const reactionUrn = idToURN(reactionId, "streamReaction");
    const athleteUrn = idToURN(athleteId, "profile");
    const parentTrainingEventUrn = trainingEventId
      ? idToURN(trainingEventId, "trainingEvent")
      : undefined;

    const payload = {
      entity: reactionUrn,
      reaction: emoji,
      athleteProfile: athleteUrn,
      parentTrainingEvent: parentTrainingEventUrn,
    };

    const response = await axios.post("reactions", payload);
    return response;
  } catch (error: any) {
    console.log(error);
    return null;
  }
}

export async function deleteReaction(reactionId: string) {
  try {
    const reactionUrn = idToURN(reactionId, "streamReaction");

    const response = await axios.delete(`reactions/${reactionUrn}`);
    return response;
  } catch (error: any) {
    return null;
  }
}
