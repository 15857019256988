import { useLocation, useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  CalendarIcon,
  BellIcon,
  PencilAltIcon,
  ClipboardListIcon,
  AtSymbolIcon,
  IdentificationIcon,
  ChartSquareBarIcon,
  CogIcon,
  ChatAlt2Icon,
  DocumentTextIcon,
} from "@heroicons/react/outline";
import { classNames } from "../../Utilities/classnames";
import useAthletes from "../Hooks/useAthletes";
import { UserInfoContext } from "../../Context/UserContext";
import { isSuperAdmin } from "../../Api/User";
import { getTrainingProfile, trainingProfile } from "../../Api/TrainingProfile";
import { DetailedOptions } from "../Combobox/ComboBox";

interface useTrainingProfileInput {
  showAllAthletes: boolean;
}

function useTrainingProfile({ showAllAthletes }: useTrainingProfileInput) {
  const location = useLocation();
  const history = useHistory();
  const { userInfo } = useContext(UserInfoContext);
  const [selectedProfile, setSelectedProfile] = useState<trainingProfile>();

  const {
    unreadMap,
    trainingProfiles,
    basicNeedsAttentionProfiles,
    premiumProfiles,
    allBasicProfiles,
  } = useAthletes({
    showAllAthletes: showAllAthletes,
  });

  const detailedOptions: DetailedOptions | undefined =
    allBasicProfiles && basicNeedsAttentionProfiles && premiumProfiles
      ? {
          allBasicProfiles: allBasicProfiles,
          basicNeedsAttentionProfiles: basicNeedsAttentionProfiles,
          premiumProfiles: premiumProfiles,
        }
      : undefined;

  const selectedProfileId = location.pathname
    .split("/")
    .filter((element) => element !== "")[1];

  const selectedProfileNotificationCount =
    unreadMap?.find(
      (mapItem) =>
        mapItem.trainingProfile.miniProfile.id ===
        selectedProfile?.miniProfile.id
    )?.unreadCount ?? 0;

  const navigation = [
    {
      name: "Calendar",
      href: "calendar",
      icon: (
        <CalendarIcon
          className={classNames(
            location.pathname.includes("calendar")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500",
            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
      ),
      current: location.pathname.includes("calendar"),
      isShown: true,
    },
    {
      name: "Activity",
      href: "activity",
      icon: (
        <ClipboardListIcon
          className={classNames(
            location.pathname.includes("activity")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500",
            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
      ),
      current:
        location.pathname.includes("activity") &&
        !location.pathname.includes("calendar"),
      isShown: true,
    },
    {
      name: "Notes",
      href: "notes",
      icon: (
        <PencilAltIcon
          className={classNames(
            location.pathname.includes("notes")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500",
            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
      ),
      current: location.pathname.includes("notes"),
      isShown: true,
    },
    {
      name: "Email",
      href: "email",
      icon: (
        <AtSymbolIcon
          className={classNames(
            location.pathname.includes("email")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500",
            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
      ),
      current: location.pathname.includes("email"),
      isShown: true,
    },
    {
      name: "Posts",
      href: "posts",
      icon: (
        <ChatAlt2Icon
          className={classNames(
            location.pathname.includes("email")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500",
            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
      ),
      current: location.pathname.includes("posts"),
      isShown: true,
    },
    {
      name: "Profile",
      href: "profile",
      icon: (
        <IdentificationIcon
          className={classNames(
            location.pathname.includes("profile")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500",
            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
      ),
      current: location.pathname.includes("profile"),
      isShown: true,
    },
    {
      name:
        selectedProfileNotificationCount > 0
          ? `Notifications (${selectedProfileNotificationCount})`
          : "Notifications",
      href: "notifications",
      icon: (
        <div className="relative">
          <>
            <BellIcon
              className={classNames(
                location.pathname.includes("notifications")
                  ? "text-gray-500"
                  : "text-gray-400 group-hover:text-gray-500",
                "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
              )}
              aria-hidden="true"
            />{" "}
            {selectedProfileNotificationCount > 0 && (
              <>
                <span className="absolute top-0.5 animate-ping right-4 inline-flex h-1.5 w-1.5 opacity-75 rounded-full ring-2 ring-rose-300 bg-rose-300" />
                <span className="absolute top-0.5 right-4 inline-flex h-1.5 w-1.5 rounded-full ring-2 ring-rose-400 bg-rose-400" />
              </>
            )}
          </>
        </div>
      ),

      current: location.pathname.includes("notifications"),
      isShown: true,
    },
    {
      name: "Stats",
      href: "stats",
      icon: (
        <ChartSquareBarIcon
          className={classNames(
            location.pathname.includes("profile")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500",
            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
      ),
      current: location.pathname.includes("stats"),
      isShown: true,
    },
    {
      name: "Tools",
      href: "tools",
      icon: (
        <CogIcon
          className={classNames(
            location.pathname.includes("profile")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500",
            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
      ),
      current: location.pathname.includes("tools"),
      isShown: userInfo && isSuperAdmin(userInfo),
    },
    {
      name: "Change log",
      href: "change-log",
      icon: (
        <DocumentTextIcon
          className={classNames(
            location.pathname.includes("change-log")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500",
            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
      ),
      current: location.pathname.includes("change-log"),
      isShown: true,
    },
  ];

  function updateNavRoute(newProfile?: trainingProfile) {
    const currentLocation = location.pathname;
    const locationArray = currentLocation
      .split("/")
      .filter((element) => element !== "");

    if (!locationArray.includes("coach")) return;

    const currentTab = locationArray[2];
    const currentID = locationArray[1];
    if (newProfile) {
      const newId = newProfile.miniProfile.id;

      if (locationArray.length === 2) {
        history.push(`/${locationArray.concat(["calendar"]).join("/")}`);
      } else {
        const newLocationArray = [...locationArray];
        newLocationArray[1] = newId;
        history.push(`/${newLocationArray.join("/")}`);
      }

      return;
    }

    if (!currentTab && currentID) {
      history.push(`/coach/${currentID}/calendar`);
    } else {
      if (currentTab === locationArray[locationArray.length - 1]) {
        if (selectedProfileId !== currentID) {
          history.push(`/coach/${selectedProfileId}/${currentTab}`);
        }
      } else {
        if (locationArray[3] && locationArray[4]) {
          history.push(
            `/coach/${selectedProfileId}/${currentTab}/${locationArray[3]}/${locationArray[4]}`
          );
        } else
          history.push(
            `/coach/${selectedProfileId}/${currentTab}/${
              locationArray[locationArray.length - 1]
            }`
          );
      }
    }
  }

  function getNextTrainingProfile() {
    if (!trainingProfiles || !selectedProfile) return;

    const currentIndex = trainingProfiles.findIndex(
      (profile) => profile.miniProfile.id === selectedProfileId
    );

    const newIndex = currentIndex + 1;

    if (newIndex >= trainingProfiles.length) {
      updateNavRoute(trainingProfiles[0]);
      return;
    }

    updateNavRoute(trainingProfiles[newIndex]);
  }

  function getPreviousTrainingProfile() {
    if (!trainingProfiles || !selectedProfile) return;

    const currentIndex = trainingProfiles.findIndex(
      (profile) => profile.miniProfile.id === selectedProfileId
    );

    const newIndex = currentIndex - 1;

    if (newIndex < 0) {
      updateNavRoute(trainingProfiles[trainingProfiles.length - 1]);

      return;
    }

    updateNavRoute(trainingProfiles[newIndex]);
  }

  useEffect(() => {
    async function initSelectedProfile() {
      if (
        selectedProfile &&
        selectedProfileId === selectedProfile.miniProfile.id
      )
        return;

      if (selectedProfileId) {
        const newProfile = await getTrainingProfile(selectedProfileId);
        if (newProfile) setSelectedProfile(newProfile);
      }
    }

    let mounted = true;

    if (mounted) {
      initSelectedProfile();
    }

    return () => {
      mounted = false;
    };
  }, [setSelectedProfile, selectedProfileId]);

  return {
    getNextTrainingProfile,
    getPreviousTrainingProfile,
    trainingProfiles,
    updateNavRoute,
    selectedProfile,
    navigation,
    selectedProfileId,
    detailedOptions,
  };
}

export interface useTrainingProfileOutput {
  getNextTrainingProfile: () => void;
  getPreviousTrainingProfile: () => void;
  trainingProfiles: trainingProfile[] | undefined;
  updateNavRoute: (newProfile?: trainingProfile | undefined) => void;
  selectedProfile: trainingProfile | undefined;
  navigation: {
    name: string;
    href: string;
    icon: JSX.Element;
    current: boolean;
    isShown: boolean | null;
  }[];
  selectedProfileId: string;
  detailedOptions: DetailedOptions | undefined;
}

export default useTrainingProfile;
