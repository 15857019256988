import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { TrainingEventType } from "../../Api/TrainingEvent";
import { classNames } from "../../Utilities/classnames";
import { titleCase } from "../../Utilities/FormatUtilties";

interface CalendarAddEventInput {
  handleAdd: (type: TrainingEventType) => void;
}

function CalendarAddEvent({ handleAdd }: CalendarAddEventInput) {
  const items = (
    Object.keys(TrainingEventType) as Array<keyof typeof TrainingEventType>
  ).map((key) => {
    return {
      name: titleCase(key.toString()).replaceAll("_", " "),
      type: key,
    };
  });

  return (
    <span className="relative z-0 inline-flex ml-6 rounded-md shadow-sm">
      <Menu as="span" className="relative block -ml-px">
        <Menu.Button className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-brand hover:bg-brand-dark focus:z-10 focus:outline-none focus:ring-1 focus:ring-brand-dark focus:border-brand">
          <div className="relative inline-flex items-center px-2 mr-4 text-sm font-medium text-white border border-transparent focus:z-10 focus:outline-none focus:ring-1">
            Add event
          </div>
          <span className="sr-only">Open options</span>
          <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-56 mt-2 -mr-1 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {items.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleAdd(TrainingEventType[item.type]);
                      }}
                      className={classNames(
                        active 
                          ? "bg-gray-100 text-gray-900" 
                          : "text-gray-700",
                        "block px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      {item.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </span>
  );
}

export default CalendarAddEvent;
