import { useContext, useEffect, useState } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { UserInfoContext } from '../../../Context/UserContext'

import AdminLayout from '../../../Layout/AdminLayout'
import { getSurveyReport, SurveyReport } from '../../../Api/Admin/Report/Survey'
import { PaginationLink, PaginationMeta } from '../../../Api'
import { Pagination } from '../../../Components'
import { da } from 'date-fns/locale'
import SurveyReportRow from '../../../Admin/Report/Survey/SurveyReportRow'

const SurveyReportPage = () => {
    const { userInfo } = useContext(UserInfoContext)

    const [page, setPage] = useState<string>('1')
    const [data, setData] = useState<SurveyReport[]>()
    const [meta, setMeta] = useState<PaginationMeta>()
    const [links, setLinks] = useState<PaginationLink[]>()

    useEffect(() => {
        getSurveyReport({ page }).then(({ data, meta, links }) => {
            setData(data)
            setLinks(links)
            setMeta(meta)
        })
    }, [page])

    return (
        <IonPage>
            <IonContent>
                {userInfo && data && meta && links && (
                    <AdminLayout title="Survey Data Report">
                        {/*<div>{meta.total}</div>*/}
                        <div className="mt-4 overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-300">
                                <tbody>
                                    {data ? data.map(
                                        (row, key) => <SurveyReportRow row={row} key={key} />
                                    ) : <></>}
                                </tbody>
                            </table>
                        </div>
                        <Pagination links={links} onChange={(e) => setPage(e)} />
                    </AdminLayout>
                )}
            </IonContent>
        </IonPage>
    )
}

export default SurveyReportPage
