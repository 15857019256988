import axios from "axios";
import { MiniProfile } from "./TrainingProfile";

export type TrainingAdmin = {
  id: string;
  miniProfile: MiniProfile;
  lastActiveAt: number;
};

export async function getTrainingAdmins(): Promise<TrainingAdmin[] | null> {
  try {
    const response = await axios.get(`training-admins?offset=0&limit=500`);
    return response.data.items;
  } catch (error: any) {
    return null;
  }
}
