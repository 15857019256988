import { IonContent, IonPage, IonRouterLink } from "@ionic/react";
import useAthleteSettings from "../../../Pages/Hooks/useAthleteSettings";
import Back from "../../../Assets/Back.svg";
import { altDayOfTheWeek } from "../../../Utilities/DateUtilities";
import React, { useEffect } from "react";
import { classNames } from "../../../Utilities/classnames";
import { Switch } from "@headlessui/react";
import SettingsSubheader from "../ProfileSettings/SettingsSubheader";

function WorkoutDay({
  day,
  isEnabled,
  handlePress,
}: {
  day: string;
  isEnabled: boolean;
  handlePress: () => void;
}) {
  return (
    <div className="flex items-center justify-between py-[15px] border-b border-neutral-100">
      <p className="font-medium text-neutral-900 text-[16px] leading-[24px]">
        {day}
      </p>
      <Switch
        checked={isEnabled}
        onChange={handlePress}
        className={classNames(
          isEnabled ? "bg-primary-500" : "bg-neutral-100",
          "relative inline-flex flex-shrink-0 h-[24px] w-[48px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            isEnabled ? "translate-x-[24px]" : "translate-x-0",
            "pointer-events-none inline-block h-[20px] w-[20px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </div>
  );
}

export default function Availability() {
  const { workoutDays, updateWorkoutDays } = useAthleteSettings();
  const daysOfTheWeek = altDayOfTheWeek;
  const [currentDays, setCurrentDays] = React.useState(workoutDays());

  useEffect(() => {
    setCurrentDays(workoutDays());
  }, [workoutDays]);

  return (
    <IonPage>
      <SettingsSubheader title="Availability" />
      <IonContent>
        <div className="max-w-xl h-full sm:h-fit mx-auto sm:mt-4 lg:mt-20 sm:px-6 lg:px-8">
          <IonRouterLink routerLink="/profile/settings" routerDirection="back">
            <button className="items-center self-start hidden mb-12 ml-6 space-x-1 text-xs text-gray-400 rounded md:flex hover:text-gray-500">
              <img src={Back} alt="Back" className="w-auto h-4" />
              <p>Back</p>
            </button>
          </IonRouterLink>
          <div className="p-4 bg-white border-t-1 border-neutral-100 flex flex-col h-full">
            <div className="flex-grow-0">
              <h2 className="font-semibold text-[24px] leading-[32px] tracking-[-0.02em] mb-[2px]">
                Workout Days
              </h2>
              <p className="text-[16px] leading-[24px] text-neutral-700 mb-4">
                Select which days that you can workout on.
              </p>

              {currentDays &&
                daysOfTheWeek.map((day) => (
                  <WorkoutDay
                    key={day}
                    day={day}
                    isEnabled={currentDays.includes(day)}
                    handlePress={() => {
                      if (currentDays.includes(day)) {
                        const newDays = currentDays.filter((d) => d !== day);
                        setCurrentDays(newDays);
                      } else {
                        const newDays = [...currentDays, day];
                        setCurrentDays(newDays);
                      }
                    }}
                  />
                ))}
            </div>

            <div className="flex-grow flex justify-center items-end">
              <button
                disabled={
                  JSON.stringify((workoutDays() ?? []).sort()) ===
                  JSON.stringify((currentDays ?? []).sort())
                }
                onClick={() => {
                  if (!currentDays) return;

                  updateWorkoutDays(currentDays).then(() => {
                    setCurrentDays(workoutDays());
                  });
                }}
                className={classNames(
                  "w-full py-[10px] rounded-[200px] text-white text-[16px] font-medium bg-primary-500 disabled:bg-neutral-100 disabled:text-neutral-500 disabled:cursor-not-allowed sm:mt-4"
                )}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
