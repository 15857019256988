import React from 'react'
import { PaginationLink } from '../../Api'

type PaginationProps = {
    links: PaginationLink[]
    onChange: (page: string) => void
}

type PaginationLinkViewProps = {
    link: PaginationLink
    onClick: () => void
}

function getClassName(active: boolean) {
    if (active) {
        return 'mr-1 mb-1 px-4 py-3 text-sm leading-4 border rounded-full focus:border-primary focus:text-primary bg-brand text-white'
    } else {
        return 'mr-1 mb-1 px-4 py-3 text-sm leading-4 border rounded-full focus:border-primary focus:text-primary'
    }
}

const PaginationLinkView = ({link, onClick}: PaginationLinkViewProps) => {
    return (
        link.url === null ?
            <div className="mr-1 mb-1 px-4 py-3 text-sm leading-4 text-gray-400 border rounded-full"
                 dangerouslySetInnerHTML={{__html: link.label}}/> :
            <button className={getClassName(link.active)}
                    dangerouslySetInnerHTML={{__html: link.label}} onClick={onClick}/>
    )
}

export const Pagination = ({links, onChange}: PaginationProps) => (
    links.length > 3 ? (
        <div className="mb-4">
            <div className="flex flex-wrap mt-8">
                {links
                    .filter(e => !['prev', 'next'].some(v => e.label.toLowerCase().includes(v)))
                    .map(
                        (link, key) => <PaginationLinkView link={link} onClick={() => onChange(link.label)} key={key}/>
                    )}
            </div>
        </div>
    ) : <></>
)
