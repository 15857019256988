import { MenuIcon } from "@heroicons/react/outline";
import { IonItem } from "@ionic/react";
import { trainingEvent } from "../../../Api/TrainingEvent";
import { classNames } from "../../../Utilities/classnames";
import { getDecorationColor } from "../util";

function SwappableCard({ event }: { event?: trainingEvent }) {
  return (
    <IonItem
      style={{
        "--inner-padding-start": "0px",
        "--padding-start": "0px",
        "--inner-padding-end": "0px",
        "--padding-end": "0px",
      }}
      lines="none"
    >
      {event ? (
        <div className="bg-white border border-neutral-300 w-[270px] h-[60px] px-3 rounded-[6px] flex items-center justify-between">
          <div className="flex items-center flex-initial gap-4 truncate">
            <div
              className={classNames(
                getDecorationColor(event),
                "w-[3px] h-[24px] shrink-0"
              )}
            ></div>

            <p className="font-semibold text-[18px] text-black truncate">
              {event.title}
            </p>
          </div>

          <MenuIcon className="w-5 h-5 shrink-0 text-neutral-400" />
        </div>
      ) : (
        <div className="bg-neutral-100 border border-neutral-300 w-[270px] h-[60px] px-3 rounded-[6px] flex items-center justify-between gap-4"></div>
      )}
    </IonItem>
  );
}

export default SwappableCard;
