import { createContext, useContext, useMemo, useState } from "react";
import { UserInfo } from "../Api/User";

export interface UserInfoContextInput {
  userInfo: UserInfo | null;
  setUserInfo: (userInfo: UserInfo | null) => void;
}

const initialUserInfoContext: UserInfoContextInput = {
  userInfo: null,
  setUserInfo: () => {
    // do nothing
  },
};

export const UserInfoContext = createContext<UserInfoContextInput>(
  initialUserInfoContext
);

export function useUserInfo() {
  const context = useContext(UserInfoContext);
  if (!context && typeof window !== "undefined") {
    throw new Error("useUserInfo must be used within a UserInfoContext");
  }
  return context;
}

export function UserInfoProvider({
  children,
  injectedValue,
}: {
  children: JSX.Element;
  injectedValue?: UserInfoContextInput;
}) {
  function setUserInfo(userInfo: UserInfo | null) {
    setUserInfoContextState({ ...userInfoContextState, userInfo: userInfo });
  }

  const initUserInfoContextState: UserInfoContextInput = {
    userInfo: null,
    setUserInfo: setUserInfo,
  };

  const [userInfoContextState, setUserInfoContextState] =
    useState<UserInfoContextInput>(initUserInfoContextState);

  const value = useMemo(
    () => ({
      userInfoContextState,
      setUserInfoContextState,
    }),
    [userInfoContextState]
  );

  return (
    <UserInfoContext.Provider
      value={injectedValue ?? value.userInfoContextState}
    >
      {children}
    </UserInfoContext.Provider>
  );
}
