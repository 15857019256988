/* eslint-disable */

import { useContext } from "react";
import { UserInfoContext } from "../../Context/UserContext";
import { IonTextarea } from "@ionic/react";
import { isMobile } from "../../Utilities/isMobile";
import TextareaAutosize from "react-textarea-autosize";
import { classNames } from "../../Utilities/classnames";
import { PaperAirplaneIcon } from "@heroicons/react/solid";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";

function NewPost({
  postContent,
  setPostContent,
  handleActionClick,
  modalActionDisabled,
}: {
  postContent: string;
  setPostContent: Function;
  handleActionClick: Function;
  modalActionDisabled: boolean;
}) {
  const { userInfo } = useContext(UserInfoContext);

  return (
    <div className="flex gap-2 px-3 mt-5">
      <ProfilePicture
        url={userInfo?.profileImage ?? ""}
        classNames="flex mt-1 items-center justify-center bg-gray-400 w-9 h-9 rounded-xl ring-[12px] ring-white"
      />
      {isMobile ? (
        <IonTextarea
          id="textarea"
          autoCapitalize="sentences"
          autoGrow
          className="w-full overflow-hidden border border-transparent rounded-lg resize-none caret-brand focus-within:border-transparent focus-within:ring-1 focus-within:ring-transparent"
          placeholder="Create a new note..."
          value={postContent}
          onIonChange={(e) => setPostContent(e.detail.value!)}
        />
      ) : (
        <div className="flex flex-col w-full gap-2">
          <TextareaAutosize
            autoCapitalize="sentences"
            className="w-full overflow-hidden border border-transparent rounded-lg resize-none caret-brand focus-within:border-transparent focus-within:ring-1 focus-within:ring-transparent"
            placeholder="Create a new note..."
            value={postContent}
            onChange={(e) => setPostContent(e.target.value!)}
          />
          <button
            disabled={modalActionDisabled}
            onClick={() => handleActionClick()}
            type="button"
            className={classNames(
              "self-end sm:inline-flex text-brand disabled:text-gray-500 disabled:cursor-default cursor-pointer justify-center px-4 py-2 ml-3 text-sm font-medium border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
            )}
          >
            <PaperAirplaneIcon className="w-5 h-5 mr-1 rotate-90" />
          </button>{" "}
        </div>
      )}
    </div>
  );
}

export default NewPost;
