import { CalendarDay } from "../../Calendar";
import { classNames } from "../../../Utilities/classnames";
import { Fragment } from "react";
import { TrainingEventType } from "../../../Api/TrainingEvent";
import { formatDistance } from "../../../Utilities/FormatUtilties";
import { UnitPreference } from "../../../Api/User";
interface CalendarMobileGridInput {
  currentCalendarMonth: CalendarDay[];
  handleSelectedDayChange: Function;
  getBackgroundColor: Function;
  selectedDay: CalendarDay | undefined;
  shouldHide?: string[];
}

function CalendarMobileGrid({
  currentCalendarMonth,
  handleSelectedDayChange,
  getBackgroundColor,
  selectedDay,
  shouldHide,
}: CalendarMobileGridInput) {
  let weeklyTotal = 0;
  let weeklyAssignedTotal = 0;
  return (
    <div className="grid w-full grid-cols-7 grid-rows-5 gap-px isolate lg:hidden">
      {currentCalendarMonth.map((day, index) => {
        if (index % 7 === 0 || index === 0) {
          weeklyTotal = 0;
          weeklyAssignedTotal = 0;
        }
        // @ts-ignorex
        if ([TrainingEventType.BASE, TrainingEventType.SPEED, TrainingEventType.LONG, TrainingEventType.RACE].includes(day.event?.trainingEventType)) {
          weeklyTotal += Number(day.event?.actualDistance || 0);
          weeklyAssignedTotal += Number(day.event?.assignedDistance || 0);
        }
        let hideDay
        if (day?.event?.trainingEventType) {
          // @ts-ignorex
          hideDay = shouldHide.includes(day?.event?.trainingEventType) || (shouldHide.includes('UNPLANNED') && day?.event?.trainingEventType === 'CHALLENGE_DAY')
        }
        return (
          <Fragment key={index}>
            <button
              onClick={() => handleSelectedDayChange(day.formattedDate)}
              key={day.formattedDate}
              type="button"
              className={classNames(
                day.isCurrentMonth ? getBackgroundColor(day.event) : "bg-gray-50",
                (day.isSelected || day.isToday) && "font-semibold",
                day.isSelected && "text-white",
                !day.isSelected && day.isToday && "text-brand",
                !day.isSelected &&
                  day.isCurrentMonth &&
                  !day.isToday &&
                  "text-gray-900",
                !day.isSelected &&
                  !day.isCurrentMonth &&
                  !day.isToday &&
                  "text-gray-500",
                hideDay ? "opacity-10" : "",
                "flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10",
                day === selectedDay && "border border-brand"
              )}
            >
              <time
                dateTime={day.formattedDate}
                className={classNames(
                  day.isSelected &&
                    "flex h-6 w-6 items-center justify-center rounded-full",
                  day.isSelected && day.isToday && "bg-brand",
                  day.isSelected && !day.isToday && "bg-gray-900",
                  "ml-auto"
                )}
              >
                {day.formattedDate.split("-").pop()!.replace(/^0/, "")}
              </time>
              <p className="sr-only">{day.event ? "1 event" : "0 events"}</p>
              {day.event && (
                <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                  <div
                    key={day.event.id}
                    className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"
                  ></div>
                </div>
              )}
            </button>
            {index % 7 === 6 && (
                <div className="bg-[#FFFFFF] outline-gray-200 relative py-4 px-3 outline outline-1 flex flex-col h-14 col-start-8 w-20" key={`divider-${index}`}>
                  {formatDistance(weeklyTotal, UnitPreference.IMPERIAL, false) || 0} mi
                </div>
            )}
          </Fragment>
        );
      }
      )}
    </div>
  );
}

export default CalendarMobileGrid;
