import { InformationCircleIcon } from "@heroicons/react/outline";
import { IonModal, useIonToast } from "@ionic/react";
import {
  add,
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
} from "date-fns";
import { useEffect, useState } from "react";
import {
  editTrainingEvent,
  editTrainingEventInput,
  trainingEvent,
} from "../../../Api/TrainingEvent";
import { useUserInfo } from "../../../Context/UserContext";
import { getThisWeeksEvents } from "../util";
import ConfirmModal from "./ConfirmModal";

import Swapper from "./Swapper";

export type weekEvents = (trainingEvent | undefined)[] | undefined;

function ModalContent({
  events,
  setShowModal,
  initEvents,
}: {
  events?: trainingEvent[];
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  initEvents: () => Promise<void>;
}) {
  const { userInfo } = useUserInfo();
  const weeksEvents = events ? getThisWeeksEvents(events) : undefined;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [present, dismiss] = useIonToast();

  const initialEventsByDaysOfTheWeek = weeksEvents
    ? [
        weeksEvents.find((event) => isMonday(event.scheduledAt)),
        weeksEvents.find((event) => isTuesday(event.scheduledAt)),
        weeksEvents.find((event) => isWednesday(event.scheduledAt)),
        weeksEvents.find((event) => isThursday(event.scheduledAt)),
        weeksEvents.find((event) => isFriday(event.scheduledAt)),
        weeksEvents.find((event) => isSaturday(event.scheduledAt)),
        weeksEvents.find((event) => isSunday(event.scheduledAt)),
      ]
    : undefined;

  const [eventsByDayOfWeek, setEventsByDayOfWeek] = useState<weekEvents>(
    initialEventsByDaysOfTheWeek
  );

  useEffect(() => {
    setEventsByDayOfWeek(initialEventsByDaysOfTheWeek);
  }, [events]);

  async function saveChanges() {
    async function handleSaves() {
      if (!eventsByDayOfWeek || !initialEventsByDaysOfTheWeek || !userInfo)
        return;
      const today = new Date();
      const first = today.getDate() - (today.getDay() || 7) + 1;
      const monday = new Date(today.setDate(first));

      eventsByDayOfWeek.forEach(async (event, index) => {
        if (!event) return;
        const initialEvent = initialEventsByDaysOfTheWeek.find(
          (iEvent) => iEvent && iEvent.id === event.id
        );

        if (initialEventsByDaysOfTheWeek.indexOf(initialEvent) !== index) {
          const newDate = add(monday, { days: index });

          const payload: editTrainingEventInput = {
            assignedDistance: event.assignedDistance || "",
            assignedDuration: event.assignedDuration || "",
            description: event.description,
            eventId: event.id,
            message: event.message,
            profile: event.profile,
            scheduledAt: newDate,
            status: event.status,
            title: event.title,
            trainingEventType: event.trainingEventType,
            unitPreference: userInfo.profileMetricSetting,
            postActivityFeedback: event.postActivityFeedback,
            postActivityFeedbackDidChange: false,
            steps: event.steps,
          };
          await editTrainingEvent(payload);
        }
      });
    }

    await handleSaves();
    setShowModal(false);

    setTimeout(() => {
      initEvents();
      present({
        message: "Your schedule has been successfully updated.",
        duration: 3000,
        position: "top",
        color: "dark",
        cssClass: "toastPosition",
      });
    }, 500);
  }

  return (
    <div className="flex flex-col px-4 mt-10 font-poppins">
      <div className="flex items-center justify-between">
        <h1 className="font-semibold text-[18px] text-black">This Week</h1>
        <button
          onClick={() => {
            setShowModal(false);
          }}
        ></button>
      </div>

      <div className="flex items-center gap-2 mt-2 mb-4">
        <InformationCircleIcon className="w-5 h-5 text-gray-500" />
        <p className="text-medium text-[12px] text-neutral-500">
          You can only edit your schedule for the current week.
        </p>
      </div>
      <div className="flex justify-between">
        <div className="ml-1 space-y-2">
          {["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map(
            (day, index) => (
              <div key={index} className="flex h-[60px] items-center">
                <p className="font-semibold tracking-[1px] text-[14px] text-black">
                  {day}
                </p>
              </div>
            )
          )}
        </div>
        <Swapper
          initialEventsByDayOfWeek={initialEventsByDaysOfTheWeek}
          eventsByDayOfWeek={eventsByDayOfWeek}
          setEventsByDayOfWeek={setEventsByDayOfWeek}
        />
      </div>
      <div className="flex items-center justify-center w-full gap-[8px] mt-4">
        <button
          onClick={() => setShowModal(false)}
          className="rounded-[200px] w-[167px] h-[48px] border border-neutral-300 flex items-center justify-center font-medium text-neutral-700 text-[18px]"
        >
          Cancel
        </button>
        <button
          onClick={() => setShowConfirmModal(true)}
          className="w-[168px] h-[48px] rounded-[200px] bg-runzy-red-orange flex items-center justify-center text-white font-medium text-[18px]"
        >
          Save Changes
        </button>
      </div>
      <IonModal
        isOpen={showConfirmModal}
        initialBreakpoint={0.45}
        breakpoints={[0, 0.45]}
        onDidDismiss={() => setShowConfirmModal(false)}
        swipeToClose
      >
        <ConfirmModal
          showModal={showConfirmModal}
          setShowModal={setShowConfirmModal}
          confirmChanges={saveChanges}
        />
      </IonModal>
    </div>
  );
}

export default ModalContent;
