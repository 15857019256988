import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { getOnboardingProgressDescription, onboardingProgressStatuses } from '../../Api/TrainingAttribute'
import { classNames } from "../../Utilities/classnames";

interface OnboardingProgressDropdownInput {
  selectedStatus: number;
  setSelected: Function;
}

function getOnboardProgressBackgroundColor(status: number): string {
  switch (status) {
    case 0:
      return "bg-sky-100";
    case 1:
      return "bg-indigo-100";
    case 2:
      return "bg-amber-100";
    case 3:
      return "bg-rose-100";
    case 4:
      return "bg-green-100";
    case 5:
      return "bg-indigo-200";
    case 6:
      return "bg-runzy-violet";

    default:
      return "bg-gray-100";
  }
}

function getOnboardProgressTextColor(status: number): string {
  switch (status) {
    case -1:
      return "text-sky-800";
    case 1:
      return "text-indigo-800";
    case 2:
      return "text-amber-800";
    case 3:
      return "text-rose-800";
    case 4:
      return "text-green-800";
    case 5:
      return "text-indigo-500";
    case 6:
      return "text-runzy-pink";
    default:
      return "text-gray-800";
  }
}

function OnboardingProgressDropdown({
  selectedStatus,
  setSelected,
}: OnboardingProgressDropdownInput) {
  const statuses = [
      ...onboardingProgressStatuses
  ];

  const [selectedElement, setSelectedElement] = useState(
      (selectedStatus !== undefined && selectedStatus !== null)
      ? statuses.filter((tag) => Number(tag.id) === Number(selectedStatus))[0]
      : undefined
  );

  useEffect(() => {
    if (selectedElement) setSelected(selectedElement.id);
  }, [selectedElement]);

  return (
    <>
      {selectedElement && (
        <Listbox
          value={selectedElement}
          onChange={(element) => setSelectedElement(element)}
        >
          {({ open }) => (
            <>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-brand focus:border-brand sm:text-sm">
                  <span
                    className={`inline-flex px-2 text-xs font-semibold leading-5 ${getOnboardProgressTextColor(
                      selectedStatus
                    )} ${getOnboardProgressBackgroundColor(
                      selectedStatus
                    )} rounded-full`}
                  >
                    {getOnboardingProgressDescription(selectedStatus)}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {statuses.map((status) => (
                      <Listbox.Option
                        key={status.id}
                        className={({ active }) =>
                          classNames(
                            active ? "text-white bg-brand/20" : "text-gray-900",
                            "cursor-default select-none relative py-2 pl-3 pr-9"
                          )
                        }
                        value={status}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`inline-flex px-2 text-xs font-semibold leading-5 ${getOnboardProgressTextColor(
                                status.id
                              )} ${getOnboardProgressBackgroundColor(
                                status.id
                              )} rounded-full`}
                            >
                              {getOnboardingProgressDescription(status.id)}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-brand",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      )}
    </>
  );
}

export default OnboardingProgressDropdown;
