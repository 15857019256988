import { isPlatform, setupIonicReact } from "@ionic/react";
import { isMobile } from "./isMobile";

export function initIonicConfig() {
  const currentMode = isPlatform("ios") ? "ios" : "md";

  if (isMobile) {
    setupIonicReact({
      mode: currentMode,
      animated: isMobile,
    });
  }
}
