import { TrainingEventType } from "../../Api/TrainingEvent";

interface WorkoutTypeTagInput {
  type: TrainingEventType;
}

export function workoutTypeTags(currentType: TrainingEventType) {
  let tags = [
    {
      value: TrainingEventType.BASE,
      component: <WorkoutTypeTag type={TrainingEventType.BASE} />,
    },
    {
      value: TrainingEventType.LONG,
      component: <WorkoutTypeTag type={TrainingEventType.LONG} />,
    },
    {
      value: TrainingEventType.RECOVERY,
      component: <WorkoutTypeTag type={TrainingEventType.RECOVERY} />,
    },
    {
      value: TrainingEventType.REST,
      component: <WorkoutTypeTag type={TrainingEventType.REST} />,
    },
    {
      value: TrainingEventType.SPEED,
      component: <WorkoutTypeTag type={TrainingEventType.SPEED} />,
    },
    {
      value: TrainingEventType.RACE,
      component: <WorkoutTypeTag type={TrainingEventType.RACE} />,
    },
    {
      value: TrainingEventType.CROSS_TRAINING,
      component: <WorkoutTypeTag type={TrainingEventType.CROSS_TRAINING} />,
    },
    {
      value: TrainingEventType.WALK,
      component: <WorkoutTypeTag type={TrainingEventType.WALK} />,
    },
    {
      value: TrainingEventType.UNPLANNED,
      component: <WorkoutTypeTag type={TrainingEventType.UNPLANNED} />,
    },
  ];

  tags = tags.filter((tag) => tag.value !== currentType);

  return tags;
}

function WorkoutTypeTag({ type }: WorkoutTypeTagInput) {
  function BaseTag() {
    return (
      <div className="py-1 text-xs text-center text-white w-14 rounded-xl justify-self-center bg-gradient-to-b from-eventIndicatorBaseFrom to-eventIndicatorBaseTo">
        Base
      </div>
    );
  }

  function LongTag() {
    return (
      <div className="py-1 text-xs text-center text-white w-14 rounded-xl justify-self-center bg-gradient-to-b from-eventIndicatorLongFrom to-eventIndicatorLongTo">
        Long
      </div>
    );
  }

  function SpeedTag() {
    return (
      <div className="py-1 text-xs text-center text-white w-14 rounded-xl bg-gradient-to-b justify-self-center from-eventIndicatorSpeedFrom to-eventIndicatorSpeedTo">
        Speed
      </div>
    );
  }

  function RecoveryTag() {
    return (
      <div className="w-20 py-1 text-xs text-center text-white rounded-xl bg-gradient-to-b justify-self-center from-eventIndicatorRecoveryFrom to-eventIndicatorRecoveryTo">
        Recovery
      </div>
    );
  }

  function RaceTag() {
    return (
      <div className="py-1 text-xs text-center text-white w-14 rounded-xl bg-gradient-to-b justify-self-center from-eventIndicatorRaceFrom to-eventIndicatorRaceTo">
        Race
      </div>
    );
  }

  function RestTag() {
    return (
      <div className="py-1 text-xs text-center text-white w-14 rounded-xl bg-gradient-to-b justify-self-center from-eventIndicatorRestFrom to-eventIndicatorRestTo">
        Rest
      </div>
    );
  }

  function CrossTrainingTag() {
    return (
      <div className="py-1 text-xs text-center text-white w-28 rounded-xl justify-self-center bg-gradient-to-b from-eventIndicatorRaceFrom to-eventIndicatorRaceTo">
        Cross Training
      </div>
    );
  }

  function WalkTag() {
    return (
      <div className="py-1 text-xs text-center text-white w-14 rounded-xl justify-self-center bg-gradient-to-b from-eventIndicatorBaseFrom to-eventIndicatorBaseTo">
        Walk
      </div>
    );
  }

  function UnplannedTag() {
    return (
      <div className="py-1 text-xs text-center text-white w-20 rounded-xl justify-self-center bg-gradient-to-b from-eventIndicatorUnplannedFrom to-eventIndicatorUnplannedTo">
        Unplanned
      </div>
    );
  }

  function renderTypeTag() {
    switch (type) {
      case TrainingEventType.BASE:
        return BaseTag();
      case TrainingEventType.LONG:
        return LongTag();
      case TrainingEventType.SPEED:
        return SpeedTag();
      case TrainingEventType.RECOVERY:
        return RecoveryTag();
      case TrainingEventType.REST:
        return RestTag();
      case TrainingEventType.RACE:
        return RaceTag();
      case TrainingEventType.CROSS_TRAINING:
        return CrossTrainingTag();
      case TrainingEventType.WALK:
        return WalkTag();
      case TrainingEventType.UNPLANNED:
        return UnplannedTag();

      default:
        return BaseTag();
    }
  }

  return renderTypeTag();
}

export default WorkoutTypeTag;
