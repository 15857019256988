import CoacheeHeader from "../Coachee/Components/CoacheeHeader";
import { GiftIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { UserInfoContext } from "../Context/UserContext";
import { toast } from "react-toastify";
import { IonContent, IonPage } from "@ionic/react";
import { isMobile } from "../Utilities/isMobile";
import MobilePageHeader from "../Coachee/Components/MobilePageHeader";
import DefaultWebFooter from "../Coach/Components/Footer/DefaultWebFooter";
function Referrals() {
  const { userInfo } = useContext(UserInfoContext);
  const [hasScrolled, setHasScrolled] = useState(false);

  function getReferralCode() {
    return userInfo && userInfo.firstName && userInfo.lastName
      ? (userInfo.firstName[0] + userInfo.lastName).toUpperCase()
      : userInfo?.id ?? "";
  }

  return (
    <IonPage>
      {isMobile ? (
        <MobilePageHeader title="Refer a friend" hasScrolled={hasScrolled} />
      ) : (
        <CoacheeHeader />
      )}
      <IonContent
        scrollEvents
        onIonScroll={(e) => setHasScrolled(e.detail.scrollTop !== 0)}
      >
        <div className="flex flex-col items-center px-4">
          <div className="pt-12">
            <GiftIcon className="w-12 h-12 text-brand-dark" />
          </div>
          <div className="pt-4 text-2xl text-center text-grey-900">
            Give 50% off, Get 50% off
          </div>
          <div className="pt-4 text-sm text-center text-grey-900">
            Give friends 50% off their first month of Runzy with your promo
            code, and you'll get 50% off next month.
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard.writeText(getReferralCode());
              toast("Referral code copied to clipboard");
            }}
            className="px-4 py-3 mt-12 bg-white border rounded-md font-large border-brand text-brand hover:text-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
          >
            Share code: <strong>{getReferralCode()}</strong>
          </button>
        </div>
      </IonContent>
      {!isMobile && <DefaultWebFooter />}
    </IonPage>
  );
}

export default Referrals;
