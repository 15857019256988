function SaveEventButton(props: any) {
  return (
    <button
      className="px-2 py-1 text-green-500 border border-green-500 rounded hover:text-green-700"
      {...props}
    >
      Save
    </button>
  );
}

export default SaveEventButton;
