import { StreamClient, ReactionAPIResponse } from "getstream";
import { useCallback, useContext, useEffect, useState } from "react";
import { EventActivity, FeedbackActivity, Post } from "../../Api/Stream";
import { UserInfoContext } from "../../Context/UserContext";

interface useStreamActivityReactionInput {
  streamClient: StreamClient;
  activity: FeedbackActivity | EventActivity | Post;
}

type State = {
  likesCount: number;
  hasLiked: boolean;
  commentBoxHidden: boolean;
  commentBoxContent: string;
  comments: ReactionAPIResponse[];
};

const initState: State = {
  likesCount: 0,
  hasLiked: false,
  commentBoxHidden: true,
  commentBoxContent: "",
  comments: [],
};

function useStreamActivityReaction({
  streamClient,
  activity,
}: useStreamActivityReactionInput) {
  const { userInfo } = useContext(UserInfoContext);

  const [state, setState] = useState<State>(initState);

  const checkIfLiked = useCallback(
    (likesReactions: ReactionAPIResponse[]) => {
      async function currentUserLiked(likesReactions: ReactionAPIResponse[]) {
        if (streamClient.currentUser) {
          const ownLikes = likesReactions.find(
            (reaction) => reaction.user_id === streamClient.currentUser!.id
          );

          return ownLikes ? true : false;
        }
      }

      return currentUserLiked(likesReactions);
    },
    [streamClient.currentUser]
  );

  async function handleLike() {
    if (!userInfo || !streamClient.currentUser) return;
    const reactions = await streamClient.reactions.filter({
      activity_id: activity.id,
      kind: "like",
    });

    const userHasLiked = await checkIfLiked(reactions.results);

    if (userHasLiked) {
      for (let i = 0; i < reactions.results.length; i++) {
        if (reactions.results[i].user_id === streamClient.currentUser.id) {
          streamClient.reactions.delete(reactions.results[i].id);
        }
      }
      setState({
        ...state,
        hasLiked: false,
        likesCount: state.likesCount - 1,
      });
    } else {
      await streamClient.reactions.add("like", activity.id, {});
      setState({
        ...state,
        hasLiked: true,
        likesCount: state.likesCount + 1,
      });
    }
  }

  const setupState = useCallback(() => {
    async function initStateValues() {
      if (userInfo) {
        const reactions = await streamClient.reactions.filter({
          activity_id: activity.id,
        });

        const likesReactions = reactions.results.filter(
          (reaction) => reaction.kind === "like"
        );

        const commentReactions = reactions.results.filter(
          (reaction) => reaction.kind === "comment"
        );

        const likesCount = likesReactions.length;
        const hasLiked = await checkIfLiked(likesReactions);

        setState((state) => {
          return {
            ...state,
            likesCount: likesCount,
            hasLiked: hasLiked ? hasLiked : false,
            comments: commentReactions,
          };
        });
      }
    }

    initStateValues();
  }, [activity.id, checkIfLiked, streamClient.reactions, userInfo]);

  useEffect(() => {
    setupState();
  }, [setupState]);

  return {
    state,
    handleLike,
  };
}

export default useStreamActivityReaction;
