import Cookies from "universal-cookie";

type StringToNumberObj = {
  [name: string]: number
};

const experimentIdParam = "eId";
const sourceParam = "c";
const experimentCookieName = "experimentId";
const sourceCookieName = "c";
export const ccCollectionOnlyId = "ggd9z2d"
export const defaultPriceId = "1d6b4d46"
export const postSurveyV2ExperimentId = "ps2";
const experimentIdToPriceMap: StringToNumberObj = {
  "1d6b4d46": 89.00,
  "4bdfadf9": 49.00,
  "c8d9b7da": 29.00,
  "ggd9z2d": 9.00,
  "Basic": 39,
  "Core": 89,
  "Plus": 149
};
const defaultPrice = 89.00;

export function setCurrentExperiment(experimentId: string) {
  const cookies = new Cookies();
  cookies.set(experimentCookieName, experimentId, {path: '/'});
}

export function setCurrentSource(source: string) {
  const cookies = new Cookies();
  cookies.set(sourceCookieName, source, {path: '/'});
}

export function clearCurrentExperiment() {
  const cookies = new Cookies();
  cookies.set(experimentCookieName, null, {path: '/'});
}

export function updateCurrentExperimentIfNeeded() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const paramField = params[experimentIdParam];
  if (params[sourceParam]) {
    setCurrentSource(params[sourceParam]);
  }
  if (!paramField) {
    clearCurrentExperiment();
    return;
  }
  setCurrentExperiment(paramField);
}

export function getCurrentExperiment(): string|null {
  const cookies = new Cookies();
  return cookies.get(experimentCookieName);
}

export function getCurrentSource(): string {
  const cookies = new Cookies();
  return cookies.get(sourceCookieName) ?? "organic";
}

export function isProductPreselected(): boolean {
  const currentExperiment = getCurrentExperiment();
  if (!currentExperiment) {
    return false;
  }
  return currentExperiment == "Basic" || currentExperiment == "Core" || currentExperiment == "Plus";
}

export function getCurrentPrice(): number {
  const currentExperiment = getCurrentExperiment();
  if (!currentExperiment) {
    return defaultPrice
  }
  if (experimentIdToPriceMap[currentExperiment]) {
    return experimentIdToPriceMap[currentExperiment];
  }
  return defaultPrice;
}

export function isCollectCCOnly(): boolean {
  const currentExperiment = getCurrentExperiment();
  if (!currentExperiment) {
    return false
  }

  return currentExperiment == ccCollectionOnlyId;
}