import { useContext, useEffect, useState } from "react";
import { ActivityRoute, getActivityRoute } from "../../../../Api/ActivityRoute";
import { trainingEvent } from "../../../../Api/TrainingEvent";
import Sneakers from "../../../../Assets/sneakers.svg";
import { UserInfoContext } from "../../../../Context/UserContext";

import StatsRow from "./StatsRow";
import Splits from "./Splits";

interface ResultsStatsInput {
  trainingEvent: trainingEvent;
}

function ResultsStats({ trainingEvent }: ResultsStatsInput) {
  const { userInfo } = useContext(UserInfoContext);
  const [currentActivityRoute, setActivityRoute] =
    useState<ActivityRoute | null>();

  async function initActivityRoute() {
    if (trainingEvent.activity) {
      const activityId = trainingEvent.activity.replace(
        "perform:activity:",
        ""
      );

      const response = await getActivityRoute(activityId);

      if (response) {
        setActivityRoute(response);
      }
    } else {
      setActivityRoute(null);
    }
  }

  useEffect(() => {
    initActivityRoute();
  }, []);

  return (
    <>
      {currentActivityRoute &&
        currentActivityRoute.activity.name &&
        currentActivityRoute.activity.localizedTimeAndPlace &&
        userInfo && (
          <>
            <div className="mb-5">
              <div className="py-4 border border-gray-300/50 rounded-xl">
                <div className="border-b">
                  <div className="px-4">
                    <p className="font-medium text-gray-900">
                      {currentActivityRoute.activity.userTitle ?? currentActivityRoute.activity.name}
                    </p>
                    <div className="flex gap-1 pb-4 mt-1">
                      <img src={Sneakers} alt="run" />
                      <p className="text-xs text-gray-500">
                        {currentActivityRoute.activity.localizedTimeAndPlace}
                      </p>
                    </div>
                  </div>
                </div>
                <StatsRow
                  distance={currentActivityRoute.activity.distanceInMeters}
                  movingTime={currentActivityRoute.activity.movingTime}
                />
              </div>
            </div>
            <Splits currentActivityRoute={currentActivityRoute} />
          </>
        )}
    </>
  );
}

export default ResultsStats;
