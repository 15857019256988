import { useState, useEffect } from "react";
import {
  getOnboardingProgressDescription,
  onboardingProgressStatus,
  onboardingProgressStatuses,
} from "../../Api/TrainingAttribute";

import { useHistory, useLocation } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

export type Filter = {
  id: string;
  name: string;
  options: onboardingProgressStatus[];
};

export enum AthleteOrderBy {
  ATHLETE = 'athlete',
  COACH = 'coach',
  CREATED = 'created',
  ONBOARDING_PROGRESS = 'onboardingProgress',
  DAILY_UPDATES_ENABLED = 'dailyUpdatesEnabled',
}

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

function useAdminFilter() {
  const [localOnboardingProgressStatus, setLocalOnboardingProgressStatus] =
    useLocalStorage("onboardingProgressStatuses", [] as number[]);

  const initialOnboardingProgressStatusesId =
    !!localOnboardingProgressStatus.length
      ? localOnboardingProgressStatus
      : [-1, 1, 2, 3, 4, 5, 6];

  const initialOnboardingProgressStatuses = onboardingProgressStatuses.filter(
    (status) => [...initialOnboardingProgressStatusesId].includes(status.id)
  );

  const [
    filteredOnboardingProgressStatuses,
    setFilteredOnboardingProgressStatuses,
  ] = useState(initialOnboardingProgressStatuses);

  const [orderBy, setOrderBy] = useLocalStorage<AthleteOrderBy>('adminOrderBy', AthleteOrderBy.CREATED)
  const [orderDir, setOrderDir] = useLocalStorage<OrderDirection>('adminOrderDir', OrderDirection.DESC)

  const changeOrder = (newOrder: AthleteOrderBy) => {
    if (orderBy === newOrder) {
      setOrderDir(orderDir === OrderDirection.ASC ? OrderDirection.DESC : OrderDirection.ASC)
    } else {
      setOrderBy(newOrder)
      setOrderDir(OrderDirection.DESC)
    }
  }

  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search).get("query");

  const [searchQuery, setSearchQuery] = useState(query || "");
  const [enableTestUsers, setEnableTestUsers] = useLocalStorage<boolean>('showTestUsers', false);

  useEffect(() => {
    setSearchQuery(query || "");
  }, [location]);

  useEffect(() => {
    setLocalOnboardingProgressStatus([
      ...filteredOnboardingProgressStatuses.map((status) => status.id),
    ]);
  }, [filteredOnboardingProgressStatuses]);

  const filters: Filter[] = [
    {
      id: "onboardingProgress",
      name: "Onboarding Progress",
      options: onboardingProgressStatuses,
    },
  ];

  function toggleTestUsers() {
    setEnableTestUsers(!enableTestUsers);
  }

  function toggleFilteredOnboardingProgressStatuses(status: number) {
    const currentStatuses = filteredOnboardingProgressStatuses.map(
      (status) => status.id
    );

    let currentFilteredOnboardingProgressStatuses =
      filteredOnboardingProgressStatuses;

    if (currentStatuses.includes(status)) {
      currentFilteredOnboardingProgressStatuses =
        filteredOnboardingProgressStatuses.filter(
          (currentStatus) => currentStatus.id !== status
        );
    } else {
      const newFilter: onboardingProgressStatus = {
        id: status,
        description: getOnboardingProgressDescription(status),
      };
      currentFilteredOnboardingProgressStatuses =
        currentFilteredOnboardingProgressStatuses.concat([newFilter]);

      currentFilteredOnboardingProgressStatuses.sort((a, b) => a.id - b.id);
    }

    setFilteredOnboardingProgressStatuses(
      currentFilteredOnboardingProgressStatuses
    );
  }

  function toggleSearch(searchQuery: string) {
    setSearchQuery(searchQuery);

    history.push('/admin?query=' + encodeURIComponent(searchQuery));
  }

  return {
    filters,
    filteredOnboardingProgressStatuses,
    searchQuery,
    toggleFilteredOnboardingProgressStatuses,
    toggleSearch,
    toggleTestUsers,
    enableTestUsers,
    orderBy,
    orderDir,
    changeOrder,
  };
}

export default useAdminFilter;
