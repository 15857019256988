import { CheckIcon } from "@heroicons/react/outline";
import { classNames } from "../../../Utilities/classnames";
import { Subaction } from "./useCalendarContextMenu";

interface SubactionInput {
  subkey: number;
  subAction: Subaction;
}

function SubAction({ subkey, subAction }: SubactionInput) {
  return (
    <p
      key={subkey}
      onClick={(e) => {
        e.preventDefault();
        subAction.action();
      }}
      className="relative flex items-center gap-2 py-2 pl-4 pr-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 hover:text-gray-900"
    >
      <CheckIcon
        className={classNames(
          subAction.isEnabled ? "block" : "invisible",
          "w-4 h-4 text-gray-500"
        )}
      />
      {subAction.name}
    </p>
  );
}

export default SubAction;
