import axios from "axios";

export async function getEmailPreviewContentForAthlete(
    emailPreviewUrl: string
): Promise<string | null> {

  try {
    const response = await axios.get(emailPreviewUrl);
    return response.data;
  } catch (error: any) {
    return null;
  }
}
