import { ActivityPoint } from "../Api/ActivityRoute";
import { TrainingEventType } from "../Api/TrainingEvent";
import { UnitPreference } from "../Api/User";
import { formatFullDuration } from "./DateUtilities";
/* eslint-disable */
const convert = require("convert-units");

export function formatDuration(time: number): string {
  if (!time) {
    return "";
  }

  const minutes = Math.floor(time / 60);
  let seconds: string | number = time - minutes * 60;

  if (seconds < 10) seconds = `0${seconds}`;

  return `${minutes}:${seconds}`;
}

// TODO: merge both formatDuration and decodeDuration

export function decodeDuration(duration: number): string {
  const minutes = Math.floor(duration / 60);

  let seconds: number | string = duration - minutes * 60;

  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
}

export function fancyDecodeDuration(duration: number): string {
  const h = Math.floor(duration / 3600);
  const m = Math.floor((duration % 3600) / 60);
  const s = Math.floor((duration % 3600) % 60);

  const hDisplay = h > 0 ? h + "h " : "";
  const mDisplay = m > 0 ? m + "m " : "";
  const sDisplay = s + "s";

  return hDisplay + mDisplay + sDisplay;
}

export function encodeDuration(duration: string): number {
  let seconds = 0;

  if (typeof duration === "string" && duration.indexOf(":") > -1) {
    const durationArray = duration.split(":");
    if (durationArray.length === 2) {
      seconds += parseInt(durationArray[0], 10) * 60;
      seconds += parseInt(durationArray[1], 10);
    }
  } else {
    seconds += parseInt(duration, 10) * 60;
  }

  return seconds;
}

export function encodeDistance(
  distance: string,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
): number {
  const parsedDistance = parseFloat(distance).toFixed(2);
  let distanceNumber = 0;

  if (unitPreference === UnitPreference.IMPERIAL) {
    distanceNumber = convert(parsedDistance).from("mi").to("m");
  } else {
    distanceNumber = convert(parsedDistance).from("km").to("m");
  }

  return distanceNumber;
}

export function formatAssignedAndActualDuration(
  assignedDuration: string,
  actualDuration: number
): string {
  let formattedAssignedDuration = "";
  let formattedActualDuration = "";

  if (assignedDuration && assignedDuration !== "0:00") {
    formattedAssignedDuration = assignedDuration;
  }

  if (actualDuration) {
    formattedActualDuration = `(${formatDuration(actualDuration)})`;
  }

  return `${formattedAssignedDuration} ${formattedActualDuration}`;
}

export function convertMinutesFloat(minutes: number): string {
  const seconds = Math.floor(minutes * 60);

  return formatDuration(seconds);
}

export function formatDistance(
  meters: number,
  unitPreference: UnitPreference,
  withUnit = false,
  toFeetOrMeters = false,
  rounded = false,
  fixed?: number
): string {
  let distance = meters;
  if (!meters) {
    return "";
  }

  let formattedUnit = "miles";

  if (unitPreference === UnitPreference.IMPERIAL) {
    if (toFeetOrMeters) {
      distance = convert(distance).from("m").to("ft");
      formattedUnit = "ft";
    } else {
      distance = convert(distance).from("m").to("mi");
    }
  } else {
    if (toFeetOrMeters) {
      formattedUnit = "m";
    } else {
      distance = convert(distance).from("m").to("km");
      formattedUnit = "km";
    }
  }

  let formattedDistance;

  if (rounded) {
    if (fixed) {
      formattedDistance = distance.toFixed(fixed);
    } else {
      formattedDistance = Math.round(distance);
    }
  } else {
    formattedDistance = distance.toFixed(2);
  }

  if (withUnit) {
    formattedDistance = `${formattedDistance} ${formattedUnit}`;
  }

  return formattedDistance.toString();
}

export function formatDistanceForAthletePage(
  meters: number,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL,
  withUnit = false,
  toFeetOrMeters = false,
  rounded = false,
  fixed?: number,
  longUnit?: boolean
): string {
  let string = formatDistance(
    meters,
    unitPreference,
    withUnit,
    toFeetOrMeters,
    rounded,
    fixed
  );

  if (string === "") {
    if (unitPreference === UnitPreference.IMPERIAL) {
      string = withUnit ? "0 miles" : "0";
    } else {
      string = withUnit ? "0 km" : "0";
    }
  }

  if (!longUnit) string = string.replace("miles", "mi");

  return string;
}

export function formatCalendarDistance(
  meters: number,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
): string {
  let distance = meters;

  if (!meters) {
    return "";
  }

  let formattedUnit = "mi";

  if (unitPreference === UnitPreference.IMPERIAL) {
    distance = convert(distance).from("m").to("mi");
  } else {
    distance = convert(distance).from("m").to("km");
    formattedUnit = "km";
  }

  return `${distance.toFixed(1)}${formattedUnit}`;
}

export function formatAssignedAndActualDistance(
  assignedMeters: number,
  actualMeters: number,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL,
  withUnit = false,
  toFeetOrMeters = false,
  rounded = false
): string {
  let formattedAssignedDistance = "";
  let formattedActualDistance = "";

  if (assignedMeters) {
    formattedAssignedDistance = formatDistance(
      assignedMeters,
      unitPreference,
      withUnit,
      toFeetOrMeters,
      rounded
    );
  }

  if (actualMeters) {
    formattedActualDistance = `(${formatDistance(
      actualMeters,
      unitPreference,
      withUnit,
      toFeetOrMeters,
      rounded
    )})`;
  }

  return `${formattedAssignedDistance} ${formattedActualDistance}`;
}

export function formatPace(
  meters: number,
  seconds: number,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
): string {
  let { paceMinutes, paceSeconds } = formatPaceToMinutesAndSeconds(
    meters,
    seconds,
    unitPreference
  );
  let paceSecondsString = paceSeconds + "";
  if (paceSeconds < 10) {
    paceSecondsString = `0${paceSeconds}`;
  }

  return paceMinutes + ":" + paceSecondsString;
}

export function formatPaceToMinutesAndSeconds(
  meters: number,
  seconds: number,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
) {
  if (!meters || !seconds || seconds === 0) {
    return { paceMinutes: 0, paceSeconds: 0 };
  }

  const speed = 1.0 / (meters / seconds);
  let localizedPace;
  if (unitPreference === UnitPreference.IMPERIAL) {
    localizedPace = convert(speed).from("s/m").to("min/mi");
  } else {
    localizedPace = convert(speed).from("s/m").to("min/km");
  }
  const paceMinutes = Math.floor(localizedPace);
  let paceSeconds: number | string = Math.floor(
    (localizedPace - paceMinutes) * 60
  );

  return { paceMinutes: paceMinutes, paceSeconds: paceSeconds };
}

export function formatMinutesAndSecondsToMetricVelocity(
  paceMinutes: number,
  paceSeconds: number,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
): number {
  if (!paceMinutes && !paceSeconds) {
    return 0;
  }

  let metricVelocity;
  if (unitPreference === UnitPreference.IMPERIAL) {
    metricVelocity =
      (1 /
        ((paceMinutes ?? 0.0000000001) / 60 +
          (paceSeconds ?? 0.0000000001) / 60 / 60)) *
      0.44704;
  } else {
    metricVelocity =
      (1 /
        ((paceMinutes ?? 0.0000000001) / 60 +
          (paceSeconds ?? 0.0000000001) / 60 / 60)) *
      0.277778;
  }

  return metricVelocity;
}

export function velocityToLocalizedPace(
  metersPerSecond: number,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
) {
  return formatPace(metersPerSecond, 1, unitPreference);
}

export function formatAssignedAndActualPace(
  assignedMeters: number,
  assignedSeconds: number,
  actualMeters: number,
  actualSeconds: number,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
): string {
  let assignedPace = formatPace(
    assignedMeters,
    assignedSeconds,
    unitPreference
  );

  let actualPace = `(${formatPace(
    actualMeters,
    actualSeconds,
    unitPreference
  )})`;

  if (assignedPace === "") {
    assignedPace = "";
  }

  if (actualPace === "()") {
    actualPace = "";
  }

  return `${assignedPace} ${actualPace}`;
}

export function getPace(
  speed: number,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
): number {
  if (speed === 0) {
    return 0;
  }

  const inverseSpeed = 1 / speed;
  let localizedPace;
  if (unitPreference === UnitPreference.IMPERIAL) {
    localizedPace = convert(inverseSpeed).from("s/m").to("min/mi");
  } else {
    localizedPace = convert(inverseSpeed).from("s/m").to("min/km");
  }

  return localizedPace;
}
export function getPaceUnit(
  metricSetting: UnitPreference = UnitPreference.IMPERIAL
) {
  if (metricSetting == UnitPreference.METRIC) {
    return "/km";
  }

  return "/mi";
}

export function formatSpeed(
  speed: number,
  metricSetting: UnitPreference = UnitPreference.IMPERIAL
): string {
  let localizedSpeed: number;
  let metric = "mps";

  if (metricSetting === UnitPreference.IMPERIAL) {
    metric = "fps";
    localizedSpeed = convert(speed).from("m/s").to("ft/s");
  } else {
    localizedSpeed = convert(speed).from("m/s").to("min/km");
  }

  return `${localizedSpeed.toFixed(1)} ${metric}`;
}

export function formatPaceFromSpeed(
  speed: number,
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
) {
  if (!speed || speed === 0) {
    return "";
  }

  const localizedPace = getPace(speed, unitPreference);

  const paceMinutes = Math.floor(localizedPace);
  let paceSeconds: number | string = Math.floor(
    (localizedPace - paceMinutes) * 60
  );

  if (paceSeconds < 10) {
    paceSeconds = `0${paceSeconds}`;
  }

  return paceMinutes + ":" + paceSeconds;
}

export function formatNetElevation(
  elevationGain: number | null,
  points: ActivityPoint[],
  unitPreference: UnitPreference = UnitPreference.IMPERIAL
): string {
  let netElevation = 0;
  if (elevationGain != null) {
    netElevation = elevationGain;
  } else {
    if (
      points &&
      points.length > 0 &&
      points[0].altitude &&
      points[points.length - 1].altitude
    ) {
      netElevation = points[points.length - 1].altitude! - points[0].altitude;
    }
  }

  return `${formatDistance(netElevation, unitPreference, true, true)}`;
}

interface GraphData extends Record<string, any> {
  time: string;
  pace?: number;
  heartRate?: number;
  cadence?: number;
}

export function formatDataPoints(
  points: ActivityPoint[],
  unitPreference: UnitPreference = UnitPreference.IMPERIAL,
  includePace = true,
  includeHeartRate = false,
  includeCadence = false
): Object[] | undefined {
  const formattedDataPoints: Object[] = [];

  if (points[0] === undefined) return undefined;

  let timeThreshold = 0;
  const initialTime = points[0].time;

  for (let i = 0; i < points.length; i++) {
    if (points[i] === undefined) return undefined;

    if (points[i].time > timeThreshold) {
      const formattedDataPoint: GraphData = {
        time: formatFullDuration(points[i].time - initialTime),
      };

      if (includePace && points[i].speed)
        formattedDataPoint.pace = getPace(points[i].speed!, unitPreference);

      if (includeHeartRate && points[i].heartRate)
        formattedDataPoint.heartRate = points[i].heartRate!;

      if (includeCadence && points[i].cadence)
        formattedDataPoint.cadence = points[i].cadence!;

      formattedDataPoints.push(formattedDataPoint);

      timeThreshold = points[i].time + 30;
    }
  }

  return formattedDataPoints;
}

// export function formatCalendarDuration(
//   event: trainingEvent,
//   metricSetting: UnitPreference = UnitPreference.IMPERIAL
// ) {}
// min / mile

export function formatTrainingEventType(type: TrainingEventType): string {
  const typeString = TrainingEventType[type];
  return titleCase(typeString).replaceAll("_", " ");
}

export function convertSecondsToHourFloat(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60) * 10;

  return `${hours}.${Math.round(minutes / 60)}`;
}

export function titleCase(string: string) {
  return (string[0].toUpperCase() + string.slice(1).toLowerCase()).replace(
    "_",
    " "
  );
}

export function createMarkup(content: string) {
  return { __html: content.replaceAll(/\n/g, "<br />") };
}
