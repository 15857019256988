import { useContext } from "react";
import { UnitPreference } from "../../../Api/User";
import { UserInfoContext } from "../../../Context/UserContext";
import {
  encodeDistance,
  encodeDuration,
  formatPace,
} from "../../../Utilities/FormatUtilties";

export default function AssignedPaceDisplay({
  assignedDistance,
  assignedDuration,
}: {
  assignedDistance: string;
  assignedDuration: string;
}) {
  const { userInfo } = useContext(UserInfoContext);
  const metricSetting =
    userInfo?.profileMetricSetting ?? UnitPreference.IMPERIAL;

  let paceString = "-";

  if (assignedDistance && assignedDuration) {
    const distance = encodeDistance(assignedDistance, metricSetting);
    const duration = encodeDuration(assignedDuration);

    if (distance > 0 && duration > 0) {
      paceString = formatPace(distance, duration, metricSetting);
    }
  }

  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="assigned-duration"
        className="block text-sm font-medium text-gray-700"
      >
        Assigned pace
      </label>
      <div className="mt-1">
        <p className="block w-full pt-2 pl-2 border-gray-300 rounded-md focus:ring-brand focus:border-brand sm:text-sm">
          {paceString}
        </p>
      </div>
    </div>
  );
}
