import axios from "axios";
import {App} from "@capacitor/app";
import {isPlatform} from "@ionic/react";

export type TrackingEvent = {
  event: TrackingEventType;
  value: string | null;
  channel: string;
};

export enum TrackingEventType {
  TRACKING_APP_WEB_ONBOARDING = "TRACKING_APP_WEB_ONBOARDING",
  TRACKING_APP_WEB_FREE_USER_ATTEMPTED_TO_CONNECT_OTHER_ACCOUNTS = "TRACKING_APP_WEB_FREE_USER_ATTEMPTED_TO_CONNECT_OTHER_ACCOUNTS",
  TRACKING_APP_WEB_SCHEDULED_EVENT = "TRACKING_APP_WEB_SCHEDULED_EVENT",
  TRACKING_APP_WEB_SIGN_UP = "TRACKING_APP_WEB_SIGN_UP",
  TRACKING_APP_WEB_ATHLETE_DASHBOARD = "TRACKING_APP_WEB_ATHLETE_DASHBOARD",
  TRACKING_APP_WEB_IOS_LINK = "TRACKING_APP_WEB_IOS_LINK",
  TRACKING_APP_WEB_ANDROID_LINK = "TRACKING_APP_WEB_ANDROID_LINK",
  TRACKING_APP_WEB_PUSH_NOTIFICATION = "TRACKING_APP_WEB_PUSH_NOTIFICATION",
  TRACKING_APP_GO_TO_CHECKOUT = "TRACKING_APP_GO_TO_CHECKOUT",
  TRACKING_APP_COACH_SUBMIT_COMMENT = "TRACKING_APP_COACH_SUBMIT_COMMENT",
  TRACKING_APP_WEB_UPGRADE = "TRACKING_APP_WEB_UPGRADE",
  TRACKING_APP_WEB_UPGRADE_CORE = "TRACKING_APP_WEB_UPGRADE_CORE",
  TRACKING_APP_WEB_UPGRADE_PLUS = "TRACKING_APP_WEB_UPGRADE_PLUS",
  TRACKING_APP_WEB_DOWNLOAD_INVOICE = "TRACKING_APP_WEB_DOWNLOAD_INVOICE",
}

export async function track(
  event: TrackingEventType,
  value: string | null,
  channel = "landing-page-events"
): Promise<object | null> {
  try {
    if (window.location.href.includes("local.")) {
      return null;
    }
    if (window.location.href.includes("https://localhost")) {
      return null;
    }
    if (window.location.href.includes("capacitor") && value) {
      value = value?.replace("capacitor://localhost", "");
      const info = await App.getInfo();
      value =
        value +
        " (" +
        (isPlatform("capacitor") && isPlatform("ios") ? "iOS" : "Android") +
        ", v: " +
        info.version +
        ", build: " +
        info.build +
        ")";
    }
    const trackingEvent: TrackingEvent = {
      event: event,
      value: value,
      channel: channel,
    };
    const response = await axios.post("track", trackingEvent);

    return response.data;
  } catch (error: any) {
    return null;
  }
}
