import { IonPage, IonContent } from "@ionic/react";
import AppleHealthConnect from "../../../Assets/AppleHealthConnect.svg";
import SettingsSubheader from "../ProfileSettings/SettingsSubheader";

export function handleConnectHealthKit() {
  window.location.href = window.location.href + "#connect-hk";
}

export default function ConnectAppleHealth() {

  return (
    <IonPage>
      <SettingsSubheader title="Connect Apple Health" />
      <IonContent>
        <div className="p-4 bg-white border-t-1 border-neutral-100 flex flex-col h-full">
          <div className="flex-grow flex flex-col items-center justify-center">
            <img src={AppleHealthConnect} alt="connect to apple health" />
            <h2 className="font-semibold text-[24px] leading-[32px] tracking-[-0.02em] mb-[2px] mt-16">
              Connect to Apple Health
            </h2>
            <p className="text-[18px] text-center leading-[28px] text-neutral-500 mt-2">
              Connect Apple Health so Runzy can track your running and keep
              everything up to date in real time.
            </p>
          </div>
          <div className="flex-grow-0 flex justify-center items-end">
            <button
              onClick={(e) => {
                e.preventDefault();
                handleConnectHealthKit();
              }}
              className="w-full py-[10px] rounded-[200px] text-white text-[16px] font-medium bg-primary-500 disabled:bg-neutral-100 disabled:text-neutral-500 disabled:cursor-not-allowed"
            >
              Connect to Apple Health
            </button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
