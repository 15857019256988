import { ChevronRightIcon } from "@heroicons/react/outline";
import { classNames } from "../../../Utilities/classnames";
import Subaction from "./Subaction";
import { contextMenuOption } from "./useCalendarContextMenu";

interface SubactionsInput {
  option: contextMenuOption;
  overflowRef: React.MutableRefObject<null>;
  displayOnLeft: boolean;
  displayOnTop: boolean;
  currentFocusedOption: string;
}

function SubactionsContainer({
  option,
  overflowRef,
  displayOnLeft,
  displayOnTop,
  currentFocusedOption,
}: SubactionsInput) {
  return (
    <>
      {option.subActions && option.subActions.length > 0 && (
        <>
          <ChevronRightIcon className="w-4 h-4 text-gray-500" />
          <div
            id="context-menu"
            ref={overflowRef}
            className={classNames(
              displayOnLeft ? "right-full" : "left-full",
              displayOnTop ? "bottom-1/4" : "top-0",
              "absolute w-40 origin-top-left bg-white rounded-md shadow-lg  focus:outline-none"
            )}
          >
            {currentFocusedOption === option.name &&
              option.subActions.map((subAction, subkey) => (
                <Subaction subAction={subAction} subkey={subkey} />
              ))}
          </div>
        </>
      )}
    </>
  );
}

export default SubactionsContainer;
