import { Comment } from "../../Api/Stream";
import { StreamClient } from "getstream";
import { formatNotificationDate } from "../../Utilities/DateUtilities";
import { useLocation } from "react-router-dom";
import ActivityFeedbackCommentDropdown from "../Activity/Feedback/ActivityFeedbackCommentDropdown";
import useActivityFeedbackComment from "../Hooks/useActivityFeedbackComment";
import StreamEditBox from "./StreamEditBox";
import Lightbox from "react-image-lightbox";
import { useState } from "react";
import ProfilePicture from "../../Utilities/Components/ProfilePicture";

interface ActivityFeedbackCommentInput {
  comment: Comment;
  streamClient?: StreamClient;
  activateDeleteModal: (reactionId: string) => void;
}

function PostFeedbackComment({
  comment,
  streamClient,
  activateDeleteModal,
}: ActivityFeedbackCommentInput) {
  const location = useLocation();

  const {
    editMode,
    enableEditMode,
    saveComment,
    cancelEdit,
    commentContent,
    setCommentContent,
  } = useActivityFeedbackComment({
    comment: comment,
    streamClient: streamClient,
  });

  function generateNameForComment() {
    if (!streamClient) return comment.data.actorMiniProfile.name;
    if (location.pathname.includes("coach")) {
      if (streamClient.userId === comment.user_id) {
        return `Coach ${comment.data.actorMiniProfile.name.split(" ")[0]}`;
      }
    }

    if (!location.pathname.includes("coach")) {
      if (streamClient.userId !== comment.user_id) {
        return `Coach ${comment.data.actorMiniProfile.name.split(" ")[0]}`;
      }
    }

    return comment.data.actorMiniProfile.name;
  }

  const [lightBoxIndex, setLightBoxIndex] = useState<number>();
  const imageAttachments = comment.data.imageAttachments;

  function ImageModal() {
    if (lightBoxIndex === undefined || !imageAttachments) return null;

    return (
      <Lightbox
        closeLabel="Close"
        mainSrc={imageAttachments[lightBoxIndex]}
        nextSrc={
          imageAttachments[(lightBoxIndex + 1) % imageAttachments.length]
        }
        prevSrc={
          imageAttachments[
            (lightBoxIndex + imageAttachments.length - 1) %
              imageAttachments.length
          ]
        }
        onCloseRequest={() => setLightBoxIndex(undefined)}
        onMovePrevRequest={() =>
          setLightBoxIndex(
            (lightBoxIndex + imageAttachments.length - 1) %
              imageAttachments.length
          )
        }
        onMoveNextRequest={() =>
          setLightBoxIndex((lightBoxIndex + 1) % imageAttachments.length)
        }
      />
    );
  }

  function CommentImage({ imageAttachment }: { imageAttachment: string }) {
    if (!imageAttachments) return null;

    return (
      <img
        src={imageAttachment}
        alt="comment image"
        className="object-cover w-64 h-64 mr-3 rounded-md cursor-pointer"
        onClick={() => {
          setLightBoxIndex(imageAttachments.indexOf(imageAttachment));
        }}
      />
    );
  }

  function CommentImages() {
    if (!comment.data.imageAttachments) return null;

    return (
      <div className="flex items-center py-4 overflow-x-auto">
        {comment.data.imageAttachments.map((imageAttachment, index) => (
          <CommentImage key={index} imageAttachment={imageAttachment} />
        ))}
      </div>
    );
  }
  return (
    <>
      {streamClient && (
        <div className="relative pb-10">
          <span
            className="absolute w-px h-full -ml-px bg-gray-200 top-8 left-5"
            aria-hidden="true"
          />

          <div className="relative flex items-start space-x-3">
            <ProfilePicture
              url={comment.data.actorMiniProfile.profilePicture}
              classNames="flex items-center justify-center bg-gray-400 w-9 h-9 rounded-xl ring-[12px] ring-white"
            />
            {editMode ? (
              <div className="flex-1 min-w-0">
                <StreamEditBox
                  text={commentContent}
                  onCancel={cancelEdit}
                  onSave={saveComment}
                  setText={setCommentContent}
                />
              </div>
            ) : (
              <>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div className="font-medium">
                        <p>{generateNameForComment()}</p>
                      </div>
                      <p className="text-xs text-gray-400">
                        {formatNotificationDate(
                          new Date(comment.created_at),
                          true
                        )}
                      </p>
                      {comment.data.isEdited && (
                        <p className="text-xs font-thin text-gray-400">
                          &#183; Edited
                        </p>
                      )}
                    </div>
                    {comment.user_id === streamClient.userId && (
                      <ActivityFeedbackCommentDropdown
                        enableEditMode={
                          comment.user_id === streamClient.userId
                            ? enableEditMode
                            : undefined
                        }
                        activateDeleteModal={
                          comment.user_id === streamClient.userId
                            ? activateDeleteModal
                            : undefined
                        }
                        comment={comment}
                      />
                    )}
                  </div>
                  <p className="text-sm whitespace-pre-line ">
                    {commentContent}
                  </p>
                  <CommentImages />
                  <ImageModal />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default PostFeedbackComment;
