import {TrainingStats} from "../../../../Api/TrainingProfile";
import Shoe from "../../../../Assets/Shoe.svg";
import {useEffect} from "react";
import {AppRate, AppRateReviewTypeAndroid, AppRateReviewTypeIos} from '@awesome-cordova-plugins/app-rate';
import {isPlatform} from "@ionic/react";
import { useLocalStorage } from "usehooks-ts";
function Runs({ stats }: { stats: TrainingStats }) {
  const hasBeenPromptedToReviewKey = "hasBeenPromptedToReview";
  const [hasBeenPromptedToReview, setHasBeenPromptedToReview] = useLocalStorage<
      string | undefined
      >(hasBeenPromptedToReviewKey, undefined);

  useEffect( () => {

    let mounted = true;

    if (mounted && isPlatform("capacitor")
        && (isPlatform("ios") || isPlatform("android"))) {
      if (stats.totalRuns >= 14 && !hasBeenPromptedToReview) {
        AppRate.setPreferences({
          reviewType: {
            ios: AppRateReviewTypeIos.InAppReview,
            android: AppRateReviewTypeAndroid.InAppBrowser,
          },
          callbacks: {
            onRateDialogShow: () => {
              setHasBeenPromptedToReview(Date().toString());
            }
          },
          customLocale: {
            appRatePromptTitle: "Are you enjoying Perform?",
            message: "It won't take more than a minute and helps promote Perform. Thanks for your support!",
          }
        });
        AppRate.promptForRating();
      }
    }

    return () => {
      mounted = false;
    };
  }, [stats, hasBeenPromptedToReview]);

  return (
    <div className="text-center">
      <img src={Shoe} alt="shoe" />
      <p className="font-semibold text-[#222222] text-xl">{stats?.totalRuns}</p>
      <p className="text-[#717171] text-[11px] font-semibold">
        {stats?.totalRuns === 1 ? "Run" : "Runs"}
      </p>
    </div>
  );
}

export default Runs;
