import axios from "axios";

export type PaceZone = {
  zone: number,
  averageSpeed: number
};

export async function getPaceZones(
  duration: number,
  distance: number,
): Promise<PaceZone[] | null> {
  try {
    const response = await axios.post(
      `pace-zones`,
        {
          duration: duration,
          distance: distance,
        }
    );

    return response.data;
  } catch (error: any) {
    return null;
  }
}

