import { IonContent, IonPage, IonToast } from "@ionic/react";
import { useEffect, useState } from "react";
import { TrainingStats } from "../../Api/TrainingProfile";
import { getStats } from "../../Api/TrainingStats";
import { isMobile } from "../../Utilities/isMobile";
import CoacheeHeader from "../Components/CoacheeHeader";

import MobileHeader from "./Header";
import Overview from "./Overview/Overview";
// import Referral from "./Referral";
import LastSevenDays from "./Stats/LastSevenDays";
import LastTwentyEightDays from "./Stats/LastTwentyEightDays";
import "./toast.css";
import Upgrade from "./Upgrade";
import { isMobileEmbeddedView } from "../../Api/Auth";
import DefaultWebFooter from "../../Coach/Components/Footer/DefaultWebFooter";

function Profile() {
  const [hasScrolled, setHasScrolled] = useState(false);

  const [stats, setStats] = useState<TrainingStats>();
  const [toast, setToast] = useState<string>();

  async function initStats() {
    const response = await getStats();

    if (response) {
      setStats(response);
    }
  }

  useEffect(() => {
    initStats();
  }, []);

  return (
    <IonPage>
      {isMobile ? (
        <MobileHeader hasScrolled={hasScrolled} />
      ) : (
        <CoacheeHeader />
      )}

      <IonContent
        scrollEvents={true}
        onIonScroll={(e) => setHasScrolled(e.detail.scrollTop !== 0)}
      >
        <IonToast
          isOpen={toast !== undefined}
          duration={3000}
          message={toast}
          position="top"
          color="dark"
          onDidDismiss={() => setToast(undefined)}
          cssClass="toastPosition"
        />
        <div className="max-w-xl px-4 mx-auto mt-4 lg:mt-20 sm:px-6 lg:px-8">
          {!isMobile && !isMobileEmbeddedView() && (
            <h1 className="text-[28px] ml-4 mb-8 font-semibold leading-tight">
              Profile
            </h1>
          )}
          {stats && (
            <>
              <div className="pb-4 mx-2 border-b border-race-50">
                <Overview stats={stats} />
              </div>
              <div className="mt-6">
                <LastSevenDays stats={stats} />
                <LastTwentyEightDays stats={stats} />
              </div>
              {/*<Referral toast={toast} setToast={setToast} />*/}
            </>
          )}
          {/* this part should be rework because design is bad */}
          {/* <Upgrade /> */}
        </div>
        {!isMobile && <DefaultWebFooter />}
      </IonContent>
    </IonPage>
  );
}

export default Profile;
