import React from "react";
import Tooltip from "../../../Utilities/Components/Tooltip";
import {
  ExclamationCircleIcon,
  SwitchHorizontalIcon,
} from "@heroicons/react/solid";
import { dateIsOnBlockedDay } from "../../../Utilities/DateUtilities";
import { trainingProfile } from "../../../Api/TrainingProfile";
import { CalendarDay } from "../../Calendar";

//this is div with time and tooltips

interface CalendarTooltipProps {
  day: CalendarDay;
  selectedTrainingProfile: trainingProfile;
}

export const CalendarTooltips = ({
  day,
  selectedTrainingProfile,
}: CalendarTooltipProps) => {
  // function hasWarning() {
  //   if (
  //     selectedTrainingProfile.trainingAttribute &&
  //     day.event &&
  //     (isDateToday(day.event.scheduledAt) ||
  //       dateIsTomorrowOrAfter(day.event.scheduledAt)) &&
  //     dateIsOnBlockedDay(
  //       day.event.scheduledAt,
  //       selectedTrainingProfile.trainingAttribute
  //     ) &&
  //     ![TrainingEventType.CROSS_TRAINING, TrainingEventType.REST].includes(
  //       day.event.trainingEventType
  //     )
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }
  function hasBeenAutoMoved() {
    if (day.event && day.event.wasAutoMoved) return true;

    return false;
  }

  function hasBeenMovedByAthlete() {
    if (day.event && day.event.wasMovedByAthlete) return true;

    return false;
  }

  return (
    <div className="flex items-center justify-between">
      <div>
        <div className="flex items-center gap-1">
          {hasBeenAutoMoved() && (
            <Tooltip
              content={
                <p>
                  This event was automatically swapped with another event in the
                  same week.
                </p>
              }
              display={<SwitchHorizontalIcon className="w-4 h-4 text-black" />}
            />
          )}
          {hasBeenMovedByAthlete() && (
            <Tooltip
              content={<p>This event was manually moved by the athlete.</p>}
              display={<SwitchHorizontalIcon className="w-4 h-4 text-black" />}
            />
          )}
          {/*{hasWarning() && (*/}
          {day.event &&
            dateIsOnBlockedDay(
              day.event.scheduledAt,
              selectedTrainingProfile.trainingAttribute
            ) && (
              <Tooltip
                content={<p>This workout is scheduled on a blocked day.</p>}
                display={
                  <ExclamationCircleIcon className="w-4 h-4 text-yellow-500" />
                }
              />
            )}
        </div>
      </div>
    </div>
  );
};
