import {
  EditableStep,
  getEnumAsString,
  getEnumValues,
  getGenericZoneName,
  getStepTypeName,
  StepType,
  TrainingEventStepType,
  TrainingEventStepTypeValues,
} from "../../../../Api/Step";
import {useEffect, useState} from "react";
import CancelButton from "./CancelButton";
import SaveButton from "./SaveButton";
import EditorDropdown from "./EditorDropdown";
import {encodeDistance, formatDistance,} from "../../../../Utilities/FormatUtilties";
import {useUserInfo} from "../../../../Context/UserContext";
import SmartField from "../../../Calendar/Input/SmartField";
import ValueField from "./ValueField";
import {trainingProfile} from "../../../../Api/TrainingProfile";
import {debounce} from 'lodash';

export default function StepEditor({
  step,
  index,
  onCancel,
  onSave,
  isSubStep,
  saveSubStep,
  cancelSubStep,
}: // selectedTrainingProfile,
{
  step: EditableStep;
  index: number;
  onCancel?: (index: number, step: EditableStep) => void;
  onSave?: (index: number, step: EditableStep) => void;
  isSubStep?: boolean;
  saveSubStep?: (index: number, step: EditableStep) => void;
  cancelSubStep?: (index: number, step: EditableStep) => void;
  selectedTrainingProfile?: trainingProfile;
}) {
  const { userInfo } = useUserInfo();
  const [editingStep, setEditingStep] = useState<EditableStep>(step);
  const [editorType, setEditorType] = useState<StepType>(
    step.assignedDistance ? StepType.DISTANCE : StepType.DURATION
  );
  const [editorValue, setEditorValue] = useState<string>("");

  const saveMethod = isSubStep ? saveSubStep : onSave;
  const cancelMethod = isSubStep ? cancelSubStep : onCancel;

  useEffect(() => {
    if (!userInfo) return;

    if (editorType === StepType.DISTANCE) {
      const distance = formatDistance(
        editingStep.assignedDistance ?? 0,
        userInfo.profileMetricSetting,
        false,
        false,
        true,
        2
      );
      setEditorValue(distance);
    } else {
      const totalSeconds = editingStep.assignedDuration ?? 0;

      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
      const seconds = totalSeconds - hours * 3600 - minutes * 60;

      const duration = `${hours}:${minutes}:${seconds}`;

      setEditorValue(duration);
    }
  }, [editorType, userInfo]);

  const getZoneString = (zone: number | undefined) => {
    if (zone === undefined) {
      return "-";
    }

    switch (zone) {
      case 1:
        return "Easy";
      case 2:
        return "Easy Moderate";
      case 3:
        return "Medium";
      case 4:
        return "Medium Hard";
      case 5:
        return "Hard";
      default:
        return "-";
    }

    // return "Zone " + zone;
  };

  const editorDropdownOptions = [1, 2, 3, 4, 5].map((zone) =>
    getZoneString(zone)
  );
  // .concat(genericZoneNames);

  // const editorDropdownOptions = ["Easy", "Easy Moderate", "Medium", "Medium Hard", "Hard"];

  function getSelectedZone(num: number | undefined) {
    if (num === undefined) {
      return "-";
    }

    if (num < 6) {
      return getZoneString(num);
    }

    if (getGenericZoneName(num)) {
      return getGenericZoneName(num);
    }

    return "-";
  }

  function handleZoneChange(zone: string, isMin: boolean) {
    let currentZone = isMin
      ? editingStep.intensityMinZone
      : editingStep.intensityMaxZone;

    if (zone === "-") {
      currentZone = undefined;
    }

    currentZone =
      ["Easy", "Easy Moderate", "Medium", "Medium Hard", "Hard"].findIndex(
        (item) => item === zone
      ) + 1;

    // if (zone.startsWith("Zone")) {
    //   currentZone = parseInt(zone.split(" ")[1]);
    // } else {
    //   currentZone = getGenericZoneNumber(zone);
    // }

    if (isMin) {
      setEditingStep({
        ...editingStep,
        intensityMinZone: currentZone,
        intensityMaxZone: currentZone,
      });
    } else {
      setEditingStep({
        ...editingStep,
        intensityMaxZone: currentZone,
      });
    }
  }

  const saveStep = () => {
    if (!userInfo) return;

    let newStep: EditableStep = { ...editingStep };

    if (
      newStep.intensityMinZone === undefined &&
      newStep.intensityMaxZone !== undefined
    ) {
      newStep.intensityMinZone = newStep.intensityMaxZone;
      newStep.intensityMaxZone = undefined;
    }

    if (
      editingStep.intensityMaxZone !== undefined &&
      editingStep.intensityMinZone !== undefined &&
      editingStep.intensityMaxZone === editingStep.intensityMinZone &&
      (editingStep.intensityMinZoneFraction ?? 1) ===
        (editingStep.intensityMaxZoneFraction ?? 1)
    ) {
      newStep.intensityMaxZone = undefined;
      newStep.intensityMinZone = editingStep.intensityMinZone;
    }

    if (
      editingStep.intensityMaxZone !== undefined &&
      editingStep.intensityMinZone !== undefined &&
      editingStep.intensityMaxZone < editingStep.intensityMinZone
    ) {
      newStep.intensityMaxZone = editingStep.intensityMinZone;
      newStep.intensityMinZone = editingStep.intensityMaxZone;
    }

    // if steptype is either other or rest, we need to reset the intensity zones to undefined
    // and reset the intensity zone fractions to 1
    if (
      newStep.stepType === TrainingEventStepType.OTHER
      // || newStep.stepType === TrainingEventStepType.REST
    ) {
      newStep.intensityMinZone = undefined;
      newStep.intensityMaxZone = undefined;
      newStep.intensityMinZoneFraction = 1;
      newStep.intensityMaxZoneFraction = 1;
    }

    if (editorType === StepType.DISTANCE) {
      const distance = encodeDistance(
        editorValue,
        userInfo.profileMetricSetting
      );
      const duration = undefined;
      newStep = {
        ...newStep,
        assignedDistance: distance,
        assignedDuration: duration,
      };
      if (saveMethod) {
        saveMethod(index, newStep);
      }
    } else {
      const duration = editorValue.split(":").reduce((acc, time) => {
        return 60 * acc + +time;
      }, 0);

      const distance = undefined;
      newStep = {
        ...newStep,
        assignedDistance: distance,
        assignedDuration: duration,
      };
      if (saveMethod) {
        saveMethod(index, newStep);
      }
    }
  };

  const cancelStep = () => {
    if (cancelMethod) {
      cancelMethod(index, step);
    }
  };

  const changeStepType = (typeString: string) => {
    const type = TrainingEventStepTypeValues.find(
      (type) => getStepTypeName(type) === typeString
    );
    if (type) {
      setEditingStep({ ...editingStep, stepType: type });
      if (type !== TrainingEventStepType.WARMUP) {
        setEditingStep({ ...editingStep, stepType: type, stepOption: undefined });
      }
    }
  };

  const changeStepOption = (option: string) => {
    setEditingStep({ ...editingStep, stepOption: option, stepOtherOption: ''})
  }

  const WARMUP_OPTIONS_LIST = [
    "5 min Easy",
    "10 min Easy",
    "15 min Easy",
    "20 min Easy",
    "1 mile Easy",
    "2 miles Easy",
    "3 miles Easy",
    "1-2 km Easy",
    "2-3 km Easy",
    "10 min progressive",
    "15 min progressive",
    "1 mile progressive",
    "2 miles progressive",
    "2 km progressive",
    "3 km progressive",
    "Other",
  ];

  const DRILLS_OPTIONS_LIST = [
    // 'Leg swings',
    'Leg swings -Buttkicks',
    'Leg swings - Buttkicks - Heelkicks',
    'Knee circles - Leg swings - Buttkicks - Heelkicks',
    'Leg swings - Buttkicks - Heelkicks - Long skip',
    'Leg Swings - Buttkicks - Heelkicks - A skip - Long Skip',
    'Leg swings - Buttkicks - Heelkicks - A skip - B skip - Long skip',
    'Leg swings - Buttkicks - Heelkicks - A skip - B skip - High skip - Long skip',
    'Leg swings - Buttkicks - Heelkicks - A skip - B skip - High skip - Long skip - Bounding',
    'Buttkicks - High knees - Carioca - Long skip',
    'Buttkicks - High knees - Carioca - Straight leg running - Ice skaters - Long skip',
    'Other',
  ];
  const STRIDES_OPTIONS_LIST = [
    '1-2',
    '2-4',
    '4-6',
    '1',
    '2',
    '3',
    '4',
    'Other',
  ];
  const COOL_DOWN_OPTIONS_LIST = [
    '5 min Easy',
    '10 min Easy',
    '15 min Easy',
    '20 min Easy',
    '1 mile Easy',
    '2 miles Easy',
    '3 miles Easy',
    '1-2 km Easy',
    '2-3 km Easy',
    'Other',
  ];

  const handleChange = debounce((value: string) => {
    const valueFormatted = value.slice(3, -4);
    setEditingStep({ ...editingStep, stepOtherOption: `${valueFormatted}` });
  }, 300);

  return (
    <div className="bg-white rounded-[6px] px-6 pt-6 pb-8 border-neutral-300 border">
      <div className="flex flex-col space-y-2">
        <label
          htmlFor="stepType"
          className="text-neutral-900 text-[14px] font-medium leading-[20px]"
        >
          Instruction Type
        </label>
        <EditorDropdown
          selected={getStepTypeName(editingStep.stepType)}
          options={TrainingEventStepTypeValues.map((type) =>
            getStepTypeName(type)
          )}
          onChange={changeStepType}
        />
      </div>

      {editingStep.stepType === TrainingEventStepType.WARMUP && (
        <div className="flex flex-col space-y-2 mt-4">
          <label
            htmlFor="stepType"
            className="text-neutral-900 text-[14px] font-medium leading-[20px]"
          >
            Warm-up options
          </label>
          <EditorDropdown
            selected={editingStep.stepOption || '-'}
            options={WARMUP_OPTIONS_LIST}
            onChange={changeStepOption}
          />
        </div>
      )}

      {editingStep.stepType === TrainingEventStepType.DRILLS && (
          <div className="flex flex-col space-y-2 mt-4">
            <label
                htmlFor="stepType"
                className="text-neutral-900 text-[14px] font-medium leading-[20px]"
            >
              Drills options
            </label>
            <EditorDropdown
                selected={editingStep.stepOption || '-'}
                options={DRILLS_OPTIONS_LIST}
                onChange={changeStepOption}
            />
          </div>
      )}

      {editingStep.stepType === TrainingEventStepType.STRIDES && (
          <div className="flex flex-col space-y-2 mt-4">
            <label
                htmlFor="stepType"
                className="text-neutral-900 text-[14px] font-medium leading-[20px]"
            >
              Strides options
            </label>
            <EditorDropdown
                selected={editingStep.stepOption || '-'}
                options={STRIDES_OPTIONS_LIST}
                onChange={changeStepOption}
            />
          </div>
      )}

      {editingStep.stepType === TrainingEventStepType.COOL_DOWN && (
          <div className="flex flex-col space-y-2 mt-4">
            <label
                htmlFor="stepType"
                className="text-neutral-900 text-[14px] font-medium leading-[20px]"
            >
              Cool-down options
            </label>
            <EditorDropdown
                selected={editingStep.stepOption || '-'}
                options={COOL_DOWN_OPTIONS_LIST}
                onChange={changeStepOption}
            />
          </div>
      )}

      {editingStep.stepOption === 'Other' &&
        <div className="flex flex-col space-y-2 mt-4">
          <label
              htmlFor="stepType"
              className="text-neutral-900 text-[14px] font-medium leading-[20px]"
          >
            Other text
          </label>
          <div className="relative">
            <SmartField
                className="block w-full border border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                value={editingStep.stepOtherOption ?? ""}
                onChange={handleChange}
            />
          </div>
        </div>
      }

      <div className="flex flex-col space-y-2 mt-4">
        <label
          htmlFor="stepType"
          className="text-neutral-900 text-[14px] font-medium leading-[20px]"
        >
          Type
        </label>
        <EditorDropdown
          selected={getEnumAsString(StepType, editorType)}
          options={
            editingStep.stepType === TrainingEventStepType.OTHER
              ? // || editingStep.stepType === TrainingEventStepType.REST
                [StepType.DURATION]
              : getEnumValues(StepType)
          }
          onChange={(type) => setEditorType(type as StepType)}
        />
      </div>

      <ValueField
        value={editorValue}
        onChange={setEditorValue}
        editorType={editorType}
      />

      {editingStep.stepType !== TrainingEventStepType.OTHER ? (
        // && editingStep.stepType !== TrainingEventStepType.REST
        <>
          <h2 className="mt-4 font-semibold text-neutral-900 text-[16px] leading-[24px]">
            Intensity Target
          </h2>
          <div className="w-full flex items-center gap-[10px] mt-2">
            <div className="flex flex-col space-y-2 flex-grow">
              {/*<label*/}
              {/*  htmlFor="stepType"*/}
              {/*  className="text-neutral-900 text-[14px] font-medium leading-[20px]"*/}
              {/*>*/}
              {/*  Min*/}
              {/*</label>*/}
              <div className="flex items-center flex-grow gap-[10px]">
                <div className="flex-grow shrink-0">
                  <EditorDropdown
                    selected={getSelectedZone(editingStep.intensityMinZone)}
                    options={editorDropdownOptions}
                    onChange={(zone) => {
                      handleZoneChange(zone, true);
                    }}
                  />
                </div>

                {/*{editingStep.intensityMinZone !== undefined &&*/}
                {/*getGenericZoneName(editingStep.intensityMinZone) ===*/}
                {/*  undefined ? (*/}
                {/*  <div className="relative py-2 pl-3 pr-[10px] text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer disabled:cursor-not-allowed focus:outline-none focus:ring-1 focus:ring-brand focus:border-brand sm:text-sm">*/}
                {/*    <input*/}
                {/*      // prevent scrolling from changing the value*/}
                {/*      onWheel={(e) => e.currentTarget.blur()}*/}
                {/*      id="minZoneFraction"*/}
                {/*      type="number"*/}
                {/*      className="border-0 text-neutral-900 text-[14px] leading-[20px] bg-transparent p-0 focus:ring-0"*/}
                {/*      value={*/}
                {/*        editingStep.intensityMinZoneFraction !== undefined*/}
                {/*          ? Math.round(*/}
                {/*              editingStep.intensityMinZoneFraction * 100*/}
                {/*            )*/}
                {/*          : 100*/}
                {/*      }*/}
                {/*      onChange={(e) => {*/}
                {/*        const fraction = parseInt(e.target.value) / 100;*/}

                {/*        if (fraction < 0 || fraction > 1.5) {*/}
                {/*          return;*/}
                {/*        }*/}

                {/*        setEditingStep({*/}
                {/*          ...editingStep,*/}
                {/*          intensityMinZoneFraction: fraction,*/}
                {/*        });*/}
                {/*      }}*/}
                {/*      max={150}*/}
                {/*      min={0}*/}
                {/*      placeholder="100"*/}
                {/*    />*/}
                {/*    <span*/}
                {/*      onClick={() => {*/}
                {/*        const input =*/}
                {/*          document.getElementById("minZoneFraction");*/}
                {/*        if (input) {*/}
                {/*          input.focus();*/}
                {/*        }*/}
                {/*      }}*/}
                {/*      className="text-neutral-900 text-[14px] leading-[20px]"*/}
                {/*    >*/}
                {/*      %*/}
                {/*    </span>*/}
                {/*  </div>*/}
                {/*) : null}*/}
              </div>
            </div>
            {/*
            <div className="flex flex-col space-y-2 flex-grow">
              <label
                htmlFor="stepType"
                className="text-neutral-900 text-[14px] font-medium leading-[20px]"
              >
                Max
              </label>
              <div className="flex items-center flex-grow gap-[10px]">
                <div className="flex-grow">
                  <EditorDropdown
                    selected={getSelectedZone(editingStep.intensityMaxZone)}
                    options={editorDropdownOptions}
                    onChange={(zone) => {
                      handleZoneChange(zone, false);
                    }}
                  />
                </div>

                {editingStep.intensityMaxZone !== undefined &&
                getGenericZoneName(editingStep.intensityMaxZone) ===
                  undefined ? (
                  <div className="relative py-2 pl-3 pr-[10px] text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer disabled:cursor-not-allowed focus:outline-none focus:ring-1 focus:ring-brand focus:border-brand sm:text-sm">
                    <input
                      // prevent scrolling from changing the value
                      onWheel={(e) => e.currentTarget.blur()}
                      id="maxZoneFraction"
                      type="number"
                      className="border-0 text-neutral-900 text-[14px] leading-[20px] bg-transparent p-0 focus:ring-0"
                      value={
                        editingStep.intensityMaxZoneFraction !== undefined
                          ? Math.round(
                              editingStep.intensityMaxZoneFraction * 100
                            )
                          : 100
                      }
                      onChange={(e) => {
                        const fraction = parseInt(e.target.value) / 100;

                        if (fraction > 1.5 || fraction < 0) {
                          return;
                        }

                        setEditingStep({
                          ...editingStep,
                          intensityMaxZoneFraction: fraction,
                        });
                      }}
                      max={150}
                      min={0}
                      placeholder="100"
                    />
                    <span
                      onClick={() => {
                        const input =
                          document.getElementById("maxZoneFraction");
                        if (input) {
                          input.focus();
                        }
                      }}
                      className="text-neutral-900 text-[14px] leading-[20px]"
                    >
                      %
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            */}
          </div>
        </>
      ) : null}

      <div className="mt-4">
        <label
          htmlFor="stepType"
          className="text-neutral-900 text-[14px] font-medium leading-[20px]"
        >
          Note
        </label>
        <div className="relative">
          <SmartField
            className="block w-full border border-gray-300 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
            value={editingStep.description ?? ""}
            onChange={(value) => {
              setEditingStep({ ...editingStep, description: value });
            }}
          />
        </div>
      </div>

      <div className="w-full flex justify-end space-x-1 mt-[18px]">
        <CancelButton onClick={cancelStep} />
        <SaveButton onClick={saveStep} />
      </div>
    </div>
  );
}
