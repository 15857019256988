import { XIcon } from "@heroicons/react/outline";
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { useEffect } from "react";
import { isMobileEmbeddedView } from "../../../Api/Auth";
import { track, TrackingEventType } from "../../../Api/Tracking";
import CoacheeHeader from "../../../Coachee/Components/CoacheeHeader";
import { classNames } from "../../../Utilities/classnames";
import { isMobile } from "../../../Utilities/isMobile";
import BasicCard from "./BasicCard";
import CoreCard from "./CoreCard";
import PlusCard from "./PlusCard";
import DefaultWebFooter from "../../../Coach/Components/Footer/DefaultWebFooter";

function Upgrade() {
  const ionRouter = useIonRouter();

  useEffect(() => {
    track(TrackingEventType.TRACKING_APP_WEB_UPGRADE, "Open upgrade screen");
  }, []);

  return (
    <IonPage>
      {!isMobile && <CoacheeHeader />}
      <IonContent>
        <div className="flex flex-col h-screen bg-runzy-red-orange overflow-y-hidden">
          <div
            className={classNames(
              isMobileEmbeddedView() ? "" : isMobile ? "pt-24" : "pt-16",
              "flex items-center justify-between px-4 md:mx-auto overflow-y-hidden"
            )}
          >
            {!isMobileEmbeddedView() && (
              <h1 className=" font-bold text-[24px] text-white">
                Reach your goals
              </h1>
            )}
            {!isMobileEmbeddedView() && (
              <XIcon
                onClick={() => {
                  ionRouter.push("/profile");
                }}
                className="w-6 h-6 text-white cursor-pointer md:hidden"
              />
            )}
          </div>

          <div className="flex items-center space-x-4 p-4 mt-2 overflow-x-scroll overflow-y-hidden md:overflow-x-auto md:justify-center snap-x snap-mandatory">
            <BasicCard /> <CoreCard /> <PlusCard />{" "}
          </div>
        </div>
      </IonContent>
      {!isMobile && <DefaultWebFooter />}
    </IonPage>
  );
}

export default Upgrade;
