import { TrainingStats } from "../../../Api/TrainingProfile";
import QuickInfo from "./QuickInfo";
import QuickStats from "./QuickStats/QuickStats";

function Overview({ stats }: { stats: TrainingStats }) {
  return (
    <>
      <QuickInfo />
      <QuickStats stats={stats} />
    </>
  );
}

export default Overview;
