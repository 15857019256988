import { getStepTypeName, Step } from "../../../../Api/Step";
import Buttons from "./Buttons";
import StepCells from "./StepCells";
import Linkify from "react-linkify";
import { useLocation } from "react-router-dom";
import * as DOMPurify from "dompurify";
import { trainingProfile } from "../../../../Api/TrainingProfile";

export default function StepDisplay({
  step,
  steps,
  onMoveUp,
  onMoveDown,
  onDelete,
  onEdit,
  canSwap,
  isInRepeat,
  selectedTrainingProfile,
}: {
  step: Step;
  steps: Step[];
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  canSwap: boolean;
  isInRepeat?: boolean;
  selectedTrainingProfile?: trainingProfile;
}) {
  const location = useLocation();

  const isCoachSide = location.pathname.includes("coach");

  const stepType = getStepTypeName(step.stepType);

  const modifiedDescription =
    step.description?.replace(/<a/g, '<a style="color: #3b82f6"') ?? "";

  const StepDescription = () => {
    const descriptionWithoutHtml = step.description?.replace(/<[^>]*>?/gm, "");

    if (
      step.description &&
      descriptionWithoutHtml &&
      descriptionWithoutHtml.length > 0
    ) {
      return (
        <Linkify>
          <p
            className="text-neutral-500 text-[12px] leading-[20px] mt-[2px]"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(modifiedDescription),
            }}
          />
        </Linkify>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="bg-white rounded-[6px] p-4 border-neutral-300 border">
      <div className="flex justify-between items-start">
        <div className="flex-grow space-y-2">
          <h1 className="font-semibold text-neutral-900 text-[12px] tracking-[1px] leading-[20px]">
            {stepType.toUpperCase()}
          </h1>
          <StepCells
            step={step}
            selectedTrainingProfile={selectedTrainingProfile}
          />
        </div>
        {isCoachSide && (
          <Buttons
            step={step}
            steps={steps}
            onDelete={onDelete}
            onMoveUp={onMoveUp}
            onMoveDown={onMoveDown}
            onEdit={onEdit}
            canSwap={canSwap}
            isInRepeat={isInRepeat}
          />
        )}
      </div>
      <StepDescription />
    </div>
  );
}
