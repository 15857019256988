import CommentsPlaceholder from "../../../Assets/CommentsPlaceholder.svg";

function NoCommentsDisplay({ isAthlete }: { isAthlete: boolean }) {
  return (
    <div className="flex flex-col items-center mb-16 text-center">
      <img src={CommentsPlaceholder} />
      <h2 className="mt-4 text-xl font-medium text-[#222222]">
        Talk with Your {isAthlete ? "Coach" : "Athlete"}
      </h2>
      <div className="mt-4 font-normal text-[#717171]">
        <p>{isAthlete ? "How did the run feel?" : "How did they do?"}</p>
        <p>Share thoughts with your {isAthlete ? "coach" : "athlete"}!</p>
      </div>
    </div>
  );
}

export default NoCommentsDisplay;
