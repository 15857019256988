import { PaperClipIcon } from "@heroicons/react/outline";
import { CalendarDay } from "../Calendar";
import ExtraActivity from "./ExtraActivity";

function SelectedDayMore({ selectedDay }: { selectedDay: CalendarDay }) {
  return (
    <div>
      {selectedDay.otherEvents &&
        selectedDay.otherEvents.map((otherEvent, index) => (
          <div key={index} className="mb-5">
            <ExtraActivity index={index} otherEvent={otherEvent} />
          </div>
        ))}
    </div>
  );
}

export default SelectedDayMore;
