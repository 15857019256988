// ignore eslint
/* eslint-disable */
import { useState } from "react";
import {
  assignedDistanceIsFormattedCorrectly,
  assignedDurationIsFormattedCorrectly,
  editTrainingEventInput,
  trainingEvent,
  TrainingEventStatus,
  TrainingEventType,
} from "../../Api/TrainingEvent";
import SaveEventButton from "./SaveEventButton";
import TextareaAutosize from "react-textarea-autosize";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import { getDisabledDaysFromAttributes } from "../../Utilities/DateUtilities";

import {
  encodeDistance,
  encodeDuration,
  formatAssignedAndActualDistance,
  formatAssignedAndActualDuration,
  formatAssignedAndActualPace,
  formatDistance,
  formatDuration,
  formatPace,
} from "../../Utilities/FormatUtilties";
import { UnitPreference } from "../../Api/User";
import Dropdown from "./TrainingEventDropdown";
import StatusTag from "./StatusTag";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import CancelEventButton from "./CancelEventButton";
import { useEffect } from "react";
import { trainingAttributes } from "../../Api/TrainingAttribute";
import { ActivityRoute, getActivityRoute } from "../../Api/ActivityRoute";
import DateCell from "./DateCell";
import TitleCell from "./TitleCell";
import DescriptionCell from "./DescriptionCell";
import NoteCell from "./NoteCell";
import WorkoutTypeTag from "./WorkoutTypeTag";
import "../updatedBubble.css";
import StatusDropdown from "./StatusDropdown";
import TypeDropdown from "./TypeDropdown";

interface TrainingEventProps {
  metricSetting: UnitPreference;
  event: trainingEvent;
  onEdit: Function;
  onDelete: Function;
  onShowActivityRoute: Function;
  trainingAttributes: trainingAttributes;
  defaultEditMode?: boolean;
  onDuplicate: Function;
}

function TrainingEvent({
  metricSetting,
  event,
  onEdit,
  onDelete,
  onShowActivityRoute,
  trainingAttributes,
  defaultEditMode = false,
  onDuplicate,
}: TrainingEventProps) {
  const [editMode, setEditMode] = useState<boolean>(defaultEditMode);
  const [currentScheduledAt, setCurrentScheduledAt] = useState<Date>(
    event.scheduledAt
  );
  const [currentTitle, setCurrentTitle] = useState<string>(event.title);
  const [currentDescription, setCurrentDescription] = useState<string[]>(
    event.description
  );
  const [currentMessage, setCurrentMessage] = useState<string>(event.message);
  const [currentWorkoutType, setCurrentWorkoutType] =
    useState<TrainingEventType>(event.trainingEventType);
  const [currentAssignedDuration, setCurrentAssignedDuration] =
    useState<string>(event.assignedDuration);
  const [currentAssignedDistance, setCurrentAssignedDistance] =
    useState<string>(event.assignedDistance);
  const [currentStatus, setCurrentStatus] = useState<TrainingEventStatus>(
    event.status
  );

  const [activityRoute, setActivityRoute] = useState<ActivityRoute | null>(
    null
  );
  function disableEditMode() {
    if (editMode) {
      if (assignedDurationIsFormattedCorrectly(currentAssignedDuration)) {
        if (assignedDistanceIsFormattedCorrectly(currentAssignedDistance)) {
          const payload: editTrainingEventInput = {
            description: currentDescription,
            eventId: event.id,
            profile: event.profile,
            scheduledAt: currentScheduledAt,
            status: currentStatus,
            title: currentTitle,
            message: currentMessage,
            trainingEventType: currentWorkoutType,
            assignedDistance: currentAssignedDistance,
            assignedDuration: currentAssignedDuration,
            unitPreference: metricSetting,
            postActivityFeedback: event.postActivityFeedback,
            postActivityFeedbackDidChange: false,
            steps: event.steps,
          };

          onEdit(payload);
          setEditMode(false);
        } else {
          alert("Please ensure your distance format is correct");
        }
      }
    } else {
      alert("Please follow MM:SS or MM format for time");
    }
  }

  async function openActivity() {
    let activityId = event.activity?.replace("perform:activity:", "");

    const response = await getActivityRoute(activityId!);

    if (response) {
      setActivityRoute(response);
      if (event.externalUrl) {
        onShowActivityRoute(response, event.externalUrl);
      } else {
        onShowActivityRoute(response);
      }
    }

    return;
  }

  function handleCancel() {
    if (editMode) {
      setCurrentScheduledAt(event.scheduledAt);
      setCurrentTitle(event.title);
      setCurrentDescription(event.description);
      setCurrentMessage(event.message);
      setCurrentAssignedDistance(event.assignedDistance);
      setCurrentAssignedDuration(event.assignedDuration);
      setEditMode(false);
      setCurrentStatus(event.status);
    }
  }

  function alertUser(e: any) {
    if (editMode) {
      let confirmation = window.confirm(
        `Are you sure you want to leave this page with unsaved changes?`
      );

      if (!confirmation) {
        e.preventDefault();
        e.returnValue = "";
      }
    }
  }

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  return (
    <tr className="font-tableHeader">
      {editMode ? (
        <>
          <td className="relative p-3">
            <DayPickerInput
              onDayChange={(day) => setCurrentScheduledAt(day)}
              value={currentScheduledAt}
              dayPickerProps={{
                modifiers: {
                  today: new Date(),
                  blockedDays: {
                    daysOfWeek:
                      getDisabledDaysFromAttributes(trainingAttributes),
                  },
                },
                modifiersStyles: {
                  today: {
                    color: "green",
                  },
                  blockedDays: {
                    color: "red",
                  },
                },
              }}
            />
          </td>
          <td className="p-3">
            <StatusDropdown
              selectedStatus={currentStatus}
              setSelected={setCurrentStatus}
              type={event.sportsType}
            />
          </td>
          <td className="p-3">
            {!event.id.includes("activity") && (
              <TypeDropdown
                selectedType={currentWorkoutType}
                setSelected={setCurrentWorkoutType}
              />
            )}
          </td>
          <td className="max-w-sm p-3">
            {!event.id.includes("activity") && (
              <TextareaAutosize
                autoFocus
                spellCheck
                className="w-full border rounded"
                value={currentTitle}
                onChange={(e) => setCurrentTitle(e.target.value)}
              />
            )}
          </td>
          <td className="max-w-sm p-3">
            {!event.id.includes("activity") && (
              <TextareaAutosize
                spellCheck
                className="w-full border rounded"
                value={currentDescription.join("$")}
                onChange={(e) =>
                  setCurrentDescription(e.target.value.split("$"))
                }
              />
            )}
          </td>
          <td className="max-w-sm p-3">
            {!event.id.includes("activity") && (
              <ReactQuill
                className="border rounded"
                theme="bubble"
                value={currentMessage}
                onChange={setCurrentMessage}
              />
            )}
          </td>
          <td className="py-3">
            {!event.id.includes("activity") && (
              <>
                <input
                  className="w-16 h-6 p-0 pl-1"
                  type="text"
                  value={currentAssignedDistance}
                  onChange={(e) => {
                    setCurrentAssignedDistance(e.target.value);
                  }}
                />{" "}
              </>
            )}

            <span className="text-xs">
              {formatDistance(event.actualDistance, metricSetting) !== "" &&
                `(${formatDistance(event.actualDistance, metricSetting)})`}
            </span>
          </td>
          <td className="p-3">
            {formatPace(event.actualDistance, event.actualDuration)}
          </td>
          <td className="py-3">
            {!event.id.includes("activity") && (
              <>
                <input
                  className="w-16 h-6 p-0 pl-1"
                  type="text"
                  value={currentAssignedDuration}
                  onChange={(e) => {
                    setCurrentAssignedDuration(e.target.value);
                  }}
                />{" "}
              </>
            )}
            <span className="text-xs">
              {formatDuration(event.actualDuration) !== "" &&
                `(${formatDuration(event.actualDuration)})`}
            </span>
          </td>
          <td></td>
          <td className="p-3 ">
            <div className="flex flex-col justify-center gap-2">
              <SaveEventButton onClick={() => disableEditMode()} />
              <CancelEventButton onClick={() => handleCancel()} />
            </div>
          </td>
        </>
      ) : (
        <>
          <DateCell
            currentScheduledAt={currentScheduledAt}
            event={event}
            trainingAttributes={trainingAttributes}
          />

          <td className="px-2 py-2 whitespace-nowrap">
            <StatusTag status={event.status} type={event.sportsType} />
          </td>
          <td className="px-2 py-2 whitespace-nowrap">
            {!event.id.includes("activity") && (
              <WorkoutTypeTag type={currentWorkoutType} />
            )}
          </td>
          <TitleCell currentTitle={currentTitle} event={event} />
          <DescriptionCell
            currentDescription={currentDescription}
            event={event}
          />

          <NoteCell currentMessage={currentMessage} event={event} />
          <td className="px-1 py-2 text-sm text-gray-600 2xl:px-4 whitespace-nowrap">
            {formatAssignedAndActualDistance(
              encodeDistance(currentAssignedDistance, metricSetting),
              event.actualDistance,
              metricSetting
            )}
          </td>
          <td className="px-1 py-2 text-sm text-gray-600 2xl:px-4 whitespace-nowrap">
            {formatAssignedAndActualPace(
              encodeDistance(currentAssignedDistance, metricSetting),
              encodeDuration(currentAssignedDuration),
              event.actualDistance,
              event.actualDuration,
              metricSetting
            )}
          </td>
          <td className="px-1 py-2 text-sm text-gray-600 2xl:px-4 whitespace-nowrap">
            {formatAssignedAndActualDuration(
              currentAssignedDuration,
              event.actualDuration
            )}
          </td>
          <td className="px-1 py-2 text-sm text-gray-400 cursor-pointer whitespace-nowrap">
            {event.activity ? (
              <>
                <a onClick={openActivity}>&nbsp;Details</a>
              </>
            ) : (
              <></>
            )}
          </td>
          <td className="px-2 py-2 whitespace-nowrap">
            <Dropdown
              onEdit={
                event.status !== TrainingEventStatus.UNSCHEDULED
                  ? (e: any) => {
                      setEditMode(!editMode);
                    }
                  : undefined
              }
              onDelete={(e: any) => {
                onDelete(event.id);
              }}
              onDuplicate={(e: any) => {
                onDuplicate(event);
              }}
            />
          </td>
        </>
      )}
    </tr>
  );
}

export default TrainingEvent;
