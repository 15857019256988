export default function DaysOfTheWeek() {
  return (
    <div className="grid grid-cols-7 gap-px text-xs font-semibold leading-6 text-center text-gray-700 bg-gray-200 border-b border-gray-300 lg:flex-none">
      <div className="py-2 bg-white">
        M<span className="sr-only sm:not-sr-only">on</span>
      </div>
      <div className="py-2 bg-white">
        T<span className="sr-only sm:not-sr-only">ue</span>
      </div>
      <div className="py-2 bg-white">
        W<span className="sr-only sm:not-sr-only">ed</span>
      </div>
      <div className="py-2 bg-white">
        T<span className="sr-only sm:not-sr-only">hu</span>
      </div>
      <div className="py-2 bg-white">
        F<span className="sr-only sm:not-sr-only">ri</span>
      </div>
      <div className="py-2 bg-white">
        S<span className="sr-only sm:not-sr-only">at</span>
      </div>
      <div className="py-2 bg-white">
        S<span className="sr-only sm:not-sr-only">un</span>
      </div>
      <div className="py-2 px-3 bg-white col-start-8 w-20">
        Total
      </div>
    </div>
  );
}
