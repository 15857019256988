/* eslint-disable */ 
// TODO @wbert - type this file

import axios from "axios";
import {FormValidation} from "./Common";
import {MiniProfile} from "./TrainingProfile";
import {resetAuth, setAuthToken} from "./Auth";
import {FEATURE_FLAGS} from "../Common/flags";

export type signInCredentials = {
  username: string;
  password: string;
};

export type SignUpCredentials = {
  username: string;
  password: string;
  source: string;
};

export interface UserInfo {
  id: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  token: string;
  profileMetricSetting: UnitPreference;
  featureFlags: string[];
  isTrainingAdmin: boolean;
  email: string;
  streamAPIAuth: string;
  miniProfile: MiniProfile;
  isStravaAuthenticated: boolean;
  isSpotifyAuthenticated: boolean;
  overrideActivityTitleSetting: string;
  overrideActivityBodySetting: string;
  enableDailyMixSetting: boolean;
}

export enum UnitPreference {
  IMPERIAL = "IMPERIAL",
  METRIC = "METRIC",
}

export enum RoleDescription {
  ADMIN = "Admin",
  COACH = "Coach",
  ATHLETE = "Athlete",
}

export async function signIn(
  signInCredentials: signInCredentials
): Promise<FormValidation | null> {
  try {
    const response = await axios.post("sign-in", signInCredentials);
    let formValidation: FormValidation = response.data;
    if (formValidation.token) {
      setAuthToken(formValidation.token);
    }
    return formValidation;
  } catch (error: any) {
    if (error.response.status === 403 && error.response.data) {
      return error.response.data;
    }
    return null;
  }
}

export async function requestResetPassword(
  email: string
): Promise<boolean | null> {
  try {
    const response = await axios.post("reset-password", {email: email});
    return true;
  } catch (error: any) {
    if (error.response.status === 403 && error.response.data) {
      return error.response.data;
    }
    return null;
  }
}

export async function signUp(
  signUpCredentials: SignUpCredentials
): Promise<FormValidation | null> {
  try {
    const response = await axios.post("sign-up", signUpCredentials);

    return response.data;
  } catch (error: any) {
    return null;
  }
}

export async function updatePassword(
  token: string,
  password: string
): Promise<FormValidation | null> {
  try {
    const response = await axios.post("sign-up", {
      token: token,
      password: password,
    });
    return response.data;
  } catch (error: any) {
    return null;
  }
}

export async function signUpWithTypeForm(
  typeFormResponseId: string
): Promise<FormValidation | null> {
  try {
    const response = await axios.post("sign-up", {
      createFromTypeFormResponseId: typeFormResponseId,
    });
    let formValidation: FormValidation = response.data;
    if (formValidation.token) {
      setAuthToken(formValidation.token);
    }
    return response.data;
  } catch (error: any) {
    return null;
  }
}

export async function signOut(): Promise<object | null> {
  try {
    const response = await axios.post("sign-out", {});
    resetAuth();
    return response;
  } catch (error: any) {
    return null;
  }
}

export function isCoach(userInfo: UserInfo): boolean {
  return userInfo.isTrainingAdmin;
}

export function isSuperAdmin(userInfo?: UserInfo|null): boolean {
  if (!userInfo) {
    return false;
  }
  return userInfo.featureFlags.includes(FEATURE_FLAGS.COACH_ADMIN);
}

export function isAICore(userInfo: UserInfo): boolean {
  return userInfo.featureFlags.includes(FEATURE_FLAGS.SOURCE_AI_CORE);
}

export function isMusicStandaloneUser(userInfo: UserInfo): boolean {
  return userInfo.featureFlags.includes(FEATURE_FLAGS.SOURCE_IOS_MUSIC);
}

export function roleDescription(userInfo: UserInfo): string {
  switch (true) {
    case isSuperAdmin(userInfo!):
      return RoleDescription.ADMIN
    case isCoach(userInfo):
      return RoleDescription.COACH
    default:
      return RoleDescription.ATHLETE
  }
}

export enum userInfoResponse {
  Success,
  Disconnect,
  Invalid,
}

export async function getUserInfo(): Promise<{
  status: userInfoResponse;
  userInfo?: UserInfo;
}> {
  try {
    const response = await axios.get("me");
    const userInfo = response.data;

    return await {
      status: userInfoResponse.Success,
      userInfo: userInfo,
    };
  } catch (error: any) {
    if (error.request && !error.response) {
      // this is where we detect offline
      return { status: userInfoResponse.Disconnect };
    }
    if (error.response.status === 403) {
      resetAuth();
    }
    return { status: userInfoResponse.Invalid };
  }
}

export async function initUserInfo(
  userInfo: UserInfo | null,
  setUserInfo: Function
) {
  if (userInfo) return;

  const response = await getUserInfo();

  if (response.userInfo) {
    setUserInfo(response.userInfo);
  }
}
