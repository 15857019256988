import RepeatIconBold from "../../../Assets/RepeatIconBold.svg";

export default function CreateRepeatStep({
  onClick,
  noText,
}: {
  onClick: () => void;
  noText?: boolean;
}) {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      // underline on hover
      className="bg-neutral-100 text-neutral-900 flex items-center rounded-full px-[10px] py-[6px] space-x-[11px] focus:outline-none focus:ring-0 font-medium text-[14px] leading-[20px] inline-flex space-x-[7px]"
    >
      <img src={RepeatIconBold} alt="" className="w-5 h-5  text-neutral-900" />
      {noText ? null : <p>Add a repeat</p>}
    </button>
  );
}
