import { ActivityRoute } from "../../Api/ActivityRoute";
import { trainingEvent } from "../../Api/TrainingEvent";
import { UnitPreference } from "../../Api/User";
import ModalGraph from "./ModalGraph";
import ModalInfo from "./ModalInfo";
import ModalSplits from "./ModalSplits";

interface EventDetailContents {
  event?: trainingEvent;
  stravaUrl?: string;
  metricSetting: UnitPreference;
  activityRoute: ActivityRoute | null;
}

function EventDetailContents({
  event,
  stravaUrl,
  metricSetting,
  activityRoute,
}: EventDetailContents) {
  return (
    <div className="flex flex-col self-center pt-8 pb-6 space-y-10 items">
      {stravaUrl ? (
        <a
          className="self-end mr-8 -mb-6 text-gray-400"
          target="_blank"
          href={stravaUrl}
        >
          Strava
        </a>
      ) : (
        <></>
      )}

      <ModalInfo
        sportsType={activityRoute?.activity?.activityType ?? event?.sportsType}
        metricSetting={metricSetting}
        activityRoute={activityRoute}
      />

      <ModalSplits
        metricSetting={metricSetting}
        activityRoute={activityRoute}
      />

      {activityRoute && (
        <ModalGraph
          metricSetting={metricSetting}
          activityRoutePoints={activityRoute.points}
        />
      )}
    </div>
  );
}

export default EventDetailContents;
