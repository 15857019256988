import { useState } from "react";
import { trainingAttributes } from "../../Api/TrainingAttribute";
import { trainingEvent, TrainingEventType } from "../../Api/TrainingEvent";
import { dateIsOnBlockedDay, formatDate } from "../../Utilities/DateUtilities";

interface DateCellInput {
  currentScheduledAt: Date;
  event: trainingEvent;
  trainingAttributes: trainingAttributes;
}

function DateCell({
  currentScheduledAt,
  event,
  trainingAttributes,
}: DateCellInput) {
  const [currentWorkoutType, setCurrentWorkoutType] =
    useState<TrainingEventType>(event.trainingEventType);
  const [dateOnHover, setDateOnHover] = useState<boolean>(false);

  function handleDateMouseOver() {
    setDateOnHover(true);
  }

  function handleDateMouseOut() {
    setDateOnHover(false);
  }

  function blockedCell() {
    return (
      <td
        onMouseOver={() => handleDateMouseOver()}
        onMouseOut={() => handleDateMouseOut()}
        className="relative px-2 py-2 text-sm text-red-500 2xl:px-6 whitespace-nowrap"
      >
        {formatDate(currentScheduledAt)}
        {dateOnHover && (
          <div className="absolute inset-x-0 z-20 px-2 overflow-visible text-white bg-black/50 w-fit h-fit -top-4">
            Warning: this is a preferred no-workout day
          </div>
        )}
      </td>
    );
  }

  function warningCell() {
    return (
      <td
        onMouseOver={() => handleDateMouseOver()}
        onMouseOut={() => handleDateMouseOut()}
        className="relative px-2 py-2 text-sm 2xl:px-6 text-amber-500 whitespace-nowrap"
      >
        {formatDate(currentScheduledAt)}
        {dateOnHover && (
          <div className="absolute inset-x-0 z-20 px-2 overflow-visible text-white bg-black/50 w-fit h-fit -top-4">
            Warning: Run activity occurred on no-workout day
          </div>
        )}
      </td>
    );
  }

  function normalCell() {
    return (
      <td className="px-2 py-2 text-sm text-gray-500 2xl:px-6 whitespace-nowrap">
        {formatDate(currentScheduledAt)}
      </td>
    );
  }

  function renderCell() {
    if (
      dateIsOnBlockedDay(currentScheduledAt, trainingAttributes) &&
      currentWorkoutType !== TrainingEventType.REST &&
      !event.id.includes("activity")
    ) {
      return blockedCell();
    }

    if (
      dateIsOnBlockedDay(currentScheduledAt, trainingAttributes) &&
      event.id.includes("activity") &&
      event.sportsType === "RUN"
    ) {
      return warningCell();
    }
    return normalCell();
  }

  return <>{renderCell()}</>;
}

export default DateCell;
