import { useEffect, FC } from "react";
import { useHistory } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";

const AppUrlListener: FC = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        // Special case: don't open CoachX links in the app, send to system browser.
        if (event.url.includes("/coach/")) {
          window.open(event.url, "_system", "location=yes");
          return;
        }
        // slug = /tabs/tab2
        const slug = event.url.split(".com").pop();
        if (slug) {
          history.push(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }, 1000);
  }, [history]);

  return null;
};

export default AppUrlListener;
