import { useEffect, useState } from "react";
import { updateSetting } from "../../../Api/Settings";
import { UnitPreference } from "../../../Api/User";
import { useUserInfo } from "../../../Context/UserContext";

function useUnitPreference() {
  const { userInfo } = useUserInfo();
  const [unitPreference, setUnitPreference] = useState<UnitPreference>();
  const [displayModal, setDisplayModal] = useState(false);

  async function handleUnitPreferenceChange() {
    try {
      if (unitPreference) {
        await updateSetting({
          settingName: "setProfileMetricSetting",
          settingValue: unitPreference.toString(),
        });
      }

      return 200;
    } catch (e) {
      return null;
    }
  }

  useEffect(() => {
    if (userInfo) setUnitPreference(userInfo.profileMetricSetting);
  }, [userInfo]);
  return {
    unitPreference,
    setUnitPreference,
    handleUnitPreferenceChange,
    displayModal,
    setDisplayModal,
  } as useUnitPreferenceOutput;
}

export interface useUnitPreferenceOutput {
  unitPreference: UnitPreference | undefined;
  setUnitPreference: React.Dispatch<
    React.SetStateAction<UnitPreference | undefined>
  >;
  handleUnitPreferenceChange(): Promise<200 | null>;
  displayModal: boolean;
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default useUnitPreference;
