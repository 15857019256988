import { classNames } from "../../Utilities/classnames";

function Spinner({ isCentered = true }: { isCentered?: boolean }) {
  return (
    <div
      className={classNames(
        isCentered ? "flex items-center justify-center p-48 space-x-2" : ""
      )}
    >
      <div
        className="inline-block w-4 h-4 rounded-full text-brand spinner-border animate-spin border-1"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default Spinner;
