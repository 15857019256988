import UpgradeX from "../../../Assets/UpgradeX.svg";
import UpgradeCheck from "../../../Assets/UpgradeCheck.svg";
import { useTrainingAttributes } from "../../../Context/TrainingAttributeContext";
import { subscriptionPlans } from "../../../Api/TrainingAttribute";
import { useUserInfo } from "../../../Context/UserContext";
import { track, TrackingEventType } from "../../../Api/Tracking";
import {isMobileEmbeddedView} from "../../../Api/Auth";

function PlusCard() {
  const { userInfo } = useUserInfo();
  const { trainingAttributes } = useTrainingAttributes();

  const checkList = [
    { checked: true, text: <p>Smart training app</p> },
    { checked: true, text: <p>Adaptive, personalized training plans</p> },
    { checked: true, text: <p>Certified 1:1 personal running coach</p> },
    {
      checked: true,
      text: (
        <p>
          <span className="font-bold">Daily</span> access to your coach
        </p>
      ),
    },
    { checked: true, text: <p>Ongoing video calls w/ your coach</p> },
  ];

  function BottomButton() {
    if (
      trainingAttributes &&
      trainingAttributes.productName === subscriptionPlans[2]
    )
      return (
        <div className="flex items-end justify-center h-full">
          <button className="w-full py-3 font-medium rounded-full bg-primary-50 text-primary-500 text-[18px] cursor-not-allowed ">
            Unlocked
          </button>
        </div>
      );

    if (
      userInfo &&
      trainingAttributes &&
      trainingAttributes.productName &&
      [subscriptionPlans[0], subscriptionPlans[1]].includes(
        trainingAttributes.productName
      )
    )
      return (
        <div className="flex items-end justify-center h-full">
          <a
            onClick={() => {
              track(
                TrackingEventType.TRACKING_APP_WEB_UPGRADE_PLUS,
                "Pressed upgrade to Plus",
                "profiles"
              );
              if (isMobileEmbeddedView("1.0.24")) {
                window.location.href = window.location.href + "#contact-upgrade-plus";
              } else {
                window.location.href = `mailto:coach@runzy.com?subject=Upgrade to Runzy Plus&body=Hi Runzy team,%0d%0a%0d%0aI'd like to upgrade my subscription to the Plus plan.%0d%0a%0d%0aThanks,%0d%0a${userInfo.firstName}`
              }
            }}
            className="w-full py-3 flex items-center justify-center font-medium rounded-full text-white bg-primary-500 text-[18px] cursor-pointer"
          >
            Upgrade to Plus
          </a>
        </div>
      );

    return;
  }

  return (
    <div className="bg-white snap-center shrink-0 p-4 rounded-[12px] w-[334px] h-[597px]">
      {" "}
      <div className="flex flex-col h-full items-between">
        <div>
          <h1 className="font-medium text-[16px] text-[#0D0D0D]">Plus</h1>

          <p>
            <span className="font-semibold text-[36px]">$149</span>
            <span className="font-medium text-[14px]  text-neutral-400">
              /month
            </span>
          </p>
          <p className="text-neutral-500 font-medium text-[16px] border-b py-5">
            Includes all of Core as well as regular video calls with your
            personal coach.
          </p>
          <div className="pt-5 space-y-4">
            {checkList.map((item, index) => (
              <div key={index} className="flex gap-4">
                <div className="mt-0.5">
                  {item.checked ? (
                    <img src={UpgradeCheck} alt="included" />
                  ) : (
                    <img src={UpgradeX} alt="not included" />
                  )}
                </div>

                {item.text}
              </div>
            ))}
          </div>
        </div>
        <div className="grow">{BottomButton()}</div>
      </div>
    </div>
  );
}

export default PlusCard;
