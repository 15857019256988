import { useEffect, useState } from "react";
import { updateSetting } from "../../../Api/Settings";
import { useUserInfo } from "../../../Context/UserContext";

function useNameSettings() {
  const { userInfo } = useUserInfo();
  const [currentName, setCurrentName] = useState("");

  async function handleNameChange() {
    try {
      await updateSetting({
        settingName: "setFirstNameLastName",
        settingValue: currentName,
      });

      return 200;
    } catch (e) {
      return null;
    }
  }

  useEffect(() => {
    if (userInfo) setCurrentName(userInfo.firstName + " " + userInfo.lastName);
  }, [userInfo]);
  return { currentName, setCurrentName, handleNameChange };
}

export interface useNameSettingsOutput {
  currentName: string;
  setCurrentName: React.Dispatch<React.SetStateAction<string>>;
  handleNameChange(): Promise<200 | null>;
}

export default useNameSettings;
